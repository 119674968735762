import { makeStyles } from "@material-ui/core";
import { ACTIVE_CIRCLE_ICON, COMPLETE_CIRCLE_ICON } from "asset/images";
import React from "react";

const useStyles = makeStyles((theme) => ({
  activeStatus: {
    background: "#FFEFD0",
    borderRadius: "4px",
    padding: "4px",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "10px",
    display: "flex",
    alignItems: "center",
  },
  completeStatus: {
    background: "#CEFFDB",
    borderRadius: "4px",
    padding: "4px",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "10px",
    display: "flex",
    alignItems: "center",
  },
  statusDot: {
    display: "inline-block",
    verticalAlign: "middle",
    marginRight: "5px",
  },
  statusBlock: {
    width: "100%",
    display: "flex",
  },
}));
function StatusBlock({ status, isDetailCard, greenStatus = "COMPLETED" }) {
  const classes = useStyles();

  const titleCase = (str) =>
    `${str[0].toUpperCase()}${str.slice(1).toLowerCase()}`;
  return (
    <div className={[classes.statusBlock]}>
      <div
        className={
          status?.toUpperCase() === greenStatus
            ? classes.completeStatus
            : classes.activeStatus
        }
      >
        <img
          className={classes.statusDot}
          src={
            status?.toUpperCase() === greenStatus
              ? COMPLETE_CIRCLE_ICON
              : ACTIVE_CIRCLE_ICON
          }
          alt="status icon"
        />
        {status ? titleCase(status) : "-"}
      </div>
    </div>
  );
}

export default StatusBlock;
