import React, { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  Grid,
  makeStyles,
  TextField,
  Typography,
  IconButton,
  InputLabel,
} from "@material-ui/core";
import * as R from "ramda";
import { AddCircleOutline } from "@material-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { updateForm } from "state/slices/globalFormSlice";
import { TierUnitPlanFormInitialState } from "state/globalForms/initialStates";
import DisplaySubUnits from "common/components/DisplaySubUnits";
import TopicsDropDown from "common/molecules/TopicsDropDown";

const useStyles = makeStyles((theme) => ({
  inputFormControl: {
    marginTop: "1rem",
  },

  addIconContainer: {
    "&.MuiGrid-root": {
      marginTop: "12px",
    },
    alignSelf: "center",
  },
  tierTitle: {
    display: "flex",
    alignItems: "center",
  },
  tierCard: {
    marginBottom: "1em",
  },
  inputLabel: {
    top: "-17px",
  },
  inputLabel1: {
    top: "-17px",
    left: "-14px",
  },
  observationRegistryHeader: {
    border: "1px solid #898EAC",
    background: "#898eac2e",
    width: "100%",
    borderRadius: 4,
  },
  accordion: {
    boxShadow: "none",
  },
}));

const subUnitInitialState = {
  sortOrder: "",
  name: "",
};
const UnitPlanForm = ({
  tierIndex,
  unitIndex,
  onUnitChange,
  formType,
  isReadOnly,
  data = null,
  page,
  topics
}) => {

  const [unitPlanForm, setUnitPlanForm] = useState(
    data
      ? data
      : {}
  );
  const [subUnit, setSubUnit] = useState(subUnitInitialState);
  const selectedTopics = R.pathOr([], ["topics"], data);
  const classes = useStyles();

  useEffect(() => {
    setUnitPlanForm(data || {})
  }, [data])

  const onDataChange = (event) => {
    const [value, name] = R.paths(
      [
        ["target", "value"],
        ["target", "name"],
      ],
      event
    );
    let updatedData = R.assocPath([name], value, unitPlanForm);
    setUnitPlanForm(updatedData);
    onUnitChange(updatedData);
  };

  const onTopicChange = (topicsData) => {
    const updatedUnitPlanForm = R.assocPath(
      ["topics"],
      topicsData,
      unitPlanForm
    );
    setUnitPlanForm(updatedUnitPlanForm);
    onUnitChange(updatedUnitPlanForm);
  };

  const addSubUnit = () => {
    if (subUnit) {
      const subUnits = R.pathOr([], ["subUnits"], unitPlanForm);
      const updatedSubUnits = R.append(subUnit, subUnits);
      const updatedUnitPlanForm = R.assocPath(
        ["subUnits"],
        updatedSubUnits,
        unitPlanForm
      );
      setUnitPlanForm(updatedUnitPlanForm);
      setSubUnit(subUnitInitialState);
      onUnitChange(updatedUnitPlanForm);
    }
  };

  const onSubUnitChange = (e) => {
    const updatedSubUnit =
      e.target.name === "sortOrder"
        ? R.assocPath([e.target.name], e.target.value.replace(/[^0-9]/g, ""))
        : R.assocPath([e.target.name], e.target.value);
    setSubUnit(updatedSubUnit);
  };

  const onSubUnitDelete = (index) => {
    const subUnits = R.pathOr([], ["subUnits"], unitPlanForm);
    const updatedUnitPlanForm = R.assocPath(
      ["subUnits"],
      R.remove(index, 1, subUnits),
      unitPlanForm
    );
    setUnitPlanForm(updatedUnitPlanForm);
    onUnitChange(updatedUnitPlanForm);
  };

  return (
    <Grid item xs={12}>
      <div style={{ marginBottom: "1rem" }}>
        <Box
          sx={{
            width: "100%",
            border: `1px solid #898EAC`,
            padding: "1rem",
            marginBottom: "1rem",
            borderRadius: 4,
          }}
        >
          <form>
            <TopicsDropDown
              selectedTopics={selectedTopics}
              topics={topics}
              setFormData={onTopicChange}
            />
            <Grid container spacing={1}>
              <Grid item xs={12} md={6}>
                <FormControl className={classes.inputFormControl}>
                  <InputLabel className={classes.inputLabel}>
                    Unit Name
                  </InputLabel>
                  <TextField
                    margin="normal"
                    disabled={isReadOnly}
                    variant="outlined"
                    name="name"
                    placeholder="Enter unit name"
                    value={unitPlanForm.name}
                    onChange={onDataChange}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3}>
                <FormControl className={classes.inputFormControl}>
                  <InputLabel className={classes.inputLabel}>
                    Start Date
                  </InputLabel>
                  <TextField
                    margin="normal"
                    disabled={isReadOnly}
                    variant="outlined"
                    id="date"
                    name="start"
                    placeholder={"Enter start date"}
                    type="date"
                    sx={{ width: 220 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={onDataChange}
                    value={unitPlanForm.start}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={3}>
                <FormControl className={classes.inputFormControl}>
                  <InputLabel className={classes.inputLabel}>
                    End Date
                  </InputLabel>
                  <TextField
                    margin="normal"
                    disabled={isReadOnly}
                    variant="outlined"
                    id="date"
                    name="end"
                    placeholder={"Enter end date"}
                    type="date"
                    sx={{ width: 220 }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={onDataChange}
                    value={unitPlanForm.end}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </form>
          <Box>
            <Typography variant="h6">Sub Units</Typography>
            <Grid container spacing={1} style={{ marginTop: "1rem" }}>
              <Grid item xs={12} md={2}>
                <FormControl className={classes.inputFormControl}>
                  <InputLabel className={classes.inputLabel}>
                    Sequence
                  </InputLabel>
                  <TextField
                    margin="normal"
                    disabled={isReadOnly}
                    variant="outlined"
                    name="sortOrder"
                    placeholder="Enter sequence"
                    value={subUnit.sortOrder}
                    onChange={(e) => onSubUnitChange(e)}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={10} md={9}>
                <FormControl className={classes.inputFormControl}>
                  <InputLabel className={classes.inputLabel}>
                    Sub Unit Name
                  </InputLabel>
                  <TextField
                    margin="normal"
                    disabled={isReadOnly}
                    variant="outlined"
                    name="name"
                    placeholder="Enter sub unit name"
                    value={subUnit.name}
                    onChange={(e) => onSubUnitChange(e)}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={2} md={1} className={classes.addIconContainer}>
                <IconButton
                  disabled={isReadOnly}
                  color="primary"
                  onClick={() => addSubUnit(tierIndex, unitIndex)}
                >
                  <AddCircleOutline />
                </IconButton>
              </Grid>
            </Grid>
            {
              <DisplaySubUnits
                isWhiteIcon={false}
                subUnits={unitPlanForm.subUnits}
                onDelete={onSubUnitDelete}
                disabled={isReadOnly}
              />
            }
          </Box>
        </Box>
      </div>
    </Grid>
  );
};

export default UnitPlanForm;
