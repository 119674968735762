import { Grid, makeStyles, useMediaQuery, useTheme } from "@material-ui/core";
import withPrivilege from "common/hocs/withPrivilege";
import { useEffect, useState } from "react";
import { colors } from "theme/colors";
import { EntityMap } from "util/roleUtils";
import "../../academicPlanDetails/styles.css";
import { getDetailsById } from "components/listOfValues/utils";
import { useParams } from "react-router-dom";
import BreadCrumb from "components/academicPlanDetails/BreadCrumb";
import StaffDetailCard from "./StaffDetailCard";
import RolesPage from "./RolesPage";
import RolesHeader from "./RolesHeader";
import RolesModal from "./RolesModal";
import { teacherSectionInitialState } from "state/globalForms/initialStates";

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    flexWrap: "nowrap",
  },
  filterSection: {
    background: "white",
  },
  planSection: {
    height: "100%",
    minHeight: "100vh",
    width: "100%",
    padding: "47px 51px",
    backgroundColor: colors.gray5,
    [theme.breakpoints.down("sm")]: {
      padding: "36px",
    },
  },
  planCard: {
    marginBottom: "30px",
  },
}));

function ManageRoles({ props, userRoles, privileges, data, planName }) {
  const classes = useStyles();
  const theme = useTheme();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down("sm"));

  const [modalState, setModalState] = useState({
    isGradeModalOpen: false,
    data: null,
    isEdit: false,
  });
  const [state, setState] = useState(teacherSectionInitialState);
  const { id } = useParams();

  useEffect(() => {
    getStaffDetails();
  }, []);

  const getStaffDetails = async () => {
    const response = await getDetailsById("/users", id);
    setState(response || null);
  };

  return (
    <>
      <Grid container direction="row" className={classes.gridContainer}>
        {!isSmallDevice && (
          <Grid className={classes.filterSection} md="3">
            <BreadCrumb planName={"STAFF_DETAILS"} parentPath="staff" />

            <StaffDetailCard
              data={state}
              {...props}
              getStaffDetails={getStaffDetails}
              isDetailedPage={true}
            />
          </Grid>
        )}
        <Grid className={classes.planSection} md="9" xs="12">
          <Grid
            container
            spacing={3}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            className={classes.container}
          >
            {isSmallDevice && (
              <BreadCrumb planName={"STAFF_DETAILS"} parentPath="staff" />
            )}
            <Grid item xs={12} md={12} className={classes.paddingNone}>
              <RolesHeader
                changeView={planName}
                privileges={privileges.teachers}
                setModalState={setModalState}
              />
            </Grid>
          </Grid>
          {isSmallDevice && (
            <Grid item className={classes.planCard} xs="12">
              <StaffDetailCard
                data={state}
                {...props}
                getStaffDetails={getStaffDetails}
                isDetailedPage={true}
              />
            </Grid>
          )}
          <RolesPage
            {...props}
            parentState={state}
            modalState={modalState}
            setModalState={setModalState}
            userRoles={userRoles}
            getStaffDetails={getStaffDetails}
          />
        </Grid>
      </Grid>
      {modalState?.isOpen && (
        <RolesModal
          userRoles={userRoles}
          modalState={modalState}
          parentState={state}
          setModalState={setModalState}
          getStaffDetails={getStaffDetails}
        />
      )}
    </>
  );
}

export default withPrivilege(ManageRoles, EntityMap.teacher);
