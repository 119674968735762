import { createTheme, alpha } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#263657"
    },
    secondary: {
      main: "#1e8c17"
    },
    typography: {
      fontFamily: "Lato",
      fontStyle: "normal",
    },
  }
});

theme.props = {
  MuiButton: {
    disableElevation: true,
    disableTouchRipple: true
  },
  MuiTextField: {
    fullWidth: true,
  },
  MuiFormControl: {
    fullWidth: true
  },
  MuiInput: {
    disableUnderline: true
  },
  MuiInputLabel: {
    shrink: true,
    focused: false
  }
};

theme.overrides = {
  MuiCssBaseline: {
          "@global": {
            body: {
              fontFamily: "Lato"
            },
          },
        },
  MuiTypography: {
    root: {
      fontFamily: "Lato",
    },
    h6: {
    },
    body1: {
      fontFamily: "Lato",
      
    },
    subtitle1: {
      fontWeight: 500,
      color: "#898EAC",
      fontSize: "0.9rem"
      
    },
    subtitle2: {
      fontSize: "0.9rem"
    }
  },
  MuiButton: {
    root: {
      textTransform: "capitalize",
      fontStyle: "normal",
      fontWeight: 300,
      fontSize: "16px",
      lineHeight: "19px",
      alignItems: "center",
      textAlign: "center",
      margin: "10px 0px"
    },
    containedPrimary: {
      borderRadius: "4px",
      fontFamily: "Lato",
      
      lineHeight: "24px"
    },
    containedSecondary: {
      borderRadius: "6px",
      fontFamily: "Lato",
      fontWeight: 500,
      padding: "23px"
    },
    outlinedPrimary: {
      borderRadius: "4px",
      fontFamily: "Lato",
      fontWeight: 500,
      lineHeight: "24px",
      border: "1px solid"
    },
    textSecondary: {
      textDecoration: "underline"
    },
    textPrimary: {
      fontWeight: 500,
      padding: "10px"
    },
  },
  MuiSelect: {
    focus: {
      backgroundColor: "#fff"
    },
    icon: {
      color: "#263657",
    },
    selectMenu: {
      whiteSpace: 'break-spaces',
    }
  },
  MuiInput: {
    root: {
      top: "10px",
      border: "1px solid #8692A6",
      padding: "8px 15px",
      borderRadius: 6
    },
    focused: {
      background: "#FFFFFF",
      border: "1px solid #263657",
      boxShadow: "0px 4px 10px 3px rgba(0, 0, 0, 0.11)"
    },
  },
  MuiOutlinedInput: {
    root: {
      // border: "1px solid #ced4da",
      fontSize: "16px"
    },
    input: {
      padding: "20px",
      borderRadius: 4
    },
    // focused: {
    //   background: "#FFFFFF",
    //   // border: "1px solid #1565D8",
    //   boxShadow: "0px 4px 10px 3px rgba(0, 0, 0, 0.11)"
    // }
    focused: {
      background: "#FFFFFF",
      border: "none",
      boxShadow: `${alpha("#30409E", 0.15)} 0 0 0 0.2rem`
    }
  },
  MuiFormControl: {
    marginNormal:{
      marginTop: "8px"
    }
  },
  MuiInputBase: {
    root: {
      fontFamily: "Lato",
      fontStyle: "normal",
    },
    input: {
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "17px",
      display: "flex",
      alignItems: "center"
    },
  },
  MuiInputLabel: {
    root: {
      fontFamily: "Lato",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "19px",
      display: "flex",
      alignItems: "center",
      // color: "#696F79"
      color: "#898EAC"
    },
    asterisk: {
      color: "#E10101"
    }
  }
};

export default theme;