import React, { useEffect, useState, useCallback } from "react";
import {
  Select,
  MenuItem,
  Box,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import * as R from "ramda";
import { getPageList } from "components/listOfValues/utils";
import { PAGES, setPageListItems } from "state/slices/pageSlice";
import { updateForm } from "state/slices/globalFormSlice";
import { mapIndexed } from "common/utils/composition";
import { CheckCircleOutline, CancelOutlined } from "@material-ui/icons";

const useStyle = makeStyles(() => ({
  listItem: {
    "&.MuiListItem-root": {
      paddingTop: "0px",
      paddingBottom: "0px",
    },
  },
  listBlock: {
    width: "100%",
    "&.MuiList-root": {
      height: "auto",
      maxHeight: "none",
    },
  },
  placeholderValue: {
    color: "#bbb",
  },
}));
const DisplySelectedList = ({
  selectList,
  listData,
  onRemove,
  isReadOnly = false,
}) => {
  const classes = useStyle();
  const removeRow = (removeIndex) => {
    onRemove(removeIndex);
  };
  const displayList = R.map(
    (item) => R.filter((listItem) => listItem.value === item)(listData)[0]
  )(selectList);

  return (
    <Box
      sx={{
        display: "block",
        overflow: "auto",
      }}
    >
      <List className={classes.listBlock}>
        {mapIndexed((eachOutcome, index) => {
          return (
            <ListItem key={index} className={classes.listItem}>
              <ListItemAvatar>
                <CheckCircleOutline disabled={isReadOnly} fontSize={"small"} />
              </ListItemAvatar>
              <ListItemText
                disabled={isReadOnly}
                primary={R.propOr(eachOutcome, "name", eachOutcome)}
              />
              <IconButton
                disabled={isReadOnly}
                color="primary"
                onClick={() => removeRow(eachOutcome.id)}
              >
                <CancelOutlined className="addIcon" />
              </IconButton>
            </ListItem>
          );
        }, displayList)}
      </List>
    </Box>
  );
};

const LOVSelect = ({
  formType,
  selectKey,
  lovKey,
  value,
  multiselect,
  selectObj = false,
  getSelectList = null,
  isReadOnly = false,
  setObj = false,
  showList = false,
  customOnChange,
  disableValues = [],
}) => {
  const LOVList = useSelector((state) => state.pages.listOfValues.items);
  const formData = useSelector((state) => state.globalForm[formType]);
  const dispatch = useDispatch();
  const classes = useStyle();
  const getLovValue = () => {
    return setObj ? R.propOr("", "value", value) : value;
  };

  const [lovValue, setLovValue] = useState(getLovValue());
  const [selectList, setSelectList] = useState([]);

  useEffect(() => {
    setLovValue(setObj ? R.propOr("", "value", value) : value);
  }, [value, setObj]);

  const getLOVList = useCallback(async () => {
    let filteredLOV = [];
    if (R.isEmpty(LOVList)) {
      const lovListData = await getPageList({
        searchKey: "",
        page: PAGES.listOfValues,
        pageData: { pageSize: 50, currentPage: 0 },
      });
      dispatch(
        setPageListItems({
          page: PAGES.listOfValues,
          itemList: lovListData.content,
        })
      );
      filteredLOV = lovListData.content.filter((lovItem) => {
        return lovItem.name === lovKey;
      })[0];
    } else {
      filteredLOV = LOVList.filter((lovItem) => {
        return lovItem.name === lovKey;
      })[0];
    }

    let itemList = R.propOr([], "items", filteredLOV);

    if (getSelectList && value) {
      const selectedlovData = getSelectList(value, itemList);
      setLovValue(selectedlovData);
    }

    if (itemList.length === 0) {
      setLovValue(null || "");
    }

    setSelectList(itemList);
  }, [lovKey, LOVList, dispatch, value]);

  useEffect(() => {
    getLOVList();
  }, [getLOVList]);

  useEffect(() => {
    if (formType === "pageFilters") {
      setLovValue(getLovValue());
    }
  }, [value]);

  const getMultiSelectedList = (value) => {
    return R.filter((lov) => R.includes(lov.value, value))(selectList);
  };

  const onChange = (event) => {
    const value = R.path(["target", "value"], event);
    let pushValue = selectObj ? getMultiSelectedList(value) : value;

    if (setObj) {
      pushValue = R.pathOr(
        {},
        [0],
        R.filter((item) => item.value === pushValue)(selectList)
      );
    }
    const updatedformData = R.pipe(R.assocPath([...selectKey], pushValue))(
      formData
    );
    setLovValue(value);
    dispatch(updateForm({ formType, formData: updatedformData }));
    if (customOnChange) {
      customOnChange(pushValue);
    }
  };

  const onRemove = (index) => {
    const removedItem = R.filter((item) => item.id === index)(selectList)[0]
      .value;
    const updatedLOVData = R.filter((lovItem) => !(removedItem === lovItem))(
      lovValue
    );
    setLovValue(updatedLOVData);
    const updatedformData = R.pipe(
      R.assocPath([...selectKey], getMultiSelectedList(updatedLOVData))
    )(formData);
    dispatch(updateForm({ formType, formData: updatedformData }));
  };
  return (
    <>
      <Select
        variant="outlined"
        margin="normal"
        labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        value={lovValue || ""}
        onChange={onChange}
        placeholder={selectKey}
        name={selectKey}
        multiple={multiselect}
        fullWidth
        disabled={isReadOnly}
        displayEmpty
        inputProps={{ "aria-label": "Without label" }}
      >
        <MenuItem value="" disabled>
          <span className={classes.placeholderValue}>Select {selectKey}</span>
        </MenuItem>
        {R.map((item, index) => {
          return (
            <MenuItem
              key={index}
              value={item.value}
              disabled={disableValues.includes(item.value)}
            >
              {item.name}
            </MenuItem>
          );
        }, selectList)}
      </Select>

      {multiselect && showList && !R.isEmpty(lovValue) && (
        <Box
          sx={{
            border: "1px solid #CCC",
            marginTop: "1rem",
          }}
        >
          <DisplySelectedList
            isReadOnly={isReadOnly}
            selectList={lovValue}
            listData={selectList}
            onRemove={onRemove}
          />
        </Box>
      )}
    </>
  );
};

export default LOVSelect;
