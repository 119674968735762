import { Divider, Grid, Paper, makeStyles } from "@material-ui/core";
import React from "react";
import moment from "moment";
import { colors } from "theme/colors";
import Avatar from "@mui/material/Avatar";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ErrorIcon from "@mui/icons-material/Error";

const useStyles = makeStyles((theme) => ({
  chipContainer: {
    marginRight: "1rem",
    borderColor: colors.blue1,
  },
}));

const secondaryLabelEnum = {
  PENDING_REVIEW: "Review Pending",
  SENT_FOR_REASSESSMENT: "Sent For Reassessment",
  APPROVED: "Approved",
  SELF_REASSESSMENT_REQUIRED: "Reassess Self Feedback",
  SELF_FEEDBACK_SUBMITTED: "Self Feedback Submitted",
  PEER_REASSESSMENT_REQUIRED: "Reassess Peer Feedback",
  PEER_FEEDBACK_SUBMITTED: "Peer Feedback Submited",
  FEEDBACK_COMPLETED: "Completed",
};

const Comment = ({ comments, primaryDataVariale, secondaryLabelVariable }) => {
  const classes = useStyles();
  const imgLink =
    "https://images.pexels.com/photos/1681010/pexels-photo-1681010.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260";

  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
    };
  }

  function converTimeDifferenceToReadableUnit(timeDiffInSeconds) {
    const timeDiffInMinutes =
      timeDiffInSeconds >= 60 ? parseInt(timeDiffInSeconds / 60, 10) : 0;
    const timeDiffInHours =
      timeDiffInMinutes >= 60 ? parseInt(timeDiffInSeconds / (60 * 60), 10) : 0;
    const timeDiffInDays =
      timeDiffInHours >= 24
        ? parseInt(timeDiffInSeconds / (60 * 60 * 24), 10)
        : 0;
    const timeDiffInMonths =
      timeDiffInDays >= 30
        ? parseInt(timeDiffInSeconds / (60 * 60 * 24 * 30), 10)
        : 0;
    const timeDiffInYears =
      timeDiffInMonths >= 12
        ? parseInt(timeDiffInSeconds / (60 * 60 * 24 * 365), 10)
        : 0;

    return timeDiffInYears > 0
      ? `${timeDiffInYears}y`
      : timeDiffInMonths > 0
      ? `${timeDiffInMonths}mo`
      : timeDiffInDays > 0
      ? `${timeDiffInDays}d`
      : timeDiffInHours > 0
      ? `${timeDiffInHours}h`
      : timeDiffInMinutes > 0
      ? `${timeDiffInMinutes}m`
      : `${timeDiffInSeconds}s`;
  }

  return (
    <div style={{ padding: 14 }} className="App">
      <Paper style={{ padding: "40px 20px" }}>
        {comments.map((comment, index) => {
          const commentPostTimeInSecondsAgo = moment().diff(
            moment(comment.createdOn),
            "seconds"
          );
          return (
            <>
              {index !== 0 && (
                <Divider variant="fullWidth" style={{ margin: "30px 0" }} />
              )}
              <Grid container wrap="nowrap" spacing={2}>
                <Grid item>
                  {comment.reviewState === "SENT_FOR_REASSESSMENT" ? (
                    <Avatar sx={{ bgcolor: colors.warnOrange }}>
                      <ErrorIcon />
                    </Avatar>
                  ) : (
                    <Avatar sx={{ bgcolor: colors.infoGreen }}>
                      <ThumbUpIcon />
                    </Avatar>
                  )}
                </Grid>
                <Grid justifyContent="left" item xs zeroMinWidth>
                  <h4 style={{ margin: 0, textAlign: "left" }}>
                    {comment.createdBy}
                  </h4>
                  <h6 style={{ margin: 0, textAlign: "left" }}>
                    (
                    {`State: ${
                      secondaryLabelEnum[comment[secondaryLabelVariable]]
                    }`}
                    )
                  </h6>
                  <p style={{ textAlign: "left" }}>
                    {comment[primaryDataVariale]}
                  </p>
                  <p style={{ textAlign: "left", color: "gray" }}>
                    {converTimeDifferenceToReadableUnit(
                      commentPostTimeInSecondsAgo
                    )}{" "}
                    ago
                  </p>
                </Grid>
              </Grid>
            </>
          );
        })}
      </Paper>
    </div>
  );
};

export default Comment;
