import {
    FormControl,
    makeStyles,
    TextField,
    IconButton,
    Grid,
    Typography,
    InputLabel
} from "@material-ui/core";
import React, { useState } from "react";
import * as R from "ramda";
import { AddCircleOutline } from "@material-ui/icons";
import DeleteableChips from "common/components/DeletableChips/DeletableChips";
import { mapIndexed } from "common/utils/composition";
import TaggedOutComes from "./TaggedOutComeForm";

const useStyles = makeStyles((theme) => ({
    inputFormControl: {
        marginBottom: "1rem",
    },
    inputLabel: {
        top: "-17px",
    }
}));

const ManageObservationsForm = ({ observationRegistry, setObservationRegistry, observationIndex }) => {
    const [tagList, observationData] = R.paths([["observations", observationIndex, "tagList"], ["observations", observationIndex]], observationRegistry);
    const classes = useStyles();
    const [tags, setTags] = useState("");

    const onChange = (event) => {
        const [value, name] = R.paths(
            [
                ["target", "value"],
                ["target", "name"],
            ],
            event
        );
        setObservationRegistry(R.assocPath(["observations", observationIndex, name], value, observationRegistry));
    };

    const onTagChange = (event) => {
        const [value] = R.paths(
            [
                ["target", "value"],
            ],
            event
        );
        setTags(value);
    }

    const addTag = () => {
        const updatedTagList = R.append(tags, tagList);
        setObservationRegistry(R.assocPath(["observations", observationIndex,"tagList"], updatedTagList, observationRegistry));
        setTags("");
    }

    const onTagDelete = (tagName) => {
        const updatedTagList = R.filter((eachTag) => eachTag !== tagName, tagList);
        setObservationRegistry(R.assocPath(["observations", observationIndex,"tagList"], updatedTagList, observationRegistry));
    }

    return (
        <form>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <FormControl className={classes.inputFormControl}>
                        <InputLabel className={classes.inputLabel}>Name</InputLabel>
                        <TextField
                            margin="normal"
                            variant="outlined"
                            name="name"
                            placeholder="Enter name"
                            value={observationData.name}
                            onChange={onChange}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={11} >
                    <FormControl>
                        <InputLabel className={classes.inputLabel}>Tags</InputLabel>
                        <TextField
                            margin="normal"
                            variant="outlined"
                            name="tags"
                            placeholder="Enter tags"
                            value={tags}
                            onChange={onTagChange}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={1} style={{ alignSelf: "center"}}>
                    <IconButton color="primary" onClick={addTag}>
                        <AddCircleOutline className="addIcon" />
                    </IconButton>
                </Grid>
                <Grid item xs={12}>
                    {
                        mapIndexed((tag, index) => {
                            return <DeleteableChips key={`${tag}-${index}`} label={tag} onDelete={onTagDelete} />
                        }, tagList)
                    }
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6" style={{ marginBottom: "2rem" }}>Outcomes</Typography>
                    <TaggedOutComes tagList={tagList} formData={observationRegistry} setTaggedOutComes={setObservationRegistry} observationIndex={observationIndex} />
                </Grid>
            </Grid>

        </form>
    );
};
export default ManageObservationsForm;