import { Grid, makeStyles } from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import { getPageList } from "components/listOfValues/utils";
import React, { useEffect, useState } from "react";
import { PAGES } from "state/slices/pageSlice";
import LessonPlanCard from "./LessonPlanCard";
import AcademicPlanHeader from "../AcademicPlanHeader";
import withPrivilege from "common/hocs/withPrivilege";
import { EntityMap } from "util/roleUtils";
import { CircularProgress } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  annualPlanCard: {
    marginBottom: "30px",
  },
  paginationContainer: {
    display: "flex",
    justifyContent: "end",
  },
  noDataAvailable: {
    margin: "20px",
  },
}));

function LessonPlans(props) {
  const { filterState, planName, setModalState } = props;
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState({
    totalPage: 0,
    pageSize: 10,
    data: [],
    currentPage: 0,
    pageFilter: {},
  });

  useEffect(() => {
    if (state?.currentPage !== 0) {
      setState((prev) => ({
        ...prev,
        currentPage: 0,
      }));
    }
    getLessonPlanList({ pageSize: state.pageSize, currentPage: 0 });
  }, [filterState]);

  const getLessonPlanList = async (pageData) => {
    const { pageSize, currentPage } = pageData || {
      pageSize: state.pageSize,
      currentPage: state.currentPage,
    };
    const pageFilter = { ...filterState };
    if (pageFilter && !pageFilter?.annualPeriod)
      pageFilter.annualPeriod = "ALL";
    setIsLoading(true);
    const pageListData = await getPageList({
      searchKey: "",
      page: PAGES.lessonPlanList,
      pageData: { pageSize, currentPage },
      pageFilter: pageFilter || {},
    });
    setIsLoading(false);
    setState((prev) => ({
      ...prev,
      data: pageListData?.content || [],
      totalPage: pageListData?.totalPages || 0,
    }));
  };

  const onPageChange = (_, page) => {
    setState((prev) => ({
      ...prev,
      currentPage: page - 1,
    }));
    getLessonPlanList({ pageSize: state.pageSize, currentPage: page - 1 });
  };

  return (
    <>
      <AcademicPlanHeader changeView={planName} setModalState={setModalState} {...props} />
      <Grid container direction="row" spacing={4}>
        {isLoading ? (
          <CircularProgress />
        ) : state?.data.length ? (
          state?.data?.map((plan, index) => (
            <Grid
              item
              className={classes.annualPlanCard}
              key={index}
              md="6"
              xs="12"
            >
              <LessonPlanCard data={plan} hideModification={true} {...props}/>
            </Grid>
          ))
        ) : (
          <div className={classes.noDataAvailable}>No Data available</div>
        )}
      </Grid>
      <Grid item xs={12} className={classes.paginationContainer}>
        <Pagination
          count={state?.totalPage || 0}
          color="primary"
          onChange={onPageChange}
          page={(state?.currentPage || 0) + 1}
        />
      </Grid>
    </>
  );
}

export default withPrivilege(LessonPlans, EntityMap.lessonplan);
