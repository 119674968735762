import React, { useEffect, useState, useCallback } from "react";
import { Select, MenuItem, makeStyles } from "@material-ui/core";
import * as R from "ramda";
import { readAllTeachersOfSchool } from "components/listOfValues/utils";

const useStyle = makeStyles(() => ({
  placeholderValue: {
    color: "#bbb",
  },
}));

const TeacherDropDown = ({
  selectKey,
  selectedValue = {},
  customOnChange,
  isDisabled = false,
  schoolCode = "",
}) => {
  const [selectData, setSelectData] = useState(selectedValue);
  const classes = useStyle();
  const [selectList, setSelectList] = useState([]);
  const onChange = (event) => {
    const value = R.path(["target", "value"], event);
    const selectedLOV = R.filter((item) => item.email === value)(selectList)[0];
    setSelectData(selectedLOV);
    if (customOnChange) {
      customOnChange(selectedLOV);
    }
  };

  const getListData = useCallback(async () => {
    const listData = await readAllTeachersOfSchool(schoolCode);
    setSelectList(listData);
  }, [schoolCode]);

  useEffect(() => {
    getListData();
  }, [getListData]);

  useEffect(() => {
    const selectedLOV = R.filter((item) => item.email === selectedValue)(
      selectList
    )[0];
    setSelectData(selectedLOV);
  }, [selectList, selectedValue]);

  return (
    <Select
      variant="outlined"
      margin="normal"
      labelId="demo-simple-select-outlined-label"
      id="demo-simple-select-outlined"
      value={R.propOr("", "email", selectData)}
      onChange={onChange}
      placeholder={selectKey}
      name={selectKey}
      disabled={isDisabled}
      displayEmpty
      inputProps={{ "aria-label": "Without label" }}
    >
      <MenuItem value="" disabled>
        <span className={classes.placeholderValue}>Select {selectKey}</span>
      </MenuItem>
      {R.map((item) => {
        return <MenuItem value={item.email}>{item.fullName}</MenuItem>;
      }, selectList)}
    </Select>
  );
};

export default TeacherDropDown;
