import { Box, FormControl, InputLabel, List, ListItemText, MenuItem, Select, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { useEffect, useState } from "react";
import * as R from "ramda";

const useStyles = makeStyles((theme) => ({
    topicBox: {
        marginBottom: "1rem",
    },
    inputFormControl: {
        maxWidth: '550px',
        minWidth: '200px',
        marginBottom: "1rem",
    },
    subTopicsDisplay: {
        marginLeft: "1rem",
        "&.MuiList-root": {
            maxHeight: "none"
        }
    },
    topicList: {
        "&.MuiList-root": {
            maxHeight: "none"
        }
    },
    inputLabel1: {
        top: "-17px",
        left: "-14px",
    },
    topicsDiv: {
        border: "1px solid #898EAC",
        background: "#898eac2e", 
        boxShadow: "none", width: "100%",
        padding: "1rem", borderRadius: 4 
    }
}));

const getTopics = (topics) => {
    let options = [];
    topics.forEach(topic => {
        options = [...options, {
            ...topic,
            isDisabled: true
        }, ...topic.subTopics]
    });
    return options;
}

const getAllSubTopics = (topics) => {
    let allSubTopics = [];
    topics.forEach(item => {
        allSubTopics = [...allSubTopics, ...item.subTopics]
    });
    return allSubTopics;
}

const getFormattedTopics = (selectedTopics, topics, allSubTopics) => {
    let finalArr = [];
    const selectedSubTopics = R.filter(item => R.includes(item.id, selectedTopics))(allSubTopics);
    selectedSubTopics.forEach(item => {
        const { id, name, sortOrder } = item;
        const topicObjIndex = R.findIndex(topic => topic.id === item.topicId)(finalArr);
        if (topicObjIndex >= 0) {
            let topicObj = finalArr[topicObjIndex];
            const prevSubTopics = topicObj.subTopics;
            finalArr[topicObjIndex] = R.assocPath(["subTopics"], [...prevSubTopics, {
                subTopicId: id,
                subTopicName: name,
                sortOrder
            }], topicObj);
        } else {
            let topicObj = R.find(topic => topic.id === item.topicId)(topics);
            ;
            finalArr = [...finalArr, R.assocPath(["subTopics"], [{
                subTopicId: id,
                subTopicName: name,
                sortOrder
            }], topicObj)];
        }
    });
    return R.map(item => {
        const { id, name, subTopics, sortOrder } = item;
        return {
            topicId: id,
            topicName: name,
            subTopics,
            sortOrder
        }
    })(finalArr);
};

const getSelectedTopicIds = (topics) => {
    let selectedTopics = [];
    topics.forEach(item => {
        selectedTopics = [...selectedTopics, ...item.subTopics];
    });

    return R.map(item => item.subTopicId)(selectedTopics);
}

export const DisplatReadOnlyTopics = ({ topics }) => {
    const classes = useStyles();
    return (
        <Box className={classes.topicsDiv}>
            <Typography variant="h6" color="primary">Topics</Typography>
            <List className={classes.topicList}>
                {
                    R.map((item) => {
                        return (
                            <Box>
                                <ListItemText>{item.topicName}</ListItemText>
                                <List className={classes.subTopicsDisplay}>
                                    {
                                        R.map((subItem) => {
                                            return <ListItemText>- {subItem.subTopicName}</ListItemText>
                                        })(item.subTopics)
                                    }
                                </List>
                            </Box>
                        )
                    })(topics)
                }
            </List>
        </Box>
    )
}
const DisplayTopics = ({ topicsList, allSubTopics, topics }) => {
    const classes = useStyles();
    return (
        <Box sx={{marginTop:"1rem", width:"100%", overflowWrap: "break-word"}} className={classes.topicsDiv}>
            <Typography variant="h6">Topics</Typography>
            <List className={classes.topicList}>
                {
                    R.map((item) => {
                        return (
                            <Box>
                                <ListItemText>{item.sortOrder} - {item.topicName}</ListItemText>
                                <List className={classes.subTopicsDisplay}>
                                    {
                                        R.map((subItem) => {
                                            return <ListItemText>{subItem.sortOrder} - {subItem.subTopicName}</ListItemText>
                                        })(item.subTopics)
                                    }
                                </List>

                            </Box>
                        )
                    })(getFormattedTopics(topicsList, topics, allSubTopics))
                }
            </List>
        </Box>
    )
}
const TopicsDropDown = ({
    topics,
    setFormData,
    selectedTopics = []
}) => {
    const allSubTopics = getAllSubTopics(topics);
    const [topicsList, setTopicsList] = useState(getSelectedTopicIds(selectedTopics));
    const classes = useStyles();

    useEffect(() => {
        setTopicsList(getSelectedTopicIds(selectedTopics))
    }, [selectedTopics])

    const onSubTopicChange = (e) => {
        setTopicsList(e.target.value);
        const finalOutPut = getFormattedTopics(e.target.value, topics, allSubTopics);
        setFormData(finalOutPut);
    };

    return (
        <Box className={classes.topicBox}>
            <Typography variant="h6">Topics</Typography>
            <FormControl variant="outlined" style={{ marginTop: "2rem" }}>
                <InputLabel className={classes.inputLabel1} htmlFor="grouped-select">Topics</InputLabel>
                <Select
                    margin="normal"
                    variant="outlined"
                    defaultValue=""
                    id="grouped-select"
                    placeholder="Enter Topics"
                    onChange={onSubTopicChange}
                    multiple
                    value={topicsList}
                >
                    <MenuItem value="">
                        <em>None</em>
                    </MenuItem>
                    {
                        R.map(item => {
                            return (
                                <MenuItem
                                    disabled={item.isDisabled}
                                    value={item.id}
                                >{item.name}</MenuItem>
                            )
                        })(getTopics(topics))
                    }
                </Select>
            </FormControl>
            <DisplayTopics topicsList={topicsList} allSubTopics={allSubTopics} topics={topics} />
        </Box>
    );
};

export default TopicsDropDown;