import React, { useState, useCallback } from "react";
import { TextField, InputAdornment, colors } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import debounce from "lodash.debounce";

const useStyles = makeStyles((theme) => ({
  searchIcon: {
    color: colors.gray3,
  },
}));

const SearchInput = ({ getList }) => {
  const classes = useStyles();
  const [value, setValue] = useState("");

  const debouncedGetList = useCallback(debounce(getList, 1000), []);

  const handleChange = (event) => {
    const { value: nextValue } = event.target;
    setValue(nextValue);
    debouncedGetList(nextValue);
  };
  return (
    <TextField
      variant="outlined"
      id="input-with-icon-textfield"
      placeholder={"Enter search term"}
      value={value}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <SearchIcon className={classes.searchIcon} />
          </InputAdornment>
        ),
      }}
      onChange={handleChange}
    />
  );
};

export default SearchInput;
