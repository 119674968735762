import React, { useCallback } from "react";
import {
  Button,
  Grid,
  makeStyles,
  Typography,
  colors,
} from "@material-ui/core";
import { getPageList } from "components/listOfValues/utils";
import { useDispatch, useSelector } from "react-redux";
import { getPageListItems, setPageListItems } from "state/slices/pageSlice";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import SearchInput from "common/components/SearchInput/SearchInput";
import * as R from "ramda";

const useStyles = makeStyles((theme) => ({
  container: {
    margin: "0",
    marginBottom: "51px",
    width: "100%",
  },
  searchIcon: {
    color: colors.gray3,
  },
  paddingNone: {
    alignItems: "center",
    padding: "0px !important",
    display: "flex",
    justifyContent: "space-between",
  },
  title: {
    fontStyle: "normal",
    fontWeight: "800",
    fontSize: "24px",
    color: "#263657",
  },
}));

function StaffHeader({ privileges, setModalState, page, setFilterState }) {
  const onAddClick = () => {
    setModalState((prev) => ({ ...prev, isOpen: true }));
  };
  const pageFilter = useSelector((state) =>
    R.path(["globalForm", "pageFilters"], state)
  );

  const classes = useStyles();
  const pageData = useSelector((state) => R.path(["pages", page], state));
  const dispatch = useDispatch();
  // const debouncedGetList = useCallback(debounce(getList, 1000), []);

  const onSearch = useCallback(
    async (searchKey = "") => {
      setFilterState((prev) => ({ ...prev, searchKey: searchKey }));
    },
    [setFilterState]
  );

  return (
    <Grid
      container
      spacing={3}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      className={classes.container}
    >
      <Grid item xs={12} md={6} className={classes.paddingNone}>
        <Typography className={classes.title} variant="div">
          <span>Staff</span>
        </Typography>
      </Grid>
      <Grid
        item
        container
        spacing={3}
        md={6}
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        className={classes.paddingNone}
      >
        <Grid item xs={12} md={8} className={classes.changeView}>
          <SearchInput getList={onSearch} className={classes.searchInput} />
        </Grid>

        <Grid item xs={12} md={4} className={classes.addNewPlan}>
          {R.propOr(true, "CREATE", privileges) && (
            <Button
              variant="contained"
              color="primary"
              className={classes.addIconButton}
              onClick={onAddClick}
              startIcon={<AddCircleIcon className="addIcon" />}
            >
              Add Staff
            </Button>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default StaffHeader;
