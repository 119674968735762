import {
  Card,
  CardContent,
  Divider,
  makeStyles,
  Typography,
  Box,
} from "@material-ui/core";
import { FILE_ICON } from "asset/images";

import React, { useState } from "react";
import MoreVerticalDot from "../../../academicPlanDetails/MoreVerticalDot";
import EditIcon from "@mui/icons-material/Edit";
import * as R from "ramda";
import ManageSchoolModal from "../Schools/ManageSchoolModal";
import { deepPurple } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  cardBody: {
    width: "100%",
    background: "#FFFFFF",
    borderRadius: "14px !important",
  },
  cardContent: {
    padding: "26px 18px !important",
  },
  cardTitle: {
    fontStyle: "normal",
    fontWeight: "800",
    fontSize: "18px",
    lineHeight: "22px",
    color: "#263657",
    display: "flex",
    alignItems: "center",
  },
  fileIcon: {
    marginRight: "10px",
  },
  cardTitleWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  subTitle: {
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "8px",
    lineHeight: "10px",
    color: "#898EAC",
  },
  cardBodyContent: {
    marginTop: "18px",
  },
  bodyText: {
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "12px",
    lineHeight: "14px",
    color: "#000000",
  },
  dotStyle: {
    width: "3.86px",
    height: "3.86px",
    background: "#898EAC",
    borderRadius: "50%",
    display: "inline-block",
    verticalAlign: "middle",
    margin: "auto 7px",
  },
  inputFormControl: {
    margin: "1.5rem",
  },
  flexGrid: {
    "&.MuiGrid-root": {
      display: "flex",
    },
  },
  inputLabel1: {
    top: "-17px",
    left: "-14px",
  },
  inputLabel: {
    top: "-17px",
  },
  placeholderValue: {
    color: "#bbb",
  },
  avatar: {
    background: deepPurple[500],
    height: "24px",
    width: "24px",
    marginRight: "10px",
    fontSize: "16px",
  },
  cardBottom: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  createdBy: {
    display: "flex",
    flexDirection: "column",
  },
  createdBySection: {
    display: "flex",
    marginTop: "18px",
  },
  statusBlock: {
    marginTop: "18px",
  },
  modifyIcons: {
    width: "65px",
    textAlign: "end",
  },
}));

function ClassesSideCard({
  data,
  privileges,
  userRoles,
  getPlanDetails,
  isDetailedPage,
}) {
  const classes = useStyles();
  const [modalState, setModalState] = useState({
    isOpen: false,
    data: null,
    isEdit: false,
  });
  return (
    <>
      <Box
        sx={{ minWidth: 275 }}
        // onClick={() => !isDetailedPage && handleRedirect(data?.id)}
        style={{ cursor: "pointer" }}
      >
        <Card
          variant="outlined"
          className={classes.cardBody}
          style={{ background: isDetailedPage ? "#E3D5CA" : "#FFF" }}
        >
          <CardContent className={classes.cardContent}>
            <div className={classes.cardTitleWrapper}>
              <Typography className={classes.cardTitle} variant="div">
                <img
                  className={classes.fileIcon}
                  src={FILE_ICON}
                  alt="File Icon"
                />
                <span>
                  {data.schoolName || ""}-{data.campus || ""}
                </span>
              </Typography>
              {privileges?.UPDATE && (
                <div className={classes.verticalDotsButton}>
                  <MoreVerticalDot
                    options={[
                      {
                        icon: (
                          <EditIcon
                            fontSize="small"
                            className={classes.editIcon}
                          />
                        ),
                        label: "Manage School",
                        value: "Manage School",
                      },
                    ]}
                    handleClick={(e) => {
                      e.stopPropagation();
                      setModalState((prev) => ({
                        ...prev,
                        isOpen: true,
                        isEdit: true,
                        data,
                      }));
                    }}
                    background={"#fff"}
                  />
                </div>
              )}
            </div>

            <div className={classes.cardBodyContent}>
              <Typography className={classes.bodyText} variant="div">
                Affiliated Board:{" "}
                <Typography className={classes.bodySubText} variant="div">
                  {R.propOr("", "affiliatedBoard", data)}
                </Typography>
              </Typography>
            </div>
            <div>
              <Typography className={classes.bodyText} variant="span">
                School Code:{" "}
                <Typography className={classes.bodySubText} variant="div">
                  {R.propOr("", "schoolCode", data)}
                </Typography>
              </Typography>
            </div>
          </CardContent>
        </Card>
      </Box>
      {modalState.isOpen && (
        <ManageSchoolModal
          userRoles={userRoles}
          modalState={modalState}
          setModalState={setModalState}
          getPlanDetails={getPlanDetails}
        />
      )}
    </>
  );
}

export default ClassesSideCard;
