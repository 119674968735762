import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  makeStyles,
} from "@material-ui/core";
import CancelIcon from "@mui/icons-material/Cancel";
import LOVSelect from "common/components/LOVSelect/LOVSelect";
import {
  LOVApiKeys,
  LOVDataKeys,
  LOVKeyMap,
  LOVSelectKeys,
} from "common/components/SelectDropDown/SelectDropDownUtils";
import { removeWhiteSpace } from "common/utils/composition";
import { useEffect, useState } from "react";

const useStyles = makeStyles((theme) => ({
  inputFormControl: {
    margin: "1.5rem",
  },
  flexGrid: {
    "&.MuiGrid-root": {
      display: "flex",
    },
  },
  inputLabel1: {
    top: "-17px",
    left: "-14px",
  },
  inputLabel: {
    top: "-17px",
  },
  placeholderValue: {
    color: "#bbb",
  },
  filterHeader: {
    fontWeight: 700,
    fontSize: "18px",
    lineHeight: "22px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    margin: "1.5rem",
    [theme.breakpoints.down("md")]: {
      marginTop: "0",
    },
  },
}));

function FilterSidebar({ filterState, setFilterState, planName }) {
  const classes = useStyles();
  const [selectedClass, setSelectedClass] = useState(null);

  useEffect(() => {
    !filterState?.gradeName && setSelectedClass(null);
  }, [filterState?.gradeName]);

  const handleChange = (check, value) => {
    setFilterState((prev) => ({
      ...prev,
      [check]: value,
    }));
  };

  const handleClearFilter = () => {
    setFilterState({
      annualPeriod: "",
      status: "",
      gradeName: "",
      subject: "",
    });
  };

  return (
    <Typography className={classes.filterWrapper} variant="div">
      <Grid container>
        <Typography className={classes.filterHeader} variant="div">
          <div>Filter</div>
          <Button
            variant="contained"
            color="primary"
            className={classes.addIconButton}
            onClick={handleClearFilter}
            startIcon={<CancelIcon className="addIcon" />}
            disabled={
              !filterState?.annualPeriod &&
              !filterState?.status &&
              !filterState?.gradeName &&
              !filterState?.subject
            }
          >
            Clear all
          </Button>
        </Typography>
        <FormControl variant="outlined" className={classes.inputFormControl}>
          <InputLabel
            className={classes.inputLabel1}
            id="demo-simple-select-outlined-label"
          >
            Annual Period
          </InputLabel>
          <LOVSelect
            margin="normal"
            formType="pageFilters"
            selectKey={LOVSelectKeys.ANNUAL_PERIOD}
            dataKey={LOVDataKeys.ANNUAL_PERIOD}
            lovKey={LOVKeyMap.ANNUAL_PERIOD}
            value={filterState?.annualPeriod || ""}
            customOnChange={(value) => handleChange("annualPeriod", value)}
          />
        </FormControl>
        <FormControl variant="outlined" className={classes.inputFormControl}>
          <InputLabel
            className={classes.inputLabel1}
            id="demo-simple-select-outlined-label"
          >
            Status
          </InputLabel>
          <Select
            variant="outlined"
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            value={filterState?.status || ""}
            onChange={(e) => handleChange("status", e.target.value)}
            placeholder="Status"
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
          >
            <MenuItem value="" disabled>
              <span className={classes.placeholderValue}>Select status</span>
            </MenuItem>
            <MenuItem value={"ACTIVE"}>Active</MenuItem>
            <MenuItem value={"COMPLETED"}>Completed</MenuItem>
            <MenuItem value={"INACTIVE"}>Inactive</MenuItem>
          </Select>
        </FormControl>
        <FormControl variant="outlined" className={classes.inputFormControl}>
          <InputLabel
            className={classes.inputLabel1}
            id="demo-simple-select-outlined-label"
          >
            Class Name
          </InputLabel>
          <LOVSelect
            margin="normal"
            selectKey={LOVSelectKeys.CLASS}
            dataKey={LOVDataKeys.CLASS}
            lovKey={LOVApiKeys.CLASS}
            value={selectedClass || ""}
            setObj={true}
            customOnChange={(data) => {
              console.log(data);
              handleChange("gradeName", data.value);
              setSelectedClass(data);
            }}
          />
        </FormControl>
        <FormControl variant="outlined" className={classes.inputFormControl}>
          <InputLabel
            className={classes.inputLabel1}
            id="demo-simple-select-outlined-label"
          >
            Subject
          </InputLabel>
          <LOVSelect
            margin="normal"
            selectKey={LOVSelectKeys.SUBJECT}
            dataKey={LOVDataKeys.SUBJECT}
            lovKey={`SubjectClass${removeWhiteSpace(selectedClass?.name)}`}
            value={filterState?.subject || ""}
            customOnChange={(value) => handleChange("subject", value)}
          />
        </FormControl>
        {planName === "TIER_PLAN" && (
          <FormControl variant="outlined" className={classes.inputFormControl}>
            <InputLabel
              className={classes.inputLabel1}
              id="demo-simple-select-outlined-label"
            >
              Tier Plan
            </InputLabel>
            <LOVSelect
              margin="normal"
              selectKey={["tierPlan"]}
              dataKey={LOVDataKeys.TIER_PLAN}
              lovKey={LOVKeyMap.TIER_PLAN}
              value={filterState?.tierPlan || ""}
              customOnChange={(value) => handleChange("tierPlan", value)}
            />
          </FormControl>
        )}
      </Grid>
    </Typography>
  );
}

export default FilterSidebar;
