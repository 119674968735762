import {
  Box,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { AddCircleOutline, RemoveCircleOutline } from "@material-ui/icons";
import {
  LOVKeyMap,
  LOVSelectKeys,
} from "common/components/SelectDropDown/SelectDropDownUtils";
import { mapIndexed } from "common/utils/composition";
import { FORM_TYPES } from "common/utils/formUtility";
import { getPageList } from "components/listOfValues/utils";
import * as R from "ramda";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateForm } from "state/slices/globalFormSlice";
import { PAGES, setPageListItems } from "state/slices/pageSlice";
import TLMReferenceForm from "./TLMReferenceForm";

export const TLMRowState = {
  sequenceOfTla: "",
  time: "",
  tlmName: "",
  tlmList: [],
  tlmReference: "",
  studentActivity: "",
};

const getTLMList = (TLMData) => {
  if (R.isEmpty(TLMData)) {
    return [TLMRowState];
  }
  return TLMData;
};

const TLMSequenceReferenceForm = ({
  TLMData,
  setTLMData,
  isReadOnly = false,
  isPlanSummary = false,
}) => {
  const lessonPlanFormData = useSelector((state) =>
    R.pathOr({}, ["globalForm", "LessonPlanForm"], state)
  );
  const mainContents = useSelector((state) =>
    isPlanSummary
      ? TLMData
      : R.pathOr(
          [TLMRowState],
          ["globalForm", "LessonPlanForm", "mainContents"],
          state
        )
  );
  const LOVList = useSelector((state) => state.pages.listOfValues.items);

  const [selectList, setSelectList] = useState([]);

  const [introList, setIntroList] = useState([]);

  const dispatch = useDispatch();

  const [TLMList, setTLMList] = useState(getTLMList(mainContents));

  const onChange = (e, index) => {
    const [value, name] = R.paths(
      [
        ["target", "value"],
        ["target", "name"],
      ],
      e
    );
    setTLMList(R.assocPath([index, name], value, TLMList));
    dispatch(
      updateForm({
        formType: FORM_TYPES.LESSON_PLAN_FORM,
        formData: R.assocPath(
          ["mainContents"],
          R.assocPath([index, name], value, TLMList),
          lessonPlanFormData
        ),
      })
    );
  };

  const tlmChange = (event, index) => {
    const value = R.path(["target", "value"], event);
    setTLMList(R.assocPath([index, "tlmName"], value, TLMList));
    dispatch(
      updateForm({
        formType: FORM_TYPES.LESSON_PLAN_FORM,
        formData: R.assocPath(
          ["mainContents"],
          R.assocPath([index, "tlmName"], value, TLMList),
          lessonPlanFormData
        ),
      })
    );
  };

  const onIntroActivityChange = (event, index) => {
    const value = R.path(["target", "value"], event);
    setTLMList(R.assocPath([index, "introductionActivity"], value, TLMList));
    dispatch(
      updateForm({
        formType: FORM_TYPES.LESSON_PLAN_FORM,
        formData: R.assocPath(
          ["mainContents"],
          R.assocPath([index, "introductionActivity"], value, TLMList),
          lessonPlanFormData
        ),
      })
    );
  };

  const getIntroLOVList = useCallback(async () => {
    let filteredLOV = [];
    if (R.isEmpty(LOVList)) {
      const lovListData = await getPageList({
        searchKey: "",
        page: PAGES.listOfValues,
        pageData: { pageSize: 50, currentPage: 0 },
      });
      dispatch(
        setPageListItems({
          page: PAGES.listOfValues,
          itemList: lovListData.content,
        })
      );
      filteredLOV = lovListData.content.filter((lovItem) => {
        return lovItem.name === LOVKeyMap.INTRO_ACTIVITIES;
      })[0];
    } else {
      filteredLOV = LOVList.filter((lovItem) => {
        return lovItem.name === LOVKeyMap.INTRO_ACTIVITIES;
      })[0];
    }

    let itemList = R.propOr([], "items", filteredLOV);

    setIntroList(itemList);
  }, [LOVList, dispatch]);

  const getLOVList = useCallback(async () => {
    let filteredLOV = [];
    if (R.isEmpty(LOVList)) {
      const lovListData = await getPageList({
        searchKey: "",
        page: PAGES.listOfValues,
        pageData: { pageSize: 50, currentPage: 0 },
      });
      dispatch(
        setPageListItems({
          page: PAGES.listOfValues,
          itemList: lovListData.content,
        })
      );
      filteredLOV = lovListData.content.filter((lovItem) => {
        return lovItem.name === LOVKeyMap.TLM;
      })[0];
    } else {
      filteredLOV = LOVList.filter((lovItem) => {
        return lovItem.name === LOVKeyMap.TLM;
      })[0];
    }

    let itemList = R.propOr([], "items", filteredLOV);

    setSelectList(itemList);
  }, [LOVList, dispatch]);

  const removeTLMEntry = (index) => {
    let filtered = R.remove(index, 1, TLMList);
    setTLMList(filtered);
  };

  useEffect(() => {
    setTLMData?.(TLMList);
  }, [JSON.stringify(TLMList)]);

  useEffect(() => {
    getLOVList();
    getIntroLOVList();
  }, [getLOVList, getIntroLOVList]);

  // useEffect(() => {
  //   setTLMList(getTLMList(mainContents));
  // }, [mainContents]);

  const onAddNewRow = () => {
    setTLMList(R.append(TLMRowState, TLMList));
  };

  const onTlmDataChange = (data, index) => {
    const updatedTlmList = [...TLMList];
    updatedTlmList[index].tlmList = data;
    setTLMList(updatedTlmList);
  };

  return (
    <Box>
      {mapIndexed((tlmItem, index) => {
        return (
          <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
              <InputLabel>Sequence</InputLabel>
              <TextField
                variant="outlined"
                margin="normal"
                type="text"
                id="multiline-static"
                placeholder="Enter Sequence"
                InputLabelProps={{ shrink: true }}
                value={tlmItem.sequenceOfTla}
                fullWidth
                multiline
                maxRows={4}
                name={"sequenceOfTla"}
                onChange={(e) => onChange(e, index)}
                disabled={isReadOnly}
              />
            </Grid>
            <Grid item xs={12} md={9}>
              <InputLabel>Introduction Activity</InputLabel>
              <FormControl fullWidth style={{ marginTop: "0.5rem" }}>
                <Select
                  variant="outlined"
                  margin="normal"
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={tlmItem.introductionActivity}
                  placeholder={LOVSelectKeys.INTRO_ACTIVITIES}
                  name={LOVSelectKeys.INTRO_ACTIVITIES}
                  onChange={(e) => onIntroActivityChange(e, index)}
                  fullWidth
                  disabled={isReadOnly}
                >
                  <MenuItem value="" disabled>
                    <em>None</em>
                  </MenuItem>
                  {R.map((item) => {
                    return <MenuItem value={item.name}>{item.name}</MenuItem>;
                  }, introList)}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel>Student Activities</InputLabel>
              <TextField
                variant="outlined"
                margin="normal"
                id="multiline-static"
                placeholder="Enter Student Activities"
                value={tlmItem.studentActivity}
                fullWidth
                InputLabelProps={{ shrink: true }}
                multiline
                maxRows={4}
                name={"studentActivity"}
                onChange={(e) => onChange(e, index)}
                disabled={isReadOnly}
              />
            </Grid>
            <Grid item xs={12} md={9}>
              <TLMReferenceForm
                displayLOVSelect={true}
                TLMData={tlmItem.tlmList}
                setTLMData={(data) => onTlmDataChange(data, index)}
                planSummary={true}
                isReadOnly={isReadOnly}
              />
            </Grid>
            <Grid item xs={2} md={1} style={{ alignSelf: "center" }}>
              {index === TLMList.length - 1 ? (
                <IconButton color="primary" onClick={onAddNewRow}>
                  <AddCircleOutline className="addIcon" />
                </IconButton>
              ) : (
                <IconButton
                  color="primary"
                  onClick={() => removeTLMEntry(index)}
                >
                  <RemoveCircleOutline className="addIcon" />
                </IconButton>
              )}
            </Grid>
          </Grid>
        );
      })(TLMList)}
    </Box>
  );
};

export default TLMSequenceReferenceForm;
