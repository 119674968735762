import { Grid, makeStyles } from "@material-ui/core";
import { getPageList } from "components/listOfValues/utils";
import React, { useEffect, useState } from "react";
import { PAGES } from "state/slices/pageSlice";
import { Pagination } from "@material-ui/lab";
import StaffModal from "./StaffModal";
import withPrivilege from "common/hocs/withPrivilege";
import { EntityMap } from "util/roleUtils";
import StaffHeader from "./StaffHeader";
import StaffCard from "./StaffCard";
import { CircularProgress } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  cardWrapper: {
    flexWrap: "wrap",
  },
  annualPlanCard: {
    marginBottom: "30px",
    [theme.breakpoints.down("md")]: {
      marginBottom: 0,
    },
  },
  paginationContainer: {
    [theme.breakpoints.down("md")]: {
      marginTop: "30px",
    },
    display: "flex",
    justifyContent: "end",
  },
  noDataAvailable: {
    margin: "20px",
  },
}));

function StaffPlans({
  privileges,
  userRoles,
  setModalState,
  modalState,
  filterState,
  setFilterState,
  planName,
}) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState({
    totalPage: 0,
    pageSize: 10,
    data: [],
    currentPage: 0,
  });

  useEffect(() => {
    if (state?.currentPage !== 0) {
      setState((prev) => ({
        ...prev,
        currentPage: 0,
      }));
    }
    getStaffList({
      pageSize: state.pageSize,
      currentPage: 0,
      searchKey: filterState.searchKey,
    });
  }, [filterState]);

  const getStaffList = async (pageData) => {
    const { pageSize = 10, currentPage = 0, searchKey = "" } = pageData;
    setIsLoading(true);
    const pageListData = await getPageList({
      searchKey: searchKey,
      page: PAGES.teachers,
      pageData: { pageSize, currentPage },
    });
    setIsLoading(false);
    setState((prev) => ({
      ...prev,
      data: pageListData?.content || [],
      totalPage: pageListData?.totalPages || 0,
    }));
  };

  const onPageChange = (_, page) => {
    setState((prev) => ({
      ...prev,
      currentPage: page - 1,
    }));
    getStaffList({
      pageSize: state.pageSize,
      currentPage: page - 1,
      searchKey: filterState.searchKey,
    });
  };

  return (
    <>
      <StaffHeader
        changeView={planName}
        privileges={privileges.teachers}
        setModalState={setModalState}
        setFilterState={setFilterState}
      />
      <Grid
        container
        direction="row"
        className={classes.cardWrapper}
        spacing={4}
      >
        {isLoading ? (
          <CircularProgress />
        ) : state?.data?.length ? (
          state?.data?.map((plan, index) => (
            <Grid
              item
              className={classes.annualPlanCard}
              key={index}
              md="6"
              xs="12"
            >
              <StaffCard
                data={plan}
                privileges={privileges}
                userRoles={userRoles}
                getStaffList={getStaffList}
                setModalState={setModalState}
                state={state}
                filterState={filterState}
              />
            </Grid>
          ))
        ) : (
          <div className={classes.noDataAvailable}>No Data available</div>
        )}
      </Grid>
      <Grid item xs={12} className={classes.paginationContainer}>
        <Pagination
          count={state?.totalPage || 0}
          color="primary"
          onChange={onPageChange}
          page={(state?.currentPage || 0) + 1}
        />
      </Grid>
      {modalState?.isOpen && (
        <StaffModal
          userRoles={userRoles}
          modalState={modalState}
          setModalState={setModalState}
          getStaffList={getStaffList}
        />
      )}
    </>
  );
}

export default withPrivilege(StaffPlans, EntityMap.teacher, EntityMap.role);
