import React from "react";
import { PAGES } from "state/slices/pageSlice";
import GridViewWrapper from "common/wrappers/GridViewWrapper";
import { LOVPayloadSelector } from "./utils";
import withPrivilege from "common/hocs/withPrivilege";
import { EntityMap } from "util/roleUtils";

const ListOfValues = ({ privileges }) => {
  return (
    <GridViewWrapper
      paginatedListRenderer="lov"
      page={PAGES.listOfValues}
      formDialogConfig={{
        formType: "LOVForm",
        formRenderer: "LOVForm",
        title: "List",
      }}
      payloadSelector={LOVPayloadSelector}
      privileges={privileges}
    />
  );
};

export default withPrivilege(ListOfValues, EntityMap.lov);
