import React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Link, makeStyles } from "@material-ui/core";
import EditIcon from "@mui/icons-material/Edit";
import { ALTERNATE_FILE_ICON, CHECK_ICON } from "asset/images";
import moment from "moment";
import { GLOBAL_DATE_FORMAT } from "constants/PageConstants";
import MoreVerticalDot from "../MoreVerticalDot";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  cardBody: {
    width: "100%",
    background: "#FFFFFF",
    borderRadius: "14px !important",
    height: "170px",
  },
  cardContent: {
    padding: "23px 18px !important",
  },
  cardTitle: {
    position: "relative",
    fontStyle: "normal",
    fontWeight: "800",
    fontSize: "18px",
    lineHeight: "22px",
    color: "#263657",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  fileIcon: {
    marginRight: "10px",
  },
  cardTitleWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  subTitle: {
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "8px",
    lineHeight: "10px",
    color: "#898EAC",
  },
  topicSection: {
    width: "100%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  bodyText: {
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "12px",
    lineHeight: "14px",
    color: "#000000",
  },
  dotStyle: {
    width: "3.86px",
    height: "3.86px",
    background: "#898EAC",
    borderRadius: "50%",
    display: "inline-block",
    verticalAlign: "middle",
    margin: "auto 7px",
  },
  cardBottom: {
    display: "flex",
    marginTop: "14px",
  },
  createdBy: {
    display: "flex",
    flexDirection: "column",
  },
  editIcon: {
    marginRight: "10px",
  },
  verticalDotsButton: {
    position: "absolute",
    right: "0px",
  },
  verticalDots: {
    margin: "0 6px",
  },
  viewMore: {
    fontWeight: 700,
    fontSize: "10px",
    color: "#898EAC",
  },
  cardBodyContent: {
    height: "48px",
  },
}));

export default function TierPlanCard({
  data,
  userRoles,
  privileges,
  setModalState,
  hideModification,
  isDetailedPage,
}) {
  const history = useHistory();
  const classes = useStyles();
  const AllowedRoles = ["Administrator"];

  const handleRedirect = () => {
    !isDetailedPage && history.push(`/tier-plan/${data?.id}`);
  };

  const isEditEnabled = (roles) => {
    let editEnabled = false;
    roles.forEach((role) => {
      if (AllowedRoles.includes(role)) {
        editEnabled = true;
      }
    });
    return editEnabled;
  };

  const isEditable = isEditEnabled(userRoles);

  return (
    <Box
      sx={{ minWidth: 275 }}
      style={{ cursor: "pointer" }}
      onClick={handleRedirect}
    >
      <Card variant="outlined" className={classes.cardBody} style={{ background: isDetailedPage ? '#E3D5CA' : '#FFF'}}>
        <CardContent className={classes.cardContent}>
          <div className={classes.cardTitleWrapper}>
            <Typography className={classes.cardTitle} variant="div">
              <div>
                <img
                  className={classes.fileIcon}
                  src={ALTERNATE_FILE_ICON}
                  alt="File Icon"
                />
                <span>{data?.name || "-"}</span>
              </div>
              {privileges?.UPDATE && !hideModification && (
                <div className={classes.verticalDotsButton}>
                  <MoreVerticalDot
                    options={[
                      {
                        icon: (
                          <EditIcon
                            fontSize="small"
                            className={classes.editIcon}
                          />
                        ),
                        label: "Manage Tier Plan",
                        value: "Manage Tier Plan",
                        isDisabled: data?.status?.toUpperCase() === "COMPLETED" ? !isEditable : !privileges?.UPDATE,
                      },
                    ]}
                    handleClick={(e) => {
                      e.stopPropagation();
                      setModalState((prev) => ({
                        ...prev,
                        isOpen: true,
                        isEdit: true,
                        data,
                      }));
                    }}
                  />
                </div>
              )}
            </Typography>
          </div>
          <Typography
            className={classes.subTitle}
            marginLeft={"25px"}
            variant="span"
          >
            Start Date:{" "}
            {data?.start ? moment(data.start).format(GLOBAL_DATE_FORMAT) : "-"}{" "}
            | End Date:{" "}
            {data?.end ? moment(data.end).format(GLOBAL_DATE_FORMAT) : "-"}
          </Typography>
          <div className={classes.cardBodyContent}>
            {data?.topics?.length
              ? data?.topics?.slice(0, 2).map((topic) => (
                  <div key={topic?.id} className={classes.topicSection}>
                    <img
                      className={classes.fileIcon}
                      src={CHECK_ICON}
                      alt="Check Icon"
                    />
                    <Typography
                      className={classes.bodyText}
                      variant="span"
                      marginRight={"34px"}
                    >
                      {topic?.name || "-"}
                    </Typography>
                  </div>
                ))
              : ""}
          </div>
          {!isDetailedPage && <div className={classes.cardBottom}>
            <Link
              component="button"
              variant="body2"
              className={classes.viewMore}
            >
              {"> View more"}
            </Link>
          </div>}
        </CardContent>
      </Card>
    </Box>
  );
}
