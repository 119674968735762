import React, { useCallback } from "react";
import {
  FormControl,
  InputLabel,
  makeStyles,
  TextField,
} from "@material-ui/core";
import * as R from "ramda";
import { LOVSelectKeys } from "common/components/SelectDropDown/SelectDropDownUtils";
import TeacherDropDown from "common/components/TeacherDropDown/TeacherDropDown";
const useStyles = makeStyles((theme) => ({
  inputFormControl: {
    marginBottom: "2rem",
  },
  inputLabel1: {
    top: "-17px",
    left: "-14px",
  },
  inputLabel: {
    top: "-17px",
  },
}));

const ManageClassSectionForm = ({ state, handleChange, isEditForm }) => {
  const classes = useStyles();

  const onChange = (event) => {
    const [value, name] = R.paths(
      [
        ["target", "value"],
        ["target", "name"],
      ],
      event
    );
    handleChange(name, value);
  };

  const handleClassTeacherChange = useCallback(
    (teacherObj) => {
      handleChange("classTeacher", teacherObj);
    },
    [handleChange]
  );

  return (
    <form style={{ marginTop: "1.5rem" }}>
      <FormControl variant="outlined" className={classes.inputFormControl}>
        <InputLabel
          className={classes.inputLabel1}
          id="demo-simple-select-outlined-label"
        >
          School
        </InputLabel>
        <TextField
          variant="outlined"
          margin="normal"
          name="school"
          inputProps={{ readOnly: true }}
          placeholder="Enter school"
          value={
            isEditForm
              ? state?.grade?.school?.schoolCampus || ""
              : state?.school?.schoolCampus || ""
          }
          onChange={onChange}
        />
      </FormControl>
      <FormControl variant="outlined" className={classes.inputFormControl}>
        <InputLabel
          className={classes.inputLabel1}
          id="demo-simple-select-outlined-label"
        >
          Class Name
        </InputLabel>
        <TextField
          variant="outlined"
          margin="normal"
          name="capacity"
          placeholder="Enter class name"
          value={
            isEditForm ? state?.grade?.gradeName || "" : state?.gradeName || ""
          }
          inputProps={{ readOnly: true }}
          onChange={onChange}
        />
      </FormControl>
      <FormControl className={classes.inputFormControl}>
        <InputLabel
          className={classes.inputLabel}
          id="demo-simple-select-outlined-label"
        >
          Section
        </InputLabel>
        <TextField
          variant="outlined"
          margin="normal"
          name="section"
          inputProps={{ readOnly: isEditForm }}
          placeholder="Enter section"
          value={state?.sectionName}
          onChange={onChange}
        />
      </FormControl>
      <FormControl className={classes.inputFormControl}>
        <InputLabel
          className={classes.inputLabel}
          id="demo-simple-select-outlined-label"
        >
          Student Capacity
        </InputLabel>
        <TextField
          variant="outlined"
          type="number"
          margin="normal"
          name="capacity"
          placeholder="Enter student capacity"
          value={state?.capacity}
          onChange={onChange}
        />
      </FormControl>
      <FormControl variant="outlined">
        <InputLabel
          className={classes.inputLabel1}
          id="demo-simple-select-outlined-label"
        >
          Class Teacher
        </InputLabel>
        <TeacherDropDown
          customOnChange={handleClassTeacherChange}
          selectKey={LOVSelectKeys.CLASS_TEACHER}
          selectedValue={state?.classTeacher?.email || ""}
          schoolCode={
            isEditForm
              ? state?.grade?.school?.schoolCode
              : state?.school?.schoolCode
          }
        />
      </FormControl>
      {/* {!isEdit && <StatusInput formType={formType} />} */}
    </form>
  );
};

export default ManageClassSectionForm;
