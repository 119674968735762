import RolesView from "components/Users/Roles/RolesView";
import TeacherReadView from "components/Users/Teachers/TeacherReadView";
import UserTeachersView from "components/Users/Teachers/TeachersView";
import AnnualPlanReadView from "components/academicPlan/AnnualPlan/AnnualPlanReadView";
import LessonPlanListView from "components/academicPlan/LessonPlans/LessonPlanListView";
import LessonPlanReadView from "components/academicPlan/LessonPlans/LessonPlanReadView";
import UnitPlanFormReadView from "components/academicPlan/UnitPlan/UnitPlanFormReadView";
import UnitPlanListView from "components/academicPlan/UnitPlan/UnitPlanListView";
import ReviewSetGoalListView from "components/goals/ReviewGoal/ReviewSetGoalListView";
import SetGoalListView from "components/goals/SetGoal/SetGoalListView";
import SetGoalReadView from "components/goals/SetGoal/SetGoalReadView";
import LOVView from "components/listOfValues/LOVView";
import ObservationsView from "components/master/ManageObservations/ObservationsView";
import SchoolView from "components/master/ManageSchool/Schools/SchoolViewCard";
import * as R from "ramda";

export const PAGE_RENDERER = {
  LOV: "lov",
  SCHOOL: "school",
  CLASS: "class",
  CLASS_SECTIONS: "classSections",
  SUBJECTS: "subjects",
  POLICIES: "policies",
  TEACHERS: "teachers",
  OBSERVATIONS: "observations",
  USER_TEACHERS: "userTeachers",
  ANNUAL_PLAN: "annualPlan",
  UNIT_PLAN: "unitPlan",
  LESSON_PLAN: "lessonPlan",
  ROLES: "roles",
  SET_GOAL: "setGoal",
  REVIEW_SET_GOAL: "reviewSetGoal",
};
export const PaginatedRendereMap = {
  lov: LOVView,
  school: SchoolView,
  observations: ObservationsView,
  userTeachers: UserTeachersView,
  unitPlan: UnitPlanListView,
  lessonPlan: LessonPlanListView,
  roles: RolesView,
  setGoal: SetGoalListView,
  reviewSetGoal: ReviewSetGoalListView,
};

export const PageReadViewRenderer = {
  annualPlan: AnnualPlanReadView,
  unitPlan: UnitPlanFormReadView,
  lessonPlan: LessonPlanReadView,
  userTeachers: TeacherReadView,
  setGoal: SetGoalReadView,
};

export const getPaginatedListRenderer = (key) => {
  return R.prop(key, PaginatedRendereMap);
};
