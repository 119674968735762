import { configureStore } from "@reduxjs/toolkit";
import globalFormSlice from "./slices/globalFormSlice";
import pageSlice from "./slices/pageSlice";
import userLoginFormSlice from "./slices/userLoginFormSlice";

export default configureStore({
  reducer: {
    globalForm: globalFormSlice,
    userProfile: userLoginFormSlice,
    pages: pageSlice,
  },
});
