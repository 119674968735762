import {
    FormControl,
    makeStyles,
    MenuItem,
    Select,
    InputLabel,
    Grid,
    Box,
    Button
} from "@material-ui/core";
import React, { useState, useEffect, useCallback } from "react";
import * as R from "ramda";
import { LOVDataKeys, LOVKeyMap, LOVSelectKeys } from "common/components/SelectDropDown/SelectDropDownUtils";
import LOVSelect from "../../common/components/LOVSelect/LOVSelect";
import { PAGES } from "state/slices/pageSlice";
import { useDispatch, useSelector } from "react-redux";
import { updateForm } from "state/slices/globalFormSlice";
import { getPageList, onAddValue } from "components/listOfValues/utils";
import { clearPages, setPageListItems } from "state/slices/pageSlice";

const useStyles = makeStyles((theme) => ({
    inputFormControl: {
        width: "100%",
    },
    inputLabel1: {
        top: "-17px",
        left: "-14px",
    },
}));
const AddGoalForm = ({ formType, setValue, isEdit }) => {
    const classes = useStyles();

    const dispatch = useDispatch();
    const userProfileRoles = useSelector((state) => state.userProfile.userProfile.roles);
    const allRoles = useSelector((state) => state.pages.roles.items);
    const formData = useSelector((state) => state.globalForm[formType]);
    const pageData = useSelector(state => R.path(["pages", PAGES.setGoal], state));
  const pageFilter = useSelector(state => R.path(["globalForm", "pageFilters"], state));

    const [userRoles, setUserRoles] = useState([]);
    
    const onSave = async() => {
        const role = R.propOr("", "role", formData);
    const annualPeriod = R.propOr("", "annualPeriod", formData);
    const payload = {role, annualPeriod};
    const response = await onAddValue(payload, PAGES.setGoal);
    getListData();
    setValue(0);
    };

    const getListData = useCallback(
        async (searchKey = "") => {
          const pageListData = await getPageList({ searchKey, page: PAGES.setGoal, pageData, pageFilter: pageFilter });
          dispatch(
            setPageListItems({
              page: PAGES.setGoal,
              itemList: pageListData?.content??[],
              totalElements: pageListData?.totalElements??0,
              totalPages: pageListData?.totalPages??1
            })
          );
        },
        [dispatch, pageData, pageFilter]
      );

    useEffect(() => {
        setRoles();
    }, []);

    const setRoles = () => {
        const userRoleIds = userProfileRoles?.map(role => role["roleId"]);
        setUserRoles(allRoles.filter(role => userRoleIds.includes(role.id)));
    }

    const onRoleChange = (e) => {
        const updatedFormData = R.assocPath(["role"], e.target.value, formData);
        dispatch(updateForm({
            formType: formType,
            formData: updatedFormData
        }));
    }

    return (
        <form>
            <Grid container spacing={2} style={{ paddingTop: '2rem' }}>
                <Grid item xs={6}>
                <FormControl variant="outlined" className={classes.inputFormControl}>
                    <InputLabel className={classes.inputLabel1} id="demo-simple-select-outlined-label">
                        Annual Period
                    </InputLabel>
                    <LOVSelect
                        margin="normal"
                        formType={formType}
                        selectKey={LOVSelectKeys.ANNUAL_PERIOD}
                        dataKey={LOVDataKeys.ANNUAL_PERIOD}
                        lovKey={LOVKeyMap.ANNUAL_PERIOD}
                        value={R.propOr("", "annualPeriod", formData)}
                        setObj={false}
                        isReadOnly={false}
                    />
                </FormControl>
                </Grid>
                <Grid item xs={6}>
                <FormControl variant="outlined" className={classes.inputFormControl}>
                    <InputLabel className={classes.inputLabel1} id="demo-simple-select-outlined-label">
                        Role
                    </InputLabel>
                    <Select
                        variant="outlined"
                        margin="normal"
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={R.propOr("", "role", formData)}
                        onChange={onRoleChange}
                        placeholder="Role"
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                    >
                        <MenuItem value="" disabled >
                            <span className={classes.placeholderValue}>Select role</span>
                        </MenuItem>
                        {
                            R.map(role => {
                                return (
                                    <MenuItem value={role.id}>{role.name}</MenuItem>
                                )
                            })(userRoles)
                        }
                    </Select>
                </FormControl>
                </Grid>
            </Grid>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Box sx={{ flex: "1 1 auto" }} />
            <Button
            variant="outlined"
            color="primary"
            style={{ marginRight: '0.5rem' }}
              onClick={() => {}}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={onSave}
              data-html2canvas-ignore
            >
              Save
            </Button>
          </Box>
        </form>
    );
};

export default AddGoalForm;