export const LOVFormState = {
  name: "",
  description: "",
  items: [
    {
      name: "",
      value: "",
    },
  ],
  status: "ACTIVE",
  id: "",
};

export const ManageSchoolState = {
  schoolName: "",
  campus: "",
  affiliatedBoard: "",
  pointOfContact: "",
  email: "",
  phoneNumber: "",
  address: "",
  websiteLink: "",
  schoolCode: "",
  establishedYear: 0,
};

export const ManageClassSectionsState = {
  school: {
    id: "",
    schoolBranchName: "",
  },
  className: {
    id: "",
    className: "",
  },
  section: "",
  capacity: 0,
  classTeacher: {
    id: "",
    email: "",
  },
};

export const teacherSectionInitialState = {
  teacher: {
    id: "",
    email: "",
  },
  section: {
    id: "",
    section: "",
  },
};

export const ManageSubjectsState = {
  subjectName: "",
  subjectType: "ELECTIVE",
  school: {
    id: "",
    schoolBranchName: "",
  },
  className: {
    id: "",
    className: "",
  },
  teachers: [teacherSectionInitialState],
};

export const LearningOutcomesState = {
  name: "",
  tag: "",
};

export const ObservationFormState = {
  name: "",
  tagList: [],
  learningOutcomes: [{ ...LearningOutcomesState }],
};
export const ManageObservationsRegistryState = {
  name: "",
  grades: [],
  observations: [{ ...ObservationFormState }],
};

export const UserTeacherFormState = {
  firstName: "",
  lastName: "",
  email: "",
  mobile: "",
  currentAddress: "",
  permanentAddress: "",
  onboardingDate: "",
  designation: "",
  experienceInMonths: "",
  roles: [],
  applicationUserType: "TEACHER",
};

export const TierTopicFormInitialState = {
  name: "",
  start: "",
  end: "",
  subTopics: [],
};

export const TierUnitPlanFormInitialState = {
  name: "",
  start: "",
  end: "",
  subUnits: [],
};

export const TierPlanFormInitialState = {
  name: "",
  start: "",
  end: "",
  topics: [TierTopicFormInitialState],
  units: [TierUnitPlanFormInitialState],
};

export const AnnualPlanInitialState = {
  name: "",
  start: "",
  end: "",
  curriculumContext: {
    school: { id: "", schoolCode: "" },
    gradeName: "",
    annualPeriod: "",
    subject: { id: "", subject: "" },
  },
  tierPlans: [TierPlanFormInitialState],
};

export const UnitLessonInitialState = {
  name: "",
  start: "",
  end: "",
};

export const UnitPlanFormInitialState = {
  tlmList: [],
  lessonPlans: [UnitLessonInitialState],
  learningOutcomes: [],
  periods: "",
  studentObjectives: "",
  teacherReflection: "",
  learningObjectiveForm: {
    conceptObjective: "",
    skillObjective: "",
    factObjective: "",
    vocabularyObjective: {
      languageOfInstruction: "",
      studentVocabulary: "",
    },
    valuesObjectives: {
      symbioticLearning: "",
      environmentalConsciousness: "",
      designThinking: "",
      emotionalWellness: "",
    },
  },
  levelOfLeaningForm: {
    levelOfLearningInput1: "",
    levelOfLearningInput2: "",
    levelOfLearningInput3: "",
  },
  assessmentForm: {
    summative: "",
    formative: "",
    formativeAssessments: [],
  },
  topics: [],
};

export const LessonPlanPrePrimaryForm = {
  tlmList: [],
  learningOutcomes: [],
  learningObjectiveForm: {
    conceptObjective: "",
    skillObjective: "",
    factObjective: "",
    vocabularyObjective: {
      languageOfInstruction: "",
      studentVocabulary: "",
    },
  },
  levelOfLeaningForm: {
    levelOfLearningInput1: "",
    levelOfLearningInput2: "",
    levelOfLearningInput3: "",
  },
  introNewKnowledgeInput: "",
  developNewKnowledgeInput: "",
  studentPracticeAssesmentInput: "",
  closureInput: "",
  teacherReflection: "",
};

export const LessonPlanForm = {
  tlmList: [],
  learningOutcomes: [],
  learningObjectiveForm: {
    conceptObjective: "",
    skillObjective: "",
    factObjective: "",
    vocabularyObjective: {
      languageOfInstruction: "",
      studentVocabulary: "",
    },
    valuesObjectives: {
      symbioticLearning: "",
      environmentalConsciousness: "",
      designThinking: "",
      emotionalWellness: "",
    },
  },
  levelOfLeaningForm: {
    levelOfLearningInput1: "",
    levelOfLearningInput2: "",
    levelOfLearningInput3: "",
  },
  introNewKnowledgeInput: "",
  developNewKnowledgeInput: "",
  studentPracticeAssesmentInput: "",
  closureInput: "",
  teacherReflection: "",
  assessmentRubric: "",
  realLifeConnect: "",
  assessmentProjectTask: "",
  assignmentProjectTask: "",
  assessmentSubmissionDate: "",
  studentObjectives: "",
  summarization: "",
  teachingStrategies: [],
  possibleMisconceptions: "",
  mainContents: [],
};

export const GoalSettingInitialState = {
  goalDetails: "",
  measurementUnit: "",
  weightage: "",
};

export const GoalReviewConfigurationInitialState = {
  roleId: "",
  goalApproverRoleId: "",
  reviewApproverRoleId: "",
  reviewerRoles: [
    {
      goalReviewerRoleId: "",
    },
  ],
};

export const GoalSettingFormInitialState = {
  annualPeriod: "",
  role: "",
};

export const PageFiltersInitialState = {
  school: {
    id: "",
    schoolCode: "",
  },
  gradeName: "",
  subject: "",
  teacher: {
    id: "",
    email: "",
  },
  tierPlan: "",
  unitPlan: "",
};
export const innitialStateMap = {
  LOVForm: LOVFormState,
  ManageSchool: ManageSchoolState,
  ManageClassSection: ManageClassSectionsState,
  ManageSubject: ManageSubjectsState,
  ManageObservations: ManageObservationsRegistryState,
  UserTeacherForm: UserTeacherFormState,
  AnnualPlanForm: AnnualPlanInitialState,
  UnitPlanForm: UnitPlanFormInitialState,
  LessonPlanPrePrimaryForm: LessonPlanPrePrimaryForm,
  LessonPlanForm: LessonPlanForm,
  SetGoal: GoalSettingFormInitialState,
  pageFilters: PageFiltersInitialState,
};
