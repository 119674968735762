import React, { useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { Button, Paper, Input, IconButton } from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 2),
  },
  elevation: {
    boxShadow: "none",
  },
  input: {
    display: "none",
  },
  buttonContainer: {
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(2),
  },
  fileName: {
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(2),
  },
  fileNameText: {
    marginRight: theme.spacing(1),
  },
  submitButton: {
    marginLeft: theme.spacing(1),
  },
}));

const FileUploader = ({ file, setFile }) => {
  const classes = useStyles();

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (!isValidFileType(file)) {
      alert("Invalid file type. Only pdf files are allowed.");
      return;
    }
    setFile(file);
  };

  const isValidFileType = (file) => {
    const validTypes = ["application/pdf"];
    return validTypes.includes(file.type);
  };

  const handleClearSelection = () => {
    setFile(null);
  };

  return (
    <Paper className={classes.root}>
      <div className={classes.buttonContainer}>
        <Input
          accept=".pdf,.doc"
          className={classes.input}
          id="contained-button-file"
          type="file"
          onChange={handleFileChange}
        />
        <label htmlFor="contained-button-file">
          <Button variant="contained" component="span">
            Browse File
          </Button>
        </label>
        {file && (
          <div className={classes.fileName}>
            <span className={classes.fileNameText}>{file.name}</span>
            <IconButton onClick={handleClearSelection}>
              <CloseIcon />
            </IconButton>
          </div>
        )}
      </div>
    </Paper>
  );
};

export default FileUploader;
