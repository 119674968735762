import {
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Box,
  IconButton,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Close } from "@material-ui/icons";
import {
  LOVApiKeys,
  LOVDataKeys,
  LOVSelectKeys,
} from "common/components/SelectDropDown/SelectDropDownUtils";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { ExpandMoreOutlined } from "@material-ui/icons";
import LOVSelect from "common/components/LOVSelect/LOVSelect";
import { removeWhiteSpace } from "common/utils/composition";
import RoleDropDown from "common/components/RoleDropDown/RoleDropDown";
import SubjectMultiSelectDropDown from "common/components/MultiSelectDropDown/SubjectMultiSelectDropDown";
import { getClassNameFormValue } from "util/APIPayloadUtils.js/ClassUtils";

const useStyles = makeStyles(() => ({
  inputFormControl: {
    marginBottom: "2rem",
  },
  inputLabel: {
    top: "-17px",
  },
  inputLabel1: {
    top: "-17px",
    left: "-14px",
  },
  displayobservation1: {
    background: "#F5F5F5",
    border: "1px solid #DBDBDB",
    boxShadow: "none",
    borderRadius: 4,
    marginBottom: "10px",
  },
  editfields: {
    margin: "1.5rem 0",
    border: "1px solid #26365785",
    borderRadius: 4,
    padding: "1rem",
    background: "#fff",
  },
  downloadIconButton: {
    backgroundColor: "#898EAC",
    "&:hover": {
      backgroundColor: "#898EAC",
    },
  },
}));

const RolesForm = ({ state, setState, filterState, formType, isEditForm }) => {
  const classes = useStyles();
  const LOVList = useSelector((state) => state.pages.listOfValues.items);
  const [addRole, setAddRole] = useState(state?.roleId);

  const allRoles = useSelector((state) => state.pages.roles.items);

  const onAddClick = () => {
    setState((prev) => ({
      ...prev,
      roleClassXrefs: [...prev.roleClassXrefs, { className: "", subjects: [] }],
    }));
  };

  const onSubjectSelect = (values, index) => {
    const classSubjectXrefs = [];
    values.forEach((value) => {
      const newXref = {};
      newXref["subjectName"] = value;
      classSubjectXrefs.push(newXref);
    });
    setState((prev) => ({
      ...prev,
      roleClassXrefs: [
        ...prev.roleClassXrefs.slice(0, index),
        { ...prev.roleClassXrefs[index], subjects: classSubjectXrefs },
        ...prev.roleClassXrefs.slice(index + 1),
      ],
    }));
  };

  const removeRow = (i) => {
    setState((prev) => ({
      ...prev,
      roleClassXrefs: [
        ...prev.roleClassXrefs.slice(0, i),
        ...prev.roleClassXrefs.slice(i + 1),
      ],
    }));
  };

  const handleChange = (key, value, index) => {
    if (key === "gradeName") {
      setState((prev) => ({
        ...prev,
        roleClassXrefs: [
          ...prev.roleClassXrefs.slice(0, index),
          { roleId: addRole, className: value, subjects: [] },
          ...prev.roleClassXrefs.slice(index + 1),
        ],
      }));
    }
  };

  useEffect(() => {
    if (!isEditForm) {
      setState({ roleId: addRole, roleClassXrefs: [] });
    }
  }, [addRole, isEditForm, setState]);

  return (
    <form style={{ marginTop: "1rem" }}>
      <Grid container>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl
              variant="outlined"
              className={classes.inputFormControl}
            >
              <InputLabel
                className={classes.inputLabel1}
                id="demo-simple-select-outlined-label"
              >
                Role(s)
              </InputLabel>

              <RoleDropDown roleState={addRole} setRoleState={setAddRole} />
            </FormControl>
            {addRole ? (
              <>
                <Accordion
                  className={classes.displayobservation1}
                  defaultExpanded
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreOutlined />}
                    aria-controls="panel1d-content"
                    id="panel1d-header"
                  >
                    <Grid container>
                      <Grid item xs={12} md={12}>
                        <Typography variant="subtitle2" align="left">
                          {allRoles.find((role) => role.id === addRole)?.name}
                        </Typography>
                      </Grid>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails style={{ background: "#fff" }}>
                    {allRoles.find((role) => role.id === addRole)
                      ?.isGradesApplicable === false ? (
                      <>
                        <p>No Grades applicable for this role</p>
                      </>
                    ) : allRoles.find((role) => role.id === addRole)
                        ?.isSubjectApplicable === true ? (
                      <Grid
                        container
                        spacing={1}
                        style={{ display: "block", textAlign: "end" }}
                      >
                        <Grid item xs={12}>
                          <Button
                            variant="contained"
                            color="primary"
                            className={classes.downloadIconButton}
                            onClick={onAddClick}
                            startIcon={<AddCircleIcon className="addIcon" />}
                            // disabled={!privileges?.CREATE}
                          >
                            Add Grade
                          </Button>
                        </Grid>

                        <Grid item xs={12}>
                          {state?.roleClassXrefs?.map((item, i) => {
                            return (
                              <Box>
                                <Grid container spacing={1}>
                                  <Grid item xs={12} md={3}>
                                    <FormControl
                                      variant="outlined"
                                      className={classes.inputFormControl}
                                    >
                                      <InputLabel
                                        className={classes.inputLabel1}
                                        id="demo-simple-select-outlined-label"
                                      >
                                        Grades
                                      </InputLabel>
                                      <LOVSelect
                                        margin="normal"
                                        selectKey={LOVSelectKeys.CLASS}
                                        dataKey={LOVDataKeys.CLASS}
                                        lovKey={LOVApiKeys.CLASS}
                                        value={item.className || ""}
                                        customOnChange={(data) => {
                                          handleChange("gradeName", data, i);
                                        }}
                                      />
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={12} md={8}>
                                    <FormControl
                                      variant="outlined"
                                      className={classes.inputFormControl}
                                    >
                                      <InputLabel
                                        className={classes.inputLabel1}
                                        id="demo-simple-select-outlined-label"
                                      >
                                        Subject
                                      </InputLabel>
                                      <SubjectMultiSelectDropDown
                                        margin="normal"
                                        defaultSelected={
                                          item?.subjects?.map(
                                            (xref) => xref.subjectName
                                          ) ?? []
                                        }
                                        selectKey={LOVSelectKeys.SUBJECT}
                                        lovKey={`SubjectClass${removeWhiteSpace(
                                          getClassNameFormValue(
                                            LOVList,
                                            item.className
                                          )
                                        )}`}
                                        dataKey={LOVDataKeys.SUBJECT}
                                        onSelect={(e) => onSubjectSelect(e, i)}
                                      />
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={12} md={1}>
                                    <IconButton onClick={() => removeRow(i)}>
                                      <Close />
                                    </IconButton>
                                  </Grid>
                                </Grid>
                              </Box>
                            );
                          })}
                        </Grid>
                      </Grid>
                    ) : (
                      <Grid
                        container
                        spacing={1}
                        style={{ display: "block", textAlign: "end" }}
                      >
                        <Grid item xs={12}>
                          <Button
                            variant="contained"
                            color="primary"
                            className={classes.downloadIconButton}
                            onClick={onAddClick}
                            startIcon={<AddCircleIcon className="addIcon" />}
                            // disabled={!privileges?.CREATE}
                          >
                            Add Grade
                          </Button>
                        </Grid>

                        <Grid item xs={12}>
                          {state?.roleClassXrefs?.map((item, i) => {
                            return (
                              <Box>
                                <Grid container spacing={1}>
                                  <Grid item xs={12} md={11}>
                                    <FormControl
                                      variant="outlined"
                                      className={classes.inputFormControl}
                                    >
                                      <InputLabel
                                        className={classes.inputLabel1}
                                        id="demo-simple-select-outlined-label"
                                      >
                                        Grades
                                      </InputLabel>
                                      <LOVSelect
                                        margin="normal"
                                        selectKey={LOVSelectKeys.CLASS}
                                        dataKey={LOVDataKeys.CLASS}
                                        lovKey={LOVApiKeys.CLASS}
                                        value={item.className || ""}
                                        customOnChange={(data) => {
                                          handleChange("gradeName", data, i);
                                        }}
                                      />
                                    </FormControl>
                                  </Grid>

                                  <Grid item xs={12} md={1}>
                                    <IconButton onClick={() => removeRow(i)}>
                                      <Close />
                                    </IconButton>
                                  </Grid>
                                </Grid>
                              </Box>
                            );
                          })}
                        </Grid>
                      </Grid>
                    )}
                  </AccordionDetails>
                </Accordion>
              </>
            ) : null}
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default RolesForm;
