import React, { useEffect, useState } from "react";
import { Close } from "@material-ui/icons";
import { FORM_TYPES } from "common/utils/formUtility";
import { useSelector } from "react-redux";
import * as R from "ramda";
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  makeStyles,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import { getSchoolsList } from "components/master/utils";
import {
  getDetailsById,
  onAddValue,
  saveTeacherSubjectMapping,
} from "components/listOfValues/utils";
import { PAGES } from "state/slices/pageSlice";
import SectionTeacher from "common/components/SectionTeacher.js/SectionTeacher";
import { colors } from "theme/colors";
import { useCallback } from "react";

const useStyles = makeStyles((theme) => ({
  cardWrapper: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  annualPlanCard: {
    marginBottom: "30px",
  },
  paginationContainer: {
    display: "flex",
    justifyContent: "end",
  },
  heading: {
    fontWeight: 800,
    fontSize: "18px",
    color: "#263657",
  },
  divider: {
    margin: "0 24px 24px 24px",
  },
  tableCell: {
    color: "#fff",
    padding: "6px 16px",
  },
  label: {
    color: colors.gray4,
  },
  activeLabel: {
    color: colors.green,
  },
  deactivateLabel: {
    color: colors.red,
  },
  table: {
    padding: "0 16px",
  },
}));

function ManageTeacherModal(props) {
  const {
    userRoles,
    setModalState,
    modalState,
    formType,
    section,
    subjects,
    schoolCode,
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down("sm"));
  const [state, setState] = useState({
    name: "",
    gradeName: "",
    subject: {},
    start: "",
    end: "",
  });
  const [teacherState, setTeacherState] = useState([]);
  const isEditForm = modalState.isEdit || false;

  const handleClose = () => {
    setModalState((prev) => ({
      isTeacherModalOpen: false,
      data: null,
      isEdit: false,
    }));
  };

  const getSubjectTeacherDetails = useCallback(async () => {
    const response = await getDetailsById("/teacher/subjects", section.id);
    setTeacherState(response);
  }, [section.id]);

  const handleAdd = (newObject) => {
    let newMap = [...teacherState];
    newMap.push({ ...newObject, section: section });
    setTeacherState(newMap);
  };

  const handleRemove = (index) => {
    let newMap = [...teacherState];
    newMap.splice(index, 1);
    setTeacherState(newMap);
  };

  useEffect(() => {
    if (section.id) {
      getSubjectTeacherDetails();
    }
  }, [getSubjectTeacherDetails, section.id]);

  const manageClassSectionFormData = useSelector(
    (state) => state.globalForm[FORM_TYPES.MANAGE_CLASS_SECTION]
  );

  const AllowedRoles = ["System Administrator", "Administrator"];

  const isEditEnabled = (roles) => {
    let editEnabled = false;
    if (roles && roles.length > 0) {
      roles.forEach((role) => {
        if (R.includes(role, AllowedRoles)) {
          editEnabled = true;
        }
      });
    }
    return editEnabled;
  };

  const isReadOnly = isEditEnabled(userRoles)
    ? false
    : R.propOr("", "status", manageClassSectionFormData) === "COMPLETED";

  const handleSubmit = async (e, schoolStatus = "ACTIVE") => {
    const response = await saveTeacherSubjectMapping(teacherState, section.id);
    setModalState({
      isTeacherModalOpen: false,
      isEdit: false,
      data: null,
    });
  };

  return (
    <Dialog
      maxWidth="md"
      fullWidth
      open={true}
      fullScreen={isSmallDevice}
      scroll="paper"
      onClose={() =>
        setModalState((prev) => ({ ...prev, isTeacherModalOpen: false }))
      }
      ariaLabeledBy="form-dialog-title"
    >
      <Grid container>
        <Grid item xs={11}>
          <DialogTitle id="form-dialog-title">
            <Typography variant="h6" className={classes.heading}>
              Manage Teachers
            </Typography>
          </DialogTitle>
        </Grid>
        <Grid item xs={1} className={classes.modalCloseBtn}>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </Grid>
      </Grid>
      <Divider />
      <DialogContent style={{ padding: "8px 25px" }}>
        <Box>
          <SectionTeacher
            schoolCode={schoolCode}
            subjects={subjects}
            handleChange={handleAdd}
          />

          <Box style={{ margin: "1.5rem 0rem" }}>
            <TableContainer component={Paper} elevation={0}>
              <Table
                style={{ minWidth: 700, border: "1px solid lightgrey" }}
                aria-label="simple table"
              >
                <TableHead style={{ background: "#263657" }}>
                  <TableRow>
                    <TableCell className={classes.tableCell}>Subject</TableCell>
                    <TableCell className={classes.tableCell}>Teacher</TableCell>
                    <TableCell className={classes.tableCell}></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {teacherState?.length ? (
                    teacherState?.map((e, index) => (
                      <TableRow
                        style={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          className={classes.table}
                        >
                          <Typography variant="subtitle2">
                            {e?.subject?.subjectName}
                          </Typography>
                        </TableCell>
                        <TableCell className={classes.table}>
                          {e?.applicationUser?.fullName}
                        </TableCell>
                        <TableCell className={classes.table}>
                          <Button
                            color="primary"
                            variant="outlined"
                            onClick={() => handleRemove(index)}
                            size="small"
                            data-html2canvas-ignore
                          >
                            Remove
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <div className={classes.noDataAvailable}>
                      No Data available
                    </div>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions style={{ border: "1px solid lightgrey" }}>
        <Box style={{ paddingRight: "24px" }}>
          <Button
            color="primary"
            // disabled={isReadOnly || isNew}
            variant="contained"
            onClick={handleSubmit}
            data-html2canvas-ignore
          >
            Save
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}

export default ManageTeacherModal;
