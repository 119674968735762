import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Select, MenuItem, makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import * as R from "ramda";
import { updateForm } from "state/slices/globalFormSlice";
import { DEPENDENT_TO_PAGE, getLOVSelectData } from "./SelectDropDownUtils";
import { setPageListItems } from "state/slices/pageSlice";

const useStyle = makeStyles(() => ({
    placeholderValue: {
        color: '#bbb',
    },
}))

const SelectDropDown = ({
    formType,
    selectKey,
    apiKey,
    displayKey,
    dataKey,
    secondaryDataKey,
    selectedValue = {},
    customOnChange,
    dependentFields = [],
    queryParams = {},
    page,
    isDisabled = false,
    isDependent = false
}) => {
    const formData = useSelector((state) => state.globalForm[formType]);
    const dispatch = useDispatch();
    const dropdownListData = useSelector((state) => state.pages[page]);
    const [selectData, setSelectData] = useState(selectedValue);
    const classes = useStyle();
    const [selectList, setSelectList] = useState(dropdownListData.items);
    const onChange = (event) => {
        const value = R.path(["target", "value"], event);
        const selectedLOV = R.filter((item) => item.id === value)(selectList)[0];
        setSelectData(selectedLOV);
        if (customOnChange) {
            customOnChange(selectedLOV)
        } else {
            updateFormData(selectedLOV);
        }

    };

    const updateFormData = (data) => {
        const selectValue = R.pipe(
            R.assocPath(["id"], R.propOr("", "id", data)),
            R.assocPath([dataKey], R.propOr("", dataKey, data))
        )({});
        if (!R.isEmpty(dependentFields)) {
            R.map((dependent) => {
                updateDependents(dataKey, selectValue, dependent);
            })(dependentFields);
        }
        const updatedformData = R.pipe(R.assoc(selectKey, selectValue))(formData)
        dispatch(updateForm({ formType, formData: updatedformData }));
    }

    const getListData = useCallback(
        async (searchKey = "") => {
            const listData = await getLOVSelectData(apiKey)({ searchKey, queryParams, size: R.pathOr(400, "totalElements", dropdownListData) });
            const contentList = R.propOr([], "content", listData);
            setSelectList(contentList);
            dispatch(
                setPageListItems({
                    page: page,
                    itemList: contentList,
                })
            );
            if (!R.isEmpty(dependentFields)) {
                R.map((dependent) => {
                    updateDependents(dataKey, "", dependent);
                })(dependentFields);
            }
        },
        [apiKey]
    );

    const updateDependents = useCallback(
        async (dataKey, selectValue, dependent) => {
            const newQueryParams = selectValue ? R.assocPath([dataKey], selectValue[dataKey], {}) : queryParams;
            const listData = await getLOVSelectData(dependent)({ searchKey: "", queryParams: { ...queryParams, ...newQueryParams } });
            const contentList = R.propOr([], "content", listData);
            dispatch(
                setPageListItems({
                    page: DEPENDENT_TO_PAGE[dependent],
                    itemList: contentList,
                })
            );
        }
    )
    useEffect(() => {
        if (!isDependent) {
            if (R.isEmpty(R.propOr([], "items", dropdownListData))) {
                getListData();
            }
        }
        setSelectList(dropdownListData.items);
        setSelectData(selectedValue);
    }, [getListData, dropdownListData, selectedValue])

    return (
        <Select
            variant="outlined"
            margin="normal"
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={R.propOr("", "id", selectData)}
            onChange={onChange}
            placeholder={selectKey}
            name={selectKey}
            disabled={isDisabled}
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
        >
            <MenuItem value="" disabled >
                <span className={classes.placeholderValue}>Select {selectKey}</span>
            </MenuItem>
            {
                R.map((item) => {
                    const menuStr = secondaryDataKey ? `${R.propOr("", displayKey, item)} ${R.propOr("", secondaryDataKey, item)}` : R.propOr("", displayKey, item);
                    return <MenuItem value={item.id}>{menuStr}</MenuItem>
                }, selectList)
            }
        </Select>
    );
};

export default SelectDropDown;