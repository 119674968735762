import { Box, Tab, Tabs, Typography } from "@material-ui/core";
import withPrivilege from "common/hocs/withPrivilege";
import { PAGE_RENDERER } from "common/utils/rendererList";
import GridViewWrapper from "common/wrappers/GridViewWrapper";
import { FORM_RENDERERS, FORM_TYPES } from "common/utils/formUtility";
import React from "react";
import { PAGES } from "state/slices/pageSlice";
import { EntityMap } from "util/roleUtils";
// import ObservationRegistryForm from "./ObservationRegistryForm";
import * as R from "ramda";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      style={{ padding: "2rem" }}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

const ManageObservation = ({ privileges }) => {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <GridViewWrapper
        page={PAGES.observations}
        paginatedListRenderer={PAGE_RENDERER.OBSERVATIONS}
        formDialogConfig={{
          formType: FORM_TYPES.ADD_REGISTRY_FORM,
          formRenderer: FORM_RENDERERS.MANAGE_REGISTRY,
          title: "Registry",
        }}
        displayAdd={true}
      />
      {/* <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Observation Registry" {...a11yProps(0)} />
                    {
                        R.propOr(true, "CREATE", privileges) && <Tab label="Add Registry" {...a11yProps(1)} />
                    }
                </Tabs>
            </Box> */}
      {/* <TabPanel value={value} index={0}>
                
            </TabPanel>
            <TabPanel value={value} index={1}>
                <ObservationRegistryForm changeTab={setValue} />
            </TabPanel> */}
    </>
  );
};

export default withPrivilege(ManageObservation, EntityMap.observationregister);
