import { Box, Grid, Typography } from "@material-ui/core";
import React from "react";
import * as R from "ramda";
import { colors } from "theme/colors";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: "0.75rem",
  },

  heading: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: theme.typography.fontWeightBold,
    marginTop: "1rem",
    marginBottom: "1rem",
  },
  label: {
    color: colors.gray4,
  },
}));

const SetGoalReadView = ({ data }) => {
  const classes = useStyles();

  return (
    <Box style={{ padding: "1rem" }} id={"annualPlanReadView"}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={10}>
          <Typography variant="h6" color="primary">
            Goal Details: {R.propOr("goalDetails", data)}
          </Typography>
        </Grid>
      </Grid>
      <Grid container style={{ marginTop: "1.5rem" }}>
        <Grid item xs={12}>
          <Typography variant="subtitle2">
            Weightage: {R.propOr("weightage", data)}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SetGoalReadView;
