export const unitPlanFormSteps = [
  {
    name: "Unit plan summary",
    key: 1,
    componentKey: "UnitPlanSummary",
    subTitles: [
      {
        name: "Periods",
        key: 1,
      },
      {
        name: "Teaching learning material",
        key: 1,
      },
    ],
  },
  {
    name: "Learning objectives",
    key: 2,
    componentKey: "LearningObjective",
    subTitles: [
      {
        name: "Concept related objectives",
        key: 2,
      },
      {
        name: "Vocabulary related objectives",
        key: 2,
      },
      {
        name: "Values related objectives",
        key: 2,
      },
    ],
  },
  {
    name: "Possible Misconceptions",
    key: 3,
    componentKey: "PossibleMisconceptions",
  },
  { name: "Learning outcomes", key: 4, componentKey: "LearningOutcomes" },
  { name: "Level/s of learning", key: 5, componentKey: "LevelOfLearning" },
  {
    name: "Assessments",
    key: 6,
    componentKey: "Assessments",
    subTitles: [
      {
        name: "Formative Assessment",
        key: 6,
      },
      {
        name: "Summative Assessment",
        key: 6,
      },
    ],
  },
  { name: "Teacher reflection", key: 7, componentKey: "TeacherReflection" },
  { name: "Consents", key: 8, componentKey: "Consents" },
];

export const lessonPlanFormPrePrimarySteps = [
  {
    name: "Lesson plan summary",
    key: 1,
    componentKey: "LessonPlanSummary",
    subTitles: [
      {
        name: "Periods",
        key: 1,
      },
      {
        name: "Teaching learning material",
        key: 1,
      },
    ],
  },
  {
    name: "Learning objectives for lesson",
    key: 2,
    componentKey: "LearningObjectiveForLesson",
    subTitles: [
      {
        name: "Concept related objectives",
        key: 2,
      },
      {
        name: "Skill related objectives",
        key: 2,
      },
    ],
  },
  { name: "Level/s of learning", key: 4, componentKey: "LevelOfLearning" },
  {
    name: "Introducing new knowledge",
    key: 5,
    componentKey: "IntroducingNewKnowledge",
  },
  {
    name: "Developing/facilitating new knowledge",
    key: 6,
    componentKey: "DevelopingNewKnowledge",
  },
  {
    name: "Assessments",
    key: 7,
    componentKey: "AssessmentForLesson",
    subTitles: [
      {
        name: "Student practice/feedback/formative assessment",
        key: 7,
      },
    ],
  },
  {
    name: "Closure (retention of new learning)",
    key: 8,
    componentKey: "ClosureForLesson",
  },
  { name: "Teacher reflection", key: 9, componentKey: "TeacherReflection" },
  { name: "Consents", key: 10, componentKey: "Consents" },
];

export const lessonPlanFormSteps = [
  {
    name: "Lesson plan summary",
    key: 1,
    componentKey: "LessonPlanSummary",
    subTitles: [
      {
        name: "Periods",
        key: 1,
      },
    ],
  },
  {
    name: "Learning objectives for lesson",
    key: 2,
    componentKey: "LearningObjectiveForLesson",
    subTitles: [
      {
        name: "Concept related objectives",
        key: 2,
      },
      {
        name: "Skill related objectives",
        key: 2,
      },
    ],
  },
  {
    name: "Objectives",
    key: 3,
    componentKey: "ObjectiveForLesson",
    subTitles: [
      {
        name: "Values related objectives",
        key: 3,
      },
    ],
  },
  {
    name: "Main Content / Core / Introducing New Knowledge",
    key: 6,
    componentKey: "MainContentKnowledge",
  },
  {
    name: "Differentiated Teaching & Learning Strategies",
    key: 8,
    componentKey: "DifferentiatedTeachingLearning",
  },
  {
    name: "Summarization / Consolidation / Check for Understanding / Opportunity for Applying, Practicing and Reviewing",
    key: 9,
    componentKey: "SummarizationPracticingReviewing",
  },
  {
    name: "Real Life Connect / Cross Curricular Links / Subject Integration",
    key: 10,
    componentKey: "RealLifeConnect",
  },
  {
    name: "Assessment (AFL/ Self / Peer)",
    key: 11,
    componentKey: "AssessmentForMainLesson",
  },
  {
    name: "Project / Home Assignment / Extension Task",
    key: 12,
    componentKey: "ProjectAssessmentForLesson",
  },
  { name: "Teacher reflection", key: 13, componentKey: "TeacherReflection" },
  { name: "Consents", key: 14, componentKey: "Consents" },
];
