import {
  addSchoolData,
  getSchools,
  getClasses,
  addClassSection,
  getClassSections,
  getSubjects,
  addSubject,
  getPolicies,
  addPolicy,
  addTeacher,
  getTeachers,
  getUnitPlanList,
  getOutstandingUnitPlanList,
  getUpcomingUnitPlanList,
  getOutstandingLessonPlanList,
  getUpcomingLessonPlanList,
  putClassSection,
} from "util/APIUtils";
import Alert from "react-s-alert";

export const saveSchool = async (payload, isEdit) => {
  try {
    const response = await addSchoolData(payload);
    Alert.success(`School ${isEdit ? "updated" : "added"} successfully`);
  } catch (error) {
    Alert.error(
      (error && error.message) ||
        "Oops! Something went wrong. Please try again!"
    );
  }
};

export const getSchoolsList = async ({ searchKey, size = 100 }) => {
  let response = { content: [] };
  try {
    const response = await getSchools({ page: 0, size, name: searchKey });
    return response;
  } catch (error) {
    Alert.error(
      (error && error.message) ||
        "Oops! Something went wrong. Please try again!"
    );
    return response;
  }
};

export const getClassList = async ({ searchKey, size = 100 }) => {
  let response = { content: [] };
  try {
    const response = await getClasses({ page: 0, size, name: searchKey });
    return response;
  } catch (error) {
    Alert.error(
      (error && error.message) ||
        "Oops! Something went wrong. Please try again!"
    );
    return response;
  }
};

export const saveClassSection = async (payload, gradeId) => {
  try {
    const response = await addClassSection(payload, gradeId);
    Alert.success("Class section added successfully");
  } catch (error) {
    Alert.error(
      (error && error.message) ||
        "Oops! Something went wrong. Please try again!"
    );
  }
};

export const updateClassSection = async (payload, id) => {
  try {
    const response = await putClassSection(payload, id);
    Alert.success("Class section updated successfully");
  } catch (error) {
    Alert.error(
      (error && error.message) ||
        "Oops! Something went wrong. Please try again!"
    );
  }
};
export const getClassSectionsList = async ({
  searchKey,
  queryParams,
  size = 100,
}) => {
  let response = { content: [] };
  try {
    const response = await getClassSections({
      page: 0,
      size,
      name: searchKey,
      ...queryParams,
    });
    return response;
  } catch (error) {
    Alert.error(
      (error && error.message) ||
        "Oops! Something went wrong. Please try again!"
    );
    return response;
  }
};

export const getUnitPlansList = async ({
  searchKey,
  queryParams,
  size = 100,
}) => {
  let response = { content: [] };
  try {
    const response = await getUnitPlanList({
      page: 0,
      size,
      name: searchKey,
      ...queryParams,
    });
    return response;
  } catch (error) {
    Alert.error(
      (error && error.message) ||
        "Oops! Something went wrong. Please try again!"
    );
    return response;
  }
};

export const getOutstandingUnitPlans = async ({
  page,
  role,
  annualPeriod,
  tierPlanValue,
  size = 100,
}) => {
  let response = { content: [] };
  try {
    const response = await getOutstandingUnitPlanList({
      page: page,
      size,
      role,
      annualPeriod,
      tierPlanValue,
    });
    return response;
  } catch (error) {
    Alert.error(
      (error && error.message) ||
        "Oops! Something went wrong. Please try again!"
    );
    return response;
  }
};

export const getUpcomingUnitPlans = async ({
  role,
  annualPeriod,
  tierPlanValue,
  size = 100,
}) => {
  let response = { content: [] };
  try {
    const response = await getUpcomingUnitPlanList({
      page: 0,
      size,
      role,
      annualPeriod,
      tierPlanValue,
    });
    return response;
  } catch (error) {
    Alert.error(
      (error && error.message) ||
        "Oops! Something went wrong. Please try again!"
    );
    return response;
  }
};

export const getOutstandingLessonPlans = async ({
  page = 0,
  role = "",
  annualPeriod = "",
  tierPlanValue = "",
  size = 100,
}) => {
  let response = { content: [] };
  try {
    const response = await getOutstandingLessonPlanList({
      page: page,
      size,
      role,
      annualPeriod,
      tierPlanValue,
    });
    return response;
  } catch (error) {
    Alert.error(
      (error && error.message) ||
        "Oops! Something went wrong. Please try again!"
    );
    return response;
  }
};

export const getUpcomingLessonPlans = async ({
  page,
  role,
  annualPeriod,
  tierPlanValue,
  size = 100,
}) => {
  let response = { content: [] };
  try {
    const response = await getUpcomingLessonPlanList({
      page: page,
      size,
      role,
      annualPeriod,
      tierPlanValue,
    });
    return response;
  } catch (error) {
    Alert.error(
      (error && error.message) ||
        "Oops! Something went wrong. Please try again!"
    );
    return response;
  }
};

export const getSubjectList = async ({
  searchKey,
  queryParams,
  size = 100,
}) => {
  let response = { content: [] };
  try {
    const response = await getSubjects({
      page: 0,
      size,
      name: searchKey,
      ...queryParams,
    });
    return response;
  } catch (error) {
    Alert.error(
      (error && error.message) ||
        "Oops! Something went wrong. Please try again!"
    );
    return response;
  }
};

export const saveSubject = async (payload, gradeId) => {
  try {
    const response = await addSubject(payload, gradeId);
    Alert.success("Class section added successfully");
  } catch (error) {
    Alert.error(
      (error && error.message) ||
        "Oops! Something went wrong. Please try again!"
    );
  }
};

export const getPoliciesData = async ({ searchKey, size = 100 }) => {
  let response = { content: [] };
  try {
    const response = await getPolicies({ page: 0, size, name: searchKey });
    return response;
  } catch (error) {
    Alert.error(
      (error && error.message) ||
        "Oops! Something went wrong. Please try again!"
    );
    return response;
  }
};

export const savePolicy = async (payload) => {
  try {
    const response = await addPolicy(payload);
    Alert.success("Class section added successfully");
  } catch (error) {
    Alert.error(
      (error && error.message) ||
        "Oops! Something went wrong. Please try again!"
    );
  }
};

export const saveTeacher = async (payload) => {
  try {
    const response = await addTeacher(payload);
    Alert.success("Class section added successfully");
  } catch (error) {
    Alert.error(
      (error && error.message) ||
        "Oops! Something went wrong. Please try again!"
    );
  }
};

export const getTeachersList = async ({
  searchKey,
  queryParams,
  size = 200,
}) => {
  let response = { content: [] };
  try {
    const response = await getTeachers({ page: 0, size, ...queryParams });
    return response;
  } catch (error) {
    Alert.error(
      (error && error.message) ||
        "Oops! Something went wrong. Please try again!"
    );
    return response;
  }
};
