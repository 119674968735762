import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import ConsentForm from "common/forms/ConsentForm";
import ObservationSelectionForm from "common/forms/ObservationSelectionForm";
import {
  getSelectListForPlanForm as getSelectList,
  setObservationsValue,
} from "common/forms/utils";
import { getDownloadFileName } from "common/utils/downloadAsPdf";
import { FORM_TYPES } from "common/utils/formUtility";
import {
  getDetailsById,
  getLessonPlanFormAPI,
  getPageList,
} from "components/listOfValues/utils";
import * as R from "ramda";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PAGES, setPageListItems } from "state/slices/pageSlice";
import { colors } from "theme/colors";
import { LessonReadOnlyData } from "./ManageLessonPlan";

import LOVSelect from "common/components/LOVSelect/LOVSelect";
import {
  LOVDataKeys,
  LOVKeyMap,
  LOVSelectKeys,
} from "common/components/SelectDropDown/SelectDropDownUtils";
import { useReactToPrint } from "react-to-print";

import ReactLinkify from "react-linkify";

const useStyle = makeStyles((theme) => ({
  heading: {
    color: "#1b2945",
    marginTop: "2rem",
    marginBottom: "1rem",
  },
  readOnlyContainer: {
    backgroundColor: colors.blue1,
    padding: "1rem",
    color: colors.gray1,
    marginBottom: "2rem",
  },
  readOnlyTitle: {
    fontSize: "20px",
  },
  btn: {
    "&.MuiButton-root": {
      marginRight: "1rem",
    },
  },
  visibleTextField: {
    "&.MuiInputBase-input": {
      visibility: "visible",
      overflow: "visible",
    },
  },
  sequenceTh: {
    "&.MuiTableCell-sizeSmall": {
      width: "100px",
    },
  },
}));

const LessonPlanReadView = ({ data, userRoles, isPlanSummary }) => {
  const printRef = useRef();
  const classes = useStyle();
  const [lessonPlanData, setLessonData] = useState({});
  let observationsData = useSelector(
    (state) => state.pages[PAGES.observations]
  );
  const downloadFileName = getDownloadFileName({
    data: data,
    templateId: "lesson",
  });
  const dispatch = useDispatch();

  useEffect(async () => {
    if (R.isEmpty(observationsData.items)) {
      getObservationsData();
    }
  }, [observationsData]);

  useEffect(() => {
    data?.id && getPlanFormData(data?.id);
  }, [data]);

  const getPlanFormData = async (id) => {
    const res = isPlanSummary
      ? await getLessonPlanFormAPI(id)
      : await getDetailsById("/lesson-plan", id);
    setLessonData(isPlanSummary ? res : res?.lessonPlanForm);
  };

  const getObservationsData = async () => {
    const pageListData = await getPageList({
      searchKey: "",
      page: PAGES.observations,
    });
    dispatch(
      setPageListItems({
        page: PAGES.observations,
        itemList: pageListData.content,
        totalElements: pageListData.totalElements,
        totalPages: pageListData.totalPages,
      })
    );
  };

  const isPreClass = R.pathOr("", ["type"], lessonPlanData) === "PRECLASS";

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: downloadFileName,
  });

  return (
    <Box id="lessonPlanForm" ref={printRef} sx={{ padding: "1rem" }}>
      {R.isEmpty(lessonPlanData) ? (
        <h3 className={classes.heading} align={"left"}>
          No Data available
        </h3>
      ) : (
        <Box>
          <LessonReadOnlyData data={data} downloadAsPdf={handlePrint} />
          {!isPreClass && (
            <Box>
              <h3 className={classes.heading} align={"left"}>
                Periods
              </h3>
              <TextField
                type="number"
                id="multiline-static"
                value={R.pathOr("", ["periods"], lessonPlanData)}
                disabled={true}
              />
              <h3 className={classes.heading} align={"left"}>
                Learning Objectives for the Lesson(Inform Students aboth what
                they are going to learn today) SWBA
              </h3>
              <h4 align={"left"}>
                Concept Related Objectives (Remembering & Recalling)
              </h4>
              <h5>Students will be able to –</h5>
              <Box
                style={{ whiteSpace: "pre-line" }}
                fullWidth
                id="conceptObjective"
              >
                {R.pathOr(
                  "",
                  ["learningObjectiveForm", "conceptObjective"],
                  lessonPlanData
                )}
              </Box>
              <br />
              <br />
              <h4 align={"left"}>
                Skill Related Objectives (Applying, Analysing, Evaluating,
                Creating)
              </h4>
              <h5>Students will be able to –</h5>
              <Box
                style={{ whiteSpace: "pre-line" }}
                fullWidth
                id="skillObjective"
              >
                {R.pathOr(
                  "",
                  ["learningObjectiveForm", "skillObjective"],
                  lessonPlanData
                )}
              </Box>
              <br />
              <br />
              <h4 align={"left"}>Values Related Objectives</h4>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <h5 align={"left"}>Value of the Month</h5>
                  <Box fullWidth id="symbioticLearning">
                    <Typography style={{ whiteSpace: "pre-line" }}>
                      {" "}
                      {R.pathOr(
                        "",
                        [
                          "learningObjectiveForm",
                          "valuesObjectives",
                          "symbioticLearning",
                        ],
                        lessonPlanData
                      )}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>

              <br />

              <br />

              <Box>
                <h3 className={classes.heading} align={"left"}>
                  Main Content / Core / Introducing New Knowledge
                </h3>
                {
                  <TableContainer component={Paper}>
                    <Table
                      sx={{ minWidth: 650 }}
                      size="small"
                      aria-label="a dense table"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            Sequence of Teaching Learning Activities
                          </TableCell>
                          <TableCell>Introduction Activities</TableCell>
                          <TableCell align="right">
                            Student Activities{" "}
                          </TableCell>
                          <TableCell align="right">
                            TLM(Teaching learning material)
                          </TableCell>
                          <TableCell align="left">Reference</TableCell>
                          <TableCell align="right">Time</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {R.map((item) => (
                          <TableRow
                            key={item.sequenceOfTla}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {item.sequenceOfTla}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {item.introductionActivity}
                            </TableCell>
                            <TableCell align="right">
                              {item.studentActivity}
                            </TableCell>
                            <TableCell align="left">
                              <ol>
                                {item.tlmList?.map((tlmItem) => (
                                  <li
                                    key={tlmItem.id}
                                    style={{ marginBottom: 10 }}
                                  >
                                    {tlmItem.name}
                                  </li>
                                ))}
                              </ol>
                            </TableCell>
                            <TableCell align="left">
                              <ol>
                                {item.tlmList?.map((tlmItem) => (
                                  <li
                                    key={tlmItem.id}
                                    style={{ marginBottom: 10 }}
                                  >
                                    <ReactLinkify>
                                      <Typography
                                        style={{ whiteSpace: "pre-line" }}
                                      >
                                        {tlmItem.reference}
                                      </Typography>
                                    </ReactLinkify>
                                  </li>
                                ))}
                              </ol>
                            </TableCell>
                            <TableCell align="right">{item.time}</TableCell>
                          </TableRow>
                        ))(R.propOr([], "mainContents", lessonPlanData))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                }
              </Box>
              <br />
              <br />
              <h3 className={classes.heading} align={"left"}>
                Learning Outcomes
              </h3>
              <ObservationSelectionForm
                className={R.pathOr(
                  "",
                  ["curriculumContext", "gradeName"],
                  data
                )}
                formType={FORM_TYPES.LESSON_PLAN_PRE_PRIMARY_FORM}
                value={setObservationsValue(
                  R.pathOr([], ["learningOutcomes"], lessonPlanData),
                  observationsData,
                  R.pathOr("", ["curriculumContext", "gradeName"], data)
                )}
                isReadOnly={true}
              />

              <h3 className={classes.heading} align={"left"}>
                Differentiated Teaching & Learning Strategies
              </h3>
              <FormControl className={classes.inputFormControl} fullWidth>
                <InputLabel id="demo-simple-select-outlined-label">
                  <b>Differentiated Teaching & Learning Strategies</b>
                </InputLabel>
                <LOVSelect
                  formType={FORM_TYPES.LESSON_PLAN_FORM}
                  selectKey={LOVSelectKeys.DIFF_TEACHING_STRATEGIES}
                  dataKey={LOVDataKeys.DIFF_TEACHING_STRATEGIES}
                  lovKey={LOVKeyMap.DIFF_TEACHING_STRATEGIES}
                  value={R.propOr([], "teachingStrategies", lessonPlanData)}
                  multiselect={true}
                  selectObj={true}
                  getSelectList={getSelectList}
                  isReadOnly={true}
                  showList={true}
                />
              </FormControl>
              <br />
              <br />
              <h3 className={classes.heading} align={"left"}>
                Summarization / Consolidation / Check for Understanding /
                Opportunity for Applying, Practicing and Reviewing
              </h3>
              <Box
                style={{ whiteSpace: "pre-line" }}
                fullWidth
                id="summarization"
              >
                {R.pathOr("", ["summarization"], lessonPlanData)}
              </Box>

              <h3 className={classes.heading} align={"left"}>
                Real Life Connect / Cross Curricular Links / Subject Integration
              </h3>
              <Box
                style={{ whiteSpace: "pre-line" }}
                fullWidth
                id="realLifeConnect"
              >
                {R.pathOr("", ["realLifeConnect"], lessonPlanData)}
              </Box>
              <h3 className={classes.heading} align={"left"}>
                Assessment (AFL/ Self / Peer)
              </h3>
              <Box
                style={{ whiteSpace: "pre-line" }}
                fullWidth
                id="assessmentProjectTask"
              >
                {R.pathOr("", ["assessmentProjectTask"], lessonPlanData)}
              </Box>
              <br />
              <br />

              <h3 className={classes.heading} align={"left"}>
                Project / Home Assignment / Extension Task
              </h3>

              <Box
                style={{ whiteSpace: "pre-line" }}
                fullWidth
                id="assignmentProjectTask"
              >
                {R.pathOr("", ["assignmentProjectTask"], lessonPlanData)}
              </Box>
              <br />
              <br />

              <h3 className={classes.heading} align={"left"}>
                CONSENTS
              </h3>
              <ConsentForm
                page={"lessonPlan"}
                userRoles={userRoles}
                consentData={R.propOr([], "consents", lessonPlanData)}
                isReadOnly={true}
              />
            </Box>
          )}
          {isPreClass && (
            <Box>
              <h3 className={classes.heading} align={"left"}>
                Periods
              </h3>
              <TextField
                type="number"
                id="multiline-static"
                value={R.pathOr("", ["periods"], lessonPlanData)}
                disabled={true}
              />

              <Box>
                <h3 className={classes.heading} align={"left"}>
                  Teaching Learning Material
                </h3>

                {R.map((item) => {
                  return (
                    <Grid container spacing={3}>
                      <Grid item xs={3}>
                        <Typography>{item.name}</Typography>
                      </Grid>
                      <Grid item xs={9}>
                        <ReactLinkify>
                          <Typography style={{ whiteSpace: "pre-line" }}>
                            {item.reference}
                          </Typography>
                        </ReactLinkify>
                      </Grid>
                    </Grid>
                  );
                })(R.propOr([], "tlmList", lessonPlanData))}
              </Box>
              <h3 className={classes.heading} align={"left"}>
                Learning Objectives for the Lesson(Inform Students aboth what
                they are going to learn today) SWBA
              </h3>
              <h4 align={"left"}>
                Concept Related Objectives (Remembering & Recalling)
              </h4>
              <h5>Students will be able to –</h5>
              <Box
                style={{ whiteSpace: "pre-line" }}
                fullWidth
                id="conceptObjective"
              >
                {R.pathOr(
                  "",
                  ["learningObjectiveForm", "conceptObjective"],
                  lessonPlanData
                )}
              </Box>
              <br />
              <br />
              <h4 align={"left"}>
                Skill Related Objectives (Applying, Analysing, Evaluating,
                Creating)
              </h4>
              <h5>Students will be able to –</h5>
              <Box
                style={{ whiteSpace: "pre-line" }}
                fullWidth
                id="skillObjective"
              >
                {R.pathOr(
                  "",
                  ["learningObjectiveForm", "skillObjective"],
                  lessonPlanData
                )}
              </Box>
              <br />
              <br />
              <h4 align={"left"}>
                Facts / Information Related Objectives (Remembering and
                Understanding)
              </h4>
              <h5>Students will be able to –</h5>
              <Box
                style={{ whiteSpace: "pre-line" }}
                fullWidth
                id="factObjective"
              >
                {R.pathOr(
                  "",
                  ["learningObjectiveForm", "factObjective"],
                  lessonPlanData
                )}
              </Box>
              <h4 align={"left"}>Vocabulary Related Objectives</h4>
              <h5>
                Language of Instruction (used by teacher, comprehended by
                students)
              </h5>
              <Box
                style={{ whiteSpace: "pre-line" }}
                fullWidth
                id="languageOfInstruction"
              >
                {R.pathOr(
                  "",
                  [
                    "learningObjectiveForm",
                    "vocabularyObjective",
                    "languageOfInstruction",
                  ],
                  lessonPlanData
                )}
              </Box>
              <h5>Student Vocabulary (internalised and use in context)</h5>
              <Box
                style={{ whiteSpace: "pre-line" }}
                fullWidth
                id="studentVocabulary"
              >
                {R.pathOr(
                  "",
                  [
                    "learningObjectiveForm",
                    "vocabularyObjective",
                    "studentVocabulary",
                  ],
                  lessonPlanData
                )}
              </Box>
              <br />
              <br />
              <h3 className={classes.heading} align={"left"}>
                LEVEL/S OF LEARNING
              </h3>
              <Box>
                <h4 align={"left"}>All learners will be able to:</h4>
                <Typography style={{ whiteSpace: "pre-line" }}>
                  {R.pathOr(
                    "",
                    ["levelOfLeaningForm", "levelOfLearningInput1"],
                    lessonPlanData
                  )}
                </Typography>
                <h4 align={"left"}>Most learners should be able to:</h4>
                <Typography style={{ whiteSpace: "pre-line" }}>
                  {R.pathOr(
                    "",
                    ["levelOfLeaningForm", "levelOfLearningInput2"],
                    lessonPlanData
                  )}
                </Typography>
                <h4 align={"left"}>Some learners may be able to:</h4>
                <Typography style={{ whiteSpace: "pre-line" }}>
                  {R.pathOr(
                    "",
                    ["levelOfLeaningForm", "levelOfLearningInput3"],
                    lessonPlanData
                  )}
                </Typography>
              </Box>
              <h3 className={classes.heading} align={"left"}>
                Introducing New Knowledge (How do you present the material for
                maximum retention?)
              </h3>
              <Box
                style={{ whiteSpace: "pre-line" }}
                fullWidth
                id="introNewKnowledgeInput"
              >
                {R.pathOr("", ["introNewKnowledgeInput"], lessonPlanData)}
              </Box>
              <h3 className={classes.heading} align={"left"}>
                Developing/Facilitating New Knowledge (How do you help them make
                connections?)
              </h3>

              <Box
                style={{ whiteSpace: "pre-line" }}
                fullWidth
                id="developNewKnowledgeInput"
              >
                {R.pathOr("", ["developNewKnowledgeInput"], lessonPlanData)}
              </Box>

              <h3 className={classes.heading} align={"left"}>
                Student Practice/Feedback/Formative Assessment
              </h3>

              <Box
                style={{ whiteSpace: "pre-line" }}
                fullWidth
                id="studentPracticeAssesmentInput"
              >
                {R.pathOr(
                  "",
                  ["studentPracticeAssesmentInput"],
                  lessonPlanData
                )}
              </Box>

              <h3 className={classes.heading} align={"left"}>
                Closure (Retention of New Learning)
              </h3>
              <Box
                style={{ whiteSpace: "pre-line" }}
                fullWidth
                id="closureInput"
              >
                {R.pathOr("", ["closureInput"], lessonPlanData)}
              </Box>
              <h3 className={classes.heading} align={"left"}>
                CONSENTS
              </h3>
              <ConsentForm
                page={"lessonPlan"}
                userRoles={userRoles}
                consentData={R.propOr([], "consents", lessonPlanData)}
                isReadOnly={true}
              />
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default LessonPlanReadView;
