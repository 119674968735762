import {
  Box,
  DialogTitle,
  DialogContent,
  Grid,
  IconButton,
  Dialog,
  Typography,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import UnitPlanDetailsForm from "common/forms/UnitPlanDetailsForm";
import withPrivilege from "common/hocs/withPrivilege";
import { FORM_TYPES, FORM_RENDERERS } from "common/utils/formUtility";
import { PAGE_RENDERER } from "common/utils/rendererList";
import GridViewWrapper from "common/wrappers/GridViewWrapper";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { useDispatch } from "react-redux";
import { UnitPlanFormInitialState } from "state/globalForms/initialStates";
import {
  clearForm,
  clearPageFilters,
  updateForm,
} from "state/slices/globalFormSlice";
import { PAGES } from "state/slices/pageSlice";
import { EntityMap } from "util/roleUtils";
import * as R from "ramda";

const useStyles = makeStyles((theme) => ({
  modalCloseBtn: {
    alignSelf: "center",
    textAlign: "center",
  },
}));

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

const ManageUnitPlan = ({ privileges, userRoles }) => {
  // const { open, onClose, formType, onSave, showActive, onActiveClick } = props;
  // const globalFormData = React.useSelector(state => state.globalForm[formType]);
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [selectedUnit, setSelectedUnit] = useState({});
  const dispatch = useDispatch();
  const handleChange = (event, newValue) => {
    if (newValue === 0) {
      dispatch(clearForm({ formType: FORM_TYPES.UNIT_PLAN_FORM }));
    }
    setValue(newValue);
    dispatch(
      updateForm({
        formType: FORM_TYPES.UNIT_PLAN_FORM,
        formData: UnitPlanFormInitialState,
      })
    );
  };
  const onTabChange = (value, data) => {
    setSelectedUnit(data);
    setValue(value);
  };

  useEffect(() => {
    dispatch(clearPageFilters());
  }, []);

  const [readViewOpen, setReadViewOpen] = useState(false);

  const onViewClose = () => {
    setReadViewOpen(false);
  };
  return (
    <>
      {value === 1 ? (
        // <UnitPlanDetailsForm unitData={selectedUnit} onViewClick={()=>setValue(0)} userRoles={userRoles}/>:
        <>
          <Dialog
            maxWidth="md"
            fullWidth
            open={true}
            onClose={() => setValue(0)}
            aria-labelledby="form-dialog-title"
          >
            <Grid container>
              <Grid item xs={10}>
                <DialogTitle id="form-dialog-title">
                  <Typography variant="h6">Edit Unit Plan</Typography>
                </DialogTitle>
              </Grid>
              <Grid item xs={2} className={classes.modalCloseBtn}>
                <IconButton onClick={() => setValue(0)}>
                  <Close />
                </IconButton>
              </Grid>
            </Grid>
            <DialogContent style={{ padding: "8px 25px" }}>
              <UnitPlanDetailsForm
                unitData={selectedUnit}
                onViewClick={() => setValue(0)}
                onTabChange={setValue}
                userRoles={userRoles}
              />
            </DialogContent>
          </Dialog>
        </>
      ) : null}
      <GridViewWrapper
        page={PAGES.unitPlan}
        paginatedListRenderer={PAGE_RENDERER.UNIT_PLAN}
        displayAdd={false}
        onTabChange={onTabChange}
        showFilters={true}
        privileges={privileges}
        userRoles={userRoles}
      />
      {/* <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Unit Plan" {...a11yProps(0)} />
                    {
                        R.propOr(true, "CREATE", privileges) && <Tab label="Edit Plan" {...a11yProps(1)} disabled/>
                    }
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
               
            </TabPanel>
            <TabPanel value={value} index={1}>
                <UnitPlanDetailsForm unitData={selectedUnit} onTabChange={()=>setValue(0)} userRoles={userRoles}/>
            </TabPanel> */}
    </>
  );
};

export default withPrivilege(
  ManageUnitPlan,
  EntityMap.unitplan,
  EntityMap.unitplanform
);
