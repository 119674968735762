import { Chip } from '@material-ui/core';
import { makeStyles } from "@material-ui/styles";
import * as React from 'react';

const useStyles = makeStyles(theme => ({
    chip: {
        marginRight: "1rem"
    }
}));

export default function DeleteableChips({ label, onDelete }) {
    const classes = useStyles();

    const handleDelete = () => {
        onDelete(label);
    };

    return (
        <Chip className={classes.chip} color="primary" label={label} onDelete={handleDelete} />
    );
}