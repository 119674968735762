import fetchIntercept from "fetch-intercept";
import { useDispatch } from "react-redux";
import {
  setPasswordChangeRequired,
  setCurrentUserContext,
  onUnauthorisedAccess,
} from "state/slices/userLoginFormSlice";

const useInterceptor = () => {
  const dispatch = useDispatch();
  fetchIntercept.register({
    request: function (url, config) {
      // Modify the url or config here
      if (url.includes("login")) {
        dispatch(
          setCurrentUserContext(
            JSON.parse(JSON.parse(JSON.stringify(config.body))).email
          )
        );
      }
      return [url, config];
    },

    requestError: function (error) {
      // Called when an error occured during another 'request' interceptor call
      return Promise.reject(error);
    },

    response: function (response) {
      // Modify the reponse object
      if (response.status === 403) {
        dispatch(onUnauthorisedAccess(true));
      }
      if (response.status === 307) {
        dispatch(setPasswordChangeRequired(true));
        return Promise.reject(new Error("password expired!"));
      }
      return response;
    },

    responseError: function (error) {
      // Handle an fetch error
      return Promise.reject(error);
    },
  });
};

export default useInterceptor;
