import {
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  TextField,
  OutlinedInput,
  Select,
  MenuItem,
} from "@material-ui/core";
import React from "react";
import * as R from "ramda";
import {
  LOVApiKeys,
  LOVDataKeys,
  LOVSelectKeys,
} from "common/components/SelectDropDown/SelectDropDownUtils";
import LOVSelect from "common/components/LOVSelect/LOVSelect";
import { getYearList } from "util/time";

const useStyles = makeStyles((theme) => ({
  inputFormControl: {
    marginBottom: "2rem",
  },
  inputLabel1: {
    top: "-17px",
    left: "-14px",
  },
  inputLabel: {
    top: "-17px",
  },
}));

const ManageSchoolForm = ({
  formType,
  isEditForm,
  isReadOnly,
  state,
  handleChange,
}) => {
  const classes = useStyles();

  const onChange = (event) => {
    const [value, name] = R.paths(
      [
        ["target", "value"],
        ["target", "name"],
      ],
      event
    );
    handleChange(name, value);
  };

  const getSelectList = (value) => {
    return value;
  };

  return (
    <form style={{ marginTop: "1.5rem" }}>
      <Grid container>
        <Grid item xs={12}>
          <FormControl className={classes.inputFormControl}>
            <InputLabel
              className={classes.inputLabel}
              id="demo-simple-select-outlined-label"
            >
              School Name
            </InputLabel>
            <TextField
              variant="outlined"
              margin="normal"
              name="schoolName"
              placeholder="Enter school name"
              value={state?.schoolName || ""}
              onChange={onChange}
              disabled={isEditForm}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl className={classes.inputFormControl}>
            <InputLabel
              className={classes.inputLabel}
              id="demo-simple-select-outlined-label"
            >
              Campus
            </InputLabel>
            <TextField
              variant="outlined"
              margin="normal"
              name="Enter campus"
              placeholder="Campus"
              value={state?.campus || ""}
              onChange={onChange}
              disabled={isEditForm}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl variant="outlined" className={classes.inputFormControl}>
            <InputLabel
              className={classes.inputLabel1}
              id="demo-simple-select-outlined-label"
            >
              Affiliated Board
            </InputLabel>
            <LOVSelect
              formType={formType}
              selectKey={LOVSelectKeys.AFFILIATED_BOARD}
              dataKey={LOVDataKeys.AFFILIATED_BOARD}
              lovKey={LOVApiKeys.AFFILIATED_BOARD}
              value={state?.affiliatedBoard || ""}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl variant="outlined" className={classes.inputFormControl}>
            <InputLabel
              className={classes.inputLabel1}
              id="demo-simple-select-outlined-label"
            >
              Established Year
            </InputLabel>
            <Select
              variant="outlined"
              margin="normal"
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              value={state?.establishedYear || ""}
              input={<OutlinedInput />}
              onChange={onChange}
              placeholder="Established Year"
              name="establishedYear"
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
            >
              <MenuItem value="" disabled>
                <span style={{ color: "#bbb" }}>Select established year</span>
              </MenuItem>
              {R.map((year) => {
                return <MenuItem value={year}>{year}</MenuItem>;
              }, getYearList(50))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl className={classes.inputFormControl}>
            <InputLabel
              className={classes.inputLabel}
              id="demo-simple-select-outlined-label"
            >
              Point of Contact
            </InputLabel>
            <TextField
              variant="outlined"
              margin="normal"
              name="pointOfContact"
              placeholder="Enter point of contact"
              value={state?.pointOfContact || ""}
              onChange={onChange}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl className={classes.inputFormControl}>
            <InputLabel
              className={classes.inputLabel}
              id="demo-simple-select-outlined-label"
            >
              School Code
            </InputLabel>
            <TextField
              variant="outlined"
              name="schoolCode"
              margin="normal"
              placeholder="Enter school code"
              value={state?.schoolCode || ""}
              onChange={onChange}
              disabled={isEditForm}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl className={classes.inputFormControl}>
            <InputLabel
              className={classes.inputLabel}
              id="demo-simple-select-outlined-label"
            >
              Email
            </InputLabel>
            <TextField
              variant="outlined"
              margin="normal"
              name="email"
              placeholder="Enter email address"
              value={state?.email || ""}
              onChange={onChange}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl className={classes.inputFormControl}>
            <InputLabel
              className={classes.inputLabel}
              id="demo-simple-select-outlined-label"
            >
              Website Link
            </InputLabel>
            <TextField
              variant="outlined"
              name="websiteLink"
              margin="normal"
              placeholder="Enter website link"
              value={state?.websiteLink || ""}
              onChange={onChange}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl className={classes.inputFormControl}>
            <InputLabel
              className={classes.inputLabel}
              id="demo-simple-select-outlined-label"
            >
              Address
            </InputLabel>
            <TextField
              variant="outlined"
              margin="normal"
              name="address"
              placeholder="Enter address"
              multiline
              rows={4}
              value={state?.address || ""}
              onChange={onChange}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl>
            <InputLabel
              className={classes.inputLabel}
              id="demo-simple-select-outlined-label"
            >
              Phone Number
            </InputLabel>
            <TextField
              variant="outlined"
              margin="normal"
              name="phoneNumber"
              placeholder="Enter phone number"
              value={state?.phoneNumber || ""}
              onChange={onChange}
            />
          </FormControl>
        </Grid>
        {/* <Grid item xs={12}>
          {!isEditForm && <StatusInput formType={formType} />}
        </Grid> */}
      </Grid>
    </form>
  );
};

export default ManageSchoolForm;
