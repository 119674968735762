import { Box, Grid, makeStyles, Typography, Button } from "@material-ui/core";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import React, { useEffect, useState } from "react";
import * as R from "ramda";
import { mapIndexed } from "common/utils/composition";
import { TierTopicFormInitialState } from "state/globalForms/initialStates";
//   import TierTopicForm from "./TierTopicForm";
import TierTopicForm from "./TierTopicForm";
import { colors } from "theme/colors";

const useStyles = makeStyles((theme) => ({
  inputFormControl: {
    marginBottom: "1.5rem",
  },
  addTierButton: {
    width: "0.75em",
    height: "0.75em",
  },
  addIconContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
  },
  tierTitle: {
    display: "flex",
    alignItems: "center",
  },
  tierCard: {
    margin: "1rem 0rem",
  },
  readOnlyContainer: {
    backgroundColor: "#263657",
    padding: "1rem",
    color: colors.gray1,
    marginBottom: "2rem",
    borderRadius: 6,
  },

  downloadBtn: {
    padding: "10px 15px",
    border: `1px solid ${colors.gray1}`,
    background: "#263657",
    color: colors.gray1,
  },
  inputLabel1: {
    top: "-17px",
    left: "-14px",
  },
  inputLabel: {
    top: "-17px",
  },
  checkIcon: {
    marginRight: "5px",
  },
  title: {
    fontWeight: 700,
    fontSize: "18px",
  },
}));

const TierPlanForm = ({ formType, isReadOnly, state, setState }) => {
  const classes = useStyles();
  const [tierPlansList, setTierPlanList] = useState([]);

  useEffect(() => {
    state && setTierPlanList([state])
  }, [state])

  const onTopicChange = (tierIndex, topicIndex, topicData) => {
    const updatedTierPlansList = R.assocPath(
      [tierIndex, "topics", topicIndex],
      topicData,
      tierPlansList
    );
    setTierPlanList(updatedTierPlansList);
    setState(updatedTierPlansList[0]);
  };

  const addNewTopic = (tierIndex) => {
    const updatedTierTopicPlans = R.append(
      TierTopicFormInitialState,
      tierPlansList[tierIndex].topics
    );
    const updatedTierList = R.assocPath(
      [tierIndex, "topics"],
      updatedTierTopicPlans,
      tierPlansList
    );
    setTierPlanList(updatedTierList);
    setState(updatedTierList[0]);
  };

  const onRemoveTopic = (topicIndex, tierIndex) => {
    const updatedTierTopicPlans = R.remove(
      topicIndex,
      1,
      tierPlansList[tierIndex].topics
    );

    const updatedTierList = R.assocPath(
      [tierIndex, "topics"],
      updatedTierTopicPlans,
      tierPlansList
    );

    setTierPlanList(updatedTierList);
    setState(updatedTierList[0]);
  };

  return (
    <Box id="state">
      <Grid container spacing={1} style={{ marginBottom: "10px" }}>
        <Grid item xs={6} className={classes.tierTitle}>
          <CheckCircleOutlineIcon
            className={classes.checkIcon}
            fontSize="small"
          />
          <Typography variant="h6" color="primary" className={classes.title}>
            Topic(s)
          </Typography>
        </Grid>
        <Grid item xs={6} className={classes.addIconContainer}>
          <Button
            disabled={isReadOnly}
            variant="outlined"
            onClick={() => addNewTopic(0)}
          >
            Add Topic
          </Button>
        </Grid>
      </Grid>
      {tierPlansList?.map((item, tierIndex) => {
        return (
          <>
            <Box>
              {item?.topics?.map((topic, topicIndex) => {
                return (
                  <TierTopicForm
                    key={`${tierIndex}-${topicIndex}`}
                    onRemoveTopic={() => onRemoveTopic(topicIndex, tierIndex)}
                    data={topic}
                    tierIndex={tierIndex}
                    topicIndex={topicIndex}
                    onTopicChange={onTopicChange}
                    formType={formType}
                    isReadOnly={isReadOnly}
                    state={state}
                    setState={setState}
                  />
                );
              })}
            </Box>
          </>
        );
      })}
    </Box>
  );
};

export default TierPlanForm;
