import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import * as R from "ramda";
import { colors } from "theme/colors";
import LOVSelect from "common/components/LOVSelect/LOVSelect";
import { FORM_TYPES } from "common/utils/formUtility";
import {
  LOVDataKeys,
  LOVKeyMap,
  LOVSelectKeys,
} from "common/components/SelectDropDown/SelectDropDownUtils";
import { useDispatch, useSelector } from "react-redux";
import { updateForm } from "state/slices/globalFormSlice";
import {
  getPageList,
  saveLessonPlanDetails,
} from "components/listOfValues/utils";
import {
  getLessonPlanFormPayload,
  getSelectList,
  isSubmitEnabled,
  setObservationsValue,
} from "./utils";
import { getTLMList } from "./LessonPlanPrePrimaryForm";
import ConsentForm from "./ConsentForm";
import ObservationSelectionForm from "./ObservationSelectionForm";
import { PAGES, setPageListItems } from "state/slices/pageSlice";
import TLMSequenceReferenceForm from "./TLMSequenceReferenceForm";
import TLMReferenceForm from "./TLMReferenceForm";

export const LessonPlanFormHiddenElem = [
  "conceptObjective",
  "skillObjective",
  "factObjective",
  "languageOfInstruction",
  "studentVocabulary",
  "symbioticLearning",
  "environmentalConsciousness",
  "designThinking",
  "emotionalWellness",
  "studentObjectives",
  "possibleMisconceptions",
  "assessmentRubric",
  "starterInput",
  "summarization",
  "levelOfLearningInput1",
  "levelOfLearningInput2",
  "levelOfLearningInput3",
  "teacherReflection",
];
const useStyle = makeStyles((theme) => ({
  heading: {
    color: "rgb(38, 54, 87)",
    marginTop: "2rem",
    marginBottom: "1rem",
  },
  readOnlyContainer: {
    backgroundColor: colors.blue1,
    padding: "1rem",
    color: colors.gray1,
    marginBottom: "2rem",
  },
  readOnlyTitle: {
    fontSize: "20px",
  },
  btn: {
    "&.MuiButton-root": {
      marginRight: "1rem",
    },
  },
}));

const LessonPlanForm = ({ lessonData, onTabChange, userRoles }) => {
  const classes = useStyle();
  const lessonPlanFormData = useSelector(
    (state) => state.globalForm[FORM_TYPES.LESSON_PLAN_FORM]
  );
  const [lessonPlanData, setlessonPlanData] = useState(lessonPlanFormData);
  const [TLMData, setTLMData] = useState([]);
  const [DTSList, setDTSList] = useState(
    R.propOr([], "teachingStrategies", lessonPlanData)
  );

  const [consentData, setConsentData] = useState(
    R.propOr([], "consents", lessonData)
  );
  let observationsData = useSelector(
    (state) => state.pages[PAGES.observations]
  );
  const dispatch = useDispatch();

  const onFormChange = (e, path) => {
    const updatedForm = R.assocPath(path, e.target.value, lessonPlanData);
    setlessonPlanData(updatedForm);
    dispatch(
      updateForm({
        formType: FORM_TYPES.LESSON_PLAN_FORM,
        formData: updatedForm,
      })
    );
  };
  const onSaveDetails = async (lessonStatus = "ACTIVE") => {
    const payload = getLessonPlanFormPayload({
      lessonData,
      lessonPlanData,
      TLMData,
      consentData,
      DTSList,
    });
    const response = await saveLessonPlanDetails(payload, lessonData.id);
    onTabChange();
  };

  const getObservationsData = async () => {
    const pageListData = await getPageList({
      searchKey: "",
      page: PAGES.observations,
    });
    dispatch(
      setPageListItems({
        page: PAGES.observations,
        itemList: pageListData.content,
        totalElements: pageListData.totalElements,
        totalPages: pageListData.totalPages,
      })
    );
  };

  useEffect(() => {
    if (R.isEmpty(TLMData)) {
      setTLMData(getTLMList(lessonData));
    }
    if (R.isEmpty(observationsData.items)) {
      getObservationsData();
    }
    setConsentData(R.propOr([], "consents", lessonData));
    setlessonPlanData(lessonPlanFormData);
  }, [lessonData, observationsData, lessonPlanFormData]);

  const isReadOnly = false;

  return (
    <Box
      sx={{
        margin: "1rem ",
      }}
      fullwidth
    >
      <h3 className={classes.heading} align={"left"}>
        Periods
      </h3>
      <Grid item xs={3}>
        <TextField
          variant="outlined"
          type="number"
          id="multiline-static"
          value={R.pathOr("", ["periods"], lessonPlanData)}
          fullWidth
          InputProps={{ inputProps: { min: 0 } }}
          onChange={(e) => onFormChange(e, ["periods"])}
          disabled={isReadOnly}
        />
      </Grid>
      <h3 className={classes.heading} align={"left"}>
        Learning Objectives for the Lesson
      </h3>
      <h4 align={"left"}>Concept Related Objectives</h4>

      <InputLabel>Students will be able to –</InputLabel>
      <TextField
        variant="outlined"
        margin="normal"
        id="multiline-static"
        placeholder="Students will be able to"
        multiline
        fullWidth
        data-html2canvas-ignore
        value={R.pathOr(
          "",
          ["learningObjectiveForm", "conceptObjective"],
          lessonPlanData
        )}
        onChange={(e) =>
          onFormChange(e, ["learningObjectiveForm", "conceptObjective"])
        }
        disabled={isReadOnly}
      />
      <Box fullWidth id="conceptObjective" display={"none"}>
        {R.pathOr(
          "",
          ["learningObjectiveForm", "conceptObjective"],
          lessonPlanData
        )}
      </Box>
      <h4 align={"left"}>
        Skill Related Objectives (Applying, Analysing, Evaluating, Creating)
      </h4>
      <InputLabel>Students will be able to –</InputLabel>
      <TextField
        variant="outlined"
        margin="normal"
        id="multiline-static"
        placeholder="Students will be able to"
        multiline
        value={R.pathOr(
          "",
          ["learningObjectiveForm", "skillObjective"],
          lessonPlanData
        )}
        data-html2canvas-ignore
        fullWidth
        onChange={(e) =>
          onFormChange(e, ["learningObjectiveForm", "skillObjective"])
        }
        disabled={isReadOnly}
      />
      <Box fullWidth id="skillObjective" display={"none"}>
        {R.pathOr(
          "",
          ["learningObjectiveForm", "skillObjective"],
          lessonPlanData
        )}
      </Box>
      <h4 align={"left"}>Fact Related Objectives (Remembering & Recalling)</h4>

      <InputLabel>Students will be able to –</InputLabel>
      <TextField
        variant="outlined"
        margin="normal"
        id="multiline-static"
        placeholder="Students will be able to"
        multiline
        value={R.pathOr(
          "",
          ["learningObjectiveForm", "factObjective"],
          lessonPlanData
        )}
        data-html2canvas-ignore
        fullWidth
        onChange={(e) =>
          onFormChange(e, ["learningObjectiveForm", "factObjective"])
        }
        disabled={isReadOnly}
      />
      <Box fullWidth id="factObjective" display={"none"}>
        {R.pathOr(
          "",
          ["learningObjectiveForm", "factObjective"],
          lessonPlanData
        )}
      </Box>
      <h4 align={"left"}>Vocabulary Related Objectives</h4>
      <InputLabel>
        Language of Instruction (used by teacher, comprehended by students)
      </InputLabel>
      <TextField
        variant="outlined"
        margin="normal"
        id="multiline-static"
        placeholder="Language of Instruction (used by teacher, comprehended by students)"
        multiline
        value={R.pathOr(
          "",
          [
            "learningObjectiveForm",
            "vocabularyObjective",
            "languageOfInstruction",
          ],
          lessonPlanData
        )}
        data-html2canvas-ignore
        fullWidth
        onChange={(e) =>
          onFormChange(e, [
            "learningObjectiveForm",
            "vocabularyObjective",
            "languageOfInstruction",
          ])
        }
        disabled={isReadOnly}
      />
      <Box fullWidth id="languageOfInstruction" display={"none"}>
        {R.pathOr(
          "",
          [
            "learningObjectiveForm",
            "vocabularyObjective",
            "languageOfInstruction",
          ],
          lessonPlanData
        )}
      </Box>
      <br />
      <br />
      <InputLabel>
        Student Vocabulary (internalised and use in context)
      </InputLabel>
      <TextField
        variant="outlined"
        margin="normal"
        id="multiline-static"
        placeholder="Student Vocabulary (internalised and use in context)"
        multiline
        value={R.pathOr(
          "",
          ["learningObjectiveForm", "vocabularyObjective", "studentVocabulary"],
          lessonPlanData
        )}
        data-html2canvas-ignore
        fullWidth
        onChange={(e) =>
          onFormChange(e, [
            "learningObjectiveForm",
            "vocabularyObjective",
            "studentVocabulary",
          ])
        }
        disabled={isReadOnly}
      />
      <Box fullWidth id="studentVocabulary" display={"none"}>
        {R.pathOr(
          "",
          ["learningObjectiveForm", "vocabularyObjective", "studentVocabulary"],
          lessonPlanData
        )}
      </Box>
      <Typography variant="h6" color="primary" className={classes.heading}>
        Values Related Objectives
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <h4 align={"left"}>Symbiotic Learning</h4>
          <InputLabel>Students will be able to -</InputLabel>
          <TextField
            variant="outlined"
            margin="normal"
            id="multiline-static"
            placeholder="Students will be able to"
            multiline
            value={R.pathOr(
              "",
              [
                "learningObjectiveForm",
                "valuesObjectives",
                "symbioticLearning",
              ],
              lessonPlanData
            )}
            data-html2canvas-ignore
            fullWidth
            onChange={(e) =>
              onFormChange(e, [
                "learningObjectiveForm",
                "valuesObjectives",
                "symbioticLearning",
              ])
            }
            disabled={isReadOnly}
          />
          <Box fullWidth id="symbioticLearning" display={"none"}>
            {R.pathOr(
              "",
              [
                "learningObjectiveForm",
                "valuesObjectives",
                "symbioticLearning",
              ],
              lessonPlanData
            )}
          </Box>
        </Grid>
        <Grid item xs={6}>
          <h4 align={"left"}>Environmental Consciousness</h4>
          <InputLabel>Students will be able to -</InputLabel>
          <TextField
            variant="outlined"
            margin="normal"
            id="multiline-static"
            placeholder="Students will be able to"
            multiline
            value={R.pathOr(
              "",
              [
                "learningObjectiveForm",
                "valuesObjectives",
                "environmentalConsciousness",
              ],
              lessonPlanData
            )}
            data-html2canvas-ignore
            fullWidth
            onChange={(e) =>
              onFormChange(e, [
                "learningObjectiveForm",
                "valuesObjectives",
                "environmentalConsciousness",
              ])
            }
            disabled={isReadOnly}
          />
          <Box fullWidth id="environmentalConsciousness" display={"none"}>
            {R.pathOr(
              "",
              [
                "learningObjectiveForm",
                "valuesObjectives",
                "environmentalConsciousness",
              ],
              lessonPlanData
            )}
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <h4 align={"left"}>Design Thinking</h4>
          <InputLabel>Students will be able to -</InputLabel>
          <TextField
            variant="outlined"
            margin="normal"
            id="multiline-static"
            placeholder="Students will be able to"
            multiline
            value={R.pathOr(
              "",
              ["learningObjectiveForm", "valuesObjectives", "designThinking"],
              lessonPlanData
            )}
            data-html2canvas-ignore
            fullWidth
            onChange={(e) =>
              onFormChange(e, [
                "learningObjectiveForm",
                "valuesObjectives",
                "designThinking",
              ])
            }
            disabled={isReadOnly}
          />
          <Box fullWidth id="designThinking" display={"none"}>
            {R.pathOr(
              "",
              ["learningObjectiveForm", "valuesObjectives", "designThinking"],
              lessonPlanData
            )}
          </Box>
        </Grid>
        <Grid item xs={6}>
          <h4 align={"left"}>Emotional Wellness</h4>
          <InputLabel>Students will be able to -</InputLabel>
          <TextField
            variant="outlined"
            margin="normal"
            id="multiline-static"
            placeholder="Students will be able to"
            multiline
            value={R.pathOr(
              "",
              [
                "learningObjectiveForm",
                "valuesObjectives",
                "emotionalWellness",
              ],
              lessonPlanData
            )}
            data-html2canvas-ignore
            fullWidth
            onChange={(e) =>
              onFormChange(e, [
                "learningObjectiveForm",
                "valuesObjectives",
                "emotionalWellness",
              ])
            }
            disabled={isReadOnly}
          />
          <Box fullWidth id="emotionalWellness" display={"none"}>
            {R.pathOr(
              "",
              [
                "learningObjectiveForm",
                "valuesObjectives",
                "emotionalWellness",
              ],
              lessonPlanData
            )}
          </Box>
        </Grid>
      </Grid>
      <Typography variant="h6" color="primary" className={classes.heading}>
        Students' own Learning Objectives
      </Typography>
      <InputLabel>Suggestion from Students</InputLabel>
      <TextField
        variant="outlined"
        margin="normal"
        id="multiline-static"
        placeholder="Suggestion from Students"
        multiline
        fullWidth
        data-html2canvas-ignore
        value={R.pathOr("", ["studentObjectives"], lessonPlanData)}
        onChange={(e) => onFormChange(e, ["studentObjectives"])}
        disabled={isReadOnly}
      />
      <Box fullWidth id="studentObjectives" display={"none"}>
        {R.pathOr("", ["studentObjectives"], lessonPlanData)}
      </Box>
      <br />

      <Typography variant="h6" color="primary" className={classes.heading}>
        Possible Misconceptions
      </Typography>
      <TextField
        variant="outlined"
        placeholder="Possible Misconceptions"
        id="multiline-static"
        multiline
        fullWidth
        data-html2canvas-ignore
        value={R.pathOr("", ["possibleMisconceptions"], lessonPlanData)}
        onChange={(e) => onFormChange(e, ["possibleMisconceptions"])}
        disabled={isReadOnly}
      />
      <Box fullWidth id="possibleMisconceptions" display={"none"}>
        {R.pathOr("", ["possibleMisconceptions"], lessonPlanData)}
      </Box>
      <br />

      <Typography variant="h6" color="primary" className={classes.heading}>
        Assessment Rubric with 3-4 Learning Levels to Guide Learning
      </Typography>
      <TextField
        variant="outlined"
        placeholder="Assessment Rubric with 3-4 Learning Levels to Guide Learning"
        id="multiline-static"
        multiline
        fullWidth
        data-html2canvas-ignore
        value={R.pathOr("", ["assessmentRubric"], lessonPlanData)}
        onChange={(e) => onFormChange(e, ["assessmentRubric"])}
        disabled={isReadOnly}
      />
      <Box fullWidth id="assessmentRubric" display={"none"}>
        {R.pathOr("", ["assessmentRubric"], lessonPlanData)}
      </Box>

      <Typography variant="h6" color="primary" className={classes.heading}>
        Gaining Attention/Hook/Starter/Activating Prior Knowledge
      </Typography>
      <InputLabel>How do you gain your student's attention?</InputLabel>
      <TextField
        variant="outlined"
        margin="normal"
        placeholder="How do you gain your student's attention?"
        id="multiline-static"
        multiline
        fullWidth
        data-html2canvas-ignore
        value={R.pathOr("", ["starterInput"], lessonPlanData)}
        onChange={(e) => onFormChange(e, ["starterInput"])}
        disabled={isReadOnly}
      />
      <Box fullWidth id="starterInput" display={"none"}>
        {R.pathOr("", ["starterInput"], lessonPlanData)}
      </Box>
      <Typography variant="h6" color="primary" className={classes.heading}>
        Introduction Activities
      </Typography>

      <FormControl className={classes.inputFormControl} fullWidth>
        <LOVSelect
          variant="outlined"
          margin="normal"
          formType={FORM_TYPES.LESSON_PLAN_FORM}
          selectKey={LOVSelectKeys.INTRO_ACTIVITIES}
          dataKey={LOVDataKeys.INTRO_ACTIVITIES}
          lovKey={LOVKeyMap.INTRO_ACTIVITIES}
          value={R.propOr([], "intoductionActivities", lessonPlanData)}
          multiselect={true}
          selectObj={true}
          getSelectList={getSelectList}
          isReadOnly={isReadOnly}
          showList={true}
        />
      </FormControl>
      <Typography variant="h6" color="primary" className={classes.heading}>
        Main Content / Core / Introducing New Knowledge
      </Typography>

      <TLMSequenceReferenceForm
        displayLOVSelect={true}
        TLMData={TLMData}
        setTLMData={setTLMData}
        isReadOnly={isReadOnly}
      />

      <Typography className={classes.heading} variant="h6" color="primary">
        Learning Outcomes
      </Typography>

      <ObservationSelectionForm
        className={R.pathOr("", ["curriculumContext", "gradeName"], lessonData)}
        formType={FORM_TYPES.LESSON_PLAN_FORM}
        value={setObservationsValue(
          R.pathOr([], ["learningOutcomes"], lessonPlanData),
          observationsData,
          R.pathOr("", ["curriculumContext", "gradeName"], lessonData)
        )}
        isReadOnly={isReadOnly}
      />

      <Typography style={{ marginBottom: "1rem" }} variant="h6" color="primary">
        Differentiated Teaching & Learning Strategies
      </Typography>

      <FormControl className={classes.inputFormControl} fullWidth>
        <TLMReferenceForm
          displayLOVSelect={true}
          TLMData={DTSList}
          setTLMData={setDTSList}
          lovSelectKeys={LOVSelectKeys.DTS_LIST}
          lovKeyMap={LOVKeyMap.DTS_LIST}
        />
      </FormControl>

      <Typography style={{ marginBottom: "1rem" }} variant="h6" color="primary">
        Summarization / Consolidation / Check for Understanding / Opportunity
        for Applying, Practicing and Reviewing
      </Typography>
      <TextField
        variant="outlined"
        id="multiline-static"
        multiline
        placeholder="Summarization / Consolidation / Check for Understanding / Opportunity for Applying, Practicing and Reviewing"
        fullWidth
        data-html2canvas-ignore
        value={R.pathOr("", ["summarization"], lessonPlanData)}
        onChange={(e) => onFormChange(e, ["summarization"])}
        disabled={isReadOnly}
      />
      <Box fullWidth id="summarization" display={"none"}>
        {R.pathOr("", ["summarization"], lessonPlanData)}
      </Box>

      <Typography className={classes.heading} variant="h6" color="primary">
        Real Life Connect / Cross Curricular Links / Subject Integration
      </Typography>
      <TextField
        variant="outlined"
        id="multiline-static"
        multiline
        fullWidth
        placeholder="Real Life Connect / Cross Curricular Links / Subject Integration"
        data-html2canvas-ignore
        value={R.pathOr("", ["realLifeConnect"], lessonPlanData)}
        onChange={(e) => onFormChange(e, ["realLifeConnect"])}
        disabled={isReadOnly}
      />
      <Box fullWidth id="realLifeConnect" display={"none"}>
        {R.pathOr("", ["realLifeConnect"], lessonPlanData)}
      </Box>

      <Typography className={classes.heading} variant="h6" color="primary">
        Assessment (AFL/ Self / Peer)
      </Typography>
      <TextField
        style={{ marginBottom: "1.5rem" }}
        variant="outlined"
        placeholder="Assessment (AFL/ Self / Peer)"
        id="multiline-static"
        multiline
        fullWidth
        data-html2canvas-ignore
        value={R.pathOr("", ["assessmentProjectTask"], lessonPlanData)}
        onChange={(e) => onFormChange(e, ["assessmentProjectTask"])}
        disabled={isReadOnly}
      />
      <Box fullWidth id="assessmentProjectTask" display={"none"}>
        {R.pathOr("", ["assessmentProjectTask"], lessonPlanData)}
      </Box>
      <Grid container xs={12} md={6}>
        <FormControl style={{ marginTop: "1rem" }}>
          <InputLabel style={{ top: "-24px" }}>Submission Date</InputLabel>
          <TextField
            variant="outlined"
            id="date"
            placeholder="Submission Date"
            name="assessmentSubmissionDate"
            type="date"
            sx={{ width: 220 }}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => onFormChange(e, ["assessmentSubmissionDate"])}
            value={R.pathOr("", ["assessmentSubmissionDate"], lessonPlanData)}
            disabled={isReadOnly}
          />
        </FormControl>
      </Grid>
      <Typography className={classes.heading} variant="h6" color="primary">
        Project / Home Assignment / Extension Task
      </Typography>
      <Grid container xs={12}>
        <TextField
          style={{ marginBottom: "1.5rem" }}
          variant="outlined"
          id="multiline-static"
          placeholder="Project / Home Assignment / Extension Task"
          value={R.pathOr("", ["assignmentProjectTask"], lessonPlanData)}
          multiline
          fullWidth
          data-html2canvas-ignore
          onChange={(e) => onFormChange(e, ["assignmentProjectTask"])}
          disabled={isReadOnly}
        />
        <Box fullWidth id="assignmentProjectTask" display={"none"}>
          {R.pathOr("", ["assignmentProjectTask"], lessonPlanData)}
        </Box>
      </Grid>

      <Grid container xs={12} md={6} style={{ marginTop: "1.5rem" }}>
        <FormControl className={classes.inputFormControl}>
          <InputLabel style={{ top: "-24px" }}>Submission Date</InputLabel>
          <TextField
            variant="outlined"
            id="date"
            name="assignmentSubmissionDate"
            placeholder={"Submission Date"}
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
            onChange={(e) => onFormChange(e, ["assignmentSubmissionDate"])}
            value={R.pathOr("", ["assignmentSubmissionDate"], lessonPlanData)}
            disabled={isReadOnly}
          />
        </FormControl>
      </Grid>
      <h3 className={classes.heading} align={"left"}>
        Level/s of Learning
      </h3>
      <InputLabel>All learners will be able to</InputLabel>
      <TextField
        variant="outlined"
        margin="normal"
        id="multiline-static"
        placeholder="All learners will be able to"
        multiline
        fullWidth
        data-html2canvas-ignore
        value={R.pathOr(
          "",
          ["levelOfLeaningForm", "levelOfLearningInput1"],
          lessonPlanData
        )}
        onChange={(e) =>
          onFormChange(e, ["levelOfLeaningForm", "levelOfLearningInput1"])
        }
        disabled={isReadOnly}
      />
      <Box fullWidth id="levelOfLearningInput1" display={"none"}>
        {R.pathOr(
          "",
          ["levelOfLeaningForm", "levelOfLearningInput1"],
          lessonPlanData
        )}
      </Box>
      <br />
      <br />
      <InputLabel>Most learners will be able to</InputLabel>
      <TextField
        variant="outlined"
        margin="normal"
        id="multiline-static"
        placeholder="Most learners will be able to"
        multiline
        fullWidth
        data-html2canvas-ignore
        value={R.pathOr(
          "",
          ["levelOfLeaningForm", "levelOfLearningInput2"],
          lessonPlanData
        )}
        onChange={(e) =>
          onFormChange(e, ["levelOfLeaningForm", "levelOfLearningInput2"])
        }
        disabled={isReadOnly}
      />
      <Box fullWidth id="levelOfLearningInput2" display={"none"}>
        {R.pathOr(
          "",
          ["levelOfLeaningForm", "levelOfLearningInput2"],
          lessonPlanData
        )}
      </Box>
      <br />
      <br />
      <InputLabel>Some learners will be able to</InputLabel>
      <TextField
        variant="outlined"
        margin="normal"
        id="multiline-static"
        placeholder="Some learners will be able to"
        multiline
        fullWidth
        data-html2canvas-ignore
        value={R.pathOr(
          "",
          ["levelOfLeaningForm", "levelOfLearningInput3"],
          lessonPlanData
        )}
        onChange={(e) =>
          onFormChange(e, ["levelOfLeaningForm", "levelOfLearningInput3"])
        }
        disabled={isReadOnly}
      />
      <Box fullWidth id="levelOfLearningInput3" display={"none"}>
        {R.pathOr(
          "",
          ["levelOfLeaningForm", "levelOfLearningInput3"],
          lessonPlanData
        )}
      </Box>
      <h3 className={classes.heading} align={"left"}>
        Teacher Reflections
      </h3>
      <TextField
        variant="outlined"
        id="multiline-static"
        multiline
        placeholder="Teacher Reflections"
        data-html2canvas-ignore
        fullWidth
        value={R.propOr("", "teacherReflection", lessonPlanData)}
        onChange={(e) => onFormChange(e, ["teacherReflection"])}
        disabled={isReadOnly}
      />
      <Box fullWidth id="teacherReflection" display={"none"}>
        {R.propOr("", "teacherReflection", lessonPlanData)}
      </Box>
      <h3 className={classes.heading} align={"left"}>
        CONSENTS
      </h3>
      <ConsentForm
        page={"lessonPlan"}
        userRoles={userRoles}
        consentData={consentData}
        setConsentData={setConsentData}
        isReadOnly={isReadOnly}
      />
      <br />
      <Box sx={{ display: "flex" }}>
        <Button
          color="primary"
          onClick={onSaveDetails}
          variant="outlined"
          className={classes.btn}
          disabled={isReadOnly}
          data-html2canvas-ignore
        >
          Save
        </Button>
        <Button
          color="primary"
          onClick={() => onSaveDetails("COMPLETED")}
          variant="contained"
          disabled={!isSubmitEnabled(consentData) || isReadOnly}
          data-html2canvas-ignore
        >
          Submit
        </Button>
      </Box>
    </Box>
  );
};

export default LessonPlanForm;
