import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import React, { useEffect, useState, useCallback, useRef } from "react";
import {
  RemoveCircleOutline,
  ExpandMoreOutlined,
  Close,
} from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import * as R from "ramda";
import { PAGES, updatePageListItems } from "state/slices/pageSlice";
import { colors } from "theme/colors";
import {
  deleteGoals,
  readGoalSettingForm,
  saveGoalEvidence,
  saveGoals,
  updateGoalSettingFormState,
} from "components/listOfValues/utils";
import { useDispatch, useSelector } from "react-redux";
import GoalsLibrary from "./GoalsLibrary";
import Comment from "common/components/Comment/Comment";
import { goalSettingFormStateOrder } from "./SetGoalListView";
import FileUploader from "common/components/FileUploader/FileUploader";
import GoalEvidenceList from "./GoalEvidenceList";

const useStyle = makeStyles((theme) => ({
  alignCenter: {
    display: "flex",
    alignItems: "center",
  },
  pointer: {
    cursor: "pointer",
  },
  iconContainer: {
    display: "flex",
    justifyContent: "end",
  },
  inputFormControl: {
    marginTop: "1.5rem",
  },
  editViewContainer: {
    textAlign: "end",
  },
  saveBtn: {
    marginRight: "1rem",
  },
  evidenceBtn: {
    marginRight: "1rem",
  },
  editIconBtn: {
    margin: "0px 8px",
    padding: "0px",
  },
  readViewContainer: {
    textAlign: "end",
  },
  subUnitChip: {
    "&.MuiChip-root": {
      marginRight: "1rem",
      color: colors.blue1,
      fontSize: "16px",
      fontWeight: "500",
    },
  },
  heading: {
    fontWeight: "600",
  },
  editIcon: {
    "&.MuiSvgIcon-root": {
      fontSize: "1.3rem",
    },
  },
  headingsContainer: {
    textAlign: "left",
  },
  inputLabel: {
    top: "-17px",
  },
  inputLabel1: {
    top: "-17px",
    left: "-14px",
  },
  placeholderValue: {
    color: "#bbb",
  },
  displayobservation: {
    background: "#898eac2e",
    border: "1px solid #898EAC",
    boxShadow: "none",
    padding: "0.5rem 1rem",
    borderRadius: 6,
    marginBottom: "0px",
  },
  displayobservation1: {
    background: "#898eac2e",
    border: "1px solid #898EAC",
    boxShadow: "none",
    borderRadius: 6,
    marginBottom: "10px",
  },
  editfields: {
    margin: "1.5rem 0",
    border: "1px solid #26365785",
    borderRadius: 4,
    padding: "1rem",
    background: "#fff",
  },
}));
const SetGoalInitialState = {
  goalDetails: "",
  weightage: 0,
  measurementUnit: "",
  evidence: "-",
};

const SetGoals = ({ goalSettingForm }) => {
  const userProfile = useSelector((state) => state.userProfile.userProfile);
  const classes = useStyle();
  const dispatch = useDispatch();

  // state variables for goals
  const [goals, setGoals] = useState(goalSettingForm.goals);
  const [accordianExpandedState, setAccordianExpandedState] = useState({});

  // state variables for goal removal
  const [removeGoalModal, setRemoveGoalModal] = useState(false);
  const [goalToDelete, setGoalToDelete] = useState({});

  // state variables for goal evidence
  const evidenceCommentRef = useRef(null);
  const [evidenceDialogOpen, setEvidenceDialogOpen] = useState(false);
  const [evidenceFile, setEvidenceFile] = useState(null);
  const [selectedGoalId, setSelectedGoalId] = useState(null);

  // state variables for goal comments
  const [viewCommentDialogOpen, setViewCommentDialogOpen] = useState(false);

  // state variables for goal learnings
  const [goalLearnings, setGoalLearnings] = useState({});

  // helper functions for goals
  const handleChange = (e, index) => {
    let updatedGoals = [];
    updatedGoals = R.assocPath([index, e.target.name], e.target.value, goals);
    setGoals(updatedGoals);
  };

  const onAddGoal = () => {
    const updatedGoals = R.append(SetGoalInitialState, goals);
    setGoals(updatedGoals);
    let expandStateArray = { ...accordianExpandedState };
    expandStateArray[updatedGoals.length - 1] = true;

    setAccordianExpandedState(expandStateArray);
  };

  const onGoalsLibrarySelect = useCallback(
    (description, measurementUnit, evidence, index) => {
      let updatedGoals = [];
      updatedGoals = R.pipe(
        R.assocPath([index, "goalDetails"], description),
        R.assocPath([index, "measurementUnit"], measurementUnit),
        R.assocPath([index, "evidence"], evidence)
      )(goals);
      setGoals(updatedGoals);
    },
    [goals]
  );

  const updateGoalSettingForm = async () => {
    const response = await readGoalSettingForm(goalSettingForm.id);
    dispatch(
      updatePageListItems({
        page: PAGES.setGoal,
        itemObject: response,
      })
    );
    setGoals(response.goals);
  };

  const onGoalSave = async (goal, index) => {
    if (goal.id && goal.id.length) {
      goal.dirty = true;
      goal.goalSettingFormId = goalSettingForm.id;
      await saveGoals(goal, goal.id);
    } else {
      goal.goalSettingFormId = goalSettingForm.id;
      await saveGoals(goal);
    }

    let expandStateArray = { ...accordianExpandedState };
    expandStateArray[index] = undefined;
    setAccordianExpandedState(expandStateArray);
    updateGoalSettingForm();
  };

  const onStateUpdate = async (state) => {
    await updateGoalSettingFormState(goalSettingForm.id, state);
    updateGoalSettingForm();
  };

  // helper functions for goal removal
  const openRemoveGoalModal = (goal) => {
    setRemoveGoalModal(true);
    setGoalToDelete(goal);
  };

  const removeGoal = async (index) => {
    if (goalToDelete.id && goalToDelete.id.length) {
      await deleteGoals(goalToDelete.id);
    }
    const expandStateArray = Object.keys(accordianExpandedState).reduce(
      (accumulator, key) => {
        return { ...accumulator, [key]: undefined };
      },
      {}
    );
    setAccordianExpandedState(expandStateArray);

    updateGoalSettingForm();
    setRemoveGoalModal(false);
  };

  // helper functions for goal evidence
  const onEvidenceDialogClose = () => {
    evidenceCommentRef.current.value = null;
    setEvidenceFile(null);
    setEvidenceDialogOpen(false);
    setSelectedGoalId(null);
  };

  const onEvidenceDialogOpen = (goalId) => {
    setEvidenceDialogOpen(true);
    setSelectedGoalId(goalId);
  };

  const handleFileUpload = async () => {
    const evidenceMetaData = {
      comment: evidenceCommentRef.current?.value,
      goalId: selectedGoalId,
    };
    const formData = new FormData();
    formData.append("evidenceMetadata", JSON.stringify(evidenceMetaData));
    formData.append("file", evidenceFile);
    await saveGoalEvidence(formData);
    onEvidenceDialogClose();
    updateGoalSettingForm();
  };

  // helper functions for goal comments
  const onViewCommentDialogOpen = () => {
    setViewCommentDialogOpen(true);
  };

  //helper functions for goal learnings
  const onGoalSettingFormSelfFeedbackPayloadChange = (event) => {
    const [value, name] = R.paths(
      [
        ["target", "value"],
        ["target", "name"],
      ],
      event
    );
    setGoalLearnings({
      ...goalLearnings,
      [name]: value,
    });
  };

  const onSaveLearning = async (state) => {
    await updateGoalSettingFormState(goalSettingForm.id, state, goalLearnings);
    updateGoalSettingForm();
  };

  useEffect(() => {
    setGoals(goalSettingForm.goals);
  }, [goalSettingForm]);

  useEffect(() => {
    setGoalLearnings({
      strength: goalSettingForm.strength,
      weakness: goalSettingForm.weakness,
      learningRequired: goalSettingForm.learningRequired,
    });
  }, [
    goalSettingForm.learningRequired,
    goalSettingForm.strength,
    goalSettingForm.weakness,
  ]);

  const renderButton = () => {
    if (
      goalSettingFormStateOrder[goalSettingForm.goalSettingState] <=
      goalSettingFormStateOrder["REASSESSMENT_REQUIRED"]
    ) {
      return (
        <Button
          color="primary"
          variant="contained"
          onClick={() => onStateUpdate("SENT_FOR_REVIEW")}
        >
          {" "}
          Submit For Review{" "}
        </Button>
      );
    } else if (
      goalSettingFormStateOrder[goalSettingForm.goalSettingState] >=
      goalSettingFormStateOrder["REVIEWED"]
    ) {
      return (
        <Button
          color="primary"
          style={{ marginRight: 20 }}
          variant="contained"
          onClick={() => onSaveLearning(goalSettingForm.goalSettingState)}
        >
          {" "}
          Save Learnings{" "}
        </Button>
      );
    }
  };

  return (
    <>
      <Grid container fullWidth>
        <Grid
          item
          xs={12}
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <Button
            color="primary"
            type="button"
            variant="outlined"
            onClick={onAddGoal}
            disabled={
              goalSettingFormStateOrder[goalSettingForm.goalSettingState] >=
              goalSettingFormStateOrder["REVIEWED"]
            }
          >
            Add Goal
          </Button>
        </Grid>
      </Grid>

      {goals?.length === 0 && (
        <Grid container spacing={3} fullWidth>
          <Grid item xs={12} className={classes.alignCenter}>
            <Typography>No goals available</Typography>
          </Grid>
        </Grid>
      )}
      <Box>
        {
          <Box className={classes.editViewContainer}>
            {goals?.map((goal, index) => {
              if (goal.deleted) {
                return null;
              }
              return (
                <>
                  <Accordion
                    className={classes.displayobservation1}
                    defaultExpanded={!goal.id || goal.id.length === 0}
                    expanded={!!accordianExpandedState[index]}
                    onChange={(_, expanded) => {
                      let expandStateArray = { ...accordianExpandedState };
                      expandStateArray[index] = expanded;
                      setAccordianExpandedState(expandStateArray);
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreOutlined />}
                      aria-controls="panel1d-content"
                      id="panel1d-header"
                    >
                      <Grid container>
                        <Grid item xs={12} md={9}>
                          <Typography variant="subtitle2" align="left">
                            Goal Details: {goal.goalDetails}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <Typography variant="subtitle1" align="left">
                            Weightage: {goal.weightage}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={9}>
                          <Typography variant="subtitle2" align="left">
                            Measurement: {goal.measurementUnit}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <Typography variant="subtitle2" align="left">
                            Evidence Accepted: {goal.evidence ?? "-"}
                          </Typography>
                        </Grid>
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails
                      className={classes.outcomeAccordionContainer}
                    >
                      <Grid
                        container
                        spacing={1}
                        className={classes.editfields}
                      >
                        {goalSettingFormStateOrder[
                          goalSettingForm.goalSettingState
                        ] < goalSettingFormStateOrder["REVIEWED"] ? (
                          <>
                            <Grid item xs={12} style={{ alignSelf: "center" }}>
                              <GoalsLibrary
                                goalSettingForm={goalSettingForm}
                                onSelect={(
                                  description,
                                  measurementUnit,
                                  evidence
                                ) =>
                                  onGoalsLibrarySelect(
                                    description,
                                    measurementUnit,
                                    evidence,
                                    index
                                  )
                                }
                              />
                            </Grid>
                            <Grid item xs={12} style={{ alignSelf: "center" }}>
                              <FormControl
                                variant="outlined"
                                className={classes.inputFormControl}
                              >
                                <InputLabel
                                  className={classes.inputLabel1}
                                  id="demo-simple-select-outlined-label"
                                >
                                  Goal Details
                                </InputLabel>
                                <TextField
                                  variant="outlined"
                                  multiline
                                  id="date"
                                  margin="normal"
                                  name="goalDetails"
                                  placeholder={"Enter goal details"}
                                  type="input"
                                  sx={{ width: 220 }}
                                  disabled={
                                    goalSettingForm.goalSettingState ===
                                    "REVIEWED"
                                  }
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  onChange={(e) => handleChange(e, index)}
                                  value={R.propOr("", "goalDetails", goal)}
                                />
                              </FormControl>
                            </Grid>

                            <Grid
                              item
                              xs={12}
                              md={5}
                              style={{ alignSelf: "center" }}
                            >
                              <FormControl
                                variant="outlined"
                                className={classes.inputFormControl}
                              >
                                <InputLabel
                                  className={classes.inputLabel1}
                                  id="demo-multiple-name-label"
                                >
                                  Measurement
                                </InputLabel>
                                <TextField
                                  variant="outlined"
                                  id="date"
                                  margin="normal"
                                  name="measurementUnit"
                                  placeholder={"Enter Measurement unit"}
                                  type="input"
                                  sx={{ width: 220 }}
                                  disabled={
                                    goalSettingForm.goalSettingState ===
                                    "REVIEWED"
                                  }
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  onChange={(e) => handleChange(e, index)}
                                  value={R.propOr("", "measurementUnit", goal)}
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} md={5}>
                              <FormControl className={classes.inputFormControl}>
                                <InputLabel className={classes.inputLabel}>
                                  Evidence
                                </InputLabel>
                                <TextField
                                  variant="outlined"
                                  id="date"
                                  disabled
                                  margin="normal"
                                  name="evidence"
                                  placeholder={"Enter Evidence"}
                                  sx={{ width: 220 }}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  onChange={(e) => handleChange(e, index)}
                                  value={R.propOr("-", "evidence", goal)}
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} md={1}>
                              <FormControl className={classes.inputFormControl}>
                                <InputLabel className={classes.inputLabel}>
                                  Weightage
                                </InputLabel>
                                <TextField
                                  variant="outlined"
                                  id="date"
                                  margin="normal"
                                  name="weightage"
                                  placeholder={"Enter Weightage"}
                                  type="number"
                                  sx={{ width: 220 }}
                                  disabled={
                                    goalSettingForm.goalSettingState ===
                                    "REVIEWED"
                                  }
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  onChange={(e) => handleChange(e, index)}
                                  value={R.propOr(0, "weightage", goal)}
                                />
                              </FormControl>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              md={1}
                              style={{
                                marginTop: "1rem",
                                alignSelf: "center",
                                textAlign: "initial",
                              }}
                            >
                              {
                                <>
                                  <IconButton
                                    color="primary"
                                    onClick={() => openRemoveGoalModal(goal)}
                                    disabled={
                                      goalSettingForm.goalSettingState ===
                                      "REVIEWED"
                                    }
                                  >
                                    <RemoveCircleOutline />
                                  </IconButton>
                                  <Dialog
                                    maxWidth="sm"
                                    fullWidth
                                    open={removeGoalModal}
                                    onClose={() => setRemoveGoalModal(false)}
                                    aria-labelledby="form-dialog-title"
                                  >
                                    <Grid container>
                                      <Grid item xs={11}>
                                        <DialogTitle id="form-dialog-title">
                                          Confirmation
                                        </DialogTitle>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={1}
                                        className={classes.modalCloseBtn}
                                      >
                                        <IconButton
                                          onClick={() =>
                                            setRemoveGoalModal(false)
                                          }
                                        >
                                          <Close />
                                        </IconButton>
                                      </Grid>
                                    </Grid>
                                    <DialogContent>
                                      <Typography varinat="h4">
                                        Are you sure you want to delete this
                                        goal?
                                      </Typography>
                                    </DialogContent>
                                    <DialogActions>
                                      <Button
                                        onClick={() =>
                                          setRemoveGoalModal(false)
                                        }
                                        variant="outlined"
                                        type="button"
                                        color="primary"
                                      >
                                        Cancel
                                      </Button>
                                      <Button
                                        onClick={() => removeGoal(index)}
                                        variant="contained"
                                        type="button"
                                        color="primary"
                                      >
                                        Delete
                                      </Button>
                                    </DialogActions>
                                  </Dialog>
                                </>
                              }
                            </Grid>
                          </>
                        ) : null}
                        {goal.evidences?.length ? (
                          <GoalEvidenceList
                            evidences={goal.evidences}
                            postMethod={updateGoalSettingForm}
                          />
                        ) : null}
                        <Grid item xs={12}>
                          <Button
                            className={classes.evidenceBtn}
                            color="primary"
                            type="button"
                            variant="text"
                            disabled={
                              goalSettingFormStateOrder[
                                goalSettingForm.goalSettingState
                              ] < goalSettingFormStateOrder["REVIEWED"]
                            }
                            onClick={() => onEvidenceDialogOpen(goal.id)}
                          >
                            Upload Evidences
                          </Button>
                          {goalSettingFormStateOrder[
                            goalSettingForm.goalSettingState
                          ] < goalSettingFormStateOrder["REVIEWED"] ? (
                            <Button
                              className={classes.saveBtn}
                              color="primary"
                              type="button"
                              variant="contained"
                              onClick={() => onGoalSave(goal, index)}
                            >
                              Save
                            </Button>
                          ) : null}
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </>
              );
            })}
            {goalSettingFormStateOrder[goalSettingForm.goalSettingState] >=
            goalSettingFormStateOrder["REVIEWED"] ? (
              <Box
                sx={{
                  paddingLeft: "1rem",
                  paddingRight: "1rem",
                }}
              >
                <h3 className={classes.heading} align={"left"}>
                  Goal Learnings
                </h3>
                <InputLabel>Strength</InputLabel>
                <TextField
                  variant="outlined"
                  margin="normal"
                  id="multiline-static"
                  placeholder="Write Strength"
                  multiline
                  data-html2canvas-ignore
                  value={goalLearnings.strength}
                  name="strength"
                  onChange={onGoalSettingFormSelfFeedbackPayloadChange}
                />
                <InputLabel>Area of Development</InputLabel>
                <TextField
                  variant="outlined"
                  margin="normal"
                  id="multiline-static"
                  placeholder="Write area of development"
                  multiline
                  data-html2canvas-ignore
                  value={goalLearnings.weakness}
                  name="weakness"
                  onChange={onGoalSettingFormSelfFeedbackPayloadChange}
                />
                <InputLabel>Training Required</InputLabel>
                <TextField
                  variant="outlined"
                  margin="normal"
                  id="multiline-static"
                  placeholder="Write training that are still required"
                  multiline
                  data-html2canvas-ignore
                  value={goalLearnings.learningRequired}
                  name="learningRequired"
                  onChange={onGoalSettingFormSelfFeedbackPayloadChange}
                />
              </Box>
            ) : null}
            {goalSettingForm?.reviewComments?.length ? (
              <Button
                color="primary"
                variant="text"
                onClick={onViewCommentDialogOpen}
                style={{ marginRight: 20 }}
              >
                {`View ${goalSettingForm?.reviewComments?.length} Comments`}
              </Button>
            ) : (
              <Button
                color="primary"
                variant="text"
                onClick={onViewCommentDialogOpen}
                style={{ marginRight: 20 }}
                disabled
              >
                {`No Comments`}
              </Button>
            )}
            {/* same like ADD GOAL - isEditEnabled condition */}
            {renderButton()}
            <Dialog
              maxWidth="md"
              fullWidth
              open={viewCommentDialogOpen}
              onClose={() => setViewCommentDialogOpen(false)}
              aria-labelledby="form-dialog-title"
            >
              <Grid container>
                <Grid item xs={10}>
                  <DialogTitle id="form-dialog-title">
                    <Typography variant="h6">Comments</Typography>
                  </DialogTitle>
                </Grid>
                <Grid item xs={2} className={classes.modalCloseBtn}>
                  <IconButton onClick={() => setViewCommentDialogOpen(false)}>
                    <Close />
                  </IconButton>
                </Grid>
              </Grid>
              <DialogContent style={{ padding: "8px 25px" }}>
                <Comment
                  comments={goalSettingForm?.reviewComments}
                  primaryDataVariale="reviewComment"
                  secondaryLabelVariable="reviewState"
                />
              </DialogContent>
            </Dialog>
            <Dialog
              maxWidth="md"
              fullWidth
              open={evidenceDialogOpen}
              onClose={onEvidenceDialogClose}
              aria-labelledby="form-dialog-title"
            >
              <Grid container>
                <Grid item xs={11}>
                  <DialogTitle id="form-dialog-title">
                    Upload Evidences
                  </DialogTitle>
                </Grid>
                <Grid item xs={1}>
                  <IconButton onClick={onEvidenceDialogClose}>
                    <Close />
                  </IconButton>
                </Grid>
              </Grid>
              <DialogContent style={{ padding: "8px 25px" }}>
                <FileUploader file={evidenceFile} setFile={setEvidenceFile} />
                <Grid item xs={6}>
                  <InputLabel className={classes.inputLabel}>
                    Comment
                  </InputLabel>
                  <TextField
                    margin="normal"
                    type="text"
                    id="multiline-static"
                    rows={14}
                    placeholder="Enter comments"
                    inputRef={evidenceCommentRef}
                    fullWidth
                  />
                </Grid>
              </DialogContent>
              <DialogActions style={{ padding: "8px 35px 8px 8px" }}>
                <Button
                  variant="outlined"
                  onClick={onEvidenceDialogClose}
                  color="primary"
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  onClick={handleFileUpload}
                  color="primary"
                >
                  Submit
                </Button>
              </DialogActions>
            </Dialog>
          </Box>
        }
      </Box>
    </>
  );
};

export default SetGoals;
