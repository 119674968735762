import React from "react";
import {
  makeStyles,
  Typography,
  Grid,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";
import * as R from "ramda";
import { colors } from "theme/colors";

const useStyles = makeStyles((theme) => ({
  tableCell: {
    color: "#fff",
  },
  label: {
    color: colors.gray4,
  },
  activeLabel: {
    color: colors.green,
  },
  deactivateLabel: {
    color: colors.red,
  },
}));

const TeacherReadView = ({ data, showTitle = true }) => {
  const classes = useStyles();
  return (
    <Box style={{ marginBottom: "1.5rem" }}>
      <Grid container spacing={1}>
        {showTitle && (
          <Grid item xs={12}>
            <Typography variant="h6" color="primary">
              {R.propOr("", "firstName", data)} {R.propOr("", "lastName", data)}
            </Typography>
          </Grid>
        )}

        <Grid item xs={12}>
          <Typography variant="subtitle2">
            Email:{R.propOr("", "email", data)}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle2">
            Mobile: {R.propOr("", "mobile", data)}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="subtitle2">
            School: {R.pathOr("-", ["school", "schoolName"], data)}{" "}
            {R.pathOr("-", ["school", "campus"], data)}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="subtitle2">
            Designation: {R.pathOr("-", ["designation"], data)}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="subtitle2">
            Address: {R.propOr("-", "currentAddress", data)}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography
            className={
              R.propOr("ACTIVE", "status", data) === "ACTIVE"
                ? classes.activeLabel
                : classes.deactivateLabel
            }
          >
            <b>Status: {R.propOr("ACTIVE", "status", data)} </b>
          </Typography>
        </Grid>
      </Grid>

      {!R.isEmpty(R.propOr([], "teachers", data)) && (
        <Box style={{ margin: "1.5rem 0rem" }}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="simple table">
              <TableHead style={{ background: "#263657" }}>
                <TableRow>
                  <TableCell className={classes.tableCell}>School</TableCell>
                  <TableCell className={classes.tableCell}>Class</TableCell>
                  <TableCell className={classes.tableCell}>Section</TableCell>
                  <TableCell className={classes.tableCell}>Subject</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {R.map((item) => {
                  return (
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        <Typography variant="subtitle2">
                          {`${R.pathOr(
                            "",
                            ["section", "school", "schoolName"],
                            item
                          )} - ${R.pathOr(
                            "",
                            ["section", "school", "campus"],
                            item
                          )}`}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        {R.pathOr("", ["section", "grade", "gradeName"], item)}
                      </TableCell>
                      <TableCell>
                        {R.pathOr("", ["section", "section"], item)}
                      </TableCell>
                      <TableCell>
                        {R.pathOr("", ["subject", "subjectName"], item)}
                      </TableCell>
                    </TableRow>
                  );
                })(R.propOr([], "teachers", data))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
    </Box>
  );
};

export default TeacherReadView;
