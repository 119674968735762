import React from "react";
import {
  makeStyles,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Chip,
} from "@material-ui/core";
import { colors } from "theme/colors";
import * as R from "ramda";
import { CheckCircleOutline, ExpandMoreOutlined } from "@material-ui/icons";
import { mapIndexed } from "common/utils/composition";

const useStyles = makeStyles((theme) => ({
  subHeading: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightBold,
    color: "#263657",
  },
  editIconContainer: {
    display: "flex",
    alignItems: "baseline",
    justifyContent: "flex-end",
  },
  editIconBtn: {
    padding: "0px",
  },
  editIcon: {
    height: "1.5rem",
    width: "1.5rem",
    fontSize: "1.5rem",
    color: "rgba(0, 0, 0, 0.54)",
  },
  label: {
    color: colors.gray4,
  },
  activeLabel: {
    color: colors.green,
  },
  deactivateLabel: {
    color: colors.red,
  },
  formContainer: {
    marginBottom: "0.5rem",
  },
  pointer: {
    display: "flex",
    alignItems: "center",
  },
  accordionDetails: {
    display: "inline",
    padding: "1rem",
    height: "auto",
    paddingLeft: "1rem",
    paddingRight: "1rem",
  },
  outcomeList: {
    height: "auto",
    width: "100%",
  },
  outcomeAccordionContainer: {
    overflow: "auto",
  },
  observationRegistryHeader: {
    backgroundColor: "#263657",
    color: colors.gray1,
    borderRadius: 6,
  },
  topIcon: {
    color: colors.gray1,
  },
  classChip: {
    borderColor: colors.gray1,
    marginLeft: "1rem",
    color: colors.gray1,
  },
  displayobservation: {
    background: "#898eac2e",
    border: "1px solid #898EAC",
    boxShadow: "none",
  },
}));

const DisplayEachObservation = ({ data, classes }) => {
  return (
    <Accordion className={classes.displayobservation}>
      <AccordionSummary
        expandIcon={<ExpandMoreOutlined />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography className={classes.subHeading}>
          {R.propOr("", "name", data)}
        </Typography>
      </AccordionSummary>
      <AccordionDetails className={classes.outcomeAccordionContainer}>
        <List className={classes.outcomeList}>
          {mapIndexed((eachOutcome, index) => {
            return (
              <ListItem key={index}>
                <ListItemAvatar>
                  <CheckCircleOutline fontSize={"small"} color="primary" />
                </ListItemAvatar>
                <ListItemText
                  className={classes.listText}
                  primary={`${index} - ${R.propOr("", "name", eachOutcome)}`}
                />
                {R.prop("tag", eachOutcome) && (
                  <div className={classes.pointer}>
                    <Chip
                      color="primary"
                      variant="outlined"
                      label={R.prop("tag", eachOutcome)}
                    />
                  </div>
                )}
              </ListItem>
            );
          }, R.propOr([], "learningOutcomes", data))}
        </List>
      </AccordionDetails>
    </Accordion>
  );
};

const ObservationsView = ({ data }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <Grid item xs={12}>
      <div style={{ marginBottom: "1rem" }}>
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreOutlined className={classes.topIcon} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className={classes.observationRegistryHeader}
          >
            <Grid container spacing={0} style={{ margin: "7px 0" }}>
              <Grid item xs={12}>
                <Typography>
                  {R.propOr("", "name", data)}
                  {mapIndexed((grade, index) => {
                    return (
                      <Chip
                        key={`${grade.className}-${index}`}
                        className={classes.classChip}
                        label={grade.className}
                        variant={"filled"}
                      />
                    );
                  }, R.propOr([], "grades", data))}
                </Typography>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails className={classes.accordionDetails}>
            {mapIndexed((eachObservation, index) => {
              return (
                <div
                  style={{
                    marginBottom: "1rem",
                    paddingRight: "1rem",
                    paddingLeft: "1rem",
                  }}
                >
                  <DisplayEachObservation
                    classes={classes}
                    data={eachObservation}
                    key={index}
                  />
                </div>
              );
            }, R.propOr([], "observations", data))}
          </AccordionDetails>
        </Accordion>
      </div>
    </Grid>
  );
};

export default ObservationsView;
