import moment from "moment";
import * as R from "ramda";

export const STANDARD_TIME_FORMATS = {};

export const formatDateString = (timestamp) => {
  return !R.isNil(timestamp) && !R.isEmpty(timestamp)? moment.unix(parseInt(timestamp)).format("DD MMMM YYYY HH:MM:SS") : "";
};

export const getYearList = (back) => {
  const year = new Date().getFullYear();
  return R.reverse(Array.from({ length: back }, (v, i) => year - back + i + 1));
};
