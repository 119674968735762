import React, { useState } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import CustomizedTabs from "../CustomizedTabs";
import PlanFormStepper from "../PlanFormStepper/PlanFormStepper";

const useStyles = makeStyles((theme) => ({
  cardWrapper: {
    flexWrap: "wrap",
  },
  annualPlanCard: {
    marginBottom: "30px",
    [theme.breakpoints.down('sm')]: {
      marginBottom: "10px",
    }
  },
  noDataAvailable: {
    margin: "20px",
  },
}));

function PlanDetailsPage(props) {
  const { parentState, userRoles, privileges, getPlanDetails } = props;
  const classes = useStyles();
  const [activeTab, setActiveTab] = useState(0);

  return (
    <>
      <Grid
        container
        direction="row"
        className={classes.cardWrapper}
        spacing={4}
      >
        <CustomizedTabs
          setActiveTab={setActiveTab}
          tabList={["Lesson Plan Form", "Teacher Reflections"]}
        />
        {activeTab === 0 && (
          <Grid item className={classes.annualPlanCard} xs="12">
            <PlanFormStepper
              planName={"LESSON_PLAN"}
              userRoles={userRoles}
              parentState={parentState}
              privileges={privileges?.lessonplanform}
              getPlanDetails={getPlanDetails}
            />
          </Grid>
        )}
        {activeTab === 1 && (
          <Grid item className={classes.annualPlanCard} xs="12">
            Teacher Reflections
          </Grid>
        )}
      </Grid>
    </>
  );
}

export default PlanDetailsPage;
