import { Button, Grid, makeStyles, Typography } from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import * as R from "ramda";

const useStyles = makeStyles((theme) => ({
  container: {
    margin: "0",
    marginBottom: "51px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "30px",
    },
  },
  paddingNone: {
    padding: "0px !important",
  },
  paddingXNone: {
    padding: "0px 10px 10px  !important",
  },
  changeView: {
    padding: "0px !important",
    marginLeft: "30px",
    marginRight: "40px",
  },
  addNewPlan: {
    padding: "0px !important",
    marginRight: "-16px",
  },
  addNewPlan1: {
    [theme.breakpoints.down("sm")]: {
      padding: "0px 6px !important",
    },
  },
  title: {
    fontStyle: "normal",
    fontWeight: "800",
    fontSize: "24px",
    color: "#263657",
  },
  inputFormControl: {
    height: "36px",
  },
  downloadIcon: {
    marginRight: "10px",
  },
}));

function ClassHeader({ setModalState, privileges, isSmallDevice }) {
  const classes = useStyles();

  const onSubjectAddClick = () => {
    setModalState((prev) => ({ ...prev, isSubjectModalOpen: true }));
  };

  const onSectionAddClick = () => {
    setModalState((prev) => ({ ...prev, isClassSectionModalOpen: true }));
  };

  return (
    <Grid
      container
      spacing={3}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      className={classes.container}
    >
      <Grid
        item
        xs={12}
        md={6}
        style={{ padding: "0px 10px 10px 5px" }}
        className={isSmallDevice ? "" : classes.paddingNone}
      >
        <Typography className={classes.title} variant="div">
          <span>Class</span>
        </Typography>
      </Grid>
      <Grid
        item
        container
        spacing={3}
        md={6}
        direction="row"
        justifyContent={isSmallDevice ? "flex-start" : "flex-end"}
        alignItems="center"
        className={isSmallDevice ? classes.paddingXNone : classes.paddingNone}
      >
        {R.propOr(true, "CREATE", privileges) && (
          <Grid item className={classes.addNewPlan1}>
            <Button
              variant="contained"
              color="primary"
              className={classes.addIconButton}
              onClick={onSubjectAddClick}
              startIcon={<AddCircleIcon className="addIcon" />}
            >
              Add Subject
            </Button>
          </Grid>
        )}
        {R.propOr(true, "CREATE", privileges) && (
          <Grid item className={classes.addNewPlan1}>
            <Button
              variant="contained"
              color="primary"
              className={classes.addIconButton}
              onClick={onSectionAddClick}
              startIcon={<AddCircleIcon className="addIcon" />}
            >
              Add Class Section
            </Button>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

export default ClassHeader;
