import { Grid, makeStyles } from "@material-ui/core";
import { CircularProgress } from "@mui/material";
import withPrivilege from "common/hocs/withPrivilege";
import BreadCrumb from "components/academicPlanDetails/BreadCrumb";
import { getPageList } from "components/listOfValues/utils";
import { useEffect, useState } from "react";
import { PAGES } from "state/slices/pageSlice";
import { colors } from "theme/colors";
import { EntityMap } from "util/roleUtils";
import "../../../../components/academicPlanDetails/styles.css";
import ManageSchoolModal from "./ManageSchoolModal";
import SchoolPlanHeader from "./SchoolPlanHeader";
import SchoolViewCard from "./SchoolViewCard";

const useStyles = makeStyles((theme) => ({
  cardWrapper: {
    flexWrap: "wrap",
  },
  annualPlanCard: {
    marginBottom: "30px",
  },
  noDataAvailable: {
    margin: "20px",
  },
  gridContainer: {
    flexWrap: "nowrap",
  },
  breadcrumb: {
    margin: "-44px 0px 5px -21px",
    [theme.breakpoints.down("md")]: {
      margin: 0, //"1.5rem 0 0 1.2rem",
    },
  },

  planSection: {
    height: "100%",
    minHeight: "100vh",
    padding: "47px 51px",
    backgroundColor: colors.gray5,
    [theme.breakpoints.down("md")]: {
      padding: "1.5rem",
    },
  },
}));

function ManageSchool({
  privileges,
  userRoles,
  setModalState,
  modalState,
  filterState,
  planName,
}) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState({
    totalPage: 0,
    pageSize: 10,
    data: [],
    currentPage: 0,
  });
  useEffect(() => {
    if (state?.currentPage !== 0) {
      setState((prev) => ({
        ...prev,
        currentPage: 0,
      }));
    }
    getSchoolList({ pageSize: state.pageSize, currentPage: 0 });
  }, [filterState, state?.currentPage, state.pageSize]);

  const getSchoolList = async (pageData) => {
    const { pageSize = 10, currentPage = 0 } = pageData;
    setIsLoading(true);
    const pageListData = await getPageList({
      searchKey: "",
      page: PAGES.schools,
      pageData: { pageSize, currentPage },
    });
    setIsLoading(false);
    setState((prev) => ({
      ...prev,
      data: pageListData?.content || [],
      totalPage: pageListData?.totalPages || 0,
    }));
  };

  return (
    <>
      <div className={classes.breadcrumb}>
        <BreadCrumb planName={"SCHOOL"} style={{}} />
      </div>
      <SchoolPlanHeader
        changeView={planName}
        privileges={privileges.school}
        setModalState={setModalState}
      />
      <Grid
        container
        direction="row"
        className={classes.cardWrapper}
        spacing={4}
      >
        {isLoading ? (
          <CircularProgress />
        ) : state?.data?.length ? (
          state?.data?.map((school, index) => (
            <Grid item key={index} md="4" xs="12">
              <SchoolViewCard
                data={school}
                privileges={privileges}
                userRoles={userRoles}
                setModalState={setModalState}
                getSchoolList={getSchoolList}
              />
            </Grid>
          ))
        ) : (
          <div className={classes.noDataAvailable}>No Data available</div>
        )}
      </Grid>
      {modalState?.isOpen && (
        <ManageSchoolModal
          userRoles={userRoles}
          modalState={modalState}
          setModalState={setModalState}
          getSchoolList={getSchoolList}
        />
      )}
    </>
  );
}

export default withPrivilege(ManageSchool, EntityMap.school, EntityMap.class);
