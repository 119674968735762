import React from "react";
import {
  makeStyles,
  Typography,
  Box,
  Card,
  CardContent,
  Grid,
  Button,
} from "@material-ui/core";
import { FILE_ICON } from "asset/images";
import * as R from "ramda";

const useStyles = makeStyles((theme) => ({
  cardBody: {
    width: "100%",
    background: "#FFFFFF",
    borderRadius: "14px !important",
  },
  cardContent: {
    padding: "26px 18px !important",
  },
  cardTitle: {
    fontStyle: "normal",
    fontWeight: "800",
    fontSize: "18px",
    lineHeight: "22px",
    color: "#263657",
    display: "flex",
    alignItems: "center",
  },
  fileIcon: {
    marginRight: "10px",
  },
  cardTitleWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  cardBodyContent: {
    marginTop: "18px",
  },
  bodyText: {
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "12px",
    lineHeight: "14px",
    color: "#000000",
  },
  editIcon: {
    marginRight: "10px",
  },
}));

const SubjectCard = ({
  data,
  userRoles = [],
  privileges = {},
  setModalState,
}) => {
  const classes = useStyles();

  // const handleRedirect = (id) => {
  //   history.push(`/schools/${id}`);
  // };

  return (
    <>
      <Grid item className={classes.annualPlanCard} md="6" xs="12">
        {
          <Box
            sx={{ minWidth: 275 }}
            // onClick={() => handleRedirect(data?.id)}
            style={{ cursor: "pointer" }}
          >
            <Card variant="outlined" className={classes.cardBody}>
              <CardContent className={classes.cardContent}>
                <div className={classes.cardTitleWrapper}>
                  <Typography className={classes.cardTitle} variant="div">
                    <img
                      className={classes.fileIcon}
                      src={FILE_ICON}
                      alt="File Icon"
                    />
                    <span>{data.subjectName}</span>
                  </Typography>
                  {R.propOr(true, "DELETE", privileges) && (
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={() => {}}
                      size="small"
                      data-html2canvas-ignore
                    >
                      Deactivate
                    </Button>
                  )}
                </div>
              </CardContent>
            </Card>
          </Box>
        }
      </Grid>
    </>
  );
};

export default SubjectCard;
