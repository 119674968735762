import {
  Box,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import LOVSelect from "common/components/LOVSelect/LOVSelect";
import {
  LOVDataKeys,
  LOVKeyMap,
  LOVSelectKeys,
} from "common/components/SelectDropDown/SelectDropDownUtils";
import ConsentForm from "common/forms/ConsentForm";
import ObservationSelectionForm from "common/forms/ObservationSelectionForm";
import { UnitReadOnlyData } from "common/forms/UnitPlanDetailsForm";
import {
  getSelectListForPlanForm as getSelectList,
  setObservationsValue,
} from "common/forms/utils";
import { getDownloadFileName } from "common/utils/downloadAsPdf";
import { FORM_TYPES } from "common/utils/formUtility";
import { getPageList, getUnitPlanFormAPI } from "components/listOfValues/utils";
import * as R from "ramda";
import { useEffect, useRef, useState } from "react";
import ReactLinkify from "react-linkify";
import { useDispatch, useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import { PAGES, setPageListItems } from "state/slices/pageSlice";
import { colors } from "theme/colors";

const useStyle = makeStyles((theme) => ({
  heading: {
    color: colors.blue1,
  },
  readOnlyContainer: {
    backgroundColor: colors.blue1,
    padding: "1rem",
    color: colors.gray1,
    marginBottom: "2rem",
  },
  readOnlyTitle: {
    fontSize: "20px",
  },
  subUnitChip: {
    "&.MuiChip-root": {
      marginRight: "1rem",
      color: colors.blue1,
      fontSize: "16px",
      fontWeight: "500",
    },
  },
  inputLabel: {
    top: "-22px",
  },
  downloadBtn: {
    "&.MuiButton-root": {
      border: `1 px solid ${colors.gray1}`,
    },
    background: colors.blue1,
    color: colors.gray1,
  },
}));

const UnitPlanFormReadView = ({ data, userRoles, isPlanSummary }) => {
  const printRef = useRef();
  const classes = useStyle();
  let observationsData = useSelector(
    (state) => state.pages[PAGES.observations]
  );
  const [unitPlanFormData, setUnitPlanFormData] = useState({});
  const downloadFileName = getDownloadFileName({
    data: data,
    templateId: "unit_plan",
  });
  const dispatch = useDispatch();

  useEffect(() => {
    if (R.isEmpty(observationsData.items)) {
      getObservationsData();
    }
  }, [observationsData, unitPlanFormData]);

  useEffect(() => {
    if (data?.id && isPlanSummary) {
      getPlanFormData(data?.id);
    } else {
      setUnitPlanFormData(data.unitPlanForm);
    }
  }, [data]);

  const getPlanFormData = async (id) => {
    const res = await getUnitPlanFormAPI(id);
    setUnitPlanFormData(res);
  };

  const getObservationsData = async () => {
    const pageListData = await getPageList({
      searchKey: "",
      page: PAGES.observations,
    });
    dispatch(
      setPageListItems({
        page: PAGES.observations,
        itemList: pageListData.content,
        totalElements: pageListData.totalElements,
        totalPages: pageListData.totalPages,
      })
    );
  };

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: downloadFileName,
    copyStyles: true,
  });

  return (
    <Box style={{ padding: "1rem" }} id="unitDetailsForm" ref={printRef}>
      {R.isEmpty(unitPlanFormData) ? (
        <h3 className={classes.heading} align={"left"}>
          No Data available
        </h3>
      ) : (
        <Box>
          {!R.isNil(R.propOr({}, "curriculumContext", data)) && (
            <UnitReadOnlyData data={data} downloadAsPdf={handlePrint} />
          )}

          <Typography variant="subtitle1">Periods:</Typography>

          <Typography variant="subtitle2">
            {R.pathOr("", ["periods"], unitPlanFormData)}
          </Typography>
          <Typography
            variant="h6"
            color="secondary"
            style={{ margin: "1rem 0rem" }}
          >
            Unit Plan
          </Typography>
          <Typography variant="body2" style={{ whiteSpace: "pre-line" }}>
            {R.pathOr("", ["studentObjectives"], unitPlanFormData)}
          </Typography>
          <Divider />
          <Typography variant="h6" color="secondary">
            Learning Outcomes
          </Typography>
          <Typography variant="subtitle1">
            TEACHING / LEARNING STRATEGIES:
          </Typography>

          <Box>
            {R.isEmpty(R.propOr([], "tlmList", unitPlanFormData)) ? (
              <Typography variant="subtitle2">No data</Typography>
            ) : (
              R.map((item) => {
                return (
                  <Grid container spacing={1}>
                    <Grid item xs={3}>
                      <Typography variant="subtitle2">{item.name}</Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <ReactLinkify>
                        <Typography variant="subtitle2">
                          {item.reference}
                        </Typography>
                      </ReactLinkify>
                    </Grid>
                  </Grid>
                );
              })(R.propOr([], "tlmList", unitPlanFormData))
            )}
          </Box>

          <Typography variant="subtitle1">Learning Objectives:</Typography>
          <Typography
            variant="h6"
            color="secondary"
            style={{ margin: "1rem 0rem" }}
          >
            Concept Related Objectives: (Knowledge and Comprehension,
            Remembering and Understanding)
          </Typography>

          <div style={{ margin: "1rem 0rem" }}>
            <Typography variant="subtitle2" color="primary">
              Students will be able to –
            </Typography>
            <Typography variant="body2" style={{ whiteSpace: "pre-line" }}>
              {R.pathOr(
                "",
                ["learningObjectiveForm", "conceptObjective"],
                unitPlanFormData
              )}
            </Typography>
          </div>
          <Divider />

          <div style={{ margin: "1rem 0rem" }}>
            <Typography variant="subtitle1" color="primary">
              Skill Related Objectives: Apply, Analyse, Evaluate and Create
              (Applying, Analysing, Evaluating, Creating)
            </Typography>
            <Typography variant="subtitle2" color="primary">
              Students will be able to –
            </Typography>
            <Typography variant="body2" style={{ whiteSpace: "pre-line" }}>
              {R.pathOr(
                "",
                ["learningObjectiveForm", "skillObjective"],
                unitPlanFormData
              )}
            </Typography>
          </div>
          <Divider />

          <div style={{ margin: "1rem 0rem" }}>
            <Typography variant="subtitle1" color="primary">
              Fact Related Objectives: Knowledge and Comprehension (Remembering
              and Understanding)
            </Typography>
            <Typography variant="subtitle2" color="primary">
              Students will be able to –
            </Typography>
            <Typography variant="body2" style={{ whiteSpace: "pre-line" }}>
              {R.pathOr(
                "",
                ["learningObjectiveForm", "factObjective"],
                unitPlanFormData
              )}
            </Typography>
          </div>
          <Divider />

          <Typography
            variant="h6"
            color="secondary"
            style={{ margin: "1rem 0rem" }}
          >
            Vocabulary Related Objectives
          </Typography>
          <Typography variant="subtitle2" color="primary">
            Students will be able to comprehend academic language, and also use
            it in context.
          </Typography>
          <Typography variant="subtitle2" color="primary">
            Language of Instruction (used by teacher, comprehended by students)
          </Typography>
          <Typography variant="body2" style={{ whiteSpace: "pre-line" }}>
            {R.pathOr(
              "",
              [
                "learningObjectiveForm",
                "vocabularyObjective",
                "languageOfInstruction",
              ],
              unitPlanFormData
            )}
          </Typography>
          {/* <Typography
            variant="subtitle2"
            color="primary"
            style={{ marginTop: "0.5rem" }}
          >
            Student Vocabulary (internalised and use in context)
          </Typography>
          <Typography
            variant="body2"
            style={{ whiteSpace: "pre-line", marginBottom: "0.5rem" }}
          >
            {R.pathOr(
              "",
              [
                "learningObjectiveForm",
                "vocabularyObjective",
                "studentVocabulary",
              ],
              unitPlanFormData
            )}
          </Typography> */}
          <Divider />

          <Typography
            variant="h6"
            color="secondary"
            style={{ margin: "1rem 0rem" }}
          >
            Values Related Objectives
          </Typography>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Typography variant="subtitle2" color="primary">
                Symbiotic Learning
              </Typography>
              <Typography variant="subtitle2" color="primary">
                Students will be able to –
              </Typography>
              <Box fullWidth id="symbioticLearning">
                <Typography variant="body2" style={{ whiteSpace: "pre-line" }}>
                  {" "}
                  {R.pathOr(
                    "",
                    [
                      "learningObjectiveForm",
                      "valuesObjectives",
                      "symbioticLearning",
                    ],
                    unitPlanFormData
                  )}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle2" color="primary">
                Environmental Consciousness
              </Typography>
              <Typography variant="subtitle2" color="primary">
                Students will be able to –
              </Typography>
              <Box fullWidth id="environmentalConsciousness">
                <Typography variant="body2" style={{ whiteSpace: "pre-line" }}>
                  {" "}
                  {R.pathOr(
                    "",
                    [
                      "learningObjectiveForm",
                      "valuesObjectives",
                      "environmentalConsciousness",
                    ],
                    unitPlanFormData
                  )}
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={1} style={{ margin: "1rem 0rem" }}>
            <Grid item xs={6}>
              <Typography variant="subtitle2" color="primary">
                Design Thinking
              </Typography>
              <Typography variant="subtitle2" color="primary">
                Students will be able to –
              </Typography>
              <Box fullWidth id="designThinking">
                <Typography variant="body2" style={{ whiteSpace: "pre-line" }}>
                  {" "}
                  {R.pathOr(
                    "",
                    [
                      "learningObjectiveForm",
                      "valuesObjectives",
                      "designThinking",
                    ],
                    unitPlanFormData
                  )}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle2" color="primary">
                Emotional Wellness
              </Typography>
              <Typography variant="subtitle2" color="primary">
                Students will be able to –
              </Typography>
              <Box fullWidth id="emotionalWellness">
                <Typography variant="body2" style={{ whiteSpace: "pre-line" }}>
                  {" "}
                  {R.pathOr(
                    "",
                    [
                      "learningObjectiveForm",
                      "valuesObjectives",
                      "emotionalWellness",
                    ],
                    unitPlanFormData
                  )}
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={1} style={{ margin: "1rem 0rem" }}>
            <Grid item xs={6}>
              <Typography variant="subtitle2" color="primary">
                Sustainable Development Goals / Global Citizenship
              </Typography>
              <Typography variant="subtitle2" color="primary">
                Students will be able to –
              </Typography>
              <Box fullWidth id="sustainableDevelopment">
                <Typography variant="body2" style={{ whiteSpace: "pre-line" }}>
                  {R.pathOr(
                    "",
                    [
                      "learningObjectiveForm",
                      "valuesObjectives",
                      "sustainableDevelopment",
                    ],
                    unitPlanFormData
                  )}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle2" color="primary">
                Value of the Month
              </Typography>
              <Typography variant="subtitle2" color="primary">
                Students will be able to –
              </Typography>
              <Box fullWidth id="valueOfMonth">
                <Typography variant="body2" style={{ whiteSpace: "pre-line" }}>
                  {R.pathOr(
                    "",
                    [
                      "learningObjectiveForm",
                      "valuesObjectives",
                      "valueOfMonth",
                    ],
                    unitPlanFormData
                  )}
                </Typography>
              </Box>
            </Grid>
          </Grid>

          <Divider />

          <ObservationSelectionForm
            className={R.pathOr("", ["curriculumContext", "gradeName"], data)}
            formType={FORM_TYPES.UNIT_PLAN_FORM}
            value={setObservationsValue(
              R.pathOr([], ["learningOutcomes"], unitPlanFormData),
              observationsData,
              R.pathOr("", ["curriculumContext", "gradeName"], data)
            )}
            isReadOnly={true}
          />

          <Typography
            variant="h6"
            color="secondary"
            style={{ margin: "1rem 0rem" }}
          >
            Level/s Of Learning
          </Typography>
          <Box>
            <Typography variant="subtitle2" color="primary">
              All learners will be able to:
            </Typography>
            <Typography variant="body2">
              {R.pathOr(
                "",
                ["levelOfLeaningForm", "levelOfLearningInput1"],
                unitPlanFormData
              )}
            </Typography>
            <Typography variant="subtitle2" color="primary">
              Most learners should be able to:
            </Typography>
            <Typography variant="body2">
              {R.pathOr(
                "",
                ["levelOfLeaningForm", "levelOfLearningInput2"],
                unitPlanFormData
              )}
            </Typography>
            <Typography variant="subtitle2" color="primary">
              Some learners may be able to:
            </Typography>
            <Typography variant="body2">
              {R.pathOr(
                "",
                ["levelOfLeaningForm", "levelOfLearningInput3"],
                unitPlanFormData
              )}
            </Typography>
          </Box>

          <Typography
            variant="h6"
            color="secondary"
            style={{ margin: "1rem 0rem" }}
          >
            Assessments
          </Typography>
          <Typography
            variant="h6"
            color="primary"
            style={{ marginBottom: "2rem" }}
          >
            Formative
          </Typography>
          <FormControl className={classes.inputFormControl} varinat="outlined">
            <InputLabel
              className={classes.inputLabel}
              id="demo-simple-select-outlined-label"
            >
              Formative
            </InputLabel>
            <LOVSelect
              margin="normal"
              varinat="outlined"
              formType={FORM_TYPES.UNIT_PLAN_FORM}
              selectKey={LOVSelectKeys.FORMATIVE}
              dataKey={LOVDataKeys.FORMATIVE}
              lovKey={LOVKeyMap.FORMATIVE}
              value={
                unitPlanFormData?.assessmentForm?.formativeAssessments?.map(
                  (item) => item?.name
                ) || []
              }
              multiselect={true}
              selectObj={true}
              getSelectList={getSelectList}
              showList={true}
              isReadOnly={true}
            />
          </FormControl>
          <Typography
            variant="subtitle1"
            color="primary"
            style={{ marginTop: "1rem" }}
          >
            Comments
          </Typography>
          <Typography variant="body1" style={{ whiteSpace: "pre-line" }}>
            {R.pathOr("", ["assessmentForm", "formative"], unitPlanFormData)}
          </Typography>
          <Typography
            variant="subtitle1"
            color="primary"
            style={{ marginTop: "1rem" }}
          >
            Reference Link
          </Typography>
          <ReactLinkify>
            <Typography variant="body1" style={{ whiteSpace: "pre-line" }}>
              {R.pathOr(
                "",
                ["assessmentForm", "formativeReference"],
                unitPlanFormData
              )}
            </Typography>
          </ReactLinkify>

          <Typography variant="h6" color="primary">
            Summative
          </Typography>
          <Typography variant="subtitle1">Comments</Typography>
          <Typography variant="body1" style={{ whiteSpace: "pre-line" }}>
            {R.pathOr("", ["assessmentForm", "summative"], unitPlanFormData)}
          </Typography>
          <Typography
            variant="subtitle1"
            color="primary"
            style={{ marginTop: "1rem" }}
          >
            Reference Link
          </Typography>
          <ReactLinkify>
            <Typography variant="body1" style={{ whiteSpace: "pre-line" }}>
              {R.pathOr(
                "",
                ["assessmentForm", "summativeReference"],
                unitPlanFormData
              )}
            </Typography>
          </ReactLinkify>
          <Divider />
          <Typography
            variant="h6"
            color="secondary"
            style={{ marginTop: "1rem" }}
          >
            TEACHER REFLECTION
          </Typography>
          <ReactLinkify>
            <Typography variant="body2" style={{ whiteSpace: "pre-line" }}>
              {R.pathOr("", ["teacherReflection"], unitPlanFormData)}
            </Typography>
          </ReactLinkify>
          <Divider />
          <Typography
            variant="h6"
            color="secondary"
            style={{ marginTop: "1rem" }}
          >
            CONSENTS
          </Typography>
          <ConsentForm
            page={"lessonPlan"}
            userRoles={userRoles}
            consentData={R.propOr([], "consents", unitPlanFormData)}
            isReadOnly={true}
          />
        </Box>
      )}
    </Box>
  );
};

export default UnitPlanFormReadView;
