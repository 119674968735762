import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  IconButton,
} from "@material-ui/core";
import { Close, ExpandMoreOutlined } from "@material-ui/icons";
import React, { useCallback, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import * as R from "ramda";
import {
  readPeerGoalFeedback,
  readSelfGoalFeedback,
  saveGoalFeedbackComment,
  updateGoalFeedback,
} from "components/listOfValues/utils";
import Comment from "common/components/Comment/Comment";

const useStyle = makeStyles((theme) => ({
  inputFormControl: {
    marginTop: "1.5rem",
  },
  pointer: {
    cursor: "pointer",
  },
  saveBtn: {
    marginRight: "1rem",
  },
  inputLabel: {
    top: "-17px",
  },
  editfields: {
    margin: "0 0 1.5rem 0",
    border: "1px solid #26365785",
    borderRadius: 4,
    padding: "1rem",
    background: "#fff",
  },
  displayobservation1: {
    background: "#898eac2e",
    border: "1px solid #898EAC",
    boxShadow: "none",
    borderRadius: 6,
    marginBottom: "10px",
  },
  editViewContainer: {
    textAlign: "end",
  },
  textfieldRating: {
    width: "60%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

const goalFeedbackType = {
  SELF: "Self",
  PEER: "Peer",
};

const goalFeedbackState = {
  SUBMIT_SELF_FEEDBACK: "Self Feedback Pending",
  SELF_REASSESSMENT_REQUIRED: "Reassess Self Feedback",
  SELF_FEEDBACK_SUBMITTED: "Self Feedback Submitted",
  SUBMIT_PEER_FEEDBACK: "Peer Feedback Pending",
  PEER_REASSESSMENT_REQUIRED: "Reassess Peer Feedback",
  PEER_FEEDBACK_SUBMITTED: "Peer Feedback Submited",
  FEEDBACK_COMPLETED: "Completed",
};

const goalFeedbackStateOrder = {
  SUBMIT_SELF_FEEDBACK: 0,
  SELF_REASSESSMENT_REQUIRED: 1,
  SELF_FEEDBACK_SUBMITTED: 2,
  SUBMIT_PEER_FEEDBACK: 3,
  PEER_REASSESSMENT_REQUIRED: 4,
  PEER_FEEDBACK_SUBMITTED: 5,
  FEEDBACK_COMPLETED: 6,
};

const GoalFeedback = ({
  goal = {},
  goalSetupId,
  postMethod,
  readOnly = false,
  isPeer = false,
}) => {
  const classes = useStyle();
  const [accordianExpandedState, setAccordianExpandedState] = useState({});
  const [feedbacks, setFeedbacks] = useState([]);
  const [updatedFeedback, setUpdatedFeedback] = useState({});
  const [commentDialogOpen, setCommentDialogOpen] = useState(false);
  const [reviewComment, setReviewComment] = useState("");
  const [viewCommentDialogOpen, setViewCommentDialogOpen] = useState(false);
  const [selectedFeedbackIndex, setSelectedFeedbackIndex] = useState(null);

  const onViewCommentDialogOpen = (index) => {
    setSelectedFeedbackIndex(index);
    setViewCommentDialogOpen(true);
  };

  // 2. Update the handleRatingChange function
  const handleRatingChange = (index, goalRatingIndex, newRating) => {
    setFeedbacks((prevFeedbacks) => {
      const updatedFeedbacks = [...prevFeedbacks];
      updatedFeedbacks[index].goalRatings[goalRatingIndex].rating = newRating;

      // Store the updated feedback object separately
      setUpdatedFeedback(updatedFeedbacks[index]);

      return updatedFeedbacks;
    });
  };

  const onCommentDialogOpen = () => {
    setCommentDialogOpen(true);
  };

  const onCommentDialogClose = () => {
    setReviewComment("");
    setCommentDialogOpen(false);
  };

  const updateGoalFeedbackForm = useCallback(async () => {
    let data = [];
    if (isPeer) {
      data = await readPeerGoalFeedback(goalSetupId);
    } else {
      data = await readSelfGoalFeedback(goalSetupId);
    }
    setFeedbacks(data);
  }, [goalSetupId, isPeer]);

  useEffect(() => {
    updateGoalFeedbackForm();
  }, [updateGoalFeedbackForm]);

  const handleSaveFeedback = async (index) => {
    if (Object.keys(updatedFeedback).length > 0) {
      await updateGoalFeedback(updatedFeedback);
    }else{
      await updateGoalFeedback(feedbacks[index]);
    }
  };

  const handleSubmitFeedbackComment = async () => {
    let goalFeedbackComment = {
      goalFeedback: {},
      comment: {},
    };
    let updated_feedback = {}
    if (Object.keys(updatedFeedback).length > 0) {
      goalFeedbackComment.goalFeedback = updatedFeedback;
      updated_feedback = updatedFeedback;
    }else{
      updated_feedback= feedbacks[0];
      goalFeedbackComment.goalFeedback = feedbacks[0];
    }
    goalFeedbackComment.goalFeedback.state = "SELF_FEEDBACK_SUBMITTED";
    goalFeedbackComment.comment.comment = reviewComment;
    goalFeedbackComment.comment.state = "SELF_FEEDBACK_SUBMITTED";
    await saveGoalFeedbackComment(goalFeedbackComment, updated_feedback.id);
    updateGoalFeedbackForm();
    onCommentDialogClose();
  };

  if (!feedbacks || feedbacks.length === 0) return null;
  return (
    <Box className={classes.editViewContainer}>
      {feedbacks.map((feedback, index) => (
        <>
          <Accordion
            className={classes.displayobservation1}
            defaultExpanded={!goal.id || goal.id.length === 0}
            expanded={!!accordianExpandedState[index]}
            onChange={(_, expanded) => {
              let expandStateArray = { ...accordianExpandedState };
              expandStateArray[index] = expanded;
              setAccordianExpandedState(expandStateArray);
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreOutlined />}
              aria-controls="panel1d-content"
              id="panel1d-header"
            >
              <Grid container>
                <Grid item xs={12} md={9}>
                  <Typography variant="subtitle2" align="left">
                    Feedback By: {feedback.createdBy} (
                    {goalFeedbackType[feedback.feedbackType]})
                  </Typography>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Typography variant="subtitle1" align="left">
                    Period: {feedback.period}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={9}>
                  <Typography variant="subtitle2" align="left">
                    State: {goalFeedbackState[feedback.state]}
                  </Typography>
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails className={classes.outcomeAccordionContainer}>
              <Grid container spacing={1} className={classes.editfields}>
                {feedback.goalRatings.map((goalRating, goalRatingIndex) => (
                  <>
                    <Grid item xs={12} md={7} style={{ alignSelf: "center" }}>
                      <Typography variant="subtitle2" align="left">
                        {goalRating.goal.goalDetails}:
                      </Typography>
                      <Typography variant="subtitle1" align="left">
                        Weightage: {goalRating.goal.weightage}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={5} style={{ alignSelf: "left" }}>
                      <FormControl variant="outlined">
                        {goalFeedbackStateOrder[feedback.state] <
                        goalFeedbackStateOrder["SELF_FEEDBACK_SUBMITTED"] ? (
                          <TextField
                            variant="outlined"
                            id="date"
                            margin="normal"
                            name="rating"
                            placeholder={
                              "Enter your rating for achievements on this goal"
                            }
                            type="input"
                            className={classes.textfieldRating}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            onChange={(e) =>
                              handleRatingChange(
                                index,
                                goalRatingIndex,
                                e.target.value
                              )
                            }
                            value={R.propOr("", "rating", goalRating)}
                          />
                        ) : (
                          <Typography variant="subtitle2" align="left">
                            Rating: {goalRating.rating}
                          </Typography>
                        )}
                      </FormControl>
                    </Grid>
                  </>
                ))}
                <Grid item xs={12}>
                  {feedback?.comments?.length ? (
                    <Button
                      color="primary"
                      variant="text"
                      onClick={() => onViewCommentDialogOpen(index)}
                      style={{ marginRight: 20 }}
                    >
                      {`View ${feedback?.comments?.length} Comments`}
                    </Button>
                  ) : (
                    <Button
                      color="primary"
                      variant="text"
                      onClick={onViewCommentDialogOpen}
                      style={{ marginRight: 20 }}
                      disabled
                    >
                      {`No Comments`}
                    </Button>
                  )}
                  <Button
                    className={classes.evidenceBtn}
                    color="primary"
                    type="button"
                    variant="text"
                    onClick={() => handleSaveFeedback(index)}
                    disabled={
                      goalFeedbackStateOrder[feedback.state] >=
                      goalFeedbackStateOrder["SELF_FEEDBACK_SUBMITTED"]
                    }
                  >
                    Save
                  </Button>
                  <Button
                    className={classes.saveBtn}
                    color="primary"
                    type="button"
                    variant="contained"
                    onClick={() => onCommentDialogOpen()}
                    disabled={
                      goalFeedbackStateOrder[feedback.state] >=
                      goalFeedbackStateOrder["SELF_FEEDBACK_SUBMITTED"]
                    }
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </>
      ))}
      <Dialog
        maxWidth="md"
        fullWidth
        open={commentDialogOpen}
        onClose={onCommentDialogClose}
        aria-labelledby="form-dialog-title"
      >
        <Grid container>
          <Grid item xs={10}>
            <DialogTitle>Feedback Comments</DialogTitle>
          </Grid>
        </Grid>

        <DialogContent>
          <InputLabel className={classes.inputLabel}>Comment</InputLabel>
          <TextField
            id={`review-comment-multiline-${updatedFeedback.id}`}
            placeholder="Submit your review comments"
            variant="outlined"
            margin="normal"
            multiline
            fullWidth
            data-html2canvas-ignore
            value={reviewComment}
            onChange={(e) => setReviewComment(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onCommentDialogClose}>Cancel</Button>
          <Button onClick={handleSubmitFeedbackComment}>Submit</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        maxWidth="md"
        fullWidth
        open={viewCommentDialogOpen}
        onClose={() => setViewCommentDialogOpen(false)}
        aria-labelledby="form-dialog-title"
      >
        <Grid container>
          <Grid item xs={10}>
            <DialogTitle id="form-dialog-title">
              <Typography variant="h6">Comments</Typography>
            </DialogTitle>
          </Grid>
          <Grid item xs={2} className={classes.modalCloseBtn}>
            <IconButton onClick={() => setViewCommentDialogOpen(false)}>
              <Close />
            </IconButton>
          </Grid>
        </Grid>
        <DialogContent style={{ padding: "8px 25px" }}>
          <Comment
            comments={feedbacks[selectedFeedbackIndex]?.comments}
            primaryDataVariale="comment"
            secondaryLabelVariable="state"
          />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default GoalFeedback;
