import React, { useState } from "react";
import { Close } from "@material-ui/icons";
import { useSelector } from "react-redux";
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  makeStyles,
  Divider,
} from "@material-ui/core";
import { saveGoalTimelineConfiguration } from "components/listOfValues/utils";
import GoalTimelineForm from "./GoalTimelineForm";

const useStyles = makeStyles((theme) => ({
  cardWrapper: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  annualPlanCard: {
    marginBottom: "30px",
  },
  paginationContainer: {
    display: "flex",
    justifyContent: "end",
  },
  heading: {
    fontWeight: 800,
    fontSize: "18px",
    color: "#263657",
  },
  divider: {
    margin: "0 24px 24px 24px",
  },
}));

function GoalTimelineModal(props) {
  const { setModalState, postSubmit } = props;
  const classes = useStyles();
  const [goalTimeline, setGoalTimeline] = useState({});
  const allRoles = useSelector((state) => state.pages.roles.items);

  const handleClose = () => {
    setModalState((prev) => ({
      isTimelineModelOpen: false,
      data: null,
      isEdit: false,
    }));
  };

  const handleSubmit = async (e) => {
    await saveGoalTimelineConfiguration(goalTimeline);
    setModalState({
      isTimelineModelOpen: false,
      isEdit: false,
      data: null,
    });
    postSubmit && postSubmit();
  };

  return (
    <Dialog
      maxWidth="md"
      fullWidth
      open={true}
      onClose={() =>
        setModalState((prev) => ({ ...prev, isTimelineModelOpen: false }))
      }
      ariaLabeledBy="form-dialog-title"
    >
      <Grid container>
        <Grid item xs={11}>
          <DialogTitle id="form-dialog-title">
            <Typography variant="h6" className={classes.heading}>
              {"Add Goal Timeline"}
            </Typography>
          </DialogTitle>
        </Grid>
        <Grid item xs={1} className={classes.modalCloseBtn}>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </Grid>
      </Grid>
      <Divider className={classes.divider} />
      <DialogContent style={{ padding: "8px 25px" }}>
        <Box>
          <GoalTimelineForm
            goalTimeline={goalTimeline}
            setGoalTimeline={setGoalTimeline}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "end",
              pt: 2,
            }}
          >
            <Button
              color="primary"
              // disabled={isReadOnly || isNew}
              variant="contained"
              onClick={handleSubmit}
              data-html2canvas-ignore
            >
              Submit
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default GoalTimelineModal;
