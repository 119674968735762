import * as R from "ramda";

export const annualFormPayloadSelector = (
  data,
  annualPlanStatus = "ACTIVE"
) => {
  const schoolCode = R.pathOr({}, ["curriculumContext", "schoolCode"], data);
  const classValue = R.pathOr({}, ["curriculumContext", "gradeName"], data);
  const subjectValue = R.pathOr({}, ["curriculumContext", "subject"], data);
  const annualPeriod = R.pathOr(
    {},
    ["curriculumContext", "annualPeriod"],
    data
  );
  const curriculumContext = R.pipe(
    R.assocPath(["schoolCode"], R.pathOr(schoolCode, ["school"], data)),
    R.assocPath(["schoolCode"], R.pathOr(schoolCode, ["schoolCode"], data)),
    R.assocPath(["gradeName"], R.pathOr(classValue, ["gradeName"], data)),
    R.assocPath(
      ["annualPeriod"],
      R.pathOr(annualPeriod, ["annualPeriod"], data)
    ),
    R.assocPath(
      ["subjectName"],
      R.pathOr(subjectValue, ["subject"], data).name
    ),
    R.assocPath(
      ["subjectValue"],
      R.pathOr(subjectValue, ["subject"], data).value
    )
  )({});
  return R.pipe(
    R.assocPath(["curriculumContext"], curriculumContext),
    R.dissocPath(["className"]),
    R.dissocPath(["school"]),
    R.dissocPath(["subject"]),
    R.dissocPath(["annualPeriod"]),
    R.assocPath(["status"], annualPlanStatus)
  )(data);
};

export const getUnitFormData = (data, lessonPlans) => {
  return R.assocPath(["lessonPlans"], lessonPlans, data);
};

export const getLessonFormData = (data) => {
  return data;
};

export const geAnnualPeriod = (annualPeriod) => {
  if (annualPeriod === "AY20_21") {
    return "A.Y. 2020-2021";
  } else if (annualPeriod === "AY21_22") {
    return "A.Y. 2021-2022";
  } else if (annualPeriod === "AY22_23") {
    return "A.Y. 2022-2023";
  } else if (annualPeriod === "AY23_24") {
    return "A.Y. 2023-2024";
  } else if (annualPeriod === "AY24_25") {
    return "A.Y. 2024-2025";
  }
  return annualPeriod;
};
