import { Grid, makeStyles } from "@material-ui/core";
import { useState } from "react";
import { colors } from "theme/colors";
import "../../../../components/academicPlanDetails/styles.css";
import { ManageSchoolState } from "state/globalForms/initialStates";
import ManageSchool from "./ManageSchool";
import { EntityMap } from "util/roleUtils";
import withPrivilege from "common/hocs/withPrivilege";

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    flexWrap: "nowrap",
  },
  filterSection: {
    background: "white",
  },
  planSection: {
    height: "100%",
    minHeight: "100vh",
    padding: "47px 51px",
    backgroundColor: colors.gray5,
    [theme.breakpoints.down("md")]: {
      padding: "1.5rem",
    },
  },
}));

function ManageSchoolList(props) {
  const classes = useStyles();
  const [modalState, setModalState] = useState({
    isOpen: false,
    data: ManageSchoolState,
    isEdit: false,
  });

  return (
    <Grid container direction="row" className={classes.gridContainer}>
      <Grid className={classes.planSection} md="12">
        <ManageSchool modalState={modalState} setModalState={setModalState} />
      </Grid>
    </Grid>
  );
}

export default withPrivilege(ManageSchoolList, EntityMap.school);
