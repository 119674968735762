import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  TextField,
  Typography,
} from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import { LWS_OG_LOGO } from "asset/images";
import { getPageList } from "components/listOfValues/utils";
import * as R from "ramda";
import { useState } from "react";
import { useDispatch } from "react-redux";
import Alert from "react-s-alert";
import { PAGES, setPageListItems } from "state/slices/pageSlice";
import {
  onLoginSuccess,
  setUserProfile,
} from "state/slices/userLoginFormSlice";
import { forgotPassword, login, profileDetails } from "util/APIUtils";
import { onLoginGetProfileSettings } from "util/LoginUtils";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://www.lodhaworldschool.com/">
        Lodha World School
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: "8rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  headerTitle: {
    marginLeft: "10px",
    lineHeight: "2rem",
    fontSize: "1rem",
    fontWeight: 600,
    color: "rgb(26, 37, 60)",
    textDecoration: "none",
  },
}));

const SignIn = (props) => {
  const classes = useStyles();
  const [signInForm, setState] = useState({ email: "", password: "" });
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [forgotPasswordDialogOpen, setForgotPasswordDialogOpen] =
    useState(false);

  const dispatch = useDispatch();
  const handleChange = (name, event) => {
    const updatedState = R.assocPath([name], event.target.value, signInForm);
    setState(updatedState);
  };

  const openForgotPasswordDialog = () => {
    setForgotPasswordDialogOpen(true);
  };

  const closeForgotPasswordDialog = () => {
    setForgotPasswordDialogOpen(false);
  };

  const getRoles = async () => {
    const response = await getPageList({ searchKey: "", page: PAGES.roles });
    dispatch(
      setPageListItems({
        page: PAGES.roles,
        itemList: response.content,
      })
    );
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const loginRequest = Object.assign(
      {},
      { ...signInForm, email: signInForm.email.trim() }
    );
    login(loginRequest)
      .then(async (response) => {
        dispatch(onLoginSuccess({ ...response }));
        const profile = await profileDetails();
        await getRoles();
        dispatch(setUserProfile({ userProfile: profile }));
        Alert.success("You're successfully logged in!");
        await onLoginGetProfileSettings(dispatch);
        props.history.push("/dashboard");
      })
      .catch((error) => {
        Alert.error(
          (error && error.message) ||
            "Oops! Something went wrong. Please try again!"
        );
      });
  };

  const handleForgotPasswordSubmit = (event) => {
    event.preventDefault();

    const loginRequest = Object.assign({}, signInForm);

    forgotPassword(loginRequest)
      .then(async (response) => {
        Alert.success("Reset password email has been sent to your email!");

        closeForgotPasswordDialog();
      })
      .catch((error) => {
        Alert.error(
          (error && error.message) ||
            "Oops! Something went wrong. Please try again!"
        );

        closeForgotPasswordDialog();
      });
  };

  const togglePasswordVisibility = () => {
    setIsPasswordVisible((prevState) => !prevState);
  };

  return (
    <Container component="main" maxWidth="xl">
      <CssBaseline />
      <div className={classes.paper}>
        <div to="/" className="app-title">
          <img className="lwsLogo" src={LWS_OG_LOGO} alt="Lodha World School" />
          <span className={classes.headerTitle}>Lodha World School</span>
        </div>
        {/* <Typography component="h1" variant="h5">
          Please Enter Your Login Details.
        </Typography> */}
        <form
          style={{ margin: "2rem 0", width: 350 }}
          onSubmit={handleSubmit}
          noValidate
        >
          <InputLabel required>Email Address</InputLabel>
          <TextField
            variant="outlined"
            margin="normal"
            required
            placeholder="Enter email address"
            id="email"
            name="email"
            autoComplete="email"
            autoFocus
            value={signInForm.email}
            onChange={(e) => handleChange("email", e)}
          />
          <InputLabel required>Password</InputLabel>
          <TextField
            variant="outlined"
            margin="normal"
            required
            name="password"
            placeholder="Enter password"
            type={isPasswordVisible ? "text" : "password"}
            id="password"
            autoComplete="current-password"
            value={signInForm.password}
            onChange={(e) => handleChange("password", e)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={togglePasswordVisibility}
                  >
                    {isPasswordVisible ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Button
            variant="text"
            size="small"
            onClick={openForgotPasswordDialog}
          >
            Forgot Password?
          </Button>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            style={{ padding: "10px" }}
          >
            Sign In
          </Button>
        </form>
        <Dialog
          maxWidth="md"
          fullWidth
          open={forgotPasswordDialogOpen}
          onClose={closeForgotPasswordDialog}
          aria-labelledby="form-dialog-title"
        >
          <Grid container>
            <Grid item xs={10}>
              <DialogTitle>Reset Password</DialogTitle>
            </Grid>
          </Grid>
          <DialogContent>
            <InputLabel className={classes.inputLabel}>Email</InputLabel>
            <TextField
              variant="outlined"
              margin="normal"
              required
              placeholder="Enter email address"
              id="email"
              name="email"
              autoComplete="email"
              autoFocus
              value={signInForm.email}
              onChange={(e) => handleChange("email", e)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={closeForgotPasswordDialog}>Cancel</Button>
            <Button onClick={handleForgotPasswordSubmit}>Submit</Button>
          </DialogActions>
        </Dialog>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
};

export default SignIn;
