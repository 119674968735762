import {
  Grid,
  IconButton,
  FormControl,
  TextField,
  makeStyles,
  InputLabel
} from "@material-ui/core";
import { AddCircleOutline, RemoveCircleOutline } from "@material-ui/icons";
import React, { useState } from "react";
import * as R from "ramda";
import { filterIndexed, mapIndexed } from "common/utils/composition";

const useStyles = makeStyles((theme) => ({
  inputFormControl: {
    marginBottom: "1rem"
  },
  inputLabel: {
    top: "-17px"
  }
}));

const EMPTY_ROW = {
  key: "",
  value: "",
  isEdit: false
};

const FieldKeyValue = ({
  propertiesLabel,
  propertiesList,
  onFieldChange,
  isReadOnly,
  isEdit
}) => {
  const classes = useStyles();
  const [keyValues, setKeyValues] = useState(propertiesList);
  const addNewRow = () => {
    const updateKeyValues = R.append(EMPTY_ROW, keyValues);
    setKeyValues(updateKeyValues);
  };

  const removeRow = (removedIndex) => {
    const updateKeyValues = filterIndexed(
      (value, index) => index !== removedIndex,
      keyValues
    );
    setKeyValues(updateKeyValues);
  };

  const onInputChange = (event, itemIndex) => {
    const [value, name] = R.paths(
      [
        ["target", "value"],
        ["target", "name"],
      ],
      event
    );
    const updateKeyValue = mapIndexed((item, index) => {
      if (itemIndex === index) {
        return R.assocPath([name], value, item);
      }
      return item;
    })(keyValues);
    setKeyValues(updateKeyValue);
    onFieldChange(keyValues);
  };

  return (
    <>
      {mapIndexed((item, index) => {
        return (
          <Grid key={index} container spacing={2} className={classes.inputFormControl}>

            <Grid item xs={8}>
              <FormControl>
                <InputLabel className={classes.inputLabel}>Name</InputLabel>
                <TextField
                  variant="outlined"
                  name="name"
                  margin="normal"
                  placeholder={propertiesLabel.key}
                  value={item.name}
                  onChange={(e) => onInputChange(e, index)}
                  disabled={item.isEdit}
                />
              </FormControl>
            </Grid>

            <Grid item xs={3}>
              <FormControl>
                <InputLabel className={classes.inputLabel}>Value</InputLabel>
                <TextField
                  variant="outlined"
                  name="value"
                  margin="normal"
                  placeholder={propertiesLabel.value}
                  value={item.value}
                  onChange={(e) => onInputChange(e, index)}
                  disabled={item.isEdit}
                />
              </FormControl>
            </Grid>

            {!isReadOnly && (
              <Grid item xs={1} style={{ alignSelf: "center", marginLeft: "-10px" }}>
                {index === keyValues.length - 1 ? (
                  <IconButton color="primary" onClick={addNewRow}>
                    <AddCircleOutline className="addIcon" />
                  </IconButton>
                ) : (
                  <IconButton color="primary" onClick={() => removeRow(index)}>
                    <RemoveCircleOutline className="addIcon" />
                  </IconButton>
                )}
              </Grid>
            )}
          </Grid>
        );
      })(keyValues)}
    </>
  );
};

export default FieldKeyValue;
