import { Button, Grid, makeStyles, Typography } from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import {
  DOWNLOAD_ICON,
  REFRESH_ICON,
  REFRESH_ICON_DISABLED,
} from "asset/images";
import { PAGE_RENDERER } from "common/utils/rendererList";
import { getDetailsById } from "components/listOfValues/utils";
import { useState } from "react";
import Alert from "react-s-alert";
import DownloadPlan from "../DownloadPlan";

const useStyles = makeStyles((theme) => ({
  container: {
    margin: "0",
    marginBottom: "51px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "30px",
    },
  },
  paddingNone: {
    padding: "0px !important",
  },
  paddingXNone: {
    padding: "0px 10px 10px  !important",
  },
  changeView: {
    padding: "0px !important",
    marginLeft: "30px",
    marginRight: "40px",
  },
  addNewPlan: {
    padding: "0px !important",
    marginRight: "-16px",
  },
  addNewPlan1: {
    [theme.breakpoints.down("sm")]: {
      padding: "0px 6px !important",
    },
  },
  title: {
    fontStyle: "normal",
    fontWeight: "800",
    fontSize: "24px",
    color: "#263657",
  },
  inputFormControl: {
    height: "36px",
  },
  downloadIcon: {
    marginRight: "10px",
  },
  downloadIconButton: {
    backgroundColor: "#898EAC",
    "&:hover": {
      backgroundColor: "#898EAC",
    },
  },
  planTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

function UnitPlanHeader({
  planId,
  getPlanDetails,
  setModalState,
  userRoles,
  parentState,
  privileges,
  isSmallDevice,
}) {
  const classes = useStyles();
  const [readViewOpen, setReadViewOpen] = useState(false);
  const AllowedRoles = ["Administrator", "Inspire Us-Core", "Coordinator"];

  const onAddClick = () => {
    setModalState((prev) => ({ ...prev, isOpen: true }));
  };

  const onDownloadClick = () => {
    setReadViewOpen(true);
  };

  const refreshConsent = async () => {
    await getDetailsById("/unit-plan/refresh-consents", planId);
    Alert.success("Consent Refreshed");
    getPlanDetails?.();
  };

  const refreshUnitPlanConsent = async () => {
    await getDetailsById(
      `/unit-plan/refresh-consents/${parentState.id}`,
      planId
    );
    getPlanDetails?.();
  };

  const isEditEnabled = (roles) => {
    let editEnabled = false;
    roles.forEach((role) => {
      if (AllowedRoles.includes(role)) {
        editEnabled = true;
      }
    });
    return editEnabled;
  };

  const isRefreshEnabled =
    isEditEnabled(userRoles) && parentState?.status === "COMPLETED";

  return (
    <>
      <Grid
        container
        spacing={3}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        className={classes.container}
      >
        <Grid
          item
          xs={12}
          md={4}
          style={{ padding: "0px 10px 10px 5px" }}
          className={isSmallDevice ? classes.planTitle : classes.paddingNone}
        >
          <Typography className={classes.title} variant="div">
            <span>Unit Plan</span>
          </Typography>
          {isSmallDevice && (
            <div className={classes.addNewPlan1}>
              <Button
                variant="contained"
                color="primary"
                className={classes.downloadIconButton}
                onClick={onDownloadClick}
              >
                <img
                  src={DOWNLOAD_ICON}
                  alt="download icon"
                  className={classes.downloadIcon}
                />
                Download
              </Button>
            </div>
          )}
        </Grid>
        <Grid
          item
          container
          spacing={3}
          md={8}
          direction="row"
          justifyContent={isSmallDevice ? "flex-start" : "flex-end"}
          alignItems="center"
          className={isSmallDevice ? classes.paddingXNone : classes.paddingNone}
        >
          <Grid item className={classes.addNewPlan1}>
            <Button
              variant="outline"
              color="primary"
              className={classes.addIconButton}
              style={{ paddingLeft: "0px" }}
              onClick={refreshConsent}
              disabled={!isRefreshEnabled}
              aria-label="Refresh consents for all lesson plans of this tier plan"
            >
              <img
                src={isRefreshEnabled ? REFRESH_ICON : REFRESH_ICON_DISABLED}
                alt="download icon"
                className={classes.downloadIcon}
              />
              Refresh Lesson Plan Consents
            </Button>
          </Grid>
          {!isSmallDevice && (
            <Grid item className={classes.addNewPlan1}>
              <Button
                variant="contained"
                color="primary"
                className={classes.downloadIconButton}
                onClick={onDownloadClick}
              >
                <img
                  src={DOWNLOAD_ICON}
                  alt="download icon"
                  className={classes.downloadIcon}
                />
                Download Unit Plan
              </Button>
            </Grid>
          )}
          <Grid item className={classes.addNewPlan1}>
            <Button
              variant="contained"
              color="primary"
              className={classes.addIconButton}
              onClick={onAddClick}
              startIcon={<AddCircleIcon className="addIcon" />}
              disabled={
                !(parentState?.status === "COMPLETED" && privileges.CREATE)
              }
            >
              Add Lesson Plan
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <DownloadPlan
        readViewOpen={readViewOpen}
        viewData={parentState}
        userRoles={userRoles}
        planName={PAGE_RENDERER.UNIT_PLAN}
        setReadViewOpen={setReadViewOpen}
        isPlanSummary={true}
      />
    </>
  );
}

export default UnitPlanHeader;
