import {
  FormControl,
  makeStyles,
  TextField,
  Typography,
  InputLabel
} from "@material-ui/core";
import FieldKeyValue from "common/components/FieldKeyValue";
import { getFieldKeyValueList } from "common/utils/formUtility";
import React, { useEffect, useState } from "react";
import * as R from "ramda";
import { useDispatch, useSelector } from "react-redux";
import { updateForm } from "state/slices/globalFormSlice";
import StatusInput from "common/components/StatusInput/StatusInput";

const useStyles = makeStyles((theme) => ({
  inputFormControl: {
    marginBottom: "1rem",
  },
  inputLabel: {
    top: "-17px"
  }
}));
const LOVForm = ({ formType, isEdit }) => {
  const classes = useStyles();
  const lovFormData = useSelector((state) => state.globalForm[formType]);
  const dispatch = useDispatch();
  const [lovData, setLovData] = useState(lovFormData);
  const onChange = (event) => {
    const [value, name] = R.paths(
      [
        ["target", "value"],
        ["target", "name"],
      ],
      event
    );
    const updatedLovData = R.assoc(name, value, lovData);
    setLovData(updatedLovData);
  };

  const propertiesList = getFieldKeyValueList({
    list: lovData.items,
    propertyAccessor: { key: "name", value: "value" },
    isEdit
  });

  const updatePropertiesList = (propertiesList) => {
    const updatedLovData = R.assoc("items", propertiesList, lovData);
    setLovData(updatedLovData);
  };

  useEffect(() => {
    dispatch(updateForm({ formType, formData: lovData }));
  }, [dispatch, formType, lovData]);

  return (
    <form style={{ marginTop: "1rem" }}>

      <FormControl className={classes.inputFormControl}>
        <InputLabel className={classes.inputLabel}>Name</InputLabel>
        <TextField
          variant="outlined"
          name="name"
          margin="normal"
          placeholder="Enter name"
          value={lovData.name}
          onChange={onChange}
        />
      </FormControl>

      <FormControl className={classes.inputFormControl}>
        <InputLabel className={classes.inputLabel}>Description</InputLabel>
        <TextField
          variant="outlined"
          name="description"
          margin="normal"
          placeholder="Enter Description"
          value={lovData.description}
          onChange={onChange}
        />
      </FormControl>

      <FormControl>
        <Typography color="primary" variant="subtitle2" style={{ marginBottom: "2rem" }}>Add Value</Typography>
        <FieldKeyValue
          propertiesLabel={{ key: "Name", value: "Value" }}
          propertiesList={propertiesList}
          onFieldChange={updatePropertiesList}
        />
      </FormControl>

      {
        !isEdit && <StatusInput formType={formType} />
      }
    </form>
  );
};

export default LOVForm;
