import { PAGE_RENDERER } from "common/utils/rendererList";
import GridViewWrapper from "common/wrappers/GridViewWrapper";
import React, { useEffect, useState } from "react";
import { GoalSettingFormInitialState } from "state/globalForms/initialStates";
import { PAGES } from "state/slices/pageSlice";
import { useDispatch } from "react-redux";
import { FORM_TYPES } from "common/utils/formUtility";
import { makeStyles } from "@material-ui/styles";
import { clearPageFilters, updateForm } from "state/slices/globalFormSlice";
import withPrivilege from "common/hocs/withPrivilege";
import { EntityMap } from "util/roleUtils";

const useStyles = makeStyles((theme) => ({
  modalCloseBtn: {
    alignSelf: "center",
    textAlign: "center",
  },
}));

const ManageReviewSetGoal = ({ privileges, userRoles }) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [currentPlan, setCurrentPlan] = useState(GoalSettingFormInitialState);
  const [isEditForm, setEditFormStatus] = useState(false);
  const dispatch = useDispatch();
  const onTabChange = (value, data) => {
    setValue(0);
  };

  const onEditPlan = (data) => {
    setEditFormStatus(true);
    dispatch(updateForm({ formType: FORM_TYPES.SET_GOAL, formData: data }));
    setCurrentPlan(data);
    setValue(1);
  };

  const onAddPlan = (data) => {
    setEditFormStatus(false);
    dispatch(
      updateForm({
        formType: FORM_TYPES.SET_GOAL,
        formData: GoalSettingFormInitialState,
      })
    );
    setValue(1);
  };

  useEffect(() => {
    dispatch(clearPageFilters());
  }, []);

  return (
    <>
      <GridViewWrapper
        page={PAGES.reviewSetGoal}
        paginatedListRenderer={PAGE_RENDERER.REVIEW_SET_GOAL}
        displayAdd={false}
        pageConfig={{
          onEditPlan: onEditPlan,
        }}
        disableReadView={true}
        showFilters={true}
        privileges={privileges}
        userRoles={userRoles}
        onTabChange={onTabChange}
      />
    </>
  );
};

export default withPrivilege(ManageReviewSetGoal, EntityMap.goalsettingreview);
