import React from "react";
import {
  makeStyles,
  Typography,
  Grid,
  Chip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
} from "@material-ui/core";
import { colors } from "theme/colors";
import * as R from "ramda";
import { ExpandMoreOutlined } from "@material-ui/icons";
import { getDetailsById, readUnitPlan } from "components/listOfValues/utils";
import { getLessonFormData } from "../utils";
import { useDispatch } from "react-redux";
import { updateForm } from "state/slices/globalFormSlice";
import { FORM_TYPES } from "common/utils/formUtility";
import LessonPlans from "./LessonPlans";
import DisplaySubUnits from "common/components/DisplaySubUnits";
import { GLOBAL_DATE_FORMAT } from "constants/PageConstants";
import moment from "moment";
import { geAnnualPeriod } from "../utils";
import { PAGES, updatePageListItems } from "state/slices/pageSlice";

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: "0.75rem",
  },
  heading: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: theme.typography.fontWeightBold,
  },
  subHeading: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightBold,
  },
  editIconContainer: {
    display: "flex",
    alignItems: "baseline",
    justifyContent: "flex-end",
  },
  editIconBtn: {
    padding: "0px",
  },
  editIcon: {
    height: "1.5rem",
    width: "1.5rem",
    fontSize: "1.5rem",
    color: "rgba(0, 0, 0, 0.54)",
  },
  label: {
    color: colors.gray4,
  },
  activeLabel: {
    color: colors.green,
  },
  deactivateLabel: {
    color: colors.red,
  },
  formContainer: {
    marginBottom: "0.5rem",
  },
  pointer: {
    display: "flex",
    alignItems: "center",
  },
  accordionDetails: {
    display: "inline",
    padding: "1rem",
    height: "auto",
    paddingLeft: "1rem",
    paddingRight: "1rem",
  },
  outcomeList: {
    height: "auto",
    width: "100%",
  },
  outcomeAccordionContainer: {
    overflow: "auto",
  },
  observationRegistryHeader: {
    backgroundColor: "#1b2945",
    color: colors.gray1,
    borderRadius: 6,
    paddingBottom: "0.5rem",
  },
  topIcon: {
    color: colors.gray1,
  },
  chip1: {
    color: "#fff",
    border: "1px solid #fff",
    fontWeight: 300,
    backgroundColor: "#263657",
  },
  subUnitChip: {
    "&.MuiChip-root": {
      marginRight: "1rem",
      color: colors.blue1,
      fontSize: "16px",
      fontWeight: "500",
    },
  },
}));

const LessonPlanListView = ({
  data,
  onTabChange,
  privileges,
  onViewClick,
  userRoles,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const onCardEditClick = async (id) => {
    const response = await getDetailsById("/lesson-plan", id);
    const lessonFormData = R.propOr(null, "lessonPlanForm", response);
    if (!R.isNil(response)) {
      if (!R.isNil(lessonFormData)) {
        const formData = getLessonFormData(lessonFormData);
        const formType =
          R.propOr("", "type", lessonFormData) === "PRECLASS"
            ? FORM_TYPES.LESSON_PLAN_PRE_PRIMARY_FORM
            : FORM_TYPES.LESSON_PLAN_FORM;
        dispatch(updateForm({ formType, formData }));
      }
      onTabChange(1, response);
    }
  };

  const subUnits = R.propOr([], "subUnits", data);
  const annualPeriodName = geAnnualPeriod(
    R.pathOr("", ["curriculumContext", "annualPeriod"], data)
  );

  const [expanded, setExpanded] = React.useState(false);
  const [openedLessonPlan, setOpenedLessonPlan] = React.useState("");

  const handleChange = (panel) => (event, isExpanded) => {
    isExpanded ? setOpenedLessonPlan(data.id) : setOpenedLessonPlan(null);
    setExpanded(isExpanded ? panel : false);
  };

  React.useEffect(() => {
    async function fetchData() {
      // You can await here
      const response = await readUnitPlan(openedLessonPlan);
      dispatch(
        updatePageListItems({
          page: PAGES.lessonPlan,
          itemObject: response,
        })
      );
      // ...
    }
    if (openedLessonPlan) fetchData();
  }, [openedLessonPlan]);

  return (
    <Grid item xs={12}>
      <div style={{ marginBottom: "1rem" }}>
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreOutlined className={classes.topIcon} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className={classes.observationRegistryHeader}
          >
            <Grid container xs={12}>
              <Grid item xs={12} md={11} style={{ marginBottom: "0.5rem" }}>
                <Typography variant="h6">
                  {R.propOr("", "name", data)}
                </Typography>
              </Grid>

              <Grid item xs={12} md={1}>
                <Chip
                  className={classes.chip1}
                  size="small"
                  label={annualPeriodName}
                />
              </Grid>

              <Grid item container style={{ marginTop: "1rem" }}>
                <Grid item xs={6} md={2}>
                  <Typography>
                    Class:{" "}
                    {R.pathOr("-", ["curriculumContext", "gradeName"], data)}
                  </Typography>
                </Grid>
                <Grid item xs={6} md={3}>
                  <Typography>
                    Subject:{" "}
                    {R.pathOr("-", ["curriculumContext", "subjectName"], data)}
                  </Typography>
                </Grid>
                <Grid item xs={6} md={2}>
                  <Typography>
                    Start:{" "}
                    {moment(R.pathOr("-", ["start"], data)).format(
                      GLOBAL_DATE_FORMAT
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={6} md={2}>
                  <Typography>
                    End:{" "}
                    {moment(R.pathOr("-", ["end"], data)).format(
                      GLOBAL_DATE_FORMAT
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={2}>
                  <Typography>
                    Status: {R.pathOr("-", ["status"], data)}
                  </Typography>
                </Grid>
              </Grid>
              <DisplaySubUnits subUnits={subUnits} />
            </Grid>
          </AccordionSummary>
          <AccordionDetails className={classes.accordionDetails}>
            <Box
              sx={{
                paddingLeft: "1rem",
                paddingRight: "1rem",
              }}
            >
              <LessonPlans
                subUnits={subUnits}
                unitData={data}
                onLessonEditClick={(id) => onCardEditClick(id)}
                privileges={privileges}
                onViewClick={onViewClick}
                userRoles={userRoles}
              />
            </Box>
          </AccordionDetails>
        </Accordion>
      </div>
    </Grid>
  );
};

export default LessonPlanListView;
