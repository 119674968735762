import { FORM_TYPES } from "common/utils/formUtility";
import * as R from "ramda";
import Alert from "react-s-alert";
import { PAGES } from "state/slices/pageSlice";
import { getLOVRequestData } from "util/APIPayloadUtils.js/LOVUtils";
import {
  addorUpdateUserRole,
  assignTempPassword,
  changePassword,
  changeStatus,
  createDuplicateAnnualPlan,
  createGoal,
  createGoalConfiguration,
  createGoalEvidence,
  createGoalFeedbackComment,
  createGoalSettingReviewComment,
  createGoalTimelineConfiguration,
  createPeerGoalFeedback,
  deleteGoal,
  deletePlanAPI,
  deleteSelfGoalFeedbackEvidence,
  deleteUserRole,
  getAllTeachersOfSchool,
  getById,
  getGoalConfigurations,
  getGoalSettingForm,
  getGoalSettingReview,
  getGoalTimelineConfigurations,
  getLOV,
  getLessonPlanFormData,
  getPageListData,
  getPeerGoalFeedback,
  getRolePrivileges,
  getSelfGoalFeedback,
  getUnitPlan,
  getUnitPlanFormData,
  planStatusAPI,
  putGoalFeedback,
  putPeerGoalFeedback,
  readResources,
  resetPassword,
  saveData,
  saveLOV,
  setLOVStatus,
  syncSchoolData,
  updateGoal,
  updateGoalSettingReviewState,
  updateGoalSettingState,
  updateLessonPlanDetails,
  updateLessonPlanFormData,
  updateTeacherSubjectMapping,
  updateUnitLessons,
  updateUnitPlanDetails,
  updateUnitPlanFormData,
} from "util/APIUtils";

export const saveLOVData = async (payload) => {
  const requestPayload = getLOVRequestData(payload);
  try {
    const response = await saveLOV(requestPayload);
    Alert.success("Value added successfully");
  } catch (error) {
    Alert.error(
      (error && error.message) ||
        "Oops! Something went wrong. Please try again!"
    );
  }
};

export const getLOVData = async ({ searchKey }) => {
  let response = { content: [] };
  try {
    const response = await getLOV({ page: 0, size: 100, name: searchKey });
    return response;
  } catch (error) {
    Alert.error(
      (error && error.message) ||
        "Oops! Something went wrong. Please try again!"
    );
    return response;
  }
};

export const setLOVActiveStatus = async (payload, id) => {
  try {
    const response = await setLOVStatus(payload, id);
    Alert.success("Status updated successfully");
  } catch (error) {
    Alert.error(
      (error && error.message) ||
        "Oops! Something went wrong. Please try again!"
    );
  }
};

export const SAVE_URL_MAP = {
  [PAGES.listOfValues]: "/lov",
  [PAGES.classSections]: "/class-sections",
  [PAGES.schools]: "/schools",
  [PAGES.subjects]: "/subjects",
  [PAGES.teachers]: "/users",
  [PAGES.observations]: "/observation-register",
  [PAGES.userTeachers]: "/users",
  [PAGES.annualPlan]: "/annual-plans",
  [PAGES.roles]: "/roles",
  [PAGES.setGoal]: "/goal-setting-form",
  [PAGES.reviewSetGoal]: "/goal-setting-review",
  [PAGES.tierPlan]: "/tier-plan",
  [PAGES.unitPlan]: "/unit-plan",
  [PAGES.lessonPlan]: "/lesson-plan",
};

export const onAddValue = async (payload, formType) => {
  const requestPayload =
    formType === FORM_TYPES.LOV_FORM ? getLOVRequestData(payload) : payload;
  const url = SAVE_URL_MAP[formType];
  try {
    const response = await saveData(requestPayload, url);
    Alert.success("Value added successfully");
  } catch (error) {
    Alert.error(
      (error && error.message) ||
        "Oops! Something went wrong. Please try again!"
    );
  }
};

export const GET_URL_MAP = {
  [PAGES.listOfValues]: "/lov",
  [PAGES.classes]: "/classes",
  [PAGES.classSections]: "/class-sections",
  [PAGES.schools]: "/schools",
  [PAGES.subjects]: "/subjects",
  [PAGES.teachers]: "/users",
  [PAGES.observations]: "/observation-register",
  [PAGES.userTeachers]: "/users",
  [PAGES.annualPlan]: "/annual-plans",
  [PAGES.unitPlan]: "/tier-plan",
  [PAGES.lessonPlan]: "/unit-plan",
  [PAGES.roles]: "/roles",
  [PAGES.privileges]: "/privileges",
  [PAGES.setGoal]: "/goal-setting-form",
  [PAGES.reviewSetGoal]: "/goal-setting-review",
  [PAGES.lessonPlanList]: "/lesson-plan",
  [PAGES.users]: "/users",
};

const getPageFilterData = (filterData) => {
  return {
    schoolCode: R.pathOr("", ["school", "schoolCode"], filterData),
    gradeName: R.pathOr("", ["gradeName"], filterData),
    subject: R.pathOr("", ["subject"], filterData),
    classTeacher: R.pathOr("", ["teacher", "email"], filterData),
    tierPlan: R.pathOr("", ["tierPlan"], filterData),
    unitPlan: R.pathOr("", ["unitPlan"], filterData),
    status: R.pathOr("", ["status"], filterData),
    annualPeriod: R.pathOr("", ["annualPeriod"], filterData),
  };
};
export const getPageList = async ({
  searchKey,
  page,
  pageData = { pageSize: 15, currentPage: 0 },
  pageFilter,
}) => {
  const { pageSize, currentPage } = pageData;
  const pageFilterData = getPageFilterData(pageFilter);
  let response = { content: [] };
  const url = GET_URL_MAP[page];
  try {
    const response = await getPageListData(
      {
        page: currentPage,
        size: pageSize,
        name: searchKey,
        pageFilter: pageFilterData,
      },
      url
    );
    return response;
  } catch (error) {
    Alert.error(
      (error && error.message) ||
        "Oops! Something went wrong. Please try again!"
    );
    return response;
  }
};

export const readAllTeachersOfSchool = async (schoolCode) => {
  try {
    const response = await getAllTeachersOfSchool(schoolCode);
    return response;
  } catch (error) {
    Alert.error(
      (error && error.message) ||
        "Oops! Something went wrong. Please try again!"
    );
  }
};

export const syncSchoolDataWithValai = async (syncRequest) => {
  try {
    const response = await syncSchoolData(syncRequest);
    Alert.success(`${syncRequest.schoolCode} successfully synced with Valai.`);
    return response;
  } catch (error) {
    Alert.error(
      (error && error.message) ||
        "Oops! Something went wrong. Please try again!"
    );
  }
};

export const saveTeacherSubjectMapping = async (payload, id) => {
  try {
    const response = await updateTeacherSubjectMapping(payload, id);
    Alert.success("Updated successfully");
  } catch (error) {
    Alert.error(
      (error && error.message) ||
        "Oops! Something went wrong. Please try again!"
    );
  }
};

export const saveUserRoleMapping = async (payload, id, isEdit) => {
  try {
    const response = await addorUpdateUserRole(payload, id, isEdit);
    Alert.success("Role added successfully");
  } catch (error) {
    Alert.error(
      (error && error.message) ||
        "Oops! Something went wrong. Please try again!"
    );
  }
};

export const deleteUserRoleMapping = async (userId, roleId) => {
  try {
    await deleteUserRole(userId, roleId);
    Alert.success("Role removed successfully");
  } catch (error) {
    Alert.error(
      (error && error.message) ||
        "Oops! Something went wrong. Please try again!"
    );
  }
};

export const assignTempPasswordToUserByAdmin = async (userEmail) => {
  try {
    const response = await assignTempPassword(userEmail);
  } catch (error) {}
};

export const updateStatus = async (payload, id, page) => {
  const url = GET_URL_MAP[page];
  try {
    const response = await changeStatus(payload, id, url);
    Alert.success("Status updated successfully");
  } catch (error) {
    Alert.error(
      (error && error.message) ||
        "Oops! Something went wrong. Please try again!"
    );
  }
};

export const saveUnitPlanDetails = async (payload, id) => {
  try {
    const response = await updateUnitPlanDetails(payload, id);
    Alert.success("Updated successfully");
  } catch (error) {
    Alert.error(
      (error && error.message) ||
        "Oops! Something went wrong. Please try again!"
    );
  }
};

export const saveLessonPlanDetails = async (payload, id) => {
  try {
    const response = await updateLessonPlanDetails(payload, id);
    Alert.success("Updated successfully");
  } catch (error) {
    Alert.error(
      (error && error.message) ||
        "Oops! Something went wrong. Please try again!"
    );
  }
};

export const LOVPayloadSelector = (data) => {
  const updatedItems = R.map((item) => {
    return R.dissocPath("isEdit", item);
  })(data.items);
  return R.pipe(
    R.dissocPath("isActive"),
    R.assocPath("items", updatedItems)
  )(data);
};

export const getDetailsById = async (url, id) => {
  try {
    const response = await getById(url, id);
    return response;
  } catch (error) {
    Alert.error(
      (error && error.message) ||
        "Oops! Something went wrong. Please try again!"
    );
    return null;
  }
};

export const saveUnitLessons = async (payload, id) => {
  try {
    const response = await updateUnitLessons(payload, id);
    Alert.success("Updated successfully");
  } catch (error) {
    Alert.error(
      (error && error.message) ||
        "Oops! Something went wrong. Please try again!"
    );
  }
};

export const saveGoals = async (payload, id) => {
  try {
    if (id && id.length) {
      const response = await updateGoal(payload, id);
    } else {
      const response = await createGoal(payload);
    }
    Alert.success("Updated successfully");
  } catch (error) {
    Alert.error(
      (error && error.message) ||
        "Oops! Something went wrong. Please try again!"
    );
  }
};

export const deleteGoals = async (id) => {
  try {
    if (id && id.length) {
      const response = await deleteGoal(id);
    }
    Alert.success("Deleted successfully");
  } catch (error) {
    // Alert.error(
    //   (error && error.message) ||
    //   "Oops! Something went wrong. Please try again!"
    // );
  }
};

export const updateGoalSettingFormState = async (
  id,
  state,
  patchableObject
) => {
  try {
    const response = await updateGoalSettingState(id, state, patchableObject);
    Alert.success("Updated successfully");
  } catch (error) {
    Alert.error(
      (error && error.message) ||
        "Oops! Something went wrong. Please try again!"
    );
  }
};

export const createDuplicateAnnualPlanReference = async (id, annualPeriod) => {
  try {
    const response = await createDuplicateAnnualPlan(id, annualPeriod);
    Alert.success("Updated successfully");
  } catch (error) {
    Alert.error(
      (error && error.message) ||
        "Oops! Something went wrong. Please try again!"
    );
  }
};

export const updateGoalSettingReviewFormState = async (id, state) => {
  try {
    const response = await updateGoalSettingReviewState(id, state);
    Alert.success("Updated successfully");
  } catch (error) {
    Alert.error(
      (error && error.message) ||
        "Oops! Something went wrong. Please try again!"
    );
  }
};
export const readGoalSettingReviewForm = async (id) => {
  try {
    const response = await getGoalSettingReview(id);
    return response;
  } catch (error) {
    Alert.error(
      (error && error.message) ||
        "Oops! Something went wrong. Please try again!"
    );
  }
};
export const readGoalConfigurations = async () => {
  try {
    const response = await getGoalConfigurations();
    return response;
  } catch (error) {
    Alert.error(
      (error && error.message) ||
        "Oops! Something went wrong. Please try again!"
    );
  }
};

export const saveGoalConfiguration = async (payload) => {
  try {
    const response = await createGoalConfiguration(payload);
    return response;
  } catch (error) {
    Alert.error(
      (error && error.message) ||
        "Oops! Something went wrong. Please try again!"
    );
  }
};

export const readGoalTimelineConfigurations = async () => {
  try {
    const response = await getGoalTimelineConfigurations();
    return response;
  } catch (error) {
    Alert.error(
      (error && error.message) ||
        "Oops! Something went wrong. Please try again!"
    );
  }
};

export const saveGoalTimelineConfiguration = async (payload) => {
  try {
    const response = await createGoalTimelineConfiguration(payload);
    return response;
  } catch (error) {
    Alert.error(
      (error && error.message) ||
        "Oops! Something went wrong. Please try again!"
    );
  }
};

export const readGoalSettingForm = async (id) => {
  try {
    const response = await getGoalSettingForm(id);
    return response;
  } catch (error) {
    Alert.error(
      (error && error.message) ||
        "Oops! Something went wrong. Please try again!"
    );
  }
};

export const saveGoalSettingReviewComment = async (payload) => {
  try {
    const response = await createGoalSettingReviewComment(payload);
    return response;
  } catch (error) {
    Alert.error(
      (error && error.message) ||
        "Oops! Something went wrong. Please try again!"
    );
  }
};

export const readSelfGoalFeedback = async (goalSetupId) => {
  try {
    const response = await getSelfGoalFeedback(goalSetupId);
    return response;
  } catch (error) {
    Alert.error(
      (error && error.message) ||
        "Oops! Something went wrong. Please try again!"
    );
  }
};

export const readPeerGoalFeedback = async (goalSetupId) => {
  try {
    const response = await getPeerGoalFeedback(goalSetupId);
    return response;
  } catch (error) {
    Alert.error(
      (error && error.message) ||
        "Oops! Something went wrong. Please try again!"
    );
  }
};

export const saveGoalFeedbackComment = async (payload, id) => {
  try {
    const response = await createGoalFeedbackComment(payload, id);
    Alert.success("Self feedback submitted for the goal.");
    return response;
  } catch (error) {
    Alert.error(
      (error && error.message) ||
        "Oops! Something went wrong. Please try again!"
    );
  }
};

export const updateGoalFeedback = async (payload) => {
  try {
    const response = await putGoalFeedback(payload);
    Alert.success("Self feedback updated for the goal.");
    return response;
  } catch (error) {
    Alert.error(
      (error && error.message) ||
        "Oops! Something went wrong. Please try again!"
    );
  }
};

export const saveGoalEvidence = async (payload) => {
  try {
    const response = await createGoalEvidence(payload);
    Alert.success("Evidence uploaded for the goal.");
    return response;
  } catch (error) {
    Alert.error(
      (error && error.message) ||
        "Oops! Something went wrong. Please try again!"
    );
  }
};

export const removeSelfGoalFeedbackEvidence = async (payload) => {
  try {
    const response = await deleteSelfGoalFeedbackEvidence(payload);
    Alert.success("Evidence removed for the goal.");
    return response;
  } catch (error) {
    Alert.error(
      (error && error.message) ||
        "Oops! Something went wrong. Please try again!"
    );
  }
};

export const savePeerGoalFeedback = async (payload) => {
  try {
    const response = await createPeerGoalFeedback(payload);
    Alert.success("Peer feedback submitted for the goal.");
    return response;
  } catch (error) {
    Alert.error(
      (error && error.message) ||
        "Oops! Something went wrong. Please try again!"
    );
  }
};

export const updatePeerGoalFeedback = async (payload, id) => {
  try {
    const response = await putPeerGoalFeedback(payload, id);
    Alert.success("Peer feedback updated for the goal.");
    return response;
  } catch (error) {
    Alert.error(
      (error && error.message) ||
        "Oops! Something went wrong. Please try again!"
    );
  }
};

export const readUnitPlan = async (id) => {
  try {
    const response = await getUnitPlan(id);
    return response;
  } catch (error) {
    Alert.error(
      (error && error.message) ||
        "Oops! Something went wrong. Please try again!"
    );
  }
};

export const getRolePrivilegesById = async (id) => {
  try {
    const response = await getRolePrivileges(id);
    return response;
  } catch (error) {
    Alert.error(
      (error && error.message) ||
        "Oops! Something went wrong. Please try again!"
    );
    return null;
  }
};

export const changeUserPassword = async (payload) => {
  try {
    let response = {};
    if (payload.passwordResetTokenId) {
      const reqObj = (({
        email,
        passwordResetTokenId,
        newPassword,
        confirmPassword,
      }) => ({
        email,
        passwordResetTokenId,
        newPassword,
        confirmPassword,
      }))(payload);
      response = await resetPassword(reqObj);
    } else {
      const reqObj = (({
        email,
        currentPassword,
        newPassword,
        confirmPassword,
      }) => ({ email, currentPassword, newPassword, confirmPassword }))(
        payload
      );
      response = await changePassword(reqObj);
    }
    return response;
  } catch (error) {
    Alert.error(
      (error && error.message) ||
        "Oops! Something went wrong. Please try again!"
    );
    return null;
  }
};

export const getUnitPlanFormAPI = async (id) => {
  try {
    const response = await getUnitPlanFormData(id);
    return response;
  } catch (error) {
    Alert.error(
      (error && error.message) ||
        "Oops! Something went wrong. Please try again!"
    );
  }
};

export const saveUnitPlanFormAPI = async (payload, id) => {
  try {
    const response = await updateUnitPlanFormData(payload, id);
    Alert.success("Updated successfully");
    return response;
  } catch (error) {
    Alert.error(
      (error && error.message) ||
        "Oops! Something went wrong. Please try again!"
    );
  }
};

export const getLessonPlanFormAPI = async (id) => {
  try {
    const response = await getLessonPlanFormData(id);
    return response;
  } catch (error) {
    Alert.error(
      (error && error.message) ||
        "Oops! Something went wrong. Please try again!"
    );
  }
};

export const saveLessonPlanFormAPI = async (payload, id) => {
  try {
    const response = await updateLessonPlanFormData(payload, id);
    Alert.success("Updated successfully");
    return response;
  } catch (error) {
    Alert.error(
      (error && error.message) ||
        "Oops! Something went wrong. Please try again!"
    );
  }
};

export const deletePlanService = async (planName, id) => {
  try {
    const response = await deletePlanAPI(id, planName);
    Alert.success("Deleted successfully");
    return response;
  } catch (error) {
    Alert.error(
      (error && error.message) ||
        "Oops! Something went wrong. Please try again!"
    );
  }
};

export const planStatusService = async (planName, id, status) => {
  try {
    const response = await planStatusAPI(id, planName, status);
    Alert.success(
      `${status === "COMPLETED" ? "Submited" : "Deactivated"} successfully`
    );
    return response;
  } catch (error) {
    Alert.error(
      (error && error.message) ||
        "Oops! Something went wrong. Please try again!"
    );
  }
};

export const getResources = async () => {
  try {
    const response = await readResources();
    return response;
  } catch (error) {
    Alert.error(
      (error && error.message) ||
        "Oops! Something went wrong. Please try again!"
    );
  }
};
