import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import {
  AddCircleOutline,
  RemoveCircleOutline,
  Visibility,
  ExpandMoreOutlined,
} from "@material-ui/icons";
import { mapIndexed } from "common/utils/composition";
import { makeStyles } from "@material-ui/styles";
import * as R from "ramda";
import SelectDropDown from "common/components/SelectDropDown/SelectDropDown";
import {
  LOVApiKeys,
  LOVDataKeys,
  LOVDisplayKeys,
  LOVSelectKeys,
} from "common/components/SelectDropDown/SelectDropDownUtils";
import {
  PAGES,
  setPageListItems,
  updatePageListItems,
} from "state/slices/pageSlice";
import { colors } from "theme/colors";
import { getUnitLessonPlanPayload } from "common/forms/utils";
import {
  getPageList,
  readUnitPlan,
  saveUnitLessons,
} from "components/listOfValues/utils";
import EditIcon from "@material-ui/icons/Edit";
import { useDispatch, useSelector } from "react-redux";
import DisplaySubUnits from "common/components/DisplaySubUnits";
import moment from "moment";
import { GLOBAL_DATE_FORMAT } from "constants/PageConstants";
import { getDetailsById } from "components/listOfValues/utils";

const useStyle = makeStyles((theme) => ({
  alignCenter: {
    display: "flex",
    alignItems: "center",
  },
  iconContainer: {
    display: "flex",
    justifyContent: "end",
  },
  inputFormControl: {
    marginTop: "1.5rem",
  },
  editViewContainer: {
    textAlign: "end",
  },
  saveBtn: {
    marginRight: "1rem",
  },
  editIconBtn: {
    margin: "0px 8px",
    padding: "0px",
  },
  readViewContainer: {
    textAlign: "end",
  },
  subUnitChip: {
    "&.MuiChip-root": {
      marginRight: "1rem",
      color: colors.blue1,
      fontSize: "16px",
      fontWeight: "500",
    },
  },
  heading: {
    fontWeight: "600",
  },
  editIcon: {
    "&.MuiSvgIcon-root": {
      fontSize: "1.3rem",
    },
  },
  headingsContainer: {
    textAlign: "left",
  },
  inputLabel: {
    top: "-17px",
  },
  inputLabel1: {
    top: "-17px",
    left: "-14px",
  },
  placeholderValue: {
    color: "#bbb",
  },
  displayobservation: {
    background: "#898eac2e",
    border: "1px solid #898EAC",
    boxShadow: "none",
    padding: "0.5rem 1rem",
    borderRadius: 6,
    marginBottom: "0px",
  },
  displayobservation1: {
    background: "#898eac2e",
    border: "1px solid #898EAC",
    boxShadow: "none",
    borderRadius: 6,
    marginBottom: "10px",
  },
  editfields: {
    margin: "1.5rem 0",
    border: "1px solid #26365785",
    borderRadius: 4,
    padding: "1rem",
    background: "#fff",
  },
}));

const LessonPlanFormInitialState = {
  subUnits: [],
  start: "",
  end: "",
  name: "",
  school: {},
};
const LessonPlans = ({
  subUnits,
  unitData,
  onLessonEditClick,
  privileges,
  onViewClick,
  userRoles,
}) => {
  const userProfile = useSelector((state) => state.userProfile.userProfile);

  const pageData = useSelector((state) =>
    R.path(["pages", PAGES.lessonPlan], state)
  );
  const pageFilter = useSelector((state) =>
    R.path(["globalForm", "pageFilters"], state)
  );
  const [isEditView, setEditView] = useState(false);
  const [lessonPlans, setLessonPlans] = useState(unitData.lessonPlans);

  const isUnitCompleted = R.propOr("", "status", unitData) === "COMPLETED";
  const classes = useStyle();
  const dispatch = useDispatch();

  const onAddLesson = () => {
    setEditView(true);
    const updatedLessonPlans = R.append(
      LessonPlanFormInitialState,
      lessonPlans
    );
    setLessonPlans(updatedLessonPlans);
  };

  const onLessonRemove = (index) => {
    const updatedLessonPlans = R.assocPath(
      [index, "deleted"],
      true,
      lessonPlans
    );
    setLessonPlans(updatedLessonPlans);
  };

  const onSchoolChange = (index, value) => {
    const updatedLessonPlans = R.assocPath(
      [index, "school"],
      value,
      lessonPlans
    );
    setLessonPlans(updatedLessonPlans);
  };

  const handleChange = (e, index) => {
    let updatedLessonPlans = [];
    if (e.target.name === "subUnits") {
      updatedLessonPlans = R.assocPath(
        [index, e.target.name],
        R.filter((unit) => R.includes(unit.name, e.target.value))(subUnits),
        lessonPlans
      );
    } else {
      updatedLessonPlans = R.assocPath(
        [index, e.target.name],
        e.target.value,
        lessonPlans
      );
    }
    setLessonPlans(updatedLessonPlans);
  };

  const onLessonSave = async () => {
    const payload = getUnitLessonPlanPayload(lessonPlans, unitData, subUnits);
    const response = await saveUnitLessons(payload, unitData.id);
    updateLessonPlan();
    setLessonPlans(unitData.lessonPlans);
  };

  const onCancel = () => {
    setEditView(false);
    setLessonPlans(unitData.lessonPlans);
  };

  const refreshConsent = async (id) => {
    await getDetailsById("/unit-plan/refresh-consents", id);
    updateLessonPlan();
  };

  const updateLessonPlan = async () => {
    const response = await readUnitPlan(unitData.id);
    dispatch(
      updatePageListItems({
        page: PAGES.lessonPlan,
        itemObject: response,
      })
    );
    setEditView(false);
  };

  const AllowedRoles = [
    "System Administrator",
    "Administrator",
    "Inspire Us-Core",
    "Coordinator",
  ];

  const isEditEnabled = (roles) => {
    let editEnabled = false;
    if (roles && roles.length > 0) {
      roles.forEach((role) => {
        if (R.includes(role, AllowedRoles)) {
          editEnabled = true;
        }
      });
    }
    return editEnabled;
  };

  useEffect(() => {
    setLessonPlans(unitData.lessonPlans);
  }, [unitData]);

  return (
    <Box>
      {(isEditView && (
        <Box className={classes.editViewContainer}>
          {mapIndexed((lesson, index) => {
            if (lesson.deleted) {
              return null;
            }
            const isSchoolExists = lesson?.curriculumContext?.school !== null;
            const isSchoolDisable =
              R.propOr("", "id", lesson) === "" ? false : isSchoolExists;

            return (
              <Accordion className={classes.displayobservation1}>
                <AccordionSummary
                  expandIcon={<ExpandMoreOutlined />}
                  aria-controls="panel1d-content"
                  id="panel1d-header"
                >
                  <Grid container>
                    <Grid item xs={12} md={9}>
                      <Typography variant="subtitle2" align="left">
                        {lesson.name}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <Typography variant="subtitle1" align="left">
                        {moment(R.propOr("", "start", lesson)).format(
                          GLOBAL_DATE_FORMAT
                        )}{" "}
                        TO{" "}
                        {moment(R.propOr("", "end", lesson)).format(
                          GLOBAL_DATE_FORMAT
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails className={classes.outcomeAccordionContainer}>
                  <Grid container spacing={1} className={classes.editfields}>
                    <Grid item xs={12} md={3} style={{ alignSelf: "center" }}>
                      <FormControl
                        variant="outlined"
                        className={classes.inputFormControl}
                      >
                        <InputLabel
                          className={classes.inputLabel1}
                          id="demo-simple-select-outlined-label"
                        >
                          School
                        </InputLabel>
                        <SelectDropDown
                          margin="normal"
                          variant="outlined"
                          selectKey={LOVSelectKeys.SCHOOL}
                          apiKey={LOVApiKeys.SCHOOL}
                          dataKey={LOVDataKeys.SCHOOL}
                          displayKey={LOVDisplayKeys.SCHOOL}
                          secondaryDataKey={"campus"}
                          selectedValue={R.pathOr(
                            R.pathOr(
                              {},
                              ["curriculumContext", "school"],
                              lesson
                            ),
                            ["school"],
                            lesson
                          )}
                          page={PAGES.schools}
                          customOnChange={(selectedValue) =>
                            onSchoolChange(index, selectedValue)
                          }
                          isDisabled={isSchoolDisable}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={9} style={{ alignSelf: "center" }}>
                      <FormControl
                        variant="outlined"
                        className={classes.inputFormControl}
                      >
                        <InputLabel
                          className={classes.inputLabel1}
                          id="demo-multiple-name-label"
                        >
                          Sub Units
                        </InputLabel>
                        <Select
                          margin="normal"
                          variant="outlined"
                          labelId="demo-multiple-name-label"
                          id="demo-multiple-name"
                          multiple
                          placeholder="subUnits"
                          value={R.map((unit) => unit.name)(lesson.subUnits)}
                          onChange={(e) => handleChange(e, index)}
                          name="subUnits"
                          displayEmpty
                          inputProps={{ "aria-label": "Without label" }}
                        >
                          <MenuItem value="" disabled>
                            <span className={classes.placeholderValue}>
                              Select sub units
                            </span>
                          </MenuItem>
                          {subUnits.map((unit) => (
                            <MenuItem key={unit.name} value={unit.name}>
                              {unit.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={2}>
                      <FormControl className={classes.inputFormControl}>
                        <InputLabel className={classes.inputLabel}>
                          Lesson Name
                        </InputLabel>
                        <TextField
                          variant="outlined"
                          name="name"
                          margin="normal"
                          placeholder="Enter lesson name"
                          value={lesson.name}
                          onChange={(e) => handleChange(e, index)}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={2}>
                      <FormControl className={classes.inputFormControl}>
                        <InputLabel className={classes.inputLabel}>
                          Start Date
                        </InputLabel>
                        <TextField
                          variant="outlined"
                          id="date"
                          margin="normal"
                          name="start"
                          placeholder={"Enter start date"}
                          type="date"
                          // sx={{ width: 220 }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={(e) => handleChange(e, index)}
                          value={R.propOr("", "start", lesson)}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={2}>
                      <FormControl className={classes.inputFormControl}>
                        <InputLabel className={classes.inputLabel}>
                          End Date
                        </InputLabel>
                        <TextField
                          variant="outlined"
                          id="date"
                          margin="normal"
                          name="end"
                          placeholder={"Enter end date"}
                          type="date"
                          sx={{ width: 220 }}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          onChange={(e) => handleChange(e, index)}
                          value={R.propOr("", "end", lesson)}
                        />
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={2}
                      style={{ alignSelf: "center", textAlign: "initial" }}
                    >
                      {index === lessonPlans.length - 1 ? (
                        <>
                          {R.propOr(false, "CREATE", privileges.lessonplan) &&
                            isUnitCompleted && (
                              <IconButton color="primary" onClick={onAddLesson}>
                                <AddCircleOutline />
                              </IconButton>
                            )}
                          <IconButton
                            color="primary"
                            onClick={() => onLessonRemove(index)}
                          >
                            <RemoveCircleOutline />
                          </IconButton>
                        </>
                      ) : R.propOr(false, "CREATE", privileges.lessonplan) ? (
                        <IconButton
                          color="primary"
                          onClick={() => onLessonRemove(index)}
                        >
                          <RemoveCircleOutline className="addIcon" />
                        </IconButton>
                      ) : null}
                    </Grid>
                    <Grid item xs={10}>
                      <DisplaySubUnits
                        subUnits={lesson.subUnits}
                        isWhiteIcon={false}
                      />
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            );
          })(lessonPlans)}
          <Button
            className={classes.saveBtn}
            color="primary"
            type="button"
            variant="contained"
            onClick={onLessonSave}
          >
            Save
          </Button>
          <Button
            color="primary"
            type="button"
            variant="outlined"
            onClick={onCancel}
          >
            Cancel
          </Button>
        </Box>
      )) || (
        <Box>
          {(lessonPlans?.filter((lesson) => !lesson.deleted).length === 0 && (
            <Grid container spacing={3} fullWidth>
              <Grid item xs={10} className={classes.alignCenter}>
                <Typography>No lesson plans available</Typography>
              </Grid>
              <Grid item xs={2} className={classes.iconContainer}>
                {R.propOr(false, "CREATE", privileges.lessonplan) &&
                  isUnitCompleted && (
                    <IconButton color="primary" onClick={onAddLesson}>
                      <AddCircleOutline className="addIcon" />
                    </IconButton>
                  )}
              </Grid>
            </Grid>
          )) || (
            <Box className={classes.readViewContainer}>
              <Grid
                container
                spacing={3}
                direction="row"
                justifyContent="flex-end"
              >
                <Grid item>
                  {R.propOr(false, "UPDATE", privileges.lessonplan) &&
                    isEditEnabled(userRoles) && (
                      <Button
                        color="secondary"
                        type="button"
                        variant="text"
                        onClick={() => refreshConsent(unitData?.id)}
                      >
                        Refresh Consents
                      </Button>
                    )}
                </Grid>
                <Grid item>
                  {R.propOr(false, "UPDATE", privileges.lessonplan) && (
                    <Button
                      color="primary"
                      type="button"
                      variant="outlined"
                      onClick={() => setEditView(true)}
                    >
                      Edit
                    </Button>
                  )}
                </Grid>
              </Grid>
              {lessonPlans
                ?.filter((lesson) => !lesson.deleted)
                ?.map((lesson) => {
                  const consentData = R.propOr([], "consents", lesson);

                  const isRelaventLessonPlan = () => {
                    let isRelavent = false;
                    if (consentData && consentData.length > 0) {
                      consentData.forEach((consent) => {
                        if (
                          userProfile.email === consent.subjectTeacher.email
                        ) {
                          isRelavent = true;
                          return isRelavent;
                        }
                      });
                    }
                    return isRelavent;
                  };
                  const isLessonCompleted = isEditEnabled(userRoles)
                    ? false
                    : R.propOr("", "status", lesson) === "COMPLETED" ||
                      !isRelaventLessonPlan();
                  return (
                    <Box
                      sx={{ textAlign: "left", marginTop: "1rem" }}
                      className={classes.displayobservation}
                    >
                      <Grid container spacing={1}>
                        <Grid item xs={11}>
                          <Typography className={classes.heading}>
                            School:{" "}
                            {`${R.pathOr(
                              "-",
                              ["curriculumContext", "school", "schoolName"],
                              lesson
                            )} - ${R.pathOr(
                              "-",
                              ["curriculumContext", "school", "campus"],
                              lesson
                            )}`}
                          </Typography>
                        </Grid>
                        <Grid item xs={1}>
                          {R.propOr(
                            false,
                            "UPDATE",
                            privileges.lessonplanform
                          ) &&
                            !isLessonCompleted && (
                              <IconButton
                                className={classes.editIconBtn}
                                onClick={() => onLessonEditClick(lesson.id)}
                              >
                                <EditIcon className={classes.editIcon} />
                              </IconButton>
                            )}
                          {R.propOr(
                            true,
                            "READ",
                            privileges.lessonplanform
                          ) && (
                            <IconButton
                              className={classes.editIconBtn}
                              onClick={() => onViewClick(lesson)}
                            >
                              <Visibility className={classes.editIcon} />
                            </IconButton>
                          )}
                        </Grid>
                        <Grid item xs={12}>
                          <Typography className={classes.heading}>
                            Created By:{" "}
                            <span noWrap>
                              {R.pathOr("-", ["createdBy"], lesson)}
                            </span>
                          </Typography>
                        </Grid>
                        <Grid item xs={5}>
                          <Typography className={classes.heading}>
                            Lesson Name: {R.pathOr("-", ["name"], lesson)}
                          </Typography>
                        </Grid>
                        <Grid item xs={6} md={2}>
                          <Typography className={classes.heading}>
                            Start Date:{" "}
                            {moment(R.pathOr("-", ["start"], lesson)).format(
                              GLOBAL_DATE_FORMAT
                            )}
                          </Typography>
                        </Grid>
                        <Grid item xs={6} md={3}>
                          <Typography className={classes.heading}>
                            End Date:{" "}
                            {moment(R.pathOr("-", ["end"], lesson)).format(
                              GLOBAL_DATE_FORMAT
                            )}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={2}>
                          <Typography className={classes.heading}>
                            Status: {R.pathOr("-", ["status"], lesson)}
                          </Typography>
                        </Grid>
                      </Grid>
                      <DisplaySubUnits
                        subUnits={lesson.subUnits}
                        isWhiteIcon={false}
                      />
                    </Box>
                  );
                })}
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default LessonPlans;
