import LOVForm from "common/forms/LOVForm";
import ManageClassSectionForm from "common/forms/ManageClassSectionForm";
import ManageSchool from "common/forms/ManageSchool";
import ManageSubjectForm from "common/forms/ManageSubjectForm";
import ManagePolicies from "common/forms/ManagePolicies";
import NotFound from "components/not-found/NotFound";
import * as R from "ramda";
import UserTeacherForm from "common/forms/UserTeacherForm";
import LessonPlanPrePrimaryForm from "common/forms/LessonPlanPrePrimaryForm";
import LessonPlanForm from "common/forms/LessonPlanForm";
import RolePrivilegesForm from "common/forms/RolePrivilegesForm";
import ObservationRegistryForm from "../../components/master/ManageObservations/ObservationRegistryForm";
import AnnualPlanStepper from "../../components/academicPlan/AnnualPlan/AnnualPlanStepper";

export const FORM_TYPES = {
  LOV_FORM: "LOVForm",
  MANAGE_SCHOOL: "ManageSchool",
  MANAGE_CLASS_SECTION: "ManageClassSection",
  MANAGE_SUBJECT: "ManageSubject",
  MANAGE_POLICIES: "ManagePolicies",
  MANAGE_OBSERVATIONS: "ManageObservations",
  TEACHER_FORM: "UserTeacherForm",
  ANNUAL_PLAN_FORM: "AnnualPlanForm",
  UNIT_PLAN_FORM: "UnitPlanForm",
  LESSON_PLAN_PRE_PRIMARY_FORM: "LessonPlanPrePrimaryForm",
  LESSON_PLAN_FORM: "LessonPlanForm",
  ROLE_PRIVILEGES_FORM: "RolePrivilegesForm",
  ADD_REGISTRY_FORM: "ObservationRegistryForm",
  ADD_ANNUAL_PLAN_FORM: "AnnualPlanStepper",
  ADD_GOAL_FORM: "AddGoalForm",
};

export const FORM_RENDERERS = {
  LOV_FORM: "LOVForm",
  MANAGE_SCHOOL: "ManageSchool",
  MANAGE_CLASS_SECTION: "ManageClassSection",
  MANAGE_SUBJECT: "ManageSubject",
  MANAGE_POLICIES: "ManagePolicies",
  MANAGE_OBSERVATIONS: "ManageObservations",
  TEACHER_FORM: "UserTeacherForm",
  ROLE_PRIVILEGES_FORM: "RolePrivilegesForm",
  MANAGE_REGISTRY: "ObservationRegistryForm",
  MANAGE_ANNUAL_PLAN_FORM: "AnnualPlanStepper",
  MANAGE_LESSON_PLAN_FORM: "LessonPlanForm",
  ADD_GOAL_FORM: "AddGoalForm",
};

export const KNOWN_FORM_RENDERERS = {
  LOVForm: LOVForm,
  ManageSchool: ManageSchool,
  ManageClassSection: ManageClassSectionForm,
  ManageSubject: ManageSubjectForm,
  ManagePolicies: ManagePolicies,
  UserTeacherForm: UserTeacherForm,
  LessonPlanPrePrimaryForm: LessonPlanPrePrimaryForm,
  LessonPlanForm: LessonPlanForm,
  RolePrivilegesForm: RolePrivilegesForm,
  ObservationRegistryForm: ObservationRegistryForm,
  AnnualPlanStepper: AnnualPlanStepper,
};

export const getFormRenderer = R.propOr(NotFound, R.__, KNOWN_FORM_RENDERERS);

export const getFieldKeyValueList = ({ list, propertyAccessor, isEdit }) => {
  return R.map((item) => {
    return {
      name: item[propertyAccessor.key],
      value: item[propertyAccessor.value],
      isEdit,
    };
  }, list);
};

export const completementStatus = (status) => {
  return status === "ACTIVE" ? "INACTIVE" : "ACTIVE";
};
