import { Button, Grid, makeStyles, Typography } from "@material-ui/core";
import { DOWNLOAD_ICON } from "asset/images";
import { useState } from "react";
import DownloadPlan from "../DownloadPlan";
import { PAGE_RENDERER } from "common/utils/rendererList";
import { planStatusService } from "components/listOfValues/utils";
import ConfirmationPopup from "../ConfirmationPopup";

const useStyles = makeStyles((theme) => ({
  container: {
    margin: "0",
    marginBottom: "51px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "30px",
    },
  },
  paddingNone: {
    padding: "0px !important",
  },
  paddingXNone: {
    padding: "0px 10px 10px  !important",
  },
  changeView: {
    padding: "0px !important",
    marginLeft: "30px",
    marginRight: "40px",
  },
  addNewPlan: {
    padding: "0px !important",
    marginRight: "-16px",
  },
  addNewPlan1: {
    [theme.breakpoints.down("sm")]: {
      padding: "0px 6px !important",
    },
  },
  title: {
    fontStyle: "normal",
    fontWeight: "800",
    fontSize: "24px",
    color: "#263657",
  },
  inputFormControl: {
    height: "36px",
  },
  downloadIcon: {
    marginRight: "10px",
  },
  downloadIconButton: {
    backgroundColor: "#898EAC",
    "&:hover": {
      backgroundColor: "#898EAC",
    },
  },
  planTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}));

function AnnualPlanHeader({
  parentState,
  userRoles,
  privileges,
  getPlanDetails,
  isSmallDevice,
}) {
  const classes = useStyles();
  const [readViewOpen, setReadViewOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const AllowedRoles = ["Administrator"];

  const onSubmitClick = async () => {
    const res = await planStatusService(
      "annual-plans",
      parentState?.id,
      "COMPLETED"
    );
    if (res) {
      getPlanDetails();
      setIsOpen(false);
    }
  };
  const onDownloadClick = () => {
    setReadViewOpen(true);
  };

  const isEditEnabled = (roles) => {
    let editEnabled = false;
    roles.forEach((role) => {
      if (AllowedRoles.includes(role)) {
        editEnabled = true;
      }
    });
    return editEnabled;
  };

  const isSubmitDisabled = isEditEnabled(userRoles) ? false : true;

  return (
    <>
      <Grid
        container
        spacing={3}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        className={classes.container}
      >
        <Grid
          item
          xs={12}
          md={6}
          style={{ padding: "0px 10px 10px 5px", }}
          className={isSmallDevice ? classes.planTitle : classes.paddingNone}
        >
          <Typography className={classes.title} variant="div">
            <span>Annual Plan</span>
          </Typography>
          {isSmallDevice && <div className={classes.addNewPlan1}>
            <Button
              variant="contained"
              color="primary"
              className={classes.downloadIconButton}
              onClick={onDownloadClick}
            >
              <img
                src={DOWNLOAD_ICON}
                alt="download icon"
                className={classes.downloadIcon}
              />
              Download
            </Button>
          </div>}
        </Grid>
        <Grid
          item
          container
          spacing={3}
          md={6}
          direction="row"
          justifyContent={isSmallDevice ? "flex-start" : "flex-end"}
          alignItems="center"
          className={isSmallDevice ? classes.paddingXNone : classes.paddingNone}
        >
          {!isSmallDevice && <Grid item className={classes.addNewPlan1}>
            <Button
              variant="contained"
              color="primary"
              className={classes.downloadIconButton}
              onClick={onDownloadClick}
            >
              <img
                src={DOWNLOAD_ICON}
                alt="download icon"
                className={classes.downloadIcon}
              />
              Download Annual Plan
            </Button>
          </Grid>}
          <Grid item className={classes.addNewPlan1}>
            <Button
              variant="contained"
              color="primary"
              className={classes.addIconButton}
              onClick={() => setIsOpen(true)}
              disabled={
                parentState?.status?.toUpperCase() === "COMPLETED"
                  ? isSubmitDisabled
                  : !privileges?.UPDATE
              }
            >
              Submit Annual Plan
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <DownloadPlan
        readViewOpen={readViewOpen}
        viewData={parentState}
        userRoles={userRoles}
        planName={PAGE_RENDERER.ANNUAL_PLAN}
        setReadViewOpen={setReadViewOpen}
      />
      <ConfirmationPopup
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        confirmationText={"Are you sure you want to submit this plan?"}
        handleAction={onSubmitClick}
      />
    </>
  );
}

export default AnnualPlanHeader;
