import {
  Box,
  CardContent,
  FormControl,
  Grid,
  IconButton,
  makeStyles,
  TextField,
  InputLabel,
  Typography,
  Card,
  Button,
  Tooltip,
} from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as R from "ramda";
import { updateForm } from "state/slices/globalFormSlice";
import {
  LOVDataKeys,
  LOVKeyMap,
} from "common/components/SelectDropDown/SelectDropDownUtils";
import {
  AddCircleOutline,
  CloudDownload,
  RemoveCircleOutlineOutlined,
} from "@material-ui/icons";
import LOVSelect from "common/components/LOVSelect/LOVSelect";
import { FORM_TYPES } from "common/utils/formUtility";

import { mapIndexed } from "common/utils/composition";
import {
  TierPlanFormInitialState,
  TierTopicFormInitialState,
} from "state/globalForms/initialStates";
import TierTopicForm from "./TierTopicForm";
import { getDownloadFileName } from "common/utils/downloadAsPdf";
import { colors } from "theme/colors";
import { GLOBAL_DATE_FORMAT } from "constants/PageConstants";
import moment from "moment";
import { useReactToPrint } from "react-to-print";

const useStyles = makeStyles((theme) => ({
  inputFormControl: {
    marginBottom: "1.5rem",
  },
  addTierButton: {
    width: "0.75em",
    height: "0.75em",
  },
  addIconContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: "20px",
  },
  tierTitle: {
    display: "flex",
    alignItems: "center",
  },
  tierCard: {
    margin: "1rem 0rem",
  },
  readOnlyContainer: {
    backgroundColor: "#263657",
    padding: "1rem",
    color: colors.gray1,
    marginBottom: "2rem",
    borderRadius: 6,
  },

  downloadBtn: {
    padding: "10px 15px",
    border: `1px solid ${colors.gray1}`,
    background: "#263657",
    color: colors.gray1,
  },
  inputLabel1: {
    top: "-17px",
    left: "-14px",
  },
  inputLabel: {
    top: "-17px",
  },
}));

const ReadOnlyData = ({ data, downloadAsPdf }) => {
  const classes = useStyles();
  const subTopics = R.pathOr([], ["subTopics"], data);
  return (
    <Grid container className={classes.readOnlyContainer}>
      <Grid item xs={12} md={10}>
        <Typography variant="h6">{R.propOr("", "name", data)}</Typography>
      </Grid>
      <Grid item xs={12} md={2}>
        <Button
          endIcon={<CloudDownload />}
          className={classes.downloadBtn}
          variant="text"
          data-html2canvas-ignore
          onClick={downloadAsPdf}
        >
          Download
        </Button>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography>
            Class: {R.pathOr("-", ["curriculumContext", "gradeName"], data)}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography>
            Subject: {R.pathOr(["curriculumContext", "subjectName"], data)}
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography>
            Start:{" "}
            {moment(R.pathOr("-", ["start"], data)).format(GLOBAL_DATE_FORMAT)}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>
            End:{" "}
            {moment(R.pathOr("-", ["end"], data)).format(GLOBAL_DATE_FORMAT)}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography>Status: {R.pathOr(["status"], data)}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

const TierPlanForm = ({ formType, isReadOnly }) => {
  const printRef = useRef();
  const classes = useStyles();
  const annualPlanForm = useSelector((state) => state.globalForm[formType]);
  const tierPlans = R.propOr([], "tierPlans", annualPlanForm);
  const [tierPlansList, setTierPlanList] = useState(tierPlans);

  const dispatch = useDispatch();

  const onChange = (event, index) => {
    const [value, name] = R.paths(
      [
        ["target", "value"],
        ["target", "name"],
      ],
      event
    );
    const updatedTierPlansList = R.assocPath(
      [index, name],
      value,
      tierPlansList
    );
    setTierPlanList(updatedTierPlansList);
    const updatedformData = R.assocPath(
      ["tierPlans"],
      updatedTierPlansList,
      annualPlanForm
    );
    dispatch(updateForm({ formType, formData: updatedformData }));
  };

  const addNewTier = () => {
    const updatedTiers = R.append(TierPlanFormInitialState, tierPlansList);
    setTierPlanList(updatedTiers);
    const updatedformData = R.assocPath(
      ["tierPlans"],
      updatedTiers,
      annualPlanForm
    );
    dispatch(updateForm({ formType, formData: updatedformData }));
  };

  const onTopicChange = (tierIndex, topicIndex, topicData) => {
    const updatedTierPlansList = R.assocPath(
      [tierIndex, "topics", topicIndex],
      topicData,
      tierPlansList
    );
    setTierPlanList(updatedTierPlansList);
    const updatedformData = R.assocPath(
      ["tierPlans"],
      updatedTierPlansList,
      annualPlanForm
    );
    dispatch(updateForm({ formType, formData: updatedformData }));
  };

  const addNewTopic = (tierIndex) => {
    const updatedTierTopicPlans = R.append(
      TierTopicFormInitialState,
      tierPlansList[tierIndex].topics
    );
    const updatedTierList = R.assocPath(
      [tierIndex, "topics"],
      updatedTierTopicPlans,
      tierPlansList
    );
    setTierPlanList(updatedTierList);
    const updatedformData = R.assocPath(
      ["tierPlans"],
      updatedTierList,
      annualPlanForm
    );
    dispatch(updateForm({ formType, formData: updatedformData }));
  };

  const onRemoveTopic = (topicIndex, tierIndex) => {
    const updatedTierTopicPlans = R.remove(
      topicIndex,
      1,
      tierPlansList[tierIndex].topics
    );

    const updatedTierList = R.assocPath(
      [tierIndex, "topics"],
      updatedTierTopicPlans,
      tierPlansList
    );

    setTierPlanList(updatedTierList);
    const updatedformData = R.assocPath(
      ["tierPlans"],
      updatedTierList,
      annualPlanForm
    );
    dispatch(updateForm({ formType, formData: updatedformData }));
  };

  const removeRow = (index) => {
    const updatedTiers = R.remove(index, 1, tierPlansList);
    setTierPlanList(updatedTiers);
    const updatedformData = R.assocPath(
      ["tierPlans"],
      updatedTiers,
      annualPlanForm
    );
    dispatch(updateForm({ formType, formData: updatedformData }));
  };
  useEffect(() => {
    setTierPlanList(R.propOr([], "tierPlans", annualPlanForm));
  }, [annualPlanForm]);

  const downloadFileName = getDownloadFileName({
    data: annualPlanForm,
    templateId: "annual_plan",
  });

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  return (
    <Box id="annualPlanForm" ref={printRef} sx={{ margin: "1rem" }}>
      <ReadOnlyData
        ReadOnlyData
        data={annualPlanForm}
        downloadAsPdf={handlePrint}
      />
      <Grid container spacing={1}>
        <Grid item xs={10} className={classes.tierTitle}>
          <Typography variant="h6" color="primary">
            Tier
          </Typography>
        </Grid>
        <Grid item xs={2} className={classes.addIconContainer}>
          <IconButton
            disabled={isReadOnly}
            color="primary"
            onClick={addNewTier}
          >
            <AddCircleOutline className={classes.addTierButton} />
          </IconButton>
        </Grid>
      </Grid>
      {mapIndexed((item, tierIndex) => {
        return (
          <Card className={classes.tierCard} key={tierIndex}>
            <CardContent>
              <form>
                {tierIndex !== 0 && (
                  <Box style={{ textAlign: "end" }}>
                    <Tooltip title="Remive Tier" placement="top-start">
                      <IconButton
                        disabled={isReadOnly}
                        color="primary"
                        onClick={() => removeRow(tierIndex)}
                      >
                        <RemoveCircleOutlineOutlined
                          className={classes.addTierButton}
                        />
                      </IconButton>
                    </Tooltip>
                  </Box>
                )}

                <Grid container spacing={1} style={{ marginTop: "1rem" }}>
                  <Grid item xs={12}>
                    <FormControl
                      variant="outlined"
                      className={classes.inputFormControl}
                    >
                      <InputLabel
                        className={classes.inputLabel1}
                        id="demo-simple-select-outlined-label"
                      >
                        Tier Plan Name
                      </InputLabel>
                      <LOVSelect
                        margin="normal"
                        formType={FORM_TYPES.ANNUAL_PLAN_FORM}
                        selectKey={["tierPlans", tierIndex, "value"]}
                        dataKey={LOVDataKeys.TIER_PLAN}
                        lovKey={LOVKeyMap.TIER_PLAN}
                        value={item.value}
                        placeholder="Select Tier plan"
                        isReadOnly={isReadOnly}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl className={classes.inputFormControl}>
                      <InputLabel className={classes.inputLabel}>
                        Start Date
                      </InputLabel>
                      <TextField
                        margin="normal"
                        disabled={isReadOnly}
                        variant="outlined"
                        id="date"
                        name="start"
                        placeholder={"Enter start date"}
                        type="date"
                        sx={{ width: 220 }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={(e) => onChange(e, tierIndex)}
                        value={item.start}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl className={classes.inputFormControl}>
                      <InputLabel className={classes.inputLabel}>
                        End Date
                      </InputLabel>
                      <TextField
                        margin="normal"
                        disabled={isReadOnly}
                        variant="outlined"
                        id="date"
                        name="end"
                        placeholder={"Enter end date"}
                        type="date"
                        sx={{ width: 220 }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={(e) => onChange(e, tierIndex)}
                        value={item.end}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </form>
              <Box>
                <Grid container spacing={1}>
                  <Grid item xs={10}>
                    <Typography variant="h6" color="primary">
                      Topic
                    </Typography>
                  </Grid>
                  <Grid item xs={2} className={classes.addIconContainer}>
                    <IconButton
                      disabled={isReadOnly}
                      color="primary"
                      onClick={() => addNewTopic(tierIndex)}
                    >
                      <AddCircleOutline className={classes.addTierButton} />
                    </IconButton>
                  </Grid>
                </Grid>
                {mapIndexed((topic, topicIndex) => {
                  return (
                    <TierTopicForm
                      key={`${tierIndex}-${topicIndex}`}
                      onRemoveTopic={() => onRemoveTopic(topicIndex, tierIndex)}
                      data={topic}
                      tierIndex={tierIndex}
                      topicIndex={topicIndex}
                      onTopicChange={onTopicChange}
                      formType={formType}
                      isReadOnly={isReadOnly}
                    />
                  );
                })(item.topics)}
              </Box>
            </CardContent>
          </Card>
        );
      })(tierPlansList)}
    </Box>
  );
};

export default TierPlanForm;
