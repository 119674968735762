import { Button, Grid, makeStyles, Typography } from "@material-ui/core";
import { DOWNLOAD_ICON } from "asset/images";
import { useState } from "react";
import DownloadPlan from "../DownloadPlan";
import { PAGE_RENDERER } from "common/utils/rendererList";

const useStyles = makeStyles((theme) => ({
  container: {
    margin: "0",
    marginBottom: "51px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "30px",
    },
  },
  paddingNone: {
    padding: "0px !important",
  },
  paddingXNone: {
    padding: "0px 10px 10px  !important",
  },
  addNewPlan1: {
    [theme.breakpoints.down("sm")]: {
      padding: "0px 6px !important",
    },
  },
  title: {
    fontStyle: "normal",
    fontWeight: "800",
    fontSize: "24px",
    color: "#263657",
  },
  inputFormControl: {
    height: "36px",
  },
  downloadIcon: {
    marginRight: "10px",
  },
  downloadIconButton: {
    backgroundColor: "#898EAC",
    "&:hover": {
      backgroundColor: "#898EAC",
    },
  },
  planTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

function LessonPlanHeader({ parentState, userRoles, isSmallDevice }) {
  const classes = useStyles();
  const [readViewOpen, setReadViewOpen] = useState(false);

  const onDownloadClick = () => {
    setReadViewOpen(true);
  };

  return (
    <>
      <Grid
        direction="row"
        className={[classes.container, classes.planTitle]}
      >
        <Typography className={classes.title} variant="div">
          <span>Lesson Plan</span>
        </Typography>
        <div className={classes.addNewPlan1}>
          <Button
            variant="contained"
            color="primary"
            className={classes.downloadIconButton}
            onClick={onDownloadClick}
          >
            <img
              src={DOWNLOAD_ICON}
              alt="download icon"
              className={classes.downloadIcon}
            />
            Download {isSmallDevice ? "" : " Lesson Plan"}
          </Button>
        </div>
      </Grid>
      <DownloadPlan
        readViewOpen={readViewOpen}
        viewData={parentState}
        userRoles={userRoles}
        planName={PAGE_RENDERER.LESSON_PLAN}
        setReadViewOpen={setReadViewOpen}
        isPlanSummary={true}
      />
    </>
  );
}

export default LessonPlanHeader;
