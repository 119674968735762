import { Box, Divider, FormControl, Grid, InputLabel, List, ListItem, ListItemIcon, ListItemText, MenuItem, Select, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import * as R from "ramda";
import { useDispatch, useSelector } from "react-redux";
import { PAGES } from "state/slices/pageSlice";
import { makeStyles } from "@material-ui/styles";
import { updateForm } from "state/slices/globalFormSlice";
import { mapIndexed, mergeArrayList } from "common/utils/composition";
import { CheckCircleOutlined } from "@material-ui/icons";

const useStyle = makeStyles(theme => ({
    controlForm: {
        "&.MuiFormControl-fullWidth": {
            maxWidth: "810px"
        }
    },
    observationTitle: {
        "&.MuiGrid-root": {
            display: "flex",
            alignItems: "end"
        }
    },
    title: {
        "&.MuiTypography-root": {
            fontWeight: "600",
        }
    },
    outcomeList:{
        "&.MuiList-root":{
            maxHeight:"none"
        },
        border: '1px solid #898EAC',
        background: '#898eac2e',
        borderRadius: 6,
    },
    inputLabel1: {
        top: "-17px",
        left: "-14px",
    },
      inputLabel: {
          top: "-17px",
    },
}));

const isItemListEmpty = (referenceOutComeList, observation) => {
    let isEmpty = true;
    R.map(item => {
        if (R.includes(item.id, referenceOutComeList)) {
            isEmpty = false;
        }
    })(R.propOr([], "learningOutcomes", observation));
    return isEmpty;
}

const getLearningOutcomesPayload = (outcomes) => {
    let learningOutcomes = [];
    R.map(eachOutcomeList => {
        learningOutcomes = [...learningOutcomes, ...eachOutcomeList]
    })(R.values(outcomes))
    return R.map(outcomeId => ({ learningOutcomeId: outcomeId }))(learningOutcomes)
};

const ObservationSelectionForm = ({ className, formType, value = {}, referenceOutComeList = [], isLesson = false, isReadOnly = false, hideSubTitle = false, setLearningOutcomes }) => {
    const classes = useStyle();
    const observations = useSelector(state => state.pages[PAGES.observations]);
    const [currentLearningOutcomes, setCurrentLearningOutcomes] = useState({ ...value });
    const formData = useSelector(state => state.globalForm[formType]);
    const dispatch = useDispatch();

    const observationRegistry = R.filter(item => {
        return R.includes(className, R.map(classItem => classItem.className)(item.grades))
    })(R.propOr([], "items", observations))[0];

    const onLearningOutcomeChange = (e, index) => {
        const updatedOutcomes = R.assocPath([index], e.target.value, currentLearningOutcomes);
        setCurrentLearningOutcomes(updatedOutcomes);
        updateFinalForm(updatedOutcomes)
    }
    const updateFinalForm = (updatedOutcomes) => {
        const learningOutcomes = getLearningOutcomesPayload(updatedOutcomes);
        setLearningOutcomes?.(learningOutcomes)
        const updatedForm = R.assocPath(["learningOutcomes"], learningOutcomes, formData);
        dispatch(updateForm({ formType, formData: updatedForm }))
    }
    if (R.isEmpty(observationRegistry) || R.isNil(observationRegistry)) {
        return null;
    }

    if (!R.equals(value, currentLearningOutcomes)) {
        setCurrentLearningOutcomes(value);
    }

    return (
        <Box>
            {!hideSubTitle && <Typography variant="subtitle2">{observationRegistry.name}</Typography>}
            {
                mapIndexed((observation, index) => {
                    return (
                        <Grid container spacing={1} >
                            <Grid item xs={12} className={classes.observationTitle} style={index !== 0 ? { marginTop: "1.5rem" } : {}}>
                                <Typography variant="subtitle2">{`${observation.sortOrder} - ${observation.name}`}</Typography>
                            </Grid>
                            <Grid item xs={12} style={{ marginTop: "2rem" }}>
                                <FormControl  variant="outlined" className={classes.controlForm}>
                                    <InputLabel className={classes.inputLabel1}>Learning Outcomes</InputLabel>
                                    <Select
                                        variant="outlined"
                                        margin="normal"
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        className={classes.wrapText}
                                        // style={{ whiteSpace: 'pre-wrap' }}
                                        value={R.propOr([], index, value)}
                                        // placeholder="Observations"
                                        onChange={(e) => onLearningOutcomeChange(e, index)}
                                        multiple
                                        fullWidth
                                        disabled={isReadOnly || (isLesson && isItemListEmpty(referenceOutComeList, observation))}
                                    >
                                         <MenuItem value="" disabled>
                                            <span style={{ color: '#bbb' }}>Select learning outcomes</span>
                                        </MenuItem>
                                        {
                                            isLesson ? (
                                                R.map(item => {
                                                    return R.includes(item.id, referenceOutComeList) ? (<MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>) : null
                                                })(R.propOr([], "learningOutcomes", observation))
                                            ) : (
                                                R.map(item => {
                                                    return (<MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                                                    )
                                                })(R.propOr([], "learningOutcomes", observation))
                                            )
                                        }

                                    </Select>
                                </FormControl>
                            </Grid>
                            {
                                !R.isEmpty(R.pathOr([], [index], currentLearningOutcomes)) && (
                                    <Grid item xs={12} sx={{ marginBottom: "1rem" }}>
                                        {
                                            <Box sx={{ marginTop: "1rem", width: "100%" }}>
                                                <Typography variant="subtitle1">
                                                    Outcomes
                                                </Typography>
                                                <List className={classes.outcomeList}>
                                                    {
                                                        mapIndexed((item, outcomeIndex) => {
                                                            if (!R.includes(item.id, R.pathOr([], [index], currentLearningOutcomes))) {
                                                                return null;
                                                            }
                                                            return (
                                                                <ListItem>
                                                                    <ListItemIcon>
                                                                        <CheckCircleOutlined fontSize={"small"} />
                                                                    </ListItemIcon>
                                                                    <ListItemText
                                                                        primary={item.name}
                                                                    />
                                                                </ListItem>
                                                            )
                                                        })(R.propOr([], "learningOutcomes", observation))
                                                    }
                                                </List>
                                            </Box>
                                        }
                                        <Divider />
                                    </Grid>
                                )
                            }
                        </Grid>
                        
                    )
                })(R.propOr([], "observations", observationRegistry))
            }
        </Box> 
    )
};

export default ObservationSelectionForm;