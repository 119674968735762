import React, { useState } from "react";
import {
  Box,
  Container,
  Step,
  StepLabel,
  Stepper,
  Typography,
  Button,
  makeStyles,
} from "@material-ui/core";
import AnnualPlanForm from "common/forms/AnnualPlanForm";
import TierPlanForm from "common/forms/TierPlanForm";
import { FORM_TYPES } from "common/utils/formUtility";
import { useDispatch, useSelector } from "react-redux";
import { annualFormPayloadSelector } from "../utils";
import { onAddValue } from "components/listOfValues/utils";
import { PAGES } from "state/slices/pageSlice";
import { updateForm } from "state/slices/globalFormSlice";
import { AnnualPlanInitialState } from "state/globalForms/initialStates";
import * as R from "ramda";

const useStyles = makeStyles((theme) => ({}));
const steps = ["Annual Plan", "Tier Plan"];

const STEP_FORM = {
  0: AnnualPlanForm,
  1: TierPlanForm,
};
const AnnualPlanStepper = ({ userRoles, onTabChange, isEditForm }) => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const dispatch = useDispatch();
  const annualPlanFormData = useSelector(
    (state) => state.globalForm[FORM_TYPES.ANNUAL_PLAN_FORM]
  );
  const handleNext = async (e, annualPlanStatus = "ACTIVE") => {
    if (activeStep === steps.length - 1) {
      const payload = annualFormPayloadSelector(
        annualPlanFormData,
        annualPlanStatus
      );
      const response = await onAddValue(payload, PAGES.annualPlan);
      dispatch(
        updateForm({
          formType: FORM_TYPES.ANNUAL_PLAN_FORM,
          formData: AnnualPlanInitialState,
        })
      );
      onTabChange && onTabChange();
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };
  const AllowedRoles = ["System Administrator", "Administrator"];

  const isEditEnabled = (roles) => {
    let editEnabled = false;
    if (roles && roles.length > 0) {
      roles.forEach((role) => {
        if (R.includes(role, AllowedRoles)) {
          editEnabled = true;
        }
      });
    }
    return editEnabled;
  };

  const isReadOnly = isEditEnabled(userRoles)
    ? false
    : R.propOr("", "status", annualPlanFormData) === "COMPLETED";

  const isNew = R.propOr("", "id", annualPlanFormData) === "";
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const getStepForm = (isEditForm) => {
    const StepRenderer = STEP_FORM[activeStep];
    return (
      <StepRenderer
        formType={FORM_TYPES.ANNUAL_PLAN_FORM}
        isEditForm={isEditForm}
        isReadOnly={isReadOnly}
      />
    );
  };

  return (
    <Box>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        <React.Fragment>
          <Typography>All steps completed - you&apos;re finished</Typography>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Box sx={{ flex: "1 1 auto" }} />
            <Button onClick={handleReset}>Reset</Button>
          </Box>
        </React.Fragment>
      ) : (
        <Container>
          {getStepForm(isEditForm)}

          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Back
            </Button>
            <Box sx={{ flex: "1 1 auto" }} />
            <Button
              disabled={activeStep === 1 && isReadOnly}
              onClick={handleNext}
            >
              {activeStep === steps.length - 1 ? "Save" : "Next"}
            </Button>
            <Button
              color="primary"
              disabled={activeStep === 0 || isReadOnly || isNew}
              variant="contained"
              onClick={(e) => handleNext(e, "COMPLETED")}
              data-html2canvas-ignore
            >
              Submit
            </Button>
          </Box>
        </Container>
      )}
    </Box>
  );
};

export default AnnualPlanStepper;
