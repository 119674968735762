import React, { useEffect, useState } from "react";
import {
  makeStyles,
  Typography,
  Grid,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Chip,
  Divider,
  Button,
} from "@material-ui/core";
import { colors } from "theme/colors";
import * as R from "ramda";
import {
  AddCircleOutline,
  RemoveCircleOutline,
  Visibility,
  ExpandMoreOutlined,
} from "@material-ui/icons";

import EditIcon from "@material-ui/icons/Edit";
import { getDetailsById, getPageList } from "components/listOfValues/utils";
import { getUnitFormData } from "../utils";
import { useDispatch, useSelector } from "react-redux";
import { updateForm } from "state/slices/globalFormSlice";
import { FORM_TYPES } from "common/utils/formUtility";
import { mapIndexed } from "common/utils/composition";
import DisplaySubUnits from "common/components/DisplaySubUnits";
import TierUnitPlanForm from "common/forms/TierUnitPlanForm";
import { TierUnitPlanFormInitialState } from "state/globalForms/initialStates";
import { updateTierPlan } from "util/APIUtils";
import {
  PAGES,
  setPageListItems,
  updatePageListItems,
} from "state/slices/pageSlice";
import moment from "moment";
import { GLOBAL_DATE_FORMAT } from "constants/PageConstants";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { DisplatReadOnlyTopics } from "common/molecules/TopicsDropDown";
import { geAnnualPeriod } from "../utils";

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: "0.75rem",
  },
  heading: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: theme.typography.fontWeightBold,
  },
  subHeading: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightBold,
  },
  editIconContainer: {
    display: "flex",
    alignItems: "baseline",
    justifyContent: "flex-end",
  },
  editIconBtn: {
    padding: "5px",
  },
  editIcon: {
    fontSize: "1.3rem",
    color: "rgba(0, 0, 0, 0.54)",
  },
  label: {
    color: colors.gray4,
  },
  activeLabel: {
    color: colors.green,
  },
  deactivateLabel: {
    color: colors.red,
  },
  formContainer: {
    marginBottom: "0.5rem",
  },
  pointer: {
    display: "flex",
    alignItems: "center",
  },
  accordionDetails: {
    display: "inline",
    padding: "1rem",
    height: "auto",
    paddingLeft: "1rem",
    paddingRight: "1rem",
  },
  outcomeList: {
    height: "auto",
    width: "100%",
  },
  outcomeAccordionContainer: {
    overflow: "auto",
  },
  observationRegistryHeader: {
    backgroundColor: "#263657",
    color: colors.gray1,
    borderRadius: 6,
  },
  chip1: {
    color: "#fff",
    border: "1px solid #fff",
    fontWeight: 300,
    backgroundColor: "#263657",
  },
  unitDetails: {
    marginTop: "0.5rem",
    marginBottom: "0.5rem",
  },
  saveBtn: {
    marginRight: "1rem",
  },
  topIcon: {
    color: colors.gray1,
  },
  addIconContainer: {
    "&.MuiGrid-root": {
      marginTop: "12px",
    },
  },
  displayobservation: {
    background: "#898eac2e",
    border: "1px solid #898EAC",
    boxShadow: "none",
  },
  divider: {
    backgroundColor: "#263657",
  },
}));

const UnitPlanListView = ({
  data,
  onTabChange,
  privileges,
  userRoles,
  onViewClick,
  onEditClick,
  index,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isEditView, setEditView] = useState(false);
  const [unitPlans, setUnitPlans] = useState(R.propOr([], "unitPlans", data));
  const pageData = useSelector((state) =>
    R.path(["pages", PAGES.unitPlan], state)
  );
  const pageFilter = useSelector((state) =>
    R.path(["globalForm", "pageFilters"], state)
  );

  const onCardEditClick = async (id) => {
    const response = await getDetailsById("/unit-plan", id);
    const lessonPlans = R.propOr([], "lessonPlans", response);
    const unitFormData = R.propOr(null, "unitPlanForm", response);
    if (!R.isNil(response)) {
      if (!R.isNil(unitFormData)) {
        const formData = getUnitFormData(unitFormData, lessonPlans);
        dispatch(updateForm({ formType: FORM_TYPES.UNIT_PLAN_FORM, formData }));
      }
      onTabChange && onTabChange(1, response); // NOTE: need to change this function name to "edit plan form"
    }
  };

  const onCardViewClick = async (id) => {
    const response = await getDetailsById("/unit-plan", id);
    onViewClick(response);
  };

  const onTierSave = async () => {
    try {
      const payload = R.assocPath(["unitPlans"], unitPlans, data);
      const response = await updateTierPlan(payload, data.id);
      await getTierPlan(data.id);
    } catch (error) {
      console.log("test", error);
    }
    setEditView(false);
  };
  const AllowedRoles = [
    "System Administrator",
    "Administrator",
    "Inspire Us-Core",
  ];

  const isEditEnabled = (roles) => {
    let editEnabled = false;
    if (roles && roles.length > 0) {
      roles.forEach((role) => {
        if (R.includes(role, AllowedRoles)) {
          editEnabled = true;
        }
      });
    }
    return editEnabled;
  };

  const getTierPlan = async (id) => {
    const response = await getDetailsById("/tier-plan", id);
    dispatch(
      updatePageListItems({
        page: PAGES.unitPlan,
        itemObject: response,
      })
    );
  };

  const onCancel = () => {
    setEditView(false);
  };

  const onAddUnit = () => {
    setUnitPlans(R.append(TierUnitPlanFormInitialState, unitPlans));
  };

  const onRemoveUnit = (id) => {
    const updatedUnitPlans = R.filter((unit) => unit.id !== id)(unitPlans);
    setUnitPlans(updatedUnitPlans);
  };

  const onUnitChange = (tierIndex, unitIndex, updatedData) => {
    setUnitPlans(R.assocPath([unitIndex], updatedData, unitPlans));
  };

  const refreshConsent = async (id) => {
    try {
      await getDetailsById("/tier-plan/refresh-consents", id);
      await getTierPlan(id);
    } catch (error) {
      console.log("test", error);
    }
    setEditView(false);
  };

  useEffect(() => {
    setUnitPlans(R.propOr([], "unitPlans", data));
  }, [data]);

  const annualPeriodName = geAnnualPeriod(
    R.pathOr("", ["curriculumContext", "annualPeriod"], data)
  );
  const [expanded, setExpanded] = React.useState(false);
  const [openedUnitPlan, setOpenedUnitPlan] = React.useState("");

  const handleChange = (panel) => (event, isExpanded) => {
    isExpanded ? setOpenedUnitPlan(data.id) : setOpenedUnitPlan(null);
    setExpanded(isExpanded ? panel : false);
  };
  React.useEffect(() => {
    async function fetchData() {
      // You can await here

      const response = await getDetailsById("/tier-plan", openedUnitPlan);
      // const response = await readUnitPlan(openedUnitPlan);
      dispatch(
        updatePageListItems({
          page: PAGES.unitPlan,
          itemObject: response,
        })
      );
      // ...
    }
    if (openedUnitPlan) fetchData();
  }, [openedUnitPlan]);

  return (
    <Grid item xs={12}>
      <div style={{ marginBottom: "1rem" }}>
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreOutlined className={classes.topIcon} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className={classes.observationRegistryHeader}
          >
            <Grid container style={{ margin: "7px" }}>
              <Grid item xs={9} md={10}>
                <Typography variant="h6">
                  {R.propOr("", "name", data)}
                </Typography>
              </Grid>
              <Grid item xs={3} md={2}>
                <Chip
                  className={classes.chip1}
                  size="small"
                  label={annualPeriodName}
                />
              </Grid>

              <Grid container spacing={1} xs={12} style={{ marginTop: "1rem" }}>
                <Grid item xs={5} md={3}>
                  <Typography>
                    Class:{" "}
                    {R.pathOr("-", ["curriculumContext", "gradeName"], data)}
                  </Typography>
                </Grid>
                <Grid item xs={7} md={3}>
                  <Typography>
                    Subject:{" "}
                    {R.pathOr("-", ["curriculumContext", "subjectName"], data)}
                  </Typography>
                </Grid>

                <Grid item xs={5} md={2}>
                  <Typography>
                    Start:{" "}
                    {moment(R.pathOr("-", ["start"], data)).format(
                      GLOBAL_DATE_FORMAT
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={7} md={2}>
                  <Typography>
                    End:{" "}
                    {moment(R.pathOr("-", ["end"], data)).format(
                      GLOBAL_DATE_FORMAT
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={2}>
                  <Typography variant="subtitle1">
                    Status: {R.pathOr("-", ["status"], data)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails className={classes.accordionDetails}>
            <Box
              sx={{
                paddingLeft: "1rem",
                paddingRight: "1rem",
              }}
            >
              <Box sx={{ textAlign: "right" }}>
                <Grid
                  container
                  spacing={1}
                  direction="row"
                  justifyContent="flex-end"
                >
                  <Grid item>
                    {!isEditView &&
                      R.propOr(false, "UPDATE", privileges.unitplan) &&
                      isEditEnabled(userRoles) && (
                        <Button
                          color="primary"
                          variant="outlined"
                          type="button"
                          onClick={() => refreshConsent(data?.id)}
                        >
                          Refresh Consents
                        </Button>
                      )}
                  </Grid>
                  <Grid item>
                    {!isEditView &&
                      R.propOr(false, "UPDATE", privileges.unitplan) && (
                        <Button
                          color="primary"
                          type="button"
                          variant="contained"
                          onClick={() => setEditView(true)}
                        >
                          Edit
                        </Button>
                      )}
                  </Grid>
                </Grid>
              </Box>
              {isEditView ? (
                <Box>
                  {unitPlans.length === 0 && (
                    <Grid container>
                      <Grid item xs={11} className={classes.tierTitle}>
                        <Typography variant="subtitle2">Units</Typography>
                      </Grid>
                      <Grid item xs={1} className={classes.addIconContainer}>
                        {R.propOr(false, "CREATE", privileges.unitplan) && (
                          <IconButton color="primary" onClick={onAddUnit}>
                            <AddCircleOutline className="addIcon" />
                          </IconButton>
                        )}
                      </Grid>
                    </Grid>
                  )}
                  {mapIndexed((unit, unitIndex) => {
                    if (unit.deleted) {
                      return null;
                    }
                    const isUnitReadOnly = isEditEnabled(userRoles)
                      ? false
                      : R.propOr("", "status", unit) === "COMPLETED";
                    const isUnitRemovable =
                      R.propOr("", "status", unit) === "COMPLETED";
                    return (
                      <Grid container spacing={1}>
                        <Grid item xs={11}>
                          <TierUnitPlanForm
                            data={unit}
                            tierIndex={index}
                            unitIndex={unitIndex}
                            onUnitChange={onUnitChange}
                            formType={FORM_TYPES.UNIT_PLAN_FORM}
                            isReadOnly={isUnitReadOnly}
                            page={PAGES.unitPlan}
                          />
                        </Grid>
                        <Grid item xs={1}>
                          {unitIndex === unitPlans.length - 1 ? (
                            <>
                              {R.propOr(
                                false,
                                "CREATE",
                                privileges.unitplan
                              ) && (
                                <IconButton color="primary" onClick={onAddUnit}>
                                  <AddCircleOutline className="addIcon" />
                                </IconButton>
                              )}
                              {R.propOr(false, "DELETE", privileges.unitplan) &&
                                !isUnitRemovable && (
                                  <IconButton
                                    color="primary"
                                    onClick={() => onRemoveUnit(unit.id)}
                                  >
                                    <RemoveCircleOutline className="addIcon" />
                                  </IconButton>
                                )}
                            </>
                          ) : R.propOr(false, "DELETE", privileges.unitplan) ? (
                            !isUnitRemovable && (
                              <IconButton
                                color="primary"
                                onClick={() => onRemoveUnit(unit.id)}
                              >
                                <RemoveCircleOutline className="addIcon" />
                              </IconButton>
                            )
                          ) : null}
                        </Grid>
                      </Grid>
                    );
                  })(unitPlans)}
                  <Button
                    className={classes.saveBtn}
                    color="primary"
                    type="button"
                    variant="contained"
                    onClick={onTierSave}
                  >
                    Save
                  </Button>
                  <Button
                    color="primary"
                    type="button"
                    variant="outlined"
                    onClick={onCancel}
                  >
                    Cancel
                  </Button>
                </Box>
              ) : (
                <Box>
                  {mapIndexed((unit) => {
                    const isUnitReadOnly = isEditEnabled(userRoles)
                      ? false
                      : R.propOr("", "status", unit) === "COMPLETED";
                    return (
                      <Box>
                        <Accordion className={classes.displayobservation}>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Grid item xs={12}>
                              <Grid container spacing={1}>
                                <Grid item xs={12}>
                                  <Typography variant="h6" color="primary">
                                    {R.propOr("", "name", unit)}
                                  </Typography>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                  <Typography variant="subtitle2">
                                    Created By:{" "}
                                    {R.pathOr("-", ["createdBy"], unit)}
                                  </Typography>
                                </Grid>
                                <Grid item xs={6} md={2}>
                                  <Typography variant="subtitle2">
                                    Start:{" "}
                                    {moment(
                                      R.pathOr("-", ["start"], unit)
                                    ).format(GLOBAL_DATE_FORMAT)}
                                  </Typography>
                                </Grid>
                                <Grid item xs={6} md={2}>
                                  <Typography variant="subtitle2">
                                    End:{" "}
                                    {moment(
                                      R.pathOr("-", ["end"], unit)
                                    ).format(GLOBAL_DATE_FORMAT)}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} md={2}>
                                  <Typography variant="subtitle2">
                                    Status: {R.pathOr("-", ["status"], unit)}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          </AccordionSummary>
                          <Divider className={classes.divider} />
                          <AccordionDetails>
                            <Grid container xs={12}>
                              <Grid
                                item
                                xs={12}
                                style={{
                                  alignSelf: "center",
                                  textAlign: "end",
                                  marginBottom: "1rem",
                                }}
                              >
                                {(R.propOr(
                                  true,
                                  "UPDATE",
                                  privileges.unitplanform
                                ) ||
                                  R.propOr(
                                    true,
                                    "CREATE",
                                    privileges.unitplanform
                                  )) &&
                                  !isUnitReadOnly && (
                                    <IconButton
                                      color="primary"
                                      className={classes.editIconBtn}
                                      onClick={() => onCardEditClick(unit.id)}
                                    >
                                      <EditIcon className={classes.editIcon} />
                                    </IconButton>
                                  )}
                                {R.propOr(
                                  false,
                                  "READ",
                                  privileges.unitplanform
                                ) && (
                                  <IconButton
                                    color="primary"
                                    className={classes.editIconBtn}
                                    onClick={() => onCardViewClick(unit.id)}
                                  >
                                    <Visibility className={classes.editIcon} />
                                  </IconButton>
                                )}
                              </Grid>

                              <Grid item xs={12}>
                                <DisplatReadOnlyTopics
                                  topics={R.propOr([], "topics", unit)}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <DisplaySubUnits
                                  isWhiteIcon={false}
                                  subUnits={unit.subUnits}
                                />
                              </Grid>
                            </Grid>
                          </AccordionDetails>
                        </Accordion>
                        <Grid
                          key={unit.id}
                          container
                          spacing={1}
                          className={classes.unitDetails}
                        >
                          {/* <Grid item xs={12}>
                            <Grid container spacing={1}>
                              <Grid item xs={10}>
                                <Typography variant="h6" color="primary">
                                  {R.propOr("", "name", unit)}
                                </Typography>
                              </Grid>
                              <Grid item xs={2} style= {{ alignSelf: "center", textAlign: "end",}}>
                            {(R.propOr(
                              true,
                              "UPDATE",
                              privileges.unitplanform
                            ) ||
                              R.propOr(
                                true,
                                "CREATE",
                                privileges.unitplanform
                              )) &&
                              !isUnitReadOnly && (
                                <IconButton
                                  color="primary"
                                  className={classes.editIconBtn}
                                  onClick={() => onCardEditClick(unit.id)}
                                >
                                  <EditIcon className={classes.editIcon} />
                                </IconButton>
                              )}
                              {R.propOr(
                              false,
                              "READ",
                              privileges.unitplanform
                            ) && (
                                <IconButton
                                  color="primary"
                                  className={classes.editIconBtn}
                                  onClick={() => onCardViewClick(unit.id)}
                                >
                                  <Visibility className={classes.editIcon} />
                                </IconButton>
                              )}
                          </Grid>
                              <Grid item xs={12} md={6}>
                                <Typography variant="subtitle2">
                                  Created By: {" "}
                                  {R.pathOr("-", ["createdBy"], unit)}
                                </Typography>
                              </Grid>
                              <Grid item xs={6} md={2}>
                                <Typography variant="subtitle2">
                                  Start: {" "}
                                  {moment(
                                    R.pathOr("-", ["start"], unit)
                                  ).format(GLOBAL_DATE_FORMAT)}
                                </Typography>
                              </Grid>
                              <Grid item xs={6} md={2}>
                                <Typography variant="subtitle2" >
                                  End: {" "}
                                  {moment(R.pathOr("-", ["end"], unit)).format(
                                    GLOBAL_DATE_FORMAT
                                  )}
                                </Typography>
                              </Grid>
                              <Grid item xs={12} md={2}>
                                <Typography variant="subtitle2">
                                  Status: {" "}
                                  {R.pathOr("-", ["status"], unit)}
                                </Typography>
                              </Grid>
                              
                            </Grid>
                          </Grid> */}
                        </Grid>
                      </Box>
                    );
                  })(unitPlans)}
                </Box>
              )}
            </Box>
          </AccordionDetails>
        </Accordion>
      </div>
    </Grid>
  );
};

export default UnitPlanListView;
