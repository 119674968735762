import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { FORM_TYPES } from "common/utils/formUtility";
import { getDetailsById, onAddValue } from "components/listOfValues/utils";
import * as R from "ramda";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { PAGES } from "state/slices/pageSlice";
import StaffForm from "./StaffForm";

const useStyles = makeStyles((theme) => ({
  cardWrapper: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  annualPlanCard: {
    marginBottom: "30px",
  },
  paginationContainer: {
    display: "flex",
    justifyContent: "end",
  },
  heading: {
    fontWeight: 800,
    fontSize: "18px",
    color: "#263657",
  },
  divider: {
    margin: "0 24px 24px 24px",
  },
}));

function StaffModal(props) {
  const { userRoles, setModalState, modalState, getStaffList } = props;
  const classes = useStyles();
  const theme = useTheme();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down("sm"));

  const [state, setState] = useState({});
  const isEditForm = modalState.isEdit || false;

  useEffect(() => {
    const { data } = modalState;
    if (isEditForm && data?.id) {
      getPlanDetails(data.id);
    }
  }, [isEditForm]);

  const getPlanDetails = async (id) => {
    const response = await getDetailsById("/users", id);
    const responseData = {
      ...response,
    };
    setState(responseData || null);
  };

  const handleClose = () => {
    setModalState((prev) => ({
      isOpen: false,
      data: null,
      isEdit: false,
    }));
  };

  const handleChange = (path, value) => {
    const keys = path.split(".");
    setState((prev) => {
      const newState = JSON.parse(JSON.stringify(prev));

      let temp = newState;
      for (let i = 0; i < keys.length - 1; i++) {
        if (!temp[keys[i]]) temp[keys[i]] = {};
        else temp[keys[i]] = { ...temp[keys[i]] }; // Else, create a shallow copy

        temp = temp[keys[i]];
      }
      temp[keys[keys.length - 1]] = value;

      return newState;
    });
  };

  const staffFormData = useSelector(
    (state) => state.globalForm[FORM_TYPES.TEACHER_FORM]
  );

  const AllowedRoles = ["System Administrator", "Administrator"];

  const isEditEnabled = (roles) => {
    let editEnabled = false;
    if (roles && roles.length > 0) {
      roles.forEach((role) => {
        if (R.includes(role, AllowedRoles)) {
          editEnabled = true;
        }
      });
    }
    return editEnabled;
  };

  const isReadOnly = isEditEnabled(userRoles)
    ? false
    : R.propOr("", "status", staffFormData) === "COMPLETED";

  const handleSubmit = async () => {
    const response = await onAddValue(state, PAGES.teachers);
    setModalState({
      isOpen: false,
      isEdit: false,
      data: null,
    });
    getStaffList({ pageSize: 10, currentPage: 0 });
  };

  return (
    <Dialog
      maxWidth="md"
      fullWidth
      fullScreen={isSmallDevice}
      open={modalState?.isOpen}
      onClose={() =>
        setModalState((prev) => ({ ...prev, isOpen: false, isEdit: false }))
      }
      ariaLabeledBy="form-dialog-title"
    >
      <Grid container>
        <Grid item xs={10} md={11}>
          <DialogTitle id="form-dialog-title">
            <Typography variant="h6" className={classes.heading}>{`${
              isEditForm ? "Edit" : "Add"
            } Staff`}</Typography>
          </DialogTitle>
        </Grid>
        <Grid item xs={1} className={classes.modalCloseBtn}>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </Grid>
      </Grid>
      <Divider className={classes.divider} />
      <DialogContent style={{ padding: "8px 25px" }}>
        <Box>
          <StaffForm
            formType={FORM_TYPES.TEACHER_FORM}
            isEditForm={isEditForm}
            isReadOnly={isReadOnly}
            state={state}
            handleChange={handleChange}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "end",
              pt: 2,
            }}
          >
            <Button
              color="primary"
              // disabled={isReadOnly || isNew}
              variant="contained"
              onClick={handleSubmit}
              data-html2canvas-ignore
            >
              Submit
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default StaffModal;
