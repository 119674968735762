import { createSlice } from "@reduxjs/toolkit";
import {
  innitialStateMap,
  LOVFormState,
  ManageSchoolState,
  ManageClassSectionsState,
  ManageSubjectsState,
  ManageObservationsRegistryState,
  UserTeacherFormState,
  AnnualPlanInitialState,
  UnitPlanFormInitialState,
  LessonPlanPrePrimaryForm,
  LessonPlanForm,
  PageFiltersInitialState,
} from "state/globalForms/initialStates";
import * as R from "ramda";

export const globalFormSlice = createSlice({
  name: "globalForm",
  initialState: {
    LOVForm: LOVFormState,
    ManageSchool: ManageSchoolState,
    ManageClassSection: ManageClassSectionsState,
    ManageSubject: ManageSubjectsState,
    ManageObservations: ManageObservationsRegistryState,
    UserTeacherForm: UserTeacherFormState,
    AnnualPlanForm: AnnualPlanInitialState,
    UnitPlanForm: UnitPlanFormInitialState,
    LessonPlanPrePrimaryForm: LessonPlanPrePrimaryForm,
    LessonPlanForm: LessonPlanForm,
    pageFilters: PageFiltersInitialState,
  },
  reducers: {
    updateForm: (state, action) => {
      const [formType, formData] = R.paths(
        [
          ["payload", "formType"],
          ["payload", "formData"],
        ],
        action
      );
      state[formType] = formData;
    },
    clearForm: (state, action) => {
      const [formType] = R.paths([["payload", "formType"]], action);
      state[formType] = innitialStateMap[formType];
    },
    clearPageFilters: (state) => {
      state.pageFilters = PageFiltersInitialState;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateForm, saveForm, clearForm, clearPageFilters } =
  globalFormSlice.actions;

export default globalFormSlice.reducer;
