import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { PageReadViewRenderer } from "common/utils/rendererList";
import React, { useState } from "react";

const useStyles = makeStyles((theme) => ({
  modalCloseBtn: {
    alignSelf: "center",
    textAlign: "center",
  },
}));

function DownloadPlan({ title, viewData, userRoles, readViewOpen, planName, setReadViewOpen, isPlanSummary=false }) {
  const classes = useStyles();
  const theme = useTheme();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down("sm"));

  const onViewClose = () => {
    setReadViewOpen(false)
  };
  const ReadViewRenderer = PageReadViewRenderer?.[planName];
  return (
    <div>
      <Dialog
        maxWidth="md"
        fullWidth
        fullScreen={isSmallDevice}
        open={readViewOpen}
        onClose={onViewClose}
        aria-labelledby="form-dialog-title"
      >
        <Grid container>
          <Grid item xs={10}>
            <DialogTitle>{title}</DialogTitle>
          </Grid>
          <Grid item xs={2} className={classes.modalCloseBtn}>
            <IconButton onClick={onViewClose}>
              <Close />
            </IconButton>
          </Grid>
        </Grid>
        <DialogContent>
          <ReadViewRenderer data={viewData} userRoles={userRoles} isPlanSummary={isPlanSummary}/>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default DownloadPlan;
