import React from "react";
import { FormControl, Grid, MenuItem, Select } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import * as R from "ramda";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  inputFormControl: {
    marginBottom: "1.5rem",
  },
  flexGrid: {
    "&.MuiGrid-root": {
      display: "flex",
    },
  },
  inputLabel1: {
    top: "-17px",
    left: "-14px",
  },
  inputLabel: {
    top: "-17px",
  },
  placeholderValue: {
    color: "#bbb",
  },
}));
function RoleDropDown({ roleState, setRoleState, multiSelect }) {
  const classes = useStyles();
  const allRoles = useSelector((state) => state.pages.roles.items);
  const handleChange = (e) => {
    setRoleState(e.target.value);
  };
  return (
    <Grid item xs={12} md={12}>
      <FormControl variant="outlined" className={classes.inputFormControl}>
        <Select
          variant="outlined"
          margin="normal"
          value={roleState}
          onChange={handleChange}
          placeholder="Role"
          displayEmpty
          multiple={multiSelect}
          inputProps={{ "aria-label": "Without label" }}
        >
          <MenuItem value="" disabled>
            <span className={classes.placeholderValue}>Select role</span>
          </MenuItem>
          {R.map((role) => {
            return <MenuItem value={role.id}>{role.name}</MenuItem>;
          })(allRoles)}
        </Select>
      </FormControl>
    </Grid>
  );
}

export default RoleDropDown;
