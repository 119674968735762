import React from "react";
import * as R from "ramda";
import { Checkbox, FormControlLabel, FormGroup, Grid } from "@material-ui/core";
import { mapIndexed } from "common/utils/composition";
import { useSelector } from "react-redux";

const GlobalConsents = {
    "lessonPlan": ["Inspire Us-Core", "Administrator", "System Administrator","Inspire Us-Mentors", "Coordinator"],
    "unitPlan": ["Inspire Us-Core", "Administrator", "System Administrator"]
};

const isAllConsentEnabled = (page, roles) => {
    let allEnabled = false;
    roles.forEach(role => {
        if(R.includes(role, GlobalConsents[page])){
            allEnabled = true;
        }
    });
    return allEnabled;
};

const ConsentForm = ({ consentData, setConsentData, isReadOnly = false, page, userRoles , isCompleted }) => {
    const userProfile = useSelector(state => state.userProfile.userProfile);
    const onConsentCheck = (e, index) => {
        const updatedConsentData = R.assocPath([index, "consent"], e.target.checked, consentData);
        setConsentData(updatedConsentData);
    }

    const isAllEnabled = isAllConsentEnabled(page, userRoles);
    return (
        <Grid container spacing={3}>
            {
                mapIndexed((item, index) => {
                    const subjectTeacher = R.prop("subjectTeacher", item);
                    const isDisabled = isReadOnly ? true : (isAllEnabled ? false  : (isCompleted ? true :!(item.subjectTeacher.email === userProfile.email)))
                    return (
                        <Grid item xs={6} md={4}>
                            <FormGroup>
                                <FormControlLabel
                                    control={<Checkbox
                                        checked={item.consent}
                                        onChange={(e) => onConsentCheck(e, index)}
                                        disabled={isDisabled}
                                    />}
                                    label={`${subjectTeacher.firstName} ${subjectTeacher.lastName}`}
                                />
                            </FormGroup>
                        </Grid>
                    )
                })(consentData)
            }
        </Grid>
    )
}

export default ConsentForm;