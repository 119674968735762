import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import clsx from "clsx";
import moment from "moment";
import {
  Container,
  Box,
  Grid,
  Card,
  CardContent,
  CardMedia,
  IconButton,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { clearPageFilters } from "state/slices/globalFormSlice";
import TierRange from "../../constants/TierRange";
import Roles from "@material-ui/icons/Label";
import Values from "@material-ui/icons/ListAlt";
import Students from "@material-ui/icons/PersonAdd";
import SettingsIcon from "@material-ui/icons/Settings";
import GoalReview from "@material-ui/icons/Adjust";
import MyClasses from "@material-ui/icons/MenuBook";
import AcademicPlans from "@material-ui/icons/BorderColor";
import Bookmark from "@material-ui/icons/Bookmark";
import Calendar from "@material-ui/icons/CalendarToday";
import Setup from "@material-ui/icons/AssignmentTurnedIn";
import Visibility from "@material-ui/icons/Visibility";
import Staff from "@material-ui/icons/Group";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import { getAllowedTabs } from "../sideDrawer/util";
import withTabsPrivilege from "common/hocs/withTabsPrivilege";

const useStyles = makeStyles((theme) => ({
  inputFormControl: {
    width: "100%",
    marginBottom: "1rem",
  },
  flexGrid: {
    "&.MuiGrid-root": {
      display: "flex",
    },
  },
  container: {
    height: "100%",
    maxWidth: "100%",
    marginTop: "3rem",
    marginBottom: "1rem",
  },
  tabsBtn: {
    background: "#898eac2e",
    borderRadius: 6,
  },
  dashCards: {
    // backgroundImage: `url(${CardBg})`,
    backgroundSize: "cover",
    backgroundPositionY: "center",
    border: "1px solid #cacaca",
  },
  configCards: {
    height: "150px",
    borderRadius: "10px",
    cursor: "pointer",
    transition: "transform 0.2s",
    "&:hover": {
      transform: "scale(1.05)",
    },
  },
  cardContent: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  cardTitle: {
    textAlign: "center",
  },
  cardIcon: {
    height: "auto",
  },
  disabled: {
    // backgroundImage: `url(${CardBg})`,
    backgroundSize: "cover",
    backgroundPositionY: "center",
    borderRadius: "10px",
    backgroundColor: "#dadada",
    cursor: "not-allowed",
    color: "grey",
    display: "none",
  },
  disabledConfig: {
    height: "150px",
    borderRadius: "10px",
    cursor: "not-allowed",
    backgroundColor: "#a8a8a8",
  },
}));

const configCards = [
  {
    id: "school",
    icon: <SettingsIcon />,
    title: "School Setup",
    width: "40px",
    route: "/schools",
    disabled: false,
  },
  {
    id: "teacher",
    icon: <Staff />,
    title: "Staff",
    width: "50px",
    route: "/users",
    disabled: false,
  },
  {
    id: "",
    icon: <Students />,
    title: "Students",
    width: "40px",
    route: "/dashboard",
    disabled: true,
  },
  {
    id: "role",
    icon: <Roles />,
    title: "Roles",
    width: "45px",
    route: "/system-configurations/roles",
    disabled: false,
  },
  {
    id: "observationregister",
    icon: <Visibility />,
    title: "Observations",
    width: "50px",
    route: "/master-data/observations",
    disabled: false,
  },
  {
    id: "lov",
    icon: <Values />,
    title: "List of Values",
    width: "40px",
    route: "/system-configurations/lov",
    disabled: false,
  },
];

const dashCards = [
  {
    id: "class",
    icon: <MyClasses />,
    title: "My Classes",
    width: "50px",
    route: "/master-data/classes",
    disabled: true,
  },
  {
    id: "",
    icon: <Calendar />,
    title: "School Calendar",
    width: "50px",
    route: "/dashboard",
    disabled: true,
  },
  {
    id: "annualplan",
    icon: <AcademicPlans />,
    title: "Academic Plans",
    width: "50px",
    route: "/annual-plans",
    disabled: false,
  },
  {
    id: "goalconfiguration",
    icon: <AccountTreeIcon />,
    title: "Goal Configuration",
    width: "50px",
    route: "/goals/reviewers/configuration",
    disabled: false,
  },
  {
    id: "goalsettingform",
    icon: <Setup />,
    title: "Goals Setup",
    width: "50px",
    route: "/goals/set",
    disabled: false,
  },
  {
    id: "goalsettingreview",
    icon: <GoalReview />,
    title: "Goals Review",
    width: "50px",
    route: "/goals/set/review",
    disabled: false,
  },
  {
    id: "",
    icon: <Bookmark />,
    title: "View Bookmarks",
    width: "50px",
    route: "/dashboard",
    disabled: true,
  },
];

const Dashboard = ({ tabAccess }) => {
  const findCurrentTierValue = () => {
    const currentDateString = moment().format("DD-MM-YYYY");
    const currentDate = moment(currentDateString, "DD-MM-YYYY");
    var currentTier = 0;
    Object.values(TierRange).forEach((ay) => {
      Object.keys(ay).forEach((tier) => {
        if (
          currentDate.isBetween(
            moment(ay[tier].startDate, "DD-MM-YYYY"),
            moment(ay[tier].endDate, "DD-MM-YYYY"),
            null,
            "[]"
          )
        ) {
          currentTier = tier;
        }
      });
    });
    return parseInt(currentTier);
  };

  const [value, setValue] = React.useState(findCurrentTierValue());
  const classes = useStyles();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearPageFilters());
  }, [dispatch]);

  useEffect(() => {
    const tier = findCurrentTierValue();
    setValue(tier);
  }, []);

  const combinedClasses = clsx(classes.configCards, classes.dashCards);

  return (
    <Container className={classes.container}>
      <Box style={{ marginBottom: "5rem" }}>
        <h2>Dashboard Overview</h2>
        <Grid container spacing={4}>
          {getAllowedTabs(tabAccess, dashCards).map((card) => (
            <Grid
              item
              key={card.id}
              xs={12}
              sm={4}
              md={3}
              style={card.disabled ? { display: "none" } : { display: "block" }}
            >
              <Link to={card.route}>
                <Card
                  className={card.disabled ? classes.disabled : combinedClasses}
                  style={{ height: "95px", display: "flex" }}
                  elevation="none"
                  disabled={card.disabled}
                >
                  <CardMedia
                    style={{
                      backgroundColor: "#E3D5CA",
                      padding: "0 0.8rem",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <IconButton
                      aria-label="card icons"
                      style={{ color: "#263657" }}
                    >
                      {card.icon}
                    </IconButton>
                  </CardMedia>

                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    <CardContent style={{ flex: "1 0 auto" }}>
                      <h3 style={{ fontWeight: "normal" }}>{card.title}</h3>
                    </CardContent>
                  </Box>
                </Card>
              </Link>
            </Grid>
          ))}
        </Grid>
      </Box>

      <Box>
        {configCards.map.length !== 0 && <h2>School Wide Configuration</h2>}
        <Box>
          <Grid container spacing={4}>
            {getAllowedTabs(tabAccess, configCards).map((card) => (
              <Grid
                item
                key={card.id}
                xs={12}
                sm={4}
                md={3}
                style={
                  card.disabled ? { display: "none" } : { display: "block" }
                }
              >
                <Link to={card.route}>
                  <Card
                    className={
                      card.disabled ? classes.disabled : combinedClasses
                    }
                    style={{
                      height: "95px",
                      display: "flex",
                      borderLeft: "5px solid #263657",
                    }}
                    elevation="none"
                    disabled={card.disabled}
                  >
                    <CardMedia
                      style={{
                        padding: "0 0.8rem",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <IconButton
                        aria-label="card icons"
                        style={{
                          color: "#263657",
                          backgroundColor: "#E3D5CA",
                          borderRadius: "4px",
                        }}
                      >
                        {card.icon}
                      </IconButton>
                    </CardMedia>
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                      }}
                    >
                      <CardContent style={{ flex: "1 0 auto" }}>
                        <h3 style={{ fontWeight: "normal" }}>{card.title}</h3>
                      </CardContent>
                    </Box>
                  </Card>
                </Link>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </Container>
  );
};

export default withTabsPrivilege(Dashboard);
