import { Box, List, Typography } from "@material-ui/core";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { makeStyles } from "@material-ui/styles";
import { mapIndexed } from "common/utils/composition";
import React from "react";
import * as R from "ramda";
import { CHECK_ICON } from "asset/images";

const useStyle = makeStyles((theme) => ({
  wrapper: {
    background: "#F6F8FF",
    padding: "18px 15px",
    borderRadius: "4px",
  },
  title: {
    "&.MuiTypography-root": {
      marginTop: "1rem",
      fontWeight: "600",
      textAlign: "left",
    },
  },
  unitList: {
    "&.MuiList-root": {
      maxHeight: "none",
    },
  },
  fileIcon: {
    marginRight: "10px",
  },
  listItem: {
    display: "flex",
    fontWeight: 500,
    fontSize: "12px",
    width: "100%",
    alignItems: "center",
  },
  cancelIcon: {
    margin: "auto",
    marginRight: "10px",
    cursor: "pointer",
  },
  highlightOffIcon: {
    color: "#898EAC",
  },
}));
const DisplaySubTopics = ({ subTopics, onDelete = null, disabled = false }) => {
  const classes = useStyle();
  if (R.isEmpty(subTopics)) {
    return null;
  }
  return (
    <Box
      sx={{ width: "100%", overflowWrap: "break-word" }}
      className={classes.wrapper}
    >
      <Typography variant="subtitle1">Sub Topics</Typography>
      <List className={classes.unitList}>
        {mapIndexed((item, index) => {
          return (
            <div className={classes.listItem}>
              <img
                className={classes.fileIcon}
                src={CHECK_ICON}
                alt="Check Icon"
              />
              <div>{item.name}</div>
              <div className={classes.cancelIcon}>
                <HighlightOffIcon
                  fontSize={"small"}
                  onClick={() => onDelete(index)}
                  className={classes.highlightOffIcon}
                />
              </div>
            </div>
          );
        })(subTopics || [])}
      </List>
    </Box>
  );
};

export default DisplaySubTopics;
