import React from "react";
import {
  makeStyles,
  Typography,
  Box,
  Card,
  CardContent,
  Grid,
} from "@material-ui/core";
import { FILE_ICON } from "asset/images";
import * as R from "ramda";
import MoreVerticalDot from "components/academicPlanDetails/MoreVerticalDot";
import EditIcon from "@mui/icons-material/Edit";
import ListAltIcon from "@mui/icons-material/ListAlt";
import { CHALKBOARD_ICON, STICKY_NOTE_ICON } from "asset/images";
import DeleteIcon from "@mui/icons-material/Delete";

const useStyles = makeStyles((theme) => ({
  cardBody: {
    width: "100%",
    background: "#FFFFFF",
    borderRadius: "14px !important",
  },
  cardContent: {
    padding: "26px 18px !important",
  },
  cardTitle: {
    fontStyle: "normal",
    fontWeight: "800",
    fontSize: "18px",
    lineHeight: "22px",
    color: "#263657",
    display: "flex",
    alignItems: "center",
  },
  fileIcon: {
    marginRight: "10px",
  },
  cardTitleWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  cardBodyContent: {
    marginTop: "18px",
  },
  bodyText: {
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "12px",
    lineHeight: "14px",
    color: "#000000",
  },
  editIcon: {
    marginRight: "10px",
  },
}));

const SectionCard = ({
  data,
  privileges = {},
  hideModification,
  userRoles,
  modalState,
  setModalState,
  state,
  getClassesDetails,
}) => {
  const classes = useStyles();
  const AllowedRoles = ["Administrator", "Inspire Us-Core"];

  const isEditEnabled = (roles) => {
    let editEnabled = false;
    roles.forEach((role) => {
      if (AllowedRoles.includes(role)) {
        editEnabled = true;
      }
    });
    return editEnabled;
  };

  const isEditable = isEditEnabled(userRoles);

  return (
    <>
      <Grid item className={classes.annualPlanCard} md="6" xs="12">
        {
          <Box
            sx={{ minWidth: 275 }}
            // onClick={() => handleRedirect(data?.id)}
            style={{ cursor: "pointer" }}
          >
            <Card variant="outlined" className={classes.cardBody}>
              <CardContent className={classes.cardContent}>
                <div className={classes.cardTitleWrapper}>
                  <Typography className={classes.cardTitle} variant="div">
                    <img
                      className={classes.fileIcon}
                      src={FILE_ICON}
                      alt="File Icon"
                    />
                    <span>{data?.sectionName}</span>
                  </Typography>
                  {privileges?.UPDATE && (
                    <div className={classes.verticalDotsButton}>
                      <MoreVerticalDot
                        options={[
                          {
                            icon: (
                              <EditIcon
                                fontSize="small"
                                className={classes.editIcon}
                              />
                            ),
                            label: "Edit Class Section",
                            value: "Edit Class Section",
                            // isDisabled:
                            //   data?.status?.toUpperCase() === "COMPLETED"
                            //     ? !isEditable
                            //     : !privileges?.UPDATE,
                          },
                          {
                            icon: (
                              <ListAltIcon
                                fontSize="small"
                                className={classes.editIcon}
                              />
                            ),
                            label: "Manage Teachers",
                            value: "Manage Teachers",
                            // isDisabled:
                            //   data?.status?.toUpperCase() === "COMPLETED"
                            //     ? !isEditable
                            //     : !privileges?.UPDATE,
                          },
                          {
                            icon: (
                              <DeleteIcon
                                fontSize="small"
                                className={classes.editIcon}
                              />
                            ),
                            label: "Delete Class Section",
                            value: "Delete Class Section",
                            // isDisabled:
                            //   data?.status?.toUpperCase() === "COMPLETED"
                            //     ? !isEditable
                            //     : !privileges?.DELETE,
                          },
                        ]}
                        handleClick={(e, value) => {
                          e.stopPropagation();
                          if (value === "Manage Teachers") {
                            setModalState((prev) => ({
                              ...prev,
                              isTeacherModalOpen: true,
                              isEdit: true,
                              data,
                            }));
                          } else {
                            setModalState((prev) => ({
                              ...prev,
                              isClassSectionModalOpen: true,
                              isEdit: true,
                              data,
                            }));
                          }
                        }}
                      />
                    </div>
                  )}
                </div>
                <div className={classes.cardBodyContent}>
                  <img
                    className={classes.fileIcon}
                    src={CHALKBOARD_ICON}
                    alt="Chalkboard Icon"
                  />
                  <Typography
                    className={classes.bodyText}
                    variant="span"
                    marginRight={"34px"}
                  >
                    Class Teacher: {data?.classTeacher?.fullName || "-"}
                  </Typography>
                </div>
                <div>
                  <img
                    className={classes.fileIcon}
                    src={STICKY_NOTE_ICON}
                    alt="Sticky Note Icon"
                  />
                  <Typography className={classes.bodyText} variant="span">
                    Capacity: {data?.capacity || "-"}
                  </Typography>
                </div>
              </CardContent>
            </Card>
          </Box>
        }
      </Grid>
    </>
  );
};

export default SectionCard;
