import {
  Card,
  CardContent,
  Divider,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { CHECK_ICON, FILE_ICON } from "asset/images";
import { GLOBAL_DATE_FORMAT } from "constants/PageConstants";
import moment from "moment";
import React, { useState } from "react";
import StatusBlock from "../StatusBlock";
import MoreVerticalDot from "../MoreVerticalDot";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import LessonPlanModal from "./LessonPlanModal";
import { deletePlanService } from "components/listOfValues/utils";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  cardBody: {
    margin: "1rem",
    background: "#F0F2FE",
    borderRadius: "15px",
    boxShadow: "none",
    marginTop: "50px",
  },
  cardContent: {
    padding: "26px 18px !important",
  },
  cardTitleDiv: {
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  cardTitle: {
    position: "relative",
    fontStyle: "normal",
    fontWeight: "800",
    fontSize: "18px",
    lineHeight: "22px",
    color: "#263657",
    display: "flex",
    alignItems: "center",
    marginBottom: "20px",
    justifyContent: "space-between",
    width: "100%",
  },
  fileIcon: {
    marginRight: "10px",
    alignSelf: "start",
    marginTop: "5px",
  },
  subTitle: {
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "8px",
    lineHeight: "10px",
    color: "#898EAC",
    marginLeft: "25px",
  },
  bodyText: {
    marginTop: "18px",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "12px",
    lineHeight: "14px",
    color: "#000000",
    display: "block",
  },
  bodySubText: {
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "14px",
  },
  topicCard: {
    background: "#fff",
    borderRadius: "4px",
    boxShadow: "none",
    marginTop: "24px",
  },
  topicTitle: {
    fontWeight: 500,
    fontSize: "14px",
    color: "#898EAC",
  },
  subTopic: {
    display: "flex",
  },
  subTopicText: {
    fontWeight: "400",
    fontSize: "12px",
  },
  statusBlock: {
    marginTop: "18px",
  },
  editIcon: {
    marginRight: "10px",
  },
  verticalDotsButton: {
    position: "absolute",
    right: "-15px",
    top: "-8px",
  },
  verticalDots: {
    margin: "0 6px",
  },
}));

function LessonPlanDetailCard({
  data,
  privileges,
  userRoles,
  hideModification,
  getPlanDetails,
}) {
  const history = useHistory()
  const classes = useStyles();
  const AllowedRoles = ["Administrator", "Inspire Us-Core", "Coordinator"];
  const [modalState, setModalState] = useState({
    isOpen: false,
    data: null,
    isEdit: false,
  });

  const isEditEnabled = (roles) => {
    let editEnabled = false;
    roles.forEach((role) => {
      if (AllowedRoles.includes(role)) {
        editEnabled = true;
      }
    });
    return editEnabled;
  };

  const isEditable = isEditEnabled(userRoles);

  const handleDeletePlan = async(id) => {
    const res = await deletePlanService('lesson-plan', id)
    if(res) {
      history.push(`/unit-plan/${data.unitPlanId}`)
    }
  }

  return (
    <>
      <Card className={classes.cardBody}>
        <CardContent className={classes.cardContent}>
          <Typography className={classes.cardTitle} variant="div">
            <div className={classes.cardTitleDiv}>
              <img
                className={classes.fileIcon}
                src={FILE_ICON}
                alt="File Icon"
              />
              <span>{data?.name || ""}</span>
            </div>
            {privileges?.UPDATE && !hideModification && data && (
              <div className={classes.verticalDotsButton}>
                <MoreVerticalDot
                  options={[
                    {
                      icon: (
                        <EditIcon
                          fontSize="small"
                          className={classes.editIcon}
                        />
                      ),
                      label: "Edit Lesson Plan",
                      value: "Edit Lesson Plan",
                      isDisabled:
                        data?.status?.toUpperCase() === "COMPLETED"
                          ? !isEditable
                          : !privileges?.UPDATE,
                    },
                    {
                      icon: (
                        <DeleteIcon
                          fontSize="small"
                          className={classes.editIcon}
                        />
                      ),
                      label: "Delete Lesson Plan",
                      value: "Delete Lesson Plan",
                      isDisabled:
                        data?.status?.toUpperCase() === "COMPLETED"
                          ? true
                          : !privileges?.DELETE,
                    },
                  ]}
                  handleClick={(e, value) => {
                    e.stopPropagation();
                    if (value === "Delete Lesson Plan") {
                      data?.id && handleDeletePlan(data.id)
                    } else {
                      setModalState((prev) => ({
                        ...prev,
                        isOpen: true,
                        isEdit: true,
                        data,
                      }));
                    }
                  }}
                  background={"#fff"}
                />
              </div>
            )}
          </Typography>
          <Divider />
          <Typography className={classes.bodyText} variant="div">
            Subject:{" "}
            <Typography className={classes.bodySubText} variant="div">
              {data?.curriculumContext?.subject?.subjectName || "-"}
            </Typography>
          </Typography>
          <Typography className={classes.bodyText} variant="div">
            Class:{" "}
            <Typography className={classes.bodySubText} variant="div">
              {data?.curriculumContext?.gradeName || "-"}
            </Typography>
          </Typography>
          <Typography className={classes.bodyText} variant="div">
            Start Date:{" "}
            <Typography className={classes.bodySubText} variant="div">
              {data?.start
                ? moment(data.start).format(GLOBAL_DATE_FORMAT)
                : "-"}
            </Typography>
          </Typography>
          <Typography className={classes.bodyText} variant="span">
            End Date:{" "}
            <Typography className={classes.bodySubText} variant="div">
              {data?.end ? moment(data.end).format(GLOBAL_DATE_FORMAT) : "-"}
            </Typography>
          </Typography>
          <Typography className={classes.bodyText} variant="div">
            Created by:
          </Typography>
          <Typography className={classes.bodySubText} variant="div">
            {data?.createdBy || "-"}
          </Typography>
          <SubUnitSection subUnits={data?.subUnits || []} />
          <div className={classes.statusBlock}>
            <StatusBlock status={data?.status || ""} isDetailCard={true} />
          </div>
        </CardContent>
      </Card>
      {modalState.isOpen && (
        <LessonPlanModal
          userRoles={userRoles}
          modalState={modalState}
          setModalState={setModalState}
          getPlanList={getPlanDetails}
          subUnits={data?.subUnits || []}
          unitData={data}
        />
      )}
    </>
  );
}

export default LessonPlanDetailCard;

const SubUnitSection = ({ subUnits }) => {
  const classes = useStyles();
  return (
    <Card className={classes.topicCard}>
      <CardContent className={classes.topicContent}>
        <Typography className={classes.topicTitle} variant="div">
          Sub Units
        </Typography>
        {subUnits?.length
          ? subUnits?.map((subTopic, index, array) => (
              <div
                key={subTopic?.id}
                className={classes.subTopic}
                style={
                  array.length - 1 === index
                    ? {
                        marginBottom: "25px",
                      }
                    : {}
                }
              >
                <img
                  className={classes.fileIcon}
                  src={CHECK_ICON}
                  alt="Check Icon"
                />
                <Typography
                  className={classes.subTopicText}
                  variant="span"
                  marginRight={"34px"}
                  fontSize={"10px"}
                  fontWeight="500"
                >
                  {subTopic?.name || "-"}
                </Typography>
              </div>
            ))
          : ""}
      </CardContent>
    </Card>
  );
};
