import * as React from "react";
import { useLocation, useHistory } from "react-router-dom";
import {
  Button,
  Grid,
  makeStyles,
  Typography,
  IconButton,
  FormControl,
  OutlinedInput,
  InputAdornment,
} from "@material-ui/core";
import Alert from "react-s-alert";
import * as R from "ramda";
import { useFormik } from "formik";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import * as Yup from "yup";
import { changeUserPassword, getPageList } from "components/listOfValues/utils";
import { useDispatch, useSelector } from "react-redux";
import {
  onChangePasswordSuccess,
  onLogout,
  setPasswordChangeRequired,
  setUserProfile,
} from "state/slices/userLoginFormSlice";
import { profileDetails } from "util/APIUtils";
import { onLoginGetProfileSettings } from "util/LoginUtils";
import { PAGES, setPageListItems } from "state/slices/pageSlice";

const useStyle = makeStyles((theme) => ({
  formFields: {
    marginBottom: "1rem",
  },
  Typography: {
    lineHeight: "2rem",
  },
}));

const ChangePassword = ({ onPasswordChangeSuccess }) => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const paramEmail = query.get("email");
  const paramToken = query.get("passwordResetTokenId");
  const history = useHistory();
  const email = useSelector((state) => state.userProfile.currentUser);
  const classes = useStyle();
  const ValidationSchema = Yup.object().shape({
    currentPassword: !paramToken && Yup.string().required("This field is required"),
    newPassword: Yup.string().required("This field is required"),
    confirmPassword: Yup.string()
      .required("This field is required")
      .when("newPassword", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("newPassword")],
          "Both password need to be the same"
        ),
      }),
  });
  const formik = useFormik({
    initialValues: {
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
      showOldPassword: false,
      showNewPassword: false,
      showConfirmPassword: false,
    },
    onSubmit: (values) => {
      const payload = {
        email: paramEmail ? paramEmail : email,
        currentPassword: values.currentPassword,
        newPassword: values.newPassword,
        confirmPassword: values.confirmPassword,
        passwordResetTokenId: paramToken,
      };
      onPasswordChange(payload);
    },
    validateOnChange: false,
    validationSchema: ValidationSchema,
  });
  const handleClickShowPassword = (passkey) => {
    const updateFormik = R.assocPath(
      ["values", passkey],
      !formik.values[passkey],
      formik
    );
    formik.setValues(updateFormik.values);
  };

  const getRoles = async () => {
    const response = await getPageList({ searchKey: "", page: PAGES.roles });
    dispatch(
      setPageListItems({
        page: PAGES.roles,
        itemList: response.content,
      })
    );
  };

  const onPasswordChange = async (payload) => {
    changeUserPassword(payload)
      .then(async (response) => {
        if (paramToken && paramToken.length) {
          Alert.success("Password set successfully!");
          history.push("/login");
        } else {
          dispatch(onChangePasswordSuccess({ ...response }));
          const profile = await profileDetails();
          await getRoles();
          dispatch(setUserProfile({ userProfile: profile }));

          dispatch(setPasswordChangeRequired(false));
          Alert.success("Password changed successfully!");
          await onLoginGetProfileSettings(dispatch);
          history.push("/dashboard");
        }
      })
      .catch((error) => {
        Alert.error(
          (error && error.message) ||
          "Oops! Something went wrong. Please try again!"
        );
      });
  };

  return (
    <form onSubmit={formik.handleSubmit} style={{ padding: "0rem 0.5rem" }}>
      <Grid container>
        {!paramToken ? (
          <Grid item xs={7} className={classes.formFields}>
            <Typography variant="subtitle1" className={classes.Typography}>
              Old Password
            </Typography>
            <FormControl>
              <OutlinedInput
                id="outlined-adornment-password"
                type={formik.values.showOldPassword ? "text" : "password"}
                onChange={formik.handleChange}
                value={formik.values.currentPassword}
                name={"currentPassword"}
                placeholder="Enter old password"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => handleClickShowPassword("showOldPassword")}
                      edge="end"
                    >
                      {formik.values.showOldPassword ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            <span class="error" style={{ color: "red" }}>
              {formik.errors.currentPassword}
            </span>
          </Grid>
        ) : null}
        <Grid item xs={7} className={classes.formFields}>
          <Typography variant="subtitle1" className={classes.Typography}>
            New Password
          </Typography>
          <FormControl>
            <OutlinedInput
              id="outlined-adornment-password"
              type={formik.values.showNewPassword ? "text" : "password"}
              onChange={formik.handleChange}
              value={formik.values.newPassword}
              name={"newPassword"}
              placeholder="Enter new password"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => handleClickShowPassword("showNewPassword")}
                    edge="end"
                  >
                    {formik.values.showNewPassword ? (
                      <VisibilityOff />
                    ) : (
                      <Visibility />
                    )}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          <span class="error" style={{ color: "red" }}>
            {formik.errors.newPassword}
          </span>
        </Grid>
        <Grid item xs={7} className={classes.formFields}>
          <Typography variant="subtitle1" className={classes.Typography}>
            Confirm Password
          </Typography>
          <FormControl>
            <OutlinedInput
              id="outlined-adornment-password"
              type={formik.values.showConfirmPassword ? "text" : "password"}
              onChange={formik.handleChange}
              value={formik.values.confirmPassword}
              placeholder="Confirm password"
              name={"confirmPassword"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() =>
                      handleClickShowPassword("showConfirmPassword")
                    }
                    edge="end"
                  >
                    {formik.values.showConfirmPassword ? (
                      <VisibilityOff />
                    ) : (
                      <Visibility />
                    )}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          <span class="error" style={{ color: "red" }}>
            {formik.errors.confirmPassword}
          </span>
        </Grid>
      </Grid>
      <p>
        <strong>Password Requirments :</strong>
      </p>

      <ul className={classes.reqs}>
        <li>
          The password must be at least 8 characters and at most 20 characters
          long.
        </li>
        <li>The password must contain at least one digit.</li>
        <li>The password must contain at least one lower case alphabet.</li>
        <li>The password must contain at least one upper case alphabet.</li>
        <li>The password must contain at least one special character.</li>
        <li>White spaces not allowed in the entire string.</li>
      </ul>
      <Button type="submit" variant="contained" color="primary">
        Submit
      </Button>
    </form>
  );
};
export default ChangePassword;
