import {
  Button,
  FormControl,
  Grid,
  makeStyles,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  container: {
    margin: "0",
    marginBottom: "51px",
    width: '100%'
  },
  paddingNone: {
    padding: "0px !important",
  },
  changeView: {
    padding: "0px !important",
    marginLeft: "30px",
    marginRight: "40px",
    [theme.breakpoints.down("md")]: {
      margin: "1.5rem 1rem",
    },
  },
  addNewPlan: {
    padding: "0px !important",
    marginRight: "-16px",
  },
  addIconButton: {
    margin: 0,
  },
  title: {
    fontStyle: "normal",
    fontWeight: "800",
    fontSize: "24px",
    color: "#263657",
  },
  inputFormControl: {
    height: "36px",
  },
  downloadIcon: {
    marginRight: "10px",
  },
}));

const planUrls = {
  ANNUAL_PLAN: { path: "/annual-plans", title: "Annual Plans" },
  TIER_PLAN: { path: "/tier-plans", title: "Tier Plans" },
  UNIT_PLAN: { path: "/unit-plans", title: "Unit Plans" },
  LESSON_PLAN: { path: "/lesson-plans", title: "Lesson Plans" },
  ANNUAL_PLAN_DETAILS: { path: "/annual-plans", title: "Annual Plans" },
  TIER_PLAN_DETAILS: { path: "/tier-plans", title: "Tier Plans" },
  UNIT_PLAN_DETAILS: { path: "/unit-plans", title: "Unit Plans" },
  LESSON_PLAN_DETAILS: { path: "/lesson-plans", title: "Lesson Plans" },
};

function AcademicPlanHeader({ changeView, privileges, setModalState }) {
  const classes = useStyles();
  const history = useHistory();
  const headerTitle = planUrls[changeView]?.title;

  const onAddClick = () => {
    setModalState((prev) => ({ ...prev, isOpen: true }));
  };
  const onChange = (e) => {
    if (e.target.value) {
      history.push(planUrls[e.target.value].path);
    }
  };

  return (
    <Grid
      container
      spacing={3}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      className={classes.container}
    >
      <Grid item xs={12} md={6} className={classes.paddingNone}>
        <Typography className={classes.title} variant="div">
          <span>{headerTitle}</span>
        </Typography>
      </Grid>
      <Grid
        item
        container
        spacing={3}
        md={6}
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        className={classes.paddingNone}
      >
        <Grid item xs={12} md={4} className={classes.changeView}>
          <FormControl variant="outlined">
            <Select
              variant="outlined"
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={changeView}
              className={classes.inputFormControl}
              onChange={onChange}
              placeholder="Change View"
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
            >
              <MenuItem value={"ANNUAL_PLAN"}>Annual Plan</MenuItem>
              <MenuItem value={"TIER_PLAN"}>Tier Plan</MenuItem>
              <MenuItem value={"UNIT_PLAN"}>Unit Plan</MenuItem>
              <MenuItem value={"LESSON_PLAN"}>Lesson Plan</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        {changeView === "ANNUAL_PLAN" && privileges?.CREATE ? (
          <Grid item xs={12} md={5} className={classes.addNewPlan}>
            <Button
              variant="contained"
              color="primary"
              className={classes.addIconButton}
              onClick={onAddClick}
              startIcon={<AddCircleIcon className="addIcon" />}
              disabled={!privileges?.CREATE}
            >
              Add New Plan
            </Button>
          </Grid>
        ) : (
          ""
        )}
      </Grid>
    </Grid>
  );
}

export default AcademicPlanHeader;
