import React from "react";
import { Route } from "react-router-dom";
import Dashboard from "components/dashboard/Dashboard";
import ChangePassword from "common/components/ChangePassword/ChangePassword";
import ListOfValues from "components/listOfValues/ListOfValues";
import ManageSchoolList from "components/master/ManageSchool/Schools/ManageSchoolList";
import ManagePolicies from "components/master/ManagePolicies/ManagePolicies";
import ManageObservations from "components/master/ManageObservations/ManageObservations";
// import Teachers from "components/Users/Teachers/Teachers";
import ManageSetGoal from "components/goals/SetGoal/ManageSetGoal";
import ManageReviewSetGoal from "components/goals/ReviewGoal/ManageReviewSetGoal";
import ManageAnnualPlan from "components/academicPlan/AnnualPlan/ManageAnnualPlan";
import ManageUnitPlan from "components/academicPlan/UnitPlan/ManageUnitPlan";
import ManageLessonPlan from "components/academicPlan/LessonPlans/ManageLessonPlan";
import Roles from "components/Users/Roles/Roles";
import AcademicPlanList from "components/academicPlanDetails/AcademicPlanList";
import AnnualPlanDetails from "components/academicPlanDetails/AnnualPlan/AnnualPlanDetails";
import TierPlanDetails from "components/academicPlanDetails/TierPlan/TierPlanDetails";
import UnitPlanDetails from "components/academicPlanDetails/UnitPlan/UnitPlanDetails";
import LessonPlanDetails from "components/academicPlanDetails/LessonPlan/LessonPlanDetails";
import ManageSchoolDetails from "components/master/ManageSchool/Classes/ManageClasses";
import ManageClassesDetails from "components/master/ManageSchool/Class/ManageClassesDetails";
import ManageGoalReviewConfigurationList from "components/goals/GoalReviewConfiguration/ManageGoalReviewConfigurationList";
import TeachersDetails from "components/Users/ManageTeachers/StaffDetails";
import ManageRolesDetails from "components/Users/ManageTeachers/ManageRoles";

const MainContent = () => {
  return (
    <>
      <Route path="/dashboard" component={Dashboard} />
      <Route path="/auth/reset-password" component={ChangePassword} />
      <Route path="/system-configurations/lov" component={ListOfValues} />
      <Route path="/master-data/policies" component={ManagePolicies} />
      <Route path="/master-data/observations" component={ManageObservations} />
      {/* <Route path="/users/teachers" component={Teachers} /> */}
      <Route
        exact
        path="/goals/reviewers/configuration"
        component={ManageGoalReviewConfigurationList}
      />
      <Route exact path="/goals/set" component={ManageSetGoal} />
      <Route path="/goals/set/review" component={ManageReviewSetGoal} />
      <Route path="/change-password" component={ChangePassword} />
      <Route path="/academic-plans/annual-plans" component={ManageAnnualPlan} />
      <Route path="/academic-plans/unit-plans" component={ManageUnitPlan} />
      <Route path="/academic-plans/lesson-plans" component={ManageLessonPlan} />
      <Route path="/system-configurations/roles" component={Roles} />

      {/* School Section */}
      <Route exact path="/schools" component={ManageSchoolList} />
      <Route path="/schools/:id" component={ManageSchoolDetails} />
      <Route exact path="/classes/:id" component={ManageClassesDetails} />

      {/* teachers Section */}
      <Route
        exact
        path="/users"
        render={() => <TeachersDetails planName="STAFF" />}
      />
      <Route path="/users/:id" component={ManageRolesDetails} />

      {/* Academic Plan Section */}
      <Route
        path="/annual-plans"
        render={() => <AcademicPlanList planName="ANNUAL_PLAN" />}
      />
      <Route
        path="/tier-plans"
        render={() => <AcademicPlanList planName="TIER_PLAN" />}
      />
      <Route
        path="/unit-plans"
        render={() => <AcademicPlanList planName="UNIT_PLAN" />}
      />
      <Route
        path="/lesson-plans"
        render={() => <AcademicPlanList planName="LESSON_PLAN" />}
      />
      <Route path="/annual-plan/:id" component={AnnualPlanDetails} />
      <Route path="/tier-plan/:id" component={TierPlanDetails} />
      <Route path="/unit-plan/:id" component={UnitPlanDetails} />
      <Route path="/lesson-plan/:id" component={LessonPlanDetails} />
    </>
  );
};

export default MainContent;
