import { Dialog, DialogTitle, DialogContent, Grid, IconButton, DialogActions, Button } from "@material-ui/core";
import { getFormRenderer } from "common/utils/formUtility";
import React from "react";
import { useSelector } from "react-redux";
import * as R from "ramda";
import { Close } from "@material-ui/icons";

const FormDialog = (props) => {
    const { open, onClose, title, formRenderer, formType, onSave, isEdit, showActive, onActiveClick, privileges } = props;
    const globalFormData = useSelector(state => state.globalForm[formType]);

    const Renderer = getFormRenderer(formRenderer);

    const activateButtonLabel = R.propOr("", "status", globalFormData) === "ACTIVE" ? "Deactivate" : "Activate";
    return (
        <Dialog maxWidth="md" fullWidth open={open} onClose={onClose} aria-labelledby="form-dialog-title">
             <Grid container>
                    <Grid item xs={11}>
                        <DialogTitle id="form-dialog-title">{title}</DialogTitle>
                    </Grid>
                    <Grid item xs={1}>
                        <IconButton onClick={onClose}>
                        <Close />
                        </IconButton>
                    </Grid>
                </Grid>
            <DialogContent style={{ padding: "8px 25px" }}>
                <Renderer onSave={onSave} formType={formType} isEdit={isEdit} />
            </DialogContent>
            <DialogActions style={{ padding: "8px 35px 8px 8px" }}>
                {
                    showActive && R.propOr(false, "DELETE", privileges) &&<Button variant="outlined" style={{ padding: "8px 15px", fontWeight: 500, color: "gray" }} onClick={()=>onActiveClick(globalFormData)}>{activateButtonLabel}</Button >
                }
                <Button variant="outlined" onClick={onClose} color="primary">
                    Cancel
                </Button>
                <Button variant="contained" onClick={() => onSave(globalFormData)} color="primary">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default FormDialog;