import { FormControlLabel, makeStyles, Switch } from "@material-ui/core";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateForm } from "state/slices/globalFormSlice";
import * as R from "ramda";

const useStyles = makeStyles(theme => ({
    controlLabel: {
        marginLeft: "0px",
    },

}))
const StatusInput = ({ formType, defaultValue = "ACTIVE" }) => {
    const classes = useStyles();
    const formData = useSelector(state => state.globalForm[formType]);
    const dispatch = useDispatch();
    const label = { inputProps: { 'aria-label': 'Switch demo' } };
    const onSwitchChange = (e) => {
        const isChecked = e.target.checked;
        setStatusActive(isChecked);
    };
    const setStatusActive = (isChecked) => {
        const updatedformData = R.assocPath(["status"], isChecked ? "ACTIVE" : "INACTIVE", formData);
        dispatch(updateForm({ formType, formData: updatedformData }));
    };
    useEffect(() => {
        setStatusActive(defaultValue === "ACTIVE" ? true : false);
    }, [defaultValue]);
    return <FormControlLabel
                className={classes.controlLabel}
                control={
                    <Switch {...label}
                        defaultChecked
                        onChange={onSwitchChange}
                    />
                }
                label="Active"
                labelPlacement="start"
            />;
}

export default StatusInput;