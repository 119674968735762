import { Button, Grid, makeStyles, Typography } from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";

const useStyles = makeStyles((theme) => ({
  container: {
    margin: "0",
    marginBottom: "51px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "30px",
    },
    flexWrap: "nowrap"
  },
  paddingNone: {
    padding: "0px !important",
    // display: "flex",
    // justifyContent: "space-between",
  },
  addNewPlan1: {
    [theme.breakpoints.down("sm")]: {
      padding: "0px 6px !important",
    },
  },
  title: {
    fontStyle: "normal",
    fontWeight: "800",
    fontSize: "24px",
    color: "#263657",
  },
}));

function GoalConfigurationHeader({ setModalState }) {
  const classes = useStyles();

  const onConfigurationClick = () => {
    setModalState((prev) => ({ ...prev, isConfigurationModelOpen: true }));
  };
  
  const onTimelineClick = () => {
    setModalState((prev) => ({ ...prev, isTimelineModelOpen: true }));
  };

  return (
    <Grid
      container
      spacing={3}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      className={classes.container}
    >
      <Grid item xs={12} md={12} className={classes.paddingNone} >
        <Typography className={classes.title} variant="div">
          <span>Goal Configurations</span>
        </Typography>
      </Grid>
        
        <Grid
        item
        container
        spacing={3}
        md={6}
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        className={classes.paddingNone}
      >
         <Grid item className={classes.addNewPlan1}>
        <Button
          variant="contained"
          color="primary"
          className={classes.addIconButton}
          onClick={onTimelineClick}
          startIcon={<AddCircleIcon className="addIcon" />}
        >
          Add Timeline
        </Button>
        </Grid>
        <Grid item className={classes.addNewPlan1}>
        <Button
          variant="contained"
          color="primary"
          className={classes.addIconButton}
          onClick={onConfigurationClick}
          startIcon={<AddCircleIcon className="addIcon" />}
        >
          Add Configuration
        </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default GoalConfigurationHeader;
