import * as React from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";

export default function MoreVerticalDot(props) {
  const { handleClick, options, background } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const onClick = (event, value) => {
    setAnchorEl(null);
    handleClick(event, value);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div
      onClick={(event) => {
        event.stopPropagation();
      }}
    >
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={(event) => {
          event.stopPropagation();
          setAnchorEl(event.currentTarget);
        }}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            background: background || "#F1F3FF",
            borderRadius: "14px",
            minWidth: "160px",
          },
        }}
        elevation={0}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {options
          ?.filter((option) => option.hidden !== true)
          ?.map((option, index) => {
            return (
              <MenuItem
                key={index}
                disabled={option.isDisabled}
                onClick={(e) => onClick(e, option.value)}
              >
                {option?.icon ? option.icon : ""} {option.label}
              </MenuItem>
            );
          })}
      </Menu>
    </div>
  );
}
