import React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { makeStyles, Grid, Button } from "@material-ui/core";
import { FOLDER_OPEN_ICON } from "asset/images";
import { useHistory } from "react-router-dom";
import { deepPurple } from "@material-ui/core/colors";
import * as R from "ramda";

const useStyles = makeStyles((theme) => ({
  cardBody: {
    width: "100%",
    background: "#FFFFFF",
    borderRadius: "14px !important",
    height: "auto",
  },
  cardContent: {
    padding: "23px 18px !important",
  },
  cardTitle: {
    fontStyle: "normal",
    fontWeight: "800",
    fontSize: "18px",
    lineHeight: "22px",
    color: "#263657",
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  cardTitleText: {
    width: "80%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  fileIcon: {
    marginRight: "10px",
  },
  cardTitleWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    position: "relative",
  },
  subTitle: {
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "12px",
    lineHeight: "14px",
    color: "#898EAC",
  },
  cardBodyContent: {
    marginTop: "18px",
  },
  bodyText: {
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "12px",
    lineHeight: "14px",
    color: "#000000",
  },
  dotStyle: {
    width: "3.86px",
    height: "3.86px",
    background: "#898EAC",
    borderRadius: "50%",
    display: "inline-block",
    verticalAlign: "middle",
    margin: "auto 7px",
  },
  cardBottom: {
    display: "flex",
    marginTop: "18px",
  },
  createdBy: {
    display: "flex",
    flexDirection: "column",
  },
  subUnitSection: {
    border: "1px solid #DBDBDB",
    borderRadius: "4px",
    padding: "10px",
    marginTop: "16px",
    height: "90px",
  },
  editIcon: {
    marginRight: "10px",
  },
  verticalDotsButton: {
    position: "absolute",
    right: "0px",
  },
  avatar: {
    background: deepPurple[500],
    height: "24px",
    width: "24px",
    marginRight: "10px",
    fontSize: "16px",
  },
}));

export default function ClassesCard({ data, privileges = {} }) {
  const history = useHistory();
  const classes = useStyles();

  const handleRedirect = () => {
    if (R.propOr(true, "READ", privileges)) {
      history.push(`/classes/${data?.id}`);
    } else return null;
  };

  return (
    <Grid item className={classes.annualPlanCard} md="6" xs="12">
      <Box
        sx={{ minWidth: 275 }}
        style={{ cursor: "pointer" }}
        onClick={handleRedirect}
      >
        <Card variant="outlined" className={classes.cardBody}>
          <CardContent className={classes.cardContent}>
            <div className={classes.cardTitleWrapper}>
              <Typography className={classes.cardTitle} variant="div">
                <img
                  className={classes.fileIcon}
                  src={FOLDER_OPEN_ICON}
                  alt="File Icon"
                />
                <span className={classes.cardTitleText}>
                  {data?.gradeName || "-"}
                </span>
              </Typography>
              {/* {R.propOr(true, "CREATE", privileges) && (
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={() => {}}
                  size="small"
                  data-html2canvas-ignore
                >
                  Enable
                </Button>
              )}
              {R.propOr(true, "DELETE", privileges) && (
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={() => {}}
                  size="small"
                  data-html2canvas-ignore
                >
                  Disable
                </Button>
              )} */}
            </div>
          </CardContent>
        </Card>
      </Box>
    </Grid>
  );
}
