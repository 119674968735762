import React from "react";
import AppHeader from "components/header/AppHeader";
import Alert from "react-s-alert";
import "react-s-alert/dist/s-alert-default.css";
import "react-s-alert/dist/s-alert-css-effects/slide.css";
import "./App.css";
import { makeStyles, ThemeProvider } from "@material-ui/styles";
import Theme from "theme/Theme";
import { useSelector } from "react-redux";
import useInterceptor from "util/useInterceptor";
import ChangePassword from "common/components/ChangePassword/ChangePassword";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import RouteWrapper from "route/RouteWrapper";

const useStyles = makeStyles((theme) => ({
  app: {
    height: "100%",
  },
  appBody: {
    height: "100%",
  },
}));
const drawerWidth = 0;
const App = (props) => {
  useInterceptor();
  const classes = useStyles();
  const userProfile = useSelector((state) => state.userProfile);
  const passwordChangeRequired = useSelector(
    (state) => state.userProfile.passwordChangeRequired
  );
  const unauthorised = useSelector((state) => state.userProfile.unauthorised);
  if (unauthorised) {
    return <div>unauthorised</div>;
  }
  if (passwordChangeRequired) {
    return (
      <ThemeProvider theme={Theme}>
        <div className={classes.app}>
          <div className={classes.appBody}>
            <Box sx={{ display: "flex" }}>
              <CssBaseline />
              <AppBar
                elevation={0}
                position="fixed"
                sx={{
                  width: { sm: `calc(100% - ${drawerWidth}px)` },
                  ml: { sm: `${drawerWidth}px` },
                }}
                style={{ backgroundColor: "#263657" }}
              >
                <Toolbar>
                  <AppHeader />
                </Toolbar>
              </AppBar>
            </Box>
            <Box
              component="main"
              sx={{
                flexGrow: 1,
                p: 1,
                width: { sm: `calc(100% - ${drawerWidth}px)` },
                marginTop: 10,
              }}
            >
              <ChangePassword />
            </Box>
          </div>
          <Alert
            stack={{ limit: 3 }}
            timeout={3000}
            position="top-right"
            effect="slide"
            offset={65}
          />
        </div>
      </ThemeProvider>
    );
  }
  return (
    <ThemeProvider theme={Theme}>
      <div className={classes.app}>
        <div className={classes.appBody}>
          {!localStorage.getItem("isLoggedIn") === "true" && (
            <AppHeader {...props} />
          )}
          <RouteWrapper
            authenticated={localStorage.getItem("isLoggedIn") === "true"}
          />
        </div>
        <Alert
          stack={{ limit: 3 }}
          timeout={3000}
          position="top-right"
          effect="slide"
          offset={65}
        />
      </div>
    </ThemeProvider>
  );
};

export default App;
