import React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Avatar, makeStyles } from "@material-ui/core";
import { CHALKBOARD_ICON, CLOCK_ICON, FOLDER_OPEN_ICON } from "asset/images";
import EditIcon from "@mui/icons-material/Edit";
import StatusBlock from "../StatusBlock";
import moment from "moment";
import { GLOBAL_DATE_FORMAT } from "constants/PageConstants";
import { deepPurple } from "@material-ui/core/colors";
import { useHistory } from "react-router-dom";
import MoreVerticalDot from "../MoreVerticalDot";
import DeleteIcon from "@mui/icons-material/Delete";
import { deletePlanService } from "components/listOfValues/utils";

const useStyles = makeStyles((theme) => ({
  cardBody: {
    width: "100%",
    background: "#FFFFFF",
    borderRadius: "14px !important",
  },
  cardContent: {
    padding: "26px 18px !important",
  },
  cardTitle: {
    position: "relative",
    fontStyle: "normal",
    fontWeight: "800",
    fontSize: "18px",
    lineHeight: "22px",
    color: "#263657",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  cardTitleText: {
    display: "block",
    width: "100%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  fileIcon: {
    marginRight: "10px",
  },
  cardTitleWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  subTitle: {
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "8px",
    lineHeight: "10px",
    color: "#898EAC",
  },
  cardBodyContent: {
    marginTop: "18px",
  },
  bodyText: {
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "12px",
    lineHeight: "14px",
    color: "#000000",
  },
  dotStyle: {
    width: "3.86px",
    height: "3.86px",
    background: "#898EAC",
    borderRadius: "50%",
    display: "inline-block",
    verticalAlign: "middle",
    margin: "auto 7px",
  },
  createdBy: {
    display: "flex",
    flexDirection: "column",
  },
  avatar: {
    background: deepPurple[500],
    height: "24px",
    width: "24px",
    marginRight: "10px",
    fontSize: "16px",
  },
  verticalDotsButton: {
    position: "absolute",
    right: "0px",
  },
  verticalDots: {
    margin: "0 6px",
  },
  editIcon: {
    marginRight: "10px",
  },
  cardBottom: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  createdBySection: {
    display: "flex",
    marginTop: "18px",
  },
  statusBlock: {
    marginTop: "18px",
  },
}));

export default function LessonPlanCard({
  data,
  userRoles,
  privileges,
  keyName,
  hideModification,
  setModalState,
  getPlanDetails,
  isDetailedPage,
}) {
  const history = useHistory();
  const classes = useStyles();
  const AllowedRoles = ["Administrator", "Inspire Us-Core", "Coordinator"];

  const handleRedirect = (id) => {
    history.push(`/lesson-plan/${id}`);
  };

  const isEditEnabled = (roles) => {
    let editEnabled = false;
    roles.forEach((role) => {
      if (AllowedRoles.includes(role)) {
        editEnabled = true;
      }
    });
    return editEnabled;
  };

  const isEditable = isEditEnabled(userRoles);

  const handleDeletePlan = async (id) => {
    const res = await deletePlanService("lesson-plan", id);
    if (res && getPlanDetails) getPlanDetails();
  };

  return (
    <Box
      sx={{ minWidth: 275 }}
      onClick={() => !isDetailedPage && handleRedirect(data?.id)}
      style={{ cursor: "pointer" }}
    >
      <Card
        variant="outlined"
        className={classes.cardBody}
        style={{ background: isDetailedPage ? "#E3D5CA" : "#FFF" }}
      >
        <CardContent className={classes.cardContent}>
          <div className={classes.cardTitleWrapper}>
            <Typography className={classes.cardTitle} variant="div">
              <img
                className={classes.fileIcon}
                src={FOLDER_OPEN_ICON}
                alt="File Icon"
              />
              <span className={classes.cardTitleText}>{data?.name || "-"}</span>
              {privileges?.UPDATE && !hideModification && (
                <div className={classes.verticalDotsButton}>
                  <MoreVerticalDot
                    options={[
                      {
                        icon: (
                          <EditIcon
                            fontSize="small"
                            className={classes.editIcon}
                          />
                        ),
                        label: "Manage Lesson Plan",
                        value: "Manage Lesson Plan",
                        isDisabled:
                          data?.status?.toUpperCase() === "COMPLETED"
                            ? !isEditable
                            : !privileges?.UPDATE,
                      },
                      {
                        icon: (
                          <DeleteIcon
                            fontSize="small"
                            className={classes.editIcon}
                          />
                        ),
                        label: "Delete Lesson Plan",
                        value: "Delete Lesson Plan",
                        isDisabled:
                          data?.status?.toUpperCase() === "COMPLETED"
                            ? true
                            : !privileges?.DELETE,
                      },
                    ]}
                    handleClick={(e, value) => {
                      e.stopPropagation();
                      if (value === "Delete Lesson Plan") {
                        data?.id && handleDeletePlan(data.id);
                      } else {
                        setModalState((prev) => ({
                          ...prev,
                          isOpen: true,
                          isEdit: true,
                          data,
                        }));
                      }
                    }}
                  />
                </div>
              )}
            </Typography>
          </div>
          <div className={classes.cardBodyContent}>
            <div>
              <img
                className={classes.fileIcon}
                src={CHALKBOARD_ICON}
                alt="Chalkboard Icon"
              />
              <Typography
                className={classes.bodyText}
                variant="span"
                marginRight={"34px"}
              >
                School: {data?.curriculumContext?.school?.schoolCampus || "-"}
              </Typography>
            </div>
            <div>
              <img
                className={classes.fileIcon}
                src={CLOCK_ICON}
                alt="Clock Icon"
              />
              <Typography className={classes.bodyText} variant="span">
                Start Date:{" "}
                {data?.start
                  ? moment(data.start).format(GLOBAL_DATE_FORMAT)
                  : "-"}{" "}
                | End Date:{" "}
                {data?.end ? moment(data.end).format(GLOBAL_DATE_FORMAT) : "-"}
              </Typography>
            </div>
          </div>
          <div className={classes.cardBottom}>
            <div className={classes.createdBySection}>
              <Avatar className={classes.avatar}>
                {data?.createdBy?.charAt(0)?.toUpperCase()}
              </Avatar>
              <div className={classes.createdBy}>
                <Typography
                  className={classes.subTitle}
                  fontSize="10px"
                  variant="div"
                >
                  Created by
                </Typography>
                <Typography
                  className={classes.bodyText}
                  variant="div"
                  fontWeight={400}
                >
                  {data?.createdBy || "-"}
                </Typography>
              </div>
            </div>
            <div className={classes.statusBlock}>
              <StatusBlock status={data?.status || ""} isDetailCard={true} />
            </div>
          </div>
        </CardContent>
      </Card>
    </Box>
  );
}
