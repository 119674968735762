import { createSlice } from "@reduxjs/toolkit";
import * as R from "ramda";

export const PAGES = {
  listOfValues: "listOfValues",
  schools: "schools",
  classes: "classes",
  classSections: "classSections",
  subjects: "subjects",
  policies: "policies",
  teachers: "teachers",
  observations: "observations",
  userTeachers: "userTeachers",
  annualPlan: "annualPlan",
  unitPlan: "unitPlan",
  lessonPlan: "lessonPlan",
  roles: "roles",
  privileges: "privileges",
  unitPlanList: "unitPlanList",
  pageFilter: "pageFilters",
  setGoal: "setGoal",
  reviewSetGoal: "reviewSetGoal",
  lessonPlanList: "lessonPlanList",
  tierPlan: "tierPlan",
  users: "users",
};

const pageFilter = {
  school: {
    id: "",
    schoolCode: "",
  },
  className: {
    id: "",
    className: "",
  },
  subject: {
    id: "",
    subject: "",
  },
  teacher: {
    id: "",
    email: "",
  },
};
export const pageSlice = createSlice({
  name: "globalForm",
  initialState: {
    listOfValues: {
      items: [],
      totalElements: 0,
      currentPage: 0,
      pageSize: 100,
      totalPages: 0,
      pageFilter: pageFilter,
      loading: true,
    },
    classes: {
      items: [],
      totalElements: 0,
      currentPage: 0,
      pageSize: 15,
      totalPages: 0,
      pageFilter: pageFilter,
      loading: true,
    },
    schools: {
      items: [],
      totalElements: 0,
      currentPage: 0,
      pageSize: 15,
      totalPages: 0,
      pageFilter: pageFilter,
      loading: true,
    },
    classSections: {
      items: [],
      totalElements: 0,
      currentPage: 0,
      pageSize: 15,
      totalPages: 0,
      pageFilter: pageFilter,
      loading: true,
    },
    subjects: {
      items: [],
      totalElements: 0,
      currentPage: 0,
      pageSize: 15,
      totalPages: 0,
      pageFilter: pageFilter,
      loading: true,
    },
    policies: {
      items: [],
      totalElements: 0,
      currentPage: 0,
      pageSize: 15,
      totalPages: 0,
      pageFilter: pageFilter,
      loading: true,
    },
    teachers: {
      items: [],
      totalElements: 0,
      currentPage: 0,
      pageSize: 15,
      totalPages: 0,
      pageFilter: pageFilter,
      loading: true,
    },
    observations: {
      items: [],
      totalElements: 0,
      currentPage: 0,
      pageSize: 15,
      totalPages: 0,
      pageFilter: pageFilter,
      loading: true,
    },
    userTeachers: {
      items: [],
      totalElements: 0,
      currentPage: 0,
      pageSize: 15,
      totalPages: 0,
      pageFilter: pageFilter,
      loading: true,
    },
    annualPlan: {
      items: [],
      totalElements: 0,
      currentPage: 0,
      pageSize: 5,
      totalPages: 0,
      pageFilter: pageFilter,
      loading: true,
    },
    unitPlan: {
      items: [],
      totalElements: 0,
      currentPage: 0,
      pageSize: 5,
      totalPages: 0,
      pageFilter: pageFilter,
      loading: true,
    },
    lessonPlan: {
      items: [],
      totalElements: 0,
      currentPage: 0,
      pageSize: 5,
      totalPages: 0,
      pageFilter: pageFilter,
      loading: true,
    },
    roles: {
      items: [],
      totalElements: 0,
      currentPage: 0,
      pageSize: 15,
      totalPages: 0,
      pageFilter: pageFilter,
      loading: true,
    },
    privileges: {
      items: [],
      totalElements: 0,
      currentPage: 0,
      pageSize: 100,
      totalPages: 0,
      pageFilter: pageFilter,
      loading: true,
    },
    unitPlanList: {
      items: [],
      totalElements: 0,
      currentPage: 0,
      pageSize: 100,
      totalPages: 0,
      pageFilter: pageFilter,
      loading: true,
    },
    setGoal: {
      items: [],
      totalElements: 0,
      currentPage: 0,
      pageSize: 5,
      totalPages: 0,
      pageFilter: pageFilter,
      loading: true,
    },
    reviewSetGoal: {
      items: [],
      totalElements: 0,
      currentPage: 0,
      pageSize: 5,
      totalPages: 0,
      pageFilter: pageFilter,
      loading: true,
    },
  },
  reducers: {
    setPageListItems: (state, action) => {
      const [page = 0, itemList, totalElements = 100, totalPages = 1] = R.paths(
        [
          ["payload", "page"],
          ["payload", "itemList"],
          ["payload", "totalElements"],
          ["payload", "totalPages"],
        ],
        action
      );
      state[page].items = itemList;
      state[page].totalElements = totalElements;
      state[page].totalPages = totalPages;
      state[page].loading = false;
    },
    // use for lazy loading
    updatePageListItems: (state, action) => {
      const [page = 0, itemObject] = R.paths(
        [
          ["payload", "page"],
          ["payload", "itemObject"],
        ],
        action
      );
      function upsert(array, element) {
        const i = array.findIndex((_element) => _element.id === element.id);
        if (i > -1) array[i] = element;
        return array;
      }
      state[page].items = upsert(state[page].items, itemObject);
      state[page].loading = false;
    },
    getPageListItems: (state, action) => {
      const [page = 0] = R.paths([["payload", "page"]], action);
      state[page].loading = true;
    },
    setCurrentPage: (state, action) => {
      const [page, currentPage] = R.paths(
        [
          ["payload", "page"],
          ["payload", "currentPage"],
        ],
        action
      );
      state[page].currentPage = currentPage;
    },
    clearPages: (state, action) => {
      state.schools.items = [];
      state.classes.items = [];
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  getPageListItems,
  setPageListItems,
  updatePageListItems,
  setCurrentPage,
  clearPages,
} = pageSlice.actions;

export default pageSlice.reducer;
