import { Button, Grid, makeStyles, Typography } from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import * as R from "ramda";

const useStyles = makeStyles((theme) => ({
  container: {
    margin: "0",
    marginBottom: "51px",
    width: "100%",
  },
  paddingNone: {
    alignItems: "center",
    padding: "0px !important",
    display: "flex",
    justifyContent: "space-between",
  },
  title: {
    fontStyle: "normal",
    fontWeight: "800",
    fontSize: "24px",
    color: "#263657",
  },
}));

function SchoolPlanHeader({ setModalState, privileges }) {
  const classes = useStyles();

  const onAddClick = () => {
    setModalState((prev) => ({ ...prev, isOpen: true }));
  };

  return (
    <Grid
      container
      spacing={3}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      className={classes.container}
    >
      <Grid item xs={12} md={12} className={classes.paddingNone}>
        <Typography className={classes.title} variant="div">
          <span>Schools</span>
        </Typography>
        {R.propOr(true, "CREATE", privileges) && (
          <Button
            variant="contained"
            color="primary"
            className={classes.addIconButton}
            onClick={onAddClick}
            startIcon={<AddCircleIcon className="addIcon" />}
          >
            Add School
          </Button>
        )}
      </Grid>
    </Grid>
  );
}

export default SchoolPlanHeader;
