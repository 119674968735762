import {
  Grid,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import withPrivilege from "common/hocs/withPrivilege";
import { useEffect, useState } from "react";
import { colors } from "theme/colors";
import { EntityMap } from "util/roleUtils";
import "../../../academicPlanDetails/styles.css";
import { getDetailsById } from "components/listOfValues/utils";
import { useParams } from "react-router-dom";
import ClassesDetailsPage from "./ClassesDetailsPage";
import { ManageSchoolState } from "state/globalForms/initialStates";
import ClassesDetailCard from "./ClassesDetailCard";
import BreadCrumb from "components/academicPlanDetails/BreadCrumb";
import ClassesSideCard from "./ClassesSideCard";

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    flexWrap: "nowrap",
  },
  filterSection: {
    background: "white",
  },
  planSection: {
    height: "100%",
    minHeight: "100vh",
    padding: "47px 51px",
    backgroundColor: colors.gray5,
    [theme.breakpoints.down("md")]: {
      padding: "36px",
    },
  },
  container: {
    marginBottom: "30px",
  },
  paddingNone: {
    padding: "0px !important",
    display: "flex",
    justifyContent: "space-between",
  },
  title: {
    fontStyle: "normal",
    fontWeight: "800",
    fontSize: "24px",
    color: "#263657",
  },
  annualPlanCard: {
    marginBottom: "30px",
  },
}));

function ManageClasses(props) {
  const classes = useStyles();
  const theme = useTheme();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down("sm"));
  const [modalState, setModalState] = useState({
    isOpen: false,
    data: null,
    isEdit: false,
  });
  const [state, setState] = useState(ManageSchoolState);
  const { id } = useParams();

  useEffect(() => {
    getClassesDetails();
  }, []);

  const getClassesDetails = async () => {
    const response = await getDetailsById("/schools", id);
    setState(response || null);
  };

  return (
    <Grid container direction="row" className={classes.gridContainer}>
      {!isSmallDevice && (
        <Grid className={classes.filterSection} md="3">
          <BreadCrumb planName={"SCHOOL_DETAILS"} parentPath="schools" />
          <ClassesDetailCard
            data={state}
            {...props}
            getClassesDetails={getClassesDetails}
          />
        </Grid>
      )}
      <Grid className={classes.planSection} md="9" xs="12">
        <Grid
          container
          spacing={3}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          className={classes.container}
        >
          {isSmallDevice && (
            <BreadCrumb planName={"SCHOOL_DETAILS"} parentPath="schools" />
          )}
          <Grid item xs={12} md={12} className={classes.paddingNone}>
            <Typography className={classes.title} variant="div">
              <span>Classes</span>
            </Typography>
          </Grid>
        </Grid>
        {isSmallDevice && (
          <Grid item className={classes.annualPlanCard} xs="12">
            <ClassesSideCard data={state} isDetailedPage={true} />
          </Grid>
        )}
        <ClassesDetailsPage
          {...props}
          modalState={modalState}
          setModalState={setModalState}
          parentState={state}
          getClassesDetails={getClassesDetails}
        />
      </Grid>
    </Grid>
  );
}

export default withPrivilege(ManageClasses, EntityMap.class);
