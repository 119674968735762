import { Grid, makeStyles, useMediaQuery, useTheme } from "@material-ui/core";
import withPrivilege from "common/hocs/withPrivilege";
import { useEffect, useState } from "react";
import { colors } from "theme/colors";
import { EntityMap } from "util/roleUtils";
import "../../../academicPlanDetails/styles.css";
import { getDetailsById } from "components/listOfValues/utils";
import { useParams } from "react-router-dom";
import ClassPage from "./ClassPage";
import ClassHeader from "./ClassHeader";
import ClassesDetails from "./ClassesDetails";
import ManageSubjectModal from "../ClassSections/ManageSubjectModal";
import ManageClassSectionModal from "../ClassSections/ManageClassSectionModal";
import ManageTeacherModal from "../ClassSections/ManageTeacherModal";
import BreadCrumb from "components/academicPlanDetails/BreadCrumb";
import ClassesDetailsSideCard from "./ClassesDetailsSideCard";

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    flexWrap: "nowrap",
  },
  filterSection: {
    background: "white",
  },
  planCard: {
    marginBottom: "30px",
  },
  planSection: {
    height: "100%",
    minHeight: "100vh",
    width: "100%",
    padding: "47px 51px",
    backgroundColor: colors.gray5,
    [theme.breakpoints.down("sm")]: {
      padding: "36px",
    },
  },
  container: {
    marginBottom: "51px",
  },
  paddingNone: {
    padding: "0px !important",
    display: "flex",
    justifyContent: "space-between",
  },
  title: {
    fontStyle: "normal",
    fontWeight: "800",
    fontSize: "24px",
    color: "#263657",
  },
}));

function ManageClassesDetails({ props, userRoles, privileges }) {
  const classes = useStyles();
  const theme = useTheme();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down("sm"));

  const [modalState, setModalState] = useState({
    isSubjectModalOpen: false,
    isClassSectionModalOpen: false,
    isTeacherModalOpen: false,
    data: null,
    isEdit: false,
  });
  const [state, setState] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    getClassesDetails();
  }, []);

  const getClassesDetails = async () => {
    const response = await getDetailsById("/classes", id);
    setState(response || null);
  };

  return (
    <>
      <Grid container direction="row" className={classes.gridContainer}>
        {!isSmallDevice && (
          <Grid className={classes.filterSection} md="3">
            <BreadCrumb
              planId={state?.school?.id || ""}
              planName={"CLASSES_DETAILS"}
              parentPath="classes"
            />
            <ClassesDetails
              data={state}
              {...props}
              getClassesDetails={getClassesDetails}
            />
          </Grid>
        )}
        <Grid className={classes.planSection} md="9" xs="12">
          {isSmallDevice && (
            <BreadCrumb
              planId={state?.school?.id || ""}
              planName={"CLASSES_DETAILS"}
              parentPath="classes"
            />
          )}
          <ClassHeader
            setModalState={setModalState}
            parentState={state}
            privileges={privileges}
            {...props}
            isSmallDevice={isSmallDevice}
          />
          {isSmallDevice && (
            <Grid item className={classes.planCard} xs="12">
              <ClassesDetailsSideCard
                data={state}
                {...props}
                getClassesDetails={getClassesDetails}
                isDetailedPage={true}
              />
            </Grid>
          )}
          <ClassPage
            {...props}
            parentState={state}
            modalState={modalState}
            privileges={privileges}
            setModalState={setModalState}
            userRoles={userRoles}
            getClassesDetails={getClassesDetails}
          />
        </Grid>
      </Grid>
      {modalState?.isSubjectModalOpen && (
        <ManageSubjectModal
          userRoles={userRoles}
          modalState={modalState}
          parentState={state}
          setModalState={setModalState}
          getClassesDetails={getClassesDetails}
        />
      )}
      {modalState?.isClassSectionModalOpen && (
        <ManageClassSectionModal
          userRoles={userRoles}
          modalState={modalState}
          parentState={state}
          setModalState={setModalState}
          getClassesDetails={getClassesDetails}
        />
      )}
      {modalState?.isTeacherModalOpen && (
        <ManageTeacherModal
          userRoles={userRoles}
          modalState={modalState}
          section={modalState.data}
          setModalState={setModalState}
          getClassesDetails={getClassesDetails}
          subjects={state.subjects}
          schoolCode={state?.school?.schoolCode}
        />
      )}
    </>
  );
}

export default withPrivilege(ManageClassesDetails, EntityMap.class);
