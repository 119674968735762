import {
  Card,
  CardContent,
  Divider,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { CHECK_ICON, FILE_ICON } from "asset/images";
import { GLOBAL_DATE_FORMAT } from "constants/PageConstants";
import moment from "moment";
import React, { useState } from "react";
import MoreVerticalDot from "../MoreVerticalDot";
import EditIcon from "@mui/icons-material/Edit";
import TierPlanModal from "./TierPlanModal";

const useStyles = makeStyles((theme) => ({
  cardBody: {
    margin: "1rem",
    background: "#F0F2FE",
    borderRadius: "15px",
    boxShadow: "none",
    marginTop: "50px",
  },
  cardContent: {
    padding: "26px 18px !important",
  },
  cardTitleDiv: {
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  cardTitle: {
    position: "relative",
    fontStyle: "normal",
    fontWeight: "800",
    fontSize: "18px",
    lineHeight: "22px",
    color: "#263657",
    display: "flex",
    alignItems: "center",
    marginBottom: "20px",
    justifyContent: "space-between",
    width: "100%",
  },
  fileIcon: {
    marginRight: "10px",
    alignSelf: "start",
    marginTop: "5px",
  },
  subTitle: {
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "8px",
    lineHeight: "10px",
    color: "#898EAC",
    marginLeft: "25px",
  },
  bodyText: {
    marginTop: "18px",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "12px",
    lineHeight: "14px",
    color: "#000000",
    display: "block",
  },
  bodySubText: {
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "14px",
  },
  topicCard: {
    background: "#fff",
    borderRadius: "4px",
    boxShadow: "none",
    marginTop: "24px",
  },
  topicTitle: {
    fontWeight: 500,
    fontSize: "14px",
    color: "#898EAC",
  },
  subTopic: {
    display: "flex",
  },
  subTopicText: {
    fontWeight: "400",
    fontSize: "12px",
  },
  editIcon: {
    marginRight: "10px",
  },
  verticalDotsButton: {
    position: "absolute",
    right: "-15px",
    top: "-8px",
  },
  verticalDots: {
    margin: "0 6px",
  },
}));

function TierPlanDetailCard({
  data,
  privileges,
  userRoles,
  hideModification,
  getPlanDetails,
}) {
  const AllowedRoles = ["Administrator"];
  const classes = useStyles();
  const [modalState, setModalState] = useState({
    isOpen: false,
    data: null,
    isEdit: false,
  });

  const isEditEnabled = (roles) => {
    let editEnabled = false;
    roles.forEach((role) => {
      if (AllowedRoles.includes(role)) {
        editEnabled = true;
      }
    });
    return editEnabled;
  };

  const isEditable = isEditEnabled(userRoles);

  return (
    <>
      <Card className={classes.cardBody}>
        <CardContent className={classes.cardContent}>
          <Typography className={classes.cardTitle} variant="div">
            <div className={classes.cardTitleDiv}>
              <img
                className={classes.fileIcon}
                src={FILE_ICON}
                alt="File Icon"
              />
              <span>{data?.name || ""}</span>
            </div>
            {privileges?.UPDATE && !hideModification && (
              <div className={classes.verticalDotsButton}>
                <MoreVerticalDot
                  options={[
                    {
                      icon: (
                        <EditIcon
                          fontSize="small"
                          className={classes.editIcon}
                        />
                      ),
                      label: "Edit Tier Plan",
                      value: "Edit Tier Plan",
                      isDisabled: data?.status?.toUpperCase() === "COMPLETED" ? !isEditable : !privileges?.UPDATE,
                    },
                  ]}
                  handleClick={(e, value) => {
                    e.stopPropagation();
                    if(value === 'Delete Tier Plan') {
                      console.log(value);
                    } else {
                      setModalState((prev) => ({
                        ...prev,
                        isOpen: true,
                        isEdit: true,
                        data,
                      }));
                    }
                  }}
                  background={'#fff'}
                />
              </div>
            )}
          </Typography>
          <Divider />
          <Typography className={classes.bodyText} variant="div">
            Start Date:{" "}
            <Typography className={classes.bodySubText} variant="div">
              {data?.start
                ? moment(data.start).format(GLOBAL_DATE_FORMAT)
                : "-"}
            </Typography>
          </Typography>
          <Typography className={classes.bodyText} variant="span">
            End Date:{" "}
            <Typography className={classes.bodySubText} variant="div">
              {data?.end ? moment(data.end).format(GLOBAL_DATE_FORMAT) : "-"}
            </Typography>
          </Typography>
          <Typography className={classes.bodyText} variant="div">
            Created by:
          </Typography>
          <Typography className={classes.bodySubText} variant="div">
            {data?.createdBy || "-"}
          </Typography>
          <TopicSection topics={data?.topics || []} />
        </CardContent>
      </Card>
      {modalState.isOpen && (
        <TierPlanModal
          userRoles={userRoles}
          modalState={modalState}
          setModalState={setModalState}
          getPlanDetails={getPlanDetails}
        />
      )}
    </>
  );
}

export default TierPlanDetailCard;

const TopicSection = ({ topics }) => {
  const classes = useStyles();
  return (
    <Card className={classes.topicCard}>
      <CardContent className={classes.topicContent}>
        {topics.map((topic) => (
          <Typography className={classes.topicSection} variant="div">
            <Typography className={classes.topicTitle} variant="div">
              {topic?.name || ""}
            </Typography>
            {topic?.subTopics?.length
              ? topic?.subTopics?.map((subTopic, index, array) => (
                  <div
                    key={subTopic?.id}
                    className={classes.subTopic}
                    style={
                      array.length - 1 === index
                        ? {
                            marginBottom: "25px",
                          }
                        : {}
                    }
                  >
                    <img
                      className={classes.fileIcon}
                      src={CHECK_ICON}
                      alt="Check Icon"
                    />
                    <Typography
                      className={classes.subTopicText}
                      variant="span"
                      marginRight={"34px"}
                      fontSize={"10px"}
                      fontWeight="500"
                    >
                      {subTopic?.name || "-"}
                    </Typography>
                  </div>
                ))
              : ""}
          </Typography>
        ))}
      </CardContent>
    </Card>
  );
};
