import React, { useEffect, useState } from "react";
import { Close } from "@material-ui/icons";
import { FORM_TYPES } from "common/utils/formUtility";
import { useSelector } from "react-redux";
import * as R from "ramda";
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  makeStyles,
  Divider,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import { getDetailsById } from "components/listOfValues/utils";
import ManageClassSectionForm from "./ManageClassSectionForm";
import { saveClassSection, updateClassSection } from "components/master/utils";

const useStyles = makeStyles((theme) => ({
  cardWrapper: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  annualPlanCard: {
    marginBottom: "30px",
  },
  paginationContainer: {
    display: "flex",
    justifyContent: "end",
  },
  heading: {
    fontWeight: 800,
    fontSize: "18px",
    color: "#263657",
  },
  divider: {
    margin: "0 24px 24px 24px",
  },
}));

function ManageClassSectionModal(props) {
  const {
    userRoles,
    setModalState,
    modalState,
    parentState,
    getClassesDetails,
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down("sm"));
  const [state, setState] = useState();
  const isEditForm = modalState.isEdit || false;

  useEffect(() => {
    const { data } = modalState;
    if (isEditForm && data?.id) {
      getClassSectionDetails(data.id);
    }
  }, [isEditForm]);

  const getClassSectionDetails = async (id) => {
    const response = await getDetailsById("/class-sections", id);

    setState(response || null);
  };

  const handleClose = () => {
    setModalState((prev) => ({
      isClassSectionModalOpen: false,
      data: null,
      isEdit: false,
    }));
  };

  const handleChange = (check, value) => {
    setState((prev) => ({
      ...prev,
      [check]: value,
    }));
  };

  const manageClassSectionFormData = useSelector(
    (state) => state.globalForm[FORM_TYPES.MANAGE_CLASS_SECTION]
  );

  const AllowedRoles = ["System Administrator", "Administrator"];

  const isEditEnabled = (roles) => {
    let editEnabled = false;
    if (roles && roles.length > 0) {
      roles.forEach((role) => {
        if (R.includes(role, AllowedRoles)) {
          editEnabled = true;
        }
      });
    }
    return editEnabled;
  };

  const isReadOnly = isEditEnabled(userRoles)
    ? false
    : R.propOr("", "status", manageClassSectionFormData) === "COMPLETED";

  const handleSubmit = async () => {
    if (!isEditForm) {
      const postPayload = {
        grade: parentState,
        sectionName: state.section,
        capacity: state.capacity,
        classTeacher: state.classTeacher,
      };
      const payload = await saveClassSection(postPayload, parentState.id);
    } else {
      const putPayload = {
        id: state?.id,
        grade: state?.grade,
        sectionName: state?.sectionName,
        capacity: state.capacity,
        classTeacher: state.classTeacher,
      };
      const payload = await updateClassSection(putPayload, state?.grade?.id);
    }

    setModalState({
      isClassSectionModalOpen: false,
      isEdit: false,
      data: null,
    });
    getClassesDetails && getClassesDetails();
  };

  return (
    <Dialog
      maxWidth="md"
      fullWidth
      open={true}
      fullScreen={isSmallDevice}
      onClose={() =>
        setModalState((prev) => ({ ...prev, isClassSectionModalOpen: false }))
      }
      ariaLabeledBy="form-dialog-title"
    >
      <Grid container>
        <Grid item xs={11}>
          <DialogTitle id="form-dialog-title">
            <Typography variant="h6" className={classes.heading}>{`${
              isEditForm ? "Edit" : "Add"
            } Class Section`}</Typography>
          </DialogTitle>
        </Grid>
        <Grid item xs={1} className={classes.modalCloseBtn}>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </Grid>
      </Grid>
      <Divider className={classes.divider} />
      <DialogContent style={{ padding: "8px 25px" }}>
        <Box>
          <ManageClassSectionForm
            formType={FORM_TYPES.MANAGE_CLASS_SECTION}
            isEditForm={isEditForm}
            isReadOnly={isReadOnly}
            state={isEditForm ? state : parentState}
            handleChange={handleChange}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "end",
              pt: 2,
            }}
          >
            <Button
              color="primary"
              variant="contained"
              onClick={handleSubmit}
              data-html2canvas-ignore
            >
              Submit
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default ManageClassSectionModal;
