import {
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  TextField,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import * as R from "ramda";
import { useDispatch, useSelector } from "react-redux";
import { updateForm } from "state/slices/globalFormSlice";
import StatusInput from "common/components/StatusInput/StatusInput";
import SelectDropDown from "common/components/SelectDropDown/SelectDropDown";
import {
  LOVApiKeys,
  LOVDataKeys,
  LOVDisplayKeys,
  LOVSelectKeys,
} from "common/components/SelectDropDown/SelectDropDownUtils";
import { PAGES } from "state/slices/pageSlice";
import RoleSelect from "common/components/RoleSelect/RoleSelect";

const useStyles = makeStyles((theme) => ({
  inputFormControl: {
    marginBottom: "1rem",
  },
  inputLabel: {
    top: "-17px",
  },
  inputLabel1: {
    top: "-17px",
    left: "-14px",
  },
}));
const UserTeacherForm = ({ formType, isEdit }) => {
  const classes = useStyles();
  const teacherFormData = useSelector((state) => state.globalForm[formType]);
  const dispatch = useDispatch();
  const [formData, setformData] = useState(teacherFormData);
  const onChange = (event) => {
    const [value, name] = R.paths(
      [
        ["target", "value"],
        ["target", "name"],
      ],
      event
    );
    const updatedformData = R.assoc(name, value, formData);
    setformData(updatedformData);
    dispatch(updateForm({ formType, formData: updatedformData }));
  };

  useEffect(() => {
    setformData(teacherFormData);
  }, [dispatch, formData, teacherFormData]);

  return (
    <form style={{ marginTop: "1rem" }}>
      <Grid container>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormControl className={classes.inputFormControl}>
              <InputLabel className={classes.inputLabel}>First Name</InputLabel>
              <TextField
                variant="outlined"
                margin="normal"
                name="firstName"
                placeholder={"Enter first name"}
                value={formData.firstName}
                onChange={onChange}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl className={classes.inputFormControl}>
              <InputLabel className={classes.inputLabel}>Last Name</InputLabel>
              <TextField
                variant="outlined"
                margin="normal"
                name="lastName"
                placeholder={"Enter last name"}
                value={formData.lastName}
                onChange={onChange}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl className={classes.inputFormControl}>
              <InputLabel className={classes.inputLabel}>Email</InputLabel>
              <TextField
                variant="outlined"
                margin="normal"
                name="email"
                placeholder={"Enter email address"}
                value={formData.email}
                onChange={onChange}
                disabled={isEdit}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl className={classes.inputFormControl}>
              <InputLabel className={classes.inputLabel}>
                Current Address
              </InputLabel>
              <TextField
                variant="outlined"
                margin="normal"
                name="currentAddress"
                placeholder={"Enter current address"}
                value={formData.currentAddress}
                onChange={onChange}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl className={classes.inputFormControl}>
              <InputLabel className={classes.inputLabel}>
                Permanent Address
              </InputLabel>
              <TextField
                variant="outlined"
                margin="normal"
                name="permanentAddress"
                placeholder={"Enter permanent address"}
                value={formData.permanentAddress}
                onChange={onChange}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl className={classes.inputFormControl}>
              <InputLabel className={classes.inputLabel}>
                Mobile Number
              </InputLabel>
              <TextField
                variant="outlined"
                margin="normal"
                name="mobile"
                placeholder={"Enter mobile number"}
                value={formData.mobile}
                onChange={onChange}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl className={classes.inputFormControl}>
              <InputLabel className={classes.inputLabel}>
                Designation
              </InputLabel>
              <TextField
                variant="outlined"
                margin="normal"
                name="designation"
                placeholder={"Enter designation"}
                value={formData.designation}
                onChange={onChange}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl className={classes.inputFormControl}>
              <InputLabel className={classes.inputLabel}>
                Experience (in months)
              </InputLabel>
              <TextField
                variant="outlined"
                margin="normal"
                name="experienceInMonths"
                placeholder={"Enter experience in number of months"}
                value={formData.experienceInMonths}
                onChange={onChange}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl className={classes.inputFormControl}>
              <InputLabel className={classes.inputLabel}>
                Onoarding Date
              </InputLabel>
              <TextField
                variant="outlined"
                margin="normal"
                id="date"
                name="onboardingDate"
                placeholder={"Enter onboarding date"}
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={onChange}
                value={formData.onboardingDate}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl
              variant="outlined"
              className={classes.inputFormControl}
            >
              <InputLabel
                className={classes.inputLabel1}
                id="demo-simple-select-outlined-label"
              >
                School
              </InputLabel>
              <SelectDropDown
                placeholder={"select school name"}
                formType={formType}
                selectKey={LOVSelectKeys.SCHOOL}
                apiKey={LOVApiKeys.SCHOOL}
                dataKey={LOVDataKeys.SCHOOL}
                secondaryDataKey={"campus"}
                selectedValue={R.propOr({}, "school", formData)}
                displayKey={LOVDisplayKeys.SCHOOL}
                page={PAGES.schools}
                isDisabled={isEdit}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl variant="outlined">
              <InputLabel
                className={classes.inputLabel1}
                id="demo-simple-select-outlined-label"
              >
                Roles
              </InputLabel>
              <RoleSelect formType={formType} />
            </FormControl>
          </Grid>
          {!isEdit && (
            <Grid item xs={12}>
              <FormControl>
                <InputLabel className={classes.inputLabel}>Password</InputLabel>
                <TextField
                  type="password"
                  variant="outlined"
                  margin="normal"
                  name="rawPassword"
                  placeholder={"Enter password"}
                  value={formData.rawPassword}
                  onChange={onChange}
                />
              </FormControl>
            </Grid>
          )}
        </Grid>

        <Grid item xs={12} spacing={3}>
          {!isEdit && <StatusInput formType={formType} />}
        </Grid>
      </Grid>
    </form>
  );
};

export default UserTeacherForm;
