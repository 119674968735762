import {
  Button,
  FormControl,
  Grid,
  TextField,
  InputLabel,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import {
  ManageObservationsRegistryState,
  ObservationFormState,
} from "state/globalForms/initialStates";
import * as R from "ramda";
import { makeStyles } from "@material-ui/styles";
import MultiSelectDropDown from "common/components/MultiSelectDropDown/MultiSelectDropDown";
import {
  LOVApiKeys,
  LOVDataKeys,
} from "common/components/SelectDropDown/SelectDropDownUtils";
import ObservationForm from "./ObservationForm";
import { mapIndexed } from "common/utils/composition";
import { onAddValue } from "components/listOfValues/utils";
import { PAGES } from "state/slices/pageSlice";

const useStyles = makeStyles((theme) => ({
  inputFormControl: {
    marginBottom: "1rem",
  },
  addObservationContainer: {
    display: "flex",
    justifyContent: "end",
  },
  inputLabel: {
    top: "-17px",
  },
  inputLabel1: {
    top: "-17px",
    left: "-14px",
  },
}));

const ObservationRegistryForm = ({ changeTab }) => {
  const classes = useStyles();
  const [observationRegistryForm, setObservationRegistryFormValue] = useState(
    ManageObservationsRegistryState
  );
  const onChange = (event) => {
    const [value, name] = R.paths(
      [
        ["target", "value"],
        ["target", "name"],
      ],
      event
    );
    setObservationRegistryFormValue(
      R.assocPath([name], value, observationRegistryForm)
    );
  };

  const onGradeSelect = (value) => {
    const updatedFormValue = R.assocPath(
      ["grades"],
      value,
      observationRegistryForm
    );
    setObservationRegistryFormValue(updatedFormValue);
  };

  const onObservationAdd = () => {
    const observations = R.prop("observations", observationRegistryForm);
    const updatedObservations = R.prepend(ObservationFormState, observations);
    const updatedFormData = R.assocPath(
      ["observations"],
      updatedObservations,
      observationRegistryForm
    );
    setObservationRegistryFormValue(updatedFormData);
  };

  const onRegistrySave = async () => {
    const observationData = R.pipe(
      R.prop("observations"),
      R.map((eachObservation) => R.dissocPath(["tagList"], eachObservation))
    )(observationRegistryForm);
    const grades = R.pipe(
      R.prop("grades"),
      R.map((eachGrade) => {
        return { className: eachGrade.className };
      })
    )(observationRegistryForm);
    const payload = R.pipe(
      R.assocPath(["observations"], observationData),
      R.assocPath(["grades"], grades)
    )(observationRegistryForm);
    const response = await onAddValue(payload, PAGES.observations);
    changeTab(0);
  };

  return (
    <form style={{ marginTop: "1rem" }}>
      <Grid container spacing={2}>
        <Grid
          item
          container
          xs={12}
          spacing={2}
          style={{ alignItems: "center" }}
        >
          <Grid item xs={6}>
            <FormControl>
              <InputLabel className={classes.inputLabel}>Name</InputLabel>
              <TextField
                variant="outlined"
                name="name"
                margin="normal"
                placeholder="Enter registry name"
                value={R.prop("name", observationRegistryForm)}
                onChange={onChange}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl variant="outlined">
              <InputLabel className={classes.inputLabel1}>Grades</InputLabel>
              <MultiSelectDropDown
                variant="outlined"
                margin="normal"
                placeholder={"Enter grades"}
                apiKey={LOVApiKeys.CLASS}
                dataKey={LOVDataKeys.CLASS}
                onSelect={onGradeSelect}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
              />
            </FormControl>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={6} style={{ alignSelf: "center" }}>
              <Typography variant="h6">Observations</Typography>
            </Grid>
            <Grid item xs={6} className={classes.addObservationContainer}>
              <Button
                variant="outlined"
                onClick={onObservationAdd}
                color="primary"
              >
                Add New Observation
              </Button>
            </Grid>
          </Grid>
          {mapIndexed((observation, index) => {
            return (
              <ObservationForm
                observationData={observation}
                key={`observation-${index}`}
                formData={observationRegistryForm}
                setFormValue={setObservationRegistryFormValue}
                observationIndex={index}
              />
            );
          }, R.propOr([], "observations", observationRegistryForm))}
        </Grid>
        <Grid item xs={8}>
          <Button variant="contained" onClick={onRegistrySave} color="primary">
            Save
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default ObservationRegistryForm;
