import DashboardIcon from "@material-ui/icons/Dashboard";
import GroupIcon from "@material-ui/icons/Group";
import SchoolIcon from "@material-ui/icons/School";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";
import SettingsIcon from "@material-ui/icons/Settings";
import StorageIcon from "@material-ui/icons/Storage";
import AdjustIcon from "@mui/icons-material/Adjust";
import GradingIcon from "@mui/icons-material/Grading";

export const GLOBAL_DATE_FORMAT = "DD-MM-YYYY";

export const DrawerList = [
  {
    id: "dashboard",
    label: "Dashboard",
    icon: DashboardIcon,
    routeTo: "/dashboard",
  },
  {
    label: "System Configuration",
    icon: SettingsIcon,
    routeTo: "/system-configurations",
    children: [
      {
        id: "lov",
        label: "List of Values",
        icon: DoubleArrowIcon,
        routeTo: "/system-configurations/lov",
      },
      {
        id: "role",
        label: "Roles",
        icon: DoubleArrowIcon,
        routeTo: "/system-configurations/roles",
      },
    ],
  },
  {
    label: "Users",
    icon: GroupIcon,
    routeTo: "/users",
    children: [
      {
        id: "teacher",
        label: "Teachers",
        icon: DoubleArrowIcon,
        routeTo: "/users/teachers",
      },
    ],
  },
  {
    label: "Master",
    icon: StorageIcon,
    routeTo: "/master/",
    children: [
      {
        id: "school",
        label: "Schools",
        icon: DoubleArrowIcon,
        routeTo: "/master-data/schools",
      },
      {
        id: "class",
        label: "Classes",
        icon: DoubleArrowIcon,
        routeTo: "/master-data/classes",
      },
      {
        id: "classsection",
        label: "Class Sections",
        icon: DoubleArrowIcon,
        routeTo: "/master-data/class-sections",
      },
      {
        id: "subject",
        label: "Subjects",
        icon: DoubleArrowIcon,
        routeTo: "/master-data/subjects",
      },
      // {
      //   label: "Teachers",
      //   icon: DoubleArrowIcon,
      //   routeTo: "/master-data/teachers",
      // },
      {
        id: "observationregister",
        label: "Observations",
        icon: DoubleArrowIcon,
        routeTo: "/master-data/observations",
      },

      // {
      //   label: "Policies",
      //   icon: DoubleArrowIcon,
      //   routeTo: "/master-data/policies",
      // }
    ],
  },
  {
    label: "Goal",
    icon: DashboardIcon,
    routeTo: "/goals/",
    children: [
      {
        id: "goalsettingform",
        label: "Set Goals",
        icon: AdjustIcon,
        routeTo: "/goals/set",
      },
      {
        id: "goalsettingreview",
        label: "Review Goal Setting",
        icon: GradingIcon,
        routeTo: "/goals/set/review",
      },
    ],
  },
  {
    label: "Academic Plan",
    icon: SchoolIcon,
    routeTo: "/academic-plans",
    children: [
      {
        id: "annualplan",
        label: "Annual Plans",
        icon: DoubleArrowIcon,
        routeTo: "/academic-plans/annual-plans",
      },
      // {
      //   label: "Tier Plans",
      //   icon: DoubleArrowIcon,
      //   routeTo: "/academic-plans/tier-plans",
      // },
      {
        id: "unitplan",
        label: "Unit Plans",
        icon: DoubleArrowIcon,
        routeTo: "/academic-plans/unit-plans",
      },
      {
        id: "lessonplan",
        label: "Lesson Plans",
        icon: DoubleArrowIcon,
        routeTo: "/academic-plans/lesson-plans",
      },
    ],
  },
];
