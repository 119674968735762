import React, { useEffect, useState } from "react";
import { Close } from "@material-ui/icons";
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  makeStyles,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import { getDetailsById, onAddValue } from "components/listOfValues/utils";
import { PAGES } from "state/slices/pageSlice";
import UnitPlanForm from "./UnitPlanForm";
import { FORM_TYPES } from "common/utils/formUtility";

const useStyles = makeStyles((theme) => ({
  cardWrapper: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  annualPlanCard: {
    marginBottom: "30px",
  },
  paginationContainer: {
    display: "flex",
    justifyContent: "end",
  },
  heading: {
    fontWeight: 800,
    fontSize: "18px",
    color: "#263657",
  },
  divider: {
    margin: "0 24px 24px 24px",
  },
  modalCloseBtn: {
    display: "flex",
    justifyContent: "end",
    padding: "20px",
  },
}));

function UnitPlanModal(props) {
  const { userRoles, setModalState, modalState, getPlanList, topics } = props;
  const classes = useStyles();
  const theme = useTheme();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down("sm"));

  const [state, setState] = useState({});
  const isEditForm = modalState.isEdit || false;
  const isUnitReadOnly = false;

  useEffect(() => {
    const { data } = modalState;
    if (isEditForm && data?.id) {
      getPlanDetails(data.id);
    }
  }, [isEditForm]);

  const getPlanDetails = async (id) => {
    const response = await getDetailsById("/unit-plan", id);
    const responseData = {
      ...response,
      gradeName: response?.curriculumContext?.gradeName || "",
      subject: {
        value: response.curriculumContext.subjectValue || "",
        name: response.curriculumContext.subjectName || "",
      },
    };
    setState(responseData || null);
  };

  const handleClose = () => {
    setModalState((prev) => ({
      isOpen: false,
      data: null,
      isEdit: false,
    }));
  };

  const handleSubmit = async (e, annualPlanStatus = "ACTIVE") => {
    const payload = {
      ...state,
      tierPlanId: modalState?.tierPlanId,
    };
    const response = await onAddValue(payload, PAGES.unitPlan);
    getPlanList?.();
    setModalState({
      isOpen: false,
      isEdit: false,
      data: null,
    });
  };

  const onUnitChange = (data) => {
    setState(data);
  };

  return (
    <Dialog
      maxWidth="md"
      fullWidth
      fullScreen={isSmallDevice}
      open={modalState?.isOpen}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <Grid container style={{ alignItems: "center" }}>
        <Grid item xs={10}>
          <DialogTitle id="form-dialog-title">
            <Typography variant="h6">{`${
              isEditForm ? "Edit" : "Add"
            } Unit Plan`}</Typography>
          </DialogTitle>
        </Grid>
        <Grid item xs={2} className={classes.modalCloseBtn}>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </Grid>
      </Grid>
      <DialogContent style={{ padding: "8px 25px" }}>
        <Typography variant="h6" style={{ marginBottom: "20px" }}>
          Unit(s) / Sub Units
        </Typography>
        <UnitPlanForm
          data={state || {}}
          tierIndex={0}
          unitIndex={0}
          onUnitChange={onUnitChange}
          formType={FORM_TYPES.UNIT_PLAN_FORM}
          isReadOnly={isUnitReadOnly}
          page={PAGES.unitPlan}
          topics={topics}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "end",
            pt: 2,
          }}
        >
          <Button
            color="primary"
            // disabled={isReadOnly || isNew}
            variant="contained"
            onClick={handleSubmit}
            data-html2canvas-ignore
          >
            Submit
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default UnitPlanModal;
