import {
  Card,
  CardContent,
  Divider,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { FILE_ICON } from "asset/images";
import { geAnnualPeriod } from "components/academicPlan/utils";
import { GLOBAL_DATE_FORMAT } from "constants/PageConstants";
import moment from "moment";
import React, { useState } from "react";
import StatusBlock from "../StatusBlock";
import MoreVerticalDot from "../MoreVerticalDot";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AnnualPlanModal from "./AnnualPlanModal";
import { planStatusService } from "components/listOfValues/utils";

const useStyles = makeStyles((theme) => ({
  cardBody: {
    margin: "1rem",
    background: "#F0F2FE",
    borderRadius: "15px",
    boxShadow: "none",
    marginTop: "50px",
  },
  cardContent: {
    padding: "26px 18px !important",
  },
  cardTitleDiv: {
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  cardTitle: {
    position: "relative",
    fontStyle: "normal",
    fontWeight: "800",
    fontSize: "18px",
    lineHeight: "22px",
    color: "#263657",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  fileIcon: {
    marginRight: "10px",
    alignSelf: "start",
    marginTop: "5px",
  },
  subTitle: {
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "8px",
    lineHeight: "10px",
    color: "#898EAC",
    marginLeft: "25px",
  },
  bodyText: {
    marginTop: "18px",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "12px",
    lineHeight: "14px",
    color: "#000000",
    display: "block",
  },
  bodySubText: {
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "14px",
  },
  statusBlock: {
    marginTop: "18px",
  },
  divider: {
    marginTop: "14px",
  },
  editIcon: {
    marginRight: "10px",
  },
  verticalDotsButton: {
    position: "absolute",
    right: "-15px",
    top: "-8px",
  },
  verticalDots: {
    margin: "0 6px",
  },
}));

function AnnualPlanDetailCard({
  data,
  privileges,
  userRoles,
  hideModification,
  getPlanDetails,
}) {
  const AllowedRoles = ["Administrator"];
  const classes = useStyles();
  const [modalState, setModalState] = useState({
    isOpen: false,
    data: null,
    isEdit: false,
  });
  const annualPeriodName = geAnnualPeriod(data.curriculumContext.annualPeriod);

  const isEditEnabled = (roles) => {
    let editEnabled = false;
    roles.forEach((role) => {
      if (AllowedRoles.includes(role)) {
        editEnabled = true;
      }
    });
    return editEnabled;
  };

  const isEditable = isEditEnabled(userRoles);

  const handleDeactivatePlan = async (id) => {
    const res = await planStatusService("annual-plans", id, "INACTIVE");
    if (res) {
      getPlanDetails();
    }
  };

  return (
    <>
      <Card className={classes.cardBody}>
        <CardContent className={classes.cardContent}>
          <div className={classes.cardTitleWrapper}>
            <Typography className={classes.cardTitle} variant="div">
              <div className={classes.cardTitleDiv}>
                <img
                  className={classes.fileIcon}
                  src={FILE_ICON}
                  alt="File Icon"
                />
                <span>{data?.name || ""}</span>
              </div>
              {privileges?.UPDATE && !hideModification && (
                <div className={classes.verticalDotsButton}>
                  <MoreVerticalDot
                    options={[
                      {
                        icon: (
                          <EditIcon
                            fontSize="small"
                            className={classes.editIcon}
                          />
                        ),
                        label: "Edit Annual Plan",
                        value: "Edit Annual Plan",
                        isDisabled:
                          data?.status?.toUpperCase() === "COMPLETED"
                            ? !isEditable
                            : !privileges?.UPDATE,
                      },
                      {
                        icon: (
                          <DeleteIcon
                            fontSize="small"
                            className={classes.editIcon}
                          />
                        ),
                        label: "Deactivate Annual Plan",
                        value: "Deactivate Annual Plan",
                        isDisabled: !privileges?.DELETE,
                      },
                    ]}
                    handleClick={(e, value) => {
                      e.stopPropagation();
                      if (value === "Deactivate Annual Plan") {
                        data?.id && handleDeactivatePlan(data.id);
                      } else {
                        setModalState((prev) => ({
                          ...prev,
                          isOpen: true,
                          isEdit: true,
                          data,
                        }));
                      }
                    }}
                    background={"#fff"}
                  />
                </div>
              )}
            </Typography>
          </div>
          <Typography
            className={classes.subTitle}
            marginLeft={"25px"}
            variant="span"
          >
            {annualPeriodName || ""}
          </Typography>
          <Divider className={classes.divider} />
          <Typography className={classes.bodyText} variant="div">
            Subject:{" "}
            <Typography className={classes.bodySubText} variant="div">
              {data?.curriculumContext?.subjectName || "-"}
            </Typography>
          </Typography>
          <Typography
            className={classes.bodyText}
            variant="span"
            marginRight={"34px"}
          >
            Class:{" "}
            <Typography className={classes.bodySubText} variant="div">
              {data?.curriculumContext?.gradeName || "-"}
            </Typography>
          </Typography>
          <Typography className={classes.bodyText} variant="div">
            Start Date:{" "}
            <Typography className={classes.bodySubText} variant="div">
              {data?.start
                ? moment(data.start).format(GLOBAL_DATE_FORMAT)
                : "-"}
            </Typography>
          </Typography>
          <Typography className={classes.bodyText} variant="span">
            End Date:{" "}
            <Typography className={classes.bodySubText} variant="div">
              {data?.end ? moment(data.end).format(GLOBAL_DATE_FORMAT) : "-"}
            </Typography>
          </Typography>
          <div className={classes.cardBottom}>
            <div className={classes.createdBy}>
              <Typography className={classes.bodyText} variant="div">
                Created by:
              </Typography>
              <Typography className={classes.bodySubText} variant="div">
                {data?.createdBy || "-"}
              </Typography>
            </div>
            <div className={classes.statusBlock}>
              <StatusBlock
                status={data?.status?.toUpperCase() || ""}
                isDetailCard={true}
              />
            </div>
          </div>
        </CardContent>
      </Card>
      {modalState.isOpen && (
        <AnnualPlanModal
          userRoles={userRoles}
          modalState={modalState}
          setModalState={setModalState}
          getAnnualPlanList={getPlanDetails}
        />
      )}
    </>
  );
}

export default AnnualPlanDetailCard;
