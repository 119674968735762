export const getClassRequestData = (globalFormData) => {
  const { className, classType } = globalFormData;
  return {
    className,
    classType,
  };
};

export const getClassFormData = (data) => {
  return {
    className: data.className,
    classType: data.classType,
  };
};

export const getClassNameFormValue = (data, classValue) => {
  return data
    .find((lovItem) => {
      return lovItem.name === "Grades";
    })
    ?.items.find((item) => item.value === classValue)?.name;
};
