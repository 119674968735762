import { Button, Grid, makeStyles, Typography } from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { REFRESH_ICON, REFRESH_ICON_DISABLED } from "asset/images";
import { getDetailsById } from "components/listOfValues/utils";
import Alert from "react-s-alert";

const useStyles = makeStyles((theme) => ({
  container: {
    margin: "0",
    marginBottom: "51px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "30px",
    },
  },
  paddingNone: {
    padding: "0px !important",
  },
  paddingXNone: {
    padding: "0px 10px 10px  !important",
  },
  changeView: {
    padding: "0px !important",
    marginLeft: "30px",
    marginRight: "40px",
  },
  addNewPlan: {
    padding: "0px !important",
    marginRight: "-16px",
  },
  addNewPlan1: {
    [theme.breakpoints.down("sm")]: {
      padding: "0px 6px !important",
    },
  },
  title: {
    fontStyle: "normal",
    fontWeight: "800",
    fontSize: "24px",
    color: "#263657",
  },
  inputFormControl: {
    height: "36px",
  },
  downloadIcon: {
    marginRight: "10px",
  },
}));

function TierPlanHeader({
  setModalState,
  getPlanDetails,
  planId,
  privileges,
  parentState,
  userRoles,
  isSmallDevice,
}) {
  const classes = useStyles();
  const AllowedRoles = ["Administrator", "Inspire Us-Core"];

  const onAddClick = () => {
    setModalState((prev) => ({ ...prev, isOpen: true, tierPlanId: planId }));
  };

  const refreshConsent = async () => {
    await getDetailsById("/tier-plan/refresh-consents", planId);
    Alert.success("Consent for teachers refreshed for all unit plans");
    getPlanDetails?.();
  };

  const isEditEnabled = (roles) => {
    let editEnabled = false;
    roles.forEach((role) => {
      if (AllowedRoles.includes(role)) {
        editEnabled = true;
      }
    });
    return editEnabled;
  };

  const isRefreshEnabled =
    isEditEnabled(userRoles) &&
    parentState?.status?.toUpperCase() === "COMPLETED";

  return (
    <Grid
      container
      spacing={3}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      className={classes.container}
    >
      <Grid
        item
        xs={12}
        md={6}
        style={{ padding: "0px 10px 10px 5px" }}
        className={isSmallDevice ? "" : classes.paddingNone}
      >
        <Typography className={classes.title} variant="div">
          <span>Tier Plan</span>
        </Typography>
      </Grid>
      <Grid
        item
        container
        spacing={3}
        md={6}
        direction="row"
        justifyContent={isSmallDevice ? "flex-start" : "flex-end"}
        alignItems="center"
        className={isSmallDevice ? classes.paddingXNone : classes.paddingNone}
      >
        <Grid item className={classes.addNewPlan1}>
          <Button
            variant="outline"
            color="primary"
            style={{ paddingLeft: "0px" }}
            className={classes.addIconButton}
            onClick={refreshConsent}
            disabled={!isRefreshEnabled}
            aria-label="Refresh consents for all unit plans of this tier plan"
          >
            <img
              src={isRefreshEnabled ? REFRESH_ICON : REFRESH_ICON_DISABLED}
              alt="download icon"
              className={classes.downloadIcon}
            />
            Refresh Unit Plan Consents
          </Button>
        </Grid>
        <Grid item className={classes.addNewPlan1}>
          <Button
            variant="contained"
            color="primary"
            className={classes.addIconButton}
            onClick={onAddClick}
            startIcon={<AddCircleIcon className="addIcon" />}
            disabled={
              !(
                parentState?.status?.toUpperCase() === "COMPLETED" ||
                privileges.CREATE
              )
            }
          >
            Add Unit Plan
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default TierPlanHeader;
