import React, { useState, useEffect } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import { EntityMap } from "util/roleUtils";
import {
  readGoalConfigurations,
  readGoalTimelineConfigurations,
} from "components/listOfValues/utils";
import { colors } from "theme/colors";
import "../../../components/academicPlanDetails/styles.css";
import withPrivilege from "common/hocs/withPrivilege";
import GoalConfigurationHeader from "./GoalConfigurationHeader";
import GoalConfigurationModal from "./GoalConfigurationModal";
import GoalTimelineModal from "./GoalTimelineModal";
import GoalConfigurationCard from "./GoalConfigurationCard";
import CustomizedTabs from "components/academicPlanDetails/CustomizedTabs";
import GoalTimelineConfigurationCard from "./GoalTimelineConfigurationCard";

const useStyles = makeStyles((theme) => ({
  cardWrapper: {
    flexWrap: "wrap",
  },
  annualPlanCard: {
    marginBottom: "30px",
  },
  noDataAvailable: {
    margin: "20px",
  },
  gridContainer: {
    flexWrap: "nowrap",
  },
  planSection: {
    height: "100%",
    minHeight: "100vh",
    padding: "47px 51px",
    backgroundColor: colors.gray5,
  },
}));

function ManageGoalReviewConfiguration({
  privileges,
  userRoles,
  setModalState,
  modalState,
  planName,
}) {
  const classes = useStyles();
  const [state, setState] = useState([]);
  const [goalTimeline, setGoalTimeline] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  useEffect(() => {
    getGoalConfigurations();
  }, []);

  const getGoalConfigurations = async () => {
    const data = await readGoalConfigurations();
    const goalTimelineData = await readGoalTimelineConfigurations();
    setState(data);
    setGoalTimeline(goalTimelineData);
  };

  return (
    <>
      <GoalConfigurationHeader
        changeView={planName}
        privileges={privileges}
        setModalState={setModalState}
      />
      <Grid
        container
        direction="row"
        className={classes.cardWrapper}
        spacing={4}
      >
        <CustomizedTabs
          setActiveTab={setActiveTab}
          tabList={[`Goal Configuration`, `Goal Timeline`]}
        />
        {activeTab === 0 && (
          <>
            {state?.length ? (
              state?.map((configuration, index) => (
                <Grid item key={index} md="4" xs="12">
                  <GoalConfigurationCard data={configuration} />
                </Grid>
              ))
            ) : (
              <div className={classes.noDataAvailable}>No Data available</div>
            )}
          </>
        )}
        {activeTab === 1 && (
          <>
            {goalTimeline?.length ? (
              goalTimeline?.map((timeline, index) => (
                <Grid item key={index} md="4" xs="12">
                  <GoalTimelineConfigurationCard data={timeline} />
                </Grid>
              ))
            ) : (
              <div className={classes.noDataAvailable}>No Data available</div>
            )}
          </>
        )}
      </Grid>
      {modalState?.isConfigurationModelOpen && (
        <GoalConfigurationModal
          userRoles={userRoles}
          modalState={modalState}
          setModalState={setModalState}
          postSubmit={getGoalConfigurations}
        />
      )}
      {modalState?.isTimelineModelOpen && (
        <GoalTimelineModal
          userRoles={userRoles}
          modalState={modalState}
          setModalState={setModalState}
          postSubmit={getGoalConfigurations}
        />
      )}
    </>
  );
}

export default withPrivilege(
  ManageGoalReviewConfiguration,
  EntityMap.goalconfiguration
);
