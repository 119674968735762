import { Card, CardContent } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import ManageObservationsForm from "common/forms/ManageObservationsForm";
import React from "react";

const useStyle = makeStyles((theme) => ({
  card: {
    padding: "1rem 1rem 0rem 1rem",
    border: "1px solid #cccccc",
    boxShadow: "none",
    marginBottom: "1rem",
  },
}));
const ObservationForm = ({
  formData,
  setFormValue,
  observationIndex,
  observationData,
}) => {
  const classes = useStyle();
  return (
    <Card className={classes.card}>
      <CardContent style={{ paddingBottom: 0 }}>
        <ManageObservationsForm
          observationIndex={observationIndex}
          observationRegistry={formData}
          setObservationRegistry={setFormValue}
        />
      </CardContent>
    </Card>
  );
};

export default ObservationForm;
