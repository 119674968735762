import React, { useState } from "react";
import {
  makeStyles,
  Typography,
  Grid,
  IconButton,
  Card,
  CardContent,
} from "@material-ui/core";
import { colors } from "theme/colors";
import EditIcon from "@material-ui/icons/Edit";
import FormDialog from "common/components/FormDialog/FormDialog";
import { saveLOV } from "util/APIUtils";
import Alert from "react-s-alert";
import {
  completementLOVStatus,
  getLOVRequestData,
} from "util/APIPayloadUtils.js/LOVUtils";
import { LOVFormState } from "state/globalForms/initialStates";
import { updateForm } from "state/slices/globalFormSlice";
import { useDispatch } from "react-redux";
import * as R from "ramda";
import { formatDateString } from "util/time";
import { setLOVActiveStatus } from "./utils";

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: "0.75rem",
    overflowWrap: "break-word",
  },
  root: {
    minHeight: "180px",
    maxHeight: "300px",
    overflow: "auto",
    border: `1px solid #d6d6d6`,
    background: "#f0f0f0",
    borderRadius: 6,
    boxShadow: "none",
    "&:hover, &:focus": {
      boxShadow: "4px 4px #e5e9ff",
      border: `1px solid ${colors.blue1}`,
      background: "#f4f6ff",
    },
  },
  editIconContainer: {
    display: "flex",
    alignItems: "baseline",
    justifyContent: "flex-end",
  },
  editIconBtn: {
    padding: "5px",
  },
  editIcon: {
    height: "1.2rem",
    width: "1.2rem",
    fontSize: "1.5rem",
    color: "rgba(0, 0, 0, 0.60)",
  },
  activeLabel: {
    color: colors.green,
  },
  deactivateLabel: {
    color: colors.red,
  },
  status: {
    marginTop: "1rem",
  },
}));

const LOVView = ({ data, getList, onEditClick, privileges }) => {
  const classes = useStyles();
  const [openForm, setFormOpen] = useState(false);

  const dispatch = useDispatch();
  const saveData = async (payload) => {
    try {
      const response = await saveLOV(payload);
      Alert.success("Value added successfully");
    } catch (error) {
      Alert.error(
        (error && error.message) ||
          "Oops! Something went wrong. Please try again!"
      );
    }
  };
  const onLOVSave = (lovFormData) => {
    const requestPayload = getLOVRequestData(lovFormData);
    saveData(requestPayload);
    dispatch(updateForm({ formType: "LOVForm", formData: LOVFormState }));
    setFormOpen(false);
  };

  const onCardEditClick = () => {
    onEditClick(data);
  };

  const onActiveClick = async () => {
    const updatedData = R.pipe(
      R.assoc("status", completementLOVStatus(data.status)),
      R.assoc("version", data.version)
    )({});
    updatedData["version"] = 0;
    const response = await setLOVActiveStatus(updatedData, data.id);
    setFormOpen(false);
    if (true) {
      getList();
    }
  };

  return (
    <Grid item xs={12} md={4}>
      <div style={{ marginBottom: "1rem" }}>
        <Card className={classes.root}>
          <CardContent>
            <Grid container>
              <Grid item xs={10} className={classes.title}>
                <Typography variant="h6" color="primary">
                  {data.name}
                </Typography>
                <Typography variant="subtitle1">{data.description}</Typography>
              </Grid>
              <Grid item xs={2} className={classes.editIconContainer}>
                {R.propOr(false, "UPDATE", privileges) && (
                  <IconButton
                    color="primary"
                    className={classes.editIconBtn}
                    onClick={onCardEditClick}
                  >
                    <EditIcon className={classes.editIcon} />
                  </IconButton>
                )}
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="subtitle2">
                created on: {formatDateString(R.propOr("", "createdOn", data))}{" "}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="subtitle2">
                last updated on:{" "}
                {formatDateString(R.propOr("", "lastUpdatedOn", data))}
              </Typography>
            </Grid>

            <Grid item xs={12} className={classes.status}>
              <Typography
                className={
                  data.status === "ACTIVE"
                    ? classes.activeLabel
                    : classes.deactivateLabel
                }
              >
                <b>Status: {data.status}</b>{" "}
              </Typography>
            </Grid>
          </CardContent>
        </Card>
        <FormDialog
          isEdit
          open={openForm}
          onClose={() => setFormOpen(false)}
          title="Edit List"
          formRenderer="LOVForm"
          formType="LOVForm"
          onSave={onLOVSave}
          showActive={true}
          onActiveClick={onActiveClick}
        />
      </div>
    </Grid>
  );
};

export default LOVView;
