import React from "react";
import { Grid, makeStyles } from "@material-ui/core";
import CustomizedTabs from "../../../academicPlanDetails/CustomizedTabs";
import ClassesCard from "./ClassesCard";
import * as R from "ramda";

const useStyles = makeStyles((theme) => ({
  cardWrapper: {
    flexWrap: "wrap",
  },
  annualPlanCard: {
    marginBottom: "30px",
  },
  noDataAvailable: {
    margin: "20px",
  },
  paginationContainer: {
    display: "flex",
    justifyContent: "end",
  },
}));

function ClassesDetailsPage(props) {
  const { parentState, privileges } = props;
  const classes = useStyles();

  return (
    <>
      <Grid
        container
        direction="row"
        className={classes.cardWrapper}
        spacing={4}
      >
        <CustomizedTabs
          tabList={[`Classes (${parentState?.grades?.length || 0})`]}
        />
        {privileges?.READ &&
          (parentState?.grades?.length ? (
            parentState?.grades?.map((cls, index) => (
              <ClassesCard data={cls} key={index} {...props} />
            ))
          ) : (
            <div className={classes.noDataAvailable}>No Data available</div>
          ))}
      </Grid>
    </>
  );
}

export default ClassesDetailsPage;
