import { Grid, makeStyles } from "@material-ui/core";
import { useState } from "react";
import { colors } from "theme/colors";
import { planListComponents, sideBarComponent } from "./index";
import "./styles.css";
import { AnnualPlanInitialState } from "state/globalForms/initialStates";
import BreadCrumb from "./BreadCrumb";

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    // flexWrap: "nowrap",
  },
  filterSection: {
    background: "white",
  },
  breadcrumb: {
    [theme.breakpoints.down("md")]: {
      margin: "1.5rem 0 0 1.2rem",
    },
  },
  planSection: {
    height: "100%",
    minHeight: "100vh",
    padding: "47px 51px",
    backgroundColor: colors.gray5,
    [theme.breakpoints.down("md")]: {
      padding: "1.5rem",
    },
  },
}));

function AcademicPlanDetails(props) {
  const { planName } = props;
  const classes = useStyles();
  const [modalState, setModalState] = useState({
    isOpen: false,
    data: AnnualPlanInitialState,
    isEdit: false,
  });

  const [filterState, setFilterState] = useState({
    annualPeriod: "",
    status: "",
    gradeName: "",
    subject: "",
  });

  const PlanComponent = planListComponents[planName];
  const SideBarComponent = sideBarComponent[planName];

  return (
    <Grid container className={classes.gridContainer}>
      <Grid className={classes.filterSection} md="3" xs="12">
        <div className={classes.breadcrumb}>
          <BreadCrumb planName={planName} />
        </div>
        <SideBarComponent
          filterState={filterState}
          setFilterState={setFilterState}
          planName={planName}
        />
      </Grid>
      <Grid className={classes.planSection} md="9" xs="12">
        <PlanComponent
          {...props}
          modalState={modalState}
          setModalState={setModalState}
          filterState={filterState}
        />
      </Grid>
    </Grid>
  );
}

export default AcademicPlanDetails;
