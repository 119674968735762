import {
  Box,
  Chip,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
  Grid,
  Paper,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import * as R from "ramda";
import { useDispatch, useSelector } from "react-redux";
import { getPageList } from "components/listOfValues/utils";
import { PAGES, setPageListItems } from "state/slices/pageSlice";
import { updateForm } from "state/slices/globalFormSlice";
import SubjectMultiSelectDropDown from "common/components/MultiSelectDropDown/SubjectMultiSelectDropDown";

import {
  LOVApiKeys,
  LOVDataKeys,
  LOVSelectKeys,
} from "common/components/SelectDropDown/SelectDropDownUtils";

import { removeWhiteSpace } from "common/utils/composition";
import { makeStyles, styled } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  inputFormControl: {
    marginBottom: "2rem",
  },
  inputLabel: {
    top: "-17px",
  },
  inputLabel1: {
    top: "-17px",
    left: "-14px",
  },
  chip: {
    color: "#263657",
    // fontWeight: 500,
    backgroundColor: "rgb(38 54 87 / 12%)",
  },
  chip1: {
    color: "#fff",
    // border: "2px solid #263657",
    // fontWeight: 500,
    backgroundColor: "#263657",
  },
}));

const getSelectedRoles = (selectedRoles) => {
  return R.map((id) => {
    return {
      roleId: id,
    };
  })(selectedRoles);
};

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const getInitialRoles = (formData) =>
  R.map((role) => role.roleId)(R.pathOr([], ["roles"], formData));

const RoleSelect = ({ isReadOnly, formType }) => {
  const dispatch = useDispatch();
  const listItems = useSelector((state) => state.pages.roles.items);
  const formData = useSelector((state) => state.globalForm[formType]);
  const [selectList, setSelectList] = useState(listItems);
  const [selectedRoles, setSelectedRoles] = useState(getInitialRoles(formData));
  const [roleClassMap, setRoleClassMap] = useState({});

  const populateRoleClassesMap = () => {
    const _map = {};
    formData.roles.forEach((role) => {
      _map[role.roleId] = {};
      role.roleClassXrefs?.forEach((xref) => {
        _map[role.roleId][xref.className] = [];
        xref.subjects?.forEach((subject) => {
          _map[role.roleId][xref.className].push(subject.subjectName);
        });
      });
    });
    setRoleClassMap(_map);
  };

  useEffect(() => {
    populateRoleClassesMap();
  }, [formData]);

  const onChange = (e) => {
    setSelectedRoles(e.target.value);
    const selRoles = ([] = e.target.value);
    let __roles = formData.roles;
    const existingRoles = [];
    __roles.forEach((existingRole) => {
      existingRoles.push(existingRole.roleId);
    });
    let deletedRoles = existingRoles.filter((x) => !selRoles.includes(x));
    let newRoles = selRoles.filter((x) => !existingRoles.includes(x));
    const _updatedRoles = getSelectedRoles(newRoles);
    __roles = __roles.filter((x) => !deletedRoles.includes(x.roleId));
    if (_updatedRoles.length > 0) {
      __roles = [...__roles, ..._updatedRoles];
    }
    const updatedFormData = R.assocPath(["roles"], __roles, formData);

    dispatch(updateForm({ formType, formData: updatedFormData }));
  };

  const onGradeSelect = (values, roleId) => {
    const roleClassXrefs = [];
    const cloneValues = [...values];
    formData.roles.forEach((role) => {
      if (role.roleId === roleId) {
        cloneValues.forEach(function (value, index, object) {
          if (role.roleClassXrefs) {
            role.roleClassXrefs.forEach((roleclass) => {
              if (roleclass.id && roleclass.className === value) {
                roleClassXrefs.push(roleclass);
                values = values.filter((val) => val !== value);
              }
            });
          }
        });

        values.forEach((value) => {
          const newXref = {};
          newXref["className"] = value;
          roleClassXrefs.push(newXref);
        });
      }
    });

    const updatedRoles = formData.roles.map((role) =>
      role.roleId === roleId
        ? { ...role, roleClassXrefs: roleClassXrefs }
        : role
    );

    const updatedFormData = R.assocPath(["roles"], updatedRoles, formData);
    dispatch(updateForm({ formType, formData: updatedFormData }));
  };

  const onSubjectSelect = (values, className, roleId) => {
    const classSubjectXrefs = [];
    values.forEach((value) => {
      const newXref = {};
      newXref["subjectName"] = value;
      classSubjectXrefs.push(newXref);
    });

    const updatedRole = formData.roles
      .find((role) => role.roleId === roleId)
      .roleClassXrefs.map((xref) =>
        xref.className === className
          ? { ...xref, subjects: classSubjectXrefs }
          : xref
      );

    const updatedRoles = formData.roles.map((role) =>
      role.roleId === roleId ? { ...role, roleClassXrefs: updatedRole } : role
    );

    const updatedFormData = R.assocPath(["roles"], updatedRoles, formData);
    dispatch(updateForm({ formType, formData: updatedFormData }));
  };

  const getRoles = async () => {
    const response = await getPageList({ searchKey: "", page: PAGES.roles });
    dispatch(
      setPageListItems({
        page: PAGES.roles,
        itemList: response.content,
      })
    );
  };
  const classes = useStyles();

  useEffect(() => {
    if (R.isEmpty(listItems)) {
      getRoles();
    }
    setSelectList(listItems);
  }, [listItems]);

  if (!roleClassMap) return null;

  return (
    <Box>
      <Grid item xs={12}>
        <Select
          variant="outlined"
          margin="normal"
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          onChange={onChange}
          multiple={true}
          placeholder="Select roles"
          fullWidth
          disabled={isReadOnly}
          value={selectedRoles}
          displayEmpty
          MenuProps
          inputProps={{ "aria-label": "Without label" }}
        >
          <MenuItem value="" disabled>
            <span style={{ color: "#bbb" }}>Select roles</span>
          </MenuItem>
          {R.map((item) => {
            return <MenuItem value={item.id}>{item.name}</MenuItem>;
          }, selectList)}
        </Select>
      </Grid>
      <Box style={{ marginTop: "2rem" }}>
        {R.map((roleId) => {
          return (
            <Grid container xs={12}>
              <Grid item xs={3}>
                <Chip
                  key={roleId}
                  variant="small"
                  className={classes.chip1}
                  label={R.propOr(
                    "",
                    "name",
                    R.find(R.propEq("id", roleId))(selectList)
                  )}
                />
              </Grid>
              <Grid item xs={9}>
                <FormControl className={classes.inputFormControl}>
                  <InputLabel style={{ top: "-24px" }}>Grades</InputLabel>
                  <SubjectMultiSelectDropDown
                    margin="normal"
                    defaultSelected={
                      roleClassMap[roleId]
                        ? Object.keys(roleClassMap[roleId])
                        : []
                    }
                    selectKey={LOVSelectKeys.CLASS}
                    lovKey={LOVApiKeys.CLASS}
                    dataKey={LOVDataKeys.CLASS}
                    onSelect={(e) => onGradeSelect(e, roleId)}
                  />
                  {/* <MultiSelectDropDown
                    margin="normal"
                    placeholder={"Enter grades"}
                    defaultSelected={
                      roleClassMap[roleId]
                        ? Object.keys(roleClassMap[roleId])
                        : []
                    }
                    apiKey={LOVApiKeys.CLASS}
                    dataKey={LOVDataKeys.CLASS}
                    onSelect={(e) => onGradeSelect(e, roleId)}
                  /> */}
                </FormControl>
              </Grid>
              {
                <Box sx={{ paddingTop: "1rem", width: "100%" }}>
                  {roleClassMap?.[roleId] &&
                    Object.keys(roleClassMap?.[roleId])?.map((cl) => {
                      return (
                        <Grid container xs={12}>
                          <Grid item xs={3}>
                            <Chip
                              variant="small"
                              key={cl}
                              className={classes.chip}
                              label={cl}
                            />
                          </Grid>
                          <Grid item xs={9}>
                            <FormControl className={classes.inputFormControl}>
                              <InputLabel
                                style={{ top: "-24px" }}
                                id="demo-multiple-name-label"
                              >
                                Subject
                              </InputLabel>
                              <SubjectMultiSelectDropDown
                                margin="normal"
                                defaultSelected={roleClassMap[roleId][cl] ?? []}
                                selectKey={LOVSelectKeys.SUBJECT}
                                lovKey={`SubjectClass${removeWhiteSpace(cl)}`}
                                dataKey={LOVDataKeys.SUBJECT}
                                onSelect={(e) => onSubjectSelect(e, cl, roleId)}
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                      );
                    })}
                </Box>
              }
            </Grid>
          );
        })(selectedRoles)}
      </Box>
    </Box>
  );
};

export default RoleSelect;
