import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  makeStyles,
} from "@material-ui/core";
import DisplaySubUnits from "common/components/DisplaySubUnits";
import SelectDropDown from "common/components/SelectDropDown/SelectDropDown";
import {
  LOVApiKeys,
  LOVDataKeys,
  LOVDisplayKeys,
  LOVSelectKeys,
} from "common/components/SelectDropDown/SelectDropDownUtils";
import React from "react";
import { useSelector } from "react-redux";
import { PAGES } from "state/slices/pageSlice";

const useStyles = makeStyles({
  editFields: {
    margin: "1.5rem 0",
    border: "1px solid #26365785",
    borderRadius: 4,
    padding: "1rem",
    background: "#fff",
  },
  inputFormControl: {
    marginTop: "1.5rem",
  },
  inputLabel: {
    top: "-17px",
  },
  inputLabel1: {
    top: "-17px",
    left: "-14px",
  },
  placeholderValue: {
    color: "#bbb",
  },
});

function LessonPlanForm({ state, handleChange, subUnits }) {
  const classes = useStyles();
  const dropdownListData = useSelector(
    (state) => state?.pages?.[PAGES.schools]?.items || []
  );

  return (
    <div>
      <Grid container spacing={1} className={classes.editFields}>
        <Grid item xs={12} md={6} style={{ alignSelf: "center" }}>
          <FormControl variant="outlined" className={classes.inputFormControl}>
            <InputLabel
              className={classes.inputLabel1}
              id="demo-simple-select-outlined-label"
            >
              School
            </InputLabel>
            <SelectDropDown
              margin="normal"
              variant="outlined"
              selectKey={LOVSelectKeys.SCHOOL}
              apiKey={LOVApiKeys.SCHOOL}
              dataKey={LOVDataKeys.SCHOOL}
              displayKey={LOVDisplayKeys.SCHOOL}
              secondaryDataKey={"campus"}
              selectedValue={
                dropdownListData.find(
                  (item) =>
                    item?.schoolCode === state?.curriculumContext?.schoolCode
                ) || {}
              }
              page={PAGES.schools}
              customOnChange={(selectedValue) =>
                handleChange(
                  ["curriculumContext", "schoolCode"],
                  selectedValue.schoolCode
                )
              }
              // isDisabled={isSchoolDisable}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={3}>
          <FormControl className={classes.inputFormControl}>
            <InputLabel className={classes.inputLabel}>Start Date</InputLabel>
            <TextField
              variant="outlined"
              id="date"
              margin="normal"
              name="start"
              placeholder={"Enter start date"}
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => handleChange(["start"], e.target.value)}
              value={state.start || ""}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={3}>
          <FormControl className={classes.inputFormControl}>
            <InputLabel className={classes.inputLabel}>End Date</InputLabel>
            <TextField
              variant="outlined"
              id="date"
              margin="normal"
              name="end"
              placeholder={"Enter end date"}
              type="date"
              sx={{ width: 220 }}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => handleChange(["end"], e.target.value)}
              value={state.end || ""}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} style={{ alignSelf: "center" }}>
          <FormControl variant="outlined" className={classes.inputFormControl}>
            <InputLabel
              className={classes.inputLabel1}
              id="demo-multiple-name-label"
            >
              Sub Units
            </InputLabel>
            <Select
              margin="normal"
              variant="outlined"
              labelId="demo-multiple-name-label"
              id="demo-multiple-name"
              multiple
              placeholder="subUnits"
              value={state?.subUnits?.map((unit) => unit.name) || []}
              onChange={(e) => {
                const value = subUnits.filter((item) =>
                  e?.target?.value?.includes(item?.name)
                );
                handleChange(["subUnits"], value);
              }}
              name="subUnits"
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
            >
              <MenuItem value="" disabled>
                <span className={classes.placeholderValue}>
                  Select sub units
                </span>
              </MenuItem>
              {subUnits.map((unit) => (
                <MenuItem key={unit.name} value={unit.name}>
                  {unit.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl className={classes.inputFormControl}>
            <InputLabel className={classes.inputLabel}>Lesson Name</InputLabel>
            <TextField
              variant="outlined"
              name="name"
              margin="normal"
              placeholder="Enter lesson name"
              onChange={(e) => handleChange(["name"], e.target.value)}
              value={state.name || ""}
            />
          </FormControl>
        </Grid>

        <Grid item xs={10}>
          <DisplaySubUnits
            subUnits={state?.subUnits || []}
            isWhiteIcon={false}
          />
        </Grid>
      </Grid>
    </div>
  );
}

export default LessonPlanForm;
