import {
  FormControl,
  makeStyles,
  Typography,
  InputLabel,
  RadioGroup,
  FormLabel,
  FormControlLabel,
  Radio,
  Grid,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import * as R from "ramda";
import SelectDropDown from "common/components/SelectDropDown/SelectDropDown";
import {
  LOVApiKeys,
  LOVDataKeys,
  LOVSelectKeys,
  LOVDisplayKeys,
} from "common/components/SelectDropDown/SelectDropDownUtils";
import StatusInput from "common/components/StatusInput/StatusInput";
import { useDispatch, useSelector } from "react-redux";
import { updateForm } from "state/slices/globalFormSlice";
import SectionTeacher from "common/components/SectionTeacher.js/SectionTeacher";
import { PAGES } from "state/slices/pageSlice";
import LOVSelect from "common/components/LOVSelect/LOVSelect";
import { removeWhiteSpace } from "common/utils/composition";

const useStyles = makeStyles((theme) => ({
  inputFormControl: {
    marginBottom: "2.5rem",
  },
  inputLabel1: {
    top: "-17px",
    left: "-14px",
  },
}));

const ManageSubjectForm = ({ formType, isEdit }) => {
  const classes = useStyles();
  const subjectFormData = useSelector((state) => state.globalForm[formType]);
  const [formData, setformData] = useState(subjectFormData);
  const dispatch = useDispatch();
  const onChange = (event) => {
    const [value, name] = R.paths(
      [
        ["target", "value"],
        ["target", "name"],
      ],
      event
    );
    const updatedformData = R.assoc(name, value, formData);
    setformData(updatedformData);
    dispatch(updateForm({ formType, formData: updatedformData }));
  };

  useEffect(() => {
    dispatch(updateForm({ formType, formData: subjectFormData }));
    setformData(subjectFormData);
  }, [dispatch, formType, subjectFormData]);

  return (
    <form style={{ marginTop: "1.5rem" }}>
      <FormControl variant="outlined" className={classes.inputFormControl}>
        <InputLabel
          className={classes.inputLabel1}
          id="demo-simple-select-outlined-label"
        >
          School
        </InputLabel>
        <SelectDropDown
          isDisabled={isEdit}
          formType={formType}
          selectKey={LOVSelectKeys.SCHOOL}
          apiKey={LOVApiKeys.SCHOOL}
          dataKey={LOVDataKeys.SCHOOL}
          secondaryDataKey={"campus"}
          selectedValue={R.propOr({}, "school", formData)}
          displayKey={LOVDisplayKeys.SCHOOL}
          page={PAGES.schools}
          dependentFields={[LOVApiKeys.TEACHER, LOVApiKeys.CLASS_SECTION]}
          queryParams={{
            schoolCode: R.pathOr("", ["school", "schoolCode"], formData),
            className: R.pathOr("", ["gradeName"], formData),
          }}
        />
      </FormControl>
      <FormControl variant="outlined" className={classes.inputFormControl}>
        <InputLabel
          className={classes.inputLabel1}
          id="demo-simple-select-outlined-label"
        >
          Class Name
        </InputLabel>
        <SelectDropDown
          isDisabled={isEdit}
          formType={formType}
          selectKey={LOVSelectKeys.CLASS}
          apiKey={LOVApiKeys.CLASS}
          dataKey={LOVDataKeys.CLASS}
          selectedValue={R.propOr({}, "className", formData)}
          displayKey={LOVDisplayKeys.CLASS}
          page={PAGES.classes}
          dependentFields={[LOVApiKeys.TEACHER, LOVApiKeys.CLASS_SECTION]}
          queryParams={{
            schoolCode: R.pathOr("", ["school", "schoolCode"], formData),
            className: R.pathOr("", ["className", "className"], formData),
          }}
        />
      </FormControl>
      <FormControl variant="outlined" className={classes.inputFormControl}>
        <InputLabel
          className={classes.inputLabel1}
          id="demo-simple-select-outlined-label"
        >
          Subject
        </InputLabel>
        <LOVSelect
          formType={formType}
          selectKey={LOVSelectKeys.SUBJECT}
          dataKey={LOVDataKeys.SUBJECT}
          lovKey={`SubjectClass${removeWhiteSpace(
            R.pathOr("", ["className", "className"], formData)
          )}`}
          value={R.pathOr({}, ["subjectValue"], formData)}
          setObj={!isEdit}
          isReadOnly={isEdit}
        />
      </FormControl>
      <SectionTeacher
        teachers={R.propOr([], "teachers", subjectFormData)}
        formType={formType}
        queryParams={{
          schoolCode: R.pathOr("", ["school", "schoolCode"], formData),
          className: R.pathOr("", ["className", "className"], formData),
        }}
      />
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <FormControl component="fieldset">
            <Typography color="primary" variant="subtitle2">
              Type
            </Typography>

            <RadioGroup
              aria-label="type"
              name="subjectType"
              value={formData.subjectType}
              onChange={onChange}
            >
              <div style={{ display: "flex", margin: "left" }}>
                <FormControlLabel
                  value="COMPULSARY"
                  control={<Radio />}
                  label="Compulsory"
                />
                <FormControlLabel
                  value="OPTIONAL"
                  control={<Radio />}
                  label="Optional"
                />
              </div>
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          {!isEdit && <StatusInput formType={formType} />}
        </Grid>
      </Grid>
    </form>
  );
};
export default ManageSubjectForm;
