import React from "react";
import {
  makeStyles,
  Typography,
  Box,
  Card,
  CardContent,
} from "@material-ui/core";
import { CHALKBOARD_ICON, FILE_ICON } from "asset/images";

const useStyles = makeStyles((theme) => ({
  cardBody: {
    width: "100%",
    background: "#FFFFFF",
    borderRadius: "14px !important",
  },
  cardContent: {
    padding: "26px 18px !important",
  },
  cardTitle: {
    fontStyle: "normal",
    fontWeight: "800",
    fontSize: "18px",
    lineHeight: "22px",
    color: "#263657",
    display: "flex",
    alignItems: "center",
  },
  fileIcon: {
    marginRight: "10px",
  },
  cardTitleWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  cardBodyContent: {
    marginTop: "18px",
  },
  bodyText: {
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "12px",
    lineHeight: "14px",
    color: "#000000",
  },
  editIcon: {
    marginRight: "10px",
  },
}));

const GoalConfigurationCard = ({ data }) => {
  const classes = useStyles();

  return (
    <>
      <Box sx={{ minWidth: 275 }} style={{ cursor: "pointer" }}>
        <Card variant="outlined" className={classes.cardBody}>
          <CardContent className={classes.cardContent}>
            <div className={classes.cardTitleWrapper}>
              <Typography className={classes.cardTitle} variant="div">
                <img
                  className={classes.fileIcon}
                  src={FILE_ICON}
                  alt="File Icon"
                />
                <span>{data.applicableRole.name}</span>
              </Typography>
            </div>

            <div className={classes.cardBodyContent}>
              <img
                className={classes.fileIcon}
                src={CHALKBOARD_ICON}
                alt="Chalkboard Icon"
              />
              <Typography className={classes.bodyText} variant="span">
                Goal Approver: {data.goalApproverRole.name}
              </Typography>
            </div>
            <div className={classes.cardBodyContent}>
              <img
                className={classes.fileIcon}
                src={CHALKBOARD_ICON}
                alt="Chalkboard Icon"
              />
              <Typography className={classes.bodyText} variant="span">
                Goal Reviewer(s):{" "}
                {data.reviewerRoles
                  .map((role) => role.goalReviewerRole.name)
                  .join(", ")}
              </Typography>
            </div>
            <div className={classes.cardBodyContent}>
              <img
                className={classes.fileIcon}
                src={CHALKBOARD_ICON}
                alt="Chalkboard Icon"
              />
              <Typography className={classes.bodyText} variant="span">
                Review Approver: {data.reviewApproverRole.name}
              </Typography>
            </div>
          </CardContent>
        </Card>
      </Box>
    </>
  );
};

export default GoalConfigurationCard;
