import React, { useEffect, useState } from "react";
import { Close } from "@material-ui/icons";
import { FORM_TYPES } from "common/utils/formUtility";
import { useSelector } from "react-redux";
import * as R from "ramda";
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  makeStyles,
  Divider,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import { annualFormPayloadSelector } from "components/academicPlan/utils";
import {
  getDetailsById,
  onAddValue,
  saveUserRoleMapping,
} from "components/listOfValues/utils";
import { PAGES } from "state/slices/pageSlice";
import RolesForm from "./RolesForm";

const useStyles = makeStyles((theme) => ({
  cardWrapper: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  annualPlanCard: {
    marginBottom: "30px",
  },
  paginationContainer: {
    display: "flex",
    justifyContent: "end",
  },
  heading: {
    fontWeight: 800,
    fontSize: "18px",
    color: "#263657",
  },
  divider: {
    margin: "0 24px 24px 24px",
  },
}));

function RolesModal(props) {
  const { parentState, userRoles, setModalState, modalState, getStaffDetails } =
    props;
  const classes = useStyles();
  const theme = useTheme();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down("sm"));

  const [state, setState] = useState(modalState.data);
  const isEditForm = modalState.isEdit || false;

  // useEffect(() => {
  //   const { data } = modalState;
  //   if (isEditForm && data?.id) {
  //     getPlanDetails(data.id);
  //   }
  // }, [isEditForm, modalState]);

  // const getPlanDetails = async (id) => {
  //   const response = await getDetailsById("/annual-plans", id);
  //   const responseData = {
  //     ...response,
  //   };
  //   setState(responseData || null);
  // };

  const handleClose = () => {
    setModalState((prev) => ({
      isOpen: false,
      data: null,
      isEdit: false,
    }));
  };

  const annualPlanFormData = useSelector(
    (state) => state.globalForm[FORM_TYPES.ANNUAL_PLAN_FORM]
  );

  const AllowedRoles = ["System Administrator", "Administrator"];

  const isEditEnabled = (roles) => {
    let editEnabled = false;
    if (roles && roles.length > 0) {
      roles.forEach((role) => {
        if (R.includes(role, AllowedRoles)) {
          editEnabled = true;
        }
      });
    }
    return editEnabled;
  };

  const isReadOnly = isEditEnabled(userRoles)
    ? false
    : R.propOr("", "status", annualPlanFormData) === "COMPLETED";

  const handleSubmit = async (e) => {
    const response = await saveUserRoleMapping(
      state,
      parentState.id,
      modalState.isEdit
    );
    getStaffDetails?.();
    setModalState({
      isOpen: false,
      isEdit: false,
      data: null,
    });
  };

  return (
    <Dialog
      maxWidth="md"
      fullWidth
      fullScreen={isSmallDevice}
      open={modalState?.isOpen}
      onClose={() => setModalState((prev) => ({ ...prev, isOpen: false }))}
      ariaLabeledBy="form-dialog-title"
    >
      <Grid container>
        <Grid item xs={10} md={11}>
          <DialogTitle id="form-dialog-title">
            <Typography variant="h6" className={classes.heading}>{`${
              isEditForm ? "Edit" : "Add"
            } Role`}</Typography>
          </DialogTitle>
        </Grid>
        <Grid item xs={1} className={classes.modalCloseBtn}>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </Grid>
      </Grid>
      <Divider className={classes.divider} />
      <DialogContent style={{ padding: "8px 25px" }}>
        <Box>
          <RolesForm
            formType={FORM_TYPES.ROLE_PRIVILEGES_FORM}
            isEditForm={isEditForm}
            isReadOnly={isReadOnly}
            state={state}
            setState={setState}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "end",
              pt: 2,
            }}
          >
            <Button
              color="primary"
              // disabled={isReadOnly || isNew}
              variant="contained"
              onClick={handleSubmit}
              data-html2canvas-ignore
            >
              Submit
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default RolesModal;
