import * as R from "ramda";

export const RolesMap = {
  unitplan: "Unit Plan",
  observationregister: "Observation Register",
  subject: "Subject",
  user: "User",
  teacher: "Teacher",
  lessonplan: "Lesson Plan",
  goalsetting: "Goal Setting",
  class: "Class",
  annualplan: "Annual Plan",
  classsection: "Class Section",
  school: "School",
  lov: "List of Value",
  role: "Role",
  unitplanform: "Unit Plan Form",
  lessonplanform: "Lesson Plan Form",
  goalsettingform: "Goal Setting Form",
  goalsettingreview: "Goal Setting Review",
  goalconfiguration: "Goal Configuration",
};

export const EntityMap = {
  unitplan: "unitplan",
  observationregister: "observationregister",
  subject: "subject",
  user: "user",
  teacher: "teacher",
  lessonplan: "lessonplan",
  goalsetting: "goalsetting",
  class: "class",
  annualplan: "annualplan",
  classsection: "classsection",
  school: "school",
  lov: "lov",
  role: "role",
  unitplanform: "unitplanform",
  lessonplanform: "lessonplanform",
  goalsettingform: "goalsettingform",
  goalsettingreview: "goalsettingreview",
  goalconfiguration: "goalconfiguration",
};

export const getMatchedRoles = (roles, assignedRoles) => {
  const assignedRoleIds = R.map((role) => role.roleId)(assignedRoles);
  const matchedRoles = R.filter((role) => R.includes(role.id, assignedRoleIds))(
    roles
  );
  return matchedRoles;
};

export const getMatchingRoles = (roles, assignedRoles) => {
  const privileges = {
    READ: [],
    CREATE: [],
    UPDATE: [],
    DELETE: [],
  };
  const matchedRoles = getMatchedRoles(roles, assignedRoles);
  matchedRoles.forEach((role) => {
    role.rolePrivilegeXrefs.forEach((rolePrivilege) => {
      const privilege = rolePrivilege.privilege;
      if (privilege.status === "ACTIVE") {
        privileges[privilege.privilegeType] = [
          ...privileges[privilege.privilegeType],
          privilege,
        ];
      }
    });
  });
  return privileges;
};
