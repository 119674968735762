import React from "react";
import { useSelector } from "react-redux";
import { Route, Switch, Redirect } from "react-router-dom";
import SideDrawer from "../components/sideDrawer/SideDrawer";
import SigninForm from "components/signin-form/SigninForm";
import SignupForm from "components/signup-form/SignupForm";
import ChangePassword from "common/components/ChangePassword/ChangePassword";
import MainContent from "./MainContent";

const RouteWrapper = ({ authenticated }) => {
  const userProfile = useSelector((state) => state.userProfile);

  return (
    <Switch>
      <Route exact path="/">
        {localStorage.getItem("isLoggedIn") === "true" ? (
          <Redirect to="/dashboard" />
        ) : (
          <Redirect to="/login" />
        )}
      </Route>
      <Route
        path="/login"
        render={(props) =>
          authenticated ? (
            <Redirect to="/dashboard" />
          ) : (
            <SigninForm authenticated={authenticated} {...props} />
          )
        }
      ></Route>
      <Route
        path="/auth/reset-password"
        render={(props) => (
          <ChangePassword authenticated={authenticated} {...props} />
        )}
      ></Route>
      <Route
        path="/signup"
        render={(props) =>
          authenticated ? (
            <Redirect to="/dashboard" />
          ) : (
            <SignupForm authenticated={authenticated} {...props} />
          )
        }
      ></Route>
      <Route
        path="/*"
        render={(props) =>
          authenticated ? (
            <>
              <SideDrawer {...props} />
              <MainContent />
            </>
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: props.location },
              }}
            />
          )
        }
      ></Route>
    </Switch>
  );
};

export default RouteWrapper;
