import { Box, List, ListItem, ListItemIcon, Typography, ListItemText, Divider } from "@material-ui/core";
import { CheckCircleOutlined, CloseOutlined } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import { mapIndexed } from "common/utils/composition";
import React from "react";
import * as R from "ramda";

const useStyle = makeStyles(theme => ({
    title:{
        "&.MuiTypography-root":{
            marginTop:"1rem",
            fontWeight: "600",
            textAlign: "left"
        }
    },
    whiteIcon:{
        color:"white",
        cursor: "pointer"
    },
    unitList: {
        "&.MuiList-root":{
            maxHeight:"none"
        }
    }
}))
const DisplaySubTopics = ({subTopics, isWhiteIcon = true, onDelete=null, disabled=false}) => {
    const classes = useStyle();
    if(R.isEmpty(subTopics)) {
        return null;
    }
    return (
        <Box sx={{ width:"100%", overflowWrap: "break-word"}}>
            <Typography variant="subtitle1">
                Sub Topics
            </Typography>
            <List className={classes.unitList}>
                {
                    mapIndexed((item, index) => {
                        return (
                            <ListItem>
                                <ListItemIcon disabled={disabled}>
                                    <CheckCircleOutlined disabled={disabled} color="primary" className={isWhiteIcon && classes.whiteIcon} fontSize={"small"} />
                                </ListItemIcon>
                                <ListItemText disabled={disabled}
                                    primary={`${item.name}`}
                                />
                                {
                                    !R.isNil(onDelete) && !disabled && (
                                        <ListItemIcon >
                                        <CloseOutlined  className={isWhiteIcon && classes.whiteIcon} fontSize={"small"} onClick={() => onDelete(index)}/>
                                    </ListItemIcon>
                                    )
                                }
                               
                            </ListItem>
                        )
                    })(subTopics)
                }
            </List>
        </Box>
    )
}

export default DisplaySubTopics;