import React, { useState, useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import * as R from "ramda";
import { MenuProps, useStyles } from "./utils";
import { getLOVSelectData } from "../SelectDropDown/SelectDropDownUtils";
import { mapIndexed } from "common/utils/composition";
import { useDispatch } from "react-redux";
import { getPageList } from "components/listOfValues/utils";

import { PAGES, setPageListItems } from "state/slices/pageSlice";

const SubjectMultiSelectDropDown = ({
  dataKey,
  multiselect = true,
  defaultSelected = [],
  lovKey,
  value,
  selectKey,
  onSelect,
  setObj = false,
}) => {
  const classes = useStyles();
  const [selected, setSelected] = useState([]);
  const [selectList, setSelectList] = useState([]);
  const isAllSelected =
    selectList.length > 0 && selected.length === selectList.length;

  const LOVList = useSelector((state) => state.pages.listOfValues.items);
  const getLovValue = () => {
    return setObj ? R.propOr("", "value", value) : value;
  };
  const [lovValue, setLovValue] = useState(getLovValue());

  const dispatch = useDispatch();

  const getLOVList = useCallback(async () => {
    let filteredLOV = [];
    if (R.isEmpty(LOVList)) {
      const lovListData = await getPageList({
        searchKey: "",
        page: PAGES.listOfValues,
      });
      dispatch(
        setPageListItems({
          page: PAGES.listOfValues,
          itemList: lovListData.content,
        })
      );
      filteredLOV = lovListData.content.filter((lovItem) => {
        return lovItem.name === lovKey;
      })[0];
    } else {
      filteredLOV = LOVList.filter((lovItem) => {
        return lovItem.name === lovKey;
      })[0];
    }

    let itemList = R.propOr([], "items", filteredLOV);

    if (defaultSelected && defaultSelected.length > 0) {
      // const selectedlovData = defaultSelected(defaultSelected, itemList);
      const selectedlovData = [];
      defaultSelected ??
        [].forEach((selected) => {
          selectedlovData.push(
            itemList.filter((item) => item.name === selected)
          );
        });
      setSelected(defaultSelected);
    }

    setSelectList(itemList);
  }, [LOVList, defaultSelected, dispatch, lovKey]);

  useEffect(() => {
    getLOVList();
  }, [getLOVList]);

  // useEffect(() => {
  //   setLovValue(getLovValue());
  // }, [defaultSelected]);

  const handleChange = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      setSelected(selected.length === selectList.length ? [] : selectList);
      return;
    }
    const updatedSelectedValues = [...value];
    setSelected(updatedSelectedValues);
    onSelect(updatedSelectedValues);
  };

  const getRenderValue = (selectedValues) => {
    return R.map((eachValue) => eachValue, selectedValues)?.join(", ");
  };

  const getMultiSelectedList = (value) => {
    return R.filter((lov) => R.includes(lov.value, value))(selectList);
  };

  return (
    <Select
      multiple={multiselect}
      displayEmpty
      margin="normal"
      variant="outlined"
      id="multi-select-outlined"
      input={<OutlinedInput />}
      value={selected}
      onChange={handleChange}
      renderValue={(selected) => {
        if (selected.length === 0) {
          return <span style={{ color: "#bbb" }}>Select subject</span>;
        }
        return getRenderValue(selected);
      }}
      MenuProps={MenuProps}
      placeholder={selectKey}
      name={selectKey}
      inputProps={{ "aria-label": "Without label" }}
    >
      {mapIndexed((item, index) => {
        return (
          <MenuItem key={`${index[dataKey]}-${index}`} value={item.name}>
            <ListItemText primary={item.name} />
          </MenuItem>
        );
      }, selectList)}
    </Select>
  );
};

export default SubjectMultiSelectDropDown;
