import { mapIndexed } from "common/utils/composition";
import * as R from "ramda";

export const getLOVRequestData = (globalFormData) => {
  const { name, description, lovList, status, id } = globalFormData;
  return {
    name,
    description,
    items: getLOVListItems(lovList),
    status,
    id
  };
};

export const getLOVListItems = (lovList) => {
  return mapIndexed((item, index) => {
    return {
      name: R.propOr("", "key", item),
      value: R.propOr("", "value", item),
    };
  }, lovList);
};

export const getLOVFormattedList = (lovList, properties) => {
    return mapIndexed((item) => {
        return {
          key: R.propOr("", properties.key, item),
          value: R.propOr("", properties.value, item),
        };
      }, lovList);
};

export const getLOVItemFormData = (data) => {
  return {
    name: data.name,
    description: data.description,
    items: data.items,
    isActive: R.propEq("status", "ACTIVE")(data),
    id: R.prop("id", data)
  };
};

export const completementLOVStatus = (status) => {
  return status === "ACTIVE" ? "INACTIVE" : "ACTIVE"
};