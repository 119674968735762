import React, { useState, useEffect } from "react";
import {
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Box,
  Typography,
  Button,
  TextField,
} from "@material-ui/core";
import DeleteIcon from "@mui/icons-material/Delete";

const useStyles = makeStyles((theme) => ({
  inputFormControl: {
    marginBottom: "2rem",
  },
  inputLabel1: {
    top: "-17px",
    left: "-14px",
  },
  inputLabel: {
    top: "-17px",
  },
}));

const GoalTimelineForm = ({ goalTimeline, setGoalTimeline }) => {
  const classes = useStyles();
  const [frequencyFields, setFrequencyFields] = useState([
    {
      frequencyLabel: "",
      selfFeedbackStartDate: "",
      selfFeedbackEndDate: "",
      peerFeedbackStartDate: "",
      peerFeedbackEndDate: "",
    },
  ]);
  const handleChange = (e) => {
    const { value, name } = e.target;
    let newValue = value;
    if (name === "feedbackFrequency") {
      newValue = value.replace(/[^0-9]/g, "");
    }
    setGoalTimeline((prev) => ({ ...prev, [name]: newValue }));
  };

  const handleFrequencyChange = (e, index) => {
    const updatedFrequencies = [...frequencyFields];
    updatedFrequencies[index][e.target.name] = e.target.value;
    setFrequencyFields(updatedFrequencies);
  };

  useEffect(() => {
    setGoalTimeline((prev) => ({
      ...prev,
      feedbackFrequencies: frequencyFields,
    }));
  }, [frequencyFields, setGoalTimeline]);

  const addNewFrequency = () => {
    setFrequencyFields([
      ...frequencyFields,
      {
        frequencyLabel: "",
        selfFeedbackStartDate: "",
        selfFeedbackEndDate: "",
        peerFeedbackStartDate: "",
        peerFeedbackEndDate: "",
      },
    ]);
  };

  const onRemoveFrequency = (i) => {
    const deleteFrequency = [...frequencyFields];
    deleteFrequency.splice(i, 1);
    setFrequencyFields(deleteFrequency);
  };

  return (
    <form style={{ marginTop: "1.5rem" }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl
            sx={{ m: 1, minWidth: 200 }}
            variant="outlined"
            className={classes.inputFormControl}
          >
            <InputLabel
              htmlFor="demo-dialog-native"
              className={classes.inputLabel1}
            >
              Annual Period
            </InputLabel>
            <Select
              variant="outlined"
              value={goalTimeline.academicCalendar || ""}
              onChange={handleChange}
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              placeholder="annual period"
              name="academicCalendar"
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
            >
              <MenuItem value="" disabled>
                <span className={classes.placeholderValue}>
                  Select annual period
                </span>
              </MenuItem>
              <MenuItem value="AY21_22">A.Y. 2021-2022</MenuItem>
              <MenuItem value="AY22_23">A.Y. 2022-2023</MenuItem>
              <MenuItem value="AY23_24">A.Y. 2023-2024</MenuItem>
              <MenuItem value="AY24_25">A.Y. 2024-2025</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid container item xs={12} md={6}>
          <FormControl className={classes.inputFormControl}>
            <InputLabel className={classes.inputLabel}>
              Goal Setup Start Date
            </InputLabel>
            <TextField
              variant="outlined"
              margin="normal"
              id="goalSetupStartDate"
              name="goalSetupStartDate"
              placeholder={"Enter Goal Setup Start Date"}
              type="date"
              sx={{ width: 220 }}
              InputLabelProps={{
                shrink: true,
              }}
              // disabled={isEditForm}
              onChange={handleChange}
              value={goalTimeline.goalSetupStartDate || ""}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl className={classes.inputFormControl}>
            <InputLabel className={classes.inputLabel}>
              Goal Setup End Date
            </InputLabel>
            <TextField
              variant="outlined"
              margin="normal"
              id="goalSetupEndDate"
              name="goalSetupEndDate"
              placeholder={"Enter Goal Setup End Date"}
              type="date"
              sx={{ width: 220 }}
              InputLabelProps={{
                shrink: true,
              }}
              // disabled={isEditForm}
              onChange={handleChange}
              value={goalTimeline.goalSetupEndDate || ""}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl>
            <InputLabel className={classes.inputLabel}>
              Goal Review Start Date
            </InputLabel>
            <TextField
              variant="outlined"
              margin="normal"
              id="goalReviewStartDate"
              name="goalReviewStartDate"
              placeholder={"Enter Goal Review Start Date"}
              type="date"
              sx={{ width: 220 }}
              InputLabelProps={{
                shrink: true,
              }}
              // disabled={isEditForm}
              onChange={handleChange}
              value={goalTimeline.goalReviewStartDate || ""}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl>
            <InputLabel className={classes.inputLabel}>
              Goal Review End Date
            </InputLabel>
            <TextField
              variant="outlined"
              margin="normal"
              id="goalReviewEndDate"
              name="goalReviewEndDate"
              placeholder={"Enter Goal Review End Date"}
              type="date"
              sx={{ width: 220 }}
              InputLabelProps={{
                shrink: true,
              }}
              // disabled={isEditForm}
              onChange={handleChange}
              value={goalTimeline.goalReviewEndDate || ""}
            />
          </FormControl>
        </Grid>

        <Grid item xs={6} className={classes.tierTitle}>
          <Typography variant="h6" color="primary" className={classes.title}>
            Feedback Frequency
          </Typography>
        </Grid>
        <Grid
          item
          xs={6}
          className={classes.addIconContainer}
          style={{ textAlign: "end" }}
        >
          <Button
            //disabled={isReadOnly}
            variant="outlined"
            onClick={addNewFrequency}
          >
            Add Frequency
          </Button>
        </Grid>
      </Grid>
      {frequencyFields.map((val, i) => {
        return (
          <>
            <Box
              style={{
                border: `1px solid #cccccc`,
                padding: "1rem 2rem 2rem 2rem",
                borderRadius: 4,
                marginBottom: "2rem",
              }}
            >
              <form>
                <Box style={{ textAlign: "end" }}>
                  <Button
                    // disabled={isReadOnly}
                    onClick={() => onRemoveFrequency(i)}
                    style={{ margin: "0px" }}
                  >
                    <DeleteIcon
                      fontSize="small"
                      style={{ marginRight: "10px" }}
                    />{" "}
                    Delete Frequency
                  </Button>
                </Box>
                <Grid container spacing={2} style={{ marginTop: "1rem" }}>
                  <Grid item xs={12}>
                    <FormControl
                      sx={{ m: 1, minWidth: 200 }}
                      variant="outlined"
                      className={classes.inputFormControl}
                    >
                      <InputLabel
                        htmlFor="demo-dialog-native"
                        className={classes.inputLabel1}
                      >
                        Frequency Label
                      </InputLabel>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        id="frequencyLabel"
                        name="frequencyLabel"
                        placeholder="Frequency Label"
                        // sx={{ width: 220 }}
                        // InputLabelProps={{
                        //   shrink: true,
                        // }}
                        // disabled={isEditForm}
                        onChange={(e) => handleFrequencyChange(e, i)}
                        value={val.frequencyLabel || ""}
                      />
                    </FormControl>
                  </Grid>
                  <Grid container item xs={12} md={6}>
                    <FormControl className={classes.inputFormControl}>
                      <InputLabel className={classes.inputLabel}>
                        Self Feedback Start Date
                      </InputLabel>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        id="selfFeedbackStartDate"
                        name="selfFeedbackStartDate"
                        placeholder={"Enter Self Feedback Start Date"}
                        type="date"
                        sx={{ width: 220 }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        // disabled={isEditForm}
                        onChange={(e) => handleFrequencyChange(e, i)}
                        value={val.selfFeedbackStartDate || ""}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl className={classes.inputFormControl}>
                      <InputLabel className={classes.inputLabel}>
                        Self Feedback End Date
                      </InputLabel>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        id="selfFeedbackEndDate"
                        name="selfFeedbackEndDate"
                        placeholder={"Enter Self Feedback End Date"}
                        type="date"
                        sx={{ width: 220 }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        // disabled={isEditForm}
                        onChange={(e) => handleFrequencyChange(e, i)}
                        value={val.selfFeedbackEndDate || ""}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <InputLabel className={classes.inputLabel}>
                        Peer Feedback Start Date
                      </InputLabel>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        id="peerFeedbackStartDate"
                        name="peerFeedbackStartDate"
                        placeholder={"Enter Peer Feedback Start Date"}
                        type="date"
                        sx={{ width: 220 }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        // disabled={isEditForm}
                        onChange={(e) => handleFrequencyChange(e, i)}
                        value={val.peerFeedbackStartDate || ""}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl>
                      <InputLabel className={classes.inputLabel}>
                        Peer Feedback End Date
                      </InputLabel>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        id="peerFeedbackEndDate"
                        name="peerFeedbackEndDate"
                        placeholder={"Enter Peer Feedback End Date"}
                        type="date"
                        sx={{ width: 220 }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        // disabled={isEditForm}
                        onChange={(e) => handleFrequencyChange(e, i)}
                        value={val.peerFeedbackEndDate || ""}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </>
        );
      })}
    </form>
  );
};

export default GoalTimelineForm;
