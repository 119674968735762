import React from "react";
import { Grid, makeStyles } from "@material-ui/core";
import TierPlanCard from "../TierPlan/TierPlanCard";
import TierPlanModal from "../TierPlan/TierPlanModal";
import CustomizedTabs from "../CustomizedTabs";

const useStyles = makeStyles((theme) => ({
  cardWrapper: {
    flexWrap: "wrap",
  },
  annualPlanCard: {
    marginBottom: "30px",
    [theme.breakpoints.down('sm')]: {
      marginBottom: "10px",
    }
  },
  noDataAvailable: {
    margin: '20px',
  }
}));

function PlanDetailsPage(props) {
  const { userRoles, setModalState, modalState, parentState, getPlanDetails } = props;
  const classes = useStyles();

  return (
    <>
      <Grid
        container
        direction="row"
        className={classes.cardWrapper}
        spacing={4}
      >
        <CustomizedTabs tabList={[`Tier Plans (${parentState?.tierPlans?.length || 0})`]} />
        {parentState?.tierPlans?.length ? (
          parentState?.tierPlans?.map((plan, index) => (
            <Grid
              item
              className={classes.annualPlanCard}
              key={index}
              md="6"
              xs="12"
            >
              <TierPlanCard data={plan} {...props} />
            </Grid>
          ))
        ) : (
          <div className={classes.noDataAvailable}>No Data available</div>
        )}
      </Grid>
      {modalState.isOpen && (
        <TierPlanModal
          userRoles={userRoles}
          modalState={modalState}
          setModalState={setModalState}
          getPlanDetails={getPlanDetails}
        />
      )}
    </>
  );
}

export default PlanDetailsPage;
