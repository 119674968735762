import {
  Box,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import {
  LOVKeyMap,
  LOVSelectKeys,
} from "common/components/SelectDropDown/SelectDropDownUtils";
import { mapIndexed } from "common/utils/composition";
import { getPageList } from "components/listOfValues/utils";
import * as R from "ramda";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PAGES, setPageListItems } from "state/slices/pageSlice";

const useStyles = makeStyles((theme) => ({
  tlmLabelBlock: {
    "&.MuiGrid-root": {
      display: "flex",
      justifyContent: "left",
      alignItems: "center",
    },
  },
  inputLabel1: {
    top: "-17px",
    left: "-14px",
  },
  inputLabel: {
    top: "-17px",
  },
}));

const getTLMData = (selectedTLM, TLMData) => {
  let updatedTLM = R.map((tlm) => {
    const filteredTlmData = R.filter((data) => data.name === tlm)(TLMData);
    if (!R.isEmpty(filteredTlmData)) {
      return filteredTlmData[0];
    }
    return {
      name: tlm,
      reference: "",
    };
  })(selectedTLM);
  return updatedTLM;
};

const TLMReferenceForm = ({
  displayLOVSelect = false,
  TLMData,
  setTLMData,
  isReadOnly = false,
  lovSelectKeys = LOVSelectKeys.TLM,
  lovKeyMap = LOVKeyMap.TLM,
  planSummary = false,
}) => {
  const classes = useStyles();
  const [selectedTLM, setTLM] = useState(R.map((tlm) => tlm.name)(TLMData));
  const LOVList = useSelector((state) => state.pages.listOfValues.items);

  useEffect(() => {
    setTLM(R.map((tlm) => tlm.name)(TLMData));
  }, [TLMData]);

  const [selectList, setSelectList] = useState([]);
  const dispatch = useDispatch();

  const onChange = (e, index) => {
    const [value, name] = R.paths(
      [
        ["target", "value"],
        ["target", "name"],
      ],
      e
    );
    const updatedTLMData = R.assocPath([index, name], value, TLMData);
    setTLMData(updatedTLMData);
  };

  const tlmChange = (event) => {
    const value = R.path(["target", "value"], event);
    setTLM(value);
    const data = getTLMData(value, TLMData);
    setTLMData(data);
  };

  const getLOVList = useCallback(async () => {
    let filteredLOV = [];
    if (R.isEmpty(LOVList)) {
      const lovListData = await getPageList({
        searchKey: "",
        page: PAGES.listOfValues,
      });
      dispatch(
        setPageListItems({
          page: PAGES.listOfValues,
          itemList: lovListData.content,
        })
      );
      filteredLOV = lovListData.content.filter((lovItem) => {
        return lovItem.name === lovKeyMap;
      })[0];
    } else {
      filteredLOV = LOVList.filter((lovItem) => {
        return lovItem.name === lovKeyMap;
      })[0];
    }

    let itemList = R.propOr([], "items", filteredLOV);

    // if (getSelectList && selectedTLM) {

    //     const selectedlovData = getSelectList(selectedTLM, itemList);
    //     setLovValue(selectedlovData);
    // }

    setSelectList(itemList);
  }, [LOVList, dispatch]);

  const removeTLMEntry = (index) => {
    setTLM(R.remove(index, 1, selectedTLM));
    setTLMData(R.remove(index, 1, TLMData));
  };

  useEffect(() => {
    getLOVList();
  }, [getLOVList]);

  return (
    <Box>
      {displayLOVSelect && (
        <FormControl variant="outlined" style={{ marginTop: "1rem" }}>
          <InputLabel
            className={classes.inputLabel1}
            id="demo-simple-select-outlined-label"
          >
            <b>TLM(Teaching learning material)</b>
          </InputLabel>
          <Select
            margin="normal"
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            variant="outlined"
            value={selectedTLM}
            placeholder={lovSelectKeys}
            name={lovSelectKeys}
            onChange={tlmChange}
            multiple={true}
            fullWidth
            disabled={isReadOnly}
          >
            <MenuItem value="" disabled>
              <em>None</em>
            </MenuItem>
            {R.map((item) => {
              return <MenuItem value={item.name}>{item.name}</MenuItem>;
            }, selectList)}
          </Select>
        </FormControl>
      )}
      <br />
      <br />
      <Box>
        {mapIndexed((tlmItem, index) => {
          return (
            <Grid container spacing={planSummary ? 0 : 2}>
              {!planSummary && (
                <Grid item xs={2} className={classes.tlmLabelBlock}>
                  <Typography>
                    <b>{tlmItem.name}</b>
                  </Typography>
                </Grid>
              )}
              <Grid item xs={planSummary ? 11 : 9}>
                {planSummary ? (
                  <InputLabel className={classes.inputLabel1}>
                    <b>{tlmItem.name}</b>
                  </InputLabel>
                ) : (
                  <InputLabel className={classes.inputLabel}>
                    Reference
                  </InputLabel>
                )}
                <TextField
                  margin="normal"
                  variant="outlined"
                  type="url"
                  id="multiline-static"
                  placeholder="Enter reference"
                  value={tlmItem.reference}
                  fullWidth
                  name={"reference"}
                  onChange={(e) => onChange(e, index)}
                  disabled={isReadOnly}
                />
              </Grid>
              <Grid item xs={1} style={{ alignSelf: "center" }}>
                <IconButton onClick={() => removeTLMEntry(index)}>
                  <Close />
                </IconButton>
              </Grid>
            </Grid>
          );
        })(TLMData)}
      </Box>
    </Box>
  );
};

export default TLMReferenceForm;
