import { getPageList } from 'components/listOfValues/utils';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PAGES, setPageListItems } from 'state/slices/pageSlice';
import * as R from "ramda";
import { getMatchingRoles, getMatchedRoles } from 'util/roleUtils';

const getPrivilegeMap = (privileges, entityName, dependentEntityName, nextDependentEntityName) => {
    if (dependentEntityName) {
        const privilege = {};
        privilege[entityName] = {
            READ: R.findIndex(privilege => privilege.entityName === entityName && privilege.status === "ACTIVE")(R.propOr([], "READ", privileges)) !== -1,
            CREATE: R.findIndex(privilege => privilege.entityName === entityName && privilege.status === "ACTIVE")(R.propOr([], "CREATE", privileges)) !== -1,
            UPDATE: R.findIndex(privilege => privilege.entityName === entityName && privilege.status === "ACTIVE")(R.propOr([], "UPDATE", privileges)) !== -1,
            DELETE: R.findIndex(privilege => privilege.entityName === entityName && privilege.status === "ACTIVE")(R.propOr([], "DELETE", privileges)) !== -1,
        }
        privilege[dependentEntityName] = {
            READ: R.findIndex(privilege => privilege.entityName === dependentEntityName && privilege.status === "ACTIVE")(R.propOr([], "READ", privileges)) !== -1,
            CREATE: R.findIndex(privilege => privilege.entityName === dependentEntityName && privilege.status === "ACTIVE")(R.propOr([], "CREATE", privileges)) !== -1,
            UPDATE: R.findIndex(privilege => privilege.entityName === dependentEntityName && privilege.status === "ACTIVE")(R.propOr([], "UPDATE", privileges)) !== -1,
            DELETE: R.findIndex(privilege => privilege.entityName === dependentEntityName && privilege.status === "ACTIVE")(R.propOr([], "DELETE", privileges)) !== -1,
        }
        if(nextDependentEntityName) {
            privilege[nextDependentEntityName] = {
                READ: R.findIndex(privilege => privilege.entityName === nextDependentEntityName && privilege.status === "ACTIVE")(R.propOr([], "READ", privileges)) !== -1,
                CREATE: R.findIndex(privilege => privilege.entityName === nextDependentEntityName && privilege.status === "ACTIVE")(R.propOr([], "CREATE", privileges)) !== -1,
                UPDATE: R.findIndex(privilege => privilege.entityName === nextDependentEntityName && privilege.status === "ACTIVE")(R.propOr([], "UPDATE", privileges)) !== -1,
                DELETE: R.findIndex(privilege => privilege.entityName === nextDependentEntityName && privilege.status === "ACTIVE")(R.propOr([], "DELETE", privileges)) !== -1,
            }
        }
        return privilege
    }
    return {
        READ: R.findIndex(privilege => privilege.entityName === entityName && privilege.status === "ACTIVE")(R.propOr([], "READ", privileges)) !== -1,
        CREATE: R.findIndex(privilege => privilege.entityName === entityName && privilege.status === "ACTIVE")(R.propOr([], "CREATE", privileges)) !== -1,
        UPDATE: R.findIndex(privilege => privilege.entityName === entityName && privilege.status === "ACTIVE")(R.propOr([], "UPDATE", privileges)) !== -1,
        DELETE: R.findIndex(privilege => privilege.entityName === entityName && privilege.status === "ACTIVE")(R.propOr([], "DELETE", privileges)) !== -1,
    }
};

const withPrivilege = (Component, entityName, dependentEntityName = "", nextDependentEntityName = "") => props => {
    const roles = useSelector(state => state.pages.roles);
    const userProfile = useSelector(state => state.userProfile.userProfile);
    const dispatch = useDispatch();
    const privileges = getMatchingRoles(R.pathOr([], ["items"], roles), R.pathOr([], ["roles"], userProfile));
    const userRoles = R.map(role => role.name)(getMatchedRoles(R.pathOr([], ["items"], roles), R.pathOr([], ["roles"], userProfile)));
    const getRoles = async () => {
        const response = await getPageList({ searchKey: "", page: PAGES.roles });
        dispatch(setPageListItems({
            page: PAGES.roles,
            itemList: response?.content,
        }));
    }

    useEffect(() => {
        if (R.isEmpty(roles.items)) {
            getRoles();
        }
    }, [roles]);

    return <Component
        {...props}
        privileges={getPrivilegeMap(privileges, entityName, dependentEntityName, nextDependentEntityName)}
        userRoles={userRoles}
    />;
};
export default withPrivilege;