import React from "react";
import { Grid, makeStyles } from "@material-ui/core";
import CustomizedTabs from "../../../academicPlanDetails/CustomizedTabs";
import SubjectCard from "../Subjects/SubjectCard";
import SectionCard from "../ClassSections/SectionCard";

const useStyles = makeStyles((theme) => ({
  cardWrapper: {
    flexWrap: "wrap",
  },
  annualPlanCard: {
    marginBottom: "30px",
  },
  noDataAvailable: {
    margin: "20px",
  },
  paginationContainer: {
    display: "flex",
    justifyContent: "end",
  },
}));

function ClassPage(props, privileges) {
  const {
    parentState,
    setModalState,
    state,
    modalState,
    userRoles,
    getClassesDetails,
  } = props;
  const classes = useStyles();
  const [activeTab, setActiveTab] = React.useState(0);

  return (
    <>
      <Grid
        container
        direction="row"
        className={classes.cardWrapper}
        spacing={4}
      >
        <CustomizedTabs
          setActiveTab={setActiveTab}
          tabList={[
            `Subject (${parentState?.subjects?.length || 0})`,
            `Class sections (${parentState?.sections?.length || 0})`,
          ]}
        />

        {activeTab === 0 && (
          <>
            {parentState?.subjects?.length ? (
              parentState?.subjects?.map((subData, index) => (
                <SubjectCard data={subData} key={index} {...props} />
              ))
            ) : (
              <div className={classes.noDataAvailable}>No Data available</div>
            )}
          </>
        )}
        {activeTab === 1 && (
          <>
            {parentState?.sections?.length ? (
              parentState?.sections?.map((secData, index) => (
                <SectionCard
                  parentState={state}

                  modalState={modalState}
                  userRoles={userRoles}
                  getClassesDetails={getClassesDetails}
                  data={secData}
                  privileges={privileges}
                  key={index}
                  {...props}
                  setModalState={setModalState}
                />
              ))
            ) : (
              <div className={classes.noDataAvailable}>No Data available</div>
            )}
          </>
        )}
      </Grid>
    </>
  );
}

export default ClassPage;
