import React from "react";
import {
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  Typography,
  RadioGroup,
  Radio,
  TextField,
  FormControlLabel,
} from "@material-ui/core";
import * as R from "ramda";
import {
  LOVDataKeys,
  LOVSelectKeys,
} from "common/components/SelectDropDown/SelectDropDownUtils";
import LOVSelect from "common/components/LOVSelect/LOVSelect";
import { removeWhiteSpace } from "common/utils/composition";

const useStyles = makeStyles((theme) => ({
  inputFormControl: {
    marginBottom: "2rem",
  },
  inputLabel1: {
    top: "-17px",
    left: "-14px",
  },
  inputLabel: {
    top: "-17px",
  },
}));

const ManageSubjectForm = ({
  formType,
  isEditForm,
  isReadOnly,
  state,
  handleChange,
  disableSubjects,
}) => {
  const classes = useStyles();
  const [typeValue, setTypeValue] = React.useState("");

  const onTypeChange = (event) => {
    setTypeValue(event.target.value);
    handleChange("subjectType", event.target.value);
  };
  const onChange = (event) => {
    const [value, name] = R.paths(
      [
        ["target", "value"],
        ["target", "name"],
      ],
      event
    );
    handleChange(name, value);
  };

  const getSelectList = (value) => {
    return value;
  };

  return (
    <form style={{ marginTop: "1.5rem" }}>
      <FormControl variant="outlined" className={classes.inputFormControl}>
        <InputLabel
          className={classes.inputLabel1}
          id="demo-simple-select-outlined-label"
        >
          School
        </InputLabel>
        <TextField
          variant="outlined"
          margin="normal"
          name="school"
          isReadOnly={isReadOnly}
          placeholder="Enter school"
          value={state?.school?.schoolName || ""}
          onChange={onChange}
        />
      </FormControl>
      <FormControl variant="outlined" className={classes.inputFormControl}>
        <InputLabel
          className={classes.inputLabel1}
          id="demo-simple-select-outlined-label"
        >
          Class Name
        </InputLabel>
        <TextField
          variant="outlined"
          margin="normal"
          name="capacity"
          placeholder="Enter class name"
          value={state?.gradeName || ""}
          isReadOnly={isReadOnly}
          onChange={onChange}
        />
      </FormControl>

      <FormControl variant="outlined" className={classes.inputFormControl}>
        <InputLabel
          className={classes.inputLabel1}
          id="demo-simple-select-outlined-label"
        >
          Subject
        </InputLabel>
        <LOVSelect
          formType={formType}
          margin="normal"
          selectKey={LOVSelectKeys.SUBJECT}
          dataKey={LOVDataKeys.SUBJECT}
          lovKey={`SubjectClass${removeWhiteSpace(state?.gradeName)}`}
          value={state?.subject?.value || ""}
          setObj={!isEditForm}
          isReadOnly={isEditForm}
          getSelectList={getSelectList}
          customOnChange={(value) => handleChange("subject", value)}
          disableValues={disableSubjects}
        />
      </FormControl>

      <Grid container spacing={0}>
        <Grid item xs={12}>
          <FormControl component="fieldset">
            <Typography color="primary" variant="subtitle2">
              Type
            </Typography>

            <RadioGroup
              aria-label="type"
              name="subjectType"
              value={typeValue}
              onChange={onTypeChange}
            >
              <div style={{ display: "flex", margin: "left" }}>
                <FormControlLabel
                  value="COMPULSARY"
                  control={<Radio />}
                  label="Compulsory"
                />
                <FormControlLabel
                  value="OPTIONAL"
                  control={<Radio />}
                  label="Optional"
                />
              </div>
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
    </form>
  );
};

export default ManageSubjectForm;
