import React, { useRef } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Avatar, makeStyles } from "@material-ui/core";
import {
  CHALKBOARD_ICON,
  CLOCK_ICON,
  FILE_ICON,
  STICKY_NOTE_ICON,
} from "asset/images";
import EditIcon from "@mui/icons-material/Edit";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PasswordIcon from "@mui/icons-material/Password";
import StatusBlock from "../../academicPlanDetails/StatusBlock";
import { deepPurple } from "@material-ui/core/colors";
import { useHistory } from "react-router-dom";
import * as R from "ramda";
import MoreVerticalDot from "components/academicPlanDetails/MoreVerticalDot";
import {
  assignTempPasswordToUserByAdmin,
  updateStatus,
} from "components/listOfValues/utils";
import { completementStatus } from "common/utils/formUtility";

const useStyles = makeStyles((theme) => ({
  cardBody: {
    width: "100%",
    background: "#FFFFFF",
    borderRadius: "14px !important",
  },
  editIcon: {
    marginRight: "10px",
  },
  cardContent: {
    padding: "26px 18px !important",
  },
  cardTitle: {
    fontStyle: "normal",
    fontWeight: "800",
    fontSize: "18px",
    lineHeight: "22px",
    color: "#263657",
    display: "flex",
    alignItems: "center",
  },
  fileIcon: {
    marginRight: "10px",
  },
  cardTitleWrapper: {
    display: "flex",
    justifyContent: "initial",
    alignItems: "center",
    width: "100%",
  },
  subTitle: {
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "8px",
    lineHeight: "10px",
    color: "#898EAC",
  },
  cardBodyContent: {
    marginTop: "18px",
  },
  bodyText: {
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "12px",
    lineHeight: "14px",
    color: "#000000",
  },
  dotStyle: {
    width: "3.86px",
    height: "3.86px",
    background: "#898EAC",
    borderRadius: "50%",
    display: "inline-block",
    verticalAlign: "middle",
    margin: "auto 7px",
  },
  inputFormControl: {
    margin: "1.5rem",
  },
  flexGrid: {
    "&.MuiGrid-root": {
      display: "flex",
    },
  },
  inputLabel1: {
    top: "-17px",
    left: "-14px",
  },
  inputLabel: {
    top: "-17px",
  },
  placeholderValue: {
    color: "#bbb",
  },
  avatar: {
    background: deepPurple[500],
    height: "24px",
    width: "24px",
    marginRight: "10px",
    fontSize: "16px",
  },
  cardBottom: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  createdBy: {
    display: "flex",
    flexDirection: "column",
  },
  createdBySection: {
    display: "flex",
    marginTop: "18px",
  },
  statusBlock: {
    marginTop: "18px",
  },
  modifyIcons: {
    width: "65px",
    textAlign: "end",
  },
  addressWrap: {
    display: "inline-block",
    maxWidth: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
}));

export default function StaffCard({
  data,
  userRoles = [],
  privileges = {},
  setModalState,
  isDetailedPage,
  getStaffList,
  state,
  filterState,
}) {
  const classes = useStyles();
  const history = useHistory();
  const cardRef = useRef(null);
  const AllowedRoles = ["Administrator"];

  const isEditEnabled = (roles) => {
    let editEnabled = false;
    roles.forEach((role) => {
      if (AllowedRoles.includes(role)) {
        editEnabled = true;
      }
    });
    return editEnabled;
  };

  const isEditable = isEditEnabled(userRoles);

  const handleRedirect = (id) => {
    const selection = window.getSelection().toString();
    if (!selection) {
      if (R.propOr(true, "READ", privileges)) {
        history.push(`/users/${id}`);
      } else return null;
    }
  };

  return (
    <>
      <Box
        sx={{ minWidth: 275 }}
        onClick={() => handleRedirect(data?.id)}
        style={{ cursor: "pointer" }}
        ref={cardRef}
      >
        <Card
          variant="outlined"
          className={classes.cardBody}
          style={{ background: isDetailedPage ? "#E3D5CA " : "#FFF" }}
        >
          <CardContent className={classes.cardContent}>
            <Typography className={classes.cardTitle} variant="div">
              <div className={classes.cardTitleWrapper}>
                <img
                  className={classes.fileIcon}
                  src={FILE_ICON}
                  alt="File Icon"
                />
                <span>{data?.fullName || "-"}</span>
              </div>
              <div className={classes.verticalDotsButton}>
                <MoreVerticalDot
                  options={[
                    {
                      icon: (
                        <EditIcon
                          fontSize="small"
                          className={classes.editIcon}
                        />
                      ),
                      label: "Manage Staff",
                      value: "Manage Staff",
                    },
                    {
                      icon: (
                        <PasswordIcon
                          fontSize="small"
                          className={classes.editIcon}
                        />
                      ),
                      label: "Assign Temporary Password",
                      value: "assignTempPassword",
                      hidden: !isEditable,
                    },
                    {
                      icon:
                        data.status === "ACTIVE" ? (
                          <CancelIcon
                            fontSize="small"
                            className={classes.editIcon}
                          />
                        ) : (
                          <CheckCircleIcon
                            fontSize="small"
                            className={classes.editIcon}
                          />
                        ),
                      label:
                        data.status === "ACTIVE"
                          ? "Deactivate User"
                          : "Activate User",
                      value: "toggleStatus",
                    },
                  ]}
                  handleClick={async (e, value) => {
                    e.stopPropagation();
                    if (value === "assignTempPassword") {
                      const res = await assignTempPasswordToUserByAdmin(
                        data.email
                      );
                      getStaffList({
                        pageSize: state.pageSize,
                        currentPage: state.currentPage,
                        searchKey: filterState.searchKey,
                      });
                    } else if (value === "toggleStatus") {
                      const res = await updateStatus(
                        { status: completementStatus(data.status) },
                        data.id,
                        "users"
                      );
                      getStaffList({
                        pageSize: state.pageSize,
                        currentPage: state.currentPage,
                        searchKey: filterState.searchKey,
                      });
                    } else {
                      setModalState((prev) => ({
                        ...prev,
                        isOpen: true,
                        isEdit: true,
                        data,
                      }));
                    }
                  }}
                />
              </div>
              {/* )} */}
            </Typography>

            <div className={classes.cardBodyContent}>
              <img
                className={classes.fileIcon}
                src={CHALKBOARD_ICON}
                alt="Chalkboard Icon"
              />
              <Typography
                className={classes.bodyText}
                variant="span"
                marginRight={"34px"}
              >
                Mobile: {data?.mobile || "-"}
              </Typography>
              <img
                className={classes.fileIcon}
                src={STICKY_NOTE_ICON}
                alt="Sticky Note Icon"
              />
              <Typography className={classes.bodyText} variant="span">
                Designation:
                {data?.designation || "-"}
              </Typography>
            </div>
            <div>
              <img
                className={classes.fileIcon}
                src={CLOCK_ICON}
                alt="Clock Icon"
              />
              <Typography className={classes.bodyText} variant="span">
                School: {data?.school?.schoolCampus || "-"}
              </Typography>
            </div>
            <div className={classes.addressWrap}>
              <img
                className={classes.fileIcon}
                src={CLOCK_ICON}
                alt="Clock Icon"
              />
              <Typography className={classes.bodyText} variant="span">
                Address: {data?.permanentAddress || "-"}
              </Typography>
            </div>
            <div className={classes.cardBottom}>
              <div className={classes.createdBySection}>
                <Avatar className={classes.avatar}>
                  {data?.createdBy?.charAt(0)?.toUpperCase()}
                </Avatar>
                <div className={classes.createdBy}>
                  <Typography className={classes.subTitle} variant="div">
                    Created by
                  </Typography>
                  <Typography
                    className={classes.bodyText}
                    marginRight="10px"
                    variant="div"
                  >
                    {data?.email || "-"}
                  </Typography>
                </div>
              </div>
              <div className={classes.statusBlock}>
                <StatusBlock
                  status={data?.status?.toUpperCase() || ""}
                  greenStatus="ACTIVE"
                />
              </div>
            </div>
          </CardContent>
        </Card>
      </Box>
    </>
  );
}
