import * as R from "ramda";

export const getUnitPlanDetailsFormPayload = (unitData, unitPlanFormData, TLMData,consentData, unitPlanStatus = "ACTIVE" ) => {
    const updatedFormativeAssessment = R.map(item => {
        return {
            name: R.propOr(R.propOr("", "name", item), "value", item)
        }
    }
    )(R.pathOr([], ["assessmentForm", "formativeAssessments"], unitPlanFormData));
    return R.pipe(
        R.assocPath(["lessonPlans"], R.propOr([], "lessonPlans", unitPlanFormData)),
        R.assocPath(["status"], unitPlanStatus),
        R.assocPath(["consents"], consentData),
        R.assocPath(["unitPlanForm"], unitPlanFormData),
        R.dissocPath(["unitPlanForm", "lessonPlans"]),
        R.assocPath(["unitPlanForm", "tlmList"], TLMData),
        R.assocPath(["unitPlanForm", "assessmentForm", "formativeAssessments"], updatedFormativeAssessment)
    )(unitData);
}

export const getLessonPlanPreFormPayload = ({ lessonData, lessonPlanData, TLMData, consentData, lessonStatus = "ACTIVE" }) => {
    const lessonFormData = R.pipe(
        R.assocPath(["tlmList"], TLMData),
    )(lessonPlanData);
    return R.pipe(
        R.assocPath(["consents"], consentData),
        R.assocPath(["lessonPlanForm"], lessonFormData),
        R.assocPath(["status"], lessonStatus)
    )(lessonData);
};

export const getLessonPlanFormPayload = ({ lessonData, lessonPlanData, TLMData, consentData, DTSList }) => {
    const lessonFormData = R.pipe(
        R.assocPath(["mainContents"], TLMData),
        R.assocPath(["intoductionActivities"], R.map(item => ({ name: R.propOr(item.name, "value", item) }))(R.propOr([], "intoductionActivities", lessonPlanData))),
        R.assocPath(["teachingStrategies"], DTSList)
    )(lessonPlanData);
    return R.pipe(
        R.assocPath(["consents"], consentData),
        R.assocPath(["lessonPlanForm"], lessonFormData),
    )(lessonData);
}
export const getSelectList = (selectedData) => {
    return R.map(item => item.name)(selectedData);
};

export const setObservationsValue = (selectedData, observationsData, className) => {
    const observationRegistry = R.filter(item => {
        return R.includes(className, R.map(classItem => classItem.className)(item.grades))
    })(R.propOr([], "items", observationsData))[0];

    if (R.isEmpty(selectedData)) {
        return {};
    };
    let finalData = {};
    const allOutcomeIds = R.map(outcome => outcome.learningOutcomeId)(selectedData);
    R.mapObjIndexed((observation, index) => {
        const filteredOutcomes = R.filter(outcome => R.includes(outcome.id, allOutcomeIds))(R.propOr([], "learningOutcomes", observation));
        finalData[index] = R.map(outcome => outcome.id)(filteredOutcomes);
    })(R.propOr([], "observations", observationRegistry));
    return finalData;
}

export const isSubmitEnabled = (consents) => {
    let isEnabled = true;
    R.map(eachItem => {
        if (!eachItem.consent) {
            isEnabled = false;
        }
    })(consents);
    return isEnabled;
}

export const getUnitLessonPlanPayload = (lessonPlan, unitData) => {
    const updatedLessonPlans = R.map(lesson => {
        const updatedSubUnits = R.map(unit => ({ name: unit.name }))(lesson.subUnits);
        const curriculumContext = R.assocPath(["school"], lesson.school, unitData.curriculumContext);
        return R.pipe(
            R.assocPath(["subUnits"], updatedSubUnits),
            R.assocPath(["curriculumContext"], curriculumContext),
            R.dissocPath(["school"])
        )(lesson)
    })(lessonPlan);
    return R.assocPath(["lessonPlans"], updatedLessonPlans, unitData);
}

export const getSelectListForPlanForm = (selectedData, listItem) => {
    return listItem
      .filter((item) => selectedData.includes(item.name) || selectedData.includes(item.value))
      .map((item) => item.value);
};