import {
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  TextField,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as R from "ramda";
import { updateForm } from "state/slices/globalFormSlice";
import SelectDropDown from "common/components/SelectDropDown/SelectDropDown";
import {
  LOVApiKeys,
  LOVDataKeys,
  LOVSelectKeys,
  LOVDisplayKeys,
  LOVKeyMap,
} from "common/components/SelectDropDown/SelectDropDownUtils";
import { PAGES } from "state/slices/pageSlice";
import LOVSelect from "common/components/LOVSelect/LOVSelect";
import { removeWhiteSpace } from "common/utils/composition";

const useStyles = makeStyles((theme) => ({
  inputFormControl: {
    marginBottom: "2rem",
  },
  inputLabel: {
    top: "-17px",
  },
  inputLabel1: {
    top: "-17px",
    left: "-14px",
  },
}));

const AnnualPlanForm = ({ formType, isEditForm, isReadOnly }) => {
  const classes = useStyles();
  const annualPlanForm = useSelector((state) => state.globalForm[formType]);
  const [formData, setformData] = useState(annualPlanForm);

  const dispatch = useDispatch();

  const onChange = (event) => {
    const [value, name] = R.paths(
      [
        ["target", "value"],
        ["target", "name"],
      ],
      event
    );
    const updatedformData = R.assoc(name, value, annualPlanForm);
    setformData(updatedformData);
    dispatch(updateForm({ formType, formData: updatedformData }));
  };

  useEffect(() => {
    setformData(annualPlanForm);
  }, [annualPlanForm]);

  return (
    <form style={{ marginTop: "1rem" }}>
      <FormControl className={classes.inputFormControl}>
        <InputLabel className={classes.inputLabel}>Annual Plan Name</InputLabel>
        <TextField
          margin="normal"
          variant="outlined"
          name="name"
          disabled={isReadOnly}
          placeholder="Enter annual plan name"
          value={formData.name}
          onChange={onChange}
        />
      </FormControl>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormControl className={classes.inputFormControl}>
            <InputLabel className={classes.inputLabel}>Start Date</InputLabel>
            <TextField
              margin="normal"
              variant="outlined"
              id="date"
              name="start"
              placeholder={"Enter start date"}
              type="date"
              disabled={isReadOnly}
              sx={{ width: 220 }}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={onChange}
              value={formData.start}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl className={classes.inputFormControl}>
            <InputLabel className={classes.inputLabel}>End Date</InputLabel>
            <TextField
              variant="outlined"
              margin="normal"
              id="date"
              name="end"
              placeholder={"Enter end date"}
              type="date"
              sx={{ width: 220 }}
              InputLabelProps={{
                shrink: true,
              }}
              disabled={isReadOnly}
              onChange={onChange}
              value={formData.end}
            />
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <FormControl variant="outlined" className={classes.inputFormControl}>
            <InputLabel
              className={classes.inputLabel1}
              id="demo-simple-select-outlined-label"
            >
              Class Name
            </InputLabel>
            <LOVSelect
              margin="normal"
              formType={formType}
              selectKey={LOVSelectKeys.CLASS}
              dataKey={LOVDataKeys.CLASS}
              lovKey={LOVApiKeys.CLASS}
              value={R.pathOr("", ["curriculumContext", "gradeName"], formData)}
              setObj={false}
              isReadOnly={isEditForm}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl variant="outlined" className={classes.inputFormControl}>
            <InputLabel
              className={classes.inputLabel1}
              id="demo-simple-select-outlined-label"
            >
              Subject
            </InputLabel>
            <LOVSelect
              formType={formType}
              margin="normal"
              selectKey={LOVSelectKeys.SUBJECT}
              dataKey={LOVDataKeys.SUBJECT}
              lovKey={`SubjectClass${removeWhiteSpace(
                R.pathOr(
                  R.pathOr("", ["curriculumContext", "gradeName"], formData),
                  ["gradeName"],
                  formData
                )
              )}`}
              value={R.pathOr(
                "",
                ["curriculumContext", "subjectValue"],
                formData
              )}
              setObj={!isEditForm}
              isReadOnly={isEditForm}
            />
          </FormControl>
        </Grid>
      </Grid>
    </form>
  );
};

export default AnnualPlanForm;
