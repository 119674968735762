import {
  Box,
  Grid,
  Button,
  makeStyles,
  TextField,
  InputLabel,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import * as R from "ramda";
import { colors } from "theme/colors";
import { FORM_TYPES } from "common/utils/formUtility";
import { useDispatch, useSelector } from "react-redux";
import { updateForm } from "state/slices/globalFormSlice";
import {
  getPageList,
  saveLessonPlanDetails,
} from "components/listOfValues/utils";
import {
  getLessonPlanPreFormPayload,
  isSubmitEnabled,
  setObservationsValue,
} from "./utils";
import ConsentForm from "./ConsentForm";
import TLMReferenceForm from "./TLMReferenceForm";
import ObservationSelectionForm from "./ObservationSelectionForm";
import { PAGES, setPageListItems } from "state/slices/pageSlice";

export const LessonPrePrimaryFormHiddenElems = [
  "starterInput",
  "conceptObjective",
  "skillObjective",
  "factObjective",
  "languageOfInstruction",
  "studentVocabulary",
  "levelOfLearningInput1",
  "levelOfLearningInput3",
  "levelOfLearningInput2",
  "introNewKnowledgeInput",
  "developNewKnowledgeInput",
  "studentPracticeAssesmentInput",
  "closureInput",
  "teacherReflection",
];

const useStyle = makeStyles((theme) => ({
  heading: {
    color: "#263657",
  },
  readOnlyContainer: {
    backgroundColor: "#263657",
    padding: "1rem",
    color: colors.gray1,
    marginBottom: "1rem",
    borderRadius: 6,
  },
  readOnlyTitle: {
    fontSize: "20px",
  },
  btn: {
    "&.MuiButton-root": {
      marginRight: "1rem",
    },
  },
  visibleTextField: {
    "&.MuiInputBase-input": {
      visibility: "visible",
      overflow: "visible",
    },
  },
  inputLabel1: {
    top: "-17px",
    left: "-14px",
  },
  inputLabel: {
    top: "-17px",
  },
}));

export const getTLMList = (lessonPlanForm) =>
  R.pathOr([], ["lessonPlanForm", "mainContents"], lessonPlanForm);

const LessonPlanPrePrimaryForm = ({ lessonData, onTabChange, userRoles }) => {
  const classes = useStyle();
  const lessonPlanPrePrimaryFormData = useSelector(
    (state) => state.globalForm[FORM_TYPES.LESSON_PLAN_PRE_PRIMARY_FORM]
  );
  const [lessonPlanData, setlessonPlanData] = useState(
    lessonPlanPrePrimaryFormData
  );
  const [consentData, setConsentData] = useState(
    R.propOr([], "consents", lessonData)
  );
  const dispatch = useDispatch();
  const [TLMData, setTLMData] = useState(getTLMList(lessonData));
  let observationsData = useSelector(
    (state) => state.pages[PAGES.observations]
  );

  const onFormChange = (e, path) => {
    const updatedForm = R.assocPath(path, e.target.value, lessonPlanData);
    setlessonPlanData(updatedForm);
    dispatch(
      updateForm({
        formType: FORM_TYPES.LESSON_PLAN_PRE_PRIMARY_FORM,
        formData: updatedForm,
      })
    );
  };
  const onSaveDetails = async (e, lessonStatus = "ACTIVE") => {
    const payload = getLessonPlanPreFormPayload({
      lessonData,
      lessonPlanData,
      TLMData,
      consentData,
      lessonStatus,
    });
    const response = await saveLessonPlanDetails(payload, lessonData.id);
    onTabChange();
  };

  const getObservationsData = async () => {
    const pageListData = await getPageList({
      searchKey: "",
      page: PAGES.observations,
    });
    dispatch(
      setPageListItems({
        page: PAGES.observations,
        itemList: pageListData.content,
        totalElements: pageListData.totalElements,
        totalPages: pageListData.totalPages,
      })
    );
  };

  useEffect(() => {
    if (R.isEmpty(observationsData.items)) {
      getObservationsData();
    }
    setlessonPlanData(lessonPlanPrePrimaryFormData);
    if (R.isEmpty(TLMData)) {
      setTLMData(getTLMList(lessonData));
    }
  }, [observationsData, lessonPlanPrePrimaryFormData, lessonData]);

  const isCompleted = false;

  return (
    <Box
      sx={{
        margin: "1rem ",
      }}
      fullwidth
    >
      <h3 className={classes.heading} align={"left"}>
        Periods
      </h3>
      <Grid item xs={4}>
        <TextField
          variant="outlined"
          type="number"
          id="multiline-static"
          value={R.pathOr("", ["periods"], lessonPlanData)}
          fullWidth
          InputProps={{ inputProps: { min: 0 } }}
          onChange={(e) => onFormChange(e, ["periods"])}
          disabled={isCompleted}
        />
      </Grid>

      <h3 className={classes.heading} align={"left"}>
        Gaining Attention/Hook/Starter/Activating Prior Knowledge
      </h3>
      <InputLabel>How do you gain your student's attention?</InputLabel>
      <TextField
        className={classes.visibleTextField}
        id="multiline-static"
        margin="normal"
        variant="outlined"
        placeholder={"How do you gain your student's attention?"}
        multiline
        fullWidth
        data-html2canvas-ignore
        value={R.pathOr("", ["starterInput"], lessonPlanData)}
        onChange={(e) => onFormChange(e, ["starterInput"])}
        disabled={isCompleted}
      />
      <Box fullWidth id="starterInput" display={"none"}>
        {R.pathOr("", ["starterInput"], lessonPlanData)}
      </Box>
      <h3 className={classes.heading} align={"left"}>
        Teaching Learning Material
      </h3>

      <TLMReferenceForm
        displayLOVSelect={true}
        TLMData={TLMData}
        setTLMData={setTLMData}
        isReadOnly={isCompleted}
      />

      <h3 className={classes.heading} align={"left"}>
        Learning Objectives for the Lesson(Inform Students aboth what they are
        going to learn today)SWBA
      </h3>
      <h4 align={"left"}>
        Concept Related Objectives (Remembering & Recalling)
      </h4>
      <InputLabel>Students will be able to:</InputLabel>
      <TextField
        className={classes.visibleTextField}
        id="multiline-static"
        margin="normal"
        variant="outlined"
        placeholder="Students will be able to"
        multiline
        fullWidth
        data-html2canvas-ignore
        value={R.pathOr(
          "",
          ["learningObjectiveForm", "conceptObjective"],
          lessonPlanData
        )}
        onChange={(e) =>
          onFormChange(e, ["learningObjectiveForm", "conceptObjective"])
        }
        disabled={isCompleted}
      />
      <Box fullWidth id="conceptObjective" display={"none"}>
        {R.pathOr(
          "",
          ["learningObjectiveForm", "conceptObjective"],
          lessonPlanData
        )}
      </Box>
      <h4 align={"left"}>
        Skill Related Objectives (Applying, Analysing, Evaluating, Creating)
      </h4>
      <InputLabel>Students will be able to:</InputLabel>
      <TextField
        className={classes.visibleTextField}
        id="multiline-static"
        margin="normal"
        variant="outlined"
        placeholder="Students will be able to"
        multiline
        value={R.pathOr(
          "",
          ["learningObjectiveForm", "skillObjective"],
          lessonPlanData
        )}
        data-html2canvas-ignore
        fullWidth
        onChange={(e) =>
          onFormChange(e, ["learningObjectiveForm", "skillObjective"])
        }
        disabled={isCompleted}
      />
      <Box fullWidth id="skillObjective" display={"none"}>
        {R.pathOr(
          "",
          ["learningObjectiveForm", "skillObjective"],
          lessonPlanData
        )}
      </Box>
      <h4 align={"left"}>Fact Related Objectives (Remembering & Recalling)</h4>
      <InputLabel>Students will be able to:</InputLabel>
      <TextField
        className={classes.visibleTextField}
        id="multiline-static"
        margin="normal"
        variant="outlined"
        placeholder="Students will be able to"
        multiline
        value={R.pathOr(
          "",
          ["learningObjectiveForm", "factObjective"],
          lessonPlanData
        )}
        data-html2canvas-ignore
        fullWidth
        onChange={(e) =>
          onFormChange(e, ["learningObjectiveForm", "factObjective"])
        }
        disabled={isCompleted}
      />
      <Box fullWidth id="factObjective" display={"none"}>
        {R.pathOr(
          "",
          ["learningObjectiveForm", "factObjective"],
          lessonPlanData
        )}
      </Box>
      <h4 align={"left"}>Vocabulary Related Objectives</h4>
      <InputLabel>
        Language of Instruction (used by teacher, comprehended by students)
      </InputLabel>
      <TextField
        className={classes.visibleTextField}
        id="multiline-static"
        margin="normal"
        variant="outlined"
        placeholder="Language of Instruction (used by teacher, comprehended by students)"
        multiline
        value={R.pathOr(
          "",
          [
            "learningObjectiveForm",
            "vocabularyObjective",
            "languageOfInstruction",
          ],
          lessonPlanData
        )}
        data-html2canvas-ignore
        fullWidth
        onChange={(e) =>
          onFormChange(e, [
            "learningObjectiveForm",
            "vocabularyObjective",
            "languageOfInstruction",
          ])
        }
        disabled={isCompleted}
      />
      <Box fullWidth id="languageOfInstruction" display={"none"}>
        {R.pathOr(
          "",
          [
            "learningObjectiveForm",
            "vocabularyObjective",
            "languageOfInstruction",
          ],
          lessonPlanData
        )}
      </Box>
      <InputLabel>
        Student Vocabulary (internalised and use in context)
      </InputLabel>
      <TextField
        className={classes.visibleTextField}
        id="multiline-static"
        margin="normal"
        variant="outlined"
        placeholder="Student Vocabulary (internalised and use in context)"
        multiline
        value={R.pathOr(
          "",
          ["learningObjectiveForm", "vocabularyObjective", "studentVocabulary"],
          lessonPlanData
        )}
        data-html2canvas-ignore
        fullWidth
        onChange={(e) =>
          onFormChange(e, [
            "learningObjectiveForm",
            "vocabularyObjective",
            "studentVocabulary",
          ])
        }
        disabled={isCompleted}
      />
      <Box fullWidth id="studentVocabulary" display={"none"}>
        {R.pathOr(
          "",
          ["learningObjectiveForm", "vocabularyObjective", "studentVocabulary"],
          lessonPlanData
        )}
      </Box>

      <h3 className={classes.heading} align={"left"}>
        Learning Outcomes
      </h3>

      <ObservationSelectionForm
        className={R.pathOr("", ["curriculumContext", "gradeName"], lessonData)}
        formType={FORM_TYPES.LESSON_PLAN_PRE_PRIMARY_FORM}
        value={setObservationsValue(
          R.pathOr([], ["learningOutcomes"], lessonPlanData),
          observationsData,
          R.pathOr("", ["curriculumContext", "gradeName"], lessonData)
        )}
        isReadOnly={isCompleted}
      />
      <br />

      <h3 className={classes.heading} align={"left"}>
        Level/s of Learning
      </h3>
      <InputLabel>All learners will be able to:</InputLabel>
      <TextField
        className={classes.visibleTextField}
        id="multiline-static"
        placeholder="All learners will be able to"
        margin="normal"
        variant="outlined"
        multiline
        fullWidth
        data-html2canvas-ignore
        value={R.pathOr(
          "",
          ["levelOfLeaningForm", "levelOfLearningInput1"],
          lessonPlanData
        )}
        onChange={(e) =>
          onFormChange(e, ["levelOfLeaningForm", "levelOfLearningInput1"])
        }
        disabled={isCompleted}
      />
      <Box fullWidth id="levelOfLearningInput1" display={"none"}>
        {R.pathOr(
          "",
          ["levelOfLeaningForm", "levelOfLearningInput1"],
          lessonPlanData
        )}
      </Box>
      <br />
      <br />
      <InputLabel>Some learners will be able to:</InputLabel>

      <TextField
        className={classes.visibleTextField}
        id="multiline-static"
        placeholder="Some learners may be able to"
        margin="normal"
        variant="outlined"
        multiline
        fullWidth
        data-html2canvas-ignore
        value={R.pathOr(
          "",
          ["levelOfLeaningForm", "levelOfLearningInput3"],
          lessonPlanData
        )}
        onChange={(e) =>
          onFormChange(e, ["levelOfLeaningForm", "levelOfLearningInput3"])
        }
        disabled={isCompleted}
      />
      <Box fullWidth id="levelOfLearningInput3" display={"none"}>
        {R.pathOr(
          "",
          ["levelOfLeaningForm", "levelOfLearningInput3"],
          lessonPlanData
        )}
      </Box>
      <br />
      <br />
      <InputLabel>Few learners will be able to:</InputLabel>
      <TextField
        className={classes.visibleTextField}
        id="multiline-static"
        placeholder="Few learners may be able to"
        margin="normal"
        variant="outlined"
        multiline
        fullWidth
        data-html2canvas-ignore
        value={R.pathOr(
          "",
          ["levelOfLeaningForm", "levelOfLearningInput2"],
          lessonPlanData
        )}
        onChange={(e) =>
          onFormChange(e, ["levelOfLeaningForm", "levelOfLearningInput2"])
        }
        disabled={isCompleted}
      />
      <Box fullWidth id="levelOfLearningInput2" display={"none"}>
        {R.pathOr(
          "",
          ["levelOfLeaningForm", "levelOfLearningInput2"],
          lessonPlanData
        )}
      </Box>
      <br />
      <br />
      <h3 className={classes.heading} align={"left"}>
        Introducing New Knowledge
      </h3>
      <InputLabel>
        How do you represent the material for maximum retention?
      </InputLabel>
      <TextField
        className={classes.visibleTextField}
        id="multiline-static"
        margin="normal"
        variant="outlined"
        placeholder="How do you represent the material for maximum retention?"
        multiline
        fullWidth
        data-html2canvas-ignore
        onChange={(e) => onFormChange(e, ["introNewKnowledgeInput"])}
        value={R.pathOr("", ["introNewKnowledgeInput"], lessonPlanData)}
        disabled={isCompleted}
      />
      <Box fullWidth id="introNewKnowledgeInput" display={"none"}>
        {R.pathOr("", ["introNewKnowledgeInput"], lessonPlanData)}
      </Box>
      <br />
      <br />
      <h3 className={classes.heading} align={"left"}>
        Developing/Facilitating New Knowledge
      </h3>
      <InputLabel>How do you help them make connections?</InputLabel>
      <TextField
        className={classes.visibleTextField}
        id="multiline-static"
        placeholder="How do you help them make connections?"
        margin="normal"
        variant="outlined"
        multiline
        fullWidth
        data-html2canvas-ignore
        onChange={(e) => onFormChange(e, ["developNewKnowledgeInput"])}
        value={R.pathOr("", ["developNewKnowledgeInput"], lessonPlanData)}
        disabled={isCompleted}
      />
      <Box fullWidth id="developNewKnowledgeInput" display={"none"}>
        {R.pathOr("", ["developNewKnowledgeInput"], lessonPlanData)}
      </Box>
      <br />
      <br />

      <h3 className={classes.heading} align={"left"}>
        Student Practice/Feedback/Formative Assessment
      </h3>
      <TextField
        className={classes.visibleTextField}
        id="multiline-static"
        variant="outlined"
        placeholder="Student Practice/Feedback/Formative Assessment"
        multiline
        fullWidth
        data-html2canvas-ignore
        onChange={(e) => onFormChange(e, ["studentPracticeAssesmentInput"])}
        value={R.pathOr("", ["studentPracticeAssesmentInput"], lessonPlanData)}
        disabled={isCompleted}
      />
      <Box fullWidth id="studentPracticeAssesmentInput" display={"none"}>
        {R.pathOr("", ["studentPracticeAssesmentInput"], lessonPlanData)}
      </Box>
      <br />
      <br />

      <h3 className={classes.heading} align={"left"}>
        Closure (Retention of New Learning)
      </h3>

      <TextField
        className={classes.visibleTextField}
        id="multiline-static"
        variant="outlined"
        placeholder="Closure (Retention of New Learning)"
        multiline
        fullWidth
        data-html2canvas-ignore
        onChange={(e) => onFormChange(e, ["closureInput"])}
        value={R.pathOr("", ["closureInput"], lessonPlanData)}
        disabled={isCompleted}
      />
      <Box fullWidth id="closureInput" display={"none"}>
        {R.pathOr("", ["closureInput"], lessonPlanData)}
      </Box>
      <br />
      <br />

      <h3 className={classes.heading} align={"left"}>
        Teacher Reflections
      </h3>
      <TextField
        className={classes.visibleTextField}
        id="multiline-static"
        multiline
        variant="outlined"
        placeholder="Teacher Reflections"
        data-html2canvas-ignore
        fullWidth
        value={R.propOr("", "teacherReflection", lessonPlanData)}
        onChange={(e) => onFormChange(e, ["teacherReflection"])}
        disabled={isCompleted}
      />
      <Box fullWidth id="teacherReflection" display={"none"}>
        {R.propOr("", "teacherReflection", lessonPlanData)}
      </Box>
      <br />
      <h3 className={classes.heading} align={"left"}>
        Consents
      </h3>
      <ConsentForm
        page={"lessonPlan"}
        userRoles={userRoles}
        consentData={consentData}
        setConsentData={setConsentData}
        isCompleted={isCompleted}
      />
      <br />
      <Box sx={{ display: "flex" }}>
        <Button
          color="primary"
          onClick={(e) => onSaveDetails(e)}
          variant="contained"
          className={classes.btn}
          disabled={isCompleted}
          data-html2canvas-ignore
        >
          Save
        </Button>
        <Button
          color="primary"
          onClick={(e) => onSaveDetails(e, "COMPLETED")}
          variant="contained"
          disabled={!isSubmitEnabled(consentData) || isCompleted}
          data-html2canvas-ignore
        >
          Submit
        </Button>
      </Box>
    </Box>
  );
};

export default LessonPlanPrePrimaryForm;
