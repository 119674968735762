import React from "react";
import {
  makeStyles,
  Typography,
  Box,
  Card,
  CardContent,
  Grid,
} from "@material-ui/core";
import { ALTERNATE_FILE_ICON } from "asset/images";
import MoreVerticalDot from "components/academicPlanDetails/MoreVerticalDot";
import EditIcon from "@mui/icons-material/Edit";
import { useSelector } from "react-redux";
import { deepPurple } from "@material-ui/core/colors";
import DeleteIcon from "@mui/icons-material/Delete";
import { deleteUserRoleMapping } from "components/listOfValues/utils";

const useStyles = makeStyles((theme) => ({
  cardBody: {
    width: "100%",
    background: "#FFFFFF",
    borderRadius: "14px !important",
  },
  editIcon: {
    marginRight: "10px",
  },
  cardContent: {
    padding: "26px 18px !important",
  },
  cardTitle: {
    fontStyle: "normal",
    fontWeight: "800",
    fontSize: "18px",
    lineHeight: "22px",
    color: "#263657",
    display: "flex",
    alignItems: "center",
  },
  fileIcon: {
    marginRight: "10px",
  },
  cardTitleWrapper: {
    display: "flex",
    justifyContent: "initial",
    alignItems: "center",
    width: "100%",
  },
  subTitle: {
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "8px",
    lineHeight: "10px",
    color: "#898EAC",
  },
  cardBodyContent: {
    marginTop: "18px",
  },
  bodyText: {
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "12px",
    lineHeight: "14px",
    color: "#000000",
  },
  dotStyle: {
    width: "3.86px",
    height: "3.86px",
    background: "#898EAC",
    borderRadius: "50%",
    display: "inline-block",
    verticalAlign: "middle",
    margin: "auto 7px",
  },
  inputFormControl: {
    margin: "1.5rem",
  },
  flexGrid: {
    "&.MuiGrid-root": {
      display: "flex",
    },
  },
  inputLabel1: {
    top: "-17px",
    left: "-14px",
  },
  inputLabel: {
    top: "-17px",
  },
  placeholderValue: {
    color: "#bbb",
  },
  avatar: {
    background: deepPurple[500],
    height: "24px",
    width: "24px",
    marginRight: "10px",
    fontSize: "16px",
  },
  cardBottom: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  createdBy: {
    display: "flex",
    flexDirection: "column",
  },
  createdBySection: {
    display: "flex",
    marginTop: "18px",
  },
  statusBlock: {
    marginTop: "18px",
  },
  modifyIcons: {
    width: "65px",
    textAlign: "end",
  },
}));

const RolesCard = ({
  data,
  userRoles,
  setModalState,
  onCardClick,
  isDetailedPage,
  getStaffDetails,
}) => {
  const classes = useStyles();
  const AllowedRoles = ["Administrator", "Inspire Us-Core"];
  const roles = useSelector((state) => state.pages.roles.items);

  const isEditEnabled = (roles) => {
    let editEnabled = false;
    roles.forEach((role) => {
      if (AllowedRoles.includes(role)) {
        editEnabled = true;
      }
    });
    return editEnabled;
  };

  const handleClick = (id) => {
    onCardClick(id);
  };

  const isEditable = isEditEnabled(userRoles);
  const handleDeleteRoles = async () => {
    await deleteUserRoleMapping(data.userId, data.id);
    if (getStaffDetails) getStaffDetails();
  };

  return (
    <>
      <Grid item className={classes.annualPlanCard} md="6" xs="12">
        <Box
          sx={{ minWidth: 275 }}
          onClick={() => handleClick(data.roleId)}
          style={{ cursor: "pointer" }}
        >
          <Card
            variant="outlined"
            className={classes.cardBody}
            style={{ background: isDetailedPage ? "#E3D5CA" : "#FFF" }}
          >
            <CardContent className={classes.cardContent}>
              <Typography className={classes.cardTitle} variant="div">
                <div className={classes.cardTitleWrapper}>
                  <img
                    className={classes.fileIcon}
                    src={ALTERNATE_FILE_ICON}
                    alt="File Icon"
                  />
                  <span>
                    {roles.find((role) => role.id === data.roleId)?.name}
                  </span>
                </div>
                {/* {privileges?.UPDATE && !hideModification && ( */}
                <div className={classes.verticalDotsButton}>
                  <MoreVerticalDot
                    options={[
                      {
                        icon: (
                          <EditIcon
                            fontSize="small"
                            className={classes.editIcon}
                          />
                        ),
                        label: "Edit Role",
                        value: "Edit Role",
                        // isDisabled:
                        //   data?.status?.toUpperCase() === "COMPLETED"
                        //     ? !isEditable
                        //     : !privileges?.UPDATE,
                      },
                      {
                        icon: (
                          <DeleteIcon
                            fontSize="small"
                            className={classes.editIcon}
                          />
                        ),
                        label: "Delete Role",
                        value: "Delete Role",
                        // isDisabled:
                        //   data?.status?.toUpperCase() === "COMPLETED"
                        //     ? !isEditable
                        //     : !privileges?.UPDATE,
                      },
                    ]}
                    handleClick={(e, value) => {
                      e.stopPropagation();
                      if (value === "Delete Role") {
                        data?.id && handleDeleteRoles();
                      } else {
                        setModalState((prev) => ({
                          ...prev,
                          isOpen: true,
                          isEdit: true,
                          data,
                        }));
                      }
                    }}
                  />
                </div>
                {/* )} */}
              </Typography>

              {/* {!isDetailedPage && ( */}
              {/* <div className={classes.cardBottom}>
                <Link
                  component="button"
                  variant="body2"
                  className={classes.viewMore}
                >
                  {"> Click to expand view"}
                </Link>
              </div> */}
              {/* )} */}
            </CardContent>
          </Card>
        </Box>
      </Grid>
    </>
  );
};

export default RolesCard;
