import {
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  TextField,
} from "@material-ui/core";
import LOVSelect from "common/components/LOVSelect/LOVSelect";
import SelectDropDown from "common/components/SelectDropDown/SelectDropDown";
import {
  LOVApiKeys,
  LOVDataKeys,
  LOVDisplayKeys,
  LOVKeyMap,
  LOVSelectKeys,
} from "common/components/SelectDropDown/SelectDropDownUtils";
import { removeWhiteSpace } from "common/utils/composition";
import * as R from "ramda";
import { useState } from "react";
import { useSelector } from "react-redux";
import { PAGES } from "state/slices/pageSlice";

const useStyles = makeStyles((theme) => ({
  inputFormControl: {
    marginBottom: "2rem",
  },
  inputLabel: {
    top: "-17px",
  },
  inputLabel1: {
    top: "-17px",
    left: "-14px",
  },
}));

const AnnualPlanForm = ({
  formType,
  isEditForm,
  isReadOnly,
  state,
  handleChange,
}) => {
  const classes = useStyles();
  const [selectedClass, setSelectedClass] = useState(null);

  const dropdownListData = useSelector(
    (state) => state?.pages?.[PAGES.schools]?.items || []
  );

  const onChange = (event) => {
    const [value, name] = R.paths(
      [
        ["target", "value"],
        ["target", "name"],
      ],
      event
    );
    handleChange(name, value);
  };

  const getSelectList = (value) => {
    return value;
  };

  return (
    <form style={{ marginTop: "1rem" }}>
      <Grid container spacing={2}>
        {/* <Grid item xs={3}>
          <FormControl variant="outlined" className={classes.inputFormControl}>
            <InputLabel
              className={classes.inputLabel1}
              id="demo-simple-select-outlined-label"
            >
              Annual Period
            </InputLabel>
            <LOVSelect
              margin="normal"
              formType={formType}
              selectKey={LOVSelectKeys.ANNUAL_PERIOD}
              dataKey={LOVDataKeys.ANNUAL_PERIOD}
              lovKey={LOVKeyMap.ANNUAL_PERIOD}
              value={state.annualPeriod || ""}
              setObj={false}
              isReadOnly={isEditForm}
              customOnChange={(value) => handleChange("annualPeriod", value)}
            />
          </FormControl>
        </Grid> */}
        <Grid item xs={12}>
          <FormControl variant="outlined" className={classes.inputFormControl}>
            <InputLabel className={classes.inputLabel}>
              Annual Plan Name
            </InputLabel>
            <TextField
              variant="outlined"
              name="name"
              disabled={isReadOnly}
              placeholder="Enter annual plan name"
              value={state?.name || ""}
              onChange={onChange}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <FormControl variant="outlined" className={classes.inputFormControl}>
            <InputLabel
              className={classes.inputLabel1}
              id="demo-simple-select-outlined-label1"
            >
              Annual Period
            </InputLabel>
            <LOVSelect
              margin="normal"
              formType={formType}
              selectKey={LOVSelectKeys.ANNUAL_PERIOD}
              dataKey={LOVDataKeys.ANNUAL_PERIOD}
              lovKey={LOVKeyMap.ANNUAL_PERIOD}
              value={state?.annual_period || ""}
              setObj={false}
              isReadOnly={isEditForm}
              customOnChange={(value) => {
                handleChange(LOVSelectKeys.ANNUAL_PERIOD, value);
              }}
            />
          </FormControl>
        </Grid>

        <Grid item xs={12} md={6}>
          <FormControl variant="outlined" className={classes.inputFormControl}>
            <InputLabel
              className={classes.inputLabel1}
              id="demo-simple-select-outlined-label"
            >
              Class Name
            </InputLabel>
            <LOVSelect
              margin="normal"
              formType={formType}
              selectKey={LOVSelectKeys.CLASS}
              dataKey={LOVDataKeys.CLASS}
              lovKey={LOVApiKeys.CLASS}
              value={selectedClass || ""}
              setObj={true}
              isReadOnly={isEditForm}
              customOnChange={(data) => {
                setSelectedClass(data);
                handleChange(LOVSelectKeys.CLASS, data.name);
              }}
            />
          </FormControl>
        </Grid>

        {["VI", "VII", "VIII"].includes(state.gradeName) && (
          <Grid item xs={12} md={6}>
            <FormControl
              variant="outlined"
              className={classes.inputFormControl}
            >
              <InputLabel
                className={classes.inputLabel1}
                id="demo-simple-select-outlined-label"
              >
                School
              </InputLabel>
              <SelectDropDown
                margin="normal"
                variant="outlined"
                selectKey={LOVSelectKeys.SCHOOL}
                apiKey={LOVApiKeys.SCHOOL}
                dataKey={LOVDataKeys.SCHOOL}
                displayKey={LOVDisplayKeys.SCHOOL}
                secondaryDataKey={"campus"}
                selectedValue={
                  dropdownListData.find(
                    (item) => item?.schoolCode === state?.schoolCode
                  ) || {}
                }
                page={PAGES.schools}
                customOnChange={(selectedValue) =>
                  handleChange("schoolCode", selectedValue.schoolCode)
                }
                // isDisabled={isSchoolDisable}
              />
            </FormControl>
          </Grid>
        )}

        <Grid item xs={12} md={6}>
          <FormControl variant="outlined" className={classes.inputFormControl}>
            <InputLabel
              className={classes.inputLabel1}
              id="demo-simple-select-outlined-label"
            >
              Subject
            </InputLabel>
            <LOVSelect
              formType={formType}
              margin="normal"
              selectKey={LOVSelectKeys.SUBJECT}
              dataKey={LOVDataKeys.SUBJECT}
              lovKey={`SubjectClass${removeWhiteSpace(state?.gradeName)}`}
              value={state?.subject?.value || ""}
              setObj={!isEditForm}
              isReadOnly={isEditForm}
              getSelectList={getSelectList}
              customOnChange={(value) => handleChange("subject", value)}
            />
          </FormControl>
        </Grid>
      </Grid>
    </form>
  );
};

export default AnnualPlanForm;
