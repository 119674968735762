import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import React from "react";

const ConfirmationPopup = ({
  isOpen,
  setIsOpen,
  handleAction,
  confirmationText,
}) => {
  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={isOpen}
      onClose={() => setIsOpen(false)}
      aria-labelledby="form-dialog-title"
    >
      <Grid container>
        <Grid item xs={10} md={11}>
          <DialogTitle id="form-dialog-title">Confirmation</DialogTitle>
        </Grid>
        <Grid item xs={2} md={1}>
          <IconButton onClick={() => setIsOpen(false)}>
            <Close />
          </IconButton>
        </Grid>
      </Grid>
      <DialogContent>
        <Typography varinat="h4">{confirmationText}</Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => setIsOpen(false)}
          variant="outlined"
          type="button"
          color="primary"
        >
          Cancel
        </Button>
        <Button
          onClick={() => handleAction()}
          variant="contained"
          type="button"
          color="primary"
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationPopup;
