import {
  Box,
  Button,
  Grid,
  DialogTitle,
  DialogContent,
  IconButton,
  Dialog,
  Typography,
} from "@material-ui/core";
import LessonPlanPrePrimaryForm from "common/forms/LessonPlanPrePrimaryForm";
import { PAGE_RENDERER } from "common/utils/rendererList";
import GridViewWrapper from "common/wrappers/GridViewWrapper";
import React, { useEffect, useRef, useState } from "react";
import { PAGES } from "state/slices/pageSlice";
import * as R from "ramda";
import { makeStyles } from "@material-ui/styles";
import { colors } from "theme/colors";
import LessonPlanForm from "common/forms/LessonPlanForm";
import { clearPageFilters } from "state/slices/globalFormSlice";
import { Close } from "@material-ui/icons";
import { useDispatch } from "react-redux";
import { getDownloadFileName } from "common/utils/downloadAsPdf";
import DisplaySubUnits from "common/components/DisplaySubUnits";
import { CloudDownload } from "@material-ui/icons";
import withPrivilege from "common/hocs/withPrivilege";
import { EntityMap } from "util/roleUtils";
import moment from "moment";
import { GLOBAL_DATE_FORMAT } from "constants/PageConstants";
import { useReactToPrint } from "react-to-print";

const useStyle = makeStyles((theme) => ({
  heading: {
    color: "#263657",
  },
  readOnlyContainer: {
    backgroundColor: "#263657",
    padding: "1rem 2rem",
    color: colors.gray1,
    marginBottom: "1rem",
    borderRadius: 6,
  },
  readOnlyTitle: {
    fontSize: "20px",
  },
  btn: {
    "&.MuiButton-root": {
      marginRight: "1rem",
    },
  },
  subUnitChip: {
    "&.MuiChip-root": {
      marginRight: "1rem",
      color: colors.blue1,
      fontSize: "16px",
      fontWeight: "500",
    },
  },
  downloadBtn: {
    "&.MuiButton-root": {
      border: `1 px solid ${colors.gray1}`,
    },
    background: "#263657",
    color: colors.gray1,
    border: "1px solid #fff",
    padding: "10px 15px",
    margin: 0,
  },
  inputLabel1: {
    top: "-17px",
    left: "-14px",
  },
  inputLabel: {
    top: "-17px",
  },
}));

export const LessonReadOnlyData = ({ data, downloadAsPdf }) => {
  const classes = useStyle();
  return (
    <Grid container className={classes.readOnlyContainer}>
      <Grid item xs={12} md={9}>
        <Typography className={classes.readOnlyTitle}>
          <b>{R.propOr("", "name", data)}</b>
        </Typography>
      </Grid>
      <Grid item xs={12} md={3} style={{ marginTop: "1rem" }}>
        <Button
          endIcon={<CloudDownload />}
          className={classes.downloadBtn}
          variant="text"
          data-html2canvas-ignore
          onClick={downloadAsPdf}
        >
          Download
        </Button>
      </Grid>
      <Grid container spacing={1} style={{ marginTop: "1rem" }}>
        <Grid item xs={12}>
          <Typography>
            School:{" "}
            {R.pathOr("", ["curriculumContext", "school", "schoolName"], data)},
            {R.pathOr("", ["curriculumContext", "school", "campus"], data)}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>
            Class: {R.pathOr("", ["curriculumContext", "gradeName"], data)}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>
            Subject:{" "}
            {R.pathOr("", ["curriculumContext", "subject", "subjectName"], data)}
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography>
            Start:{" "}
            {moment(R.pathOr("", ["start"], data)).format(GLOBAL_DATE_FORMAT)}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>
            End: {moment(R.pathOr("", ["end"], data)).format(GLOBAL_DATE_FORMAT)}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography>Status: {R.pathOr("", ["status"], data)}</Typography>
        </Grid>
      </Grid>
      <DisplaySubUnits subUnits={data.subUnits} />
    </Grid>
  );
};

const ManageLessonPlan = ({ privileges, userRoles }) => {
  const printRef = useRef();
  const [value, setValue] = useState(0);
  const [selectedLesson, setSelectedLesson] = useState({});
  const dispatch = useDispatch();
  const onTabChange = (value, data) => {
    setSelectedLesson(data);
    setValue(value);
  };

  useEffect(() => {
    dispatch(clearPageFilters());
  }, []);

  const downloadFileName = getDownloadFileName({
    data: selectedLesson,
    templateId: "lesson",
  });

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: downloadFileName,
  });

  return (
    <>
      {value === 1 ? (
        <>
          <Dialog
            maxWidth="md"
            fullWidth
            open={true}
            onClose={() => setValue(0)}
            aria-labelledby="form-dialog-title"
          >
            <Grid container>
              <Grid item xs={11}>
                <DialogTitle id="form-dialog-title">
                  <Typography variant="h6">Edit Lesson Plan</Typography>
                </DialogTitle>
              </Grid>
              <Grid item xs={1}>
                <IconButton onClick={() => setValue(0)}>
                  <Close />
                </IconButton>
              </Grid>
            </Grid>
            <DialogContent style={{ padding: "8px 25px" }}>
              <Box id="lessonPlanForm" ref={printRef} sx={{ padding: "1rem" }}>
                {!R.isNil(
                  R.propOr(null, "curriculumContext", selectedLesson)
                ) && (
                  <LessonReadOnlyData
                    data={selectedLesson}
                    downloadAsPdf={handlePrint}
                  />
                )}
                <Box>
                  {R.pathOr("", ["lessonPlanForm", "type"], selectedLesson) ===
                  "PRECLASS" ? (
                    <LessonPlanPrePrimaryForm
                      lessonData={selectedLesson}
                      onTabChange={() => setValue(0)}
                      userRoles={userRoles}
                    />
                  ) : (
                    <LessonPlanForm
                      lessonData={selectedLesson}
                      onTabChange={() => setValue(0)}
                      userRoles={userRoles}
                    />
                  )}
                </Box>
              </Box>
            </DialogContent>
          </Dialog>
        </>
      ) : null}
      <GridViewWrapper
        page={PAGES.lessonPlan}
        paginatedListRenderer={PAGE_RENDERER.LESSON_PLAN}
        displayAdd={false}
        onTabChange={onTabChange}
        showFilters={true}
        privileges={privileges}
        userRoles={userRoles}
      />
    </>
  );
};

export default withPrivilege(
  ManageLessonPlan,
  EntityMap.lessonplan,
  EntityMap.lessonplanform
);
