import { Grid, makeStyles, useMediaQuery, useTheme } from "@material-ui/core";
import withPrivilege from "common/hocs/withPrivilege";
import { useEffect, useState } from "react";
import { colors } from "theme/colors";
import { EntityMap } from "util/roleUtils";
import "../styles.css";
import { getDetailsById } from "components/listOfValues/utils";
import { useParams } from "react-router-dom";
import PlanDetailsPage from "./PlanDetailsPage";
import LessonPlanDetailCard from "./LessonPlanDetailCard";
import LessonPlanHeader from "./LessonPlanHeader";
import BreadCrumb from "../BreadCrumb";
import LessonPlanCard from "./LessonPlanCard";

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    flexWrap: "nowrap",
  },
  filterSection: {
    background: "white",
  },
  planSection: {
    height: "100%",
    minHeight: "100vh",
    width: "100%",
    padding: "47px 51px",
    backgroundColor: colors.gray5,
    [theme.breakpoints.down("sm")]: {
      padding: "36px",
    },
  },
  planCard: {
    marginBottom: "30px",
  },
}));

function LessonPlanDetails(props) {
  const classes = useStyles();
  const theme = useTheme();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down("sm"));

  const [modalState, setModalState] = useState({
    isOpen: false,
    data: null,
    isEdit: false,
  });
  const [state, setState] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    getPlanDetails();
  }, []);

  const getPlanDetails = async () => {
    const response = await getDetailsById("/lesson-plan", id);
    setState(response || null);
  };

  return (
    <Grid container direction="row" className={classes.gridContainer}>
      {!isSmallDevice && (
        <Grid className={classes.filterSection} md="3">
          <BreadCrumb
            planId={state?.unitPlanId || ""}
            planName={"LESSON_PLAN_DETAILS"}
            parentPath="lesson-plans"
          />
          <LessonPlanDetailCard
            data={state}
            getPlanDetails={getPlanDetails}
            {...props}
            privileges={props?.privileges?.lessonplan}
          />
        </Grid>
      )}
      <Grid className={classes.planSection} md="9" xs="12">
        {isSmallDevice && (
          <BreadCrumb
            planId={state?.unitPlanId || ""}
            planName={"LESSON_PLAN_DETAILS"}
            parentPath="lesson-plans"
          />
        )}
        <LessonPlanHeader
          setModalState={setModalState}
          parentState={state}
          {...props}
          isSmallDevice={isSmallDevice}
        />
        {isSmallDevice && (
          <Grid item className={classes.planCard} xs="12">
            <LessonPlanCard data={state} {...props} hideModification={true} isDetailedPage={true} />
          </Grid>
        )}
        <PlanDetailsPage
          {...props}
          modalState={modalState}
          setModalState={setModalState}
          parentState={state}
          getPlanDetails={getPlanDetails}
        />
      </Grid>
    </Grid>
  );
}

export default withPrivilege(
  LessonPlanDetails,
  EntityMap.lessonplan,
  EntityMap.lessonplanform
);
