import { Box, Tab, Tabs, styled } from "@material-ui/core";
import * as React from "react";

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    maxWidth: 40,
    width: "100%",
    backgroundColor: "#263657",
  },
  "& .MuiTabs-fixed": {
    overflowX: 'auto !important'
  },
  "& .MuiTabs-scroller": {
    "&::-webkit-scrollbar-thumb": {
      display: 'none'
  }
  }
});

const StyledTab = styled((props) => <Tab disableRipple {...props}/>)(
  ({ theme }) => ({
    textTransform: "none",
    fontWeight: 600,
    fontSize: "16px",
    marginRight: theme.spacing(1),
    color: "#263657",
    textAlign: "start",
  })
);

export default function CustomizedTabs(props) {
  const { tabList, setActiveTab } = props;
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setActiveTab?.(newValue);
  };

  return (
    <Box sx={{ width: '100%', marginBottom: "20px" }}>
      <StyledTabs
        value={value}
        onChange={handleChange}
        aria-label="ant example"
      >
        {tabList.map((tabItem) => (
          <StyledTab label={tabItem} disabled={tabItem === 'Teacher Reflections'}/>
        ))}
      </StyledTabs>
    </Box>
  );
}
