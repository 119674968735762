import { Grid, makeStyles } from "@material-ui/core";
import { useState } from "react";
import { colors } from "theme/colors";
import "../../../components/academicPlanDetails/styles.css";
import { GoalReviewConfigurationInitialState } from "state/globalForms/initialStates";
import ManageGoalReviewConfiguration from "./ManageGoalReviewConfiguration";

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    flexWrap: "nowrap",
  },
  filterSection: {
    background: "white",
  },
  planSection: {
    height: "100%",
    minHeight: "100vh",
    padding: "47px 51px",
    backgroundColor: colors.gray5,
  },
}));

function ManageGoalReviewConfigurationList(props) {
  const classes = useStyles();
  const [modalState, setModalState] = useState({
    isConfigurationModelOpen: false,
    isTimelineModelOpen: false,
    data: GoalReviewConfigurationInitialState,
    isEdit: false,
  });

  return (
    <Grid container direction="row" className={classes.gridContainer}>
      <Grid className={classes.planSection} md="12">
        <ManageGoalReviewConfiguration
          modalState={modalState}
          setModalState={setModalState}
        />
      </Grid>
    </Grid>
  );
}

export default ManageGoalReviewConfigurationList;
