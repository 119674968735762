export const colors = {
  blue1: "#30409E",

  gray1: "#FFFFFF",
  gray2: "#F8F8F8",
  gray3: "#B8B8B8",
  gray4: "#6E6E6E",
  gray5: "#F0F2FE",

  red: "#EB2109",
  red2: "#E99288",
  green: "#13A007",
  green2: "#92CE8D",
  infoGreen: "#2bbe2e",
  warnOrange: "#fd822e",
};
