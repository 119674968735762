import {
  Card,
  CardContent,
  Divider,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { FILE_ICON } from "asset/images";
import React, { useState } from "react";
import MoreVerticalDot from "../../../academicPlanDetails/MoreVerticalDot";
import EditIcon from "@mui/icons-material/Edit";
import ManageSchoolModal from "../Schools/ManageSchoolModal";

const useStyles = makeStyles((theme) => ({
  cardBody: {
    width: "100%",
    background: "#FFFFFF",
    borderRadius: "14px !important",
    height: "170px",
  },
  cardContent: {
    padding: "23px 18px !important",
  },
  cardTitle: {
    position: "relative",
    fontStyle: "normal",
    fontWeight: "800",
    fontSize: "18px",
    lineHeight: "22px",
    color: "#263657",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  fileIcon: {
    marginRight: "10px",
  },
  cardTitleWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  subTitle: {
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "8px",
    lineHeight: "10px",
    color: "#898EAC",
  },
  topicSection: {
    width: "100%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  bodyText: {
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "12px",
    lineHeight: "14px",
    color: "#000000",
  },
  dotStyle: {
    width: "3.86px",
    height: "3.86px",
    background: "#898EAC",
    borderRadius: "50%",
    display: "inline-block",
    verticalAlign: "middle",
    margin: "auto 7px",
  },
  cardBottom: {
    display: "flex",
    marginTop: "14px",
  },
  createdBy: {
    display: "flex",
    flexDirection: "column",
  },
  editIcon: {
    marginRight: "10px",
  },
  verticalDotsButton: {
    position: "absolute",
    right: "0px",
  },
  verticalDots: {
    margin: "0 6px",
  },
  viewMore: {
    fontWeight: 700,
    fontSize: "10px",
    color: "#898EAC",
  },
  cardBodyContent: {
    height: "48px",
  },
}));

function ClassesDetailsSideCard({
  data,
  privileges,
  userRoles,
  getPlanDetails,
  isDetailedPage,
}) {
  const classes = useStyles();
  const [modalState, setModalState] = useState({
    isOpen: false,
    data: null,
    isEdit: false,
  });
  return (
    <>
      <Card
        variant="outlined"
        className={classes.cardBody}
        style={{ background: isDetailedPage ? "#E3D5CA" : "#FFF" }}
      >
        <CardContent className={classes.cardContent}>
          <div className={classes.cardTitleWrapper}>
            <Typography className={classes.cardTitle} variant="div">
              <div>
                <img
                  className={classes.fileIcon}
                  src={FILE_ICON}
                  alt="File Icon"
                />
                <span>{data?.gradeName || ""}</span>
              </div>
              {privileges?.UPDATE && (
                <div className={classes.verticalDotsButton}>
                  <MoreVerticalDot
                    options={[
                      {
                        icon: (
                          <EditIcon
                            fontSize="small"
                            className={classes.editIcon}
                          />
                        ),
                        label: "Manage Classes",
                        value: "Manage Classes",
                      },
                    ]}
                    handleClick={(e) => {
                      e.stopPropagation();
                      setModalState((prev) => ({
                        ...prev,
                        isOpen: true,
                        isEdit: true,
                        data,
                      }));
                    }}
                    background={"#fff"}
                  />
                </div>
              )}
            </Typography>
          </div>
          <div className={classes.cardBodyContent}>
            <Typography className={classes.bodyText} variant="div">
              School Name:{" "}
              <Typography className={classes.bodySubText} variant="div">
                {data?.school?.schoolCampus || ""}
              </Typography>
            </Typography>
          </div>
        </CardContent>
      </Card>
      {modalState.isOpen && (
        <ManageSchoolModal
          userRoles={userRoles}
          modalState={modalState}
          setModalState={setModalState}
          getPlanDetails={getPlanDetails}
        />
      )}
    </>
  );
}

export default ClassesDetailsSideCard;
