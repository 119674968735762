import { getPageList } from "components/listOfValues/utils";
import { PAGES, setPageListItems } from "state/slices/pageSlice";

export const onLoginGetProfileSettings = async (dispatch) => {
  const pageListData = await getPageList({
    searchKey: "",
    page: PAGES.listOfValues,
    pageData: { pageSize: 50, currentPage: 0 },
  });
  dispatch(
    setPageListItems({
      page: PAGES.listOfValues,
      itemList: pageListData.content,
    })
  );
};
