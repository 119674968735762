import { FORM_RENDERERS, FORM_TYPES } from "common/utils/formUtility";
import { PAGE_RENDERER } from "common/utils/rendererList";
import GridViewWrapper from "common/wrappers/GridViewWrapper";
import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PAGES, setPageListItems } from "state/slices/pageSlice";
import { getPoliciesData, savePolicy } from "../utils";
import { clearForm } from "state/slices/globalFormSlice";

const ManagePolicies = () => {
  const dispatch = useDispatch();
  const pageListData = useSelector((state) => state.pages.policies.items);

  const onPolicyAdd = async (data) => {
    const response = await savePolicy(data);
    dispatch(clearForm({ formType: FORM_TYPES.MANAGE_CLASS_SECTION }));
    getListData();
  };

  const getListData = useCallback(
    async (searchKey = "") => {
      const pageListData = await getPoliciesData({ searchKey });
      dispatch(
        setPageListItems({
          page: PAGES.policies,
          itemList: pageListData.content,
        })
      );
    },
    [dispatch]
  );

  useEffect(() => {
    getListData();
  }, [getListData]);

  return (
    <GridViewWrapper
      getList={getListData}
      paginatedListRenderer={PAGE_RENDERER.POLICIES}
      pageData={pageListData}
      formDialogConfig={{
        onFormSave: onPolicyAdd,
        formType: FORM_TYPES.MANAGE_POLICIES,
        formRenderer: FORM_RENDERERS.MANAGE_POLICIES,
        title: "Add Policy",
      }}
    />
  );
};

export default ManagePolicies;
