import React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Avatar, makeStyles } from "@material-ui/core";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  CHALKBOARD_ICON,
  CHECK_ICON,
  CLOCK_ICON,
  FOLDER_OPEN_ICON,
  STICKY_NOTE_ICON,
} from "asset/images";
import moment from "moment";
import { GLOBAL_DATE_FORMAT } from "constants/PageConstants";
import StatusBlock from "../StatusBlock";
import MoreVerticalDot from "../MoreVerticalDot";
import { deepPurple } from "@material-ui/core/colors";
import { useHistory } from "react-router-dom";
import { deletePlanService } from "components/listOfValues/utils";

const useStyles = makeStyles((theme) => ({
  cardBody: {
    width: "100%",
    background: "#FFFFFF",
    borderRadius: "14px !important",
    height: "290px",
    [theme.breakpoints.down("xs")]: {
      height: "auto",
    },
  },
  cardContent: {
    padding: "26px 18px !important",
  },
  cardTitle: {
    fontStyle: "normal",
    fontWeight: "800",
    fontSize: "18px",
    lineHeight: "22px",
    color: "#263657",
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  cardTitleText: {
    width: "80%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  fileIcon: {
    marginRight: "10px",
  },
  cardTitleWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    position: "relative",
  },
  subTitle: {
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "12px",
    lineHeight: "14px",
    color: "#898EAC",
  },
  cardBodyContent: {
    marginTop: "18px",
  },
  bodyText: {
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "12px",
    lineHeight: "14px",
    color: "#000000",
  },
  subUnitText: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "block",
  },
  subUnitWrapper: {
    lineHeight: 0.8,
    width: "100%",
    display: "flex",
    alignItems: "center",
  },
  dotStyle: {
    width: "3.86px",
    height: "3.86px",
    background: "#898EAC",
    borderRadius: "50%",
    display: "inline-block",
    verticalAlign: "middle",
    margin: "auto 7px",
  },
  createdBy: {
    display: "flex",
    flexDirection: "column",
  },
  subUnitSection: {
    border: "1px solid #DBDBDB",
    borderRadius: "4px",
    padding: "10px",
    marginTop: "16px",
    height: "90px",
  },
  editIcon: {
    marginRight: "10px",
  },
  verticalDotsButton: {
    position: "absolute",
    right: "0px",
  },
  avatar: {
    background: deepPurple[500],
    height: "24px",
    width: "24px",
    marginRight: "10px",
    fontSize: "16px",
  },
  cardBottom: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  createdBySection: {
    display: "flex",
    marginTop: "18px",
  },
  statusBlock: {
    marginTop: "18px",
  },
}));

export default function UnitPlanCard({
  data,
  userRoles,
  privileges,
  keyName,
  hideModification,
  setModalState,
  getPlanDetails,
  isDetailedPage
}) {
  const history = useHistory();
  const classes = useStyles();
  const AllowedRoles = ["Administrator", "Inspire Us-Core"];

  const handleRedirect = (id) => {
    history.push(`/unit-plan/${id}`);
  };

  const isEditEnabled = (roles) => {
    let editEnabled = false;
    roles.forEach((role) => {
      if (AllowedRoles.includes(role)) {
        editEnabled = true;
      }
    });
    return editEnabled;
  };

  const isEditable = isEditEnabled(userRoles);

  const handleDeletePlan = async (id) => {
    const res = await deletePlanService("unit-plan", id);
    if (res && getPlanDetails) getPlanDetails();
  };

  return (
    <Box
      sx={{ minWidth: 275 }}
      onClick={() => !isDetailedPage && handleRedirect(data?.id)}
      style={{ cursor: "pointer" }}
    >
      <Card variant="outlined" className={classes.cardBody} style={{ background: isDetailedPage ? '#E3D5CA' : '#FFF'}}>
        <CardContent className={classes.cardContent}>
          <div className={classes.cardTitleWrapper}>
            <Typography className={classes.cardTitle} variant="div">
              <img
                className={classes.fileIcon}
                src={FOLDER_OPEN_ICON}
                alt="File Icon"
              />
              <span className={classes.cardTitleText}>{data?.name || "-"}</span>
            </Typography>
            {!hideModification && (
              <div className={classes.verticalDotsButton}>
                <MoreVerticalDot
                  options={[
                    {
                      icon: (
                        <EditIcon
                          fontSize="small"
                          className={classes.editIcon}
                        />
                      ),
                      label: "Manage Unit Plan",
                      value: "Manage Unit Plan",
                      isDisabled:
                        data?.status?.toUpperCase() === "COMPLETED"
                          ? !isEditable
                          : !privileges?.UPDATE,
                    },
                    {
                      icon: (
                        <DeleteIcon
                          fontSize="small"
                          className={classes.editIcon}
                        />
                      ),
                      label: "Delete Unit Plan",
                      value: "Delete Unit Plan",
                      isDisabled:
                        data?.status?.toUpperCase() === "COMPLETED"
                          ? true
                          : !privileges?.DELETE,
                    },
                  ]}
                  handleClick={(e, value) => {
                    e.stopPropagation();
                    if (value === "Delete Unit Plan") {
                      data?.id && handleDeletePlan(data.id);
                    } else {
                      setModalState((prev) => ({
                        ...prev,
                        isOpen: true,
                        isEdit: true,
                        data,
                      }));
                    }
                  }}
                />
              </div>
            )}
          </div>
          <div className={classes.cardBodyContent}>
            <img
              className={classes.fileIcon}
              src={CHALKBOARD_ICON}
              alt="Chalkboard Icon"
            />
            <Typography
              className={classes.bodyText}
              variant="span"
              marginRight={"34px"}
            >
              Class: {data?.curriculumContext?.gradeName || "-"}
            </Typography>
            <img
              className={classes.fileIcon}
              src={STICKY_NOTE_ICON}
              alt="Sticky Note Icon"
            />
            <Typography className={classes.bodyText} variant="span">
              Subject: {data?.curriculumContext?.subjectName || "-"}
            </Typography>
          </div>
          <div>
            <img
              className={classes.fileIcon}
              src={CLOCK_ICON}
              alt="Clock Icon"
            />
            <Typography className={classes.bodyText} variant="span">
              Start Date:{" "}
              {data?.start
                ? moment(data.start).format(GLOBAL_DATE_FORMAT)
                : "-"}
            </Typography>
            <span className={classes.dotStyle}></span>
            <Typography className={classes.bodyText} variant="span">
              End Date:{" "}
              {data?.end ? moment(data.end).format(GLOBAL_DATE_FORMAT) : "-"}
            </Typography>
          </div>
          <div className={classes.subUnitSection}>
            {!data?.[keyName || "subUnits"]?.length ? (
              "No sub units available"
            ) : (
              <>
                <Typography className={classes.subTitle} variant="div">
                  Sub Units:
                </Typography>
                {data?.[keyName || "subUnits"]?.length
                  ? data?.[keyName || "subUnits"]
                      ?.slice(0, 3)
                      .map((subUnit) => (
                        <div
                          key={subUnit?.id}
                          className={classes.subUnitWrapper}
                        >
                          <img
                            className={classes.fileIcon}
                            src={CHECK_ICON}
                            alt="Check Icon"
                          />
                          <Typography
                            className={[classes.bodyText, classes.subUnitText]}
                            variant="span"
                            marginRight={"34px"}
                            fontSize={"10px"}
                            fontWeight="500"
                          >
                            {subUnit?.name || "-"}
                          </Typography>
                        </div>
                      ))
                  : ""}
              </>
            )}
          </div>
          <div className={classes.cardBottom}>
            <div className={classes.createdBySection}>
              <Avatar className={classes.avatar}>
                {data?.createdBy?.charAt(0)?.toUpperCase()}
              </Avatar>
              <div className={classes.createdBy}>
                <Typography
                  className={classes.subTitle}
                  fontSize="10px"
                  variant="div"
                >
                  Created by
                </Typography>
                <Typography
                  className={classes.bodyText}
                  fontWeight="400"
                  fontSize={"10px"}
                  variant="div"
                >
                  {data?.createdBy || "-"}
                </Typography>
              </div>
            </div>
            <div className={classes.statusBlock}>
              <StatusBlock status={data?.status?.toUpperCase() || ""} />
            </div>
          </div>
        </CardContent>
      </Card>
    </Box>
  );
}
