import {
  Box,
  Grid,
  DialogTitle,
  DialogContent,
  IconButton,
  Dialog,
  Typography,
} from "@material-ui/core";
import { PAGE_RENDERER } from "common/utils/rendererList";
import GridViewWrapper from "common/wrappers/GridViewWrapper";
import React, { useEffect, useState } from "react";
import { GoalSettingFormInitialState } from "state/globalForms/initialStates";
import { PAGES } from "state/slices/pageSlice";
import { useDispatch } from "react-redux";
import { FORM_TYPES } from "common/utils/formUtility";
import { makeStyles } from "@material-ui/styles";
import { Close } from "@material-ui/icons";
import { clearPageFilters, updateForm } from "state/slices/globalFormSlice";
import withPrivilege from "common/hocs/withPrivilege";
import { EntityMap } from "util/roleUtils";
import AddGoalForm from "common/forms/AddGoalForm";

const useStyles = makeStyles((theme) => ({
  modalCloseBtn: {
    alignSelf: "center",
    textAlign: "center",
  },
}));

const ManageSetGoal = ({ privileges, userRoles }) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [currentPlan, setCurrentPlan] = useState(GoalSettingFormInitialState);
  const [isEditForm, setEditFormStatus] = useState(false);
  const dispatch = useDispatch();
  const onTabChange = (value, data) => {
    setValue(0);
  };

  const onEditPlan = (data) => {
    setEditFormStatus(true);
    dispatch(updateForm({ formType: FORM_TYPES.SET_GOAL, formData: data }));
    setCurrentPlan(data);
    setValue(1);
  };

  const onAddGoalSettingForm = (data) => {
    setEditFormStatus(false);
    dispatch(
      updateForm({
        formType: FORM_TYPES.SET_GOAL,
        formData: GoalSettingFormInitialState,
      })
    );
    setValue(1);
  };

  useEffect(() => {
    dispatch(clearPageFilters());
  }, []);

  return (
    <>
      {value === 1 ? (
        <>
          <Dialog
            maxWidth="md"
            fullWidth
            open={true}
            onClose={() => setValue(0)}
            aria-labelledby="form-dialog-title"
          >
            <Grid container>
              <Grid item xs={11}>
                <DialogTitle id="form-dialog-title">
                  <Typography variant="h6">{`${
                    isEditForm ? "Edit" : "Add"
                  } Goal Set Form`}</Typography>
                </DialogTitle>
              </Grid>
              <Grid item xs={1} className={classes.modalCloseBtn}>
                <IconButton onClick={() => setValue(0)}>
                  <Close />
                </IconButton>
              </Grid>
            </Grid>
            <DialogContent style={{ padding: "8px 25px" }}>
              <AddGoalForm formType={PAGES.setGoal} setValue={setValue} />
            </DialogContent>
          </Dialog>
        </>
      ) : null}
      <GridViewWrapper
        page={PAGES.setGoal}
        paginatedListRenderer={PAGE_RENDERER.SET_GOAL}
        displayAdd={true}
        pageConfig={{
          onEditPlan: onEditPlan,
        }}
        disableReadView={true}
        showFilters={true}
        privileges={privileges}
        userRoles={userRoles}
        onTabChange={onTabChange}
        onSeparateAddForm={onAddGoalSettingForm}
      />
    </>
  );
};

export default withPrivilege(
  ManageSetGoal,
  EntityMap.goalsetting,
  EntityMap.goalsettingform
);
