import { Box, List, ListItem, ListItemIcon, Typography, ListItemText, Divider } from "@material-ui/core";
import { CheckCircleOutlined, CloseOutlined } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import { mapIndexed } from "common/utils/composition";
import React from "react";
import * as R from "ramda";

const useStyle = makeStyles(theme => ({
    title:{
        "&.MuiTypography-root":{
            marginTop:"1rem",
            fontWeight: "600",
            textAlign: "left"
        }
    },
    whiteIcon:{
        color:"white",
        cursor: "pointer"
    },
    unitList: {
        "&.MuiList-root":{
            maxHeight:"none"
        }
    },
    topicsDiv: {
        border: "1px solid #898EAC",
        background: "#898eac2e", 
        boxShadow: "none", width: "100%",
        padding: "1rem", borderRadius: 4 
    },
    listItemIcon: {
        minWidth: 'unset',
    },
    listItemText: {
        paddingLeft: '10px'
    },
    listItem: {
        padding: '6px 0px'
    }

}))
const DisplaySubUnits = ({subUnits, isWhiteIcon = true, onDelete=null, disabled=false}) => {
    const classes = useStyle();
    if(R.isEmpty(subUnits)) {
        return null;
    }
    return (
        <Box sx={{marginTop:"1rem", width:"100%", overflowWrap: "break-word"}} className={classes.topicsDiv}>
            <Typography variant="h6" className={isWhiteIcon && classes.whiteIcon} style={{ textAlign: "left" }}>
                Sub Units
            </Typography>
            <List className={classes.unitList}>
                {
                    subUnits && mapIndexed((item, index) => {
                        return (
                            <ListItem className={classes.listItem}>
                                <ListItemIcon disabled={disabled} className={classes.listItemIcon}>
                                    <CheckCircleOutlined disabled={disabled} className={isWhiteIcon && classes.whiteIcon} fontSize={"small"} />
                                </ListItemIcon>
                                <ListItemText disabled={disabled}
                                    primary={`${item.name}`}
                                    className={classes.listItemText}
                                />
                                {
                                    !R.isNil(onDelete) && !disabled && (
                                        <ListItemIcon className={classes.listItemIcon}>
                                        <CloseOutlined  className={isWhiteIcon && classes.whiteIcon} fontSize={"small"} onClick={() => onDelete(index)}/>
                                    </ListItemIcon>
                                    )
                                }
                               
                            </ListItem>
                        )
                    })(subUnits)
                }
            </List>
        </Box>
    )
}

export default DisplaySubUnits;