import {
  Card,
  CardContent,
  Divider,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { FILE_ICON } from "asset/images";
import React, { useState } from "react";
import MoreVerticalDot from "../../../academicPlanDetails/MoreVerticalDot";
import EditIcon from "@mui/icons-material/Edit";
import ManageSchoolModal from "../Schools/ManageSchoolModal";

const useStyles = makeStyles((theme) => ({
  cardBody: {
    margin: "1rem",
    background: "#F0F2FE",
    borderRadius: "15px",
    boxShadow: "none",
    marginTop: "50px",
  },
  cardContent: {
    padding: "26px 18px !important",
  },
  cardTitleDiv: {
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  cardTitle: {
    position: "relative",
    fontStyle: "normal",
    fontWeight: "800",
    fontSize: "18px",
    lineHeight: "22px",
    color: "#263657",
    display: "flex",
    alignItems: "center",
    marginBottom: "20px",
    justifyContent: "space-between",
    width: "100%",
  },
  fileIcon: {
    marginRight: "10px",
    alignSelf: "start",
    marginTop: "5px",
  },
  subTitle: {
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "8px",
    lineHeight: "10px",
    color: "#898EAC",
    marginLeft: "25px",
  },
  bodyText: {
    marginTop: "18px",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "12px",
    lineHeight: "14px",
    color: "#000000",
    display: "block",
  },
  bodySubText: {
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "14px",
  },
  topicCard: {
    background: "#fff",
    borderRadius: "4px",
    boxShadow: "none",
    marginTop: "24px",
  },
  topicTitle: {
    fontWeight: 500,
    fontSize: "14px",
    color: "#898EAC",
  },
  subTopic: {
    display: "flex",
  },
  subTopicText: {
    fontWeight: "400",
    fontSize: "12px",
  },
  editIcon: {
    marginRight: "10px",
  },
  verticalDotsButton: {
    position: "absolute",
    right: "-15px",
    top: "-8px",
  },
  verticalDots: {
    margin: "0 6px",
  },
}));

function ClassesDetails({ data, privileges, userRoles, getPlanDetails }) {
  const classes = useStyles();
  const [modalState, setModalState] = useState({
    isOpen: false,
    data: null,
    isEdit: false,
  });
  return (
    <>
      <Card className={classes.cardBody}>
        <CardContent className={classes.cardContent}>
          <Typography className={classes.cardTitle} variant="div">
            <div className={classes.cardTitleDiv}>
              <img
                className={classes.fileIcon}
                src={FILE_ICON}
                alt="File Icon"
              />
              <span>{data?.gradeName || ""}</span>
            </div>
            {privileges?.UPDATE && (
              <div className={classes.verticalDotsButton}>
                <MoreVerticalDot
                  options={[
                    {
                      icon: (
                        <EditIcon
                          fontSize="small"
                          className={classes.editIcon}
                        />
                      ),
                      label: "Manage Classes",
                      value: "Manage Classes",
                    },
                  ]}
                  handleClick={(e) => {
                    e.stopPropagation();
                    setModalState((prev) => ({
                      ...prev,
                      isOpen: true,
                      isEdit: true,
                      data,
                    }));
                  }}
                  background={"#fff"}
                />
              </div>
            )}
          </Typography>
          <Divider />
          <Typography className={classes.bodyText} variant="div">
            School Name:{" "}
            <Typography className={classes.bodySubText} variant="div">
              {data?.school?.schoolCampus || ""}
            </Typography>
          </Typography>
        </CardContent>
      </Card>
      {modalState.isOpen && (
        <ManageSchoolModal
          userRoles={userRoles}
          modalState={modalState}
          setModalState={setModalState}
          getPlanDetails={getPlanDetails}
        />
      )}
    </>
  );
}

export default ClassesDetails;
