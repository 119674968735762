import React, { useState, useCallback, useEffect } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import * as R from "ramda";
import { MenuProps, useStyles } from "./utils";
import { getLOVSelectData } from "../SelectDropDown/SelectDropDownUtils";
import { mapIndexed } from "common/utils/composition";

const MultiSelectDropDown = ({
  apiKey,
  defaultSelected = [],
  dataKey,
  secondaryDataKey,
  onSelect,
}) => {
  const classes = useStyles();
  const [selected, setSelected] = useState([]);
  const [selectList, setSelectList] = useState([]);
  const isAllSelected =
    selectList.length > 0 && selected.length === selectList.length;

  const getDefaultSelected = useCallback(() => {
    if (defaultSelected) {
      const _map = {};
      if (selectList.length > 0) {
        selectList.forEach((cl) => {
          _map[cl.className] = cl;
        });
        const defaultSelectedObject = [];
        defaultSelected.forEach((ds) => {
          defaultSelectedObject.push(_map[ds]);
        });
        setSelected(defaultSelectedObject);
      }
    }
  }, [defaultSelected, selectList]);

  useEffect(() => {
    if (defaultSelected.length > 0) {
      getDefaultSelected();
    }
  }, [defaultSelected, getDefaultSelected, selectList]);

  const handleChange = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      setSelected(selected.length === selectList.length ? [] : selectList);
      return;
    }
    const updatedSelectedValues = [...value];
    setSelected(updatedSelectedValues);
    onSelect(updatedSelectedValues);
  };

  const getListData = useCallback(
    async (searchKey = "") => {
      const listData = await getLOVSelectData(apiKey)({ searchKey });
      setSelectList(R.propOr([], "content", listData));
    },
    [apiKey]
  );

  useEffect(() => {
    getListData();
  }, [getListData]);

  const getRenderValue = (selectedValues) => {
    return R.map((eachValue) => eachValue[dataKey], selectedValues).join(", ");
  };

  return (
    <Select
      multiple
      displayEmpty
      variant="outlined"
      margin="normal"
      id="multi-select-outlined"
      input={<OutlinedInput />}
      value={selected}
      onChange={handleChange}
      renderValue={(selected) => {
        if (selected.length === 0) {
          return <span style={{ color: "#bbb" }}>Select grades</span>;
        }
        return getRenderValue(selected);
      }}
      placeholder="grades"
      MenuProps={MenuProps}
      inputProps={{ "aria-label": "Without label" }}
    >
      <MenuItem
        value="all"
        classes={{
          root: isAllSelected ? classes.selectedAll : "",
        }}
      >
        <ListItemIcon>
          <Checkbox
            classes={{ indeterminate: classes.indeterminateColor }}
            checked={isAllSelected}
            indeterminate={
              selected.length > 0 && selected.length < selectList.length
            }
          />
        </ListItemIcon>
        <ListItemText
          classes={{ primary: classes.selectAllText }}
          primary="Select All"
        />
      </MenuItem>
      {mapIndexed((item, index) => {
        const menuStr = secondaryDataKey
          ? `${R.propOr("", dataKey, item)}-${R.propOr(
              "",
              secondaryDataKey,
              item
            )}`
          : R.propOr("", dataKey, item);
        return (
          <MenuItem key={`${index[dataKey]}-${index}`} value={item}>
            <ListItemText primary={menuStr} />
          </MenuItem>
        );
      }, selectList)}
    </Select>
  );
};

export default MultiSelectDropDown;
