import {
  Box,
  Typography,
  Grid,
  FormControl,
  Button,
  MenuItem,
  Select,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { useState } from "react";
import { LOVSelectKeys } from "../SelectDropDown/SelectDropDownUtils";
import * as R from "ramda";
import TeacherDropDown from "../TeacherDropDown/TeacherDropDown";

const useStyles = makeStyles((theme) => ({
  inputFormControl: {
    marginBottom: "1rem",
  },
  editIconContainer: {
    display: "flex",
    alignItems: "baseline",
    justifyContent: "flex-end",
  },
  inputLabel1: {
    top: "-17px",
    left: "-14px",
  },
  placeholderText: {
    color: "#bbb",
  },
}));

const SectionTeacher = ({ schoolCode, handleChange, subjects }) => {
  const classes = useStyles();
  const [subjectTeacherMap, setSubjectTeacherMap] = useState({
    subject: {},
    applicationUser: {},
  });

  const addNewRow = () => {
    handleChange(subjectTeacherMap);
    setSubjectTeacherMap({
      subject: {},
      applicationUser: {},
    });
  };

  const handleSubjectTeacherChange = (teacherObj) => {
    setSubjectTeacherMap((prev) => ({ ...prev, applicationUser: teacherObj }));
  };

  const handleSubjectChange = (e) => {
    setSubjectTeacherMap((prev) => ({
      ...prev,
      subject: subjects.find((sub) => sub.id === e.target.value),
    }));
  };

  return (
    <>
      <Grid container style={{ marginTop: "1rem" }}>
        <Grid item xs={12}>
          <Typography
            color="primary"
            variant="subtitle2"
            style={{ marginBottom: "1rem" }}
          >
            Teachers
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Box>
            <Grid container spacing={1}>
              <Grid item xs={12} md={3}>
                <FormControl
                  variant="outlined"
                  className={classes.inputFormControl}
                >
                  <Select
                    variant="outlined"
                    margin="normal"
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    name={LOVSelectKeys.SUBJECT}
                    onChange={handleSubjectChange}
                    placeholder="Select Subject"
                    inputProps={{ "aria-label": "Without label" }}
                    selectedValue={subjectTeacherMap.subject.id || ""}
                    renderValue={(value) =>
                      value ? (
                        subjectTeacherMap.subject.subjectName
                      ) : (
                        <span className={classes.placeholderText}>
                          Select Subject
                        </span>
                      )
                    }
                    displayEmpty
                  >
                    {R.map((subject) => {
                      return (
                        <MenuItem value={subject.id}>
                          {subject.subjectName}
                        </MenuItem>
                      );
                    })(subjects)}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={8}>
                <FormControl
                  variant="outlined"
                  className={classes.inputFormControl}
                >
                  <TeacherDropDown
                    customOnChange={handleSubjectTeacherChange}
                    selectKey={LOVSelectKeys.CLASS_TEACHER}
                    selectedValue={subjectTeacherMap.applicationUser.email}
                    schoolCode={schoolCode}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={1}>
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={addNewRow}
                  size="small"
                  data-html2canvas-ignore
                >
                  Add
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default SectionTeacher;
