import { getPageList } from 'components/listOfValues/utils';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PAGES, setPageListItems } from 'state/slices/pageSlice';
import * as R from "ramda";
import { getMatchingRoles } from 'util/roleUtils';

const getAvaialableTabs = (privileges) => {
    // return R.append("reviewgoal",R.append("goalsettingform", R.append("dashboard",R.map((item) => item.entityName)(R.propOr([], "READ", privileges)))));
    return R.append("dashboard",R.map((item) => item.entityName)(R.propOr([], "READ", privileges)));
};

const withTabsPrivilege = (Component) => props => {
    const roles = useSelector(state => state.pages.roles);
    const userProfile = useSelector(state => state.userProfile.userProfile);
    const dispatch = useDispatch();
    const privileges = getMatchingRoles(R.pathOr([], ["items"], roles), R.pathOr([], ["roles"], userProfile));
    const getRoles = async () => {
        const response = await getPageList({ searchKey: "", page: PAGES.roles });
        dispatch(setPageListItems({
            page: PAGES.roles,
            itemList: R.propOr([],"content",response),
        }));
    }

    useEffect(() => {
        if (R.isEmpty(roles.items)) {
            getRoles();
        }
    }, [roles]);

    return <Component
        {...props}
        tabAccess={getAvaialableTabs(privileges)}
    />;
};
export default withTabsPrivilege;