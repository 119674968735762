import React from "react";
import {
  makeStyles,
  Typography,
  Box,
  Card,
  CardContent,
} from "@material-ui/core";
import { FILE_ICON } from "asset/images";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import EditIcon from "@mui/icons-material/Edit";
import { geAnnualPeriod } from "components/academicPlan/utils";
import MoreVerticalDot from "../../academicPlanDetails/MoreVerticalDot";

const useStyles = makeStyles((theme) => ({
  cardBody: {
    width: "100%",
    background: "#FFFFFF",
    borderRadius: "14px !important",
  },
  cardContent: {
    padding: "26px 18px !important",
  },
  cardTitle: {
    fontStyle: "normal",
    fontWeight: "800",
    fontSize: "18px",
    lineHeight: "22px",
    color: "#263657",
    display: "flex",
    alignItems: "center",
  },
  fileIcon: {
    marginRight: "10px",
  },
  cardTitleWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  cardBodyContent: {
    marginTop: "18px",
  },
  bodyText: {
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "12px",
    lineHeight: "14px",
    color: "#000000",
  },
  editIcon: {
    marginRight: "10px",
  },
}));

const GoalTimelineConfigurationCard = ({ data }) => {
  const classes = useStyles();

  return (
    <>
      <Box sx={{ minWidth: 275 }} style={{ cursor: "pointer" }}>
        <Card variant="outlined" className={classes.cardBody}>
          <CardContent className={classes.cardContent}>
            <div className={classes.cardTitleWrapper}>
              <Typography className={classes.cardTitle} variant="div">
                <img
                  className={classes.fileIcon}
                  src={FILE_ICON}
                  alt="File Icon"
                />
                <span>{geAnnualPeriod(data.academicCalendar)}</span>

                {/* <div className={classes.verticalDotsButton}>
                  <MoreVerticalDot
                    options={[
                      {
                        icon: (
                          <EditIcon
                            fontSize="small"
                            className={classes.editIcon}
                          />
                        ),
                        label: "Manage Tier Plan",
                        value: "Manage Tier Plan",
                        // isDisabled: data?.status?.toUpperCase() === "COMPLETED" ? !isEditable : !privileges?.UPDATE,
                      },
                    ]}
                    handleClick={(e) => {
                      e.stopPropagation();
                      setModalState((prev) => ({
                        ...prev,
                        isOpen: true,
                        isEdit: true,
                        data,
                      }));
                    }}
                  />
                </div> */}
              </Typography>
            </div>

            <div className={classes.cardBodyContent}>
              <CalendarMonthIcon />
              <Typography className={classes.bodyText} variant="span">
                Goal Setup: {data.goalSetupStartDate} - {data.goalSetupEndDate}
              </Typography>
            </div>
            <div className={classes.cardBodyContent}>
              <CalendarMonthIcon />
              <Typography className={classes.bodyText} variant="span">
                Goal Review: {data.goalReviewStartDate} -{" "}
                {data.goalReviewEndDate}
              </Typography>
            </div>
          </CardContent>
        </Card>
      </Box>
    </>
  );
};

export default GoalTimelineConfigurationCard;
