import { Box, Divider, Grid, Typography } from "@material-ui/core";
import React from "react";
import * as R from "ramda";
import { colors } from "theme/colors";
import { makeStyles } from "@material-ui/styles";
import DisplaySubTopics from "common/components/DisplaySubTopics";

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: "0.75rem",
  },

  heading: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: theme.typography.fontWeightBold,
    marginTop: "1rem",
    marginBottom: "1rem",
  },

  label: {
    color: colors.gray4,
  },
}));

const TopicReadView = ({ data }) => {
  const classes = useStyles();
  return (
    <Box
      style={{
        border: "1px solid rgb(137 142 172 / 29%)",
        borderRadius: 4,
        padding: "0.9rem",
        margin: "0.5rem 0",
      }}
    >
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="subtitle2">
            Name: {R.propOr("-", "name", data)}
          </Typography>
        </Grid>
      </Grid>
      <DisplaySubTopics
        isWhiteIcon={false}
        subTopics={R.propOr([], "subTopics", data)}
      />
    </Box>
  );
};

export default TopicReadView;
