import React, { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  Typography,
} from "@material-ui/core";
import CancelIcon from "@mui/icons-material/Cancel";
import { PAGES } from "state/slices/pageSlice";
import * as R from "ramda";
import {
  LOVApiKeys,
  LOVDataKeys,
  LOVDisplayKeys,
  LOVSelectKeys,
} from "common/components/SelectDropDown/SelectDropDownUtils";
import SelectDropDown from "common/components/SelectDropDown/SelectDropDown";

const useStyles = makeStyles((theme) => ({
  inputFormControl: {
    margin: "1.5rem",
  },
  flexGrid: {
    "&.MuiGrid-root": {
      display: "flex",
    },
  },
  inputLabel1: {
    top: "-17px",
    left: "-14px",
  },
  inputLabel: {
    top: "-17px",
  },
  placeholderValue: {
    color: "#bbb",
  },
  filterHeader: {
    fontWeight: 700,
    fontSize: "18px",
    lineHeight: "22px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    margin: "1.5rem",
    [theme.breakpoints.down("md")]: {
      marginTop: "0",
    },
  },
}));

function StaffFilterSidebar({
  filterState,
  setFilterState,
  formType,
  isEditForm,
  state,
}) {
  const classes = useStyles();
  const [selectedClass, setSelectedClass] = useState(null);

  useEffect(() => {
    !filterState?.gradeName && setSelectedClass(null);
  }, [filterState?.gradeName]);

  const handleChange = (check, value) => {
    setFilterState((prev) => ({
      ...prev,
      [check]: value,
    }));
  };

  const handleClearFilter = () => {
    setFilterState({
      annualPeriod: "",
      status: "",
      gradeName: "",
      subject: "",
    });
  };

  return (
    <Typography className={classes.filterWrapper} variant="div">
      <Grid container>
        <Typography className={classes.filterHeader} variant="div">
          <div>Filter</div>
          <Button
            variant="contained"
            color="primary"
            className={classes.addIconButton}
            onClick={handleClearFilter}
            startIcon={<CancelIcon className="addIcon" />}
            disabled={!filterState?.status && !filterState?.school}
          >
            Clear all
          </Button>
        </Typography>

        {/* <FormControl variant="outlined" className={classes.inputFormControl}>
          <InputLabel
            className={classes.inputLabel1}
            id="demo-simple-select-outlined-label"
          >
            Roles
          </InputLabel>
          <SelectDropDown
            placeholder={"select Roles"}
            formType={formType}
            selectKey={LOVSelectKeys.ROLES}
            apiKey={LOVApiKeys.ROLES}
            dataKey={LOVDataKeys.ROLES}
            secondaryDataKey={"campus"}
            selectedValue={R.propOr({}, "roles", state)}
            displayKey={LOVDisplayKeys.ROLES}
            page={PAGES.roles}
            isDisabled={isEditForm}
          />
        </FormControl> */}
        <FormControl variant="outlined" className={classes.inputFormControl}>
          <InputLabel
            className={classes.inputLabel1}
            id="demo-simple-select-outlined-label"
          >
            School
          </InputLabel>

          <SelectDropDown
            placeholder={"select school name"}
            // formType={formType}
            selectKey={LOVSelectKeys.SCHOOL}
            apiKey={LOVApiKeys.SCHOOL}
            dataKey={LOVDataKeys.SCHOOL}
            secondaryDataKey={"campus"}
            selectedValue={filterState?.school || ""}
            // displayKey={LOVDisplayKeys.SCHOOL}
            page={PAGES.schools}
            // isDisabled={isEdit}
          />
        </FormControl>
      </Grid>
    </Typography>
  );
}

export default StaffFilterSidebar;
