import React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
} from "@material-ui/core";
import {
  CHALKBOARD_ICON,
  CLOCK_ICON,
  COPY_ICON,
  EDIT_ICON,
  FILE_ICON,
  STICKY_NOTE_ICON,
} from "asset/images";
import moment from "moment";
import { GLOBAL_DATE_FORMAT } from "constants/PageConstants";
import { geAnnualPeriod } from "components/academicPlan/utils";
import { createDuplicateAnnualPlanReference } from "components/listOfValues/utils";
import { useHistory } from "react-router-dom";
import StatusBlock from "../StatusBlock";
import { deepPurple } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  cardBody: {
    width: "100%",
    background: "#FFFFFF",
    borderRadius: "14px !important",
  },
  cardContent: {
    padding: "26px 18px !important",
  },
  cardTitle: {
    fontStyle: "normal",
    fontWeight: "800",
    fontSize: "18px",
    lineHeight: "22px",
    color: "#263657",
    display: "flex",
    alignItems: "center",
  },
  fileIcon: {
    marginRight: "10px",
  },
  cardTitleWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  subTitle: {
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "8px",
    lineHeight: "10px",
    color: "#898EAC",
  },
  cardBodyContent: {
    marginTop: "18px",
  },
  bodyText: {
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "12px",
    lineHeight: "14px",
    color: "#000000",
  },
  dotStyle: {
    width: "3.86px",
    height: "3.86px",
    background: "#898EAC",
    borderRadius: "50%",
    display: "inline-block",
    verticalAlign: "middle",
    margin: "auto 7px",
  },
  inputFormControl: {
    margin: "1.5rem",
  },
  flexGrid: {
    "&.MuiGrid-root": {
      display: "flex",
    },
  },
  inputLabel1: {
    top: "-17px",
    left: "-14px",
  },
  inputLabel: {
    top: "-17px",
  },
  placeholderValue: {
    color: "#bbb",
  },
  avatar: {
    background: deepPurple[500],
    height: "24px",
    width: "24px",
    marginRight: "10px",
    fontSize: "16px",
  },
  cardBottom: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: 'space-between'
  },
  createdBy: {
    display: "flex",
    flexDirection: "column",
  },
  createdBySection: {
    display: 'flex',
    marginTop: "18px",
  },
  statusBlock: {
    marginTop: "18px",
  },
  modifyIcons: {
    width: "65px",
    textAlign: "end",
  },
}));

export default function AnnualPlanCard({
  data,
  userRoles = [],
  privileges = {},
  getAnnualPlanList,
  setModalState,
  isDetailedPage
}) {
  const history = useHistory();
  const classes = useStyles();
  const annualPeriodName = geAnnualPeriod(data.curriculumContext.annualPeriod);
  const AllowedRoles = ["Administrator"];
  const [copyDialogOpen, setCopyDialogOpen] = React.useState(false);
  const [annualPeriodDuplicate, setAnnualPeriodDuplicate] = React.useState("");

  const isEditEnabled = (roles) => {
    let editEnabled = false;
    roles.forEach((role) => {
      if (AllowedRoles.includes(role)) {
        editEnabled = true;
      }
    });
    return editEnabled;
  };

  const isEditable = isEditEnabled(userRoles);

  const handleAnnualPlanChange = (event) => {
    setAnnualPeriodDuplicate(event.target.value || "");
  };

  const handleContentCopyOpen = (e) => {
    e.stopPropagation();
    setCopyDialogOpen(true);
  };

  const handleContentCopyClose = () => {
    setCopyDialogOpen(false);
  };

  const handleContentCopySubmit = async () => {
    const response = await createDuplicateAnnualPlanReference(
      data.id,
      annualPeriodDuplicate
    );
    setCopyDialogOpen(false);
    getAnnualPlanList();
  };

  const handleRedirect = (id) => {
    history.push(`/annual-plan/${id}`);
  };

  return (
    <>
      <Box
        sx={{ minWidth: 275 }}
        onClick={() => !isDetailedPage && handleRedirect(data?.id)}
        style={{ cursor: "pointer" }}
      >
        <Card variant="outlined" className={classes.cardBody} style={{ background: isDetailedPage ? '#E3D5CA' : '#FFF'}}>
          <CardContent className={classes.cardContent}>
            <div className={classes.cardTitleWrapper}>
              <Typography className={classes.cardTitle} variant="div">
                <img
                  className={classes.fileIcon}
                  src={FILE_ICON}
                  alt="File Icon"
                />
                <span>{data?.name}</span>
              </Typography>
              {(privileges?.UPDATE || privileges?.CREATE) && (
                <div className={classes.modifyIcons}>
                  {privileges?.CREATE && (
                    <img
                      className={classes.fileIcon}
                      style={{ cursor: "pointer" }}
                      src={COPY_ICON}
                      alt="Copy Icon"
                      onClick={handleContentCopyOpen}
                    />
                  )}
                  {(data?.status?.toUpperCase() === "COMPLETED"
                    ? isEditable
                    : privileges?.UPDATE) && (
                    <img
                      className={classes.fileIcon}
                      src={EDIT_ICON}
                      style={{ cursor: "pointer" }}
                      alt="Edit Icon"
                      onClick={(e) => {
                        e.stopPropagation();
                        setModalState((prev) => ({
                          ...prev,
                          isOpen: true,
                          isEdit: true,
                          data,
                        }));
                      }}
                    />
                  )}
                </div>
              )}
            </div>
            <Typography
              className={classes.subTitle}
              marginLeft={"25px"}
              variant="span"
            >
              {annualPeriodName}
            </Typography>
            <div className={classes.cardBodyContent}>
              <img
                className={classes.fileIcon}
                src={CHALKBOARD_ICON}
                alt="Chalkboard Icon"
              />
              <Typography
                className={classes.bodyText}
                variant="span"
                marginRight={"34px"}
              >
                Class: {data?.curriculumContext?.gradeName || "-"}
              </Typography>
              <img
                className={classes.fileIcon}
                src={STICKY_NOTE_ICON}
                alt="Sticky Note Icon"
              />
              <Typography className={classes.bodyText} variant="span">
                Subject: {data?.curriculumContext?.subjectName || "-"}
              </Typography>
            </div>
            <div>
              <img
                className={classes.fileIcon}
                src={CLOCK_ICON}
                alt="Clock Icon"
              />
              <Typography className={classes.bodyText} variant="span">
                Start Date:{" "}
                {data?.start
                  ? moment(data.start).format(GLOBAL_DATE_FORMAT)
                  : "-"}
              </Typography>
              <span className={classes.dotStyle}></span>
              <Typography className={classes.bodyText} variant="span">
                End Date:{" "}
                {data?.end ? moment(data.end).format(GLOBAL_DATE_FORMAT) : "-"}
              </Typography>
            </div>
            <div className={classes.cardBottom}>
              <div className={classes.createdBySection}>
                <Avatar className={classes.avatar}>
                  {data?.createdBy?.charAt(0)?.toUpperCase()}
                </Avatar>
                <div className={classes.createdBy}>
                  <Typography className={classes.subTitle} variant="div">
                    Created by
                  </Typography>
                  <Typography
                    className={classes.bodyText}
                    marginRight="10px"
                    variant="div"
                  >
                    {data?.createdBy || "-"}
                  </Typography>
                </div>
              </div>
              <div className={classes.statusBlock}>
                <StatusBlock status={data?.status?.toUpperCase() || ""} />
              </div>
            </div>
          </CardContent>
        </Card>
      </Box>
      <Dialog open={copyDialogOpen} onClose={handleContentCopyClose}>
        <DialogTitle>Create duplicate annual plan</DialogTitle>
        <DialogContent>
          <Box component="form" sx={{ display: "flex", flexWrap: "wrap" }}>
            <FormControl
              sx={{ m: 1, minWidth: 200 }}
              variant="outlined"
              className={classes.inputFormControl}
            >
              <InputLabel
                htmlFor="demo-dialog-native"
                className={classes.inputLabel1}
              >
                Annual Period
              </InputLabel>
              <Select
                variant="outlined"
                value={annualPeriodDuplicate || ""}
                onChange={handleAnnualPlanChange}
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                placeholder="annual period"
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem value="" disabled>
                  <span className={classes.placeholderValue}>
                    Select annual period
                  </span>
                </MenuItem>
                <MenuItem value="AY21_22">A.Y. 2021-2022</MenuItem>
                <MenuItem value="AY22_23">A.Y. 2022-2023</MenuItem>
                <MenuItem value="AY23_24">A.Y. 2023-2024</MenuItem>
                <MenuItem value="AY24_25">A.Y. 2024-2025</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleContentCopyClose}>Cancel</Button>
          <Button onClick={handleContentCopySubmit}>Submit</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
