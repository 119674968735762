import {
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  TextField,
} from "@material-ui/core";
import SelectDropDown from "common/components/SelectDropDown/SelectDropDown";
import {
  LOVApiKeys,
  LOVDataKeys,
  LOVDisplayKeys,
  LOVSelectKeys,
} from "common/components/SelectDropDown/SelectDropDownUtils";
import * as R from "ramda";
import { useSelector } from "react-redux";
import { PAGES } from "state/slices/pageSlice";

const useStyles = makeStyles((theme) => ({
  inputFormControl: {
    marginBottom: "2rem",
  },
  inputLabel: {
    top: "-17px",
  },
  inputLabel1: {
    top: "-17px",
    left: "-14px",
  },
}));

const StaffForm = ({
  formType,
  isEditForm,
  isReadOnly,
  state,
  handleChange,
}) => {
  const classes = useStyles();
  const dropdownListData = useSelector(
    (state) => state?.pages?.[PAGES.schools]?.items || []
  );

  const onChange = (event) => {
    const [value, name] = R.paths(
      [
        ["target", "value"],
        ["target", "name"],
      ],
      event
    );
    handleChange(name, value);
  };

  return (
    <form style={{ marginTop: "1rem" }}>
      <Grid container>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormControl className={classes.inputFormControl}>
              <InputLabel className={classes.inputLabel}>First Name</InputLabel>
              <TextField
                variant="outlined"
                margin="normal"
                name="firstName"
                placeholder={"Enter first name"}
                value={state.firstName || ""}
                onChange={onChange}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl className={classes.inputFormControl}>
              <InputLabel className={classes.inputLabel}>Last Name</InputLabel>
              <TextField
                variant="outlined"
                margin="normal"
                name="lastName"
                placeholder={"Enter last name"}
                value={state.lastName || ""}
                onChange={onChange}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl className={classes.inputFormControl}>
              <InputLabel className={classes.inputLabel}>Email</InputLabel>
              <TextField
                variant="outlined"
                margin="normal"
                name="email"
                autoComplete={false}
                placeholder={"Enter email address"}
                value={state.email || ""}
                onChange={onChange}
                disabled={isEditForm}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl className={classes.inputFormControl}>
              <InputLabel className={classes.inputLabel}>
                Current Address
              </InputLabel>
              <TextField
                variant="outlined"
                margin="normal"
                name="currentAddress"
                placeholder={"Enter current address"}
                value={state.currentAddress || ""}
                onChange={onChange}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl className={classes.inputFormControl}>
              <InputLabel className={classes.inputLabel}>
                Permanent Address
              </InputLabel>
              <TextField
                variant="outlined"
                margin="normal"
                name="permanentAddress"
                placeholder={"Enter permanent address"}
                value={state.permanentAddress || ""}
                onChange={onChange}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl className={classes.inputFormControl}>
              <InputLabel className={classes.inputLabel}>
                Mobile Number
              </InputLabel>
              <TextField
                variant="outlined"
                margin="normal"
                name="mobile"
                placeholder={"Enter mobile number"}
                value={state.mobile || ""}
                onChange={onChange}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl className={classes.inputFormControl}>
              <InputLabel className={classes.inputLabel}>
                Designation
              </InputLabel>
              <TextField
                variant="outlined"
                margin="normal"
                name="designation"
                placeholder={"Enter designation"}
                value={state.designation || ""}
                onChange={onChange}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl className={classes.inputFormControl}>
              <InputLabel className={classes.inputLabel}>
                Experience (in months)
              </InputLabel>
              <TextField
                variant="outlined"
                margin="normal"
                name="experienceInMonths"
                placeholder={"Enter experience in number of months"}
                value={state.experienceInMonths || ""}
                onChange={onChange}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl className={classes.inputFormControl}>
              <InputLabel className={classes.inputLabel}>
                Onoarding Date
              </InputLabel>
              <TextField
                variant="outlined"
                margin="normal"
                id="date"
                name="onboardingDate"
                placeholder={"Enter onboarding date"}
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={onChange}
                value={state.onboardingDate || ""}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl
              variant="outlined"
              className={classes.inputFormControl}
            >
              <InputLabel
                className={classes.inputLabel1}
                id="demo-simple-select-outlined-label"
              >
                School
              </InputLabel>
              <SelectDropDown
                placeholder={"select school name"}
                formType={formType}
                selectKey={LOVSelectKeys.SCHOOL}
                apiKey={LOVApiKeys.SCHOOL}
                dataKey={LOVDataKeys.SCHOOL}
                secondaryDataKey={"campus"}
                selectedValue={
                  dropdownListData.find(
                    (item) => item?.schoolCode === state?.school?.schoolCode
                  ) || {}
                }
                displayKey={LOVDisplayKeys.SCHOOL}
                page={PAGES.schools}
                customOnChange={(selectedValue) =>
                  handleChange("school.schoolCode", selectedValue.schoolCode)
                }
              />
            </FormControl>
          </Grid>

          {/* {!isEditForm && (
            <Grid item xs={12}>
              <FormControl>
                <InputLabel className={classes.inputLabel}>Password</InputLabel>
                <TextField
                  type="password"
                  variant="outlined"
                  margin="normal"
                  name="rawPassword"
                  placeholder={"Enter password"}
                  autoComplete="false"
                  value={isEditForm ? state.rawPassword : ""}
                  onChange={onChange}
                />
              </FormControl>
            </Grid>
          )} */}
        </Grid>
      </Grid>
    </form>
  );
};

export default StaffForm;
