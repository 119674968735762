import * as React from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Button from "@mui/material/Button";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";

const goalList = {
  "Skill Goals": [
    {
      Goal_Description:
        "_________% of students will exhibit critical thinking skills ",
      Measure_Unit:
        "% of students consistently excelling on HOTS assignments/projects/activities",
      Evidence: "worksheets, assigments",
      SortOrder: 101,
    },
    {
      Goal_Description:
        "_________% of students will exhibit design thinking skills",
      Measure_Unit:
        "% of students consistently using Design thinking process on assignments/projects",
      Evidence:
        "list of projects as a group activity and documenting outcomes in the form of videos / written feedback.",
      SortOrder: 102,
    },
    {
      Goal_Description:
        "_________% of students will exhibit discipline and values",
      Measure_Unit: "% of students consistently displaying good behavior",
      Evidence: "restorative discipline management chart.",
      SortOrder: 103,
    },
    {
      Goal_Description:
        "_________% of students will master the art of negotiation ",
      Measure_Unit: "% of students",
      Evidence: "debates/ argument writing activities",
      SortOrder: 104,
    },
    {
      Goal_Description:
        "_________% of students will exhibit communication skills ",
      Measure_Unit:
        "% students consistently displaying great oratory skills in assignments/projects/competitions",
      Evidence: "competitions, projects",
      SortOrder: 105,
    },
    {
      Goal_Description:
        "________% of students will exhibit logical reasoning skills",
      Measure_Unit:
        "% students consistently displaying great logical reasoning skills in assignments/projects/competitions",
      Evidence: "competitions, projects",
      SortOrder: 106,
    },
    {
      Goal_Description:
        "_________% of students will exhibit physical and emotional well-being",
      Measure_Unit: "% students with 100% attendance records",
      Evidence:
        "observable , documented evidence from QCT sessions, student feedback",
      SortOrder: 107,
    },
    {
      Goal_Description:
        "_________% of students will exhibit public speaking skills",
      Measure_Unit: "% students ",
      Evidence: "assemblies, competitions, events",
      SortOrder: 108,
    },
    {
      Goal_Description:
        "_________% of students will develop problem solving and conflict resolution skills.",
      Measure_Unit: "% students",
      Evidence: "projects, case studies",
      SortOrder: 109,
    },
    {
      Goal_Description:
        "________% of students will participate in international/national competitions",
      Measure_Unit: "% of students",
      Evidence: "participation/winning certificates",
      SortOrder: 110,
    },
    {
      Goal_Description:
        "________% of students will win international/national competitions",
      Measure_Unit: "% of students",
      Evidence: "participation/winning certificates",
      SortOrder: 111,
    },
    {
      Goal_Description: "_______% of students will develop financial literacy ",
      Measure_Unit: "% of students",
      Evidence: "project reports, training sessions",
      SortOrder: 112,
    },
    {
      Goal_Description: "_______% of students will develop digital literacy",
      Measure_Unit: "% of students",
      Evidence: "project reports, training sessions",
      SortOrder: 113,
    },
    {
      Goal_Description: "_______% of students will develop reading proficiency",
      Measure_Unit: "% of students",
      Evidence: "reading tests, competitions",
      SortOrder: 114,
    },
    {
      Goal_Description:
        "_______% of students will develop literary proficiency(composing prose/poetry)",
      Measure_Unit: "% of students",
      Evidence: "events, competitions",
      SortOrder: 115,
    },
    {
      Goal_Description:
        "_______% of students will develop STEM innovation proficiency",
      Measure_Unit: "% of students",
      Evidence: "events, competitions, projects",
      SortOrder: 116,
    },
    {
      Goal_Description:
        "_______% of students will develop Fine arts innovation proficiency",
      Measure_Unit: "% of students",
      Evidence: "events, exhibitions, competitions, projects",
      SortOrder: 117,
    },
    {
      Goal_Description:
        "_______% of students will develop performing arts proficiency",
      Measure_Unit: "% of students",
      Evidence: "events, exhibitions, competitions, projects",
      SortOrder: 118,
    },
    {
      Goal_Description: "_______% of students will develop coding proficiency ",
      Measure_Unit: "% of students",
      Evidence: "competitions, projects",
      SortOrder: 119,
    },
    {
      Goal_Description:
        "_______% of students will develop proficiency in _____ sport",
      Measure_Unit: "% of students",
      Evidence: "competitions, skill display",
      SortOrder: 120,
    },
    {
      Goal_Description:
        "______% of students exhibiting finest levels of fitness and agility",
      Measure_Unit: "% of students",
      Evidence: "fitness assessments, BMI Indices",
      SortOrder: 121,
    },
    {
      Goal_Description:
        "______% of students exhibiting proficiency in playing musical instruments",
      Measure_Unit: "% of students",
      Evidence: "events, competitions, projects",
      SortOrder: 122,
    },
  ],
  "Learning Goals": [
    {
      Goal_Description: "Improving class averages to ___________",
      Measure_Unit: "Class average %",
      Evidence: "-",
      SortOrder: 201,
    },
    {
      Goal_Description:
        "______ no. of opportunities will be created for students to become independent logical thinkers",
      Measure_Unit: "No. of opportunities through the tier/term/year",
      Evidence: "events, projects, activities organized",
      SortOrder: 202,
    },
    {
      Goal_Description:
        "______ no. of inter-disciplinary learning opportunities will be created for students",
      Measure_Unit: "No. of opportunities through the tier/term/year",
      Evidence: "interdisciplinary lesson plans/assessments conducted",
      SortOrder: 203,
    },
    {
      Goal_Description:
        "______ no. of experiential learning opportunities will be created for students",
      Measure_Unit: "No. of opportunities through the tier/term/year",
      Evidence:
        "lesson plans with experiential learning projects, actual project description, images",
      SortOrder: 204,
    },
    {
      Goal_Description:
        "_______ no. of problem solving opportunities will be created for students",
      Measure_Unit: "No. of opportunities through the tier/term/year",
      Evidence: "events, projects, activities organized",
      SortOrder: 205,
    },
    {
      Goal_Description:
        "_______ no. of creative expression opportunities will be created for students",
      Measure_Unit: "No. of opportunities through the tier/term/year",
      Evidence: "events, projects, activities organized",
      SortOrder: 206,
    },
    {
      Goal_Description:
        "______ no. of exhibiting leadership opportunities will be created for students ",
      Measure_Unit: "No. of opportunities through the tier/term/year",
      Evidence: "events, projects, activities organized",
      SortOrder: 207,
    },
    {
      Goal_Description:
        "______ no. of symbiotic learning opportunities will be be created for students",
      Measure_Unit: "No. of opportunities through the tier/term/year",
      Evidence: "events, projects, activities organized",
      SortOrder: 208,
    },
    {
      Goal_Description:
        "______ no. of design thinking opportunities will be created for students ",
      Measure_Unit: "No. of opportunities through the tier/term/year",
      Evidence: "events, projects, activities organized",
      SortOrder: 209,
    },
  ],
  "Teaching goals": [
    {
      Goal_Description:
        "______ no. of lesson plans where differentiated learning strategies were used",
      Measure_Unit: "No. of lesson plans ",
      Evidence: "lesson plans, execution photos",
      SortOrder: 301,
    },
    {
      Goal_Description:
        "______ no. of lesson plans where project based learning strategies were used",
      Measure_Unit: "No. of lesson plans ",
      Evidence: "lesson plans, execution photos",
      SortOrder: 302,
    },
    {
      Goal_Description:
        "______ no. of lesson plans where flipped classroom techniques were used",
      Measure_Unit: "No. of lesson plans ",
      Evidence: "lesson plans, execution photos",
      SortOrder: 303,
    },
    {
      Goal_Description:
        "______ no. of lesson plans where inquiry based learning was used",
      Measure_Unit: "No. of lesson plans ",
      Evidence: "lesson plans, execution photos",
      SortOrder: 304,
    },
    {
      Goal_Description:
        "______ no. of lesson plans where field visits were planned",
      Measure_Unit: "No. of lesson plans ",
      Evidence: "lesson plans, execution photos",
      SortOrder: 305,
    },
    {
      Goal_Description:
        "______ no. of lesson plans where thinking routines were followed",
      Measure_Unit: "No. of lesson plans ",
      Evidence: "lesson plans, execution photos",
      SortOrder: 306,
    },
  ],
  "Leadership goals": [
    {
      Goal_Description:
        "________no. of lesson observations and review sessions conducted per teacher",
      Measure_Unit: "no. of observations ",
      Evidence: "observation schedule, observation sheets",
      SortOrder: 401,
    },
    {
      Goal_Description:
        "________% of teachers exceeding their individual goals ",
      Measure_Unit: "% of teachers",
      Evidence: "teacher review documents",
      SortOrder: 402,
    },
    {
      Goal_Description: "Will achieve parent satisfaction rating of ________",
      Measure_Unit: "Rating score",
      Evidence: "feedback survey report",
      SortOrder: 403,
    },
    {
      Goal_Description: "Will achieve student satisfaction rating of _______",
      Measure_Unit: "Rating score",
      Evidence: "feedback survey report",
      SortOrder: 404,
    },
    {
      Goal_Description:
        "Will minimize teacher errors on papers/lesson plans/assignments to _________ %",
      Measure_Unit: "% error",
      Evidence: "teacher review documents",
      SortOrder: 405,
    },
  ],
};

const GoalsLibrary = ({ goalSettingForm, onSelect }) => {
  const [open, setOpen] = React.useState(false);
  const [selectedGoalDescription, setSelectedGoalDescription] =
    React.useState("");
  const [selectedMeasurementUnit, setSelectedMeasurementUnit] =
    React.useState("");
  const [selectedGoalEvidence, setSelectedGoalEvidence] = React.useState("");

  const [selectedIndex, setSelectedIndex] = React.useState(1);

  const handleChange = (event, goal) => {
    setSelectedGoalDescription(goal.Goal_Description);
    setSelectedMeasurementUnit(goal.Measure_Unit);
    setSelectedGoalEvidence(goal.Evidence);
    setSelectedIndex(goal.SortOrder);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    setSelectedGoalDescription("");
    if (reason !== "backdropClick") {
      setOpen(false);
    }
  };

  const handleSave = (event, reason) => {
    if (reason !== "backdropClick") {
      setOpen(false);
    }
    onSelect &&
      onSelect(
        selectedGoalDescription,
        selectedMeasurementUnit,
        selectedGoalEvidence
      );
    setOpen(false);
  };

  const renderSelectListGroup = (goalCategory) => {
    const items = goalList[goalCategory].map((goal, index) => {
      return (
        <ListItem key={index} sx={{ width: "100%", maxWidth: 600 }}>
          <ListItemButton
            selected={selectedIndex === goal.SortOrder}
            onClick={(event) => handleChange(event, goal)}
          >
            <ListItemIcon>
              <ArrowRightIcon />
            </ListItemIcon>
            <ListItemText
              primary={goal.Goal_Description}
              secondary={`${goal.Measure_Unit} (Evidence Accepted - ${goal.Evidence})`}
            />
          </ListItemButton>
        </ListItem>
      );
    });
    return [items];
  };

  return (
    <div>
      <Button
        onClick={handleClickOpen}
        disabled={goalSettingForm.goalSettingState === "REVIEWED"}
      >
        Select goal from library
      </Button>
      <Dialog
        maxWidth="md"
        fullWidth
        disableEscapeKeyDown
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>Choose goal</DialogTitle>
        <DialogContent>
          <Box component="form" sx={{ display: "flex", flexWrap: "wrap" }}>
            {Object.keys(goalList)?.map((goalCategory, index) => {
              return (
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="index"
                  >
                    <Typography>{goalCategory}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <List component="nav" aria-label="secondary mailbox folder">
                      {renderSelectListGroup(goalCategory, index)}
                    </List>
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSave}>Ok</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default GoalsLibrary;
