import * as R from "ramda";

export const mapIndexed = R.addIndex(R.map);
export const filterIndexed = R.addIndex(R.filter);
export const isNotEmpty = R.complement(R.isEmpty);
export const mergeArrayList = R.pipe(
    R.map(R.indexBy(R.prop('id'))),
    R.reduce(R.mergeWith(R.merge), {}),
    R.values
);

export const removeWhiteSpace = str => str?.replace(/ +/g, "");