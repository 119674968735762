import { Box, Button, Divider, Grid, Typography } from "@material-ui/core";
import React, { useRef } from "react";
import * as R from "ramda";
import { colors } from "theme/colors";
import { makeStyles } from "@material-ui/styles";
import { CloudDownload } from "@material-ui/icons";
import moment from "moment";
import { GLOBAL_DATE_FORMAT } from "constants/PageConstants";
import TopicReadView from "../UnitPlan/TopicReadView";
import { useReactToPrint } from "react-to-print";

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: "0.75rem",
  },

  heading: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: theme.typography.fontWeightBold,
    marginTop: "1rem",
    marginBottom: "1rem",
  },
  label: {
    color: colors.gray4,
  },
  downloadBtn: {
    "&.MuiButton-root": {
      "&:hover": {
        background: "#263657",
      },
    },
    background: "#263657",
    color: colors.gray1,
    padding: "10px 15px",
    margin: 0,
  },
}));

export const TierPlanReadView = ({ data }) => {
  const classes = useStyles();
  return (
    <Box>
      <Box
        sx={{
          background: "#898eac2e",
          padding: "2rem",
          borderRadius: 6,
          border: "1px solid #898EAC",
          margin: "0.5rem 0",
        }}
      >
        <Grid container>
          <Grid item xs={12} md={8}>
            <Typography variant="h6" color="primary">
              Name: {R.propOr("-", "name", data)}
            </Typography>
          </Grid>

          <Grid item xs={6} md={2} style={{ alignSelf: "center" }}>
            <Typography variant="subtitle1">
              Start:{" "}
              {moment(R.propOr("-", "start", data)).format(GLOBAL_DATE_FORMAT)}
            </Typography>
          </Grid>

          <Grid item xs={6} md={2} style={{ alignSelf: "center" }}>
            <Typography variant="subtitle1">
              End:{" "}
              {moment(R.propOr("-", "end", data)).format(GLOBAL_DATE_FORMAT)}
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <Box>
        <Box>
          <Typography variant="h6">Topics</Typography>
        </Box>
        {R.map((item) => {
          return <TopicReadView data={item} />;
        })(R.propOr([], "topics", data))}
      </Box>
    </Box>
  );
};

const AnnualPlanReadView = ({ data }) => {
  const printRef = useRef();
  const classes = useStyles();

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: "Annual Plan",
  });

  return (
    <Box style={{ padding: "1rem" }} id={"annualPlanReadView"} ref={printRef}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={10}>
          <Typography variant="h6" color="primary">
            Name: {R.propOr("-", "name", data)}
          </Typography>
        </Grid>
        <Grid item xs={12} md={2} className="k-pdf-export">
          <Button
            endIcon={<CloudDownload />}
            className={classes.downloadBtn}
            onClick={handlePrint}
          >
            Download
          </Button>
        </Grid>
      </Grid>
      <Grid container style={{ marginTop: "1.5rem" }}>
        <Grid item xs={12}>
          <Typography variant="subtitle2">
            Created By: {R.propOr("-", "createdBy", data)}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="subtitle2">
            Last Updated By: {R.propOr("-", "lastUpdatedBy", data)}
          </Typography>
        </Grid>

        <Grid item xs={5}>
          <Typography variant="subtitle2">
            Class:
            {R.pathOr("-", ["curriculumContext", "gradeName"], data)}
          </Typography>
        </Grid>

        <Grid item xs={7}>
          <Typography variant="subtitle2">
            Subject: {R.pathOr("-", ["curriculumContext", "subjectName"], data)}
          </Typography>
        </Grid>

        <Grid item xs={5}>
          <Typography variant="subtitle2">
            Start:{" "}
            {moment(R.propOr("-", "start", data)).format(GLOBAL_DATE_FORMAT)}
          </Typography>
        </Grid>

        <Grid item xs={7}>
          <Typography variant="subtitle2">
            End: {moment(R.propOr("-", "end", data)).format(GLOBAL_DATE_FORMAT)}
          </Typography>
        </Grid>

        <Grid item xs={12} style={{ marginTop: "1rem" }}>
          <Typography
            className={
              data.status === "ACTIVE"
                ? classes.activeLabel
                : classes.deactivateLabel
            }
          >
            <b>Status: {data.status}</b>
          </Typography>
        </Grid>
      </Grid>
      <Box style={{ marginTop: "1rem" }}>
        <Box
          sx={{
            background: "#263657",
            color: "#fff",
            padding: "0.5rem",
            borderRadius: 6,
          }}
        >
          <Typography variant="h6">Tiers</Typography>
        </Box>
        {R.map((item) => {
          return <TierPlanReadView data={item} />;
        })(R.propOr([], "tierPlans", data))}
      </Box>
    </Box>
  );
};

export default AnnualPlanReadView;
