import FILTER_SIDEBAR from "./StaffFilterSidebar";
import STAFF_LIST from "./StaffPlans";

export const planListComponents = {
  STAFF: STAFF_LIST,
};

export const sideBarComponent = {
  STAFF: FILTER_SIDEBAR,
};
