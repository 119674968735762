import React, { useEffect } from "react";
import { PAGES, setPageListItems } from "state/slices/pageSlice";
import GridViewWrapper from "common/wrappers/GridViewWrapper";
import { PAGE_RENDERER } from "common/utils/rendererList";
import { useDispatch, useSelector } from "react-redux";
import { clearPageFilters } from "state/slices/globalFormSlice";
import { FORM_RENDERERS, FORM_TYPES } from "common/utils/formUtility";
import { getPageList } from "components/listOfValues/utils";
import * as R from "ramda";

const Roles = () => {
  const dispatch = useDispatch();
  const pageData = useSelector((state) =>
    R.path(["pages", PAGES.privileges], state)
  );
  const pageFilter = useSelector((state) =>
    R.path(["globalForm", "pageFilters"], state)
  );

  const getPrivilegeList = async () => {
    const pageListData = await getPageList({
      searchKey: "",
      page: PAGES.privileges,
      pageData,
      pageFilter,
    });
    dispatch(
      setPageListItems({
        page: PAGES.privileges,
        itemList: pageListData.content,
        totalElements: pageListData.totalElements,
        totalPages: pageListData.totalPages,
      })
    );
  };
  useEffect(() => {
    dispatch(clearPageFilters());
    getPrivilegeList();
  }, []);
  return (
    <GridViewWrapper
      paginatedListRenderer={PAGE_RENDERER.ROLES}
      page={PAGES.roles}
      displayAdd={false}
      showFilters={false}
      formDialogConfig={{
        formType: FORM_TYPES.ROLE_PRIVILEGES_FORM,
        formRenderer: FORM_RENDERERS.ROLE_PRIVILEGES_FORM,
        title: "Role Privileges",
      }}
    />
  );
};

export default Roles;
