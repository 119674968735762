import React from "react";
import {
  Grid,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
} from "@material-ui/core";
import AttachmentIcon from "@material-ui/icons/Attachment";
import DeleteIcon from "@material-ui/icons/Delete";
import { makeStyles } from "@material-ui/styles";
import {
  getDetailsById,
  removeSelfGoalFeedbackEvidence,
} from "components/listOfValues/utils";

const useStyle = makeStyles(() => ({
  pointer: {
    cursor: "pointer",
  },
  inputLabel: {
    top: "-17px",
  },
}));
function GoalEvidenceList({ evidences, readOnly = false, postMethod }) {
  const classes = useStyle();
  const handleOpenEvidence = async (evidenceId) => {
    const response = await getDetailsById("/goal/evidence", evidenceId);
    const base64String = response.body;
    const buffer = Buffer.from(base64String, "base64");
    const blob = new Blob([buffer], { type: response.documentType });
    const url = URL.createObjectURL(blob);
    window.open(url, "_blank");
    // URL.revokeObjectURL(url);
  };

  const handleDeleteEvidence = async (evidenceId) => {
    await removeSelfGoalFeedbackEvidence(evidenceId);
    postMethod && postMethod();
  };
  return (
    <Grid item xs={6}>
      <InputLabel className={classes.inputLabel}>Evidences</InputLabel>
      <List dense>
        {evidences.map((evidence) => (
          <ListItem>
            <ListItemIcon>
              <AttachmentIcon />
            </ListItemIcon>
            <ListItemText
              primary={evidence.fileName}
              secondary={evidence.comment}
              className={classes.pointer}
              onClick={() => handleOpenEvidence(evidence.id)}
            />
            {!readOnly ? (
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => handleDeleteEvidence(evidence.id)}
                >
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            ) : null}
          </ListItem>
        ))}
      </List>
    </Grid>
  );
}

export default GoalEvidenceList;
