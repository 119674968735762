import {
  Divider,
  Grid,
  InputLabel,
  TextField,
  makeStyles,
} from "@material-ui/core";
import LOVSelect from "common/components/LOVSelect/LOVSelect";
import {
  LOVDataKeys,
  LOVKeyMap,
  LOVSelectKeys,
} from "common/components/SelectDropDown/SelectDropDownUtils";
import ConsentForm from "common/forms/ConsentForm";
import ObservationSelectionForm from "common/forms/ObservationSelectionForm";
import TLMReferenceForm from "common/forms/TLMReferenceForm";
import TLMSequenceReferenceForm from "common/forms/TLMSequenceReferenceForm";
import TLStrategyReferenceForm from "common/forms/TLStrategyReferenceForm";
import {
  getSelectListForPlanForm as getSelectList,
  setObservationsValue,
} from "common/forms/utils";
import { FORM_TYPES } from "common/utils/formUtility";
import * as R from "ramda";
import { useSelector } from "react-redux";
import { PAGES } from "state/slices/pageSlice";

const useStyles = makeStyles((theme) => ({
  heading: {
    margin: 0,
    color: "#263657",
  },
  divider: {
    marginBottom: "24px",
    marginTop: "8px",
  },
  marginBottom30: {
    marginBottom: "30px",
  },
  marginBottom42: {
    marginBottom: "42px",
  },
  inputLabel1: {
    top: "-17px",
    left: "-14px",
  },
  inputLabel: {
    top: "-17px",
    width: "100%",
  },
}));

function UnitPlanSummary({ formData = {}, onFormChange, isReadOnly }) {
  const classes = useStyles();

  return (
    <div>
      <h3 className={classes.heading} align={"left"}>
        Periods
      </h3>
      <Divider className={classes.divider} />
      <InputLabel className={classes.inputLabel}>
        <b>Select Periods</b>
      </InputLabel>
      <TextField
        type="number"
        variant="outlined"
        id="multiline-static"
        value={formData?.periods}
        fullWidth
        placeholder="Enter Periods"
        InputProps={{ inputProps: { min: 0 } }}
        className={classes.marginBottom42}
        onChange={(e) => onFormChange(e.target.value, ["periods"])}
        disabled={isReadOnly}
      />
      <h3 className={classes.heading} align={"left"}>
        Teaching / Learning Strategies
      </h3>
      <Divider className={classes.divider} />
      <TLStrategyReferenceForm
        displayLOVSelect={true}
        TLMData={formData?.tlmList || []}
        setTLMData={(data) => onFormChange(data, ["tlmList"])}
        planSummary={true}
        isReadOnly={isReadOnly}
      />
    </div>
  );
}

function StudentOwnLearning({ formData = {}, onFormChange, isReadOnly }) {
  const classes = useStyles();

  return (
    <div>
      <h3 className={classes.heading} align={"left"}>
        Students own Learning Objectives
      </h3>
      <Divider className={classes.divider} />
      <InputLabel className={classes.inputLabel}>
        <b>Student own learning objectives</b>
      </InputLabel>
      <TextField
        variant="outlined"
        margin="normal"
        id="multiline-static"
        placeholder="Students Own Learning Objectives"
        multiline
        fullWidth
        data-html2canvas-ignore
        value={formData?.["studentObjectives"] || ""}
        onChange={(e) => onFormChange(e.target.value, ["studentObjectives"])}
        disabled={isReadOnly}
      />
    </div>
  );
}

function LearningObjective({ formData = {}, onFormChange, isReadOnly }) {
  const classes = useStyles();

  return (
    <div>
      <h3 className={classes.heading} align={"left"}>
        Concept Related Objectives
      </h3>
      <Divider className={classes.divider} />
      <InputLabel className={classes.inputLabel}>
        <b>
          Concept Related Objectives: (Knowledge and Comprehension, Remembering
          and Understanding)
        </b>
      </InputLabel>
      <TextField
        id="multiline-static"
        placeholder="Students will be able to"
        multiline
        variant="outlined"
        margin="normal"
        fullWidth
        data-html2canvas-ignore
        className={classes.marginBottom30}
        value={formData?.learningObjectiveForm?.conceptObjective || ""}
        onChange={(e) =>
          onFormChange(e.target.value, [
            "learningObjectiveForm",
            "conceptObjective",
          ])
        }
        disabled={isReadOnly}
      />
      <InputLabel className={classes.inputLabel}>
        <b>
          Skill Related Objectives: Apply, Analyse, Evaluate and Create
          (Applying, Analysing, Evaluating, Creating)
        </b>
      </InputLabel>
      <TextField
        id="multiline-static"
        variant="outlined"
        margin="normal"
        placeholder="Students will be able to"
        multiline
        fullWidth
        data-html2canvas-ignore
        className={classes.marginBottom30}
        value={formData?.learningObjectiveForm?.skillObjective || ""}
        onChange={(e) =>
          onFormChange(e.target.value, [
            "learningObjectiveForm",
            "skillObjective",
          ])
        }
        disabled={isReadOnly}
      />
      <InputLabel className={classes.inputLabel}>
        <b>
          Fact Related Objectives: Knowledge and Comprehension (Remembering and
          Understanding)
        </b>
      </InputLabel>
      <TextField
        id="multiline-static"
        variant="outlined"
        margin="normal"
        placeholder="Students will be able to"
        multiline
        className={classes.marginBottom42}
        fullWidth
        data-html2canvas-ignore
        value={formData?.learningObjectiveForm?.factObjective || ""}
        onChange={(e) =>
          onFormChange(e.target.value, [
            "learningObjectiveForm",
            "factObjective",
          ])
        }
        disabled={isReadOnly}
      />
      <h3 className={classes.heading} align={"left"}>
        Vocabulary Related Objectives
      </h3>
      <Divider className={classes.divider} />
      <InputLabel className={classes.inputLabel}>
        <b>
          Students will be able to comprehend academic language, and also use it
          in context.
        </b>
      </InputLabel>
      <TextField
        id="multiline-static"
        variant="outlined"
        margin="normal"
        placeholder="Language of Instruction (used by teacher, comprehended by students)"
        multiline
        className={classes.marginBottom30}
        fullWidth
        data-html2canvas-ignore
        value={
          formData?.learningObjectiveForm?.vocabularyObjective
            ?.languageOfInstruction || ""
        }
        onChange={(e) =>
          onFormChange(e.target.value, [
            "learningObjectiveForm",
            "vocabularyObjective",
            "languageOfInstruction",
          ])
        }
        disabled={isReadOnly}
      />
      {/* <InputLabel className={classes.inputLabel}>
        <b>Student vocabulary (internalised and use in context)</b>
      </InputLabel>
      <TextField
        id="multiline-static"
        variant="outlined"
        margin="normal"
        placeholder="Student Vocabulary (internalised and use in context)"
        className={classes.marginBottom42}
        multiline
        fullWidth
        data-html2canvas-ignore
        value={
          formData?.learningObjectiveForm?.vocabularyObjective
            ?.studentVocabulary || ""
        }
        onChange={(e) =>
          onFormChange(e.target.value, [
            "learningObjectiveForm",
            "vocabularyObjective",
            "studentVocabulary",
          ])
        }
        disabled={isReadOnly}
      /> */}
      <h3 className={classes.heading} align={"left"}>
        Values Related Objectives
      </h3>
      <Divider className={classes.divider} />
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <InputLabel className={classes.inputLabel}>
            <b>Symbiotic Learning</b>
          </InputLabel>
          <TextField
            id="multiline-static"
            variant="outlined"
            margin="normal"
            placeholder="Students will be able to"
            multiline
            className={classes.marginBottom30}
            data-html2canvas-ignore
            fullWidth
            value={
              formData?.learningObjectiveForm?.valuesObjectives
                ?.symbioticLearning || ""
            }
            onChange={(e) =>
              onFormChange(e.target.value, [
                "learningObjectiveForm",
                "valuesObjectives",
                "symbioticLearning",
              ])
            }
            disabled={isReadOnly}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <InputLabel className={classes.inputLabel}>
            <b>Environmental Consciousness</b>
          </InputLabel>
          <TextField
            id="multiline-static"
            placeholder="Students will be able to:"
            variant="outlined"
            margin="normal"
            multiline
            className={classes.marginBottom30}
            data-html2canvas-ignore
            fullWidth
            value={
              formData?.learningObjectiveForm?.valuesObjectives
                ?.environmentalConsciousness || ""
            }
            onChange={(e) =>
              onFormChange(e.target.value, [
                "learningObjectiveForm",
                "valuesObjectives",
                "environmentalConsciousness",
              ])
            }
            disabled={isReadOnly}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <InputLabel className={classes.inputLabel}>
            <b>Design Thinking</b>
          </InputLabel>
          <TextField
            id="multiline-static"
            placeholder="Students will be able to:"
            variant="outlined"
            margin="normal"
            multiline
            className={classes.marginBottom30}
            data-html2canvas-ignore
            fullWidth
            value={
              formData?.learningObjectiveForm?.valuesObjectives
                ?.designThinking || ""
            }
            onChange={(e) =>
              onFormChange(e.target.value, [
                "learningObjectiveForm",
                "valuesObjectives",
                "designThinking",
              ])
            }
            disabled={isReadOnly}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <InputLabel className={classes.inputLabel}>
            <b>Emotional Wellness</b>
          </InputLabel>
          <TextField
            id="multiline-static"
            placeholder="Students will be able to:"
            variant="outlined"
            margin="normal"
            className={classes.marginBottom30}
            multiline
            data-html2canvas-ignore
            fullWidth
            value={
              formData?.learningObjectiveForm?.valuesObjectives
                ?.emotionalWellness || ""
            }
            onChange={(e) =>
              onFormChange(e.target.value, [
                "learningObjectiveForm",
                "valuesObjectives",
                "emotionalWellness",
              ])
            }
            disabled={isReadOnly}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <InputLabel className={classes.inputLabel}>
            <b>Sustainable Development Goals / Global Citizenship</b>
          </InputLabel>
          <TextField
            id="multiline-static-sustainableDevelopment"
            placeholder="Students will be able to:"
            variant="outlined"
            margin="normal"
            multiline
            className={classes.marginBottom30}
            data-html2canvas-ignore
            fullWidth
            value={
              formData?.learningObjectiveForm?.valuesObjectives
                ?.sustainableDevelopment || ""
            }
            onChange={(e) =>
              onFormChange(e.target.value, [
                "learningObjectiveForm",
                "valuesObjectives",
                "sustainableDevelopment",
              ])
            }
            disabled={isReadOnly}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <InputLabel className={classes.inputLabel}>
            <b>Value of the Month</b>
          </InputLabel>
          <TextField
            id="multiline-static-valueOfMonth"
            placeholder="Students will be able to:"
            variant="outlined"
            margin="normal"
            multiline
            className={classes.marginBottom30}
            data-html2canvas-ignore
            fullWidth
            value={
              formData?.learningObjectiveForm?.valuesObjectives?.valueOfMonth ||
              ""
            }
            onChange={(e) =>
              onFormChange(e.target.value, [
                "learningObjectiveForm",
                "valuesObjectives",
                "valueOfMonth",
              ])
            }
            disabled={isReadOnly}
          />
        </Grid>
      </Grid>
    </div>
  );
}

function LearningOutcomes({
  formData = {},
  onFormChange,
  isReadOnly,
  planName,
  unitData,
  prePrimaryLesson,
}) {
  const classes = useStyles();
  let observationsData = useSelector(
    (state) => state.pages[PAGES.observations]
  );
  const observationRegistry = R.filter((item) => {
    return R.includes(
      unitData?.curriculumContext?.gradeName || "",
      R.map((classItem) => classItem.className)(item.grades)
    );
  })(R.propOr([], "items", observationsData))[0];

  return (
    <div>
      <h3 className={classes.heading} align={"left"}>
        Learning Outcomes - {observationRegistry?.name}
      </h3>
      <Divider className={classes.divider} />
      <ObservationSelectionForm
        className={unitData?.curriculumContext?.gradeName || ""}
        value={setObservationsValue(
          formData.learningOutcomes || [],
          observationsData,
          unitData?.curriculumContext?.gradeName || ""
        )}
        formType={
          FORM_TYPES[
            planName === "UNIT_PLAN"
              ? "UNIT_PLAN_FORM"
              : prePrimaryLesson
              ? "LESSON_PLAN_PRE_PRIMARY_FORM"
              : "LESSON_PLAN_FORM"
          ]
        }
        setLearningOutcomes={(data) => onFormChange(data, ["learningOutcomes"])}
        hideSubTitle={true}
        isReadOnly={isReadOnly}
      />
    </div>
  );
}

function LevelOfLearning({ formData = {}, onFormChange, isReadOnly }) {
  const classes = useStyles();
  return (
    <div>
      <h3 className={classes.heading} align={"left"}>
        Level/s Of Learning
      </h3>
      <Divider className={classes.divider} />
      <InputLabel className={classes.inputLabel}>
        <b>All learners will be able to</b>
      </InputLabel>
      <TextField
        id="multiline-static"
        placeholder="All learners will be able to"
        variant="outlined"
        margin="normal"
        multiline
        fullWidth
        data-html2canvas-ignore
        className={classes.marginBottom30}
        value={formData?.levelOfLeaningForm?.levelOfLearningInput1 || ""}
        onChange={(e) =>
          onFormChange(e.target.value, [
            "levelOfLeaningForm",
            "levelOfLearningInput1",
          ])
        }
        disabled={isReadOnly}
      />
      <InputLabel className={classes.inputLabel}>
        <b>Most learners should be able to</b>
      </InputLabel>
      <TextField
        id="multiline-static"
        placeholder="Most learners should be able to"
        variant="outlined"
        margin="normal"
        multiline
        fullWidth
        data-html2canvas-ignore
        className={classes.marginBottom30}
        value={formData?.levelOfLeaningForm?.levelOfLearningInput2 || ""}
        onChange={(e) =>
          onFormChange(e.target.value, [
            "levelOfLeaningForm",
            "levelOfLearningInput2",
          ])
        }
        disabled={isReadOnly}
      />
      <InputLabel className={classes.inputLabel}>
        <b>Some learners should be able to</b>
      </InputLabel>
      <TextField
        id="multiline-static"
        placeholder="Some learners may be able to"
        variant="outlined"
        margin="normal"
        multiline
        fullWidth
        data-html2canvas-ignore
        className={classes.marginBottom30}
        value={formData?.levelOfLeaningForm?.levelOfLearningInput3 || ""}
        onChange={(e) =>
          onFormChange(e.target.value, [
            "levelOfLeaningForm",
            "levelOfLearningInput3",
          ])
        }
        disabled={isReadOnly}
      />
    </div>
  );
}

function Assessments({ formData = {}, onFormChange, isReadOnly }) {
  const classes = useStyles();
  const selectedValue =
    formData?.assessmentForm?.formativeAssessments?.map((item) => item?.name) ||
    [];

  return (
    <div>
      <h3 className={classes.heading} align={"left"}>
        Formative Assessment
      </h3>
      <Divider className={classes.divider} />
      <InputLabel className={classes.inputLabel}>
        <b>Formative assessment</b>
      </InputLabel>
      <LOVSelect
        variant="outlined"
        margin="normal"
        formType={FORM_TYPES.UNIT_PLAN_FORM}
        selectKey={LOVSelectKeys.FORMATIVE}
        dataKey={LOVDataKeys.FORMATIVE}
        lovKey={LOVKeyMap.FORMATIVE}
        className={classes.marginBottom30}
        value={selectedValue}
        customOnChange={(value) => {
          onFormChange(value, ["assessmentForm", "formativeAssessments"]);
        }}
        multiselect={true}
        selectObj={true}
        getSelectList={getSelectList}
        showList={true}
        isReadOnly={isReadOnly}
      />
      <InputLabel className={classes.inputLabel} style={{ marginTop: "30px" }}>
        <b>Formative assessment Comments</b>
      </InputLabel>
      <TextField
        id="multiline-static"
        margin="normal"
        variant="outlined"
        placeholder="Enter formative assessment comments"
        helperText="Please enter your Formative assessment comments"
        multiline
        fullWidth
        data-html2canvas-ignore
        className={classes.marginBottom30}
        value={formData?.assessmentForm?.formative || ""}
        onChange={(e) =>
          onFormChange(e.target.value, ["assessmentForm", "formative"])
        }
        disabled={isReadOnly}
      />
      <InputLabel className={classes.inputLabel}>
        <b>Formative assessment Reference Links</b>
      </InputLabel>
      <TextField
        id="multiline-static"
        margin="normal"
        variant="outlined"
        placeholder="Enter formative assessment reference links"
        helperText="Please enter your Formative assessment reference links"
        multiline
        fullWidth
        data-html2canvas-ignore
        className={classes.marginBottom42}
        value={formData?.assessmentForm?.formativeReference || ""}
        onChange={(e) =>
          onFormChange(e.target.value, ["assessmentForm", "formativeReference"])
        }
        disabled={isReadOnly}
      />
      <h3 className={classes.heading} align={"left"}>
        Summative Assessment
      </h3>
      <Divider className={classes.divider} />
      <InputLabel className={classes.inputLabel}>
        <b>Summative assessment</b>
      </InputLabel>
      <TextField
        id="multiline-static"
        margin="normal"
        variant="outlined"
        placeholder="Enter summative assessment comments"
        helperText="Please enter your Summative assessment comments"
        multiline
        fullWidth
        data-html2canvas-ignore
        className={classes.marginBottom30}
        value={formData?.assessmentForm?.summative || ""}
        onChange={(e) =>
          onFormChange(e.target.value, ["assessmentForm", "summative"])
        }
        disabled={isReadOnly}
      />
      <InputLabel className={classes.inputLabel}>
        <b>Summative assessment Reference links</b>
      </InputLabel>
      <TextField
        id="multiline-static"
        variant="outlined"
        margin="normal"
        placeholder="Enter summative assessment reference links"
        helperText="Please enter your Summative assessment reference links"
        multiline
        fullWidth
        data-html2canvas-ignore
        className={classes.marginBottom30}
        value={formData?.assessmentForm?.summativeReference || ""}
        onChange={(e) =>
          onFormChange(e.target.value, ["assessmentForm", "summativeReference"])
        }
        disabled={isReadOnly}
      />
    </div>
  );
}

function TeacherReflection({ formData = {}, onFormChange, isReadOnly }) {
  const classes = useStyles();
  return (
    <div>
      <h3 className={classes.heading} align={"left"}>
        Teacher Reflection
      </h3>
      <Divider className={classes.divider} />
      <InputLabel className={classes.inputLabel}>
        <b>Teacher reflection</b>
      </InputLabel>
      <TextField
        id="multiline-static"
        multiline
        placeholder="Enter teacher reflections"
        fullWidth
        margin="normal"
        variant="outlined"
        data-html2canvas-ignore
        value={formData?.teacherReflection || ""}
        onChange={(e) => onFormChange(e.target.value, ["teacherReflection"])}
        disabled={isReadOnly}
      />
    </div>
  );
}

function Consents({ userRoles, planName, isReadOnly, formData, onFormChange }) {
  const classes = useStyles();

  return (
    <div>
      <h3 className={classes.heading} align={"left"}>
        Consents
      </h3>
      <Divider className={classes.divider} />
      <ConsentForm
        page={planName === "UNIT_PLAN" ? "unitPlan" : "lessonPlan"}
        userRoles={userRoles}
        consentData={formData.consents || []}
        setConsentData={(data) => onFormChange(data, ["consents"])}
        isCompleted={false}
        isReadOnly={isReadOnly}
      />
    </div>
  );
}

function LessonPlanSummary({
  prePrimaryLesson,
  isReadOnly,
  formData = {},
  onFormChange,
}) {
  const classes = useStyles();

  return (
    <div>
      <h3 className={classes.heading} align={"left"}>
        Periods
      </h3>
      <Divider className={classes.divider} />
      <InputLabel className={classes.inputLabel}>
        <b>Select Periods</b>
      </InputLabel>
      <TextField
        type="number"
        variant="outlined"
        id="multiline-static"
        value={R.pathOr("", ["periods"], formData)}
        fullWidth
        placeholder="Enter Periods"
        InputProps={{ inputProps: { min: 0 } }}
        className={classes.marginBottom42}
        onChange={(e) => onFormChange(e.target.value, ["periods"])}
        disabled={isReadOnly}
      />
      {prePrimaryLesson && (
        <>
          <h3 className={classes.heading} align={"left"}>
            Gaining Attention / Hook / Starter / Activating Prior Knowledge
          </h3>
          <Divider className={classes.divider} />
          <InputLabel className={classes.inputLabel}>
            <b>How do you gain your student's attention?</b>
          </InputLabel>
          <TextField
            variant="outlined"
            margin="normal"
            placeholder="How do you gain your student's attention?"
            id="multiline-static"
            className={classes.marginBottom42}
            multiline
            fullWidth
            data-html2canvas-ignore
            value={R.pathOr("", ["starterInput"], formData)}
            onChange={(e) => onFormChange(e.target.value, ["starterInput"])}
            disabled={isReadOnly}
          />
          <h3 className={classes.heading} align={"left"}>
            Teaching Learning Material
          </h3>
          <Divider className={classes.divider} />
          <TLMReferenceForm
            displayLOVSelect={true}
            TLMData={R.pathOr([], ["tlmList"], formData)}
            setTLMData={(data) => onFormChange(data, ["tlmList"])}
            planSummary={true}
            isReadOnly={isReadOnly}
          />
        </>
      )}
    </div>
  );
}

function LearningObjectiveForLesson({
  prePrimaryLesson,
  userRoles,
  planName,
  isReadOnly,
  unitData,
  formData = {},
  onFormChange,
}) {
  const classes = useStyles();

  return (
    <div>
      <h3 className={classes.heading} align={"left"}>
        Concept Related Objectives (Remembering & Recalling)
      </h3>
      <Divider className={classes.divider} />
      <InputLabel className={classes.inputLabel}>
        <b>
          Concept Related Objectives: (Knowledge and Comprehension, Remembering
          and Understanding)
        </b>
      </InputLabel>
      <TextField
        id="multiline-static"
        placeholder="Students will be able to"
        multiline
        variant="outlined"
        margin="normal"
        fullWidth
        data-html2canvas-ignore
        className={classes.marginBottom42}
        value={R.pathOr(
          "",
          ["learningObjectiveForm", "conceptObjective"],
          formData
        )}
        onChange={(e) =>
          onFormChange(e.target.value, [
            "learningObjectiveForm",
            "conceptObjective",
          ])
        }
        disabled={isReadOnly}
      />
      <h3 className={classes.heading} align={"left"}>
        Skill Related Objectives (Applying, Analysing, Evaluating, Creating)
      </h3>
      <Divider className={classes.divider} />
      <InputLabel className={classes.inputLabel}>
        <b>
          Skill Related Objectives: Apply, Analyse, Evaluate and Create
          (Applying, Analysing, Evaluating, Creating)
        </b>
      </InputLabel>
      <TextField
        id="multiline-static"
        variant="outlined"
        margin="normal"
        placeholder="Students will be able to"
        multiline
        value={R.pathOr(
          "",
          ["learningObjectiveForm", "skillObjective"],
          formData
        )}
        fullWidth
        data-html2canvas-ignore
        className={classes.marginBottom42}
        onChange={(e) =>
          onFormChange(e.target.value, [
            "learningObjectiveForm",
            "skillObjective",
          ])
        }
        disabled={isReadOnly}
      />
    </div>
  );
}

function IntroducingNewKnowledge({
  prePrimaryLesson,
  userRoles,
  planName,
  isReadOnly,
  unitData,
  formData = {},
  onFormChange,
}) {
  const classes = useStyles();

  return (
    <div>
      <h3 className={classes.heading} align={"left"}>
        Introducing New Knowledge
      </h3>
      <Divider className={classes.divider} />
      <InputLabel className={classes.inputLabel}>
        <b>How do you represent the material for maximum retention?</b>
      </InputLabel>
      <TextField
        variant="outlined"
        margin="normal"
        id="multiline-static"
        placeholder="Enter description"
        multiline
        fullWidth
        data-html2canvas-ignore
        onChange={(e) =>
          onFormChange(e.target.value, ["introNewKnowledgeInput"])
        }
        value={R.pathOr("", ["introNewKnowledgeInput"], formData)}
        disabled={isReadOnly}
      />
    </div>
  );
}

function DevelopingNewKnowledge({
  prePrimaryLesson,
  userRoles,
  planName,
  isReadOnly,
  unitData,
  formData = {},
  onFormChange,
}) {
  const classes = useStyles();

  return (
    <div>
      <h3 className={classes.heading} align={"left"}>
        Developing/Facilitating New Knowledge
      </h3>
      <Divider className={classes.divider} />
      <InputLabel className={classes.inputLabel}>
        <b>How do you help them make connections?</b>
      </InputLabel>
      <TextField
        variant="outlined"
        margin="normal"
        id="multiline-static"
        placeholder="Enter description"
        multiline
        fullWidth
        data-html2canvas-ignore
        onChange={(e) =>
          onFormChange(e.target.value, ["developNewKnowledgeInput"])
        }
        value={R.pathOr("", ["developNewKnowledgeInput"], formData)}
        disabled={isReadOnly}
      />
    </div>
  );
}

function AssessmentForLesson({
  prePrimaryLesson,
  userRoles,
  planName,
  isReadOnly,
  unitData,
  formData = {},
  onFormChange,
}) {
  const classes = useStyles();

  return (
    <div>
      <h3 className={classes.heading} align={"left"}>
        Student Practice / Feedback / Formative Assessment
      </h3>
      <Divider className={classes.divider} />
      <InputLabel className={classes.inputLabel}>
        <b>Student Practice / Feedback / Formative Assessment</b>
      </InputLabel>
      <TextField
        variant="outlined"
        margin="normal"
        id="multiline-static"
        placeholder="Enter description"
        multiline
        fullWidth
        data-html2canvas-ignore
        onChange={(e) =>
          onFormChange(e.target.value, ["studentPracticeAssesmentInput"])
        }
        value={R.pathOr("", ["studentPracticeAssesmentInput"], formData)}
        disabled={isReadOnly}
      />
    </div>
  );
}

function ClosureForLesson({
  prePrimaryLesson,
  userRoles,
  planName,
  isReadOnly,
  unitData,
  formData = {},
  onFormChange,
}) {
  const classes = useStyles();

  return (
    <div>
      <h3 className={classes.heading} align={"left"}>
        Closure (Retention of New Learning)
      </h3>
      <Divider className={classes.divider} />
      <InputLabel className={classes.inputLabel}>
        <b>Closure (Retention of New Learning)</b>
      </InputLabel>
      <TextField
        variant="outlined"
        margin="normal"
        id="multiline-static"
        placeholder="Enter description"
        multiline
        fullWidth
        data-html2canvas-ignore
        onChange={(e) => onFormChange(e.target.value, ["closureInput"])}
        value={R.pathOr("", ["closureInput"], formData)}
        disabled={isReadOnly}
      />
    </div>
  );
}

function ObjectiveForLesson({ formData = {}, onFormChange, isReadOnly }) {
  const classes = useStyles();

  return (
    <div>
      <h3 className={classes.heading} align={"left"}>
        Values Related Objectives
      </h3>
      <Divider className={classes.divider} />
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <InputLabel className={classes.inputLabel}>
            <b>Value of the month</b>
          </InputLabel>
          <TextField
            id="multiline-static"
            variant="outlined"
            margin="normal"
            placeholder="Students will be able to"
            multiline
            className={classes.marginBottom30}
            data-html2canvas-ignore
            fullWidth
            value={
              formData?.learningObjectiveForm?.valuesObjectives
                ?.symbioticLearning || ""
            }
            onChange={(e) =>
              onFormChange(e.target.value, [
                "learningObjectiveForm",
                "valuesObjectives",
                "symbioticLearning",
              ])
            }
            disabled={isReadOnly}
          />
        </Grid>
      </Grid>
    </div>
  );
}

function PossibleMisconceptions({
  prePrimaryLesson,
  userRoles,
  planName,
  isReadOnly,
  unitData,
  formData = {},
  onFormChange,
}) {
  const classes = useStyles();

  return (
    <div>
      <h3 className={classes.heading} align={"left"}>
        Possible Misconceptions
      </h3>
      <Divider className={classes.divider} />
      <InputLabel className={classes.inputLabel}>
        <b>Possible Misconceptions</b>
      </InputLabel>
      <TextField
        variant="outlined"
        margin="normal"
        id="multiline-static"
        placeholder="Enter description"
        multiline
        fullWidth
        data-html2canvas-ignore
        value={R.pathOr("", ["possibleMisconceptions"], formData)}
        onChange={(e) =>
          onFormChange(e.target.value, ["possibleMisconceptions"])
        }
        disabled={isReadOnly}
      />
    </div>
  );
}

function AssessmentRubric({
  prePrimaryLesson,
  userRoles,
  planName,
  isReadOnly,
  unitData,
  formData = {},
  onFormChange,
}) {
  const classes = useStyles();

  return (
    <div>
      <h3 className={classes.heading} align={"left"}>
        Assessment Rubric with 3-4 Learning Levels to Guide Learning
      </h3>
      <Divider className={classes.divider} />
      <InputLabel className={classes.inputLabel}>
        <b>Assessment Rubric with 3-4 Learning Levels to Guide Learning</b>
      </InputLabel>
      <TextField
        variant="outlined"
        margin="normal"
        id="multiline-static"
        placeholder="Enter description"
        multiline
        fullWidth
        data-html2canvas-ignore
        value={R.pathOr("", ["assessmentRubric"], formData)}
        onChange={(e) => onFormChange(e.target.value, ["assessmentRubric"])}
        disabled={isReadOnly}
      />
    </div>
  );
}

function GainingAttention({
  prePrimaryLesson,
  userRoles,
  planName,
  isReadOnly,
  unitData,
  formData = {},
  onFormChange,
}) {
  const classes = useStyles();

  return (
    <div>
      <h3 className={classes.heading} align={"left"}>
        Gaining Attention / Hook / Starter / Activating Prior Knowledge
      </h3>
      <Divider className={classes.divider} />
      <InputLabel className={classes.inputLabel}>
        <b>Gaining Attention / Hook / Starter / Activating Prior Knowledge</b>
      </InputLabel>
      <TextField
        variant="outlined"
        margin="normal"
        id="multiline-static"
        placeholder="Enter description"
        multiline
        fullWidth
        data-html2canvas-ignore
        value={R.pathOr("", ["starterInput"], formData)}
        onChange={(e) => onFormChange(e.target.value, ["starterInput"])}
        disabled={isReadOnly}
      />
    </div>
  );
}

function IntroductionActivities({ formData, isReadOnly, onFormChange }) {
  const classes = useStyles();
  const selectedValue =
    formData?.intoductionActivities?.map((item) => item?.name) || [];

  return (
    <div>
      <h3 className={classes.heading} align={"left"}>
        Introduction Activities
      </h3>
      <Divider className={classes.divider} />
      <InputLabel className={classes.inputLabel}>
        <b>Introduction Activities</b>
      </InputLabel>
      <LOVSelect
        variant="outlined"
        margin="normal"
        formType={FORM_TYPES.LESSON_PLAN_FORM}
        selectKey={LOVSelectKeys.INTRO_ACTIVITIES}
        dataKey={LOVDataKeys.INTRO_ACTIVITIES}
        lovKey={LOVKeyMap.INTRO_ACTIVITIES}
        value={selectedValue}
        multiselect={true}
        selectObj={true}
        isReadOnly={isReadOnly}
        getSelectList={getSelectList}
        showList={true}
        customOnChange={(value) =>
          onFormChange(value, ["intoductionActivities"])
        }
      />
    </div>
  );
}

function MainContentKnowledge({
  formData,
  onFormChange,
  unitData,
  isReadOnly,
}) {
  const classes = useStyles();

  return (
    <div>
      <h3 className={classes.heading} align={"left"}>
        Main Content / Core / Introducing New Knowledge
      </h3>
      <Divider className={classes.divider} />
      <TLMSequenceReferenceForm
        displayLOVSelect={true}
        TLMData={formData?.mainContents || []}
        setTLMData={(data) => {
          onFormChange(data, ["mainContents"]);
        }}
        isReadOnly={isReadOnly}
        isPlanSummary={true}
      />
    </div>
  );
}

function DifferentiatedTeachingLearning({
  formData,
  unitData,
  isReadOnly,
  onFormChange,
}) {
  const classes = useStyles();

  return (
    <div>
      <h3 className={classes.heading} align={"left"}>
        Differentiated Teaching & Learning Strategies
      </h3>
      <Divider className={classes.divider} />
      <InputLabel className={classes.inputLabel}>
        <b>Differentiated Teaching & Learning Strategies</b>
      </InputLabel>
      <TextField
        variant="outlined"
        margin="normal"
        id="multiline-static"
        placeholder="Enter description"
        multiline
        fullWidth
        data-html2canvas-ignore
        value={R.pathOr("", ["differentiatedTeachingStrategy"], formData)}
        onChange={(e) =>
          onFormChange(e.target.value, ["differentiatedTeachingStrategy"])
        }
        disabled={isReadOnly}
      />
    </div>
  );
}

function SummarizationPracticingReviewing({
  prePrimaryLesson,
  userRoles,
  planName,
  isReadOnly,
  unitData,
  formData = {},
  onFormChange,
}) {
  const classes = useStyles();

  return (
    <div>
      <h3 className={classes.heading} align={"left"}>
        Summarization / Consolidation / Check for Understanding / Opportunity
        for Applying, Practicing and Reviewing
      </h3>
      <Divider className={classes.divider} />
      <InputLabel className={classes.inputLabel}>
        <b>
          Summarization / Consolidation / Check for Understanding / Opportunity
          for Applying, Practicing and Reviewing
        </b>
      </InputLabel>
      <TextField
        variant="outlined"
        margin="normal"
        id="multiline-static"
        placeholder="Enter description"
        multiline
        fullWidth
        data-html2canvas-ignore
        value={R.pathOr("", ["summarization"], formData)}
        onChange={(e) => onFormChange(e.target.value, ["summarization"])}
        disabled={isReadOnly}
      />
    </div>
  );
}

function RealLifeConnect({
  prePrimaryLesson,
  userRoles,
  planName,
  isReadOnly,
  unitData,
  formData = {},
  onFormChange,
}) {
  const classes = useStyles();

  return (
    <div>
      <h3 className={classes.heading} align={"left"}>
        Real Life Connect / Cross Curricular Links / Subject Integration
      </h3>
      <Divider className={classes.divider} />
      <InputLabel className={classes.inputLabel}>
        <b>Real Life Connect / Cross Curricular Links / Subject Integration</b>
      </InputLabel>
      <TextField
        variant="outlined"
        margin="normal"
        id="multiline-static"
        placeholder="Enter description"
        multiline
        fullWidth
        data-html2canvas-ignore
        value={R.pathOr("", ["realLifeConnect"], formData)}
        onChange={(e) => onFormChange(e.target.value, ["realLifeConnect"])}
        disabled={isReadOnly}
      />
    </div>
  );
}

function AssessmentForMainLesson({
  prePrimaryLesson,
  userRoles,
  planName,
  isReadOnly,
  unitData,
  formData = {},
  onFormChange,
}) {
  const classes = useStyles();

  return (
    <div>
      <h3 className={classes.heading} align={"left"}>
        Assessment (AFL / Self / Peer)
      </h3>
      <Divider className={classes.divider} />
      <InputLabel className={classes.inputLabel}>
        <b>Assessment (AFL / Self / Peer)</b>
      </InputLabel>
      <TextField
        variant="outlined"
        margin="normal"
        id="multiline-static"
        placeholder="Enter description"
        multiline
        fullWidth
        className={classes.marginBottom30}
        data-html2canvas-ignore
        value={R.pathOr("", ["assessmentProjectTask"], formData)}
        onChange={(e) =>
          onFormChange(e.target.value, ["assessmentProjectTask"])
        }
        disabled={isReadOnly}
      />
    </div>
  );
}

function ProjectAssessmentForLesson({
  prePrimaryLesson,
  userRoles,
  planName,
  isReadOnly,
  unitData,
  formData = {},
  onFormChange,
}) {
  const classes = useStyles();

  return (
    <div>
      <h3 className={classes.heading} align={"left"}>
        Project / Home Assignment / Extension Task
      </h3>
      <Divider className={classes.divider} />
      <InputLabel className={classes.inputLabel}>
        <b>Project / Home Assignment / Extension Task</b>
      </InputLabel>
      <TextField
        variant="outlined"
        margin="normal"
        id="multiline-static"
        placeholder="Enter description"
        multiline
        fullWidth
        className={classes.marginBottom30}
        data-html2canvas-ignore
        value={R.pathOr("", ["assignmentProjectTask"], formData)}
        onChange={(e) =>
          onFormChange(e.target.value, ["assignmentProjectTask"])
        }
        disabled={isReadOnly}
      />
    </div>
  );
}

export {
  AssessmentForLesson,
  AssessmentForMainLesson,
  AssessmentRubric,
  Assessments,
  ClosureForLesson,
  Consents,
  DevelopingNewKnowledge,
  DifferentiatedTeachingLearning,
  GainingAttention,
  IntroducingNewKnowledge,
  IntroductionActivities,
  LearningObjective,
  LearningObjectiveForLesson,
  LearningOutcomes,
  LessonPlanSummary,
  LevelOfLearning,
  MainContentKnowledge,
  ObjectiveForLesson,
  PossibleMisconceptions,
  ProjectAssessmentForLesson,
  RealLifeConnect,
  StudentOwnLearning,
  SummarizationPracticingReviewing,
  TeacherReflection,
  UnitPlanSummary,
};
