import {
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
  makeStyles,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import { isSubmitEnabled } from "common/forms/utils";
import {
  getLessonPlanFormAPI,
  getPageList,
  getUnitPlanFormAPI,
  saveLessonPlanFormAPI,
  saveUnitPlanFormAPI,
} from "components/listOfValues/utils";
import * as R from "ramda";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { PAGES, setPageListItems } from "state/slices/pageSlice";
import * as PlanSummary from "./PlanSummary";
import {
  lessonPlanFormPrePrimarySteps,
  lessonPlanFormSteps,
  unitPlanFormSteps,
} from "./constant";

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    width: "100%",
  },
  titleWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  title: {
    display: "flex",
    alignItems: "center",
  },
  titleText: {
    fontWeight: 600,
    fontSize: "10px",
    color: "#263657",
    marginLeft: "5px",
  },
  leftArrow: {
    marginRight: "16px",
  },
  disabledArrow: {
    color: "#898EAC",
  },
  cardBody: {
    marginTop: "30px",
    border: "none",
    borderRadius: "14.4675px",
  },
  cardContent: {
    padding: "25px",
  },
  stepsTitle: {
    display: "flex",
    alignItems: "center",
    marginBottom: "14px",
    cursor: "pointer",
  },
  stepsCheckIcon: {
    width: "12px !important",
    height: "12px !important",
    marginRight: "10px",
    alignSelf: "start",
    marginTop: "2px",
  },
  stepsArrowIcon: {
    width: "16px !important",
    height: "16px !important",
    marginLeft: "5px",
    alignSelf: "start",
  },
  stepsTitleText: {
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "17px",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  subSteps: {
    margin: "14px 0 14px 35px",
    cursor: "pointer",
  },
  stepsSubTitleText: {
    fontWeight: 400,
    fontSize: "12px",
    color: "#797D89",
    display: "block",
    marginBottom: "8px",
    lineHeight: "14px",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  saveButton: {
    marginTop: "26px",
    marginRight: "16px",
  },
}));

const stepper = {
  UNIT_PLAN: unitPlanFormSteps,
  LESSON_PLAN: lessonPlanFormSteps,
  PRE_PRIMARY_LESSON_PLAN: lessonPlanFormPrePrimarySteps,
};

const AllowedRoles = {
  LESSON_PLAN: ["Coordinator", "Administrator", "Inspire Us-Core"],
  UNIT_PLAN: ["Administrator", "Inspire Us-Core"],
};

function PlanFormStepper({
  planName,
  userRoles,
  parentState,
  privileges,
  getPlanDetails,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down("sm"));

  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState({});
  const dispatch = useDispatch();

  const prePrimaryLesson = formData?.type === "PRECLASS";
  const formStepper = prePrimaryLesson
    ? stepper?.PRE_PRIMARY_LESSON_PLAN
    : stepper?.[planName];
  const selectedStep = formStepper?.[activeStep];
  const CardComponent = PlanSummary[selectedStep.componentKey];

  const isEditEnabled = (roles) => {
    let editEnabled = false;
    if (roles && roles.length > 0) {
      roles.forEach((role) => {
        if (R.includes(role, AllowedRoles[planName])) {
          editEnabled = true;
        }
      });
    }
    return editEnabled;
  };

  const isReadOnly =
    parentState?.status === "COMPLETED"
      ? !isEditEnabled(userRoles)
      : !privileges.UPDATE;

  useEffect(() => {
    getObservationsData();
  }, []);

  useEffect(() => {
    parentState?.id && getPlanFormData(parentState?.id);
  }, [parentState]);

  const getPlanFormData = async (id) => {
    const res =
      planName === "UNIT_PLAN"
        ? await getUnitPlanFormAPI(id)
        : await getLessonPlanFormAPI(id);
    setFormData(res);
  };

  const getObservationsData = async () => {
    const pageListData = await getPageList({
      searchKey: "",
      page: PAGES.observations,
    });
    dispatch(
      setPageListItems({
        page: PAGES.observations,
        itemList: pageListData.content,
        totalElements: pageListData.totalElements,
        totalPages: pageListData.totalPages,
      })
    );
  };

  const onFormChange = (value, path) => {
    setFormData((prev) => {
      const data = R.assocPath(path, value, prev);
      return data;
    });
  };

  const handleSaveSubmit = async (
    goToNextStep = true,
    status = "ACTIVE",
    isSubmit = false
  ) => {
    let payload = {
      ...formData,
      status,
    };
    if (planName === "UNIT_PLAN") {
      const updatedFormativeAssessment = R.map((item) => {
        return {
          name: R.propOr(R.propOr("", "name", item), "value", item),
        };
      })(R.pathOr([], ["assessmentForm", "formativeAssessments"], formData));

      payload = R.assocPath(
        ["assessmentForm", "formativeAssessments"],
        updatedFormativeAssessment,
        payload
      );
    } else if (!prePrimaryLesson && planName === "LESSON_PLAN") {
      payload = {
        ...payload,
        intoductionActivities: R.map((item) => ({
          name: R.propOr(item.name, "value", item),
        }))(R.propOr([], "intoductionActivities", formData)),
      };
    }

    const res =
      planName === "UNIT_PLAN"
        ? await saveUnitPlanFormAPI(payload, parentState?.id)
        : await saveLessonPlanFormAPI(payload, parentState?.id);
    if (res) {
      setFormData(res);
      goToNextStep && window.scrollTo(0, 0);
      goToNextStep && handleNext();
      isSubmit && getPlanDetails?.();
    }
  };

  const handleNext = () => {
    setActiveStep((prev) => (prev === formStepper.length - 1 ? prev : ++prev));
  };

  const handlePrev = () => {
    setActiveStep((prev) => (prev > 0 ? --prev : 0));
  };

  const handleStepClick = (index) => {
    window.scrollTo(0, 0);
    setActiveStep(index);
  };

  return (
    <>
      <Grid
        container
        spacing={isSmallDevice ? 0 : 3}
        direction="row"
        className={classes.gridContainer}
      >
        <Grid item md="9" xs="12">
          <Typography variant="div" className={classes.titleWrapper}>
            <div className={classes.title}>
              <CheckCircleIcon />
              <Typography variant="p" className={classes.titleText}>
                {selectedStep?.name?.toUpperCase()}
              </Typography>
            </div>
            <div className={classes.title}>
              <ArrowCircleLeftOutlinedIcon
                className={[
                  classes.leftArrow,
                  activeStep === 0 ? classes.disabledArrow : {},
                ]}
                style={activeStep === 0 ? {} : { cursor: "pointer" }}
                onClick={handlePrev}
              />
              <ArrowCircleRightOutlinedIcon
                className={
                  activeStep === formStepper.length - 1
                    ? classes.disabledArrow
                    : {}
                }
                style={
                  activeStep === formStepper.length - 1
                    ? {}
                    : { cursor: "pointer" }
                }
                onClick={handleNext}
              />
            </div>
          </Typography>
          <Card variant="outlined" className={classes.cardBody}>
            <CardContent className={classes.cardContent}>
              <Typography>
                {CardComponent && (
                  <CardComponent
                    userRoles={userRoles}
                    planName={planName}
                    formData={formData}
                    onFormChange={onFormChange}
                    unitData={parentState}
                    prePrimaryLesson={prePrimaryLesson}
                    isReadOnly={isReadOnly}
                  />
                )}
              </Typography>
              {activeStep === formStepper.length - 1 ? (
                <>
                  <Button
                    variant="outlined"
                    color="primary"
                    className={classes.saveButton}
                    onClick={() => handleSaveSubmit()}
                    disabled={isReadOnly}
                  >
                    Save
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.saveButton}
                    onClick={() => handleSaveSubmit(false, "COMPLETED", true)}
                    disabled={
                      !isSubmitEnabled(formData?.consents || []) || isReadOnly
                    }
                  >
                    Submit
                  </Button>
                  <Button
                    variant="text"
                    color="primary"
                    className={classes.saveButton}
                    onClick={() => handleSaveSubmit(false, "ACTIVE", true)}
                    disabled={!isEditEnabled(userRoles)}
                  >
                    Resume
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    variant="outlined"
                    color="primary"
                    className={classes.saveButton}
                    onClick={() => handleSaveSubmit(false)}
                    disabled={isReadOnly}
                  >
                    Save Draft
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.saveButton}
                    onClick={() => handleSaveSubmit()}
                    disabled={isReadOnly}
                  >
                    Save & Next
                  </Button>
                </>
              )}
            </CardContent>
          </Card>
        </Grid>
        {!isSmallDevice && (
          <Grid item md="3">
            {formStepper.map((steps, index) => {
              return (
                <>
                  <div
                    className={classes.stepsTitle}
                    onClick={() => handleStepClick(index)}
                  >
                    <CheckCircleIcon className={classes.stepsCheckIcon} />
                    <Typography variant="p" className={classes.stepsTitleText}>
                      {steps.name}
                    </Typography>
                    {steps?.subTitles?.length && (
                      <ExpandMoreOutlinedIcon
                        className={classes.stepsArrowIcon}
                      />
                    )}
                  </div>
                  {steps?.subTitles?.length ? (
                    <div
                      className={classes.subSteps}
                      onClick={() => handleStepClick(index)}
                    >
                      {steps?.subTitles.map((subTitle) => (
                        <Typography
                          variant="p"
                          className={classes.stepsSubTitleText}
                        >
                          {subTitle.name}
                        </Typography>
                      ))}
                    </div>
                  ) : (
                    ""
                  )}
                </>
              );
            })}
          </Grid>
        )}
      </Grid>
    </>
  );
}

export default PlanFormStepper;
