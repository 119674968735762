import React, { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  Grid,
  makeStyles,
  TextField,
  IconButton,
  InputLabel,
  Button,
} from "@material-ui/core";
import * as R from "ramda";
import {
  AddCircleOutline,
} from "@material-ui/icons";
import DeleteIcon from "@mui/icons-material/Delete";
import { TierTopicFormInitialState } from "state/globalForms/initialStates";
import DisplaySubTopics from "./DisplaySubTopics";

const useStyles = makeStyles((theme) => ({
  inputFormControl: {
    marginBottom: "1rem",
  },
  addTierButton: {
    width: "30px",
    height: "30px",
    color: "#898EAC",
  },
  addIconContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: "20px",
  },
  tierTitle: {
    display: "flex",
    alignItems: "center",
  },
  tierCard: {
    marginBottom: "1em",
  },
  inputLabel: {
    top: "-17px",
  },
}));

const subTopicInitialState = {
  sortOrder: "",
  name: "",
};
const TierTopicForm = ({
  tierIndex,
  topicIndex,
  onTopicChange,
  formType,
  isReadOnly,
  data = null,
  onRemoveTopic,
  state,
  setState,
}) => {
  const annualPlanForm = state;
  const [topicPlanForm, setTopicPlanForm] = useState(
    data
      ? data
      : R.pathOr(
          TierTopicFormInitialState,
          ["tierPlans", tierIndex, "topicPlans", topicIndex],
          annualPlanForm
        )
  );
  const [subTopic, setSubTopic] = useState(subTopicInitialState);
  const classes = useStyles();

  useEffect(() => {
    setTopicPlanForm(data
      ? data
      : R.pathOr(
          TierTopicFormInitialState,
          ["tierPlans", tierIndex, "topicPlans", topicIndex],
          annualPlanForm
        ))
  }, [data]);

  const onDataChange = (event) => {
    const [value, name] = R.paths(
      [
        ["target", "value"],
        ["target", "name"],
      ],
      event
    );
    let newValue = value;
    if (name === "sortOrder") {
      newValue = value.replace(/[^0-9]/g, "");
    }
    let updatedData = R.assocPath([name], newValue, topicPlanForm);
    setTopicPlanForm(updatedData);
    onTopicChange(tierIndex, topicIndex, updatedData);
  };

  const addSubTopic = () => {
    if (subTopic) {
      const subTopics = R.pathOr([], ["subTopics"], topicPlanForm);
      const updatedSubTopics = R.append(subTopic, subTopics);
      const updatedTopicPlanForm = R.assocPath(
        ["subTopics"],
        updatedSubTopics,
        topicPlanForm
      );
      setTopicPlanForm(updatedTopicPlanForm);
      const updatedFormData = R.assocPath(
        ["topics", topicIndex],
        updatedTopicPlanForm,
        annualPlanForm
      );
      setState(updatedFormData);
      setSubTopic(subTopicInitialState);
      onTopicChange(tierIndex, topicIndex, updatedTopicPlanForm);
    }
  };

  const onSubTopicChange = (e) => {
    const updatedSubTopic = R.assocPath(
      [e.target.name],
      e.target.name === "name"
        ? e.target.value
        : e.target.value.replace(/[^0-9]/g, "")
    );
    setSubTopic(updatedSubTopic);
  };

  const onSubTopicDelete = (index) => {
    const subTopics = R.pathOr([], ["subTopics"], topicPlanForm);
    const updatedTopicPlanForm = R.assocPath(
      ["subTopics"],
      R.remove(index, 1, subTopics),
      topicPlanForm
    );
    setTopicPlanForm(updatedTopicPlanForm);
    const updatedFormData = R.assocPath(
      ["topics", topicIndex],
      updatedTopicPlanForm,
      annualPlanForm
    );
    setState(updatedFormData);
    onTopicChange(tierIndex, topicIndex, updatedTopicPlanForm);
  };

  return (
    <Box
      sx={{
        border: `1px solid #cccccc`,
        padding: "1rem",
        marginBottom: "34px",
        borderRadius: 4,
      }}
    >
      <form>
        <Box style={{ textAlign: "end" }}>
          <Button
            disabled={isReadOnly}
            onClick={onRemoveTopic}
            style={{ margin: "0px" }}
          >
            <DeleteIcon fontSize="small" style={{ marginRight: "10px" }} />{" "}
            Delete Topic
          </Button>
        </Box>
        <Grid container spacing={1} style={{ marginTop: "1rem" }}>
          <Grid item xs={3}>
            <FormControl className={classes.inputFormControl}>
              <InputLabel
                className={classes.inputLabel}
                id="demo-simple-select-label"
              >
                Sequence
              </InputLabel>
              <TextField
                margin="normal"
                disabled={isReadOnly}
                variant="outlined"
                name="sortOrder"
                placeholder="Enter sequence"
                value={topicPlanForm.sortOrder}
                onChange={(e) => onDataChange(e)}
              />
            </FormControl>
          </Grid>
          <Grid item xs={9}>
            <FormControl className={classes.inputFormControl}>
              <InputLabel
                className={classes.inputLabel}
                id="demo-simple-select-label"
              >
                Topic Name
              </InputLabel>
              <TextField
                margin="normal"
                disabled={isReadOnly}
                variant="outlined"
                name="name"
                placeholder="Enter topic name"
                value={topicPlanForm.name}
                onChange={onDataChange}
              />
            </FormControl>
          </Grid>
        </Grid>
      </form>
      <Box>
        {!isReadOnly && (
          <Grid>
            <Grid container spacing={1}>
              <Grid item xs={3}>
                <FormControl className={classes.inputFormControl}>
                  <InputLabel
                    className={classes.inputLabel}
                    id="demo-simple-select-label"
                  >
                    Sequence
                  </InputLabel>
                  <TextField
                    margin="normal"
                    disabled={isReadOnly}
                    variant="outlined"
                    name="sortOrder"
                    placeholder="Enter sequence"
                    value={subTopic.sortOrder}
                    onChange={(e) => onSubTopicChange(e)}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={7} md={8}>
                <FormControl className={classes.inputFormControl}>
                  <InputLabel
                    className={classes.inputLabel}
                    id="demo-simple-select-label"
                  >
                    Sub Topic Name
                  </InputLabel>
                  <TextField
                    margin="normal"
                    disabled={isReadOnly}
                    variant="outlined"
                    name="name"
                    placeholder="Enter sub topic name"
                    value={subTopic.name}
                    onChange={(e) => onSubTopicChange(e)}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={2} md={1} className={classes.addIconContainer}>
                <IconButton
                  disabled={isReadOnly}
                  color="primary"
                  onClick={() => addSubTopic(tierIndex, topicIndex)}
                >
                  <AddCircleOutline className={classes.addTierButton} />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        )}
        {
          <DisplaySubTopics
            isWhiteIcon={false}
            subTopics={topicPlanForm.subTopics}
            onDelete={onSubTopicDelete}
            disabled={isReadOnly}
          />
        }
      </Box>
    </Box>
  );
};

export default TierTopicForm;
