import { LWS_OG_LOGO } from "asset/images";
import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import "./AppHeader.css";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import {
  Dialog,
  makeStyles,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Menu,
  MenuItem,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { onLogout } from "state/slices/userLoginFormSlice";
import { Close } from "@material-ui/icons";
import ChangePassword from "common/components/ChangePassword/ChangePassword";
import * as R from "ramda";

const useStyles = makeStyles((theme) => ({
  modalCloseBtn: {
    alignSelf: "center",
    textAlign: "center",
  },
}));

const AppHeader = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openChangePasswordModal, setChangePasswordModal] = useState(false);
  const userProfile = useSelector((state) => state.userProfile);
  const dispatch = useDispatch();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onLogoutClick = () => {
    dispatch(onLogout());
  };

  const onPasswordChangeSuccess = () => {
    setChangePasswordModal(false);
  };

  const onPasswordChangeClick = () => {
    history.push("/change-password");
    handleClose();
  };

  return (
    <header className="app-header">
      <div className="app-branding">
        <Link to="/" className="app-title">
          <img className="lwsLogo" src={LWS_OG_LOGO} alt="Lodha World School" />
          <span className="header-Title">Lodha World School</span>
        </Link>
      </div>
      <div className="app-options">
        <nav className="app-nav">
          {localStorage.getItem("isLoggedIn") === "true" && (
            <ul>
              {/* <li>
                                        <NavLink to="/profile">Dashboard</NavLink>
                                    </li> */}
              <li>
                <IconButton
                  aria-label="nav options"
                  component="span"
                  onClick={handleClick}
                  style={{ color: "#fff" }}
                >
                  <AccountCircleIcon />
                </IconButton>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem disabled={true}>
                    {userProfile?.userProfile?.fullName}
                  </MenuItem>
                  <MenuItem onClick={onPasswordChangeClick}>
                    Change Password
                  </MenuItem>
                  <MenuItem onClick={onLogoutClick}>Logout</MenuItem>
                </Menu>
              </li>
            </ul>
          )}
        </nav>
        <Dialog
          maxWidth="sm"
          fullWidth
          open={openChangePasswordModal}
          onClose={() => setChangePasswordModal(false)}
          aria-labelledby="form-dialog-title"
        >
          <Grid container>
            <Grid item xs={10}>
              <DialogTitle id="form-dialog-title">Change Password</DialogTitle>
            </Grid>
            <Grid item xs={2} className={classes.modalCloseBtn}>
              <IconButton onClick={() => setChangePasswordModal(false)}>
                <Close />
              </IconButton>
            </Grid>
          </Grid>
          <DialogContent>
            <ChangePassword
              email={R.pathOr("", ["userProfile", "email"], userProfile)}
              onPasswordChangeSuccess={onPasswordChangeSuccess}
            />
          </DialogContent>
        </Dialog>
      </div>
    </header>
  );
};

export default AppHeader;
