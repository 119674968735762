import { Grid, makeStyles } from "@material-ui/core";
import { getPageList } from "components/listOfValues/utils";
import React, { useEffect, useState } from "react";
import { PAGES } from "state/slices/pageSlice";
import AnnualPlanCard from "./AnnualPlanCard";
import { Pagination } from "@material-ui/lab";
import AnnualPlanModal from "./AnnualPlanModal";
import withPrivilege from "common/hocs/withPrivilege";
import { EntityMap } from "util/roleUtils";
import AcademicPlanHeader from "../AcademicPlanHeader";
import { CircularProgress } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  cardWrapper: {
    flexWrap: "wrap",
  },
  annualPlanCard: {
    marginBottom: "30px",
  },
  paginationContainer: {
    display: "flex",
    justifyContent: "end",
  },
  noDataAvailable: {
    margin: "20px",
  },
}));

function AnnualPlans({
  privileges,
  userRoles,
  setModalState,
  modalState,
  filterState,
  planName,
}) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState({
    totalPage: 0,
    pageSize: 10,
    data: [],
    currentPage: 0,
  });

  useEffect(() => {
    if (state?.currentPage !== 0) {
      setState((prev) => ({
        ...prev,
        currentPage: 0,
      }));
    }
    getAnnualPlanList({ pageSize: state.pageSize, currentPage: 0 });
  }, [filterState]);

  const getAnnualPlanList = async (pageData) => {
    const { pageSize, currentPage } = pageData || {
      pageSize: state.pageSize,
      currentPage: state.currentPage,
    };
    const pageFilter = { ...filterState };
    if (pageFilter && !pageFilter?.annualPeriod)
      pageFilter.annualPeriod = "ALL";
    setIsLoading(true);
    const pageListData = await getPageList({
      searchKey: "",
      page: PAGES.annualPlan,
      pageData: { pageSize, currentPage },
      pageFilter: pageFilter || {},
    });
    setIsLoading(false);
    setState((prev) => ({
      ...prev,
      data: pageListData?.content || [],
      totalPage: pageListData?.totalPages || 0,
    }));
  };

  const onPageChange = (_, page) => {
    setState((prev) => ({
      ...prev,
      currentPage: page - 1,
    }));
    getAnnualPlanList({ pageSize: state.pageSize, currentPage: page - 1 });
  };

  return (
    <>
      <AcademicPlanHeader
        changeView={planName}
        privileges={privileges}
        setModalState={setModalState}
      />
      <Grid
        container
        direction="row"
        className={classes.cardWrapper}
        spacing={4}
      >
        {isLoading ? (
          <CircularProgress />
        ) : state?.data?.length ? (
          state?.data?.map((plan, index) => (
            <Grid
              item
              className={classes.annualPlanCard}
              key={index}
              md="6"
              xs="12"
            >
              <AnnualPlanCard
                data={plan}
                privileges={privileges}
                userRoles={userRoles}
                getAnnualPlanList={getAnnualPlanList}
                setModalState={setModalState}
              />
            </Grid>
          ))
        ) : (
          <div className={classes.noDataAvailable}>No Data available</div>
        )}
      </Grid>
      <Grid item xs={12} className={classes.paginationContainer}>
        <Pagination
          count={state?.totalPage || 0}
          color="primary"
          onChange={onPageChange}
          page={(state?.currentPage || 0) + 1}
        />
      </Grid>
      {modalState.isOpen && (
        <AnnualPlanModal
          userRoles={userRoles}
          modalState={modalState}
          setModalState={setModalState}
          getAnnualPlanList={getAnnualPlanList}
        />
      )}
    </>
  );
}

export default withPrivilege(AnnualPlans, EntityMap.annualplan);
