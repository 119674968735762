import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import * as R from "ramda";

export const getDownloadFileName = ({ data, templateId }) => {
  const schoolCode = R.pathOr(
    "LWS",
    ["curriculumContext", "school", "schoolCode"],
    data
  );
  const className = R.pathOr(
    "",
    ["curriculumContext", "gradeName"],
    data
  ).replace(/\s/g, "");
  return `${schoolCode}_${className}_${templateId}`;
};

export const downloadAsPdf = ({
  templateId,
  hiddenElementids = [],
  fileName = "file",
}) => {
  const htmlElem = document.getElementById(templateId);
  const HTML_Height = htmlElem.offsetHeight;
  const HTML_Width = htmlElem.offsetWidth;
  const top_left_margin = 15;
  const PDF_Width = HTML_Width + top_left_margin * 2;
  const PDF_Height = PDF_Width * 1.5 + top_left_margin * 2;
  const canvas_image_width = HTML_Width;
  const canvas_image_height = HTML_Height;
  const totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;
  R.map((divId) => (document.getElementById(divId).style.display = "block"))(
    hiddenElementids
  );
  html2canvas(document.getElementById(templateId), { allowTaint: true }).then(
    function (canvas) {
      canvas.getContext("2d");
      R.map((divId) => (document.getElementById(divId).style.display = "none"))(
        hiddenElementids
      );
      const imgData = canvas.toDataURL("image/jpeg", 1.0);
      const pdf = new jsPDF("p", "pt", [PDF_Width, PDF_Height]);
      pdf.addImage(
        imgData,
        "JPG",
        top_left_margin,
        top_left_margin,
        canvas_image_width,
        canvas_image_height
      );

      for (let i = 1; i <= totalPDFPages; i++) {
        pdf.addPage("p", "pt", [PDF_Width, PDF_Height]);
        pdf.addImage(
          imgData,
          "JPG",
          top_left_margin,
          -(PDF_Height * i) + top_left_margin * 4,
          canvas_image_width,
          canvas_image_height
        );
      }

      pdf.save(`${fileName}.pdf`);
    }
  );
};

export const exportPDF = (printRef, file) => {
  if (printRef.current) {
    printRef.current.save();
  }
};
