import ANNUAL_PLAN_LIST from './AnnualPlan/AnnualPlans';
import LESSON_PLAN_LIST from './LessonPlan/LessonPlans';
import TIER_PLAN_LIST from './TierPlan/TierPlans';
import UNIT_PLAN_LIST from './UnitPlan/UnitPlans';
import ANNUAL_PLAN_DETAILS from './AnnualPlan/PlanDetailsPage';
import LESSON_PLAN_DETAILS from './LessonPlan/PlanDetailsPage';
import TIER_PLAN_DETAILS from './TierPlan/PlanDetailsPage';
import UNIT_PLAN_DETAILS from './UnitPlan/PlanDetailsPage';
import ANNUAL_PLAN_DETAIL_CARD from './AnnualPlan/AnnualPlanDetailCard';
import ANNUAL_PLAN_HEADER from './AnnualPlan/AnnualPlanHeader'
import TIER_PLAN_HEADER from './TierPlan/TierPlanHeader'
import FILTER_SIDEBAR from "./FilterSidebar";

export const planListComponents = {
    ANNUAL_PLAN: ANNUAL_PLAN_LIST,
    TIER_PLAN: TIER_PLAN_LIST,
    UNIT_PLAN: UNIT_PLAN_LIST,
    LESSON_PLAN: LESSON_PLAN_LIST,
    ANNUAL_PLAN_DETAILS: ANNUAL_PLAN_DETAILS,
    TIER_PLAN_DETAILS: TIER_PLAN_DETAILS,
    UNIT_PLAN_DETAILS: UNIT_PLAN_DETAILS,
    LESSON_PLAN_DETAILS: LESSON_PLAN_DETAILS,
};

export const sideBarComponent = {
    ANNUAL_PLAN: FILTER_SIDEBAR,
    TIER_PLAN: FILTER_SIDEBAR,
    UNIT_PLAN: FILTER_SIDEBAR,
    LESSON_PLAN: FILTER_SIDEBAR,
    ANNUAL_PLAN_DETAILS: ANNUAL_PLAN_DETAIL_CARD,
    TIER_PLAN_DETAILS: ANNUAL_PLAN_DETAIL_CARD,
    UNIT_PLAN_DETAILS: ANNUAL_PLAN_DETAIL_CARD,
    LESSON_PLAN_DETAILS: ANNUAL_PLAN_DETAIL_CARD,
};

export const headerComponent = {
    ANNUAL_PLAN_DETAILS: ANNUAL_PLAN_HEADER,
    TIER_PLAN_DETAILS: TIER_PLAN_HEADER,
    UNIT_PLAN_DETAILS: ANNUAL_PLAN_HEADER,
    LESSON_PLAN_DETAILS: ANNUAL_PLAN_HEADER,
};