import {
  Box,
  Card,
  CardContent,
  Typography,
  makeStyles,
} from "@material-ui/core";
import EditIcon from "@mui/icons-material/Edit";
import SyncIcon from "@mui/icons-material/Sync";
import { CHALKBOARD_ICON, FILE_ICON, STICKY_NOTE_ICON } from "asset/images";
import { syncSchoolDataWithValai } from "components/listOfValues/utils";
import * as R from "ramda";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import MoreVerticalDot from "../../../academicPlanDetails/MoreVerticalDot";

const useStyles = makeStyles((theme) => ({
  cardBody: {
    width: "100%",
    background: "#FFFFFF",
    borderRadius: "14px !important",
  },
  cardContent: {
    padding: "26px 18px !important",
  },
  cardTitle: {
    fontStyle: "normal",
    fontWeight: "800",
    fontSize: "18px",
    lineHeight: "22px",
    color: "#263657",
    display: "flex",
    alignItems: "center",
  },
  fileIcon: {
    marginRight: "10px",
  },
  cardTitleWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  cardBodyContent: {
    marginTop: "18px",
  },
  bodyText: {
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "12px",
    lineHeight: "14px",
    color: "#000000",
  },
  editIcon: {
    marginRight: "10px",
  },
}));

const SchoolViewCard = ({
  data,
  userRoles = [],
  privileges = {},
  setModalState,
  getSchoolList,
}) => {
  const history = useHistory();
  const classes = useStyles();
  const [syncInProgress, setSyncInProgress] = useState(false);

  const handleRedirect = (id) => {
    if (R.propOr(true, "READ", privileges)) {
      history.push(`/schools/${id}`);
    } else return null;
  };

  return (
    <>
      <Box
        sx={{ minWidth: 275 }}
        onClick={() => handleRedirect(data?.id)}
        style={{ cursor: "pointer" }}
      >
        <Card variant="outlined" className={classes.cardBody}>
          <CardContent className={classes.cardContent}>
            <div className={classes.cardTitleWrapper}>
              <Typography className={classes.cardTitle} variant="div">
                <img
                  className={classes.fileIcon}
                  src={FILE_ICON}
                  alt="File Icon"
                />
                <span>
                  {data.schoolName}-{data.campus}
                </span>
              </Typography>
              {privileges?.school?.UPDATE && (
                <div>
                  <MoreVerticalDot
                    options={[
                      {
                        icon: (
                          <EditIcon
                            fontSize="small"
                            className={classes.editIcon}
                          />
                        ),
                        label: "Manage School",
                        value: "Manage School",
                      },
                      {
                        icon: (
                          <SyncIcon
                            fontSize="small"
                            className={classes.editIcon}
                          />
                        ),
                        label: "Sync With Valai",
                        value: "Sync With Valai",
                        isDisabled: syncInProgress,
                      },
                    ]}
                    handleClick={async (e, value) => {
                      e.stopPropagation();
                      if (value === "Sync With Valai") {
                        if (data?.schoolCode) {
                          setSyncInProgress(true);
                          await syncSchoolDataWithValai({
                            schoolCode: data.schoolCode,
                          });
                          setSyncInProgress(false);
                          getSchoolList?.();
                        }
                      } else {
                        setModalState((prev) => ({
                          ...prev,
                          isOpen: true,
                          isEdit: true,
                          data,
                        }));
                      }
                    }}
                  />
                </div>
              )}
            </div>

            <div className={classes.cardBodyContent}>
              <img
                className={classes.fileIcon}
                src={CHALKBOARD_ICON}
                alt="Chalkboard Icon"
              />
              <Typography className={classes.bodyText} variant="span">
                Affiliated Board: {R.propOr("", "affiliatedBoard", data)}
              </Typography>
            </div>
            <div>
              <img
                className={classes.fileIcon}
                src={STICKY_NOTE_ICON}
                alt="Sticky Note Icon"
              />
              <Typography className={classes.bodyText} variant="span">
                School Code: {R.propOr("", "schoolCode", data)}
              </Typography>
            </div>
          </CardContent>
        </Card>
      </Box>
    </>
  );
};

export default SchoolViewCard;
