import { createSlice } from "@reduxjs/toolkit";
import * as R from "ramda";
import { logout } from "util/APIUtils";
import Alert from "react-s-alert";

export const userLoginFormSlice = createSlice({
  name: "userProfile",
  initialState: localStorage.getItem("isLoggedIn")
    ? {
        isLoggedIn: localStorage.getItem("isLoggedIn") === "true",
        authToken: localStorage.getItem("authToken"),
        userProfile: JSON.parse(localStorage.getItem("userProfile")),
        passwordChangeRequired: false,
        currentUser: null,
        unauthorised: false,
      }
    : {
        isLoggedIn: true,
        authToken: null,
        userProfile: {},
        passwordChangeRequired: false,
        currentUser: null,
        unauthorised: false,
      },
  reducers: {
    onLoginSuccess: (state, action) => {
      const { payload } = action;
      payload.accessToken?.length && localStorage.setItem("isLoggedIn", true);
      localStorage.setItem("authToken", R.prop("accessToken", payload));
    },
    setUserProfile: (state, action) => {
      const { payload } = action;
      localStorage.setItem("userProfile", JSON.stringify(payload.userProfile));
      state.userProfile = payload.userProfile;
    },
    setCurrentUserContext: (state, action) => {
      const { payload } = action;
      state.currentUser = payload;
    },
    onUnauthorisedAccess: (state, action) => {
      const { payload } = action;
      state.unauthorised = payload;
    },
    onChangePasswordSuccess: (state, action) => {
      const { payload } = action;
      payload.accessToken?.length && localStorage.setItem("isLoggedIn", true);
      localStorage.setItem("authToken", R.prop("accessToken", payload));
    },
    setPasswordChangeRequired: (state, action) => {
      const { payload } = action;
      state.passwordChangeRequired = payload;
    },
    onLogout: (state, action) => {
      logout()
        .then(() => {
          Alert.success("You've been successfully logged out.");
          localStorage.setItem("isLoggedIn", false);
          localStorage.setItem("authToken", null);
          localStorage.setItem("userProfile", null);
          state = {
            isLoggedIn: false,
            authToken: null,
            currentUser: null,
            userProfile: {},
            unauthorised: false,
          };
          const w = window.open("", "_self");
          w.location.href = "./";
        })
        .catch((error) => {
          Alert.error(
            (error && error.message) ||
              "Oops! Something went wrong. Please try again!"
          );
        });
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  onLoginSuccess,
  onLogout,
  setUserProfile,
  setPasswordChangeRequired,
  onChangePasswordSuccess,
  setCurrentUserContext,
  onUnauthorisedAccess,
} = userLoginFormSlice.actions;

export default userLoginFormSlice.reducer;
