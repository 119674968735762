import {
  Card,
  CardContent,
  Grid,
  IconButton,
  makeStyles,
  Typography,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import React from "react";
import { colors } from "theme/colors";
import * as R from "ramda";
import withPrivilege from "common/hocs/withPrivilege";
import { EntityMap } from "util/roleUtils";

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: "0.75rem",
    overflowWrap: "break-word",
  },
  root: {
    minHeight: "80px",
    maxHeight: "300px",
    overflow: "auto",
    border: `1px solid #d6d6d6`,
    background: "#f0f0f0",
    borderRadius: 6,
    boxShadow: "none",
    "&:hover, &:focus": {
      boxShadow: "4px 4px #e5e9ff",
      border: `1px solid ${colors.blue1}`,
      background: "#f4f6ff",
    },
  },
  editIconContainer: {
    display: "flex",
    alignItems: "baseline",
    justifyContent: "flex-end",
  },
  editIconBtn: {
    padding: "5px",
  },
  editIcon: {
    height: "1.2rem",
    width: "1.2rem",
    fontSize: "1.5rem",
    color: "rgba(0, 0, 0, 0.60)",
  },
  label: {
    color: colors.gray4,
  },
  activeLabel: {
    color: colors.green,
  },
  deactivateLabel: {
    color: colors.red,
  },
}));

const RolesView = ({ data, onEditClick, privileges }) => {
  const classes = useStyles();
  const onCardEditClick = () => {
    onEditClick(data);
  };
  return (
    <Grid item xs={12} md={4}>
      <div style={{ marginBottom: "1rem" }}>
        <Card className={classes.root}>
          <CardContent>
            <Grid container>
              <Grid item xs={10} className={classes.title}>
                <Typography variant="h6" color="primary">
                  {R.pathOr("", ["name"], data)}
                </Typography>
              </Grid>

              <Grid item xs={2} className={classes.editIconContainer}>
                {R.propOr(true, "UPDATE", privileges) && (
                  <IconButton
                    className={classes.editIconBtn}
                    onClick={onCardEditClick}
                  >
                    <EditIcon className={classes.editIcon} />
                  </IconButton>
                )}
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <Typography
                  className={
                    data.status === "ACTIVE"
                      ? classes.activeLabel
                      : classes.deactivateLabel
                  }
                >
                  <b>Status: {data.status}</b>
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </div>
    </Grid>
  );
};

export default withPrivilege(RolesView, EntityMap.role);
