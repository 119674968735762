import React, { useState } from "react";
import { Close } from "@material-ui/icons";
import {
  Box,
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  makeStyles,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import { colors } from "theme/colors";

const useStyles = makeStyles((theme) => ({
  cardWrapper: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  annualPlanCard: {
    marginBottom: "30px",
  },
  paginationContainer: {
    display: "flex",
    justifyContent: "end",
  },
  heading: {
    fontWeight: 800,
    fontSize: "18px",
    color: "#263657",
  },
  divider: {
    margin: "0 24px 24px 24px",
  },
  tableCell: {
    color: "#fff",
    padding: "6px 16px",
  },
  label: {
    color: colors.gray4,
  },
  activeLabel: {
    color: colors.green,
  },
  deactivateLabel: {
    color: colors.red,
  },
  table: {
    padding: "0 16px",
  },
}));

function GradeSubjectModal(props) {
  const { setModalState, data } = props;
  const classes = useStyles();
  const theme = useTheme();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down("sm"));
  const [teacherState, setTeacherState] = useState([]);

  const handleClose = () => {
    setModalState((prev) => ({
      isGradeModalOpen: false,
      data: null,
      isEdit: false,
    }));
  };

  // const getSubjectTeacherDetails = useCallback(async () => {
  //   const response = await getDetailsById("/teacher/subjects", section.id);
  //   setTeacherState(response);
  // }, [section.id]);

  // useEffect(() => {
  //   if (section.id) {
  //     getSubjectTeacherDetails();
  //   }
  // }, [getSubjectTeacherDetails, section.id]);

  // const handleSubmit = async (e, schoolStatus = "ACTIVE") => {
  //   setModalState({
  //     isGradeModalOpen: false,
  //     isEdit: false,
  //     data: null,
  //   });
  // };

  return (
    <Dialog
      maxWidth="md"
      fullWidth
      open={true}
      fullScreen={isSmallDevice}
      scroll="paper"
      onClose={() =>
        setModalState((prev) => ({ ...prev, isGradeModalOpen: false }))
      }
      ariaLabeledBy="form-dialog-title"
    >
      <Grid container>
        <Grid item xs={11}>
          <DialogTitle id="form-dialog-title">
            <Typography variant="h6" className={classes.heading}>
              Roles: Grades & Subjects
              {/* teacher name - role name */}
            </Typography>
          </DialogTitle>
        </Grid>
        <Grid item xs={1} className={classes.modalCloseBtn}>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </Grid>
      </Grid>
      <Divider />
      <DialogContent style={{ padding: "8px 25px" }}>
        <Box>
          <Box style={{ margin: "1.5rem 0rem" }}>
            <TableContainer component={Paper} elevation={0}>
              <Table
                style={{ minWidth: 700, border: "1px solid lightgrey" }}
                aria-label="simple table"
              >
                <TableHead style={{ background: "#263657" }}>
                  <TableRow>
                    <TableCell className={classes.tableCell}>Grade</TableCell>
                    <TableCell className={classes.tableCell}>
                      Subjects
                    </TableCell>
                    <TableCell className={classes.tableCell}></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.roleClassXrefs?.length ? (
                    data?.roleClassXrefs?.map((e, index) => (
                      <TableRow
                        style={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          className={classes.table}
                        >
                          <Typography variant="subtitle2">
                            {e?.className}
                          </Typography>
                        </TableCell>
                        <TableCell className={classes.table}>
                          {e?.subjects.map((s) => s.subjectName).join(", ")}
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <div className={classes.noDataAvailable}>
                      No Data available
                    </div>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </DialogContent>
      {/* <DialogActions style={{ border: "1px solid lightgrey" }}>
        <Box style={{ paddingRight: "24px" }}>
          <Button
            color="primary"
            // disabled={isReadOnly || isNew}
            variant="contained"
            onClick={handleSubmit}
            data-html2canvas-ignore
          >
            Save
          </Button>
        </Box>
      </DialogActions> */}
    </Dialog>
  );
}

export default GradeSubjectModal;
