import * as R from "ramda";

// const BASE_URL = `https://dev.eduailytics.com`;
const BASE_URL = process.env.REACT_APP_API_ENDPOINT; // This is temporary fix

const API_BASE_URL = `${BASE_URL}/api`;

const request = (options, doNotSetContentType = false) => {
  const headers = new Headers({
    ...(!doNotSetContentType && { "Content-Type": "application/json" }),
    "Access-Control-Allow-Origin": "*",
  });

  if (localStorage.getItem("authToken")) {
    headers.append(
      "Authorization",
      "Bearer " + localStorage.getItem("authToken")
    );
  }

  const defaults = { headers: headers };
  options = Object.assign({}, defaults, options);
  return fetch(options.url, options)
    .then((response) => {
      if (response.status === 204) {
        return response.text();
      }
      return response.json().then((json) => {
        if (response.status === 401 || response.status === 404) {
          localStorage.setItem("isLoggedIn", false);
          localStorage.setItem("authToken", null);
          if (options.url.includes("login")) {
            throw new Error(json.error);
          }
          const w = window.open("", "_self");
          w.location.href = "./";
        }
        if (!response.ok) {
          return Promise.reject(json);
        }
        return json;
      });
    })
    .catch((error) => {
      if (error.name === "TypeError") {
        localStorage.setItem("isLoggedIn", false);
        localStorage.setItem("authToken", null);
        const w = window.open("", "_self");
        w.location.href = "./";
      }
      throw error;
    });
};

export function getCurrentUser() {
  if (!localStorage.getItem("authToken")) {
    return Promise.reject("No access token set.");
  }

  return request({
    url: API_BASE_URL + "/auth/me",
    method: "GET",
  });
}

export function login(loginRequest) {
  return request({
    url: API_BASE_URL + "/auth/login/",
    method: "POST",
    body: JSON.stringify(loginRequest),
  });
}

export function profileDetails() {
  return request({
    url: API_BASE_URL + "/auth/me",
    method: "GET",
  });
}

export function forgotPassword(req) {
  return request({
    url: API_BASE_URL + `/auth/forgot-password?email=${req.email}`,
    method: "GET",
  });
}

export function signup(signupRequest) {
  return request({
    url: API_BASE_URL + "/auth/signup",
    method: "POST",
    body: JSON.stringify(signupRequest),
  });
}

export function logout() {
  return request({
    url: API_BASE_URL + "/auth/logout",
    method: "GET",
  });
}

export function saveLOV(requestPayload) {
  let method = "POST";
  if (R.prop("id", requestPayload)) {
    method = "PUT";
  }
  const payload =
    method === "POST" ? R.dissocPath(["id"], requestPayload) : requestPayload;
  const URL =
    method === "POST" ? "/lov" : `/lov/${R.prop("id", requestPayload)}`;
  return request({
    url: API_BASE_URL + URL,
    method: method,
    body: JSON.stringify(payload),
  });
}

export const getLOV = (requestParams) => {
  const { page, size, name } = requestParams;
  return request({
    url: API_BASE_URL + `/lov?page=${page}&size=${size}&name=${name}`,
    method: "GET",
    params: { ...requestParams },
  });
};

export const setLOVStatus = (requestPayload, id) => {
  const { status } = requestPayload;
  return request({
    url: API_BASE_URL + `/lov/${id}?status=${status}`,
    method: "PATCH",
    body: JSON.stringify(requestPayload),
  });
};

export function addSchoolData(requestPayload) {
  return request({
    url: API_BASE_URL + "/schools",
    method: "POST",
    body: JSON.stringify(requestPayload),
  });
}

export function syncSchoolData(requestPayload) {
  return request({
    url: API_BASE_URL + "/schools/sync",
    method: "POST",
    body: JSON.stringify(requestPayload),
  });
}

export const getSchools = (requestParams) => {
  const { page, size, name } = requestParams;
  return request({
    url:
      API_BASE_URL +
      `/schools?page=${page}&size=${size}${name ? `&name=${name}` : ""}`,
    method: "GET",
    params: { ...requestParams },
  });
};

export const getClasses = (requestParams) => {
  const { page, size, name } = requestParams;
  return request({
    url: API_BASE_URL + `/classes?page=${page}&size=${size}&name=${name}`,
    method: "GET",
    params: { ...requestParams },
  });
};

export function addClassSection(requestPayload, gradeId) {
  return request({
    url: API_BASE_URL + `/classes/${gradeId}/class-sections`,
    method: "POST",
    body: JSON.stringify(requestPayload),
  });
}

export function putClassSection(requestPayload, gradeId) {
  return request({
    url: API_BASE_URL + `/classes/${gradeId}/class-sections`,
    method: "PUT",
    body: JSON.stringify(requestPayload),
  });
}

export const getClassSections = (requestParams) => {
  const { page, size, name, schoolCode = "", className = "" } = requestParams;
  return request({
    url:
      API_BASE_URL +
      `/class-sections?page=${page}&size=${size}&name=${name}&schoolCode=${schoolCode}&className=${className}`,
    method: "GET",
    params: { ...requestParams },
  });
};

export const getSubjects = (requestParams) => {
  const { page, size, name, schoolCode = "", className = "" } = requestParams;
  return request({
    url:
      API_BASE_URL +
      `/subjects?page=${page}&size=${size}&name=${name}&schoolCode=${schoolCode}&className=${className}`,
    method: "GET",
    params: { ...requestParams },
  });
};

export function addSubject(requestPayload, gradeId) {
  return request({
    url: API_BASE_URL + `/classes/${gradeId}/subjects`,
    method: "POST",
    body: JSON.stringify(requestPayload),
  });
}

export const getPolicies = (requestParams) => {
  const { page, size, name } = requestParams;
  return request({
    url: API_BASE_URL + `/policies?page=${page}&size=${size}&name=${name}`,
    method: "GET",
    params: { ...requestParams },
  });
};

export function addPolicy(requestPayload) {
  return request({
    url: API_BASE_URL + "/policy",
    method: "POST",
    body: JSON.stringify(requestPayload),
  });
}

export function addTeacher(requestPayload) {
  return request({
    url: API_BASE_URL + "/users",
    method: "POST",
    body: JSON.stringify(requestPayload),
  });
}

export const getTeachers = (requestParams) => {
  const { page, size, schoolCode = "", className = "" } = requestParams;
  return request({
    url:
      API_BASE_URL +
      `/users?page=${page}&size=${size}&schoolCode=${schoolCode}&className=${className}`,
    method: "GET",
    params: { ...requestParams },
  });
};

export const saveData = (requestPayload, url) => {
  const { id } = requestPayload;
  return request({
    url: API_BASE_URL + `${id ? `${url}/${id}` : url}`,
    method: id ? "PUT" : "POST",
    body: JSON.stringify(requestPayload),
  });
};

export const getPageListData = (requestParams, url) => {
  const { page, size, name, pageFilter } = requestParams;
  const {
    schoolCode,
    gradeName,
    subject,
    classTeacher,
    tierPlan,
    status,
    annualPeriod,
    unitPlan,
  } = pageFilter;
  const filterAppendData = `${
    annualPeriod ? `&annualPeriod=${annualPeriod}` : ""
  }${status ? `&status=${status}` : ""}${
    schoolCode ? `&schoolCode=${schoolCode}` : ""
  }${gradeName ? `&className=${gradeName}` : ""}${
    subject ? `&subject=${subject}` : ""
  }${classTeacher ? `&classTeacher=${classTeacher}` : ""}${
    tierPlan ? `&tierPlan=${tierPlan}` : ""
  }${unitPlan ? `&unitPlan=${unitPlan}` : ""}`;
  return request({
    url:
      API_BASE_URL +
      `${url}?page=${page}&size=${size}&name=${name}${filterAppendData}`,
    method: "GET",
    params: { ...requestParams, ...pageFilter },
  });
};

export const getAllTeachersOfSchool = (schoolCode) => {
  return request({
    url: API_BASE_URL + `/users/list?schoolCode=${schoolCode}`,
    method: "GET",
  });
};

export const updateTeacherSubjectMapping = (requestPayload, sectionId) => {
  return request({
    url: API_BASE_URL + `/teacher/subjects/${sectionId}`,
    method: "POST",
    body: JSON.stringify(requestPayload),
  });
};

export const addorUpdateUserRole = (requestPayload, userId, isEdit) => {
  return request({
    url: API_BASE_URL + `/users/${userId}/roles/`,
    method: isEdit ? "PUT" : "POST",
    body: JSON.stringify(requestPayload),
  });
};

export const deleteUserRole = (userId, roleId) => {
  return request({
    url: API_BASE_URL + `/users/${userId}/roles/${roleId}`,
    method: "DELETE",
  });
};

export const assignTempPassword = (userEmail) => {
  return request({
    url: API_BASE_URL + `/admin-controls/update-password/${userEmail}`,
    method: "PATCH",
  });
};

export const changeStatus = (requestPayload, id, url) => {
  const { status } = requestPayload;
  return request({
    url: API_BASE_URL + `${url}/${id}?status=${status}`,
    method: "PATCH",
    body: JSON.stringify(requestPayload),
  });
};

export const updateUnitPlanDetails = (requestPayload, id) => {
  return request({
    url: API_BASE_URL + `/unit-plan/${id}/unitplan-form`,
    method: "PUT",
    body: JSON.stringify(requestPayload),
  });
};

export const getUnitPlanFormData = (id) => {
  return request({
    url: API_BASE_URL + `/unit-plan/${id}/unit-plan-form`,
    method: "GET",
  });
};

export const updateUnitPlanFormData = (requestPayload, id) => {
  return request({
    url: API_BASE_URL + `/unit-plan/${id}/unit-plan-form`,
    method: "PUT",
    body: JSON.stringify(requestPayload),
  });
};

export const getLessonPlanFormData = (id) => {
  return request({
    url: API_BASE_URL + `/lesson-plan/${id}/lesson-plan-form`,
    method: "GET",
  });
};

export const updateLessonPlanFormData = (requestPayload, id) => {
  return request({
    url: API_BASE_URL + `/lesson-plan/${id}/lesson-plan-form`,
    method: "PUT",
    body: JSON.stringify(requestPayload),
  });
};

export const updateLessonPlanDetails = (requestPayload, id) => {
  return request({
    url: API_BASE_URL + `/lesson-plan/${id}`,
    method: "PUT",
    body: JSON.stringify(requestPayload),
  });
};

export const getById = (url, id) => {
  return request({
    url: API_BASE_URL + `${url}/${id}`,
    method: "GET",
  });
};

export const updateUnitLessons = (requestPayload, id) => {
  return request({
    url: API_BASE_URL + `/unit-plan/${id}/lesson-plans`,
    method: "PUT",
    body: JSON.stringify(requestPayload),
  });
};

export const createGoal = (requestPayload) => {
  return request({
    url: API_BASE_URL + `/goal`,
    method: "POST",
    body: JSON.stringify(requestPayload),
  });
};

export const updateGoal = (requestPayload, id) => {
  return request({
    url: API_BASE_URL + `/goal/${id}`,
    method: "PUT",
    body: JSON.stringify(requestPayload),
  });
};

export const deleteGoal = (id) => {
  return request({
    url: API_BASE_URL + `/goal/${id}`,
    method: "DELETE",
  });
};

export const updateGoalSettingState = (id, state, patchableObject) => {
  return request({
    url: API_BASE_URL + `/goal-setting-form/${id}?status=${state}`,
    method: "PATCH",
    body: JSON.stringify(patchableObject),
  });
};

export const createDuplicateAnnualPlan = (id, annualPeriod) => {
  return request({
    url:
      API_BASE_URL +
      `/annual-plans/duplicate/${id}?annualPeriod=${annualPeriod}`,
    method: "PATCH",
  });
};

export const getGoalConfigurations = (id) => {
  return request({
    url: API_BASE_URL + `/goal-configurations/`,
    method: "GET",
  });
};

export const createGoalConfiguration = (requestPayload) => {
  return request({
    url: API_BASE_URL + `/goal-configurations/`,
    method: "POST",
    body: JSON.stringify(requestPayload),
  });
};

export const getGoalTimelineConfigurations = (id) => {
  return request({
    url: API_BASE_URL + `/goal-timeline-configurations/`,
    method: "GET",
  });
};

export const createGoalTimelineConfiguration = (requestPayload) => {
  return request({
    url: API_BASE_URL + `/goal-timeline-configurations/`,
    method: "POST",
    body: JSON.stringify(requestPayload),
  });
};

export const getGoalSettingForm = (id) => {
  return request({
    url: API_BASE_URL + `/goal-setting-form/${id}`,
    method: "GET",
  });
};

export const updateGoalSettingReviewState = (id, state) => {
  return request({
    url: API_BASE_URL + `/goal-setting-review/${id}?status=${state}`,
    method: "PATCH",
  });
};

export const getGoalSettingReview = (id) => {
  return request({
    url: API_BASE_URL + `/goal-setting-review/${id}`,
    method: "GET",
  });
};

export const createGoalSettingReviewComment = (requestPayload) => {
  return request({
    url: API_BASE_URL + `/review-comments`,
    method: "POST",
    body: JSON.stringify(requestPayload),
  });
};

export const getSelfGoalFeedback = (id) => {
  return request({
    url: API_BASE_URL + `/goal-feedback/${id}/self`,
    method: "GET",
  });
};

export const getPeerGoalFeedback = (id) => {
  return request({
    url: API_BASE_URL + `/goal-feedback/${id}/peer`,
    method: "GET",
  });
};

export const createGoalFeedbackComment = (requestPayload, id) => {
  return request({
    url: API_BASE_URL + `/goal-feedback/${id}/comment`,
    method: "PUT",
    body: JSON.stringify(requestPayload),
  });
};

export const putGoalFeedback = (requestPayload) => {
  return request({
    url: API_BASE_URL + `/goal-feedback/`,
    method: "PUT",
    body: JSON.stringify(requestPayload),
  });
};

export const createGoalEvidence = (requestPayload) => {
  return request(
    {
      url: API_BASE_URL + `/goal/evidence`,
      method: "POST",
      body: requestPayload,
    },
    true
  );
};

export const deleteSelfGoalFeedbackEvidence = (id) => {
  return request({
    url: API_BASE_URL + `/goal/evidence/${id}`,
    method: "DELETE",
  });
};

export const createPeerGoalFeedback = (requestPayload) => {
  return request({
    url: API_BASE_URL + `/goal-feedback/peer`,
    method: "POST",
    body: JSON.stringify(requestPayload),
  });
};

export const putPeerGoalFeedback = (requestPayload, id) => {
  return request({
    url: API_BASE_URL + `/goal-feedback/peer/${id}`,
    method: "PUT",
    body: JSON.stringify(requestPayload),
  });
};

export const getRolePrivileges = (id) => {
  return request({
    url: API_BASE_URL + `/privileges`,
    method: "GET",
  });
};

export const updateTierPlan = (requestPayload, id) => {
  return request({
    url: API_BASE_URL + `/tier-plan/${id}`,
    method: "PUT",
    body: JSON.stringify(requestPayload),
  });
};

export const changePassword = (requestPayload) => {
  return request({
    url: API_BASE_URL + `/auth/change-password`,
    method: "POST",
    body: JSON.stringify(requestPayload),
  });
};

export const resetPassword = (requestPayload) => {
  return request({
    url: API_BASE_URL + `/auth/reset-password`,
    method: "POST",
    body: JSON.stringify(requestPayload),
  });
};

export const getUnitPlanList = (requestParams) => {
  const { page, size, name, className = "", subject = "" } = requestParams;
  return request({
    url:
      API_BASE_URL +
      `/unit-plan/list?page=${page}&size=${size}&name=${name}&className=${className}&subject=${subject}`,
    method: "GET",
    params: { ...requestParams },
  });
};

export const getUnitPlan = (id) => {
  return request({
    url: API_BASE_URL + `/unit-plan/${id}`,
    method: "GET",
  });
};

export const getOutstandingUnitPlanList = (requestParams) => {
  const { page, size, role, annualPeriod, tierPlanValue } = requestParams;
  return request({
    url:
      API_BASE_URL +
      `/unit-plan/outstanding?page=${page}&size=${size}&role=${role}&annualPeriod=${annualPeriod}&tierPlanValue=${tierPlanValue}`,
    method: "GET",
    params: { ...requestParams },
  });
};

export const getUpcomingUnitPlanList = (requestParams) => {
  const { page, size, role, annualPeriod, tierPlanValue } = requestParams;
  return request({
    url:
      API_BASE_URL +
      `/unit-plan/upcoming?page=${page}&size=${size}&role=${role}&annualPeriod=${annualPeriod}&tierPlanValue=${tierPlanValue}`,
    method: "GET",
    params: { ...requestParams },
  });
};

export const getOutstandingLessonPlanList = (requestParams) => {
  const { page, size, role, annualPeriod, tierPlanValue } = requestParams;
  return request({
    url:
      API_BASE_URL +
      `/lesson-plan/outstanding?page=${page}&size=${size}&role=${role}&annualPeriod=${annualPeriod}&tierPlanValue=${tierPlanValue}`,
    method: "GET",
    params: { ...requestParams },
  });
};

export const getUpcomingLessonPlanList = (requestParams) => {
  const { page, size, role, annualPeriod, tierPlanValue } = requestParams;
  return request({
    url:
      API_BASE_URL +
      `/lesson-plan/upcoming?page=${page}&size=${size}&role=${role}&annualPeriod=${annualPeriod}&tierPlanValue=${tierPlanValue}`,
    method: "GET",
    params: { ...requestParams },
  });
};

export const deletePlanAPI = (id, planName) => {
  return request({
    url: API_BASE_URL + `/${planName}/${id}`,
    method: "DELETE",
  });
};

export const planStatusAPI = (id, planName, status) => {
  return request({
    url: API_BASE_URL + `/${planName}/${id}?status=${status}`,
    method: "PATCH",
  });
};

export const readResources = () => {
  return request({
    url: API_BASE_URL + `/resources`,
    method: "GET",
  });
};
