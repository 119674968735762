import {
  getClassList,
  getClassSectionsList,
  getSchoolsList,
  getSubjectList,
  getTeachersList,
  getUnitPlansList,
} from "components/master/utils";

export const LOVSelectKeys = {
  SCHOOL: "school",
  CLASS: ["gradeName"],
  CLASS_SECTION: "section",
  SUBJECT: "subject",
  AFFILIATED_BOARD: ["affiliatedBoard"],
  CLASS_TEACHER: "classTeacher",
  TEACHER: "teacher",
  TLM: ["tlmList"],
  TLS: ["tlmList"],
  FORMATIVE: ["assessmentForm", "formativeAssessments"],
  DIFF_TEACHING_STRATEGIES: ["teachingStrategies"],
  INTRO_ACTIVITIES: ["intoductionActivities"],
  TIER_PLAN: ["name"],
  SUBJECT: ["subject"],
  ROLES: ["roles"],
  UNIT_PLANS_LIST: ["unitPlanList"],
  DTS_LIST: ["dtsList"],
  ANNUAL_PERIOD: ["annualPeriod"],
};
export const LOVDataKeys = {
  SCHOOL: "schoolCode",
  CLASS: "gradeName",
  CLASS_SECTION: "section",
  SUBJECT: "subjectName",
  AFFILIATED_BOARD: "value",
  CLASS_TEACHER: "email",
  TEACHER: "email",
  TLM: "name",
  FORMATIVE: "name",
  DIFF_TEACHING_STRATEGIES: "name",
  INTRO_ACTIVITIES: "name",
  SUBJECT: "value",
  TIER_PLAN: "name",
  ROLES: "name",
  UNIT_PLANS_LIST: "name",
  ANNUAL_PERIOD: "value",
};

export const LOVDisplayKeys = {
  SCHOOL: "schoolName",
  CLASS: "gradeName",
  CLASS_SECTION: "section",
  SUBJECT: "subjectName",
  AFFILIATED_BOARD: "value",
  CLASS_TEACHER: "firstName",
  TEACHER: "firstName",
  ROLES: "name",
  UNIT_PLANS_LIST: "name",
};

export const LOVApiKeys = {
  SCHOOL: "school",
  CLASS: "Grades",
  CLASS_SECTION: "classSections",
  SUBJECT: "subject",
  CLASS_TEACHER: "classTeacher",
  TEACHER: "teachers",
  ROLES: "roles",
  UNIT_PLANS_LIST: "unitPlansList",
};

export const LOVKeyMap = {
  AFFILIATED_BOARD: "AffiliatedBoard",
  TLM: "TLMList",
  TLS: "TLSList",
  TIER_PLAN: "TierPlan",
  FORMATIVE: "FormativeAssessment",
  DIFF_TEACHING_STRATEGIES: "DifferentiatedTeachingStrategies",
  INTRO_ACTIVITIES: "IntroductionActivity",
  UNIT_PLANS_LIST: "unitPlanList",
  DTS_LIST: "DTSList",
  ANNUAL_PERIOD: "AnnualPeriod",
};

export const DEPENDENT_TO_PAGE = {
  classTeacher: "userTeachers",
  teachers: "teachers",
  classSections: "classSections",
  subject: "subjects",
};
export const LOVFunctionMap = {
  school: getSchoolsList,
  class: getClassList,
  classSections: getClassSectionsList,
  subject: getSubjectList,
  classTeacher: getTeachersList,
  teachers: getTeachersList,
  unitPlansList: getUnitPlansList,
};

export const getLOVSelectData = (apiKey) => {
  return LOVFunctionMap[apiKey];
};
