import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import LOVSelect from "common/components/LOVSelect/LOVSelect";
import {
  LOVDataKeys,
  LOVKeyMap,
  LOVSelectKeys,
} from "common/components/SelectDropDown/SelectDropDownUtils";
import { FORM_TYPES } from "common/utils/formUtility";
import {
  getPageList,
  saveUnitPlanDetails,
} from "components/listOfValues/utils";
import * as R from "ramda";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UnitPlanFormInitialState } from "state/globalForms/initialStates";
import { updateForm } from "state/slices/globalFormSlice";
import { PAGES, setPageListItems } from "state/slices/pageSlice";
import { colors } from "theme/colors";
import ObservationSelectionForm from "./ObservationSelectionForm";
import {
  getSelectList,
  getUnitPlanDetailsFormPayload,
  isSubmitEnabled,
  setObservationsValue,
} from "./utils";

import { CloudDownload } from "@material-ui/icons";
import DisplaySubUnits from "common/components/DisplaySubUnits";
import { getDownloadFileName } from "common/utils/downloadAsPdf";
import { GLOBAL_DATE_FORMAT } from "constants/PageConstants";
import moment from "moment";
import { useReactToPrint } from "react-to-print";
import ConsentForm from "./ConsentForm";
import TLMReferenceForm from "./TLMReferenceForm";

/**
 * @deprecated Since version 1.0. Will be deleted in version 2.0.
 */
export const UnitPlanDetailsFormHiddenElems = [
  "conceptObjective",
  "skillObjective",
  "factObjective",
  "languageOfInstruction",
  "studentVocabulary",
  "symbioticLearning",
  "environmentalConsciousness",
  "designThinking",
  "emotionalWellness",
  "levelOfLearningInput1",
  "levelOfLearningInput2",
  "levelOfLearningInput3",
  "studentObjectives",
  "summative",
  "teacherReflection",
];
const useStyle = makeStyles((theme) => ({
  heading: {
    color: "#263657",
  },
  readOnlyContainer: {
    backgroundColor: "#263657",
    padding: "1rem",
    color: colors.gray1,
    marginBottom: "1rem",
    borderRadius: 6,
  },
  readOnlyTitle: {
    fontSize: "20px",
  },
  btn: {
    "&.MuiButton-root": {
      marginRight: "1rem",
    },
  },
  subUnitChip: {
    "&.MuiChip-root": {
      marginRight: "1rem",
      color: colors.blue1,
      fontSize: "16px",
      fontWeight: "500",
    },
  },
  downloadBtn: {
    "&.MuiButton-root": {
      border: `1 px solid ${colors.gray1}`,
    },
    background: "#263657",
    color: colors.gray1,
    border: "1px solid #fff",
    padding: "10px 15px",
    margin: 0,
  },
  inputLabel1: {
    top: "-17px",
    left: "-14px",
  },
  inputLabel: {
    top: "-17px",
  },
}));

export const UnitReadOnlyData = ({ data, downloadAsPdf }) => {
  const classes = useStyle();
  const subUnits = R.pathOr([], ["subUnits"], data);
  return (
    <Grid container className={classes.readOnlyContainer}>
      <Grid item xs={12} md={9}>
        <Typography className={classes.readOnlyTitle}>
          <b>{R.propOr("", "name", data)}</b>
        </Typography>
      </Grid>
      <Grid item xs={12} md={3} style={{ marginTop: "1rem" }}>
        <Button
          endIcon={<CloudDownload />}
          className={classes.downloadBtn}
          variant="text"
          data-html2canvas-ignore
          onClick={downloadAsPdf}
        >
          Download
        </Button>
      </Grid>
      <Grid container spacing={1} style={{ marginTop: "1rem" }}>
        <Grid item xs={5}>
          <Typography>
            Class: {R.pathOr("-", ["curriculumContext", "gradeName"], data)}
          </Typography>
        </Grid>

        <Grid item xs={7}>
          <Typography>
            Subject: {R.pathOr("-", ["curriculumContext", "subjectName"], data)}
          </Typography>
        </Grid>

        <Grid item xs={5}>
          <Typography>
            Start:{" "}
            {moment(R.pathOr("-", ["start"], data)).format(GLOBAL_DATE_FORMAT)}
          </Typography>
        </Grid>

        <Grid item xs={7}>
          <Typography>
            End:{" "}
            {moment(R.pathOr("-", ["end"], data)).format(GLOBAL_DATE_FORMAT)}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography>Status: {R.pathOr("-", ["status"], data)}</Typography>
        </Grid>
      </Grid>
      <DisplaySubUnits subUnits={subUnits} />
    </Grid>
  );
};

/**
 * @deprecated Since version 1.0. Will be deleted in version 2.0.
 */
const UnitPlanDetailsForm = ({ unitData, onTabChange, userRoles }) => {
  const printRef = useRef();
  const classes = useStyle();
  const { curriculumContext = {} } = unitData;
  const unitPlanFormData = useSelector(
    (state) => state.globalForm[FORM_TYPES.UNIT_PLAN_FORM]
  );
  const [unitPlanDetailsData, setUnitPlanDetailsData] =
    useState(unitPlanFormData);

  const [consentData, setConsentData] = useState(
    R.propOr([], "consents", unitData)
  );

  const [TLMData, setTLMData] = useState(
    R.pathOr([], ["tlmList"], unitPlanFormData)
  );
  const dispatch = useDispatch();
  let observationsData = useSelector(
    (state) => state.pages[PAGES.observations]
  );

  const onSaveDetails = async (e, unitPlanStatus = "ACTIVE") => {
    const payload = getUnitPlanDetailsFormPayload(
      unitData,
      unitPlanFormData,
      TLMData,
      consentData,
      unitPlanStatus
    );
    const response = await saveUnitPlanDetails(payload, unitData.id);
    dispatch(
      updateForm({
        formType: FORM_TYPES.UNIT_PLAN_FORM,
        formDaata: UnitPlanFormInitialState,
      })
    );
    onTabChange(0);
  };

  const onFormChange = (e, path) => {
    const updatedForm = R.assocPath(path, e.target.value, unitPlanDetailsData);
    setUnitPlanDetailsData(updatedForm);
    dispatch(
      updateForm({ formType: FORM_TYPES.UNIT_PLAN_FORM, formData: updatedForm })
    );
  };

  const getObservationsData = async () => {
    const pageListData = await getPageList({
      searchKey: "",
      page: PAGES.observations,
    });
    dispatch(
      setPageListItems({
        page: PAGES.observations,
        itemList: pageListData.content,
        totalElements: pageListData.totalElements,
        totalPages: pageListData.totalPages,
      })
    );
  };
  const AllowedRoles = [
    "System Administrator",
    "Administrator",
    "Inspire Us-Core",
  ];

  const isEditEnabled = (roles) => {
    let editEnabled = false;
    if (roles && roles.length > 0) {
      roles.forEach((role) => {
        if (R.includes(role, AllowedRoles)) {
          editEnabled = true;
        }
      });
    }
    return editEnabled;
  };

  const isReadOnly = isEditEnabled(userRoles)
    ? false
    : R.propOr("", "status", unitData) === "COMPLETED";
  const saveTLMData = (data) => {
    const updatedForm = R.assocPath(["tlmList"], data, unitPlanDetailsData);
    setUnitPlanDetailsData(updatedForm);
    dispatch(
      updateForm({ formType: FORM_TYPES.UNIT_PLAN_FORM, formData: updatedForm })
    );
  };

  useEffect(() => {
    if (R.isEmpty(observationsData.items)) {
      getObservationsData();
    }
    setUnitPlanDetailsData(unitPlanFormData);
    setConsentData(R.propOr([], "consents", unitData));
    setTLMData(R.pathOr([], ["tlmList"], unitPlanFormData));
  }, [observationsData, unitPlanFormData]);

  const downloadFileName = getDownloadFileName({
    data: unitData,
    templateId: "unit_plan",
  });

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  return (
    <Box
      id="unitDetailsForm"
      style={{
        marginTop: "1rem ",
        margin: "1rem ",
      }}
      fullwidth
      ref={printRef}
    >
      {!R.isNil(curriculumContext) && (
        <UnitReadOnlyData data={unitData} downloadAsPdf={handlePrint} />
      )}
      <h3 className={classes.heading} align={"left"}>
        Periods
      </h3>
      <Grid item xs={3}>
        <TextField
          type="number"
          variant="outlined"
          id="multiline-static"
          value={R.pathOr("", ["periods"], unitPlanDetailsData)}
          fullWidth
          InputProps={{ inputProps: { min: 0 } }}
          onChange={(e) => onFormChange(e, ["periods"])}
          disabled={isReadOnly}
        />
      </Grid>
      <Typography variant="h6" color="primary" className={classes.heading}>
        Unit Plan
      </Typography>
      <TextField
        variant="outlined"
        margin="normal"
        id="multiline-static"
        placeholder="This field is applicable for Science and Mathematics subjects"
        multiline
        fullWidth
        data-html2canvas-ignore
        value={R.pathOr("", ["studentObjectives"], unitPlanDetailsData)}
        onChange={(e) => onFormChange(e, ["studentObjectives"])}
        disabled={isReadOnly}
      />
      <Box fullWidth id="studentObjectives" display={"none"}>
        {R.pathOr("", ["studentObjectives"], unitPlanDetailsData)}
      </Box>
      <h3 className={classes.heading} align={"left"}>
        Teaching Learning Material
      </h3>
      <TLMReferenceForm
        displayLOVSelect={true}
        TLMData={TLMData}
        setTLMData={saveTLMData}
      />
      <Typography
        className={classes.heading}
        variant="h6"
        style={{ marginTop: "1rem" }}
      >
        Learning Objectives
      </Typography>
      <h4 align={"left"}>
        Concept Related Objectives: (Knowledge and Comprehension, Remembering
        and Understanding)
      </h4>
      <InputLabel>Students will be able to:</InputLabel>
      <TextField
        id="multiline-static"
        placeholder="Students will be able to"
        multiline
        variant="outlined"
        margin="normal"
        fullWidth
        data-html2canvas-ignore
        value={R.pathOr(
          "",
          ["learningObjectiveForm", "conceptObjective"],
          unitPlanDetailsData
        )}
        onChange={(e) =>
          onFormChange(e, ["learningObjectiveForm", "conceptObjective"])
        }
      />
      <Box fullWidth id="conceptObjective" display={"none"}>
        {R.pathOr(
          "",
          ["learningObjectiveForm", "conceptObjective"],
          unitPlanDetailsData
        )}
      </Box>
      <h4 align={"left"}>
        Skill Related Objectives: Apply, Analyse, Evaluate and Create (Applying,
        Analysing, Evaluating, Creating)
      </h4>
      <InputLabel>Students will be able to:</InputLabel>
      <TextField
        id="multiline-static"
        variant="outlined"
        margin="normal"
        placeholder="Students will be able to"
        multiline
        value={R.pathOr(
          "",
          ["learningObjectiveForm", "skillObjective"],
          unitPlanDetailsData
        )}
        fullWidth
        data-html2canvas-ignore
        onChange={(e) =>
          onFormChange(e, ["learningObjectiveForm", "skillObjective"])
        }
      />
      <Box fullWidth id="skillObjective" display={"none"}>
        {R.pathOr(
          "",
          ["learningObjectiveForm", "skillObjective"],
          unitPlanDetailsData
        )}
      </Box>
      <h4 align={"left"}>
        Fact Related Objectives: Knowledge and Comprehension (Remembering and
        Understanding)
      </h4>
      <InputLabel>Students will be able to:</InputLabel>
      <TextField
        id="multiline-static"
        variant="outlined"
        margin="normal"
        placeholder="Students will be able to"
        multiline
        value={R.pathOr(
          "",
          ["learningObjectiveForm", "factObjective"],
          unitPlanDetailsData
        )}
        fullWidth
        data-html2canvas-ignore
        onChange={(e) =>
          onFormChange(e, ["learningObjectiveForm", "factObjective"])
        }
      />
      <Box fullWidth id="factObjective" display={"none"}>
        {R.pathOr(
          "",
          ["learningObjectiveForm", "factObjective"],
          unitPlanDetailsData
        )}
      </Box>
      <h3 className={classes.heading} align={"left"}>
        Vocabulary Related Objectives
      </h3>
      <h4 align={"left"}>
        Students will be able to comprehend academic language, and also use it
        in context.
      </h4>
      <InputLabel>Students will be able to:</InputLabel>
      <TextField
        id="multiline-static"
        variant="outlined"
        margin="normal"
        placeholder="Language of Instruction (used by teacher, comprehended by students)"
        multiline
        value={R.pathOr(
          "",
          [
            "learningObjectiveForm",
            "vocabularyObjective",
            "languageOfInstruction",
          ],
          unitPlanDetailsData
        )}
        fullWidth
        data-html2canvas-ignore
        onChange={(e) =>
          onFormChange(e, [
            "learningObjectiveForm",
            "vocabularyObjective",
            "languageOfInstruction",
          ])
        }
      />
      <br />
      <Box fullWidth id="languageOfInstruction" display={"none"}>
        {R.pathOr(
          "",
          [
            "learningObjectiveForm",
            "vocabularyObjective",
            "languageOfInstruction",
          ],
          unitPlanDetailsData
        )}
      </Box>
      <InputLabel>
        Student vocabulary (internalised and use in context)
      </InputLabel>
      <TextField
        id="multiline-static"
        variant="outlined"
        margin="normal"
        placeholder="Student Vocabulary (internalised and use in context)"
        multiline
        value={R.pathOr(
          "",
          ["learningObjectiveForm", "vocabularyObjective", "studentVocabulary"],
          unitPlanDetailsData
        )}
        fullWidth
        data-html2canvas-ignore
        onChange={(e) =>
          onFormChange(e, [
            "learningObjectiveForm",
            "vocabularyObjective",
            "studentVocabulary",
          ])
        }
      />
      <br />
      <Box fullWidth id="studentVocabulary" display={"none"}>
        {R.pathOr(
          "",
          ["learningObjectiveForm", "vocabularyObjective", "studentVocabulary"],
          unitPlanDetailsData
        )}
      </Box>
      <Typography variant="h6">Values Related Objectives</Typography>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <h4 align={"left"}>Symbiotic Learning</h4>
          <InputLabel>Students will be able to:</InputLabel>
          <TextField
            id="multiline-static"
            variant="outlined"
            margin="normal"
            placeholder="Students will be able to"
            multiline
            value={R.pathOr(
              "",
              [
                "learningObjectiveForm",
                "valuesObjectives",
                "symbioticLearning",
              ],
              unitPlanDetailsData
            )}
            data-html2canvas-ignore
            fullWidth
            onChange={(e) =>
              onFormChange(e, [
                "learningObjectiveForm",
                "valuesObjectives",
                "symbioticLearning",
              ])
            }
          />
          <Box fullWidth id="symbioticLearning" display={"none"}>
            {R.pathOr(
              "",
              [
                "learningObjectiveForm",
                "valuesObjectives",
                "symbioticLearning",
              ],
              unitPlanDetailsData
            )}
          </Box>
        </Grid>
        <Grid item xs={6}>
          <h4 align={"left"}>Environmental Consciousness</h4>
          <InputLabel>Students will be able to:</InputLabel>
          <TextField
            id="multiline-static"
            placeholder="Students will be able to:"
            variant="outlined"
            margin="normal"
            multiline
            value={R.pathOr(
              "",
              [
                "learningObjectiveForm",
                "valuesObjectives",
                "environmentalConsciousness",
              ],
              unitPlanDetailsData
            )}
            data-html2canvas-ignore
            fullWidth
            onChange={(e) =>
              onFormChange(e, [
                "learningObjectiveForm",
                "valuesObjectives",
                "environmentalConsciousness",
              ])
            }
          />
          <Box fullWidth id="environmentalConsciousness" display={"none"}>
            {R.pathOr(
              "",
              [
                "learningObjectiveForm",
                "valuesObjectives",
                "environmentalConsciousness",
              ],
              unitPlanDetailsData
            )}
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <h4 align={"left"}>Design Thinking</h4>
          <InputLabel>Students will be able to:</InputLabel>
          <TextField
            id="multiline-static"
            placeholder="Students will be able to:"
            variant="outlined"
            margin="normal"
            multiline
            value={R.pathOr(
              "",
              ["learningObjectiveForm", "valuesObjectives", "designThinking"],
              unitPlanDetailsData
            )}
            data-html2canvas-ignore
            fullWidth
            onChange={(e) =>
              onFormChange(e, [
                "learningObjectiveForm",
                "valuesObjectives",
                "designThinking",
              ])
            }
          />
          <Box fullWidth id="designThinking" display={"none"}>
            {R.pathOr(
              "",
              ["learningObjectiveForm", "valuesObjectives", "designThinking"],
              unitPlanDetailsData
            )}
          </Box>
        </Grid>
        <Grid item xs={6}>
          <h4 align={"left"}>Emotional Wellness</h4>
          <InputLabel>Students will be able to:</InputLabel>
          <TextField
            id="multiline-static"
            placeholder="Students will be able to:"
            variant="outlined"
            margin="normal"
            multiline
            value={R.pathOr(
              "",
              [
                "learningObjectiveForm",
                "valuesObjectives",
                "emotionalWellness",
              ],
              unitPlanDetailsData
            )}
            data-html2canvas-ignore
            fullWidth
            onChange={(e) =>
              onFormChange(e, [
                "learningObjectiveForm",
                "valuesObjectives",
                "emotionalWellness",
              ])
            }
          />
          <Box fullWidth id="emotionalWellness" display={"none"}>
            {R.pathOr(
              "",
              [
                "learningObjectiveForm",
                "valuesObjectives",
                "emotionalWellness",
              ],
              unitPlanDetailsData
            )}
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        {/* Sustainable Development */}
        <Grid item xs={6}>
          <h4 align={"left"}>Sustainable Development</h4>
          <InputLabel>Students will be able to:</InputLabel>
          <TextField
            id="sustainableDevelopment-multiline-static"
            placeholder="Students will be able to:"
            variant="outlined"
            margin="normal"
            multiline
            value={R.pathOr(
              "",
              [
                "learningObjectiveForm",
                "valuesObjectives",
                "sustainableDevelopment",
              ],
              unitPlanDetailsData
            )}
            data-html2canvas-ignore
            fullWidth
            onChange={(e) =>
              onFormChange(e, [
                "learningObjectiveForm",
                "valuesObjectives",
                "sustainableDevelopment",
              ])
            }
          />
          <Box fullWidth id="sustainableDevelopment" display={"none"}>
            {R.pathOr(
              "",
              [
                "learningObjectiveForm",
                "valuesObjectives",
                "sustainableDevelopment",
              ],
              unitPlanDetailsData
            )}
          </Box>
        </Grid>

        {/* Value of the Month */}
        <Grid item xs={6}>
          <h4 align={"left"}>Value of the Month</h4>
          <InputLabel>Students will be able to:</InputLabel>
          <TextField
            id="valueOfMonth-multiline-static"
            placeholder="Students will be able to:"
            variant="outlined"
            margin="normal"
            multiline
            value={R.pathOr(
              "",
              ["learningObjectiveForm", "valuesObjectives", "valueOfMonth"],
              unitPlanDetailsData
            )}
            data-html2canvas-ignore
            fullWidth
            onChange={(e) =>
              onFormChange(e, [
                "learningObjectiveForm",
                "valuesObjectives",
                "valueOfMonth",
              ])
            }
          />
          <Box fullWidth id="valueOfMonth" display={"none"}>
            {R.pathOr(
              "",
              ["learningObjectiveForm", "valuesObjectives", "valueOfMonth"],
              unitPlanDetailsData
            )}
          </Box>
        </Grid>
      </Grid>
      <br />
      <Typography variant="h6">Learning Outcomes</Typography>
      <ObservationSelectionForm
        className={R.pathOr("", ["curriculumContext", "gradeName"], unitData)}
        formType={FORM_TYPES.UNIT_PLAN_FORM}
        value={setObservationsValue(
          R.pathOr([], ["learningOutcomes"], unitPlanDetailsData),
          observationsData,
          R.pathOr("", ["curriculumContext", "gradeName"], unitData)
        )}
      />
      <br />
      <Typography variant="h6">Level/s of Learning</Typography>
      <TextField
        id="multiline-static"
        placeholder="All learners will be able to"
        variant="outlined"
        margin="normal"
        multiline
        fullWidth
        data-html2canvas-ignore
        value={R.pathOr(
          "",
          ["levelOfLeaningForm", "levelOfLearningInput1"],
          unitPlanDetailsData
        )}
        onChange={(e) =>
          onFormChange(e, ["levelOfLeaningForm", "levelOfLearningInput1"])
        }
      />
      <br />
      <Box fullWidth id="levelOfLearningInput1" display={"none"}>
        {R.pathOr(
          "",
          ["levelOfLeaningForm", "levelOfLearningInput1"],
          unitPlanDetailsData
        )}
      </Box>
      <TextField
        id="multiline-static"
        placeholder="Most learners should be able to"
        variant="outlined"
        margin="normal"
        multiline
        fullWidth
        data-html2canvas-ignore
        value={R.pathOr(
          "",
          ["levelOfLeaningForm", "levelOfLearningInput2"],
          unitPlanDetailsData
        )}
        onChange={(e) =>
          onFormChange(e, ["levelOfLeaningForm", "levelOfLearningInput2"])
        }
      />
      <br />
      <Box fullWidth id="levelOfLearningInput2" display={"none"}>
        {R.pathOr(
          "",
          ["levelOfLeaningForm", "levelOfLearningInput2"],
          unitPlanDetailsData
        )}
      </Box>
      <TextField
        id="multiline-static"
        placeholder="Some learners may be able to"
        variant="outlined"
        margin="normal"
        multiline
        fullWidth
        data-html2canvas-ignore
        value={R.pathOr(
          "",
          ["levelOfLeaningForm", "levelOfLearningInput3"],
          unitPlanDetailsData
        )}
        onChange={(e) =>
          onFormChange(e, ["levelOfLeaningForm", "levelOfLearningInput3"])
        }
      />
      <br />
      <Box fullWidth id="levelOfLearningInput3" display={"none"}>
        {R.pathOr(
          "",
          ["levelOfLeaningForm", "levelOfLearningInput3"],
          unitPlanDetailsData
        )}
      </Box>
      <Typography variant="h6">Assessments</Typography>
      <Typography variant="h6" style={{ marginBottom: "2rem" }}>
        Formative Assessment
      </Typography>
      <FormControl variant="outlined" className={classes.inputFormControl}>
        <InputLabel className={classes.inputLabel1}>Formative</InputLabel>
        <LOVSelect
          variant="outlined"
          margin="normal"
          formType={FORM_TYPES.UNIT_PLAN_FORM}
          selectKey={LOVSelectKeys.FORMATIVE}
          dataKey={LOVDataKeys.FORMATIVE}
          lovKey={LOVKeyMap.FORMATIVE}
          value={R.pathOr(
            [],
            ["assessmentForm", "formativeAssessments"],
            unitPlanDetailsData
          )}
          multiselect={true}
          selectObj={true}
          getSelectList={getSelectList}
          showList={true}
        />
      </FormControl>
      <br />
      <Typography variant="h6">Formative assessment Comments</Typography>
      <TextField
        id="multiline-static"
        margin="normal"
        variant="outlined"
        placeholder="Enter formative assessment comments"
        helperText="Please enter your Formative assessment comments"
        multiline
        fullWidth
        data-html2canvas-ignore
        onChange={(e) => onFormChange(e, ["assessmentForm", "formative"])}
        value={R.pathOr(
          "",
          ["assessmentForm", "formative"],
          unitPlanDetailsData
        )}
      />
      <br />
      <Typography variant="h6">Formative assessment Reference Links</Typography>
      <TextField
        id="multiline-static"
        margin="normal"
        variant="outlined"
        placeholder="Enter formative assessment reference links"
        helperText="Please enter your Formative assessment reference links"
        multiline
        fullWidth
        data-html2canvas-ignore
        onChange={(e) =>
          onFormChange(e, ["assessmentForm", "formativeReference"])
        }
        value={R.pathOr(
          "",
          ["assessmentForm", "formativeReference"],
          unitPlanDetailsData
        )}
      />
      <br />
      <Typography variant="h6">Summative assessment</Typography>
      <TextField
        id="multiline-static"
        margin="normal"
        variant="outlined"
        placeholder="Enter summative assessment comments"
        helperText="Please enter your Summative assessment comments"
        multiline
        fullWidth
        data-html2canvas-ignore
        onChange={(e) => onFormChange(e, ["assessmentForm", "summative"])}
        value={R.pathOr(
          "",
          ["assessmentForm", "summative"],
          unitPlanDetailsData
        )}
      />
      <br />
      <Typography variant="h6">Summative Assessment Reference links</Typography>
      <TextField
        id="multiline-static"
        variant="outlined"
        margin="normal"
        placeholder="Enter summative assessment reference links"
        helperText="Please enter your Summative assessment reference links"
        multiline
        fullWidth
        data-html2canvas-ignore
        onChange={(e) =>
          onFormChange(e, ["assessmentForm", "summativeReference"])
        }
        value={R.pathOr(
          "",
          ["assessmentForm", "summativeReference"],
          unitPlanDetailsData
        )}
      />
      <br />
      <Typography variant="h6">Teacher reflections</Typography>
      <TextField
        id="multiline-static"
        multiline
        placeholder="Enter teacher reflections"
        fullWidth
        margin="normal"
        variant="outlined"
        data-html2canvas-ignore
        value={R.propOr("", "teacherReflection", unitPlanDetailsData)}
        onChange={(e) => onFormChange(e, ["teacherReflection"])}
      />
      <br />
      <Box fullWidth id="teacherReflection" display={"none"}>
        {R.propOr("", "teacherReflection", unitPlanDetailsData)}
      </Box>
      <Typography variant="h6">Consents</Typography>
      <ConsentForm
        page={"unitPlan"}
        userRoles={userRoles}
        consentData={consentData}
        setConsentData={setConsentData}
        isCompleted={false}
      />
      <br />
      <Box sx={{ display: "flex" }}>
        <Button
          color="primary"
          onClick={onSaveDetails}
          variant="contained"
          className={classes.btn}
          data-html2canvas-ignore
        >
          Save
        </Button>
        <Button
          color="primary"
          variant="contained"
          disabled={!isSubmitEnabled(consentData) || isReadOnly}
          onClick={(e) => onSaveDetails(e, "COMPLETED")}
          data-html2canvas-ignore
        >
          Submit
        </Button>
      </Box>{" "}
    </Box>
  );
};

export default UnitPlanDetailsForm;
