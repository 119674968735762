import React, { useState } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import LessonPlanCard from "../LessonPlan/LessonPlanCard";
import CustomizedTabs from "../CustomizedTabs";
import LessonPlanModal from "../LessonPlan/LessonPlanModal";
import UnitPlanFormStepper from "../PlanFormStepper/PlanFormStepper";

const useStyles = makeStyles((theme) => ({
  cardWrapper: {
    flexWrap: "wrap",
  },
  annualPlanCard: {
    marginBottom: "30px",
    [theme.breakpoints.down('sm')]: {
      marginBottom: "10px",
    }
  },
  noDataAvailable: {
    margin: "20px",
  },
}));

function PlanDetailsPage(props) {
  const { parentState, modalState, userRoles, setModalState, getPlanDetails } =
    props;
  const classes = useStyles();
  const [activeTab, setActiveTab] = useState(0);

  return (
    <>
      <Grid
        container
        direction="row"
        className={classes.cardWrapper}
        spacing={4}
      >
        <CustomizedTabs
          setActiveTab={setActiveTab}
          tabList={[
            `Lesson Plans (${parentState?.lessonPlans?.length || 0})`,
            "Unit Plan Form",
            "Teacher Reflections",
          ]}
        />

        {activeTab === 0 && (
          <>
            {parentState?.lessonPlans?.length ? (
              parentState?.lessonPlans?.map((plan, index) => (
                <Grid
                  item
                  className={classes.annualPlanCard}
                  key={index}
                  md="6"
                  xs="12"
                >
                  <LessonPlanCard
                    {...props}
                    data={plan}
                    privileges={props?.privileges?.lessonplan}
                  />
                </Grid>
              ))
            ) : (
              <div className={classes.noDataAvailable}>No Data available</div>
            )}
          </>
        )}
        {activeTab === 1 && (
          <Grid item className={classes.annualPlanCard} xs="12">
            <UnitPlanFormStepper
              planName={"UNIT_PLAN"}
              userRoles={userRoles}
              parentState={parentState}
              privileges={props?.privileges?.unitplanform}
              getPlanDetails={getPlanDetails}
            />
          </Grid>
        )}
        {activeTab === 2 && (
          <Grid item className={classes.annualPlanCard} xs="12">
            Teacher Reflections
          </Grid>
        )}
      </Grid>
      {modalState.isOpen && (
        <LessonPlanModal
          userRoles={userRoles}
          modalState={modalState}
          setModalState={setModalState}
          getPlanList={getPlanDetails}
          subUnits={parentState?.subUnits || []}
          unitData={parentState}
        />
      )}
    </>
  );
}

export default PlanDetailsPage;
