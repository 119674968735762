import React, { useState, useCallback, useEffect, Suspense, lazy } from "react";
import {
  Container,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { colors } from "theme/colors";
import SearchInput from "common/components/SearchInput/SearchInput";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { useDispatch, useSelector } from "react-redux";
import { clearForm, updateForm } from "state/slices/globalFormSlice";
import FormDialog from "common/components/FormDialog/FormDialog";
import CircularProgress from "@mui/material/CircularProgress";
// import PaginatedList from "common/components/PaginatedList";
import * as R from "ramda";
import {
  getPageList,
  onAddValue,
  updateStatus,
} from "components/listOfValues/utils";
import {
  clearPages,
  getPageListItems,
  setPageListItems,
} from "state/slices/pageSlice";
import { completementStatus, FORM_TYPES } from "common/utils/formUtility";
import { getLOVItemFormData } from "util/APIPayloadUtils.js/LOVUtils";
import { PageReadViewRenderer } from "common/utils/rendererList";
import { Close } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100%",
    backgroundColor: colors.gray1,
    maxWidth: "100%",
    marginTop: "1rem",
    marginBottom: "1rem",
  },
  addIconButton: {
    float: "right",
  },
  addIcon: {
    fontSize: "3rem",
  },
  searchInput: {
    paddingBottom: "10px",
  },
  searchIcon: {
    color: colors.gray3,
  },
  modalCloseBtn: {
    alignSelf: "center",
    textAlign: "center",
  },
  searchContainer: {
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
    },
  },
}));

const PaginatedList = lazy(() => import("common/components/PaginatedList"));
const GridViewWrapper = ({
  paginatedListRenderer,
  formDialogConfig = {},
  displayAdd = true,
  page,
  payloadSelector = undefined,
  onTabChange,
  pageConfig,
  showFilters = false,
  disableReadView = false,
  privileges,
  userRoles,
  onSeparateAddForm,
  enableSearch = true,
}) => {
  const classes = useStyles();
  const [openForm, setFormOpen] = useState(false);
  const [readViewOpen, setReadViewOpen] = useState(false);
  const [viewData, setViewData] = useState({});
  const { formType, formRenderer, title } = formDialogConfig;
  const [isEdit, setIsEdit] = useState(false);

  const formData = useSelector((state) =>
    R.path(["globalForm", formType], state)
  );
  const pageData = useSelector((state) => R.path(["pages", page], state));
  const dataLoading = useSelector(
    (state) => R.path(["pages", page], state)?.loading
  );
  const pageFilter = useSelector((state) =>
    R.path(["globalForm", "pageFilters"], state)
  );
  const { currentPage } = pageData;
  const dispatch = useDispatch();

  const onAddClick = () => {
    dispatch(clearForm({ formType }));
    setFormOpen(true);
    onSeparateAddForm && onSeparateAddForm();
  };

  const onSave = async () => {
    const selectedFormData = payloadSelector
      ? payloadSelector(formData)
      : formData;
    const response = await onAddValue(selectedFormData, page);
    setFormOpen(false);
    dispatch(clearForm({ formType }));
    getListData();
  };

  const getListData = useCallback(
    async (searchKey = "") => {
      dispatch(
        getPageListItems({
          page,
        })
      );
      const pageListData = await getPageList({
        searchKey,
        page,
        pageData,
        pageFilter: pageFilter,
      });
      dispatch(
        setPageListItems({
          page,
          itemList: pageListData?.content ?? [],
          totalElements: pageListData?.totalElements ?? 0,
          totalPages: pageListData?.totalPages ?? 1,
        })
      );
    },
    [dispatch, page, pageData, pageFilter]
  );

  useEffect(() => {
    getListData();
  }, [currentPage, pageFilter]);

  const onEditClick = (data) => {
    if (!page === "schools") {
      dispatch(clearPages());
    }
    const editFormData =
      formType === FORM_TYPES.LOV_FORM ? getLOVItemFormData(data) : data;
    setIsEdit(true);
    dispatch(clearForm({ formType: formType }));
    dispatch(updateForm({ formType: formType, formData: editFormData }));
    setFormOpen(true);
  };

  const onFormClose = () => {
    setFormOpen(false);
    setIsEdit(false);
  };

  const onViewClick = (data) => {
    setViewData(data);
    setReadViewOpen(true);
  };

  const onViewClose = () => {
    setReadViewOpen(false);
  };

  const onActiveClick = async (data = formData) => {
    const updatedData = R.pipe(
      R.assoc("status", completementStatus(data.status)),
      R.assoc("version", data.version)
    )({});
    updatedData["version"] = 0;
    const response = await updateStatus(updatedData, data.id, page);
    setFormOpen(false);
    getListData();
  };

  const ReadViewRenderer = R.prop(paginatedListRenderer, PageReadViewRenderer);

  return (
    <Container className={classes.container}>
      <Grid container spacing={3}>
        <Grid item xs={12} container className={classes.searchContainer}>
          {enableSearch && (
            <Grid item xs={12} md={3}>
              <SearchInput
                getList={getListData}
                className={classes.searchInput}
              />
            </Grid>
          )}
          {displayAdd && R.propOr(true, "CREATE", privileges) && (
            <Grid item xs={12} md={9}>
              <Button
                variant="contained"
                color="primary"
                onClick={onAddClick}
                className={classes.addIconButton}
                startIcon={<AddCircleIcon className="addIcon" />}
              >
                {`Add ${title ? title : ""}`}

                {/* make this name dynamic for all add forms */}
              </Button>

              {/* <IconButton
                color="primary"
                className={classes.addIconButton}
                onClick={onAddClick}
              >
                <AddCircleIcon className="addIcon" />
              </IconButton> */}
            </Grid>
          )}
        </Grid>
        <Grid item xs={12}>
          <Suspense fallback={<CircularProgress />}>
            <PaginatedList
              page={page}
              renderer={paginatedListRenderer}
              onEditClick={onEditClick}
              onActiveClick={onActiveClick}
              onTabChange={onTabChange}
              pageConfig={pageConfig}
              showFilters={showFilters}
              disableReadView={disableReadView}
              privileges={privileges}
              onViewClick={onViewClick}
              userRoles={userRoles}
              dataLoading={dataLoading}
            />
          </Suspense>
        </Grid>
      </Grid>
      {!onSeparateAddForm ? (
        <FormDialog
          isEdit={isEdit}
          open={openForm}
          onClose={onFormClose}
          title={`${isEdit ? "Edit" : "Add"} ${title}`}
          formRenderer={formRenderer}
          formType={formType}
          onSave={onSave}
          showActive={isEdit}
          onActiveClick={onActiveClick}
          privileges={privileges}
        />
      ) : null}
      <Dialog
        maxWidth="md"
        fullWidth
        open={readViewOpen}
        onClose={onViewClose}
        aria-labelledby="form-dialog-title"
      >
        <Grid container>
          <Grid item xs={10}>
            <DialogTitle>{title}</DialogTitle>
          </Grid>
          <Grid item xs={2} className={classes.modalCloseBtn}>
            <IconButton onClick={onViewClose}>
              <Close />
            </IconButton>
          </Grid>
        </Grid>
        <DialogContent>
          <ReadViewRenderer data={viewData} userRoles={userRoles} />
        </DialogContent>
      </Dialog>
    </Container>
  );
};

export default GridViewWrapper;
