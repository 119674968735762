const TierRange = {
 "AY21_22": {
    0: {
        startDate:"17-03-2021",
        endDate:"02-07-2021"
    },
    1: {
        startDate:"03-07-2021",
        endDate:"24-09-2021"
    },
    2: {
        startDate:"25-09-2021",
        endDate:"22-12-2021"
    },
    3: {
        startDate:"23-12-2021",
        endDate:"04-03-2022"
    }
 },
 "AY22_23": {
    0: {
        startDate:"05-03-2022",
        endDate:"02-07-2022"
    },
    1: {
        startDate:"03-07-2022",
        endDate:"24-09-2022"
    },
    2: {
        startDate:"25-09-2022",
        endDate:"22-12-2022"
    },
    3: {
        startDate:"23-12-2022",
        endDate:"04-03-2023"
    }
 },
 "AY23_24": {
    0: {
        startDate:"05-03-2023",
        endDate:"02-07-2023"
    },
    1: {
        startDate:"03-07-2023",
        endDate:"24-09-2023"
    },
    2: {
        startDate:"25-09-2023",
        endDate:"22-12-2023"
    },
    3: {
        startDate:"23-12-2023",
        endDate:"04-03-2024"
    }
 },
}

export default TierRange;