import {
  Box,
  Checkbox,
  Table,
  TableBody,
  Typography,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  FormControlLabel,
} from "@material-ui/core";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as R from "ramda";
import { updateForm } from "state/slices/globalFormSlice";
import { makeStyles } from "@material-ui/styles";
import { RolesMap } from "util/roleUtils";

const useStyle = makeStyles((theme) => ({
  tableCell: {
    color: "#fff",
  },
}));
const getprivilegeList = (privileges) => {
  const allEntities = [];
  const entityMap = {};
  R.forEach((entityObj) => {
    if (!R.includes(entityObj.entityName, allEntities)) {
      allEntities.push(entityObj.entityName);
    }
  })(privileges);
  R.forEach((entity) => {
    entityMap[entity] = {};
    const relatedEntityObj = R.filter(
      (entityObj) => entityObj.entityName === entity
    )(privileges);
    R.forEach((relatedObj) => {
      entityMap[entity][relatedObj.privilegeType] = relatedObj;
    })(relatedEntityObj);
  })(allEntities);
  return { allEntities, entityMap };
};

const RolePrivilegesForm = ({ formType }) => {
  const classes = useStyle();
  const privileges = useSelector((state) => state.pages.privileges.items);
  const privilegeFormData = useSelector((state) => state.globalForm[formType]);
  const { allEntities, entityMap } = getprivilegeList(privileges);
  const [checkedPrivileges, setCheckedPrivileges] = useState(
    R.propOr([], "rolePrivilegeXrefs", privilegeFormData)
  );
  const dispatch = useDispatch();
  const onChange = (e, entityObj) => {
    let updatedCheckedPrivileges = [...checkedPrivileges];
    if (e.target.checked) {
      updatedCheckedPrivileges.push({ privilege: entityObj });
      if (R.includes(entityObj.privilegeType, ["CREATE", "UPDATE", "DELETE"])) {
        updatedCheckedPrivileges.push({
          privilege: entityMap[entityObj.entityName].READ,
        });
      }
    } else {
      updatedCheckedPrivileges = R.filter(
        (entity) => entity.privilege.name !== entityObj.name
      )(checkedPrivileges);
    }
    const updatedPrivilegeFormData = R.assocPath(
      ["rolePrivilegeXrefs"],
      updatedCheckedPrivileges,
      privilegeFormData
    );
    dispatch(updateForm({ formType, formData: updatedPrivilegeFormData }));
    setCheckedPrivileges(updatedCheckedPrivileges);
  };

  const onCriteriaChange = (e) => {
    const updatedPrivilegeFormData = { ...privilegeFormData };
    if (e.target.name === "isGlobal" && e.target.checked) {
      updatedPrivilegeFormData["isSchoolApplicable"] = false;
      updatedPrivilegeFormData["isGradesApplicable"] = false;
      updatedPrivilegeFormData["isSubjectApplicable"] = false;
    }

    updatedPrivilegeFormData[e.target.name] = e.target.checked;
    dispatch(updateForm({ formType, formData: updatedPrivilegeFormData }));
  };

  return (
    <Box>
      <FormControlLabel
        label="Applicable Globally"
        control={
          <Checkbox
            checked={privilegeFormData?.["isGlobal"]}
            onChange={onCriteriaChange}
            name="isGlobal"
          />
        }
      />
      <FormControlLabel
        label="Applicable at School Level"
        control={
          <Checkbox
            disabled={privilegeFormData?.["isGlobal"]}
            checked={privilegeFormData?.["isSchoolApplicable"]}
            onChange={onCriteriaChange}
            name="isSchoolApplicable"
          />
        }
      />
      <FormControlLabel
        label="Applicable at Grade Level"
        control={
          <Checkbox
            disabled={privilegeFormData?.["isGlobal"]}
            checked={privilegeFormData?.["isGradesApplicable"]}
            onChange={onCriteriaChange}
            name="isGradesApplicable"
          />
        }
      />
      <FormControlLabel
        label="Applicable at Subject Level"
        control={
          <Checkbox
            disabled={privilegeFormData?.["isGlobal"]}
            checked={privilegeFormData?.["isSubjectApplicable"]}
            onChange={onCriteriaChange}
            name="isSubjectApplicable"
          />
        }
      />
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="simple table">
          <TableHead style={{ background: "#263657" }}>
            <TableRow>
              <TableCell className={classes.tableCell}>Roles</TableCell>
              <TableCell className={classes.tableCell}>Read</TableCell>
              <TableCell className={classes.tableCell}>Create</TableCell>
              <TableCell className={classes.tableCell}>Update</TableCell>
              <TableCell className={classes.tableCell}>Delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {R.map((entity) => {
              return (
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {" "}
                    <Typography variant="subtitle2">
                      {" "}
                      {RolesMap[entity]}
                    </Typography>{" "}
                  </TableCell>
                  <TableCell>
                    <Checkbox
                      onChange={(e) => onChange(e, entityMap[entity].READ)}
                      checked={
                        R.findIndex(
                          (checkedEntity) =>
                            checkedEntity.name === entityMap[entity].READ.name
                        )(
                          R.map((privilegeItem) => privilegeItem.privilege)(
                            checkedPrivileges
                          )
                        ) >= 0
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <Checkbox
                      onChange={(e) => onChange(e, entityMap[entity].CREATE)}
                      checked={
                        R.findIndex(
                          (checkedEntity) =>
                            checkedEntity.name === entityMap[entity].CREATE.name
                        )(
                          R.map((privilegeItem) => privilegeItem.privilege)(
                            checkedPrivileges
                          )
                        ) >= 0
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <Checkbox
                      onChange={(e) => onChange(e, entityMap[entity].UPDATE)}
                      checked={
                        R.findIndex(
                          (checkedEntity) =>
                            checkedEntity.name === entityMap[entity].UPDATE.name
                        )(
                          R.map((privilegeItem) => privilegeItem.privilege)(
                            checkedPrivileges
                          )
                        ) >= 0
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <Checkbox
                      onChange={(e) => onChange(e, entityMap[entity].DELETE)}
                      checked={
                        R.findIndex(
                          (checkedEntity) =>
                            checkedEntity.name === entityMap[entity].DELETE.name
                        )(
                          R.map((privilegeItem) => privilegeItem.privilege)(
                            checkedPrivileges
                          )
                        ) >= 0
                      }
                    />
                  </TableCell>
                </TableRow>
              );
            })(allEntities)}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default RolePrivilegesForm;
