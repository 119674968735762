import {
  Box,
  Button,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  TextField,
  InputLabel,
  IconButton,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Close, ExpandMoreOutlined } from "@material-ui/icons";
import { mapIndexed } from "common/utils/composition";
import { makeStyles } from "@material-ui/styles";
import * as R from "ramda";
import { PAGES, updatePageListItems } from "state/slices/pageSlice";
import { colors } from "theme/colors";
import {
  readGoalSettingReviewForm,
  saveGoalSettingReviewComment,
} from "components/listOfValues/utils";
import Comment from "common/components/Comment/Comment";
import { useDispatch } from "react-redux";
import GoalFeedback from "../SetGoal/GoalFeedback";
import { goalSettingReviewFormStateOrder } from "./ReviewSetGoalListView";
import GoalEvidenceList from "../SetGoal/GoalEvidenceList";

const useStyle = makeStyles((theme) => ({
  alignCenter: {
    display: "flex",
    alignItems: "center",
  },
  iconContainer: {
    display: "flex",
    justifyContent: "end",
  },
  inputFormControl: {
    marginTop: "1.5rem",
  },
  editViewContainer: {
    textAlign: "end",
  },
  saveBtn: {
    marginRight: "1rem",
  },
  editIconBtn: {
    margin: "0px 8px",
    padding: "0px",
  },
  readViewContainer: {
    textAlign: "end",
  },
  subUnitChip: {
    "&.MuiChip-root": {
      marginRight: "1rem",
      color: colors.blue1,
      fontSize: "16px",
      fontWeight: "500",
    },
  },
  heading: {
    fontWeight: "600",
  },
  editIcon: {
    "&.MuiSvgIcon-root": {
      fontSize: "1.3rem",
    },
  },
  headingsContainer: {
    textAlign: "left",
  },
  inputLabel: {
    top: "-17px",
  },
  inputLabel1: {
    top: "-17px",
    left: "-14px",
  },
  placeholderValue: {
    color: "#bbb",
  },
  displayobservation: {
    background: "#898eac2e",
    border: "1px solid #898EAC",
    boxShadow: "none",
    padding: "0.5rem 1rem",
    borderRadius: 6,
    marginBottom: "0px",
  },
  displayobservation1: {
    background: "#898eac2e",
    border: "1px solid #898EAC",
    boxShadow: "none",
    borderRadius: 6,
    marginBottom: "10px",
  },
  editfields: {
    margin: "1.5rem 0",
    border: "1px solid #26365785",
    borderRadius: 4,
    padding: "1rem",
    background: "#fff",
  },
}));

const SetGoalsReadView = ({ goalSetting }) => {
  const [reviewCommentData, setReviewCommentData] = useState(goalSetting);
  const [goals, setGoals] = useState(goalSetting.goalSetting?.goals);
  const [updatedState, setUpdatedState] = useState("");
  const [commentDialogOpen, setCommentDialogOpen] = useState(false);
  const [reviewComment, setReviewComment] = useState("");
  const [viewCommentDialogOpen, setViewCommentDialogOpen] = useState(false);

  const [accordianExpandedState, setAccordianExpandedState] = useState({});
  const classes = useStyle();
  const dispatch = useDispatch();

  const onViewCommentDialogOpen = () => {
    setViewCommentDialogOpen(true);
  };

  const handleSubmit = async (state) => {
    await saveGoalSettingReviewComment({
      reviewComment: reviewComment,
      reviewState: updatedState,
      goalReviewId: reviewCommentData.id,
    });
    updateGoalSettingReviewForm();
    setGoals(reviewCommentData.goalSetting.goals);
    setReviewComment("");
    setCommentDialogOpen(false);
  };

  const updateGoalSettingReviewForm = async () => {
    const itemData = await readGoalSettingReviewForm(reviewCommentData.id);
    setReviewCommentData(itemData);
    dispatch(
      updatePageListItems({
        page: PAGES.reviewSetGoal,
        itemObject: itemData,
      })
    );
  };

  useEffect(() => {
    setGoals(reviewCommentData.goalSetting?.goals);
  }, [reviewCommentData]);

  useEffect(() => {
    setReviewCommentData(goalSetting);
  }, [goalSetting]);

  const onStateUpdateButtonClick = (stateToBeUpdated) => {
    setCommentDialogOpen(true);
    setUpdatedState(stateToBeUpdated);
  };

  const onCommentDialogClose = (stateToBeUpdated) => {
    setCommentDialogOpen(false);
    setUpdatedState("");
  };

  const renderButton = () => {
    return (
      <>
        {goalSettingReviewFormStateOrder[goalSetting.reviewState] <
        goalSettingReviewFormStateOrder["SELF_REVIEW_COMPLETED"] ? (
          <>
            <Button
              color="primary"
              variant="contained"
              onClick={() => onStateUpdateButtonClick("SENT_FOR_REASSESSMENT")}
              style={{ marginRight: 20 }}
            >
              {" "}
              Send For Reassessment{" "}
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => onStateUpdateButtonClick("APPROVED")}
            >
              {" "}
              Approve{" "}
            </Button>
          </>
        ) : null}
      </>
    );
  };

  return (
    <>
      {goals?.filter((goal) => !goal.deleted).length === 0 && (
        <Grid container spacing={3} fullWidth>
          <Grid item xs={12} className={classes.alignCenter}>
            <Typography>No goals available</Typography>
          </Grid>
        </Grid>
      )}

      <Box>
        {(
          <Box className={classes.editViewContainer}>
            {mapIndexed((goal, index) => {
              return (
                <>
                  <Accordion
                    className={classes.displayobservation1}
                    defaultExpanded={!goal.id || goal.id.length === 0}
                    expanded={!!accordianExpandedState[index]}
                    onChange={(_, expanded) => {
                      let expandStateArray = { ...accordianExpandedState };
                      expandStateArray[index] = expanded;
                      setAccordianExpandedState(expandStateArray);
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreOutlined />}
                      aria-controls="panel1d-content"
                      id="panel1d-header"
                    >
                      <Grid container>
                        <Grid item xs={12} md={9}>
                          <Typography variant="subtitle2" align="left">
                            Goal Details: {goal.goalDetails}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <Typography variant="subtitle1" align="left">
                            Weightage: {goal.weightage}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={9}>
                          <Typography variant="subtitle2" align="left">
                            Measurement: {goal.measurementUnit}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <Typography variant="subtitle2" align="left">
                            Evidence Accepted: {goal.evidence ?? "-"}
                          </Typography>
                        </Grid>
                      </Grid>
                    </AccordionSummary>
                    <AccordionDetails
                      className={classes.outcomeAccordionContainer}
                    >
                      {goal.evidences?.length ? (
                        <Grid
                          container
                          spacing={1}
                          className={classes.editfields}
                        >
                          <GoalEvidenceList
                            evidences={goal.evidences}
                            readOnly={true}
                          />
                        </Grid>
                      ) : (
                        <Grid
                          container
                          spacing={1}
                          className={classes.editfields}
                        >
                          Evidences not submitted!
                        </Grid>
                      )}
                    </AccordionDetails>
                  </Accordion>
                </>
              );
            })(goals ?? [])}
            {goals?.length > 0 &&
              goalSettingReviewFormStateOrder[goalSetting.reviewState] >=
                goalSettingReviewFormStateOrder["APPROVED"] && (
                <Box
                  sx={{
                    paddingLeft: "1rem",
                    paddingRight: "1rem",
                  }}
                  style={{ textAlign: "start" }}
                >
                  <h3 className={classes.heading} align={"left"}>
                    Goal Learnings
                  </h3>
                  <InputLabel>Strength</InputLabel>
                  <Typography> {goalSetting.goalSetting.strength} </Typography>
                  <InputLabel>Area of Development</InputLabel>
                  <Typography> {goalSetting.goalSetting.weakness} </Typography>
                  <InputLabel>Training Required</InputLabel>
                  <Typography>
                    {goalSetting.goalSetting.learningRequired}
                  </Typography>
                </Box>
              )}
            {/* same like ADD GOAL - isEditEnabled condition */}
            {reviewCommentData?.reviewComments?.length ? (
              <Button
                color="primary"
                variant="text"
                onClick={onViewCommentDialogOpen}
                style={{ marginRight: 20 }}
              >
                {`View ${reviewCommentData?.reviewComments?.length} Comments`}
              </Button>
            ) : (
              <Button
                color="primary"
                variant="text"
                onClick={onViewCommentDialogOpen}
                style={{ marginRight: 20 }}
                disabled
              >
                {`No Comments`}
              </Button>
            )}
            {renderButton()}
            <Dialog
              maxWidth="md"
              fullWidth
              open={viewCommentDialogOpen}
              onClose={() => setViewCommentDialogOpen(false)}
              aria-labelledby="form-dialog-title"
            >
              <Grid container>
                <Grid item xs={10}>
                  <DialogTitle id="form-dialog-title">
                    <Typography variant="h6">Comments</Typography>
                  </DialogTitle>
                </Grid>
                <Grid item xs={2} className={classes.modalCloseBtn}>
                  <IconButton onClick={() => setViewCommentDialogOpen(false)}>
                    <Close />
                  </IconButton>
                </Grid>
              </Grid>
              <DialogContent style={{ padding: "8px 25px" }}>
                <Comment
                  comments={reviewCommentData?.reviewComments}
                  primaryDataVariale="reviewComment"
                  secondaryLabelVariable="reviewState"
                />
              </DialogContent>
            </Dialog>

            <Dialog
              maxWidth="md"
              fullWidth
              open={commentDialogOpen}
              onClose={onCommentDialogClose}
              aria-labelledby="form-dialog-title"
            >
              <Grid container>
                <Grid item xs={10}>
                  <DialogTitle>Review Comments</DialogTitle>
                </Grid>
              </Grid>

              <DialogContent>
                <InputLabel className={classes.inputLabel}>Comment</InputLabel>
                <TextField
                  id={`review-comment-multiline-${reviewCommentData.id}`}
                  placeholder="Submit your review comments"
                  variant="outlined"
                  margin="normal"
                  multiline
                  fullWidth
                  data-html2canvas-ignore
                  value={reviewComment}
                  onChange={(e) => setReviewComment(e.target.value)}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={onCommentDialogClose}>Cancel</Button>
                <Button onClick={handleSubmit}>Submit</Button>
              </DialogActions>
            </Dialog>
          </Box>
        ) || (
          <Box>
            {R.filter((goal) => !goal.deleted)(goals).length === 0 && (
              <Grid container spacing={3} fullWidth>
                <Grid item xs={10} className={classes.alignCenter}>
                  <Typography>No goals available</Typography>
                </Grid>
              </Grid>
            )}
          </Box>
        )}
      </Box>
    </>
  );
};

export default SetGoalsReadView;
