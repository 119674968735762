import React, { useState } from "react";
import {
  makeStyles,
  Typography,
  Grid,
  IconButton,
  Card,
  CardContent,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";
import { colors } from "theme/colors";
import EditIcon from "@material-ui/icons/Edit";
import * as R from "ramda";
import { Close, Settings, Visibility } from "@material-ui/icons";
import ChangePassword from "common/components/ChangePassword/ChangePassword";
import { getDetailsById } from "components/listOfValues/utils";
import TeacherReadView from "./TeacherReadView";

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: "0.75rem",
  },
  root: {
    minHeight: "310px",
    maxHeight: "450px",
    overflow: "auto",
    border: `1px solid #d6d6d6`,
    background: "#f0f0f0",
    borderRadius: 6,
    boxShadow: "none",
    "&:hover, &:focus": {
      boxShadow: "4px 4px #e5e9ff",
      border: `1px solid ${colors.blue1}`,
      background: "#f4f6ff",
    },
  },
  editIconContainer: {
    display: "flex",
    alignItems: "baseline",
    justifyContent: "flex-end",
  },
  editIconBtn: {
    padding: "5px",
  },
  editIcon: {
    height: "1.2rem",
    width: "1.2rem",
    fontSize: "1.5rem",
    color: "rgba(0, 0, 0, 0.60)",
  },
  label: {
    color: colors.gray4,
  },
  activeLabel: {
    color: colors.green,
  },
  deactivateLabel: {
    color: colors.red,
  },
  modalCloseBtn: {
    alignSelf: "center",
    textAlign: "center",
  },
}));

const UserTeachersView = ({
  data,
  onEditClick,
  privileges,
  userRoles,
  onViewClick,
}) => {
  const [openChangePasswordModal, setChangePasswordModal] = useState(false);
  const classes = useStyles();

  const onCardEditClick = () => {
    onEditClick(data);
  };

  const onPasswordChangeSuccess = () => {
    setChangePasswordModal(false);
  };

  const onVisibilityClick = async () => {
    const response = await getDetailsById("/users", data.id);
    onViewClick(response);
  };

  return (
    <Grid item xs={12} md={6}>
      <div style={{ marginBottom: "1rem" }}>
        <Card className={classes.root}>
          <CardContent>
            <Grid container>
              <Grid item xs={10} className={classes.title}>
                <Typography variant="h6" color="primary">
                  {data.firstName} {data.lastName}
                </Typography>
              </Grid>
              <Grid item xs={2} className={classes.editIconContainer}>
                {R.propOr(true, "READ", privileges) && (
                  <IconButton
                    color="primary"
                    className={classes.editIconBtn}
                    onClick={onVisibilityClick}
                  >
                    <Visibility className={classes.editIcon} />
                  </IconButton>
                )}
                {R.propOr(true, "UPDATE", privileges) && (
                  <IconButton
                    color="primary"
                    className={classes.editIconBtn}
                    onClick={onCardEditClick}
                  >
                    <EditIcon className={classes.editIcon} />
                  </IconButton>
                )}
                {R.includes("System Administrators", userRoles) && (
                  <IconButton
                    color="primary"
                    className={classes.editIconBtn}
                    onClick={() => setChangePasswordModal(true)}
                  >
                    <Settings className={classes.editIcon} />
                  </IconButton>
                )}
              </Grid>
            </Grid>
            <TeacherReadView data={data} showTitle={false} />
          </CardContent>
        </Card>
      </div>
      <Dialog
        maxWidth="sm"
        fullWidth
        open={openChangePasswordModal}
        onClose={() => setChangePasswordModal(false)}
        aria-labelledby="form-dialog-title"
      >
        <Grid container>
          <Grid item xs={10}>
            <DialogTitle id="form-dialog-title">Change Password</DialogTitle>
          </Grid>
          <Grid item xs={2} className={classes.modalCloseBtn}>
            <IconButton onClick={() => setChangePasswordModal(false)}>
              <Close />
            </IconButton>
          </Grid>
        </Grid>
        <DialogContent>
          <ChangePassword
            email={data.email}
            onPasswordChangeSuccess={onPasswordChangeSuccess}
          />
        </DialogContent>
      </Dialog>
    </Grid>
  );
};

export default UserTeachersView;
