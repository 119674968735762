import { Divider, makeStyles } from "@material-ui/core";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import React from "react";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  breadcrumbWrapper: {
    margin: "2.5rem 0 0 1.5rem",
    "& .MuiBreadcrumbs-separator": {
      fontSize: "14px",
      alignSelf: "end",
      color: "#263657",
    },
    [theme.breakpoints.down("sm")]: {
      margin: "0px 0 10px 5px",
    },
  },
  link: {
    fontWeight: 700,
    fontSize: "14px",
    color: "#263657",
    "&:hover": {
      color: "#263657",
    },
  },
  endLink: {
    fontWeight: 400,
    fontSize: "14px",
    color: "#263657",
  },
  divider: {
    marginTop: "10px",
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
}));

const breadcrumbData = {
  ANNUAL_PLAN: [{ label: "Annual Plans" }], // Home / Annual Plans
  TIER_PLAN: [{ label: "Tier Plans" }], // Home / Tier Plans
  UNIT_PLAN: [{ label: "Unit Plans" }], // Home / Unit Plans
  LESSON_PLAN: [{ label: "Lesson Plans" }], // Home / Lesson Plans
  ANNUAL_PLAN_DETAILS: [
    { label: "Annual Plans", path: "annual-plans" },
    { label: "Annual Plan" },
  ], // Home / Annual Plans / Annual Plan
  TIER_PLAN_DETAILS: [
    { label: "..." },
    { label: "Annual Plan", path: "annual-plan" },
    { label: "Tier Plan" },
  ], // Home / ... / Annual Plan / Tier Plan
  UNIT_PLAN_DETAILS: [
    { label: "..." },
    { label: "Tier Plan", path: "tier-plan" },
    { label: "Unit Plan" },
  ], // Home / ... / Tier Plan / Unit Plan
  LESSON_PLAN_DETAILS: [
    { label: "..." },
    { label: "Unit Plan", path: "unit-plan" },
    { label: "Lesson Plan" },
  ], // Home / ... / Unit Plan / Lesson Plan

  SCHOOL: [{ label: "Schools" }], // Home / Schools
  SCHOOL_DETAILS: [{ label: "Schools", path: "schools" }, { label: "Classes" }], // Home / Schools / Classes
  CLASSES_DETAILS: [
    { label: "..." },
    { label: "Classes", path: "schools" },
    { label: "Class" },
  ], // Home / ... / Classes / Class

  STAFF: [{ label: "Users" }], // Home / Users
  STAFF_DETAILS: [{ label: "Users", path: "users" }, { label: "User" }], // Home / Users / USer
};

function BreadCrumb({ planName, planId }) {
  const classes = useStyles();
  const history = useHistory();

  const handleRedirect = (path, planId) => {
    history.push(`/${path || ""}${planId ? "/" + planId : ""}`);
  };

  return (
    <div className={classes.breadcrumbWrapper}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          underline="hover"
          color="inherit"
          onClick={() => handleRedirect()}
          className={classes.link}
        >
          Home
        </Link>
        {breadcrumbData?.[planName]?.map((item, index) => {
          if (!item.path) {
            return (
              <Typography variant="div" key={index} className={classes.endLink}>
                {item.label}
              </Typography>
            );
          }
          return (
            <Link
              underline="hover"
              color="inherit"
              key={index}
              className={classes.link}
              onClick={() => handleRedirect(item.path, planId)}
            >
              {item.label}
            </Link>
          );
        })}
      </Breadcrumbs>
      <Divider className={classes.divider} />
    </div>
  );
}

export default BreadCrumb;
