import React from "react";
import { FormControl, Grid, InputLabel, makeStyles } from "@material-ui/core";
import RoleDropDown from "common/components/RoleDropDown/RoleDropDown";

const useStyles = makeStyles((theme) => ({
  inputFormControl: {
    marginBottom: "2rem",
  },
  inputLabel1: {
    top: "-17px",
    left: "-14px",
  },
  inputLabel: {
    top: "-17px",
  },
}));

const GoalConfigurationForm = ({
  configurationFor,
  setConfigurationFor,
  goalApprover,
  setGoalApprover,
  goalReviewers,
  setGoalReviewers,
  reviewApprover,
  setReviewApprover,
  handleChange,
}) => {
  const classes = useStyles();
  return (
    <form style={{ marginTop: "1.5rem" }}>
      <Grid container>
        <Grid item xs={12}>
          <FormControl className={classes.inputFormControl}>
            <InputLabel className={classes.inputLabel}>
              Configuration For
            </InputLabel>
            <RoleDropDown
              roleState={configurationFor}
              setRoleState={setConfigurationFor}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl className={classes.inputFormControl}>
            <InputLabel className={classes.inputLabel}>
              Goal Approver
            </InputLabel>
            <RoleDropDown
              roleState={goalApprover}
              setRoleState={setGoalApprover}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl className={classes.inputFormControl}>
            <InputLabel className={classes.inputLabel}>
              Goal Reviewer(s)
            </InputLabel>
            <RoleDropDown
              roleState={goalReviewers}
              setRoleState={setGoalReviewers}
              multiSelect={true}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl className={classes.inputFormControl}>
            <InputLabel className={classes.inputLabel}>
              Review Approver
            </InputLabel>
            <RoleDropDown
              roleState={reviewApprover}
              setRoleState={setReviewApprover}
            />
          </FormControl>
        </Grid>
      </Grid>
    </form>
  );
};

export default GoalConfigurationForm;
