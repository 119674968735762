import React from "react";
import { Grid, makeStyles } from "@material-ui/core";
import CustomizedTabs from "../../academicPlanDetails/CustomizedTabs";
import SectionCard from "components/master/ManageSchool/ClassSections/SectionCard";
import RolesCard from "./RolesCard";
import GradeSubjectModal from "./GradeSubjectModal";

const useStyles = makeStyles((theme) => ({
  cardWrapper: {
    flexWrap: "wrap",
  },
  annualPlanCard: {
    marginBottom: "30px",
  },
  noDataAvailable: {
    margin: "20px",
  },
  paginationContainer: {
    display: "flex",
    justifyContent: "end",
  },
}));

function RolesPage(props) {
  const {
    parentState,
    setModalState,
    state,
    modalState,
    userRoles,
    getClassesDetails,
    privileges,
    getStaffDetails,
  } = props;
  const classes = useStyles();
  const [activeTab, setActiveTab] = React.useState(0);

  const onCardClick = (id) => {
    setModalState((prev) => ({
      ...prev,
      isGradeModalOpen: true,
      selectedRole: id,
    })); //isRoleModelOpen rename
  };

  return (
    <>
      <Grid
        container
        direction="row"
        className={classes.cardWrapper}
        spacing={4}
      >
        <CustomizedTabs
          setActiveTab={setActiveTab}
          tabList={[
            `Roles (${parentState?.roles?.length || 0})`,
            `NA (${parentState?.sections?.length || 0})`,
          ]}
        />

        {activeTab === 0 && (
          <>
            {parentState?.roles?.length ? (
              parentState?.roles?.map((role, index) => (
                <RolesCard
                  onCardClick={onCardClick}
                  data={role}
                  privileges={privileges}
                  userRoles={userRoles}
                  key={index}
                  getStaffDetails={getStaffDetails}
                  setModalState={setModalState}
                />
              ))
            ) : (
              <div className={classes.noDataAvailable}>No Data available</div>
            )}
          </>
        )}
        {activeTab === 1 && (
          <>
            {parentState?.sections?.length ? (
              parentState?.sections?.map((secData, index) => (
                <SectionCard
                  parentState={state}
                  modalState={modalState}
                  userRoles={userRoles}
                  getClassesDetails={getClassesDetails}
                  data={secData}
                  key={index}
                  {...props}
                  setModalState={setModalState}
                />
              ))
            ) : (
              <div className={classes.noDataAvailable}>No Data available</div>
            )}
          </>
        )}
      </Grid>
      {modalState?.isGradeModalOpen && (
        <GradeSubjectModal
          userRoles={userRoles}
          data={parentState.roles.find(
            (role) => role.roleId === modalState.selectedRole
          )}
          modalState={modalState}
          parentState={state}
          setModalState={setModalState}
          getClassesDetails={getClassesDetails}
        />
      )}
    </>
  );
}

export default RolesPage;
