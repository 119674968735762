import {
  FormControl,
  makeStyles,
  TextField,
  InputLabel,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import * as R from "ramda";
import SelectDropDown from "common/components/SelectDropDown/SelectDropDown";
import {
  LOVApiKeys,
  LOVDataKeys,
  LOVSelectKeys,
  LOVDisplayKeys,
} from "common/components/SelectDropDown/SelectDropDownUtils";
import StatusInput from "common/components/StatusInput/StatusInput";
import { updateForm } from "state/slices/globalFormSlice";
import { useDispatch, useSelector } from "react-redux";
import { PAGES } from "state/slices/pageSlice";

const useStyles = makeStyles((theme) => ({
  inputFormControl: {
    marginBottom: "2.5rem",
  },
  inputLabel1: {
    top: "-17px",
    left: "-14px",
  },
  inputLabel: {
    top: "-17px",
  },
}));
const ManageClassSectionForm = ({ formType, isEdit }) => {
  const classes = useStyles();
  const classSectionData = useSelector((state) => state.globalForm[formType]);
  const [formData, setformData] = useState(classSectionData);
  const dispatch = useDispatch();
  const onChange = (event) => {
    const [value, name] = R.paths(
      [
        ["target", "value"],
        ["target", "name"],
      ],
      event
    );
    const updatedformData = R.assoc(name, value, classSectionData);
    setformData(updatedformData);
    dispatch(updateForm({ formType, formData: updatedformData }));
  };

  useEffect(() => {
    dispatch(updateForm({ formType, formData: classSectionData }));
    setformData(classSectionData);
  }, [dispatch, formType, classSectionData]);

  return (
    <form style={{ marginTop: "1.5rem" }}>
      <FormControl variant="outlined" className={classes.inputFormControl}>
        <InputLabel
          className={classes.inputLabel1}
          id="demo-simple-select-outlined-label"
        >
          School
        </InputLabel>
        <SelectDropDown
          isDisabled={isEdit}
          formType={formType}
          selectKey={LOVSelectKeys.SCHOOL}
          apiKey={LOVApiKeys.SCHOOL}
          dataKey={LOVDataKeys.SCHOOL}
          displayKey={LOVDisplayKeys.SCHOOL}
          secondaryDataKey={"campus"}
          selectedValue={R.propOr({}, "school", formData)}
          dependentFields={[LOVApiKeys.CLASS_TEACHER]}
          page={PAGES.schools}
          queryParams={{
            schoolCode: R.pathOr("", ["school", "schoolCode"], formData),
            className: R.pathOr("", ["gradeName"], formData),
          }}
        />
      </FormControl>
      <FormControl variant="outlined" className={classes.inputFormControl}>
        <InputLabel
          className={classes.inputLabel1}
          id="demo-simple-select-outlined-label"
        >
          Class Name
        </InputLabel>
        <SelectDropDown
          isDisabled={isEdit}
          formType={formType}
          selectKey={LOVSelectKeys.CLASS}
          apiKey={LOVApiKeys.CLASS}
          dataKey={LOVDataKeys.CLASS}
          displayKey={LOVDisplayKeys.CLASS}
          selectedValue={R.propOr({}, "gradeName", formData)}
          page={PAGES.classes}
          dependentFields={[LOVApiKeys.CLASS_TEACHER]}
          queryParams={{
            schoolCode: R.pathOr("", ["school", "schoolCode"], formData),
            className: R.pathOr("", ["gradeName"], formData),
          }}
        />
      </FormControl>
      <FormControl className={classes.inputFormControl}>
        <InputLabel
          className={classes.inputLabel}
          id="demo-simple-select-outlined-label"
        >
          Section
        </InputLabel>
        <TextField
          disabled={isEdit}
          variant="outlined"
          margin="normal"
          name="section"
          placeholder="Enter section"
          value={formData.section}
          onChange={onChange}
        />
      </FormControl>
      <FormControl className={classes.inputFormControl}>
        <InputLabel
          className={classes.inputLabel}
          id="demo-simple-select-outlined-label"
        >
          Student Capacity
        </InputLabel>
        <TextField
          variant="outlined"
          type="number"
          margin="normal"
          name="capacity"
          placeholder="Enter student capacity"
          value={formData.capacity}
          onChange={onChange}
        />
      </FormControl>
      <FormControl variant="outlined">
        <InputLabel
          className={classes.inputLabel1}
          id="demo-simple-select-outlined-label"
        >
          Class Teacher
        </InputLabel>
        <SelectDropDown
          formType={formType}
          selectKey={LOVSelectKeys.CLASS_TEACHER}
          apiKey={LOVApiKeys.CLASS_TEACHER}
          dataKey={LOVDataKeys.CLASS_TEACHER}
          displayKey={LOVDisplayKeys.CLASS_TEACHER}
          secondaryDataKey={"lastName"}
          selectedValue={R.propOr({}, "classTeacher", formData)}
          page={PAGES.teachers}
          queryParams={{
            schoolCode: R.pathOr("", ["school", "schoolCode"], formData),
            className: R.pathOr("", ["gradeName"], formData),
          }}
          isDependent={true}
        />
      </FormControl>
      {!isEdit && <StatusInput formType={formType} />}
    </form>
  );
};

export default ManageClassSectionForm;
