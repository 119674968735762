import React from "react";
// import clsx from "clsx";
import { LWS_OG_LOGO } from "asset/images";
import AppHeader from "components/header/AppHeader";
import { DrawerList } from "constants/PageConstants";
import { Router, Link } from "react-router-dom";
import * as R from "ramda";
import { isNotEmpty, mapIndexed } from "common/utils/composition";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
} from "@material-ui/core";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import withTabsPrivilege from "common/hocs/withTabsPrivilege";
import { getAllowedTabs } from "../sideDrawer/util";

const drawerWidth = 0;
const planRoutes = ["/annual-plan", "/tier-plan", "/unit-plan", "/lesson-plan"];

const SideDrawer = ({ authenticated, onLogout, history, tabAccess }) => {
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevMobileOpen) => !prevMobileOpen);
  };

  const getListItem = ({
    item,
    index,
    classes,
    isNested = false,
    currentPath,
  }) => {
    return (
      <div key={`${item.label}-${index}`}>
        {isNotEmpty(R.propOr([], "children", item)) ? (
          <>
            <Accordion
              style={{ backgroundColor: "#263657", color: "#fff", margin: 0 }}
              elevation={0}
            >
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon
                    style={{ color: "#fff", padding: "0 5px", marginRight: 0 }}
                  />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
                style={{ paddingLeft: 0 }}
              >
                <ListItem
                  component={Link}
                  to={item.routeTo}
                  button
                  key={item.label}
                  selected={currentPath.includes(item.routeTo)}
                  style={{
                    padding: "0 16px",
                    backgroundColor: "#263657",
                    color: "#fff",
                  }}
                >
                  <ListItemIcon style={{ minWidth: "40px" }}>
                    {<item.icon style={{ color: "#fff" }} />}
                  </ListItemIcon>
                  <ListItemText
                    primary={item.label}
                    style={{ fontSize: "16px", "&.hover": { color: "#fff" } }}
                  />
                </ListItem>
              </AccordionSummary>
              <AccordionDetails
                style={{ padding: "0px", backgroundColor: "#23314f" }}
              >
                <div style={{ width: "100%" }}>
                  {mapIndexed((child, childIndex) =>
                    getListItem({
                      item: child,
                      index: childIndex,
                      classes,
                      isNested: true,
                      currentPath,
                    })
                  )(item.children)}
                </div>
              </AccordionDetails>
            </Accordion>
            <Divider style={{ BorderColor: "rgb(55, 78, 129)" }} />
          </>
        ) : (
          <>
            {isNested ? (
              <>
                <ListItem
                  onClick={() =>
                    setMobileOpen((prevMobileOpen) => !prevMobileOpen)
                  }
                  style={{ color: "#fff", paddingLeft: "3rem" }}
                  component={Link}
                  to={item.routeTo}
                  button
                  key={item.label}
                  selected={currentPath.includes(item.routeTo)}
                >
                  <ListItemIcon style={{ minWidth: "25px" }}>
                    {
                      <item.icon
                        style={{ color: "#374e81", fontSize: "15px" }}
                      />
                    }
                  </ListItemIcon>
                  <ListItemText primary={item.label} />
                </ListItem>
              </>
            ) : (
              <>
                <ListItem
                  onClick={() =>
                    setMobileOpen((prevMobileOpen) => !prevMobileOpen)
                  }
                  style={{ color: "#fff" }}
                  component={Link}
                  to={item.routeTo}
                  button
                  key={item.label}
                  selected={currentPath.includes(item.routeTo)}
                >
                  <ListItemIcon style={{ minWidth: "40px" }}>
                    {<item.icon style={{ color: "#fff" }} />}
                  </ListItemIcon>
                  <ListItemText primary={item.label} />
                </ListItem>
              </>
            )}
          </>
        )}
      </div>
    );
  };

  return (
    <Router history={history}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          elevation={0}
          position="fixed"
          sx={{
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            ml: { sm: `${drawerWidth}px` },
          }}
          style={{ backgroundColor: "#263657" }}
        >
          <Toolbar>
            <AppHeader {...{ authenticated, onLogout }} />
          </Toolbar>
        </AppBar>
        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
          aria-label="LWS Nav"
        >
          <Drawer
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: "300px",
                backgroundColor: "#263657",
              },
            }}
          >
            <div
              style={{
                margin: "12px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Button
                to="/"
                className="app-title"
                style={{ margin: 0, padding: 0 }}
              >
                <img
                  className="lwsLogo"
                  src={LWS_OG_LOGO}
                  alt="Lodha World School"
                />
                <span className="header-Title">Lodha World School</span>
              </Button>
            </div>
            <Divider />

            <List>
              {getAllowedTabs(tabAccess, DrawerList).map((eachItem, index) =>
                getListItem({
                  item: eachItem,
                  index,
                  currentPath: history.location.pathname,
                })
              )}
            </List>
          </Drawer>

          {/* Desktop nav */}
          <Drawer
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on desktop.
            }}
            sx={{
              display: { xs: "none", sm: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: "300px",
                backgroundColor: "#263657",
              },
            }}
          >
            <div style={{ margin: "12px" }}>
              <Button className="app-title" style={{ margin: 0, padding: 0 }}>
                <img
                  className="lwsLogo"
                  src={LWS_OG_LOGO}
                  alt="Lodha World School"
                />
                <span className="header-Title">Lodha World School</span>
              </Button>
            </div>
            <Divider />
            <List>
              {getAllowedTabs(tabAccess, DrawerList).map((eachItem, index) =>
                getListItem({
                  item: eachItem,
                  index,
                  currentPath: history.location.pathname,
                })
              )}
            </List>
          </Drawer>
        </Box>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: planRoutes.some((route) =>
              window.location.pathname.includes(route)
            )
              ? 0
              : 1,
            width: { sm: `calc(100% - ${drawerWidth}px)` },
          }}
          style={{ padding: 0 }}
        >
          <Toolbar />
        </Box>
      </Box>
    </Router>
  );
};

export default withTabsPrivilege(SideDrawer);
