import React, { useState } from "react";
import {
  makeStyles,
  Typography,
  Grid,
  Chip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Tab,
  Tabs,
} from "@material-ui/core";
import { colors } from "theme/colors";
import * as R from "ramda";
import { ExpandMoreOutlined } from "@material-ui/icons";
import { readGoalSettingForm } from "components/listOfValues/utils";
import { geAnnualPeriod } from "../../academicPlan/utils";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import SetGoals from "./SetGoals";
import { PAGES, updatePageListItems } from "state/slices/pageSlice";
import GoalFeedback from "./GoalFeedback";

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: "0.75rem",
  },
  heading: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: theme.typography.fontWeightBold,
  },
  subHeading: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightBold,
  },
  editIconContainer: {
    display: "flex",
    alignItems: "baseline",
    justifyContent: "flex-end",
  },
  editIconBtn: {
    padding: "0px",
  },
  editIcon: {
    height: "1.3rem",
    width: "1.3rem",
    fontSize: "1.3rem",
    color: "rgba(0, 0, 0, 0.54)",
  },
  label: {
    color: colors.gray4,
  },
  activeLabel: {
    color: colors.green,
  },
  deactivateLabel: {
    color: colors.red,
  },
  formContainer: {
    marginBottom: "0.5rem",
  },
  pointer: {
    display: "flex",
    alignItems: "center",
  },
  accordionDetails: {
    display: "inline",
    padding: "1rem",
    height: "auto",
    paddingLeft: "1rem",
    paddingRight: "1rem",
  },
  outcomeList: {
    height: "auto",
    width: "100%",
  },
  outcomeAccordionContainer: {
    overflow: "auto",
  },
  observationRegistryHeader: {
    backgroundColor: "#1b2945",
    color: colors.gray1,
    borderRadius: 6,
    paddingBottom: "0.5rem",
  },
  topIcon: {
    color: colors.gray1,
  },
  chip1: {
    color: "#fff",
    border: "1px solid #fff",
    fontWeight: 300,
    backgroundColor: "#263657",
  },
  subUnitChip: {
    "&.MuiChip-root": {
      marginRight: "1rem",
      color: colors.blue1,
      fontSize: "16px",
      fontWeight: "500",
    },
  },
}));

export const goalSettingFormState = {
  GOAL_SETUP: "Goal Setup",
  SENT_FOR_REVIEW: "Sent For Review",
  REASSESSMENT_REQUIRED: "Reassessment Required",
  REVIEWED: "Reviewed",
  SELF_REVIEW_COMPLETED: "Self Review Completed",
  PEER_REVIEW_COMPLETED: "Peer Review Completed",
  FINAL_REVIEW_COMPLETED: "Final Review Completed",
  COMPLETED: "Completed",
};

export const goalSettingFormStateOrder = {
  GOAL_SETUP: 0,
  SENT_FOR_REVIEW: 1,
  REASSESSMENT_REQUIRED: 2,
  REVIEWED: 3,
  SELF_REVIEW_COMPLETED: 4,
  PEER_REVIEW_COMPLETED: 5,
  FINAL_REVIEW_COMPLETED: 6,
  COMPLETED: 7,
};

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

const SetGoalListView = ({ data }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const annualPeriodName = geAnnualPeriod(R.pathOr("", ["annualPeriod"], data));
  const userProfile = useSelector((state) => state.userProfile.userProfile);
  const allRoles = useSelector((state) => state.pages.roles.items);

  const [expanded, setExpanded] = useState(false);
  const [openedReviewForm, setOpenedReviewForm] = useState("");
  const [selectedTab, setSelectedTab] = useState(0);

  const handleChange = (panel) => (event, isExpanded) => {
    isExpanded ? setOpenedReviewForm(data.id) : setOpenedReviewForm(null);
    setExpanded(isExpanded ? panel : false);
  };
  const handleChangeTab = (event, newValue) => {
    setSelectedTab(newValue);
  };

  React.useEffect(() => {
    async function fetchData() {
      // You can await here
      const response = await readGoalSettingForm(openedReviewForm);
      dispatch(
        updatePageListItems({
          page: PAGES.setGoal,
          itemObject: response,
        })
      );
    }
    if (openedReviewForm) fetchData();
  }, [openedReviewForm]);

  return (
    <Grid item xs={12}>
      <div style={{ marginBottom: "1rem" }}>
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreOutlined className={classes.topIcon} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className={classes.observationRegistryHeader}
          >
            <Grid container xs={12}>
              <Grid item xs={12} md={11}>
                <Typography variant="subtitle1">
                  Role:{" "}
                  {allRoles.find(
                    (role) => role.id === R.propOr("", "role", data)
                  )?.name ?? "-"}
                </Typography>
              </Grid>
              <Grid item xs={12} md={1}>
                <Chip
                  className={classes.chip1}
                  size="small"
                  label={annualPeriodName}
                />
              </Grid>

              <Grid item container style={{ marginTop: "1rem" }}>
                <Grid item xs={6} md={3}>
                  <Typography>
                    State:{" "}
                    {
                      goalSettingFormState[
                        R.pathOr("-", ["goalSettingState"], data)
                      ]
                    }
                  </Typography>
                </Grid>
                <Grid item xs={6} md={3}>
                  <Typography>
                    Full Name: {R.pathOr("-", ["fullName"], userProfile)}
                  </Typography>
                </Grid>
                <Grid item xs={6} md={4}>
                  <Typography>{`School: ${R.pathOr(
                    "-",
                    ["school", "schoolName"],
                    userProfile
                  )} (${R.pathOr(
                    "-",
                    ["school", "campus"],
                    userProfile
                  )})`}</Typography>
                </Grid>
                <Grid item xs={6} md={2}>
                  <Typography>
                    Designation: {R.pathOr("-", ["designation"], userProfile)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails className={classes.accordionDetails}>
            <Box
              sx={{
                paddingLeft: "1rem",
                paddingRight: "1rem",
              }}
            >
              <Box style={{ marginBottom: "1rem" }}>
                <Tabs
                  value={selectedTab}
                  onChange={handleChangeTab}
                  aria-label="basic tabs example"
                >
                  <Tab label="Goal Setup" {...a11yProps(0)} />
                  <Tab
                    label="Goal Feedback"
                    {...a11yProps(1)}
                    disabled={
                      goalSettingFormStateOrder[data.goalSettingState] <
                      goalSettingFormStateOrder["REVIEWED"]
                    }
                  />
                </Tabs>
              </Box>
              <TabPanel value={selectedTab} index={0}>
                <SetGoals goalSettingForm={data} />
              </TabPanel>
              <TabPanel value={selectedTab} index={1}>
                <GoalFeedback goalSetupId={data.id} />
              </TabPanel>
            </Box>
          </AccordionDetails>
        </Accordion>
      </div>
    </Grid>
  );
};

export default SetGoalListView;
