import {
    FormControl,
    makeStyles,
    TextField,
    IconButton,
    Grid,
    Select,
    MenuItem,
    InputLabel
} from "@material-ui/core";
import React from "react";
import * as R from "ramda";
import { filterIndexed, mapIndexed } from "common/utils/composition";
import { AddCircleOutline, RemoveCircleOutline } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
    inputFormControl: {
        marginBottom: "1rem",
    },
    inputLabel: {
        top: "-17px",
    },
    inputLabel1: {
        top: "-17px",
        left: "-14px",
    },
}));

const EMPTY_ROW = {
    name: "",
    tag: "",
};

const TaggedOutComes = ({ tagList, formData, setTaggedOutComes, observationIndex }) => {
    const learningOutcomes = R.path(["observations", observationIndex, "learningOutcomes"], formData);
    const classes = useStyles();
    const onInputChange = (event, itemIndex) => {
        const [value, name] = R.paths(
            [
                ["target", "value"],
                ["target", "name"],
            ],
            event
        );

        const updatedFormData = R.assocPath(["observations", observationIndex, "learningOutcomes", itemIndex, name], value, formData);
        setTaggedOutComes(updatedFormData);
    };

    const addNewRow = () => {
        const taggedOutComeList = R.path(["observations", observationIndex, "learningOutcomes"], formData);
        const updateKeyValues = R.append(EMPTY_ROW, taggedOutComeList);
        const updatedFormData = R.assocPath(["observations", observationIndex, "learningOutcomes"], updateKeyValues, formData);
        setTaggedOutComes(updatedFormData);
    };

    const removeRow = (removedIndex) => {
        const taggedOutComeList = R.path(["observations", observationIndex, "learningOutcomes"], formData);
        const updateKeyValues = filterIndexed((value, index) => index !== removedIndex,
            taggedOutComeList
        );
        const updatedFormData = R.assocPath(["observations", observationIndex, "learningOutcomes"], updateKeyValues, formData);
        setTaggedOutComes(updatedFormData);
    };

    return (
        <>
            {
                mapIndexed((eachOutCome, outcomeIndex) => {
                    return (<Grid container spacing={2} key={outcomeIndex} style={{ alignItems: "center"}}  className={classes.inputFormControl}>
                        <Grid item xs={6}>
                            <FormControl >
                                <InputLabel className={classes.inputLabel}>Outcome</InputLabel>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    name="name"
                                    placeholder="Enter outcome"
                                    value={eachOutCome.name}
                                    onChange={(e) => onInputChange(e, outcomeIndex)}
                                />
                            </FormControl>
                        </Grid>
                        {
                            !R.isEmpty(tagList) && (
                                <Grid item xs={5}>
                                    <FormControl variant="outlined">
                                        <InputLabel className={classes.inputLabel1}>Tag</InputLabel>
                                        <Select
                                            // margin="normal"
                                            variant="outlined"
                                            name={"tag"}
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={eachOutCome.tag}
                                            placeholder="Enter tag"
                                            onChange={(e) => onInputChange(e, outcomeIndex)}
                                            displayEmpty
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >
                                            <MenuItem value="" disabled >
                                                <span style={{ color: '#bbb' }}>Select tag</span>
                                            </MenuItem>
                                            {
                                                mapIndexed((tag, index) => {
                                                    return <MenuItem key={index} value={tag}>{tag}</MenuItem>
                                                }, tagList)
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                            )
                        }
                        <Grid item xs={1}>
                            {outcomeIndex === learningOutcomes.length - 1 ? (
                                <IconButton color="primary" onClick={addNewRow}>
                                    <AddCircleOutline className="addIcon" />
                                </IconButton>
                            ) : (
                                <IconButton color="primary" onClick={() => removeRow(outcomeIndex)}>
                                    <RemoveCircleOutline className="addIcon" />
                                </IconButton>
                            )}
                        </Grid>
                    </Grid>)
                }, learningOutcomes)
            }
        </>
    )
};

export default TaggedOutComes;