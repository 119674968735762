import * as R from "ramda";

export const getAllowedTabs = (tabAccessList, tabList) => {
  let allowedTabs = [];
  tabList.forEach((tabObj) => {
    if (!R.isEmpty(tabObj.children)) {
      const filteredChildren = R.filter((childItem) =>
        R.includes(childItem.id, tabAccessList)
      )(R.pathOr([], ["children"], tabObj));
      if (!R.isEmpty(filteredChildren)) {
        allowedTabs.push(R.assocPath(["children"], filteredChildren, tabObj));
      }
    }
    if (R.includes(tabObj.id, tabAccessList)) {
      allowedTabs.push(tabObj);
    }
  });
  return allowedTabs;
};
